<nb-card class="login-card" [nbSpinner]="loading" nbSpinnerStatus="primary">
  <nb-card-body>
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)">
      <app-input-email
        [error]="!!error"
        controlName="email"
        [formGroup]="loginForm"
        prefixIcon="at-outline"
      ></app-input-email>
      <app-input-password
        [error]="!!error"
        controlName="password"
        placeholder="Password"
        [formGroup]="loginForm"
        prefixIcon="lock-outline"
      ></app-input-password>
      <div class="login-error" *ngIf="error">
        {{ error }}
      </div>
      <button type="submit" nbButton status="primary" [disabled]="!loginForm.valid || loading">
        Login
      </button>
    </form>
    <a class="login-forgot-password" [routerLink]="['/password-recovery']">Forgot your password?</a>
  </nb-card-body>
</nb-card>
