/* eslint-disable max-lines */
import { FAILED, NO_TITLE_ERROR } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { Annotation, AnnotoriousAnnotation } from '@App/app/entities/files/annotation.model';
import { AnnotationForm } from '@App/app/entities/forms/annotation-form.model';
import { Coordinates2 } from '@App/app/entities/layer/measurements/coordinates';
import { AnnotationPriorities } from '@App/app/entities/shared/annotation-priorities.enum';
import { getMinMaxFromSelector } from '@App/app/shared/utils/annotations.utils';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Annotorious } from '@recogito/annotorious';
import { takeWhile } from 'rxjs/operators';
import { Annotations2dService } from './services/annotations2d-service/annotations2d.service';

@Component({
  selector: 'app-model-photo-card-zoom-annotations',
  templateUrl: './model-photo-card-zoom-annotations.component.html',
  styleUrls: ['./model-photo-card-zoom-annotations.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ModelPhotoCardZoomAnnotationsComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('editor') editor: ElementRef<HTMLDivElement> | null;
  @Input() annotations: Annotation[];
  @Input() htmlImage: HTMLImageElement;
  @Input() htmlWrapper: HTMLDivElement;
  @Input() parentRef: HTMLElement;
  @Input() visible: boolean;
  @Input() editingActive: boolean;
  @Output() save = new EventEmitter();
  private initialized = false;
  private annotorious: Annotorious;
  selectedAnnotation: Annotation | null = null;
  editingForm: FormGroup | null = null;

  constructor(
    private fb: FormBuilder,
    private annotations2dService: Annotations2dService,
    private toastrService: NbToastrService,
  ) {}

  ngOnInit() {
    this.annotorious = this.annotations2dService.createAnnotorious(this.htmlImage);
    this.annotorious.on('createSelection', this.createSelection);
    this.annotorious.on('cancelSelected', this.cancelSelection);
    this.annotorious.on('createAnnotation', this.createAnnotation);
    this.annotorious.on('selectAnnotation', this.selectAnnotation);
    this.annotorious.on('updateAnnotation', this.updateAnnotation);
    this.annotorious.on('deleteAnnotation', this.deleteAnnotation);
    this.annotorious.on('changeSelectionTarget', this.resizeAnnotation);
    this.initialized = true;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.annotorious.setAnnotations(
        this.annotations.map((ann, i) => {
          const id = `#${i}`;
          ann.localId = id;
          return ann.toAnnotoriousAnnotation(id, '', this.htmlImage);
        }),
      );
      this.updateAnnotationsUI();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialized) {
      if (changes.editingActive) {
        this.toggleEditMode(!changes.editingActive.currentValue);
      }
      if (changes.visible) {
        this.toggleVisibility(changes.visible.currentValue);
      }
    }
  }

  ngOnDestroy() {
    this.annotorious.destroy();
  }

  getAnnotationLabelClass(annotation: Annotation) {
    return {
      '--no-display':
        this.checkLabelIsOutOfBox(annotation) ||
        (this.editingActive && annotation.localId === this.selectedAnnotation?.localId),
      [`--${annotation.priority}`]: true,
    };
  }

  getAnnotationLabelStyle(annotation: Annotation) {
    const { localMinPx: min } = annotation;
    return {
      'margin-top': min?.y + 'px',
      'margin-left': min?.x + 'px',
      'max-width': this.htmlWrapper.offsetWidth - (min?.x || 0) - 2 + 'px',
    };
  }

  onSave() {
    if (this.editingForm?.valid) {
      this.annotations2dService.saveButton?.click();
    } else if (!this.editingForm?.controls?.title?.value.length) {
      this.toastrService.show(NO_TITLE_ERROR, FAILED, {
        ...WARNING_TOASTR_CONFIG,
      });
    }
  }

  onCancel() {
    this.annotations2dService.cancelButton?.click();
  }

  onDelete() {
    this.annotations2dService.deleteButton?.click();
  }

  isSelectedAnnotationSaved() {
    return (
      this.selectedAnnotation &&
      this.annotations.some((a) => a.localId === this.selectedAnnotation?.localId)
    );
  }

  // eslint-disable-next-line complexity
  updateAnnotationsUI = () => {
    const annotations = [...this.annotations];
    const annotoriousAnnotations = this.annotorious.getAnnotations() as AnnotoriousAnnotation[];
    if (this.selectedAnnotation && this.editingActive) {
      const tmpAnnotation = this.selectedAnnotation.copy();
      tmpAnnotation.priority = this.getChanges().priority;
      if (!this.isSelectedAnnotationSaved()) {
        tmpAnnotation.localId = 'undefined';
      }
      annotations.push(tmpAnnotation);
    }
    const viewBox = this.parentRef.querySelector('.a9s-annotationlayer');
    for (const ann of annotations) {
      const annotoriousAnn = annotoriousAnnotations.find(
        (a) => a.id === ann.localId || (ann.localId === 'undefined' && !a.id),
      );
      if (annotoriousAnn) {
        ann.updateUI(viewBox, this.htmlImage);
      }
    }
  };

  private toggleEditMode(value: boolean) {
    this.annotorious.readOnly = value;
    this.onCancel();
  }

  private toggleVisibility(value: boolean) {
    this.annotations2dService.toggleVisibility(this.htmlWrapper, value);
    this.updateAnnotationsUI();
  }

  private selectAnnotation = ({ id }: AnnotoriousAnnotation) => {
    const annotation = this.annotations.find((ann) => ann.localId === id);
    if (annotation) {
      this.selectedAnnotation = annotation.copy();
      this.openPopup();
    }
  };

  private createAnnotation = ({ id }: AnnotoriousAnnotation) => {
    if (this.selectedAnnotation) {
      const { min: mn, max: mx } = this.selectedAnnotation;
      const { title: t, description: d, priority: p } = this.getChanges();
      const annotation = this.annotations2dService.createAnnotation(mn, mx, t, d, p, id);
      this.annotations = this.annotations || [];
      this.annotations.push(annotation);
      this.closePopup();
      this.save.emit();
    }
  };

  private updateAnnotation = ({ id }: AnnotoriousAnnotation) => {
    if (this.selectedAnnotation) {
      const { min, max } = this.selectedAnnotation;
      const { title, description, priority } = this.getChanges();
      const annotation = this.annotations.find((ann) => ann.localId === id);
      if (annotation) {
        annotation.update(min, max, title, description, priority);
        this.closePopup();
        this.save.emit();
      }
    }
  };

  private resizeAnnotation = (target: AnnotoriousAnnotation['target']) => {
    const selector = target.selector.value;
    const [min, max] = getMinMaxFromSelector(selector, this.htmlImage);
    if (this.selectedAnnotation) {
      this.selectedAnnotation.min = min as Coordinates2;
      this.selectedAnnotation.max = max as Coordinates2;
    }
  };

  private deleteAnnotation = ({ id }: AnnotoriousAnnotation) => {
    const annotation = this.annotations.find((ann) => ann.localId === id);
    const index = this.annotations.indexOf(annotation as Annotation);
    if (index !== null) {
      this.annotations.splice(index, 1);
    }
    this.closePopup();
    this.save.emit();
  };

  private createSelection = ({ target }: AnnotoriousAnnotation) => {
    const { value: selector } = target.selector;
    const [min, max] = getMinMaxFromSelector(selector, this.htmlImage);
    this.selectedAnnotation = this.annotations2dService.createAnnotation(min, max);
    this.openPopup();
  };

  private cancelSelection = () => {
    this.closePopup();
  };

  private openPopup() {
    if (this.selectedAnnotation) {
      const annotoriousEditor = this.parentRef.querySelector('.r6o-editor');
      if (this.editingActive) {
        this.initForm();
      }
      this.annotations2dService.bindAnnotoriousControls(annotoriousEditor, this.editingActive);
      if (annotoriousEditor) {
        setTimeout(() => {
          if (this.editor) {
            this.annotations2dService.setEditorStyles(
              annotoriousEditor as HTMLElement,
              this.editor.nativeElement,
              this.parentRef,
            );
          }
        });
      }
      this.updateAnnotationsUI();
    }
  }

  private closePopup() {
    this.selectedAnnotation = null;
    this.editingForm = null;
    this.updateAnnotationsUI();
  }

  private getChanges() {
    const form = this.editingForm as FormGroup;
    const title = form.controls.title.value as string;
    const description = form.controls.description.value as string;
    const priority = form.controls.priority.value as AnnotationPriorities;
    return { title, description, priority };
  }

  private initForm() {
    const ann = this.selectedAnnotation as Annotation;
    this.editingForm = this.fb.group<AnnotationForm>({
      title: ann.title,
      description: ann.description,
      priority: ann.priority,
    });
    this.editingForm.controls.priority.valueChanges
      .pipe(takeWhile(() => this.editingActive))
      .subscribe(() => this.updateAnnotationsUI());
  }

  private checkLabelIsOutOfBox(annotation: Annotation) {
    const { localMinPx: min } = annotation;
    return (
      min &&
      (min.x < 0 ||
        min.y < 0 ||
        min.x > this.htmlWrapper.offsetWidth ||
        min.y > this.htmlWrapper.offsetHeight)
    );
  }
}
