import { EmptyOption } from '@App/app/configs/processing-settings-preset.config';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { IProcessingSettings } from '@App/app/entities/processing/processing-preset-settings.model';
import { PpSetupGroupNames } from '../../tabs/at-setup-tab/models/pp-setup-tab.enum';
import { ProcessingsPresetName } from '../../tabs/at-setup-tab/models/processing-preset.enum';

export const ppSettings = [
  {
    groupName: ProcessingsPresetName.SETTINGS_PRESET,
    settings: [
      {
        title: 'Select preset',
        formControlName: 'settingsPreset',
        inputType: 'select',
        options: [EmptyOption],
      },
    ],
  } as IProcessingSettings,
  {
    groupName: PpSetupGroupNames.POST_PROCESSING_SETTINGS,
    settings: [
      {
        title: 'Automapping',
        formControlName: 'runAutomapping',
        inputType: 'checkbox',
        description: 'Detects layers on the 3D model, using AI solution.',
        permissions: [UserPermissions.CAN_RUN_AUTOMAPPING],
        tooltipText: '',
      },
    ],
  },
];
