import { TemplateRef } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';

export default (rolesTpl: TemplateRef<any>, actionsTpl: TemplateRef<any>): TableColumn[] => [
  {
    prop: 'id',
    name: 'ID',
    flexGrow: 1.5,
  },
  {
    prop: 'firstName',
    name: 'First name',
    flexGrow: 3,
    cellClass: 'first-name',
  },
  {
    prop: 'lastName',
    name: 'Last name',
    flexGrow: 3,
  },
  {
    prop: 'email',
    name: 'E-mail',
    flexGrow: 4,
  },
  {
    prop: 'roles',
    name: 'Roles',
    cellTemplate: rolesTpl,
    flexGrow: 3,
  },
  {
    prop: 'id',
    name: '',
    cellTemplate: actionsTpl,
    cellClass: 'action',
    flexGrow: 1.5,
    sortable: false,
  },
];
