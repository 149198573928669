/* eslint-disable max-lines */
import { Box, Tube } from '@App/app/entities/babylon/nodes';
import { CustomScene } from '@App/app/entities/viewer/custom-scene.model';
import { Injectable } from '@angular/core';
import {
  Axis,
  CSG,
  Color3,
  Material,
  Mesh,
  MeshBuilder,
  Quaternion,
  Space,
  StandardMaterial,
  Vector3,
  VertexData,
} from 'babylonjs';
import { GridMaterial } from 'babylonjs-materials/grid/gridMaterial';
import {
  CAMERA_MESH_FACE,
  CAMERA_MESH_NAME,
  CAMERA_MESH_VERTEX,
  ELECTRIC_BLUE_COLOR3,
  ENGINE_BACKGROUND,
  MESH,
} from 'src/app/configs/babylon.config';
import { initLODSpheres } from '../../helpers/layers-helpers';
import { MaterialService } from '../material-service/material.service';
import { SceneService } from '../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export class BabylonNodesService {
  cameraMesh: Mesh | null;

  constructor(private materialService: MaterialService, private sceneService: SceneService) {}

  createBox(
    position: Vector3 | null,
    options: Box['options'],
    rotationQuaternion: Quaternion | null = null,
    name = MESH.box.defaultName,
    material = this.materialService.electricBlueMaterial,
  ) {
    const box = MeshBuilder.CreateBox(
      name,
      { ...MESH.box.options, ...options },
      this.sceneService.scene,
    );
    if (rotationQuaternion && !rotationQuaternion.equals(Quaternion.Zero())) {
      box.rotationQuaternion = rotationQuaternion;
    }
    box.material = material;
    if (position) {
      box.position = position.clone();
    }
    return box;
  }

  createSphere(
    position: Vector3 | null,
    name = MESH.sphere.defaultName,
    material: Material | StandardMaterial = this.materialService.electricBlueMaterial,
    options = MESH.sphere.options,
    initLod = true,
  ) {
    const sphere = MeshBuilder.CreateSphere(name, options, this.sceneService.scene);
    sphere.material = material;
    if (position) {
      sphere.position = position.clone();
    }
    sphere.renderingGroupId = MESH.sphere.renderGroupId;
    if (initLod) {
      initLODSpheres(
        sphere,
        this.sceneService.scene,
        this.materialService.electricBlueMaterial,
        MESH.sphere.lodContainerName,
        MESH.sphere.lodName,
      );
    }

    return sphere;
  }

  createTube(
    path: Vector3[],
    options: Tube['options'],
    name = MESH.tube.defaultName,
    material = this.materialService.electricBlueMaterial,
  ) {
    if (options.radiusInner && options.radiusOuter && options.radiusInner < options.radiusOuter) {
      const mOuter = MeshBuilder.CreateTube(
        MESH.tube.outerMeshName,
        {
          path,
          radius: options.radiusOuter,
          cap: Mesh.CAP_ALL,
        },
        this.sceneService.scene,
      );
      const mInner = MeshBuilder.CreateTube(
        MESH.tube.innerMeshName,
        {
          ...MESH.tube.options,
          path,
          radius: options.radiusInner,
        },
        this.sceneService.scene,
      );
      const outerCSG = CSG.FromMesh(mOuter);
      const innerCSG = CSG.FromMesh(mInner);
      const tubeCSG = outerCSG.subtract(innerCSG);
      const tube = tubeCSG.toMesh(name, material, this.sceneService.scene);
      mInner.dispose();
      mOuter.dispose();
      return tube;
    } else {
      const tube = MeshBuilder.CreateTube(
        name,
        {
          ...MESH.tube.options,
          path,
          radius: options.radiusOuter,
          cap: Mesh.CAP_ALL,
        },
        this.sceneService.scene,
      );
      tube.material = material;
      return tube;
    }
  }

  createLine(
    points: Vector3[],
    name: string | null = MESH.line.defaultName,
    color: Color3 | null = ELECTRIC_BLUE_COLOR3,
    options = MESH.line.options,
  ) {
    const centerLine = MeshBuilder.CreateLines(name || MESH.line.defaultName, {
      points,
      ...options,
    });
    if (color) {
      centerLine.color = color;
    }
    return centerLine;
  }

  createPlane(
    name = MESH.plane.defaultName,
    options = MESH.plane.options,
    material?: StandardMaterial | GridMaterial,
    direction?: Vector3,
  ) {
    const plane = MeshBuilder.CreatePlane(name, options, this.sceneService.scene);
    if (direction) {
      plane.setDirection(direction);
    }
    if (material) {
      plane.material = material;
    }
    return plane;
  }

  createCylinder(name = MESH.cylinder.defaultName, options = MESH.cylinder.options) {
    return MeshBuilder.CreateCylinder(name, options, this.sceneService.scene);
  }

  createCameraMesh(name: string, position: Vector3, quaternion: Quaternion) {
    if (!this.cameraMesh) {
      const cameraMesh = {
        vertex: CAMERA_MESH_VERTEX,
        face: CAMERA_MESH_FACE,
      };
      this.cameraMesh = this.createPolyhedron(cameraMesh, 1, this.sceneService.scene);
    }
    const result = this.cameraMesh.createInstance(name);
    result.enableEdgesRendering();
    result.edgesColor = ENGINE_BACKGROUND.color;
    result.position = position;
    result.rotationQuaternion = quaternion;
    result.rotate(Axis.Z, -(result.rotationQuaternion.toEulerAngles().z * 2), Space.LOCAL);
    return result;
  }

  disposeCameraMesh() {
    this.cameraMesh?.dispose();
    this.cameraMesh = null;
  }

  private createPolyhedron = (
    data: {
      vertex: number[][];
      face: number[][];
    },
    size: number,
    scene: CustomScene,
  ) => {
    const indices: number[] = [];
    const normals: number[] = [];
    let uvs: number[] = [];
    const faceUVS = [
      [0, 0],
      [1, 0],
      [1, 1],
      [0, 1],
    ];

    // positions
    const positions = data.vertex.flatMap((v) => [v[0] * size, v[1] * size, v[2] * size]);

    // indices from faces
    for (const face of data.face) {
      for (let j = 0; j < face.length; j++) {
        uvs = uvs.concat(faceUVS[j]);
      }
      for (let i = 0; i < face.length - 2; i++) {
        indices.push(face[0], face[i + 2], face[i + 1]);
      }
    }

    VertexData.ComputeNormals(positions, indices, normals);
    VertexData._ComputeSides(Mesh.BACKSIDE, positions, indices, normals, uvs);

    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.normals = normals;
    vertexData.uvs = uvs;

    const polygon = new Mesh(CAMERA_MESH_NAME, scene);
    vertexData.applyToMesh(polygon);

    const mat = new StandardMaterial('mat', this.sceneService.scene);
    mat.alpha = 0;
    polygon.material = mat;
    polygon.edgesWidth = 1;
    return polygon;
  };
}
