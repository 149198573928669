import { LAYER_EDITION_SUCCESS, SUCCESS } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { LayerHttpService } from '@App/app/engine/services/layer-services/layer-http-service/layer-http.service';
import { LayerUtilsService } from '@App/app/engine/services/layer-services/layer-utils-service/layer-utils.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_EVENTS, LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { httpLayer } from '@App/app/entities/layer/layer.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { LayersHttpActionsService } from '../../services/layers-http-actions-service/layers-http-actions.service';
import { LayersService } from '../../services/layers/layers.service';
import { editLayer, editLayerSuccess } from '../actions/layers.actions';
import { selectAllLayers } from '../selectors/layers.selectors';
import { updateLayer } from './../actions/layers.actions';

@Injectable()
export class EditLayerEffects {
  constructor(
    private actions: Actions,
    private layerEventsService: LayerEventsService,
    private layerHttpService: LayerHttpService,
    private layerUtilsService: LayerUtilsService,
    private layersService: LayersService,
    private store: Store,
    private viewerLayerService: ViewerLayerService,
    private _engineService: CustomerEngineService,
    private _toastrService: NbToastrService,
    private _layersHttpActionsService: LayersHttpActionsService,
  ) {}

  editLayer = createEffect(() => {
    return this.actions.pipe(
      ofType(editLayer),
      withLatestFrom(this.store.select(selectAllLayers)),
      mergeMap(([{ id, editedData, props }, layers]) => {
        const changes = editedData || this.viewerLayerService.getData(id);
        const updatedLayer = layers.find((layer) => layer.id === id);
        if (!updatedLayer) {
          return EMPTY;
        }
        const body = this.layerUtilsService.createRequestBody(updatedLayer);
        body.data = { ...changes };
        if (props) {
          props.forEach(
            (prop: { key: keyof (LayerUI | LayerUiExpandable); value: string | number }) => {
              (body[prop.key as keyof httpLayer] as typeof prop.value) = prop.value;
            },
          );
        }
        return this.layerHttpService.putBatchLayers([body]).pipe(
          tap(([layer]) => {
            if (layer.type === LAYER_TYPES.RECT_TUBE || layer.type === LAYER_TYPES.CUBOID) {
              this.layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
            }
          }),
          map(
            ([layer]) => {
              return editLayerSuccess({ layer });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  editLayerSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(editLayerSuccess),
      map(({ layer }) => {
        if (layer.type === LAYER_TYPES.SKETCH_PLANES) {
          this._layersHttpActionsService.updateSketchTools();
        }
        this.viewerLayerService.deleteLayer(layer.id);
        this.viewerLayerService.initViewerLayer(layer);
        this.viewerLayerService.showLayer(layer);
        this.layersService.setLoadingLayers([]);
        this.layersService.setRecalculatedLayers([]);
        this._engineService.activeCursorMove(false, false);
        this._toastrService.show(LAYER_EDITION_SUCCESS, SUCCESS, SUCCESS_TOASTR_CONFIG);
        return updateLayer({
          update: {
            id: layer.id,
            changes: { name: layer.name, data: layer.data, description: layer.description },
          },
        });
      }),
    );
  });
}
