import { AngleIron, AngleIronPartsArrays } from '@App/app/entities/layer/angle-iron.model';
import { ANGLE_IRON_PART, ANGLE_IRON_STRICT } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { AbstractMesh, Mesh, Plane, Vector3 } from 'babylonjs';
import { Subject } from 'rxjs';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { UtilsService } from '../../utils-service/utils.service';
import { AngleIronUtilsService } from './angle-iron-utils.service';

@Injectable({
  providedIn: 'root',
})
export class CreateAngleIronService {
  activePart: ANGLE_IRON_STRICT | null = null;
  highlightedSample: { part: ANGLE_IRON_PART; i: number } = { part: ANGLE_IRON_PART.NONE, i: -1 };
  samples: AngleIronPartsArrays<AbstractMesh | null> = { 1: [], 2: [], 3: [], 4: [] };

  private planes: {
    [ANGLE_IRON_PART.SIDE_1_OUTER]: Plane | null;
    [ANGLE_IRON_PART.SIDE_2_OUTER]: Plane | null;
  } = { 1: null, 3: null };
  private _angleIronDataChange$ = new Subject<void>();
  angleIronDataChange$ = this._angleIronDataChange$.asObservable();

  constructor(
    private angleIronUtilsService: AngleIronUtilsService,
    private nodeService: BabylonNodesService,
    private store: Store,
    private toastrService: NbToastrService,
    private utilsService: UtilsService,
  ) {}

  onClickAction() {
    const hit = this.utilsService.pickRay();
    if (hit?.pickedMesh && hit.pickedPoint) {
      this.createSamplePoint(hit.pickedPoint.clone());
      this._angleIronDataChange$.next();
    }
  }

  createSamplePoint(position: Vector3) {
    const sphere = this.nodeService.createSphere(position);
    if (this.activePart) {
      this.samples[this.activePart].push(sphere);
    }
  }

  deleteSamplePoint(part: ANGLE_IRON_STRICT, i: number) {
    this.samples[part][i]?.dispose();
    this.samples[part][i] = null;
    this.samples[part] = this.samples[part].filter((_, j) => j !== i);
    if (this.highlightedSample.part === part && this.highlightedSample.i === i) {
      this.highlightedSample = { part: ANGLE_IRON_PART.NONE, i: -1 };
    }
    this._angleIronDataChange$.next();
  }

  createAngleIron() {
    const z = Vector3.Zero();
    const sidePoints1 = this.samples[ANGLE_IRON_PART.SIDE_1_OUTER].map(
      (mesh) => mesh?.position || z,
    );
    const sidePoints2 = this.samples[ANGLE_IRON_PART.SIDE_2_OUTER].map(
      (mesh) => mesh?.position || z,
    );
    this.planes[ANGLE_IRON_PART.SIDE_1_OUTER] = Plane.FromPoints(
      sidePoints1[0],
      sidePoints1[1],
      sidePoints1[2],
    );
    this.planes[ANGLE_IRON_PART.SIDE_2_OUTER] = Plane.FromPoints(
      sidePoints2[0],
      sidePoints2[1],
      sidePoints2[2],
    );
    try {
      const side1 = this.samples[ANGLE_IRON_PART.SIDE_1_INNER][0];
      const side2 = this.samples[ANGLE_IRON_PART.SIDE_2_INNER][0];
      const plane1 = this.planes[ANGLE_IRON_PART.SIDE_1_OUTER];
      const plane2 = this.planes[ANGLE_IRON_PART.SIDE_2_OUTER];
      const { boxes, quaternion, length } = this.angleIronUtilsService.calculateAngleIronData(
        [side1 as Mesh, side2 as Mesh],
        [plane1 as Plane, plane2 as Plane],
      );
      const angleIron = new AngleIron();
      angleIron.name = `angleIron`;
      angleIron.isVisible = true;
      angleIron.isSaved = true;
      angleIron.data.absoluteRotationQuaternion = {
        x: quaternion.x,
        y: quaternion.y,
        z: quaternion.z,
        w: quaternion.w,
      };
      angleIron.data.boxes = [
        {
          min: { x: boxes[0].min.x, y: boxes[0].min.y, z: boxes[0].min.z },
          max: { x: boxes[0].max.x, y: boxes[0].max.y, z: boxes[0].max.z },
        },
        {
          min: { x: boxes[1].min.x, y: boxes[1].min.y, z: boxes[1].min.z },
          max: { x: boxes[1].max.x, y: boxes[1].max.y, z: boxes[1].max.z },
        },
      ];
      angleIron.data.length = +length.toFixed(5);
      this.store.dispatch(addLayer({ layer: angleIron as LayerUI, commit: false }));
    } catch (err) {
      this.utilsService.catchToolCreationError(err);
      this.clear();
    }
  }

  clear() {
    this.activePart = null;
    if (this.samples) {
      for (const part in this.samples) {
        if (
          this.samples[(part as any) as ANGLE_IRON_STRICT] &&
          this.samples[(part as any) as ANGLE_IRON_STRICT].length
        ) {
          this.removeSamplesOfPart((part as any) as ANGLE_IRON_STRICT);
        }
      }
      this.samples = { 1: [], 2: [], 3: [], 4: [] };
    }
    this.planes = { 1: null, 3: null };
    this.highlightedSample = { part: ANGLE_IRON_PART.NONE, i: -1 };
    this._angleIronDataChange$.next();
  }

  private removeSamplesOfPart(part: ANGLE_IRON_STRICT) {
    for (let i = 0; i < this.samples[part].length; i++) {
      this.samples[part][i]?.dispose();
      this.samples[part][i] = null;
    }
  }

  highlightSample(part: ANGLE_IRON_STRICT, i: number) {
    if (
      this.highlightedSample.part !== ANGLE_IRON_PART.NONE &&
      this.samples[this.highlightedSample.part].at(this.highlightedSample.i)
    ) {
      this.angleIronUtilsService.changeSampleMaterial(
        this.samples[this.highlightedSample.part][this.highlightedSample.i] as Mesh,
        true,
      );
    }
    if (this.highlightedSample.part === part && this.highlightedSample.i === i) {
      this.highlightedSample = { part: ANGLE_IRON_PART.NONE, i: -1 };
    } else {
      this.angleIronUtilsService.changeSampleMaterial(this.samples[part][i] as Mesh, false);
      this.highlightedSample = { part, i };
    }
    this._angleIronDataChange$.next();
  }

  setActivePart(part: ANGLE_IRON_STRICT) {
    this.activePart = this.activePart === part ? null : part;
    this._angleIronDataChange$.next();
  }
}
