import {
  ANGLE_IRON_PART,
  ANGLE_IRON_PART_NAME,
  CUBOID_PART,
  CUBOID_PART_NAME,
  RECT_TUBE_PART,
  RECT_TUBE_PART_NAME,
  TUBE_PART,
  TUBE_PART_NAME,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbstractMesh } from 'babylonjs';

type PartType = TUBE_PART | CUBOID_PART | RECT_TUBE_PART | ANGLE_IRON_PART;

@UntilDestroy()
@Component({
  selector: 'app-manual-tool-layer-details',
  templateUrl: './manual-tool-details.component.html',
  styleUrls: ['./manual-tool-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManualToolLayerDetailsComponent {
  @Output() cancel = new EventEmitter();
  @Output() generate = new EventEmitter();
  @Output() setPartActive = new EventEmitter();
  @Output() deleteSample = new EventEmitter();
  @Output() highlightSample = new EventEmitter();

  @Input() sampleHighlighted: { part: number | null; i: number };
  @Input() generateAvailable: boolean;
  @Input() partName:
    | typeof TUBE_PART_NAME
    | typeof CUBOID_PART_NAME
    | typeof RECT_TUBE_PART_NAME
    | typeof ANGLE_IRON_PART_NAME;
  @Input() samples:
    | Partial<Record<TUBE_PART, AbstractMesh[]>>
    | Partial<Record<CUBOID_PART, AbstractMesh[]>>
    | Partial<Record<RECT_TUBE_PART, AbstractMesh[]>>
    | Partial<Record<ANGLE_IRON_PART, AbstractMesh[]>>;

  @Input() activePart: PartType | null;
  constructor() {}

  toggleHighlightSample(part: PartType, i: number) {
    this.highlightSample.emit({ part, i });
  }

  onDeleteSample(part: PartType, i: number) {
    this.deleteSample.emit({ part, i });
  }

  onGenerate() {
    this.generate.emit();
  }

  onCancel() {
    this.cancel.emit();
  }

  setActivePart(part: PartType) {
    this.setPartActive.emit({ part });
  }
}
