<div class="camera_controls">
  <div
    class="camera_controls__wrapper"
    *ngIf="showCameraControlsTab$ | async; else closedControlsButton"
  >
    <div class="camera_controls__header">
      <div class="camera_controls__title select-none">Controls</div>
      <nb-icon
        class="camera_controls__close"
        data-testId="camera-controls-close-btn"
        icon="close"
        (click)="toggleCameraControls(false)"
      ></nb-icon>
    </div>
    <div class="camera_controls__body">
      <div class="camera_controls__section">
        <nb-toggle
          class="camera_controls__toggle select-none"
          [(checked)]="imperialUnits"
          (checkedChange)="onUnitsChange($event)"
          status="control"
        >
          Imperial units
        </nb-toggle>
        <nb-toggle
          *ngIf="isLODRegistered$ | async"
          class="camera_controls__toggle select-none"
          (checkedChange)="onShowOnlyHQModelChange($event)"
          [(checked)]="shouldShowOnlyHQModel"
          status="control"
        >
          Show only HQ Model
        </nb-toggle>
        <div class="separate_line"></div>
      </div>
      <div class="camera_controls__section" *ngIf="!isProd">
        <div class="camera_controls__label">Heap usage: {{ heapSizeReport$ | async }}%</div>
        <div class="camera_controls__label">
          Tiles cache: {{ (totalCacheSize$ | async)?.total }} MB ({{
            (totalCacheSize$ | async)?.percentage
          }}%)
        </div>
        <div class="camera_controls__label select-none">Slice range</div>
        <div class="camera_controls__slice_range">
          <!-- MEMORY LEAK -->
          <input
            type="number"
            class="camera_controls__slice_input"
            nbInput
            fieldSize="tiny"
            min="0"
            [max]="tilesInfo.tilesNumber"
            [value]="tilesRange[0]"
            (input)="onSliceRangeChange($event, 0)"
          />
          <input
            type="number"
            class="camera_controls__slice_input"
            nbInput
            fieldSize="tiny"
            min="0"
            [max]="tilesInfo.tilesNumber"
            [value]="tilesRange[1]"
            (input)="onSliceRangeChange($event, 1)"
          />
        </div>
      </div>
      <div class="camera_controls__section" *ngIf="!isProd">
        <button
          [disabled]="!isReadyToLoadModel() || isModelLoaded"
          class="camera_controls__load_button"
          nbButton
          size="tiny"
          status="info"
          (click)="onLoadModel()"
        >
          Load model
        </button>
        <div class="separate_line"></div>
      </div>
      <div class="camera_controls__section">
        <app-color-picker
          data-testId="camera-controls-background-picker-input"
          [color]="backgroundSceneColor"
          (changeColor)="acceptColor($event)"
        ></app-color-picker>
        Background color
      </div>
      <div *ngIf="photoMode">
        <div class="camera_controls__section">
          <app-color-picker
            data-testId="camera-controls-viewfinder-picker-input"
            [color]="viewfinderColor"
            (changeColor)="changeColorMesh($event)"
          ></app-color-picker>
          Viewfinder color
        </div>
        <div class="camera_controls__section__viewfinder_size">
          <div class="camera_controls__label select-none">Viewfinder size</div>
          <div class="camera_controls__slider">
            <div class="camera_controls__slider_value select-none">{{ sizeMesh }}</div>
            <ng5-slider
              [options]="viewfinderOptions"
              [value]="sizeMesh"
              (valueChange)="changeViewfinderSize($event)"
            ></ng5-slider>
          </div>
        </div>
      </div>
      <app-color-picker-presets
        (setBackgroundPreset)="setBackgroundPreset($event)"
        (setViewfinderPreset)="setViewfinderPreset($event)"
      ></app-color-picker-presets>
      <div
        class="camera_controls__section"
        *ngxPermissionsOnly="permissions.CAN_CREATE_COLOR_PRESET"
      >
        <button
          size="tiny"
          status="info"
          class="camera_controls__preset_button"
          (click)="openCustomPresetPopup()"
          nbButton
        >
          Create new preset
        </button>
        <div class="separate_line"></div>
      </div>

      <div class="camera_controls__section">
        <div class="camera_controls__label select-none">Camera speed</div>
        <div class="camera_controls__slider">
          <div class="camera_controls__slider_value select-none">{{ cameraSpeed }}</div>
          <ng5-slider
            [(value)]="cameraSpeed"
            [options]="cameraOptions"
            (userChangeEnd)="onCameraSpeedChange($event)"
          ></ng5-slider>
        </div>
      </div>
      <div class="camera_controls__section">
        <button
          class="camera_controls__button camera_controls_button_big"
          size="tiny"
          status="info"
          nbButton
          (click)="onCameraChange()"
          *ngIf="roi$ | async"
        >
          Set Camera on Region Of Interest
        </button>
      </div>
      <div class="camera_controls__section">
        <button
          [disabled]="isChangeCameraButtonEnabled$ | async"
          nbButton
          class="camera_controls__button"
          size="tiny"
          status="info"
          (click)="changeCameraMode()"
        >
          Change camera mode
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #closedControlsButton>
  <button
    class="camera_controls__btn"
    data-testId="camera-controls-closed-btn"
    nbButton
    ghost
    shape="round"
    status="control"
    (click)="toggleCameraControls(true)"
  >
    <nb-icon icon="options-2-outline"></nb-icon>
  </button>
</ng-template>
