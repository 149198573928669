import { EmptyOption } from '@App/app/configs/processing-settings-preset.config';
import { IProcessingSettings } from '@App/app/entities/processing/processing-preset-settings.model';
import {
  AtSettingsDescription,
  AtSetupGroupNames,
} from '../../tabs/at-setup-tab/models/at-setup-tab.enum';
import { ProcessingsPresetName } from '../../tabs/at-setup-tab/models/processing-preset.enum';

const policesOptions = () => [
  { title: 'Compute', value: 'compute' },
  { title: 'Adjust', value: 'adjust' },
  { title: 'Keep', value: 'keep' },
];

export const atSettings = [
  {
    groupName: AtSetupGroupNames.GROUND_CONTROL_POINTS,
    settings: [
      {
        title: 'Use ground control points',
        formControlName: 'useGCP',
        inputType: 'checkbox',
      },
    ],
  },
  {
    groupName: ProcessingsPresetName.SETTINGS_PRESET,
    settings: [
      {
        title: 'Select preset',
        formControlName: 'settingsPreset',
        inputType: 'select',
        options: [EmptyOption],
      },
    ],
  } as IProcessingSettings,
  {
    groupName: AtSetupGroupNames.GENERAL_SETTINGS,
    settings: [
      {
        title: 'Key points density',
        formControlName: 'keyPointsDensity',
        settingsDescription: AtSettingsDescription.KEY_POINTS_DENSITY,
        inputType: 'select',
        options: [
          { title: 'Normal', value: 'normal' },
          { title: 'High', value: 'high' },
        ],
      },
      {
        title: 'Pair selection mode',
        formControlName: 'pairSelectionMode',
        settingsDescription: AtSettingsDescription.PAIR_SELECTION_MODE,
        inputType: 'select',
        options: [
          { title: 'Exhaustive', value: 'exhaustive' },
          { title: 'Loop', value: 'loop' },
          { title: 'Sequence', value: 'sequence' },
          { title: 'Default', value: 'default' },
          { title: 'Similar images', value: 'similarImagesOnly' },
        ],
      },
      {
        title: 'Component construction mode',
        formControlName: 'componentConstructionMode',
        settingsDescription: AtSettingsDescription.COMPONENT_CONSTRUCTION_MODE,
        inputType: 'select',
        options: [
          { title: 'One Pass', value: 'onePass' },
          { title: 'Multi Pass', value: 'multiPass' },
        ],
      },
      {
        title: 'Blockwise color equalization',
        formControlName: 'colorEqualizationPreprocessing',
        settingsDescription: AtSettingsDescription.BLOCKWISE_COLOR_EQUALIZATION,
        inputType: 'select',
        options: [
          { title: 'None', value: 'none' },
          { title: 'Blockwise', value: 'blockwise' },
        ],
      },
    ],
  },
  {
    groupName: AtSetupGroupNames.ESTIMATION_POLICE,
    settings: [
      {
        title: 'Tie points',
        formControlName: 'tiePointsPolicy',
        settingsDescription: AtSettingsDescription.TIE_POINTS,
        inputType: 'select',
        options: policesOptions(),
      },
      {
        title: 'Rotation',
        formControlName: 'rotationPolicy',
        settingsDescription: AtSettingsDescription.ROTATION,
        inputType: 'select',
        options: policesOptions(),
      },
      {
        title: 'Center',
        formControlName: 'centerPolicy',
        settingsDescription: AtSettingsDescription.CENTER,
        inputType: 'select',
        options: policesOptions(),
      },
      {
        title: 'Optical properties estimation mode',
        formControlName: 'photogroupEstimationMode',
        settingsDescription: AtSettingsDescription.OPTICAL_ESTIMATION_MODE,
        inputType: 'select',
        options: [
          { title: 'One Pass', value: 'onePass' },
          { title: 'Multi Pass', value: 'multiPass' },
        ],
      },
      {
        title: 'Focal length',
        formControlName: 'focalPolicy',
        settingsDescription: AtSettingsDescription.FOCAL_LENGTH,
        inputType: 'select',
        options: policesOptions(),
      },
      {
        title: 'Principal point',
        formControlName: 'principalPolicy',
        settingsDescription: AtSettingsDescription.PRINCIPAL_POINT,
        inputType: 'select',
        options: policesOptions(),
      },
      {
        title: 'Radial distortion',
        formControlName: 'radialPolicy',
        settingsDescription: AtSettingsDescription.RADIAL_DISTORTION,
        inputType: 'select',
        options: policesOptions(),
      },
      {
        title: 'Tangential distortion',
        formControlName: 'tangentialPolicy',
        settingsDescription: AtSettingsDescription.TANGENTIAL_DISTORTION,
        inputType: 'select',
        options: policesOptions(),
      },
      {
        title: 'Aspect Ratio',
        formControlName: 'aspectRatioPolicy',
        settingsDescription: AtSettingsDescription.ASPECT_RATIO,
        inputType: 'select',
        options: policesOptions(),
      },
      {
        title: 'Skew',
        formControlName: 'skewPolicy',
        settingsDescription: AtSettingsDescription.SKEW,
        inputType: 'select',
        options: policesOptions(),
      },
    ],
  },
  {
    groupName: AtSetupGroupNames.ADDITIONAL_DATA,
    settings: [
      {
        title: 'Block type',
        formControlName: 'blockType',
        settingsDescription: AtSettingsDescription.BLOCK_TYPE,
        inputType: 'select',
        options: [
          { title: 'Generic', value: 'generic' },
          { title: 'Nadir', value: 'nadir' },
          { title: 'Aerial', value: 'aerial' },
          { title: 'Orbit', value: 'orbit' },
        ],
      },
    ],
  },
];
