import { AuthService } from '@App/app/auth/auth.service';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ModelsProcessingService } from '../services/models-processing-service/models-processing.service';
import { loadCurrentProcess } from '../store/processes/actions/processes.actions';

@UntilDestroy()
@Component({
  selector: 'app-model-processing-details',
  templateUrl: './model-processing-details.component.html',
  styleUrls: [
    './model-processing-details.component.scss',
    '../../../shared/components/model-site-header/model-site-header.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ModelProcessingDetailsComponent implements OnInit, OnDestroy {
  private buildProcessId: number;
  buildProcess: BuildProcess;
  companyId: number;
  loading: boolean;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private modelsProcessingService: ModelsProcessingService,
    private store: Store,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.authService.currentEndpoint$.pipe(untilDestroyed(this)).subscribe((endpoint) => {
      if (endpoint) {
        this.companyId = endpoint.id;
      }
    });

    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.buildProcessId = +params['id'];
      this.loadProcess();
    });
  }

  ngOnDestroy(): void {
    window.stop();
  }

  goBack() {
    this.location.back();
  }

  private loadProcess() {
    this.loading = true;
    this.store.dispatch(loadCurrentProcess({ id: this.buildProcessId }));
    this.modelsProcessingService.currentBuildProcess
      .pipe(
        filter((val) => !!val?.id),
        take(1),
      )
      .subscribe((process: BuildProcess) => {
        this.buildProcess = process;
        this.loading = false;
      });
  }
}
