<div class="container">
  <div class="sidebar" [style.width]="sidebarWidthPx + 'px'">
    <div class="sidebar__section sidebar__description">
      <nb-card data-testId="photo-dialog-description">
        <nb-card-header>Description</nb-card-header>
        <nb-card-body>
          Use precision crosshair to select the location of the control point in the image. Use the
          magnified view for extra precision
        </nb-card-body>
      </nb-card>
    </div>
    <div class="sidebar__section sidebar__rect_overview">
      <nb-card data-testId="photo-dialog-overview">
        <nb-card-header>Preview</nb-card-header>
        <nb-card-body>
          <ng-container *ngIf="target && !loading; else noTarget">
            <ng-container *ngIf="croppingImageData | async | imgRectOverviewSrc: target as url">
              <div class="sidebar__rect_overview__container">
                <div class="sidebar__rect_overview__viewfinder">
                  <nb-icon icon="plus-outline"></nb-icon>
                </div>
                <img
                  class="sidebar__rect_overview__image"
                  data-testId="photo-dialog-cropped-image"
                  [src]="url"
                />
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noTarget>
            <div class="sidebar__rect_overview__no_point">
              <span>No point selected</span>
            </div>
          </ng-template>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="sidebar__section sidebar__actions">
      <nb-card data-testId="photo-dialog-actions">
        <nb-card-header>Actions</nb-card-header>
        <nb-card-body>
          <button
            nbButton
            status="success"
            data-testId="photo-dialog-save-btn"
            [disabled]="!target"
            (click)="onClose()"
          >
            <nb-icon icon="checkmark-outline"></nb-icon>
            <span>Confirm</span>
          </button>
          <button
            nbButton
            status="warning"
            data-testId="photo-dialog-clear-btn"
            [disabled]="!target"
            (click)="removeTarget()"
          >
            <nb-icon icon="close-outline"></nb-icon>
            <span>Remove</span>
          </button>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
  <nb-card class="photo_card_zoom" [ngClass]="{ '--large-displaying': !loading }">
    <nb-card-header class="photo_card_zoom__header">
      <span class="photo_card_zoom__header__title">{{ photo.name }}</span>
      <nb-icon
        nbTooltip="Close"
        icon="close-outline"
        class="photo_card_zoom__header__button"
        (click)="onClose()"
        data-testId="close-btn"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body
      class="photo_card_zoom__photo"
      data-testId="photo-dialog-photo-container"
      [nbSpinner]="loading"
    >
      <div class="photo_card_zoom__photo__container">
        <div #wrapper class="photo_card_zoom__photo__wrapper" data-testId="wrapper">
          <img #wrapperImg [src]="photo.downloadURL" class="--dark" alt="enlarge site photo" />
        </div>
        <app-target-photo-dialog
          *ngIf="target && !loading"
          #target
          class="photo_card_zoom__photo__target"
        ></app-target-photo-dialog>
      </div>
      <app-photo-zoom-legend
        leftMouseButtonActionLabel="Pick target"
        class="photo_card_zoom__photo__legend"
        data-testId="legend"
      ></app-photo-zoom-legend>
    </nb-card-body>
  </nb-card>
</div>
