/* eslint-disable rxjs/no-unsafe-switchmap */
/* eslint-disable ngrx/no-dispatch-in-effects */
import { updateUserUiSettingsLocally } from '@App/app/auth/store/auth/actions/auth.actions';
import { selectUiSettings } from '@App/app/auth/store/auth/selectors/auth.selectors';
import { USER_DATA } from '@App/app/configs/auth.config';
import {
  FAILED,
  PRESET_CREATE_FAIL,
  PRESET_CREATE_SUCCESS,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { DEFAULT_PRESETS } from '@App/app/configs/viewer.config';
import { AuthUser } from '@App/app/entities/users/user';
import { UsersHttpService } from '@App/app/pages/users/users-http.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  acceptDocuments,
  addCustomPreset,
  addCustomPresetsFail,
  deletePreset,
  loadAllPresets,
  loadAllPresetsSuccess,
  updateCurrentBGColor,
  updateCurrentVFColor,
  updatePageOffset,
} from '../actions/ui-settings.actions';

@Injectable()
export class UiSettingsEffects {
  constructor(
    private actions: Actions,
    private toastrService: NbToastrService,
    private usersHttpService: UsersHttpService,
    private store: Store,
  ) {}

  loadAllPresets = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAllPresets),
      map(() => {
        const defaultPresets = DEFAULT_PRESETS;

        const user = JSON.parse(localStorage.getItem(USER_DATA) as string) as AuthUser | undefined;
        const customPresets =
          user?.uiSettings?.presets?.map((preset) => ({ ...preset, default: false })) || [];

        const allPresets = defaultPresets.concat(customPresets || []);
        return loadAllPresetsSuccess({ presets: allPresets });
      }),
    );
  });

  addCustomPreset = createEffect(() => {
    return this.actions.pipe(
      ofType(addCustomPreset),
      switchMap(({ preset }) => this._withPreviousSettings({ preset })),
      mergeMap(({ preset, allUiSettings }) => {
        const newCustomPresets = [...(allUiSettings?.presets || []), preset];
        const newUiSettings = { ...allUiSettings, presets: [...newCustomPresets] };
        return this.usersHttpService
          .putUserUiSettings(newUiSettings)
          .pipe(map(() => updateUserUiSettingsLocally({ uiSettings: newUiSettings })))
          .pipe(
            tap(() => {
              this.toastrService.show(PRESET_CREATE_SUCCESS, SUCCESS, {
                ...SUCCESS_TOASTR_CONFIG,
              });
            }),
          );
      }),
    );
  });

  addCustomPresetsFail = createEffect(
    () => {
      return this.actions.pipe(
        ofType(addCustomPresetsFail),
        tap(() => {
          this.toastrService.show(PRESET_CREATE_FAIL, FAILED, { ...WARNING_TOASTR_CONFIG });
        }),
      );
    },
    { dispatch: false },
  );

  deletePreset = createEffect(() => {
    return this.actions.pipe(
      ofType(deletePreset),
      switchMap(({ name }) => this._withPreviousSettings({ name })),
      mergeMap(({ name, allUiSettings }) => {
        const newCustomPresets = (allUiSettings?.presets ? allUiSettings.presets : []).filter(
          (preset) => preset.name !== name,
        );
        const newUiSettings = { ...allUiSettings, presets: [...newCustomPresets] };
        return this.usersHttpService
          .putUserUiSettings(newUiSettings)
          .pipe(map(() => updateUserUiSettingsLocally({ uiSettings: newUiSettings })));
      }),
    );
  });

  updatePageOffset = createEffect(() => {
    return this.actions.pipe(
      ofType(updatePageOffset),
      switchMap(({ pageOffset }) => this._withPreviousSettings({ pageOffset })),
      mergeMap(({ pageOffset, allUiSettings }) => {
        const newUiSettings = { ...allUiSettings, pageOffset };
        return this.usersHttpService
          .putUserUiSettings(newUiSettings)
          .pipe(map(() => updateUserUiSettingsLocally({ uiSettings: newUiSettings })));
      }),
    );
  });

  updateCurrentBGColor = createEffect(() => {
    return this.actions.pipe(
      ofType(updateCurrentBGColor),
      debounceTime(700),
      switchMap(({ backgroundColor }) => this._withPreviousSettings({ backgroundColor })),
      mergeMap(({ backgroundColor, allUiSettings }) => {
        const newUiSettings = {
          ...allUiSettings,
          currentPreset: {
            ...allUiSettings?.currentPreset,
            backgroundColor,
          },
        };
        return this.usersHttpService
          .putUserUiSettings(newUiSettings)
          .pipe(map(() => updateUserUiSettingsLocally({ uiSettings: newUiSettings })));
      }),
    );
  });

  updateCurrentVFColor = createEffect(() => {
    return this.actions.pipe(
      ofType(updateCurrentVFColor),
      debounceTime(700),
      switchMap(({ viewFinderColor }) => this._withPreviousSettings({ viewFinderColor })),
      mergeMap(({ viewFinderColor, allUiSettings }) => {
        const newUiSettings = {
          ...allUiSettings,
          currentPreset: {
            ...allUiSettings?.currentPreset,
            viewFinderColor,
          },
        };
        return this.usersHttpService
          .putUserUiSettings(newUiSettings)
          .pipe(map(() => updateUserUiSettingsLocally({ uiSettings: newUiSettings })));
      }),
    );
  });

  acceptDocuments = createEffect(() => {
    return this.actions.pipe(
      ofType(acceptDocuments),
      switchMap(() => this._withPreviousSettings({})),
      mergeMap(({ allUiSettings }) => {
        const newUiSettings = { ...allUiSettings, hasDocsAccepted: true };
        return this.usersHttpService
          .putUserUiSettings(newUiSettings)
          .pipe(map(() => updateUserUiSettingsLocally({ uiSettings: newUiSettings })));
      }),
    );
  });

  private _withPreviousSettings = <T>(props: T) => {
    return this.store.select(selectUiSettings).pipe(
      first(),
      // eslint-disable-next-line ngrx/avoid-mapping-selectors
      map((allUiSettings) => ({ allUiSettings, ...props })),
    );
  };
}
