import { loadRoiLimits } from '@App/app/engine/store/actions/limits.actions';
import { Model } from '@App/app/entities/models/model.model';
import { ModelsSitesService } from '@App/app/pages/sites/shared/site-services/models-sites.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ModelsHttpService } from '../../../services/models-http-service/models-http.service';
import {
  loadAllModels,
  loadAllModelsSuccess,
  loadAllModelsWithThumbnails,
} from '../actions/models.actions';

@Injectable()
export class LoadModelsEffects {
  constructor(
    private actions: Actions,
    private modelsHttpService: ModelsHttpService,
    private sitesService: ModelsSitesService,
  ) {}

  loadAllModels = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAllModels, loadAllModelsWithThumbnails),
      mergeMap(() => {
        return this.modelsHttpService.getModels().pipe(
          map(
            (modelsResp: Model[]) => {
              this.sitesService.loading.next(false);
              modelsResp.sort((a: Model, b: Model) => {
                return +new Date(b.editedAt || b.createdAt) - +new Date(a.editedAt || a.createdAt);
              });
              return loadAllModelsSuccess({
                models: modelsResp,
                totalCount: modelsResp.length,
              });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  loadRoiLimits = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAllModels),
      map(() => loadRoiLimits()),
    );
  });
}
