import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Photo } from '@App/app/entities/files/files.model';
import { DownloadPhotoService } from '@App/app/shared/download-photo.service';

@Component({
  selector: 'app-model-photo-card',
  templateUrl: './model-photo-card.component.html',
  styleUrls: ['./model-photo-card.component.scss'],
})
export class ModelPhotoCardComponent implements OnInit {
  @Input() photo: Photo;
  @Input() isSelected: boolean;
  @Output() photoClick: EventEmitter<Photo> = new EventEmitter();
  @Output() enlargePhoto: EventEmitter<Photo> = new EventEmitter();
  downloading = false;
  permissions = UserPermissions;

  constructor(private downloadPhotoService: DownloadPhotoService, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onClick(clickedPhoto: Photo) {
    this.photoClick.emit(clickedPhoto);
  }

  onEnlargePhoto(event: MouseEvent, photo: Photo) {
    event.stopPropagation();
    this.enlargePhoto.emit(photo);
  }

  async onDownload(event: MouseEvent, photo: Photo) {
    event.stopPropagation();
    if (!this.downloading) {
      this.downloading = true;
      await this.downloadPhotoService.downloadPhoto(photo);
      this.downloading = false;
      this.ref.detectChanges();
    }
  }
}
