import { CustomLODService } from '@App/app/engine/services/custom-lod-service/custom-lod.service';
import { BaseEngineService } from '@App/app/engine/services/engine-services/base-engine-service/base-engine.service';
import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { ProcessingEngineService } from '@App/app/engine/services/engine-services/processing-engine-service/processing-engine.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { LAYER_EVENTS } from '@App/app/entities/layer/enums/layer-types.enum';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { VIEWER_MODE, VIEWER_TYPE } from '@App/app/entities/viewer/viewer.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { CypressService } from '@App/app/shared/services/cypress/cypress.service';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PointerEventTypes } from 'babylonjs';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MODEL_MESH_NAME } from 'src/app/configs/babylon.config';
import { LayersService } from 'src/app/pages/viewer/services/layers/layers.service';
import { LayerEventsService } from '../../engine/services/layer-services/layer-events-service/layer-events.service';
import { PhotoMarkerService } from '../../engine/services/photo-marker-service/photo-marker.service';
import { SceneService } from '../../engine/services/scene-service/scene.service';
import { ModelsProcessingService } from '../processing/services/models-processing-service/models-processing.service';
import { ProcessingService } from './processing/processing.service';
import { AtSetupControlPointsService } from './processing/tabs/at-setup-tab/at-setup-control-points/services/at-setup-control-points-service/at-setup-control-points.service';
import { AtSetupTabs } from './processing/tabs/at-setup-tab/models/at-setup-tab.enum';
import { AtSetupTabService } from './processing/tabs/at-setup-tab/services/at-setup-tab/at-setup-tab.service';
import { ActiveLayerToolService } from './services/active-layer-tool/active-layer-tool.service';
import { PhotosService } from './services/photos/photos.service';
import { TiePointsService } from './services/tie-points/tie-points.service';

@UntilDestroy()
@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [AtSetupControlPointsService],
})
export class ViewerComponent implements OnInit, AfterViewChecked {
  photoMode: boolean;
  engineService: BaseEngineService;
  activeMode = VIEWER_MODE.LAYERS_MODE;
  VIEWER_MODE = VIEWER_MODE;
  viewerType: VIEWER_TYPE;
  VIEWER_TYPE = VIEWER_TYPE;
  tiePointsLoading$: Observable<boolean>;
  tiePointsModeActive = false;
  photoMarkerActive$: Observable<boolean>;
  tiePointAccessible$: Observable<boolean>;
  showTools = true;
  targetIcon = '../../assets/svg/icons/sf_portal-ui-icon-target.svg';
  targetIconCrossed = '../../assets/svg/icons/sf_portal-ui-icon-target-crossed.svg';
  cursorIcon = '../../assets/svg/icons/sf_portal-ui_icon_cursor.svg';
  cursorIconCrossed = '../../assets/svg/icons/sf_portal-ui_icon_cursor_crossed.svg';
  controlPointActive: Observable<boolean>;
  showGcpLegend$: Observable<boolean>;
  permissions = UserPermissions;
  tilesLoadingProgress$: Observable<number | null>;

  constructor(
    private activeLayerToolService: ActiveLayerToolService,
    private layerEventsService: LayerEventsService,
    private layersService: LayersService,
    private route: ActivatedRoute,
    private photosService: PhotosService,
    private sceneService: SceneService,
    private tiePointsService: TiePointsService,
    private photoMarkerService: PhotoMarkerService,
    private modelsProcessingService: ModelsProcessingService,
    private atSetupTabService: AtSetupTabService,
    private broadcastService: BroadcastService,
    private cdr: ChangeDetectorRef,
    private processingService: ProcessingService,
    private injector: Injector,
    private customLODService: CustomLODService,
    private cypressService: CypressService,
  ) {
    this.tiePointsLoading$ = this.tiePointsService.tiePointsLoading$;
    this.tiePointAccessible$ = this.tiePointsService.tiePointAccessible$;
    this.showGcpLegend$ = this.processingService.activeStep$.pipe(
      map((step) => {
        const process = this.modelsProcessingService.getCurrentBuildProcess();
        const url = process?.gcpList?.downloadURL;
        const stepsUsingGCP: number[] = [ProcessingSteps.AT_REVIEW, ProcessingSteps.PROD_SETUP];
        return !!(url && stepsUsingGCP.includes(step));
      }),
    );
    this.tiePointsService.tiePointsModeActive$.pipe(untilDestroyed(this)).subscribe((active) => {
      this.tiePointsModeActive = active;
    });
    this.photoMarkerActive$ = this.photoMarkerService.markerActive$;
    this.modelsProcessingService.currentBuildProcess
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((buildProcess: BuildProcess) => {
        this.showTools = !buildProcess.steps.upload.isCurrent();
      });
    this.controlPointActive = this.atSetupTabService.activeTab$.pipe(
      map((tab) => tab === AtSetupTabs.CONTROL_POINTS),
    );
    this.broadcastService
      .on(EVENT_TYPE.LOAD_MODEL)
      .pipe(untilDestroyed(this))
      .pipe(filter(() => this.activeMode === VIEWER_MODE.PHOTOS_MODE))
      .subscribe(() => this.changeMode(VIEWER_MODE.LAYERS_MODE));

    this.customLODService.registerOnStartCallback(() => {
      this.tilesLoadingProgress$ = this.customLODService.totalProgressChange$;
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
    this.cypressService.fixLoaderIfNeeded();
  }

  changeMode(value: VIEWER_MODE): void {
    this.activeMode = value;
    this.photosService.setPhotoActiveMode(value === VIEWER_MODE.PHOTOS_MODE);
    this.photosService.toggleCameraPoints(value === VIEWER_MODE.PHOTOS_MODE);
    if (value === VIEWER_MODE.PHOTOS_MODE) {
      this.photoMode = true;
      this.layersService.clearDetailsViewLayers();
      this.layersService.setDetailsViewLayers([]);
      this.layersService.editedLayers = [];
      this.layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
      this.activeLayerToolService.setActiveTool();
      this.sceneService.scene.onPointerObservable.add((pointerInfo) => {
        const pickedPoint = pointerInfo.pickInfo?.pickedPoint;
        const pickedModel = pointerInfo.pickInfo?.pickedMesh?.name.startsWith(MODEL_MESH_NAME);
        if (pointerInfo.type === PointerEventTypes.POINTERTAP && pickedPoint && pickedModel) {
          this.tiePointsService.onClickModelHandler(pickedPoint);
        }
      });
    } else {
      this.photoMode = false;
      this.tiePointsService.setTiePointsModeActive(false);
    }
  }

  toggleMarkerVisibility() {
    this.photoMarkerService.toggleMarkerVisibility();
  }

  toggleTiePointsMode() {
    this.tiePointsService.setTiePointsModeActive(!this.tiePointsModeActive);
  }

  ngOnInit(): void {
    if (
      this.route.snapshot.data['viewerType'] &&
      Object.values(VIEWER_TYPE).includes(this.route.snapshot.data['viewerType'])
    ) {
      this.viewerType = this.route.snapshot.data['viewerType'];
    } else {
      this.viewerType = VIEWER_TYPE.CUSTOMER;
    }
    this.setEngineService();
    this.engineService.postInit();
  }

  private setEngineService() {
    this.engineService =
      this.viewerType === VIEWER_TYPE.CUSTOMER
        ? this.injector.get(CustomerEngineService)
        : this.injector.get(ProcessingEngineService);
    this.engineService.queryParams$ = this.route.queryParams;
  }
}
