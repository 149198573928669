import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { LayerClasses } from '@App/app/entities/layer/layer-classes.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayersService } from '../../../services/layers/layers.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
})
export class FolderComponent {
  @Input() folder: LayerUI | LayerUiExpandable;
  @Input() isAnyGuiVisible: boolean;
  @Input() isFolderVisible: boolean;
  @Input() classes: LayerClasses;
  @Input() layerPath: string;
  @Input() showExpandableArrow = true;
  @Output() selectFolder: EventEmitter<LayerUI | LayerUiExpandable> = new EventEmitter();
  @Output() toggleAllGuiFolder: EventEmitter<boolean> = new EventEmitter();
  @Output() expand: EventEmitter<LayerUiExpandable> = new EventEmitter();
  @Output() clickLayerPath: EventEmitter<LayerUiExpandable> = new EventEmitter();
  permissions = UserPermissions;

  constructor(private layersService: LayersService) {}

  onSelect(event: MouseEvent) {
    event.stopPropagation();
    this.selectFolder.emit(this.folder);
  }

  onAllGuiToggle(event: MouseEvent) {
    event.stopPropagation();
    if (this.isFolderVisible) {
      this.toggleAllGuiFolder.emit(!this.isAnyGuiVisible);
    }
  }

  onEditClick(event: MouseEvent) {
    event.stopPropagation();
    this.layersService.setEditingFolder(this.folder);
  }

  onExpand(event: MouseEvent) {
    event.stopPropagation();
    this.expand.emit(this.folder as LayerUiExpandable);
  }

  onClickLayerPath() {
    this.clickLayerPath.emit(this.folder as LayerUiExpandable);
  }
}
