/* eslint-disable max-lines */
import { createGui } from '@App/app/entities/layer/create-gui.model';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { Tube, ViewerTubeLayer } from '@App/app/entities/layer/tube.model';
import { CreationToolError } from '@App/app/shared/exceptions/creation-tool-error';
import { Injectable } from '@angular/core';
import { AbstractMesh, PickingInfo, Plane, Quaternion, Ray, Tools, Vector3 } from 'babylonjs';
import { MESH } from 'src/app/configs/babylon.config';
import { median } from 'src/app/shared/utils/utils';
import { modelMeshPredicate } from '../../../helpers/layers-helpers';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { GenerateGUIService } from '../../gui-services/generate-gui-service/generate-gui.service';
import { MaterialService } from '../../material-service/material.service';
import { SceneService } from '../../scene-service/scene.service';
import { UtilsService } from '../../utils-service/utils.service';

@Injectable({
  providedIn: 'root',
})
export class TubeUtilsService implements createGui {
  constructor(
    public generateGUIService: GenerateGUIService,
    private materialService: MaterialService,
    private nodeService: BabylonNodesService,
    private sceneService: SceneService,
    private utilsService: UtilsService,
  ) {}

  calculateTubeData(
    centers: [Vector3, Vector3],
    inners: [Vector3[], Vector3[]] = [[], []],
  ): { edges: [Vector3, Vector3]; radiusOuter: number; radiusInner: number } {
    const direction = centers[1].subtract(centers[0]).normalizeToNew();
    const radiusOuter = this.calculateRadiusOuter(centers, direction);
    if (radiusOuter <= 0) {
      throw new CreationToolError('Outer radius not valid');
    }
    const radiusInner = this.calculateRadiusInner(centers, inners, direction);
    const edges = this.findEdges(centers, direction, radiusOuter);
    if (edges.some((edge) => !edge)) {
      throw new CreationToolError('Mesh edges not find');
    }
    return { edges, radiusOuter, radiusInner };
  }

  changeSampleMaterial(sample: AbstractMesh, defaultMaterial: boolean) {
    sample.material = defaultMaterial
      ? this.materialService.electricBlueMaterial
      : this.materialService.redMaterial;
  }

  createTube(edges: [Vector3, Vector3], radiusOuter: number, radiusInner: number) {
    const tube = new Tube();
    tube.name = `tube`;
    tube.isVisible = true;
    tube.isSaved = true;
    tube.data.edges = [
      { x: edges[0].x, y: edges[0].y, z: edges[0].z },
      { x: edges[1].x, y: edges[1].y, z: edges[1].z },
    ];
    tube.data.length = this.utilsService.getLengthBetweenPoints(...edges);
    tube.data.radiusOuter = +radiusOuter.toFixed(5);
    tube.data.radiusInner = radiusInner ? +radiusInner.toFixed(5) : 0;
    return tube;
  }

  showTube(tube: Tube, tubeEdges: [Coordinates3, Coordinates3]) {
    const newTube = tube as ViewerTubeLayer;
    const { radiusOuter, radiusInner } = newTube.data;
    const edges: [Vector3, Vector3] = [
      new Vector3(tubeEdges[0].x, tubeEdges[0].y, tubeEdges[0].z),
      new Vector3(tubeEdges[1].x, tubeEdges[1].y, tubeEdges[1].z),
    ];
    newTube.data.length = Vector3.Distance(edges[0], edges[1]);
    newTube.mesh = this.nodeService.createTube(
      edges,
      { radiusOuter, radiusInner },
      `${MESH.tube.defaultName}${newTube.id}`,
    );
    newTube.mesh.visibility = 0.6;
    newTube.centerLine = this.nodeService.createLine(edges);
    newTube.gui = this.createGui(newTube);
    // init gizmos here
    return newTube;
  }

  createGui(layer: ViewerTubeLayer) {
    if (layer.mesh) {
      return this.generateGUIService.createNameTag(layer.name, layer.mesh, true, layer.id);
    }
  }

  private findCenterEdges(centers: [Vector3, Vector3], direction: Vector3) {
    const edges: [Vector3, Vector3] = [Vector3.Zero(), Vector3.Zero()];
    const ray1 = new Ray(centers[0], direction, Vector3.Distance(centers[0], centers[1]) * 2);
    const ray2 = new Ray(
      centers[1],
      direction.negate(),
      Vector3.Distance(centers[0], centers[1]) * 2,
    );
    [ray1, ray2].forEach((ray, i) => {
      const pick = this.sceneService.scene.pickWithRay(ray, modelMeshPredicate);
      if (pick?.pickedMesh && pick.pickedPoint) {
        edges[i] = pick.pickedPoint.clone();
      }
    });
    return edges;
  }

  private findEdges(centers: [Vector3, Vector3], direction: Vector3, radius: number) {
    const findOriginAndCross = (edge: Vector3, i: number, n: number) => {
      const origin =
        i === 0
          ? edge.add(direction.scale(n * 0.001))
          : edge.add(direction.negate().scale(n * 0.001));
      const cross = origin.normalizeToNew().cross(direction).normalizeToNew();
      return [origin, cross];
    };

    const checkPick = (pick: PickingInfo, pickedPoints: number) =>
      pick.pickedMesh && pick.pickedPoint ? pickedPoints + 1 : pickedPoints;

    const calculateFinalEdge = (edge: Vector3, i: number, n: number) => {
      return i === 0
        ? edge.add(direction.scale((n - 1) * 0.001))
        : edge.add(direction.negate().scale((n - 1) * 0.001));
    };

    const edges: [Vector3, Vector3] = [Vector3.Zero(), Vector3.Zero()];
    const centerEdges = this.findCenterEdges(centers, direction);
    if (centerEdges.some((edge) => !edge)) {
      throw new CreationToolError('Mesh edges not find');
    }
    // eslint-disable-next-line complexity
    centerEdges.forEach((edge, i) => {
      let isModelPicked = true;
      let n = 1;
      while (isModelPicked) {
        const [origin, cross] = findOriginAndCross(edge, i, n);
        let pickedPoints = 0;
        for (let j = 0; j < 12; j++) {
          const rotatedCross = new Vector3(0, 0, 0);
          cross.rotateByQuaternionToRef(
            Quaternion.RotationAxis(direction, Tools.ToRadians(j * 15)),
            rotatedCross,
          );
          const position = origin.add(rotatedCross.scale(radius * 1.1));
          const ray = new Ray(position, rotatedCross.negate(), radius * 2.2);
          const pick = this.sceneService.scene.pickWithRay(ray, modelMeshPredicate);
          if (pick) {
            pickedPoints = checkPick(pick, pickedPoints);
          }

          // DEBUGGING
          // const sphere = MeshBuilder.CreateSphere(
          //   'test',
          //   { diameter: 0.001 },
          //   this.sceneService.scene,
          // );
          // sphere.position = position;
          // sphere.material = this.materialService.redMaterial;
          // RayHelper.CreateAndShow(ray, this.sceneService.scene, new Color3(1, 0, 0));
          // END OF DEBUGGING

          if (pickedPoints > 1) {
            break;
          }
        }
        isModelPicked = !!pickedPoints && n < 100;
        n++;
      }
      edges[i] = calculateFinalEdge(edge, i, n);
    });
    return edges;
  }

  private calculateRadiusOuter(centers: [Vector3, Vector3], direction: Vector3) {
    const radiuses: number[] = [];
    for (let i = 0; i < 6; i++) {
      const origin = centers[0].add(
        direction.scale((Vector3.Distance(centers[0], centers[1]) / 5) * i),
      );
      const cross = origin.normalizeToNew().cross(direction).normalizeToNew();
      for (let j = 0; j < 12; j++) {
        const rotatedCross = new Vector3(0, 0, 0);
        cross.rotateByQuaternionToRef(
          Quaternion.RotationAxis(direction, Tools.ToRadians(j * 30)),
          rotatedCross,
        );
        const rayRotated = new Ray(
          origin,
          rotatedCross,
          Vector3.Distance(centers[0], centers[1]) * 2,
        );
        const pick = this.sceneService.scene.pickWithRay(rayRotated, modelMeshPredicate);
        if (pick?.pickedPoint && pick.pickedMesh) {
          radiuses.push(Vector3.Distance(pick.pickedPoint, origin));
        }
      }
    }
    return median(radiuses);
  }

  private calculateRadiusInner(
    centers: [Vector3, Vector3],
    inners: [Vector3[], Vector3[]] = [[], []],
    direction: Vector3,
  ) {
    const planes = [
      Plane.FromPositionAndNormal(centers[0], direction),
      Plane.FromPositionAndNormal(centers[1], direction),
    ];
    const radiuses = inners.flatMap((positions, i) => {
      return positions.map((position) => {
        const projected = position
          .clone()
          .projectOnPlane(planes[i], position.add(i === 1 ? direction : direction.negate()));
        const distance = Vector3.Distance(projected, centers[i]);
        return distance;
      });
    });
    return radiuses.reduce((a, b) => a + b, 0) / radiuses.length;
  }
}
