<app-filter-layout
  [filterForm]="filterForm"
  [loading]="false"
  [contentTpl]="contentTpl"
  [isFilterPopoverActive]="isFilterPopoverActive"
  [textInputValue]="getKeywordsValue()"
  [showClearButton]="isFormTouched()"
  textInputPlaceholder="Type to search users"
  (clearFilters)="clearFilters()"
  (queryChange)="queryChange($event)"
  (submitHandler)="onSubmit($event)"
  (toggleFilterPopover)="toggleFilterPopover()"
>
</app-filter-layout>

<ng-template #contentTpl>
  <div [formGroup]="filterForm">
    <div class="filter__section">
      <div class="filter__title">Roles</div>
      <div class="grid" formArrayName="role">
        <ng-container *ngFor="let role of roles | keyvalue">
          <nb-checkbox
            *ngIf="typeOf(role) !== 'number'"
            class="filter__checkbox checkbox-grid"
            [formControlName]="role.key"
          >
            {{ roles[role.key] }}
          </nb-checkbox>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
