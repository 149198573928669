<div class="layer_details__list" *ngIf="childLayers.length">
  <div *ngFor="let sketchTool of childLayers" class="layer_details__list_item">
    <ng-container *ngIf="!(sketchTool | isLayerDeleteActive); else deletionConfirmation">
      <div class="layer_details__label">{{ sketchTool.name }}</div>
      <div class="layer_details__value layer_details__value--right">
        <button
          type="button"
          class="layer_details__action layer_details__action--delete select-none"
          (click)="onDeleteClick(sketchTool)"
        >
          Delete
        </button>
      </div>
    </ng-container>

    <ng-template #deletionConfirmation>
      <div class="layer_details__label layer_details__label--info select-none">Are you sure?</div>
      <div class="layer_details__value layer_details__value--right">
        <button
          type="button"
          class="layer_details__action layer_details__action--cancel select-none"
          (click)="onDeleteCancel(sketchTool)"
        >
          Cancel
        </button>
        <button
          type="button"
          class="layer_details__action layer_details__action--delete select-none"
          (click)="onDeleteSketchConfirm(sketchTool)"
        >
          Delete
        </button>
      </div>
    </ng-template>
  </div>
</div>
