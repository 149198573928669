import { RectType, getCenterPoint } from '@App/app/engine/helpers/rectangle-tools-helpers';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rectCenter',
  pure: false,
})
export class RectCenterPipe implements PipeTransform {
  transform(layer: RectType) {
    return getCenterPoint(layer);
  }
}
