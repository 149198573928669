import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Folder } from '@App/app/entities/layer/folder.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { LayersService } from '../../../services/layers/layers.service';
import { hideLayer, showLayers, updateLayer } from '../../../store/actions/layers.actions';
import { IsFolderVisiblePipe } from '../../layers-sortable/pipes/is-folder-visible/is-folder-visible.pipe';
import { IsGuiLayerVisiblePipe } from '../../layers-sortable/pipes/is-gui-layer-visible/is-gui-layer-visible.pipe';
import { LayersListService } from '../layers-list-service/layers-list.service';

@Injectable()
export class LayersListActionsService {
  private _isAtLeastOneSelectedLayerVisible$: Observable<boolean>;
  private _rootFolder$: Observable<LayerUiExpandable>;

  constructor(
    private _layersService: LayersService,
    private _layersListService: LayersListService,
    private _viewerLayerService: ViewerLayerService,
    private _isGuiLayerVisiblePipe: IsGuiLayerVisiblePipe,
    private _isFolderVisiblePipe: IsFolderVisiblePipe,
    private store: Store,
  ) {
    this._isAtLeastOneSelectedLayerVisible$ = this._layersListService.isAtLeastOneSelectedLayerVisible$;
    this._rootFolder$ = this._layersListService.rootFolder$;
  }

  toggleLayerSelection(layer: LayerUI) {
    const isLayerSelected = this._layersListService.selectedLayers.some(
      (item) => item.id === layer.id,
    );
    this._layersService.setSelectedLayers(
      isLayerSelected
        ? this._layersListService.selectedLayers.filter((item) => item.id !== layer.id)
        : [...this._layersListService.selectedLayers, layer],
    );
  }

  setLayerAsSelected(layer: LayerUI) {
    this._layersService.setSelectedLayers([layer]);
  }

  setLayerAsDraggedById(id: number) {
    const layer = this._layersListService.getAllItems().find((l) => l.id === id);
    if (layer && this._layersListService.selectedLayers.every((l) => l.id !== layer.id)) {
      this._layersService.setSelectedLayers([layer]);
    }
  }

  setLayerAsDroppedByOriginAndDestinationIds(originId: number, destinationId: number) {
    if (!!originId && !!destinationId && originId !== destinationId) {
      this._layersListService.moveToNewParent(destinationId);
    }
  }

  showLayerDetails(layer: LayerUI) {
    for (const folder in this._layersListService.layersByFolders) {
      if (!!this._layersListService.layersByFolders[folder]) {
        this._layersListService.layersByFolders[folder].forEach((item) => {
          if (item.isVisible) {
            this._layersService.setLayerVisibility(item, false);
          }
        });
      }
    }
    this._layersService.setLayerVisibility(layer, true);
    this._layersService.setDetailsViewLayers([layer]);
  }

  toggleLayerOpen(layer: LayerUI | LayerUiExpandable) {
    this.store.dispatch(updateLayer({ update: { id: layer.id, changes: { open: !layer.open } } }));
  }

  toggleLayerVisibility(layer: LayerUI) {
    const { visibleLayers } = this._layersListService;
    let newVisibleLayers: LayerUI[] = [];
    if (!!visibleLayers.length) {
      if (
        visibleLayers.some((selected) => selected.id === layer.id && selected.type === layer.type)
      ) {
        newVisibleLayers = visibleLayers.filter(
          (selected) => !(selected.id === layer.id && selected.type === layer.type),
        );
        this._layersService.setLayerVisibility(layer, false);
      } else {
        newVisibleLayers = [...visibleLayers, layer];
        this._layersService.setLayerVisibility(layer, true);
      }
    } else {
      newVisibleLayers = [layer];
      this._layersService.setLayerVisibility(layer, true);
    }
    this._layersService.setVisibleLayers(newVisibleLayers);
  }

  toggleLayerGuiVisibility(layer: LayerUI) {
    const isOn = this._viewerLayerService.checkIsGuiVisible(layer.id);
    this._layersService.setLayerGuiVisibility(layer.id, !isOn);
    this.store.dispatch(updateLayer({ update: { id: layer.id, changes: {} } }));
  }

  toggleShowAllLayers() {
    this._isAtLeastOneSelectedLayerVisible$.pipe(first()).subscribe((hidden) => {
      const show = !hidden;
      this._layersListService.selectedLayers
        .filter((layer) => !this._layersListService.isFolder(layer))
        .forEach((layer) => {
          this._layersService.setVisibleLayers(
            show
              ? [...this._layersListService.visibleLayers, layer]
              : this._layersListService.visibleLayers.filter((item) => item.id !== layer.id),
          );
          this._layersService.setLayerVisibility(layer, show);
        });
    });
  }

  toggleAllLayersVisibility() {
    const isOn = !!this._layersListService.visibleLayers.length;
    this._rootFolder$.pipe(first()).subscribe((root) => {
      this._layersListService.setFolderVisibility(root, !isOn);
    });
  }

  toggleAllLayerGuiVisibility() {
    const isOn = this._layersListService.visibleLayers.some((layer) =>
      this._isGuiLayerVisiblePipe.transform(layer),
    );
    this._rootFolder$.pipe(first()).subscribe((root) => {
      this.toggleAllGuiInFolder({ folder: root as Folder, value: !isOn });
    });
  }

  toggleAllGuiInFolder(data: { folder: Folder; value: boolean }) {
    const { folder, value } = data;
    this._layersListService.setFolderGuiVisibility(folder as LayerUiExpandable, value);
    this.store.dispatch(updateLayer({ update: { id: folder.id, changes: {} } }));
  }

  toggleFolderVisibility(folder: LayerUiExpandable) {
    const isVisible = this._isFolderVisiblePipe.transform(folder);
    this._layersListService.setFolderVisibility(folder, !isVisible);
  }

  toggleLayerInFolderVisibility(layer: LayerUI, parent: LayerUiExpandable) {
    if (layer.isVisible) {
      this.store.dispatch(hideLayer({ id: layer.id }));
    } else {
      this.store.dispatch(showLayers({ ids: [parent.id, layer.id] }));
    }
  }

  showBatchLayerDetails() {
    if (!!this._layersListService.selectedLayers.length) {
      const layers = this._layersListService.selectedLayers.filter(
        (layer) => !this._layersListService.isFolder(layer),
      );
      layers.forEach((layer) => {
        if (!this._layersListService.visibleLayers.some((l) => l.id === layer.id)) {
          this._layersService.setLayerVisibility(layer, true);
        }
      });
      this._layersService.setDetailsViewLayers([...layers]);
    } else {
      this._layersListService.showInfoToast('Please select at least one layer.');
    }
  }

  deleteSelectedLayers() {
    const layers = this._layersListService.selectedLayers.filter(
      (layer) => !this._layersListService.isFolder(layer) && layer.type !== LAYER_TYPES.BASE_PLANE,
    );
    this._layersListService.deleteLayers(layers);
  }
}
