import { BaseFile } from '@App/app/entities/files/files.model';
import { S3Service } from '@App/app/shared/services/s3/s3.service';
import { getDataURLFromFile } from '@App/app/shared/utils/files.utils';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, combineLatest, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ModelFilesHttpService } from '../../../services/model-files-http/model-files-http.service';
import { ModelsService } from '../../../services/models-service/models.service';
import {
  createModelFiles,
  createModelFilesFail,
  createModelFilesSuccess,
  deleteModelFiles,
  deleteModelFilesFail,
  deleteModelFilesSuccess,
  loadCurrentModelFiles,
  loadModelFilesSuccess,
  uploadModelFiles,
} from '../actions/model-files.actions';

@Injectable()
export class ModelFilesEffects {
  constructor(
    private actions: Actions,
    private modelsService: ModelsService,
    private modelFilesHttpService: ModelFilesHttpService,
    private s3Service: S3Service,
  ) {}

  loadCurrentModelFiles = createEffect(() => {
    return this.actions.pipe(
      ofType(loadCurrentModelFiles),
      mergeMap(() => {
        const { model } = this.modelsService;
        if (!model) {
          return EMPTY;
        }

        return this.modelFilesHttpService.getModelFiles(+model.id).pipe(
          map((resp) => {
            const files = BaseFile.fromHttpFiles(resp);
            return loadModelFilesSuccess({ objects: files });
          }),
        );
      }),
    );
  });

  createModelFiles = createEffect(() => {
    return this.actions.pipe(
      ofType(createModelFiles),
      mergeMap(({ objects, files }) => {
        const { model } = this.modelsService;
        if (!model) {
          return EMPTY;
        }
        return this.modelFilesHttpService.postModelFiles(+model.id, objects).pipe(
          map((resp) => uploadModelFiles({ objects: resp, files })),
          catchError(async () => createModelFilesFail({ objects })),
        );
      }),
    );
  });

  uploadModelFiles = createEffect(() => {
    return this.actions.pipe(
      ofType(uploadModelFiles),
      mergeMap(({ objects, files }) => {
        return combineLatest(
          objects.map((obj, index) => this.s3Service.uploadFile(obj.uploadURL, files[index])),
        ).pipe(
          switchMap(() => {
            return from(
              Promise.all(
                BaseFile.fromHttpFiles(objects).map(async (obj, index) => {
                  obj.isUploaded = true;
                  obj.downloadURL = await getDataURLFromFile(files[index]);
                  return obj;
                }),
              ),
            );
          }),
          map((newObjects) => {
            return createModelFilesSuccess({ objects: newObjects });
          }),
          catchError(async () => createModelFilesFail({ objects })),
        );
      }),
    );
  });

  deleteModelFiles = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteModelFiles),
      mergeMap(({ ids }) => {
        const { model } = this.modelsService;
        if (!model) {
          return EMPTY;
        }
        return this.modelFilesHttpService.deleteModelFiles(+model.id, ids).pipe(
          map(() => deleteModelFilesSuccess({ ids })),
          catchError(async () => deleteModelFilesFail({ ids })),
        );
      }),
    );
  });
}
