import { LayerDetailsEditForm } from '@App/app/entities/forms/layer-details-edit-form.model';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { UnitsService } from '@App/app/pages/viewer/services/utils/units.service';
import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayerDetailsService } from '../../../services/layer-details-service/layer-details.service';

@UntilDestroy()
@Component({
  template: '',
})
export abstract class BaseLayerPropertiesComponent<T> {
  @Input() layer: T;
  @Input() editForm: LayerDetailsEditForm;
  unitsSystem: UnitsSystem;

  constructor(
    private _unitsService: UnitsService,
    protected _layerDetailsService: LayerDetailsService,
  ) {
    this._unitsService.isImperialActive$.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
      this.unitsSystem = value ? UnitsSystem.Imperial : UnitsSystem.Metric;
    });
  }
}
