import { SUCCESS, USER_CREATE_SUCCESS } from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { UsersHttpService } from 'src/app/pages/users/users-http.service';
import { FAILED, USER_CREATE_FAILED } from '../../../../configs/toastr-events.config';
import { RolesHttpService } from '../../roles-http.service';
import { AddUserService } from './../../add-user/add-user.service';
import {
  addNewUser,
  addNewUserFailed,
  addNewUserSuccess,
  assignRoles,
} from './../actions/users.actions';

@Injectable()
export class AddUserEffects {
  constructor(
    private actions: Actions,
    private usersHttpService: UsersHttpService,
    private rolesHttpService: RolesHttpService,
    private router: Router,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private addUserService: AddUserService,
  ) {}

  addUser = createEffect(() => {
    return this.actions.pipe(
      ofType(addNewUser),
      mergeMap(({ user }) => {
        this.addUserService.setRequestSended(true);
        return this.usersHttpService.postUser(user).pipe(
          map((respUser) => {
            const roles = {
              userId: respUser.id,
              roleIds: user.roleIds,
            };
            return assignRoles({ roles });
          }),
          catchError(() => of(addNewUserFailed())),
        );
      }),
    );
  });

  assignRoles = createEffect(() => {
    return this.actions.pipe(
      ofType(assignRoles),
      mergeMap(({ roles }) => {
        return this.rolesHttpService.assignRoles(roles).pipe(
          map(() => {
            return addNewUserSuccess();
          }),
          catchError(() => of(addNewUserFailed())),
        );
      }),
    );
  });

  addNewUserSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(addNewUserSuccess),
        tap(() => {
          this.addUserService.setRequestSended(false);
          this.authService.currentEndpoint$.pipe(first()).subscribe((endpoint) => {
            if (endpoint) {
              this.router.navigate([`/${endpoint.id}/users`]);
            }
            this.toastrService.show(USER_CREATE_SUCCESS, SUCCESS, {
              ...SUCCESS_TOASTR_CONFIG,
            });
          });
        }),
      );
    },
    { dispatch: false },
  );

  addNewUserFailed = createEffect(
    () => {
      return this.actions.pipe(
        ofType(addNewUserFailed),
        tap(() => {
          this.addUserService.setRequestSended(false);
          this.toastrService.show(USER_CREATE_FAILED, FAILED, {
            ...WARNING_TOASTR_CONFIG,
          });
        }),
      );
    },
    { dispatch: false },
  );
}
