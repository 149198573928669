import { SKETCH_LINE_MESH_NAME } from '@App/app/configs/babylon.config';
import { SketchLine } from '@App/app/entities/layer/sketch-tools/tools/sketch-line.model';
import { SketchRectangle } from '@App/app/entities/layer/sketch-tools/tools/sketch-rectangle.model';
import { Injectable } from '@angular/core';
import { LinesMesh, Mesh } from 'babylonjs';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';

@Injectable()
export class SketchLineMeshUtilsService {
  constructor(private _babylonNodesService: BabylonNodesService) {}

  createLine({ pointsMeshes, id }: SketchLine | SketchRectangle, pointer?: Mesh): LinesMesh {
    const path = pointsMeshes.map((sphere) => sphere.position);
    if (pointer) {
      path.push(pointer.position);
    }
    const mesh = this._babylonNodesService.createLine(path, `${SKETCH_LINE_MESH_NAME}${id}`, null, {
      updatable: true,
    });

    mesh.renderingGroupId = 2;
    mesh.alwaysSelectAsActiveMesh = true;
    return mesh;
  }

  updateLine(sketch: SketchLine | SketchRectangle, pointerMesh?: Mesh): LinesMesh {
    const path = sketch.pointsMeshes.map((sphere) => sphere.position);
    path.push(pointerMesh ? pointerMesh.position : sketch.pointsMeshes[0].position);
    return this._babylonNodesService.createLine(path, null, null, {
      updatable: true,
      instance: sketch.linesMesh as LinesMesh,
    });
  }

  finishLine({ pointsMeshes, id, linesMesh }: SketchLine | SketchRectangle): LinesMesh {
    if (linesMesh) {
      linesMesh.dispose();
      linesMesh = null;
    }
    const path = pointsMeshes.map((sphere) => sphere.position);
    path.push(pointsMeshes[0].position);
    const mesh = this._babylonNodesService.createLine(path, `${SKETCH_LINE_MESH_NAME}${id}`, null, {
      updatable: true,
    });

    mesh.renderingGroupId = 2;
    mesh.alwaysSelectAsActiveMesh = true;
    return mesh;
  }
}
