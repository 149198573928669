import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileHttp } from '@App/app/entities/files/files.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ApiService } from '@App/app/shared/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModelsProcessingHttpService extends ApiService {
  getBuildProcess(id: number) {
    return this.http.get(`${this.api}/buildProcess/${id}`, { params: { expanded: 'true' } });
  }

  getBuildProcessFiles(id: number) {
    return this.http.get(`${this.api}/buildProcess/${id}/files`);
  }

  putBuildProcessFiles(id: number, files: FileHttp[]) {
    return this.http.put(`${this.api}/buildProcess/${id}/files`, files);
  }

  getBuildProcesses() {
    return this.http.get(`${this.api}/buildProcess`, { params: { expanded: 'true' } });
  }

  getBuildProcessLogs(url: string) {
    return this.http.get(url, { responseType: 'text', headers: { notLogOutOn403: 'true' } });
  }

  putBuildProcess(process: BuildProcess) {
    return this.http.put(`${this.api}/buildProcess/${process.id}`, process.toHttp());
  }

  deleteBuildProcess(id: number, force = false) {
    const options = { params: new HttpParams() };
    if (force) {
      options.params = options.params.set('force', `${force}`);
    }
    return this.http.delete(`${this.api}/buildProcess/${id}`, options);
  }

  toggleSubscribeBuildProcess(id: number, subscribe: boolean): Observable<BuildProcess> {
    return this.http.post<BuildProcess>(
      `${this.api}/buildProcess/${subscribe ? 'subscribe' : 'unsubscribe'}/${id}`,
      {},
    );
  }
}
