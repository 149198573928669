import { LAYERS_CREATE_SUCCESS, SUCCESS } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { httpLayer } from '@App/app/entities/layer/layer.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LayerHttpService } from '../../../../engine/services/layer-services/layer-http-service/layer-http.service';
import { ViewerLayerService } from '../../../../engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { addLayers, addLayersSuccess } from './../actions/layers.actions';

@Injectable()
export class AddLayersEffects {
  constructor(
    private actions: Actions,
    private layerHttpService: LayerHttpService,
    private viewerLayerService: ViewerLayerService,
    private toastrService: NbToastrService,
  ) {}

  addLayers = createEffect(() => {
    return this.actions.pipe(
      ofType(addLayers),
      mergeMap(({ layers, showToast = true }) => {
        return this.layerHttpService.postBatchLayers(layers as httpLayer[]).pipe(
          map(
            (respLayers) => {
              respLayers.forEach((layer) => {
                layer.isVisible = true;
                layer.isSaved = true;
              });
              return addLayersSuccess({ layers: respLayers, showToast });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  addLayersSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(addLayersSuccess),
        tap(({ layers, showToast = true }) => {
          const nonFolderLayers = layers.filter((layer) => layer.type !== LAYER_TYPES.FOLDERS);
          this.viewerLayerService.initViewerLayers(nonFolderLayers);
          this.viewerLayerService.showLayers(nonFolderLayers);
          if (showToast) {
            this.toastrService.show(LAYERS_CREATE_SUCCESS, SUCCESS, {
              ...SUCCESS_TOASTR_CONFIG,
            });
          }
        }),
      );
    },
    { dispatch: false },
  );
}
