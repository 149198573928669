import { ProcessingEngineService } from '@App/app/engine/services/engine-services/processing-engine-service/processing-engine.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { ATSetupTabForm, ControlPointsForm } from '@App/app/entities/forms/at-setup-tab-form.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Options } from 'ng5-slider';
import { NgxPermissionsService } from 'ngx-permissions';
import { filter, first, take } from 'rxjs/operators';
import { PhotosUtilsService } from '../../../services/photos-utils/photos-utils.service';
import { ProcessingService } from '../../processing.service';
import { atSettings } from '../../shared/models/at-settings';
import { SetupTabComponent } from '../base-tabs/setup-tab.component';
import { AtSetupControlPointsService } from './at-setup-control-points/services/at-setup-control-points-service/at-setup-control-points.service';
import { ControlPointsHttpService } from './at-setup-control-points/services/control-points-http-service/control-points-http.service';
import { AtSettingsDescription, AtSetupGroupNames, AtSetupTabs } from './models/at-setup-tab.enum';
import { ControlPoint } from './models/control-point.model';
import { AtSetupTabService } from './services/at-setup-tab/at-setup-tab.service';

@UntilDestroy()
@Component({
  selector: 'app-at-setup-tab',
  templateUrl: './at-setup-tab.component.html',
  styleUrls: ['./at-setup-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AtSetupTabComponent extends SetupTabComponent<ATSetupTabForm>
  implements OnInit, OnDestroy {
  static step = ProcessingSteps.AT_SETUP;
  static settings = atSettings;
  protected cancelStep = ProcessingSteps.AT_REVIEW;
  activeTab = AtSetupTabs.SETTINGS;
  atSetupTabs = AtSetupTabs;
  options: Options = { step: 0.01, floor: 0.01, ceil: 1 };
  isModelLoading = false;
  gcpFileLoading = true;
  atQualityDescription = AtSettingsDescription.AT_QUALITY;
  permissions = UserPermissions;

  constructor(
    fb: NonNullableFormBuilder,
    processingService: ProcessingService,
    private _atSetupTabService: AtSetupTabService,
    _modelsProcessingService: ModelsProcessingService,
    private _controlPointsHttpService: ControlPointsHttpService,
    private _atSetupControlPointsService: AtSetupControlPointsService,
    private _processingEngineService: ProcessingEngineService,
    private _photosUtilsService: PhotosUtilsService,
    private _permissionsService: NgxPermissionsService,
    store: Store,
  ) {
    super(fb, processingService, store, _modelsProcessingService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.isFailed =
      !!this.processingService.buildProcess?.isFailed() &&
      this.processingService.buildProcess.steps.at.isCurrent();

    this._atSetupTabService.activeTab$
      .pipe(untilDestroyed(this))
      .subscribe((tab) => (this.activeTab = tab));

    this._processingEngineService.isModelLoading$
      .pipe(untilDestroyed(this))
      .subscribe((value) => (this.isModelLoading = value));

    if (this.process) {
      this._modelsProcessingService.areFilesLoaded.pipe(filter(Boolean), first()).subscribe(() => {
        this.gcpFileLoading = false;
        this.onFilesLoad();
      });
    }
  }

  ngOnDestroy() {
    this._atSetupTabService.setActiveTab(AtSetupTabs.SETTINGS);
  }

  getControlPoints() {
    return this._atSetupControlPointsService.getControlPoints();
  }

  getSettings() {
    const settings = atSettings;
    return this._atSetupControlPointsService.getControlPoints().length &&
      this.hasConfigGcpPermission()
      ? settings
      : settings.filter((s: any) => s.groupName !== AtSetupGroupNames.GROUND_CONTROL_POINTS);
  }

  hasConfigGcpPermission() {
    return this._permissionsService.getPermission(this.permissions.CAN_CONFIGURE_GCPS);
  }

  restoreDefault() {
    this.form.reset();
  }

  areGCPSettingsAvailable() {
    return this.getControlPoints().length && this.form.controls.useGCP.value;
  }

  areGCPSettingsValid() {
    return this.areGCPSettingsAvailable()
      ? !(
          this._atSetupControlPointsService.form.touched &&
          !this._atSetupControlPointsService.form.valid
        )
      : true;
  }

  onSubmit(_: ATSetupTabForm) {
    let settings = this.form.value;
    let controlPointSettings: ControlPointsForm['value'] | null = null;
    if (settings.useGCP) {
      const gcpForm = this._atSetupControlPointsService.form;
      gcpForm.markAllAsTouched();
      if (!gcpForm.valid) {
        return;
      }
      const controlPoints = this._atSetupControlPointsService.getControlPoints();
      const controlPointsWithMeasurements = this._atSetupControlPointsService.createHttpControlPoints(
        controlPoints,
      );
      controlPointSettings = {
        ...gcpForm.value,
        controlPoints: controlPointsWithMeasurements,
      };
    }
    settings = { ...settings, controlPointSettings };
    this.startNext.emit(settings);
  }

  setActiveTab(tab: AtSetupTabs) {
    if (this.areGCPSettingsAvailable() && !this.isModelLoading) {
      this._atSetupTabService.setActiveTab(tab);
    }
  }

  onFilesLoad() {
    const process = this._modelsProcessingService.getCurrentBuildProcess() as BuildProcess;
    if (process.gcp?.isUploaded) {
      this._controlPointsHttpService
        .getControlPointsFromUrl(process.gcp.downloadURL)
        .pipe(take(1))
        .subscribe((res: any) => {
          const controlPoints: ControlPoint[] = [];
          const csvToRowArray = res.split('\n');
          for (let index = 1; index < csvToRowArray.length - 1; index++) {
            const [name, lat, long, alt] = csvToRowArray[index].split(',');
            controlPoints.push({ name, lat: +lat, long: +long, alt: +alt });
          }
          this._atSetupControlPointsService.setControlPoints(controlPoints);
          this.form.patchValue({ useGCP: true });
        });
    }

    if (!this._photosUtilsService.checkPhotosUseGPSMetadata(process.photos)) {
      this._atSetupControlPointsService.form.controls.useImageGPSMetadata.patchValue(false);
      this._atSetupControlPointsService.form.controls.useImageGPSMetadata.disable();
    }
  }

  protected createForm(): ATSetupTabForm {
    return this._atSetupTabService.getAtSettingsForm(this.fb);
  }
}
