import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DEFAULT_LIST_PAGE_OFFSETS } from '@App/app/configs/app.config';
import { NgxTableEvent } from '@App/app/entities/shared/ngx-table-event.model';
import { PageInfo } from '@App/app/entities/shared/page-info.model';
import { Page } from '@App/app/entities/shared/page.model';

import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
  SortType,
  TableColumn,
} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-main-datatable',
  templateUrl: './main-datatable.component.html',
  styleUrls: ['./main-datatable.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainDatatableComponent<T> implements AfterViewInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @Input() selected: T[];
  @Input() columns: TableColumn[];
  @Input() rows: T[];
  @Input() page: Page;
  @Input() loading: boolean;
  @Input() sortType: keyof typeof SortType | undefined;
  @Input() selectAllRowsOnPage: boolean;
  @Input() sorts: any[] = [];
  @Input() showStatisticsButton: boolean;
  @Input() getRowClass: ((row: any) => any) | undefined;
  @Input() rowHeight: any = 'auto';
  @Input() columnMode: boolean;
  @Output() activate = new EventEmitter<NgxTableEvent<T>>();
  @Output() selectRow = new EventEmitter<NgxTableEvent<T>>();
  @Output() setPage = new EventEmitter<PageInfo>();
  @Output() setPageOffset = new EventEmitter<number>();
  SelectionType = SelectionType;
  ColumnMode = ColumnMode;
  defaultOffsets = DEFAULT_LIST_PAGE_OFFSETS;

  ngAfterViewInit() {
    if (this.table) {
      this.table.columnMode = ColumnMode.force;
    }
    if (this.columnMode) {
      this.table.columnMode = ColumnMode.flex;
    }
  }

  onSelect(event: NgxTableEvent<T>) {
    this.selectRow.emit(event);
  }

  onLimitChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.setPageOffset.emit(+target.value);
  }

  onSetPage(pageInfo: PageInfo) {
    this.setPage.emit(pageInfo);
  }

  onActivate(event: NgxTableEvent<T>) {
    this.activate.emit(event);
  }
}
