<div class="layer_details__subtitle">Dimensions:</div>
<div class="layer_details__row left">
  <div class="layer_details__label">Width:</div>
  <div class="layer_details__value">
    {{ (layer | rectDimensions).width | lengthUnit: unitsSystem }}
  </div>
</div>
<div class="layer_details__row left">
  <div class="layer_details__label">Height:</div>
  <div class="layer_details__value">
    {{ (layer | rectDimensions).height | lengthUnit: unitsSystem }}
  </div>
</div>
<div class="layer_details__row left">
  <div class="layer_details__label">Depth:</div>
  <div class="layer_details__value">
    {{ (layer | rectDimensions).depth | lengthUnit: unitsSystem }}
  </div>
</div>
<button
  type="button"
  class="layer_details__action layer_details__action--edit layer_details__copy select-none"
  (click)="copyDimensions()"
>
  Copy
</button>
<app-layer-details-position [position]="layer | rectCenter"> </app-layer-details-position>
<app-layer-details-geolocation [position]="layer | rectCenter"></app-layer-details-geolocation>
<div class="layer_details__row left">
  <div class="layer_details__label">Azimuth</div>
  <div class="layer_details__value">{{ layer.data.azimuth }}°</div>
</div>
<div class="layer_details__row left">
  <div class="layer_details__label">Down Tilt</div>
  <div class="layer_details__value">{{ layer.data.verticalTilt }}°</div>
</div>
