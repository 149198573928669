import { Photo } from '@App/app/entities/files/files.model';
import { createAction, props } from '@ngrx/store';

export const loadAllPhotos = createAction('[Photo] Load all photos', props<{ modelId: number }>());

export const loadAllPhotosSuccess = createAction(
  '[Photo] Load all photos success',
  props<{ photos: Photo[] }>(),
);

export const removeAllPhotosLocal = createAction('[Photo] Remove all photos local');

export const createSelectedPhotosZip = createAction(
  '[Photo] Create selected photos zip',
  props<{ folderName: string; photos: Photo[] }>(),
);

export const createSelectedPhotosZipSuccess = createAction(
  '[Photo] Create selected photos zip success',
);
