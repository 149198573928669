import { Scene } from 'babylonjs';
import { AdvancedDynamicTexture } from 'babylonjs-gui';
import { GizmoManager } from 'babylonjs/Gizmos/gizmoManager';

export class CustomScene extends Scene {
  static DoubleClickDelay = 500;
  __advancedTexture: AdvancedDynamicTexture | null;
  __editMode: boolean;
  __gizmoManagerList: { [key in gizmoManagerTypes]: GizmoManager[] } = {
    sketchLines: [],
    sketchCircles: [],
  };
  __orthoprojectionCustomLODScale = 1;
  __orthoprojectionCustomGizmoScale = 1;
}

export enum gizmoManagerTypes {
  sketchLines = 'sketchLines',
  sketchCircles = 'sketchCircles',
}
