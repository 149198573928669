import { RegionOfInterestService } from '@App/app/engine/services/region-of-interest-service/region-of-interest.service';
import { RegionOfInterest } from '@App/app/entities/processing/region-of-interest.model';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-photo-zoom-legend',
  templateUrl: './photo-zoom-legend.component.html',
  styleUrls: ['./photo-zoom-legend.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoZoomLegendComponent implements OnInit {
  @Input() leftMouseButtonActionLabel: string;
  @Input() isViewerMode = false;
  leftMouseButtonIcon = '../../../../assets/svg/icons/sf_portal-ui_icon-left-mouse-button.svg';
  rightMouseButtonIcon = '../../../../assets/svg/icons/sf_portal-ui_icon-right-mouse-button.svg';
  scrollIcon = '../../../../assets/svg/icons/sf_portal-ui_icon-scroll.svg';
  scrollClickIcon = '../../../../assets/svg/icons/scroll-click.svg';

  roi$: Observable<RegionOfInterest | null>;

  constructor(private regionOfInterestService: RegionOfInterestService) {}

  ngOnInit(): void {
    this.roi$ = this.regionOfInterestService.regionOfInterest$;
  }
}
