/* eslint-disable max-lines */
import { BaseViewerLayer } from '@App/app/engine/viewer-layers/base-viewer-layer';
import { BasePlane } from '@App/app/entities/layer/base-plane.model';
import { LAYER_TYPES, LAYER_WITH_THICKNESS } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { TubeData } from '@App/app/entities/layer/tube.model';
import { AnnotationPriorities } from '@App/app/entities/shared/annotation-priorities.enum';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Injectable, Injector } from '@angular/core';
import { Vector3 } from 'babylonjs';
import { ArcRotateCamera } from 'babylonjs/Cameras/arcRotateCamera';
import 'reflect-metadata';
import { BehaviorSubject } from 'rxjs';
import { AnnotationViewerLayer } from '../../../viewer-layers/annotation-viewer-layer';
import { BasePlaneViewerLayer } from '../../../viewer-layers/base-plane-viewer-layer';
import { CuboidViewerLayer } from '../../../viewer-layers/cuboid-viewer-layer';
import { PolygonViewerLayer } from '../../../viewer-layers/polygon-viewer-layer';
import { SketchLineViewerLayer } from '../../../viewer-layers/sketch-line-viewer-layer';
import { SketchPlaneViewerLayer } from '../../../viewer-layers/sketch-plane-viewer-layer';
import { SceneService } from '../../scene-service/scene.service';
import { BasePlaneService } from '../base-plane-services/base-plane.service';
import { ViewerModelTypes } from './models/viewer-layer-types.model';

@Injectable({
  providedIn: 'root',
})
export class ViewerLayerService {
  private viewerLayers: { [id: string]: BaseViewerLayer } = {};
  private _layersLoaded$ = new BehaviorSubject<boolean>(false);
  private _viewerLayerByTypes: ViewerModelTypes;
  layersLoaded$ = this._layersLoaded$.asObservable();

  constructor(
    private basePlaneService: BasePlaneService,
    private sceneService: SceneService,
    private _injector: Injector,
  ) {
    import('./utils/viewer-layer-types.utils').then(({ VIEWER_MODEL_TYPES }) => {
      this._viewerLayerByTypes = VIEWER_MODEL_TYPES;
    });
  }

  initViewerLayers(layers: (LayerUI | LayerUiExpandable)[]) {
    for (const layer of layers) {
      this.initViewerLayer(layer);
    }
    this._layersLoaded$.next(true);
  }

  destroyBasePlane() {
    const basePlane = this.getBasePlane();
    basePlane?.destroyLayer();
  }

  getThicknessFormControls(layer: LayerUI, unitsSystem: UnitsSystem) {
    return this.viewerLayers[layer.id].getThicknessFormControls(unitsSystem);
  }

  updateAnnotationPriority(id: number, priority: AnnotationPriorities) {
    const annotation = this.viewerLayers[id] as AnnotationViewerLayer;
    if (annotation) {
      annotation.setPriority(priority);
    }
  }

  updateThickness(
    layer: LayerUI,
    value: number,
    fieldName?: keyof Omit<TubeData, 'edges' | 'length'>,
  ) {
    this.viewerLayers[layer.id].updateThickness(
      value,
      fieldName as keyof Omit<TubeData, 'edges' | 'length'>,
    );
  }

  isLayerSaved(id: number) {
    return this.viewerLayers[id]?.layerViewer?.isSaved;
  }

  deleteLayer(id: number) {
    this.viewerLayers[id]?.deleteLayer();
    if (this.viewerLayers.hasOwnProperty(id)) {
      delete this.viewerLayers[id];
    }
  }

  hasGuiToggler(id: number) {
    return this.viewerLayers[id]?.hasGuiToggler;
  }

  hasThickness(id: number) {
    const { type } = this.viewerLayers[id].layerViewer;
    const values = (Object.values(LAYER_WITH_THICKNESS) as unknown) as LAYER_TYPES[];
    return values.includes(type as NonNullable<typeof type>);
  }

  hasActiveLayerViewer(id: number) {
    return !!this.viewerLayers[id].hasActiveLayerViewer();
  }

  createBackupLayer(layer: LayerUI | LayerUiExpandable) {
    this.viewerLayers[layer.id].createBackupLayer(layer);
  }

  loadBackupLayer(id: number) {
    this.viewerLayers[id].loadBackupLayer();
  }

  setLayerEditMode(id: number, value: boolean) {
    this.viewerLayers[id].setLayerEditMode(value, id);
  }

  showLayer(layer: LayerUI | LayerUiExpandable) {
    this.viewerLayers[layer.id].showLayer(layer);
  }

  showLayers(layers: (LayerUI | LayerUiExpandable)[]) {
    layers.forEach((layer) => {
      this.viewerLayers[layer.id].showLayer(layer);
    });
  }

  hideLayer(id: number) {
    this.viewerLayers[id].hideLayer();
  }

  hideLayers(layers: (LayerUI | LayerUiExpandable)[]) {
    layers.forEach((layer) => {
      this.viewerLayers[layer.id].hideLayer();
    });
  }

  showLayerGui(id: number) {
    const layer = this.viewerLayers[id];
    if (layer.hasGuiToggler) {
      layer.showLayerGui();
    }
  }

  hideLayerGui(id: number) {
    this.viewerLayers[id].hideLayerGui();
  }

  changeLayerViewerName(id: number, text: string) {
    this.viewerLayers[id].changeLayerViewerName(text);
    if (this.checkIsGuiVisible(id)) {
      this.viewerLayers[id].changeLayerNameOnGui(text);
    }
  }

  getData(id: number) {
    return this.viewerLayers[id].getData();
  }

  getBackupData(id: number) {
    return this.viewerLayers[id].getBackupData();
  }

  checkIsGuiVisible(id: number) {
    return this.viewerLayers[id]?.checkGuiIsVisible();
  }

  setTmpData(id: number, data: any) {
    const layer = this.viewerLayers[id] as CuboidViewerLayer;
    if (layer) {
      layer.setData(data);
    }
  }

  addTmpLayer(layer: LayerUI | LayerUiExpandable) {
    this.initViewerLayer(layer);
    this.showLayer(layer);
    this.createBackupLayer(layer);
    this.setLayerEditMode(layer.id, true);
  }

  toggleImperialGui(isImperial: boolean) {
    for (const id in this.viewerLayers) {
      if (Object.prototype.hasOwnProperty.call(this.viewerLayers, id)) {
        this.viewerLayers[id].toggleImperialGui(isImperial);
      }
    }
  }

  addPointToPolygon(id: number, point: Vector3) {
    const polygon = this.viewerLayers[id] as PolygonViewerLayer;
    if (polygon) {
      polygon.addPointToPolygon(point);
    }
  }

  checkViewerLayerExists(id: number) {
    return id in this.viewerLayers;
  }

  getTmpLayerData(id: number) {
    return this.getData(id);
  }

  toggleChildLayerVisibility(parent: LayerUI | LayerUiExpandable, childId: number) {
    const sketchPlane = this.viewerLayers[parent.id] as SketchPlaneViewerLayer;
    if (sketchPlane) {
      sketchPlane.toggleChildVisibility(parent, childId);
    }
  }

  setNewParentId(id: number, parentId: number) {
    this.viewerLayers[id].setParentId(parentId);
  }

  getDataToSetCameraPosition(id: number) {
    const sketchPlane = this.viewerLayers[id] as SketchPlaneViewerLayer;
    if (sketchPlane) {
      return sketchPlane.getDataToSetCameraPosition();
    }
  }

  getSketchLayer(id: number) {
    const sketchPlane = this.viewerLayers[id] as SketchPlaneViewerLayer;
    if (sketchPlane) {
      return sketchPlane.layerViewer;
    }
  }

  deleteAllLocal() {
    for (const id in this.viewerLayers) {
      if (Object.prototype.hasOwnProperty.call(this.viewerLayers, id)) {
        this.viewerLayers[id].deleteLayer();
      }
    }
    this.viewerLayers = {};
    this._layersLoaded$.next(false);
  }

  setCameraOnLayer(layer: LayerUI) {
    const camera = this.sceneService.scene.activeCamera as ArcRotateCamera;
    const targetData = this.viewerLayers[layer.id].getCameraTargetData();
    if (targetData) {
      const { center, radius } = targetData;
      camera.target = center;
      if (layer.type === LAYER_TYPES.BASE_PLANE) {
        camera.radius = radius;
      } else {
        const cameraPositionModifier = this.basePlaneService.getBasePlanesPosition();
        cameraPositionModifier.y = center.y;
        const normal = center.subtract(cameraPositionModifier).normalize();
        const cameraPosition = center.add(normal.scale(radius));
        camera.position = cameraPosition;
      }
    }
  }

  restoreAllSketchToolsMeshesDefault(ids: number[]) {
    ids.forEach((id) => {
      (this.viewerLayers[id] as SketchLineViewerLayer).restoreMeshesDefault();
    });
  }

  getBasePlane() {
    return Object.values(this.viewerLayers).find(
      (layer) => layer instanceof BasePlaneViewerLayer,
    ) as BasePlaneViewerLayer | undefined;
  }

  initViewerLayer(layer: LayerUI | LayerUiExpandable, setAsLayerViewer = false) {
    const viewerLayerType = this._viewerLayerByTypes[layer.type];

    if (viewerLayerType) {
      this.viewerLayers[layer.id] = new viewerLayerType(this._injector);
    }

    if (setAsLayerViewer) {
      this.viewerLayers[layer.id].layerViewer = layer;
    }

    if (layer.type === LAYER_TYPES.BASE_PLANE) {
      this._initBasePlane(layer as BasePlane);
    }
  }

  private _initBasePlane(basePlane: BasePlane) {
    const basePlaneViewerLayer = this.getBasePlane();
    if (basePlaneViewerLayer) {
      basePlaneViewerLayer.initBasePlane(basePlane);
    }
  }
}
