import { Metadata } from '@App/app/entities/shared/metadata.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataResponse } from '../../../entities/layer/measurements/data-response.model';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class MetadataService extends ApiService {
  getVersion(): Observable<DataResponse<Metadata> | null> {
    return this.authService.endpointPath.pipe(
      switchMap((path) => (path ? this.http.get(`${path}/metadata/version`) : of(null))),
    );
  }
}
