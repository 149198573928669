import { AuthGuard } from '@App/app/auth/auth.guard';
import { HasDocsAcceptedGuard } from '@App/app/auth/guards/has-docs-accepted/has-docs-accepted.guard';
import { SelectedCompanyGuard } from '@App/app/auth/selected-company.guard';
import { AUTH_ROLES } from '@App/app/entities/auth/auth-roles.enum';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { VIEWER_TYPE } from '@App/app/entities/viewer/viewer.enum';
import { ViewerComponent } from '@App/app/pages/viewer/viewer.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ModelsProcessingGuard } from '../processing/guards/models-processing-guard/models-processing.guard';
import { ModelProcessingDetailsComponent } from '../processing/model-processing-details/model-processing-details.component';
import { ModelsSiteComponent } from '../sites/models-site/models-site.component';
import { ProcessingSiteComponent } from '../sites/processing-site/processing-site.component';
import { UsersSiteComponent } from '../sites/users-site/users-site.component';
import { ModelsListGuard } from './guards/models-list-guard/models-list.guard';
import { ModelDetailsComponent } from './model-details/model-details.component';
import { ModelOverviewComponent } from './model-details/model-overview/model-overview.component';
import { ModelPhotosComponent } from './model-details/model-photos/model-photos.component';

const routes: Routes = [
  {
    path: ':companyId',
    canActivate: [AuthGuard, SelectedCompanyGuard, HasDocsAcceptedGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'models',
            component: ModelsSiteComponent,
            canActivate: [ModelsListGuard],
            data: {
              permission: UserPermissions.CAN_ACCESS_MODEL_LIST,
              redirectPermissions: [
                UserPermissions.CAN_ACCESS_PROCESSING_LIST,
                UserPermissions.CAN_UPLOAD_PHOTOS,
              ],
            },
          },
          {
            path: 'processing',
            component: ProcessingSiteComponent,
            canActivate: [ModelsProcessingGuard, ModelsListGuard],
            data: {
              permission: UserPermissions.CAN_ACCESS_PROCESSING_LIST,
              redirectPermissions: [
                UserPermissions.CAN_ACCESS_MODEL_LIST,
                UserPermissions.CAN_UPLOAD_PHOTOS,
              ],
            },
          },
          {
            path: 'users',
            component: UsersSiteComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: [UserPermissions.CAN_ACCESS_USER_LIST],
              },
            },
          },
        ],
      },
      {
        path: 'models/add',
        loadChildren: () =>
          import('./model-details/model-form/add-model/add-model.module').then(
            (m) => m.AddModelModule,
          ),
        canActivate: [AuthGuard, ModelsProcessingGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: [UserPermissions.CAN_ADD_MODEL],
          },
        },
      },
      {
        path: 'models/:id/edit',
        loadChildren: () =>
          import('./model-details/model-form/edit-model/edit-model.module').then(
            (m) => m.EditModelModule,
          ),
        canActivate: [AuthGuard, ModelsProcessingGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: [UserPermissions.CAN_EDIT_MODEL],
          },
        },
      },
      {
        path: 'models/:id',
        component: ModelDetailsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ModelOverviewComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: [UserPermissions.CAN_ACCESS_MODEL_OVERVIEW],
              },
            },
          },
          {
            path: 'renders/:renderId',
            children: [
              {
                path: 'viewer',
                component: ViewerComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [UserPermissions.CAN_ACCESS_VIEWER],
                  },
                },
              },
              {
                path: 'photos',
                component: ModelPhotosComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                  permissions: {
                    only: [UserPermissions.CAN_ACCESS_PHOTOS],
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'processing/:id',
        component: ModelProcessingDetailsComponent,
        data: {
          role: AUTH_ROLES.ADMIN,
          permissions: {
            only: [UserPermissions.CAN_ACCESS_PROCESSING_VIEWER],
          },
        },
        canActivate: [AuthGuard, NgxPermissionsGuard, ModelsProcessingGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: '',
            component: ViewerComponent,
            data: {
              viewerType: VIEWER_TYPE.PROCESSING,
            },
          },
        ],
      },
      { path: '**', redirectTo: 'models' },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class ModelsRoutingModule {}
