import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LayersUtilsService } from '../services/layers-utils-service/layers-utils.service';
import { LayersService } from '../services/layers/layers.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
})
export class CreateFolderComponent implements OnInit {
  name = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);

  constructor(
    private _layersService: LayersService,
    private _layersUtilsService: LayersUtilsService,
  ) {}

  ngOnInit(): void {}

  onConfirm(): void {
    if (this.name.valid) {
      this._layersUtilsService.createFolder(this.name.value);
    }
  }

  onCancel(): void {
    this._layersService.setCreatingFolderActive(false);
  }
}
