import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import {
  ArcRotateCamera,
  Camera,
  Engine,
  Mesh,
  Observer,
  PointerEventTypes,
  PointerInfo,
  Vector3,
} from 'babylonjs';
import {
  ORTHOGRAPHIC_CAMERA_SPECS,
  SKETCH_MESHES_CUSTOM_SCALE,
  ORTHOPROJECTION_CONFIG as orthoConfig,
} from 'src/app/configs/babylon.config';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { SceneService } from '../../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export class OrthoProjectionService {
  private customScaleMeshes: Mesh[];
  private totalZoom = 0;
  private maxPanningSensibility = 20000;
  private zoomObserver: Observer<PointerInfo> | null;
  private zoomTarget: Vector3 | null = null;
  private zoomTargetObserver: Observer<PointerInfo> | null;

  constructor(private broadcastService: BroadcastService, private sceneService: SceneService) {
    // For new lineMeshes that user takes while in orthoprojection mode
    this.broadcastService.on(EVENT_TYPE.REFRESH_CUSTOM_LOD).subscribe(() => {
      this.customScaleMeshes = this.sceneService.scene.getMeshesByTags(SKETCH_MESHES_CUSTOM_SCALE);
    });
  }

  /**
   *
   * @param oldMin start of the original scale
   * @param oldMax end of the original scale
   * @param newMin start of the new scale
   * @param newMax end of the new scale
   */
  private changeScale(
    oldMin: number,
    oldMax: number,
    newMin: number,
    newMax: number,
  ): (x: number) => number {
    const offset = newMin - oldMin;
    const scale = (newMax - newMin) / (oldMax - oldMin);
    return (x: number) => offset + scale * x;
  }

  private resetCameraZoom = (camera: ArcRotateCamera, canvas: HTMLCanvasElement) => {
    camera.orthoLeft = -32;
    camera.orthoRight = 32;
    this.setTopBottomRatio(camera, canvas);
  };

  private setTopBottomRatio = (camera: ArcRotateCamera, canvas: HTMLCanvasElement) => {
    const ratio = canvas.height / canvas.width;
    if (camera.orthoRight) {
      camera.orthoTop = camera.orthoRight * ratio;
    }
    if (camera.orthoLeft) {
      camera.orthoBottom = camera.orthoLeft * ratio;
    }
  };

  private calculateZoomTarget(engine: Engine, cameraArcRotate: ArcRotateCamera): Vector3 {
    return Vector3.Unproject(
      new Vector3(this.sceneService.scene.pointerX, this.sceneService.scene.pointerY, 0),
      engine.getRenderWidth(),
      engine.getRenderHeight(),
      cameraArcRotate.getWorldMatrix(),
      cameraArcRotate.getViewMatrix(),
      cameraArcRotate.getProjectionMatrix(),
    );
  }

  initOrthoprojection(
    canvas: HTMLCanvasElement,
    engine: Engine,
    cameraArcRotate: ArcRotateCamera,
    maxZoom?: number,
  ): void {
    this.customScaleMeshes = this.sceneService.scene.getMeshesByTags(SKETCH_MESHES_CUSTOM_SCALE);
    this.totalZoom = 0;
    this.resetCameraZoom(cameraArcRotate, canvas);
    // Lock the camera's placement, zooming is done manually in orthographic mode.
    // Locking this fixes strange issues with Hemispheric Light
    cameraArcRotate.lowerRadiusLimit = cameraArcRotate.radius;
    cameraArcRotate.upperRadiusLimit = cameraArcRotate.radius;
    cameraArcRotate.mode = Camera.ORTHOGRAPHIC_CAMERA;
    this.zoomObserver = this.sceneService.scene.onPointerObservable.add(({ event }: any) => {
      const delta =
        Math.max(-1, Math.min(1, event.wheelDelta || -event.detail || event.deltaY)) *
        orthoConfig.scrollSpeed;
      if (maxZoom) {
        this.makeZoomCalculations(maxZoom, delta, cameraArcRotate);
      } else {
        if (delta !== 0) {
          this.totalZoom += delta;
          this.zoom2DView(cameraArcRotate, delta);
        }
      }
    }, PointerEventTypes.POINTERWHEEL);

    if (!this.zoomTarget) {
      this.zoomTarget = this.calculateZoomTarget(engine, cameraArcRotate);
    }

    this.zoomTargetObserver = this.sceneService.scene.onPointerObservable.add(() => {
      this.zoomTarget = this.calculateZoomTarget(engine, cameraArcRotate);
    }, PointerEventTypes.POINTERMOVE);
  }

  removeOrthoprojectionObservables(): void {
    this.sceneService.scene.onPointerObservable.remove(this.zoomObserver);
    this.sceneService.scene.onPointerObservable.remove(this.zoomTargetObserver);
  }

  resetOrthograpichCamera(camera: ArcRotateCamera) {
    camera.orthoBottom = ORTHOGRAPHIC_CAMERA_SPECS.bottom;
    camera.orthoLeft = ORTHOGRAPHIC_CAMERA_SPECS.left;
    camera.orthoRight = ORTHOGRAPHIC_CAMERA_SPECS.right;
    camera.orthoTop = ORTHOGRAPHIC_CAMERA_SPECS.top;
  }

  // eslint-disable-next-line complexity
  zoom2DView(camera: ArcRotateCamera, delta: number) {
    if (this.zoomTarget) {
      const totalX =
        camera.orthoLeft && camera.orthoRight
          ? Math.abs(camera.orthoLeft - camera.orthoRight)
          : null;
      const totalY =
        camera.orthoTop && camera.orthoBottom
          ? Math.abs(camera.orthoTop - camera.orthoBottom)
          : null;
      const panningSensibility = totalX ? 6250 / Math.abs(totalX / 2) : null;
      if ((panningSensibility && panningSensibility <= this.maxPanningSensibility) || delta < 0) {
        const fromCoordLeft = camera.orthoLeft ? camera.orthoLeft - this.zoomTarget.x : null;
        const fromCoordRight = camera.orthoRight ? camera.orthoRight - this.zoomTarget.x : null;
        const fromCoordTop = camera.orthoTop ? camera.orthoTop - this.zoomTarget.y : null;
        const fromCoordBottom = camera.orthoBottom ? camera.orthoBottom - this.zoomTarget.y : null;
        const ratioLeft = fromCoordLeft && totalX ? fromCoordLeft / totalX : null;
        const ratioRight = fromCoordRight && totalX ? fromCoordRight / totalX : null;
        const ratioTop = fromCoordTop && totalY ? fromCoordTop / totalY : null;
        const ratioBottom = fromCoordBottom && totalY ? fromCoordBottom / totalY : null;
        const orthoLeft = ratioLeft ? ratioLeft * delta : null;
        const orthoRight = ratioRight ? ratioRight * delta : null;
        const aspectRatio = totalY && totalX ? totalY / totalX : null;
        const orthoTop = aspectRatio && ratioTop ? ratioTop * delta * aspectRatio : null;
        const orthoBottom = aspectRatio && ratioBottom ? ratioBottom * delta * aspectRatio : null;
        if (camera.orthoBottom && orthoBottom) camera.orthoBottom -= orthoBottom;
        if (camera.orthoTop && orthoTop) camera.orthoTop -= orthoTop;
        if (camera.orthoLeft && orthoLeft) camera.orthoLeft -= orthoLeft;
        if (camera.orthoRight && orthoRight) camera.orthoRight -= orthoRight;
        if (panningSensibility) camera.panningSensibility = panningSensibility;
      }
    }
  }

  private makeZoomCalculations(maxZoom: number, delta: number, cameraArcRotate: ArcRotateCamera) {
    const { LODScale, gizmoScale } = orthoConfig;
    const rescaleLOD = this.changeScale(0, maxZoom, LODScale.min, LODScale.max);
    const rescaleGizmo = this.changeScale(0, maxZoom, gizmoScale.min, gizmoScale.max);
    if ((delta !== 0 && this.totalZoom < maxZoom) || delta < 0) {
      this.sceneService.scene.__orthoprojectionCustomGizmoScale = rescaleGizmo(this.totalZoom);
      this.sceneService.scene.__orthoprojectionCustomLODScale = rescaleLOD(this.totalZoom);
      const gizmos = [
        ...this.sceneService.scene.__gizmoManagerList.sketchLines,
        ...this.sceneService.scene.__gizmoManagerList.sketchCircles,
      ];
      gizmos.forEach((gizmoManager) => {
        if (gizmoManager.gizmos.positionGizmo) {
          const orthoGizmoScale = this.sceneService.scene.__orthoprojectionCustomGizmoScale;
          gizmoManager.gizmos.positionGizmo.scaleRatio = Math.abs(orthoGizmoScale);
          gizmoManager.gizmos.positionGizmo.yPlaneGizmo.scaleRatio = Math.abs(orthoGizmoScale);
        }
      });
      this.customScaleMeshes.forEach((mesh) => {
        mesh.scaling = new Vector3(
          this.sceneService.scene.__orthoprojectionCustomLODScale,
          this.sceneService.scene.__orthoprojectionCustomLODScale,
          this.sceneService.scene.__orthoprojectionCustomLODScale,
        );
      });
      this.totalZoom += delta;
      this.zoom2DView(cameraArcRotate, delta);
    }
  }
}
