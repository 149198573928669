<div class="header">
  <div class="header__info">
    <a class="header__logo_wrapper" [routerLink]="['/', companyId, 'models']">
      <svg-icon [src]="skyfishLogo" svgClass="header__logo"></svg-icon>
      <span class="header__version">v.{{ version }}</span>
    </a>
  </div>

  <div class="header__settings">
    <ng-container *ngIf="isAuth">
      <button
        type="button"
        [nbContextMenu]="items"
        nbContextMenuTag="profile-menu"
        type="button"
        class="header__user header__opacity_button"
      >
        <span class="header__name">{{ user.firstName }} {{ user.lastName }}</span>
        <span class="header__title">{{ roles }}</span>
      </button>

      <div
        style="width: 1px; height: 4.75rem; background-color: lightgray; margin-right: 20px;"
      ></div>

      <div
        *ngIf="isUserInOtherCompanies() || hasAccessToAdminPanel()"
        class="header__button"
        (click)="toggleMenu()"
      >
        <svg-icon
          [src]="getCompanyLogo() || ''"
          svgClass="header__logo header__opacity_button"
        ></svg-icon>
        <nb-icon class="header__logo__arrow" icon="arrow-ios-downward-outline"></nb-icon>
      </div>

      <svg-icon
        *ngIf="!isUserInOtherCompanies() && !hasAccessToAdminPanel()"
        [src]="getCompanyLogo() || ''"
        svgClass="header__logo"
      ></svg-icon>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="showDropDownMenu">
  <app-company-selection-drop-down></app-company-selection-drop-down>
</ng-container>
