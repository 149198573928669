import { NebularModule } from '@App/app/modules';
import { DownloadAnalyticsButtonComponent } from '@App/app/shared/components/main-datatable/download-analytics-button/download-analytics-button/download-analytics-button.component';
import { MainDatatableComponent } from '@App/app/shared/components/main-datatable/main-datatable.component';
import { ModelSiteHeaderModule } from '@App/app/shared/components/model-site-header/model-site-header.module';
import { SharedModule } from '@App/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbCardModule } from '@nebular/theme';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModelsGridComponent } from './models-site/models-grid/models-grid.component';
import { ModelsMapComponent } from './models-site/models-map/models-map.component';
import { ModelsSiteComponent } from './models-site/models-site.component';
import { ModelsTableFiltersComponent } from './models-site/models-table-filters/models-table-filters.component';
import { ModelsTableComponent } from './models-site/models-table/models-table.component';
import { ThumbnailPipe } from './models-site/models-table/pipes/thumbnail/thumbnail.pipe';
import { ProcessingSiteComponent } from './processing-site/processing-site.component';
import { ProcessingTableFiltersComponent } from './processing-site/processing-table-filters/processing-table-filters.component';
import { ProcessingTableComponent } from './processing-site/processing-table/processing-table.component';
import { ActionsButtonsComponent } from './shared/actions-buttons/actions-buttons.component';
import { FilterFooterComponent } from './shared/filter-footer/filter-footer.component';
import { FilterInputComponent } from './shared/filter-input/filter-input.component';
import { FilterLayoutComponent } from './shared/filter-layout/filter-layout.component';
import { AsPageRangeInfoPipe } from './shared/pipes/as-page-range-info/as-page-range-info.pipe';
import { SiteLayoutComponent } from './shared/site-layout/site-layout.component';
import { UsersSiteComponent } from './users-site/users-site.component';
import { UsersTableFiltersComponent } from './users-site/users-table-filters/users-table-filters.component';
import { UsersTableComponent } from './users-site/users-table/users-table.component';

@NgModule({
  declarations: [
    ProcessingSiteComponent,
    UsersSiteComponent,
    ModelsSiteComponent,
    SiteLayoutComponent,
    ActionsButtonsComponent,
    ModelsTableComponent,
    ProcessingTableComponent,
    UsersTableComponent,
    MainDatatableComponent,
    UsersTableFiltersComponent,
    ProcessingTableFiltersComponent,
    ModelsTableFiltersComponent,
    FilterFooterComponent,
    FilterInputComponent,
    FilterLayoutComponent,
    ModelsMapComponent,
    DownloadAnalyticsButtonComponent,
    ThumbnailPipe,
    ModelsGridComponent,
    AsPageRangeInfoPipe,
  ],
  imports: [
    CommonModule,
    NebularModule,
    NgxDatatableModule,
    NbCardModule,
    CommonModule,
    SharedModule,
    NgxDatatableModule,
    ModelSiteHeaderModule,
    ReactiveFormsModule,
    RouterModule,
    AngularSvgIconModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AsPageRangeInfoPipe, ThumbnailPipe],
  exports: [MainDatatableComponent],
})
export class SitesModule {}
