<div class="text text--error">
  Wrong password. Password:
  <ul>
    <li class="text text--error">must be at least 8 characters long</li>
    <li class="text text--error">should contain at least 1 number</li>
    <li class="text text--error">should contain at least 1 special character</li>
    <li class="text text--error">should contain at least 1 uppercase character</li>
    <li class="text text--error">should contain at least 1 lowercase character</li>
  </ul>
</div>
