import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FilterInputComponent {
  @Input() placeholder: string;
  @Input() value: string;
  @Input() showClearButton: boolean;
  @Output() resetFilters = new EventEmitter<void>();
  @Output() toggleFilterPopover = new EventEmitter();
  @Output() queryChange = new EventEmitter<string>();

  togglePopover() {
    this.toggleFilterPopover.emit();
  }

  onChangeEvent(event: any) {
    this.queryChange.emit(event.target.value);
  }

  onKeyUpEvent(event: any) {
    this.queryChange.emit(event.target.value);
  }

  onResetFilters() {
    this.resetFilters.emit();
  }
}
