import { importedCuboidsCenter } from '@App/app/engine/helpers/rectangle-tools-helpers';
import { CuboidPickerService } from '@App/app/engine/services/layer-services/rectangle-tools-services/cuboid-services/cuboid-picker.service';
import { CreateTubePickerService } from '@App/app/engine/services/layer-services/tube-services/create-tube-picker.service';
import { Cuboid } from '@App/app/entities/layer/cuboid.model';
import {
  LAYER_TYPES,
  LAYER_TYPES_AUTO_MAPPING_IMPORTABLE,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Layer, httpLayer } from '@App/app/entities/layer/layer.model';
import { Tube } from '@App/app/entities/layer/tube.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Vector3 } from 'babylonjs';
import { map } from 'rxjs/operators';
import { AutomappingService } from '../../services/automapping/automapping.service';
import { addLayers, importLayers } from './../actions/layers.actions';

@Injectable()
export class ImportLayerEffects {
  constructor(
    private actions: Actions,
    private cuboidPickerService: CuboidPickerService,
    private createTubePickerService: CreateTubePickerService,
    private automappingService: AutomappingService,
  ) {}

  importLayers = createEffect(() => {
    return this.actions.pipe(
      ofType(importLayers),
      map(({ layers }) => {
        let layersCenter: Vector3 | null = null;
        const cuboids = layers.filter((layer) => layer.type === LAYER_TYPES.CUBOID);
        if (cuboids.length) {
          layersCenter = importedCuboidsCenter(cuboids as Cuboid[]);
        }
        const prepareToImport: {
          [key: string]: (layer: Layer) => httpLayer;
        } = {
          [LAYER_TYPES.CUBOID]: (layer: Layer) =>
            this.cuboidPickerService.importCuboid(layer as Cuboid, layersCenter as Vector3),
          [LAYER_TYPES.TUBES]: (layer: Layer) =>
            this.createTubePickerService.importTube(layer as Tube),
        };
        const httpBody = layers.reduce((acc, layer) => {
          const newLayer = { ...layer, isVisible: false, isSaved: true };
          if (
            Object.values(LAYER_TYPES_AUTO_MAPPING_IMPORTABLE).includes(
              (layer.type as unknown) as LAYER_TYPES_AUTO_MAPPING_IMPORTABLE,
            )
          ) {
            const importFunction = prepareToImport[layer.type];
            return importFunction ? [...acc, importFunction(newLayer)] : [...acc];
          }
          return [...acc];
        }, []);
        this.automappingService.setLoading(false);
        return addLayers({ layers: httpBody as LayerUI[] });
      }),
    );
  });
}
