import { CreateAngleIronService } from '@App/app/engine/services/layer-services/angle-iron-services/create-angle-iron.service';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { AngleIronPartsArrays } from '@App/app/entities/layer/angle-iron.model';
import {
  ANGLE_IRON_PART,
  ANGLE_IRON_PART_NAME,
  ANGLE_IRON_STRICT,
  LAYER_EVENTS,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractMesh } from 'babylonjs/Meshes/abstractMesh';

@UntilDestroy()
@Component({
  selector: 'app-create-angle-iron-details',
  templateUrl: './create-angle-iron-details.component.html',
  styleUrls: ['./create-angle-iron-details.component.scss'],
})
export class CreateAngleIronDetailsComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() generate = new EventEmitter();

  LAYER_EVENTS = LAYER_EVENTS;
  ANGLE_IRON_PART_NAME = ANGLE_IRON_PART_NAME;
  activeLayerEvent: LAYER_EVENTS;
  activePart: ANGLE_IRON_PART | null;
  highlightedSample: { part: ANGLE_IRON_PART; i: number };
  samples: AngleIronPartsArrays<AbstractMesh>;
  isDetailsVisible = true;

  constructor(
    private createAngleIronService: CreateAngleIronService,
    private layerEventsService: LayerEventsService,
  ) {}

  ngOnInit(): void {
    this.activeLayerEvent = this.layerEventsService.activeLayerEvent.value;
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_ANGLE_IRON) {
      this.createAngleIronService.angleIronDataChange$.pipe(untilDestroyed(this)).subscribe(() => {
        this.activePart = this.createAngleIronService.activePart;
        this.highlightedSample = this.createAngleIronService.highlightedSample;
        const samples = { ...this.createAngleIronService.samples };
        Object.entries(samples).filter(([key, value]) => {
          samples[Number(key) as keyof AngleIronPartsArrays<AbstractMesh>] = value.filter(
            (mesh) => !!mesh,
          );
        });
        this.samples = samples as AngleIronPartsArrays<AbstractMesh>;
      });
      this.createAngleIronService.setActivePart(ANGLE_IRON_PART.SIDE_1_OUTER);
    }
  }

  toggleHighlightSample({ part, i }: { part: ANGLE_IRON_STRICT; i: number }) {
    this.createAngleIronService.highlightSample(part, i);
  }

  onDeleteSample({ part, i }: { part: ANGLE_IRON_STRICT; i: number }) {
    this.createAngleIronService.deleteSamplePoint(part, i);
  }

  onGenerate() {
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_ANGLE_IRON) {
      this.createAngleIronService.createAngleIron();
      this.generate.emit();
      this.isDetailsVisible = false;
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  isSampleHighlighted(part: ANGLE_IRON_PART, i: number) {
    return this.highlightedSample.part === part && this.highlightedSample.i === i;
  }

  setActivePart({ part }: { part: ANGLE_IRON_STRICT }) {
    this.createAngleIronService.setActivePart(part);
  }

  isGenerateAvailable() {
    return (
      this.samples[ANGLE_IRON_PART.SIDE_1_OUTER].length > 2 &&
      this.samples[ANGLE_IRON_PART.SIDE_2_OUTER].length > 2 &&
      this.samples[ANGLE_IRON_PART.SIDE_1_INNER].length > 0 &&
      this.samples[ANGLE_IRON_PART.SIDE_2_INNER].length > 0
    );
  }
}
