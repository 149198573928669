import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LayerDetailsEditForm } from '@App/app/entities/forms/layer-details-edit-form.model';
import { Annotation } from '@App/app/entities/layer/annotation.model';
import { AnnotationPriorities } from '@App/app/entities/shared/annotation-priorities.enum';
import { UnitsService } from '@App/app/pages/viewer/services/utils/units.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayerDetailsService } from '../../../services/layer-details-service/layer-details.service';
import { BaseLayerPropertiesComponent } from '../base-layer-properties/base-layer-properties.component';

type EditForm = LayerDetailsEditForm & FormGroup<{ priority: FormControl<AnnotationPriorities> }>;

@UntilDestroy()
@Component({
  selector: 'app-annotation-properties',
  templateUrl: './annotation-properties.component.html',
})
export class AnnotationPropertiesComponent extends BaseLayerPropertiesComponent<Annotation>
  implements OnInit {
  constructor(
    private viewerLayerService: ViewerLayerService,
    unitsService: UnitsService,
    layerDetailsService: LayerDetailsService,
  ) {
    super(unitsService, layerDetailsService);
  }

  ngOnInit(): void {
    const editForm = this.editForm as EditForm;

    editForm.addControl('priority', new FormControl(this._getDefaultPriorityValue()));

    editForm.controls.priority.valueChanges.pipe(untilDestroyed(this)).subscribe((priority) => {
      if (!priority) {
        editForm.controls.priority.patchValue(this._getDefaultPriorityValue());
      } else {
        this.viewerLayerService.updateAnnotationPriority(this.layer.id, priority);
      }
    });
  }

  private _getDefaultPriorityValue() {
    return this.layer.data.priority || AnnotationPriorities.LOW;
  }
}
