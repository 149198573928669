<div class="profile__title">
  Profile
  <nb-icon id="icon" class="layers__header_svg profile__icon" icon="person-outline"></nb-icon>
</div>
<form class="profile__form" [formGroup]="profileForm" (ngSubmit)="onSubmit(profileForm)">
  <div class="profile__section">
    <div class="profile__label">First name</div>
    <input
      class="profile__input"
      nbInput
      type="text"
      placeholder="First name"
      name="firstName"
      formControlName="firstName"
      required
    />
  </div>
  <div class="profile__section">
    <div class="profile__label">Last name</div>
    <input
      class="profile__input"
      nbInput
      type="text"
      placeholder="Last name"
      name="lastName"
      formControlName="lastName"
      required
    />
  </div>
  <div class="profile__section">
    <div class="profile__label">Email address</div>
    <input
      class="profile__input"
      nbInput
      type="text"
      placeholder="Email address"
      name="email"
      formControlName="email"
      required
    />
  </div>
</form>
