<div id="sortable" (mouseout)="updateCurrentFolderByLayer(root?.layer)">
  <ng-container
    *ngTemplateOutlet="nodeView; context: { node: root, childOfRoot: false }"
  ></ng-container>
</div>

<ng-template #nodeView let-node="node" let-childOfRoot="childOfRoot">
  <div
    *ngIf="!(keywords$ | async)?.length && node"
    class="list-node"
    [ngClass]="{
      '--dragging-mode': isDragging$ | async,
      '--curr-folder': node.layer.id === currentFolderId
    }"
    [attr.data-testId]="node.layer === root?.layer ? 'root-folder' : 'layer'"
    [attr.data-id]="node.layer.id"
  >
    <ng-container *ngIf="node.layer.type === LAYER_TYPES.FOLDERS">
      <ng-container
        *ngTemplateOutlet="folderView; context: { layer: node.layer, childOfRoot }"
      ></ng-container>
      <div
        [attr.data-folder-id]="node.layer.id"
        [style.margin-left]="node.layer === root?.layer ? '0' : '20px'"
        [style.display]="node.layer.open || node.layer === root?.layer ? 'block' : 'none'"
        [sortablejs]="node.children"
        [sortablejsOptions]="options"
      >
        <ng-container *ngFor="let child of node.children; trackBy: trackById">
          <ng-container
            *ngTemplateOutlet="
              nodeView;
              context: { node: child, childOfRoot: node.layer === root?.layer }
            "
          ></ng-container>
        </ng-container>
        <div class="layers__empty layers__static" *ngIf="!node.children.length">Empty</div>
        <ng-container *ngIf="node | includesOnlyFolders">
          <ng-container *ngIf="node.layer === root?.layer; nonRoot">
            <!--Some empty space under the last element-->
            <div
              *ngFor="let _ of [0, 1, 2, 3, 4]"
              class="layers__empty layers__static layers__ghost_block"
            ></div>
          </ng-container>
          <ng-template #nonRoot>
            <div class="layers__empty layers__static layers__ghost_block"></div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="node.layer.type !== LAYER_TYPES.FOLDERS">
      <ng-container
        *ngTemplateOutlet="layerView; context: { layer: node.layer, childOfRoot }"
      ></ng-container>
    </ng-container>
  </div>
  <div *ngIf="(keywords$ | async)?.length">
    <ng-container *ngTemplateOutlet="filteredView"></ng-container>
  </div>
</ng-template>

<ng-template #filteredView>
  <ng-container *ngFor="let layer of filteredLayers$ | async">
    <div *ngIf="layer.type === LAYER_TYPES.FOLDERS">
      <ng-container
        *ngTemplateOutlet="folderView; context: { layer, childOfRoot: true }"
      ></ng-container>
    </div>
    <div *ngIf="layer.type !== LAYER_TYPES.FOLDERS">
      <ng-container
        *ngTemplateOutlet="layerView; context: { layer, childOfRoot: true }"
      ></ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #layerView let-layer="layer" let-childOfRoot="childOfRoot">
  <app-layer
    *ngIf="!(layer | isExpandable)"
    data-testId="app-layer"
    [layer]="layer"
    [classes]="{
      '--selected': layer | isLayerSelected,
      '--visible': layer | isLayerVisible,
      '--dragging': ((layer | isLayerSelected) && (isDragging$ | async)) || false,
      '--with-left-border': !childOfRoot
    }"
    (toggleLayerVisibility)="onToggleLayerVisibility(layer)"
    (toggleLayerGuiVisibility)="onToggleLayerGuiVisibility(layer)"
    (showDetails)="onShowDetails(layer)"
    [guiVisibility]="layer | isGuiLayerVisible"
    [hasGuiToggler]="layer | hasGuiToggler"
    (clickLayerPath)="onClickLayerPath(layer)"
    [layerPath]="(keywords$ | async) ? (layer | layerPath) : ''"
    [dragDisabled]="!!(keywords$ | async)"
    (click)="onLayerClick(layer)"
    (mouseover)="updateCurrentFolderByLayer(layer)"
  ></app-layer>
  <app-layer-expandable
    *ngIf="layer | isExpandable"
    data-testId="app-layer"
    [layer]="layer"
    [showExpandableArrow]="!(keywords$ | async)?.length"
    [classes]="{
      '--selected': layer | isLayerSelected,
      '--visible': layer | isLayerVisible,
      '--dragging': ((layer | isLayerSelected) && (isDragging$ | async)) || false,
      '--with-left-border': !childOfRoot
    }"
    (toggleLayerVisibility)="onToggleLayerVisibility(layer)"
    [layerPath]="(keywords$ | async) ? (layer | layerPath) : ''"
    [dragDisabled]="!!(keywords$ | async)"
    (clickLayerPath)="onClickLayerPath(layer)"
    (showDetails)="onShowDetails(layer)"
    (selectChild)="onChildLayerSelect($event)"
    (clickLayer)="onLayerClick(layer)"
    (editLayer)="onShowDetails(layer)"
    (expandLayer)="onExpandableLayerClick(layer)"
    (mouseover)="updateCurrentFolderByLayer(layer)"
  >
  </app-layer-expandable>
</ng-template>

<ng-template #folderView let-layer="layer" let-childOfRoot="childOfRoot">
  <app-folder
    data-testId="app-folder"
    *ngIf="!(layer === root?.layer)"
    [classes]="{
      '--visible': layer | isFolderVisible,
      '--selected': layer | isLayerSelected,
      '--with-left-border': !childOfRoot
    }"
    [folder]="layer"
    [showExpandableArrow]="!(keywords$ | async)?.length"
    [layerPath]="(keywords$ | async) ? (layer | layerPath) : ''"
    (clickLayerPath)="onClickLayerPath(layer)"
    [isFolderVisible]="layer | isFolderVisible"
    [isAnyGuiVisible]="layer | isAnyGuiLayerInFolder"
    (selectFolder)="onFolderSelect(layer)"
    (toggleAllGuiFolder)="onAllGuiFolderToggle(layer, $event)"
    (expand)="onFolderClick($event)"
    (click)="onLayerClick(layer)"
    (mouseover)="updateCurrentFolderByLayer(layer)"
  ></app-folder>
</ng-template>
