import {
  INTERNET_CONNECTION_ESTABLISHED,
  INTERNET_CONNECTION_LOST,
} from '@App/app/configs/toastr-events.config';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from '../../services/network/network.service';

@Component({
  selector: 'app-internet-connection-status',
  templateUrl: './internet-connection-status.component.html',
  styleUrls: ['./internet-connection-status.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class InternetConnectionLostInfoComponent implements OnInit {
  isInternetAvailable$: Observable<boolean>;
  INTERNET_CONNECTION_LOST = INTERNET_CONNECTION_LOST;
  INTERNET_CONNECTION_ESTABLISHED = INTERNET_CONNECTION_ESTABLISHED;

  constructor(private _networkService: NetworkService) {}

  ngOnInit() {
    this.isInternetAvailable$ = this._networkService.isInternetAvailable$;
  }
}
