<div class="layer_details__row">
  <div class="layer_details__label">Priority</div>
  <div class="layer_details__value">
    <span *ngIf="!(layer | isLayerBeingEdited)">
      {{ layer.data.priority | titlecase }}
    </span>
    <form [formGroup]="editForm" class="select_form" *ngIf="layer | isLayerBeingEdited">
      <nb-select
        formControlName="priority"
        status="control"
        size="tiny"
        data-testId="annotation-props-priority-select"
      >
        <nb-option value="low">Low</nb-option>
        <nb-option value="normal">Normal</nb-option>
        <nb-option value="high">High</nb-option>
      </nb-select>
    </form>
  </div>
</div>
