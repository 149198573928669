import { getPicketPointGeoCoords } from '@App/app/engine/helpers/layers-helpers';
import { BasePlaneService } from '@App/app/engine/services/layer-services/base-plane-services/base-plane.service';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { ClipboardService } from '@App/app/shared/services/clipboard/clipboard.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import { selectCurrentModelLocation } from '../../../models/store/models/selectors/models.selectors';

@Component({
  selector: 'app-layer-details-geolocation',
  templateUrl: './layer-details-geolocation.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LayerDetailsGeolocationComponent implements OnInit {
  @Input() position: Coordinates3;

  baseGeoPosition: GeoCoords;

  constructor(
    private store: Store,
    private basePlaneService: BasePlaneService,
    private _clipboardService: ClipboardService,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectCurrentModelLocation)
      .pipe(
        first(),
        filter((loc) => !!(loc?.latitude && loc?.longitude)),
      )
      .subscribe((loc: GeoCoords) => {
        this.baseGeoPosition = loc;
      });
  }

  copyCoordinates(position: Coordinates3) {
    const geoCoords = this.calculateGeoCords(position);
    this._clipboardService.copyGeoCoords(geoCoords);
  }

  calculateGeoCords(position: Coordinates3) {
    const baseAxePos = this.basePlaneService.getBasePlanesPosition();
    return getPicketPointGeoCoords(position, baseAxePos, this.baseGeoPosition);
  }
}
