import { BaseFile } from '@App/app/entities/files/files.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ModelFilesHttpService } from '../../../services/model-files-http/model-files-http.service';
import { loadModelFilesSuccess } from '../actions/model-files.actions';
import { loadAllModelsWithThumbnails } from '../actions/models.actions';

@Injectable()
export class LoadModelThumbnailsEffects {
  constructor(private actions: Actions, private _modelFilesHttpService: ModelFilesHttpService) {}

  loadModelThumbnails = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAllModelsWithThumbnails),
      mergeMap(() => {
        return this._modelFilesHttpService.getModelThumbnails().pipe(
          map(
            (resp) => {
              return loadModelFilesSuccess({
                objects: BaseFile.fromHttpFiles(resp),
              });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });
}
