import { Injectable } from '@angular/core';
import { createGui } from '@App/app/entities/layer/create-gui.model';
import { Cuboid, ViewerCuboidLayer } from '@App/app/entities/layer/cuboid.model';
import { LoadedMeshes } from '@App/app/entities/viewer/loaded-meshes';
import { Mesh, Vector3, VertexBuffer } from 'babylonjs';
import { rotationQuaternion } from '../../../../helpers/layers-helpers';
import { minMax } from '../../../../helpers/rectangle-tools-helpers';
import { GenerateGUIService } from '../../../gui-services/generate-gui-service/generate-gui.service';
import { VolumeUtilsService } from '../shared/volume-utils.service';

@Injectable({
  providedIn: 'root',
})
export class CuboidUtilsService implements createGui {
  constructor(
    private generateGUIService: GenerateGUIService,
    private volumeUtilsService: VolumeUtilsService,
  ) {}

  showCuboid(cuboid: Cuboid) {
    const newCuboid = cuboid as ViewerCuboidLayer;
    const { min, max } = minMax(cuboid);
    newCuboid.mesh = this.volumeUtilsService.initBoundingBox(min, max, cuboid);
    newCuboid.mesh.rotationQuaternion = rotationQuaternion(newCuboid);
    newCuboid.mesh.visibility = 0.7;
    newCuboid.gui = this.createGui(newCuboid);
    return newCuboid;
  }

  createGui(layer: ViewerCuboidLayer) {
    return this.generateGUIService.createNameTag(layer.name, layer.mesh as Mesh, true, layer.id);
  }

  createVertices(box: Mesh, loadedMeshes: LoadedMeshes[]): Vector3[] {
    const boundingBox = box.getBoundingInfo().boundingBox;
    const meshes = this.initMeshesArray(loadedMeshes);
    const volumeVerticesPosition: Vector3[] = [];
    const tmpVector3 = new Vector3();
    meshes.forEach((testMesh) => {
      testMesh.computeWorldMatrix(true);
      const positions = testMesh.getVerticesData(VertexBuffer.PositionKind);
      if (positions) {
        for (let i = 0; i < positions.length; i += 3) {
          tmpVector3.set(positions[i], positions[i + 1], positions[i + 2]);
          Vector3.TransformCoordinatesToRef(tmpVector3, testMesh.getWorldMatrix(), tmpVector3);
          if (boundingBox.intersectsPoint(tmpVector3)) {
            volumeVerticesPosition.push(tmpVector3.clone());
          }
        }
      }
    });

    return volumeVerticesPosition;
  }

  private initMeshesArray(loadedMeshes: LoadedMeshes[]) {
    return loadedMeshes.reduce((acc, element) => {
      acc.push(element.meshes[1] as Mesh);
      return acc;
    }, [] as Mesh[]);
  }
}
