import { AddUserForm } from '@App/app/entities/users/user';
import { match } from '@App/app/shared/match-form-validator';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { FormValidators } from '../../../shared/form.validators';
import { passwordRegExpPattern } from './../../../shared/password-pattern';
import { AddUserService } from './add-user.service';

@UntilDestroy()
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  companyId: number | null;
  requestSended$: Observable<boolean>;
  showPassword = false;
  showConfirmPassword = false;
  form: FormGroup = this.fb.group(
    {
      firstName: ['', [Validators.required, FormValidators.noOnlySpaces]],
      lastName: ['', [Validators.required, FormValidators.noOnlySpaces]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [FormValidators.phoneValidator]],
      password: [
        '',
        [
          Validators.required,
          FormValidators.noOnlySpaces,
          Validators.pattern(passwordRegExpPattern),
        ],
      ],
      confirmPassword: [, [Validators.required, FormValidators.noOnlySpaces]],
      roleIds: [[]],
    },
    {
      validator: [match('password', 'confirmPassword', true)],
    },
  );

  constructor(
    private authService: AuthService,
    private addUserService: AddUserService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.authService.currentEndpoint$.pipe(untilDestroyed(this)).subscribe((endpoint) => {
      this.companyId = endpoint?.id || null;
    });
    this.requestSended$ = this.addUserService.isRequestSended$;
  }

  onSubmit(user: AddUserForm) {
    this.addUserService.addNewUser(user);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
