import { AuthService } from '@App/app/auth/auth.service';
import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { RendersHttpService } from '../../../services/renders-http-service/renders-http.service';
import { RendersService } from '../../../services/renders-service/renders.service';
import { loadCurrentRender, loadCurrentRenderSuccess } from '../actions/renders.actions';

@Injectable()
export class LoadCurrentRenderEffects {
  constructor(
    private actions: Actions,
    private rendersHttpService: RendersHttpService,
    private rendersService: RendersService,
    private authService: AuthService,
    private router: Router,
  ) {}

  loadCurrentRender = createEffect(() => {
    return this.actions.pipe(
      ofType(loadCurrentRender),
      mergeMap(({ modelId, renderId }) => {
        this.rendersService.setCurrentRender(null);
        return this.rendersHttpService.getExtendedRender(modelId, renderId).pipe(
          map(
            (resp: ExtendedRender) => {
              return loadCurrentRenderSuccess({ render: new ExtendedRender({ ...resp }) });
            },
            catchError((error) => {
              const endpoint = this.authService.getCurrentEndpoint();
              const sitesUrl = endpoint ? `${endpoint.id}/models/${modelId}` : '';
              this.router.navigate([sitesUrl]);
              return of(error);
            }),
          ),
        );
      }),
    );
  });

  loadCurrentRenderSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(loadCurrentRenderSuccess),
        tap(({ render }) => {
          this.rendersService.setCurrentRender(render);
        }),
      );
    },
    { dispatch: false },
  );
}
