import { WRONG_METADATA_INFO } from '@App/app/configs/uploader.config';
import { Model } from '@App/app/entities/models/model.model';
import { selectModels } from '@App/app/pages/models/store/models/selectors/models.selectors';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import formatUnicorn from 'format-unicorn/safe';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddFilesProcessStatus } from '../../models/add-files-process-status.model';
import { FileMetadataInfo } from '../../models/uploaded-image-metadata.model';
import {
  selectFiles,
  selectIsGCPAdded,
  selectXlifData,
} from '../../store/files/selectors/files.selectors';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UploaderStorageService {
  private files: File[] = [];
  private _wrongMetadataInfo$ = new BehaviorSubject<string | null>('');
  xlifData: FileMetadataInfo[] = [];
  wrongMetadataInfo = this._wrongMetadataInfo$.asObservable();
  removingMetadata$ = new BehaviorSubject(false);
  logs$ = new BehaviorSubject('');
  addFilesProcessStatus$ = new BehaviorSubject<AddFilesProcessStatus | null>(null);
  openATSettingsDialog$ = new Subject<number | null>();
  cancelUploadSubject = new Subject();
  uploadStarted$ = new BehaviorSubject(false);
  isPendingUpload$ = new BehaviorSubject(false);
  uploadProgress$ = new BehaviorSubject(0);
  validXlifData$ = new BehaviorSubject<FileMetadataInfo[]>([]);
  noCoordsFileNames$ = new BehaviorSubject<string[]>([]);
  wrongCoordsFileNames$ = new BehaviorSubject<string[]>([]);
  models$ = new BehaviorSubject<Model[] | null>(null);
  selectedModelId$ = new BehaviorSubject<number | null>(null);
  showProblematicFiles = new BehaviorSubject(false);
  runATAutomatically = new BehaviorSubject(true);
  shouldRunATAutomatically$: Observable<boolean>;
  keywords = new BehaviorSubject<string>('');
  companyId: number;
  buildProcessId: number | null = null;
  invalidGCPFormat$ = new BehaviorSubject(false);
  canStartUpload$: Observable<boolean>;

  constructor(private store: Store) {
    this.store
      .select(selectFiles)
      .pipe(untilDestroyed(this))
      .subscribe((files) => (this.files = files));

    this.store
      .select(selectXlifData)
      .pipe(untilDestroyed(this))
      .subscribe((xlifData) => (this.xlifData = xlifData));

    this.store.select(selectModels(false)).subscribe((models) => {
      this.models$.next(models);
    });

    this.shouldRunATAutomatically$ = combineLatest([
      this.store.select(selectIsGCPAdded),
      this.runATAutomatically,
    ]).pipe(map(([isGCPAdded, runAT]) => (isGCPAdded ? false : runAT)));

    this.canStartUpload$ = combineLatest([
      this.uploadStarted$,
      this.store.select(selectFiles),
    ]).pipe(map(([uploadStarted, photos]) => !(uploadStarted || !photos.length)));
  }

  updateLogs(text: string) {
    this.logs$.next(`${this.logs$.value}<div>${text}...</div>`);
  }

  updateWrongMetadataInfo() {
    const { length: totalCount } = this.files;
    const { length: noCoordsCount } = this.noCoordsFileNames$.value;
    const { length: wrongCoordsCount } = this.wrongCoordsFileNames$.value;
    const shouldDisplayInfo = !!(noCoordsCount || wrongCoordsCount);
    const info = shouldDisplayInfo
      ? formatUnicorn(WRONG_METADATA_INFO, {
          totalCount,
          noCoordsCount,
          wrongCoordsCount,
        })
      : null;
    this._wrongMetadataInfo$.next(info);
  }

  setPending(processId: number | null, isPendingUpload: boolean, progress: number) {
    this.uploadProgress$.next(progress);
    this.isPendingUpload$.next(isPendingUpload);
    this.buildProcessId = processId;
  }

  resetPending() {
    this.uploadProgress$.next(0);
    this.isPendingUpload$.next(false);
    this.buildProcessId = null;
  }

  localUnsubscribe() {
    this.cancelUploadSubject.next();
    this.cancelUploadSubject.complete();
  }

  noFilesLoaded() {
    return !this.files.length || this.addFilesProcessStatus$.value;
  }

  displaysInfo() {
    return !!(
      (this.isPendingUpload$.value && !this.uploadStarted$.value) ||
      this.noCoordsFileNames$.value.length ||
      this.wrongCoordsFileNames$.value.length
    );
  }

  reset() {
    this.removingMetadata$.next(false);
    this.logs$.next('');
    this.addFilesProcessStatus$.next(null);
    this.uploadStarted$.next(false);
    this.isPendingUpload$.next(false);
    this.uploadProgress$.next(0);
    this.models$.next(null);
    this.selectedModelId$.next(null);
    this.showProblematicFiles.next(false);
    this.keywords.next('');
    this.invalidGCPFormat$.next(false);
    window.stop();
  }
}
