<app-model-site-header
  [label]="'Edit User'"
  [backUrl]="'/' + companyId + '/users'"
></app-model-site-header>
<app-user-form
  [requestSended]="requestSended$ | async"
  [parentForm]="form"
  [editUser]="true"
  [initialRoles]="initialRoles"
  (submitted)="onSubmit($event)"
>
  <h1>Edit User</h1>
</app-user-form>
