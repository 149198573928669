import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlPointMeasurement } from '../../models/control-point-measurement.model.';

@Component({
  selector: 'app-measurement-list',
  templateUrl: './measurement-list.component.html',
  styleUrls: ['./measurement-list.component.scss'],
})
export class MeasurementListComponent {
  @Input() measurements: ControlPointMeasurement[];
  @Output() deleteMeasurement: EventEmitter<ControlPointMeasurement> = new EventEmitter();

  onDeleteMeasurement(measurement: ControlPointMeasurement) {
    this.deleteMeasurement.emit(measurement);
  }

  showOnGrid(event: Event, imageName: string) {
    event.preventDefault();
    const imgElem = document.querySelector(`[data-name="${imageName}"]`) as HTMLElement | null;
    const grid = document.querySelector('.photos_grid__inner');
    if (imgElem && grid) {
      const highlightedCls = 'photo-card--highlighted';
      imgElem.classList.add(highlightedCls);
      grid.scrollTo({ top: imgElem.offsetTop - imgElem.offsetHeight, behavior: 'smooth' });
      setTimeout(() => imgElem.classList.remove(highlightedCls), 2000);
    }
  }
}
