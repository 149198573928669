import { WARNING } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { ProcessingEngineService } from '@App/app/engine/services/engine-services/processing-engine-service/processing-engine.service';
import { Photo } from '@App/app/entities/files/files.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAlbum } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AtSetupControlPointsService } from '../tabs/at-setup-tab/at-setup-control-points/services/at-setup-control-points-service/at-setup-control-points.service';
import { ControlPointMeasurement } from '../tabs/at-setup-tab/models/control-point-measurement.model.';
import { FiltersForm } from './models/filters.form.model';
import { SortingOptions } from './models/sorting-options.model';
import { ProcessingPhotoDialogComponent } from './processing-photo-dialog/processing-photo-dialog.component';
import { ProcessingPhotosGridService } from './services/processing-photos-grid-service/processing-photos-grid.service';

@UntilDestroy({ arrayName: 'activeSubscriptions' })
@Component({
  selector: 'app-processing-photos-grid',
  templateUrl: './processing-photos-grid.component.html',
  styleUrls: ['./processing-photos-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('mapOverlap', [
      state('notOverlap', style({ top: '40vh' })),
      state('overlap', style({ top: '1rem' })),
      transition('overlap <=> notOverlap', animate('0.3s')),
    ]),
  ],
})
export class ProcessingPhotosGridComponent implements OnInit, OnDestroy {
  photos: Photo[] = [];
  filteredPhotos: Photo[] = [];
  albums: IAlbum[] = [];
  activePhoto: Photo | null;
  selectedPoints: ControlPointMeasurement[] = [];
  activeControlPointName: string;
  activeSubscriptions: Subscription[];
  mapOverlap = false;
  SortingOptions = SortingOptions;
  form: FiltersForm = this.fb.group({
    keywords: this.fb.control<string>(''),
    sortBy: this.fb.control<string | null>(null),
  });
  cameraControlComponent = document.getElementsByClassName('camera_controls')[0];

  constructor(
    private atSetupControlPointsService: AtSetupControlPointsService,
    private modelsProcessingService: ModelsProcessingService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private processingPhotosGridService: ProcessingPhotosGridService,
    private engineService: ProcessingEngineService,
    private dialogService: NbDialogService,
    private _toastrService: NbToastrService,
  ) {}

  ngOnInit() {
    this.photos = this.modelsProcessingService.getCurrentBuildProcess()?.photos || [];
    this.albums = this.processingPhotosGridService.getLightboxAlbums(this.photos);
    this.engineService.setAllowToShowModelPlaceholder(false);
    this.engineService.setIsCameraControlsTabOpen(false);

    this.activeSubscriptions = [
      this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.cdr.detectChanges();
      }),

      this.atSetupControlPointsService.activeControlPointName$
        .pipe(filter(Boolean))
        .subscribe((name: string) => {
          this.activeControlPointName = name;

          if (!this.form.controls.sortBy.value) {
            this.form.controls.sortBy.patchValue(SortingOptions.Metadata.distanceToGCP);
          }

          this.cdr.detectChanges();
        }),

      this.atSetupControlPointsService.selectedMeasurements$.subscribe((selectedPoints) => {
        this.selectedPoints = selectedPoints;
        this.cdr.detectChanges();
      }),

      this.engineService.isCameraControlsTabOpen$?.subscribe((isOpen) => {
        if (!isOpen && this.cameraControlComponent) {
          (this.cameraControlComponent as HTMLElement).style.display = 'none';
          this.cdr.detectChanges();
        }
      }),
    ];
  }

  ngOnDestroy() {
    this.engineService.setAllowToShowModelPlaceholder(true);
    this.engineService.setIsCameraControlsTabOpen(true);
    if (this.cameraControlComponent) {
      (this.cameraControlComponent as HTMLElement).style.display = 'block';
    }
  }

  resetFilters() {
    this.form.reset();
  }

  toggleMapOverlapping() {
    this.mapOverlap = !this.mapOverlap;
  }

  isMarked(photoName: string) {
    return this.selectedPoints.some(
      (point) =>
        point.imageName === photoName && point.controlPointName === this.activeControlPointName,
    );
  }

  onClick(photo: Photo) {
    if (!this.activeControlPointName) {
      return this._toastrService.show(
        'Select a control point to pick it in the photo',
        WARNING,
        WARNING_TOASTR_CONFIG,
      );
    }

    this.activePhoto = photo;
    const dialog = this.dialogService.open(ProcessingPhotoDialogComponent, {
      context: { photo },
      dialogClass: 'photos_grid__image_dialog',
    });

    const cpName = this.activeControlPointName;
    const cp = this._getMeasurementByPhotoAndControlPointName(photo, cpName);
    if (cp) {
      const { x, y } = cp.pos;
      dialog.componentRef.instance.initTarget(x, y);
    }

    dialog.componentRef.instance.pickTarget.pipe(untilDestroyed(this)).subscribe((target) => {
      const prevPoint = this._getMeasurementByPhotoAndControlPointName(photo, cpName);
      if (target) {
        const { x, y } = target;
        this._addControlPoints(x, y);
      } else if (prevPoint) {
        this.atSetupControlPointsService.deleteControlPointByImageId(prevPoint.imageId);
      }
    });
  }

  private _getMeasurementByPhotoAndControlPointName(photo: Photo, cpName: string) {
    return this.selectedPoints.find(
      (p) => p.imageName === photo.name && p.controlPointName === cpName,
    );
  }

  private _addControlPoints(controlPointX: number, controlPointY: number) {
    if (!isNaN(controlPointX) && !isNaN(controlPointY) && this.activePhoto) {
      this.atSetupControlPointsService.addControlPoint({
        imageName: this.activePhoto.name,
        imageId: +this.activePhoto.id,
        pos: { x: +controlPointX.toFixed(0), y: +controlPointY.toFixed(0) },
      });
    }
  }
}
