import { ISketchPlaneBroadcast } from '@App/app/entities/layer/sketch-tools/sketch-plane-broadcast-info.model';
import {
  SketchPlane,
  sketchPlanePredicate,
} from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { SketchBaseTool } from '@App/app/entities/layer/sketch-tools/tools/sketch-base-tool.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable, OnDestroy } from '@angular/core';
import {
  ActionEvent,
  ExecuteCodeAction,
  Mesh,
  PickingInfo,
  PointerDragBehavior,
  StandardMaterial,
  Vector3,
} from 'babylonjs';
import {
  ELECTRIC_BLUE_MATERIAL_NAME,
  SKETCH_LINE_POINTER_NAME,
} from 'src/app/configs/babylon.config';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../../material-service/material.service';
import { SceneService } from '../../../scene-service/scene.service';
import { UtilsService } from '../../../utils-service/utils.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';
import { SketchesUtilsService } from './sketches-utils.service';

@Injectable()
export abstract class SketchToolService<T extends SketchBaseTool<any>> implements OnDestroy {
  protected draftSketch: T | null;
  protected draftSketches: T[] = [];
  currentSketchPlane: SketchPlane | null;
  pointer: Mesh;
  predicate: sketchPlanePredicate;
  protected handleTakingEvent: EVENT_TYPE;

  constructor(
    protected broadcastService: BroadcastService,
    protected materialService: MaterialService,
    protected sketchesUtilsService: SketchesUtilsService,
    protected utilsService: UtilsService,
    protected babylonNodesService: BabylonNodesService,
    protected sceneService: SceneService,
    protected viewerLayerService: ViewerLayerService,
  ) {
    broadcastService.on(EVENT_TYPE.ADD_SKETCH).subscribe((resp: ISketchPlaneBroadcast) => {
      if (resp.sketchPlane) {
        this.currentSketchPlane = resp.sketchPlane;
        this.predicate = (mesh: Mesh) => mesh.name === resp.sketchPlane?.plane?.fillingPlane.name;
      } else {
        if (this.currentSketchPlane?.plane) {
          this.currentSketchPlane.plane.fillingPlane.isPickable = false;
        }
        this.currentSketchPlane = null;
        this.predicate = (_: Mesh) => false;
      }
    });

    this.broadcastService.on(this.handleTakingEvent).subscribe(() => {
      this.handleTakingSketch();
    });
  }

  ngOnDestroy() {
    this.removePointerMeshEventListener();
  }

  resetCurrentSketchPlane() {
    this.currentSketchPlane = null;
  }

  setStandardMaterial(mesh: Mesh) {
    this.sketchesUtilsService.setStandardMaterial(mesh);
  }

  protected initPointer(): Mesh {
    const box = this.babylonNodesService.createBox(
      null,
      { size: 0.1 },
      null,
      SKETCH_LINE_POINTER_NAME,
    );
    box.isPickable = false;
    box.isVisible = false;
    return box;
  }

  protected handleDragBehavior(
    evt: ActionEvent,
    dragBehavior: PointerDragBehavior,
    draggableMaterial: StandardMaterial,
  ) {
    if (evt.source.material.id === ELECTRIC_BLUE_MATERIAL_NAME) {
      evt.source.addBehavior(dragBehavior, true);
      if (evt.meshUnderPointer) {
        this.sketchesUtilsService.changeMaterial(evt.meshUnderPointer, draggableMaterial);
      }
    } else {
      evt.source.removeBehavior(dragBehavior);
      if (evt.meshUnderPointer) {
        this.sketchesUtilsService.changeMaterial(
          evt.meshUnderPointer,
          this.materialService.electricBlueMaterial,
        );
      }
    }
  }

  protected onPickAction = (
    dragBehavior: PointerDragBehavior,
    draggableMaterial: StandardMaterial,
    draftSketches?: T[],
  ) => {
    return new ExecuteCodeAction(
      {
        trigger: BABYLON.ActionManager.OnPickTrigger,
        parameter: {},
      },
      (evt) => {
        draftSketches?.forEach((draftSketch) =>
          draftSketch.pointsMeshes.forEach((mesh) => {
            mesh.behaviors.forEach((behavior) => {
              mesh.removeBehavior(behavior);
            });
            this.setStandardMaterial(mesh);
          }),
        );
        this.broadcastService.broadcast(
          EVENT_TYPE.MESH_RESTORE_DEFAULT,
          this.currentSketchPlane?.id,
        );
        this.handleDragBehavior(evt, dragBehavior, draggableMaterial);
      },
    );
  };

  protected addPointerMeshEventListener(): void {
    document.querySelector('#renderCanvas')?.addEventListener('mousemove', this.handleMouseMove);
  }

  protected removePointerMeshEventListener(): void {
    document.querySelector('#renderCanvas')?.removeEventListener('mousemove', this.handleMouseMove);
  }

  abstract handleClickAction(hit: PickingInfo): void;

  abstract handleTakingSketch(): void;

  abstract handleMouseMove: () => void;

  abstract onClickAction(): boolean;

  abstract getDraftSketches(): T[];

  abstract showSketch(sketch: T): T;

  abstract removeDraftSketch(): void;

  abstract removeUnfinishedDraftSketch(): void;

  abstract setMeshesDragBehavior(sketch: T): void;

  protected abstract finishSketch(pickedPoint?: Vector3): void;

  protected setDragBehavior(normal: Vector3) {
    const dragBehavior = new PointerDragBehavior({
      dragPlaneNormal: normal,
    });
    dragBehavior.dragDeltaRatio = 1;
    dragBehavior.validateDrag = (targetPosition) => {
      return !!this.currentSketchPlane?.plane?.fillingPlane.intersectsPoint(targetPosition);
    };
    return dragBehavior;
  }
}
