import { CUSTOM_ICONS_PACK } from '@App/app/configs/app.config';
import { MARKER_SKYFISH_SYMBOL, MARKER_SYMBOL } from '@App/app/configs/map.config';
import { DEFAULT_POSITION } from '@App/app/configs/photos-map.config';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getBounds, getCenter } from 'geolib';
import { GeometryCollection, Label, Map, Marker, Polygon, TileLayer, VectorLayer } from 'maptalks';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AtSetupControlPointsService } from '../tabs/at-setup-tab/at-setup-control-points/services/at-setup-control-points-service/at-setup-control-points.service';
import { ControlPoint } from '../tabs/at-setup-tab/models/control-point.model';

@UntilDestroy()
@Component({
  selector: 'app-processing-gcp-map',
  templateUrl: './processing-gcp-map.component.html',
  styleUrls: ['./processing-gcp-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
})
export class ProcessingGcpMapComponent implements OnInit {
  private _map: Map;
  private _container = 'processing_gcp_map__map_container';
  private _controlPoints: ControlPoint[] = [];
  CUSTOM_ICONS_PACK = CUSTOM_ICONS_PACK;

  constructor(private _atSetupControlPointsService: AtSetupControlPointsService) {
    const mapHasLoaded = new Subject();
    mapHasLoaded
      .asObservable()
      .pipe(first())
      .subscribe(() => this._adjustExtentByGCPs());

    (Map as any).addOnLoadHook(() => mapHasLoaded.next());
  }

  ngOnInit() {
    this._controlPoints = this._atSetupControlPointsService.getControlPoints();
    const mapOptions = {
      center: this._getMapCenter(),
      maxZoom: 20,
      zoom: 17,
      baseLayer: new TileLayer('base', {
        urlTemplate:
          'https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.jpg',
        subdomains: ['a', 'b', 'c'],
      }),
      layers: [new VectorLayer('main', [])],
    };
    this._map = new Map(this._container, mapOptions);
    this._pushControlPointsToMap();

    this._atSetupControlPointsService.activeControlPointName$
      .pipe(
        untilDestroyed(this),
        map(
          (activeControlPointName) =>
            this._controlPoints.find((cp) => cp.name === activeControlPointName) || null,
        ),
      )
      .subscribe((cp) => this._updateMarkersByActiveControlPoint(cp));

    document.querySelector('.maptalks-attribution')?.classList.remove('maptalks-attribution');
  }

  private _getMapCenter(): [number, number] {
    const coords: GeoCoords[] = this._controlPoints.map((cp) => ({
      latitude: cp.lat,
      longitude: cp.long,
    }));

    const center = getCenter(coords);
    return center ? [center.longitude, center.latitude] : DEFAULT_POSITION;
  }

  private _pushControlPointsToMap() {
    for (const controlPoint of this._controlPoints) {
      const { lat, long } = controlPoint;
      const marker = new Marker([long, lat], { symbol: MARKER_SYMBOL });
      const label = new Label(controlPoint.name, [long, lat], {
        textSymbol: {
          textFaceName: 'arial',
          textSize: 12,
          textFill: '#ffffff',
          textDy: -55,
        },
      });
      const layer = this._map.getLayer('main') as VectorLayer;
      const collection = new GeometryCollection([marker, label]);
      collection.setProperties({ name: controlPoint.name });
      this._addMarkerEventListeners(collection);
      layer.addGeometry(collection);
    }
  }

  private _addMarkerEventListeners(collection: GeometryCollection) {
    collection.on('mouseenter', (event) => this._setCanvasCursor(event, 'pointer'));
    collection.on('mouseout', (event) => this._setCanvasCursor(event, 'default'));
    collection.on('click', (event) => this._onMarkerClick(event));
  }

  private _setCanvasCursor(event: { domEvent: MouseEvent }, value: string) {
    const e: MouseEvent = event.domEvent;
    (e.target as HTMLCanvasElement).style.cursor = value;
  }

  private _onMarkerClick(event: { target: Marker }) {
    const { target: marker } = event;
    const properties = marker.getProperties() as { name: string };
    const { name } = properties;
    this._atSetupControlPointsService.setActiveControlPointName(name);
  }

  private _updateMarkersByActiveControlPoint(activeControlPoint: ControlPoint | null) {
    const layer = this._map.getLayer('main') as VectorLayer;
    const collections = layer.getGeometries() as GeometryCollection[];

    for (const collection of collections) {
      const { name } = collection.getProperties() as { name: string };
      const isActive = name === activeControlPoint?.name;
      const marker = collection.getGeometries().find((i) => i instanceof Marker);
      marker?.updateSymbol(isActive ? MARKER_SKYFISH_SYMBOL : MARKER_SYMBOL);
    }
  }

  private _adjustExtentByGCPs() {
    const allCoords = this._controlPoints.map((cp) => ({ lat: cp.lat, lon: cp.long }));
    const { minLat, maxLat, minLng, maxLng } = getBounds(allCoords);
    const polygon = new Polygon([
      [minLng, minLat],
      [minLng, maxLat],
      [maxLng, maxLat],
      [maxLng, minLat],
    ]);
    setTimeout(() => this._map.fitExtent(polygon.getExtent(), -1), 500);
  }

  onResetMap() {
    this._map.remove();
    this.ngOnInit();
  }
}
