import { CuboidPartsArrays } from '@App/app/entities/layer/cuboid.model';
import {
  LAYER_TYPES,
  RECT_TUBE_PART,
  RECT_TUBE_STRICT,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { Matrix, Mesh, Plane, Quaternion, Vector3 } from 'babylonjs';
import { median } from 'src/app/shared/utils/utils';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { SceneService } from '../../../scene-service/scene.service';
import { UtilsService } from '../../../utils-service/utils.service';
import { BasePlaneService } from '../../base-plane-services/base-plane.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';
import { RectangleToolsEdgeDetectService } from '../shared/rectangle-tools-edge-detect.service';
import { RectangleToolsService } from '../shared/rectangle-tools-service';
import { RectangleToolsUtilsService } from '../shared/rectangle-tools-utls.service';
import { VolumeUtilsService } from '../shared/volume-utils.service';

@Injectable({
  providedIn: 'root',
})
export class RectTubeService extends RectangleToolsService {
  activePart: RECT_TUBE_PART = RECT_TUBE_PART.NONE;
  highlightedSample: { part: RECT_TUBE_PART; i: number } = {
    part: RECT_TUBE_PART.NONE,
    i: -1,
  };
  samples: CuboidPartsArrays<Mesh> = { 1: [], 2: [], 3: [] };

  private adjacentSurfaceLength: number;
  private centerPosition: Vector3;
  private surfaceLength: number;
  private surfacePlaneNormal: Vector3;
  private thickness: number;
  private topBottomSurfaceLength: number;
  private topPlaneNormal: Vector3;

  constructor(
    private basePlaneService: BasePlaneService,
    private toastrService: NbToastrService,
    private sceneService: SceneService,
    private babylonNodesService: BabylonNodesService,
    private store: Store,
    private utilsService: UtilsService,
    private rectangleToolsUtilsService: RectangleToolsUtilsService,
    private viewerLayerService: ViewerLayerService,
    private volumeUtilsService: VolumeUtilsService,
    private rectangleToolsEdgeDetectService: RectangleToolsEdgeDetectService,
  ) {
    super(
      basePlaneService,
      rectangleToolsUtilsService,
      sceneService,
      store,
      utilsService,
      viewerLayerService,
      volumeUtilsService,
      babylonNodesService,
      rectangleToolsEdgeDetectService,
    );
  }

  generateRectTube() {
    try {
      [
        this.surfaceLength,
        this.adjacentSurfaceLength,
        this.topBottomSurfaceLength,
        this.topPlaneNormal,
        this.surfacePlaneNormal,
        this.centerPosition,
      ] = this.calculateSidesData(
        this.samples[RECT_TUBE_PART.SURFACE],
        this.samples[RECT_TUBE_PART.ADJACENT_SURFACE],
      );
      this.thickness = this.calculateThickness();
      this.save(
        LAYER_TYPES.RECT_TUBE,
        this.topPlaneNormal,
        this.surfacePlaneNormal,
        this.centerPosition,
        this.adjacentSurfaceLength,
        this.surfaceLength,
        this.topBottomSurfaceLength,
        this.samples[RECT_TUBE_PART.ADJACENT_SURFACE],
        this.thickness,
      );
      this.clearSamples();
    } catch (err) {
      this.clearSamples();
      this.utilsService.catchToolCreationError(err);
    }
  }

  setActivePart(part: RECT_TUBE_PART) {
    this.activePart = this.activePart === part ? RECT_TUBE_PART.NONE : part;
    this._dataChange$.next();
  }

  highlightSample(part: RECT_TUBE_STRICT, i: number) {
    this.highlightSamplePoint(part, i);
    this._dataChange$.next();
  }

  deleteSample(part: RECT_TUBE_STRICT, i: number) {
    this.deleteSamplePoint(part, i);
    this._dataChange$.next();
  }

  clearSamples() {
    this.clearSamplesPoints();
    this._dataChange$.next();
  }

  private calculateThickness() {
    const options = {
      height: this.topBottomSurfaceLength,
      depth: this.surfaceLength,
      width: this.adjacentSurfaceLength,
    };

    const box = this.babylonNodesService.createBox(
      this.centerPosition.clone(),
      options,
      Quaternion.FromRotationMatrix(
        Matrix.LookDirectionLH(this.surfacePlaneNormal.negate(), this.topPlaneNormal.negate()),
      ),
      'box',
    );

    box.bakeCurrentTransformIntoVertices(true);
    const vertices: any[] = this.rectangleToolsUtilsService.extractVertices(box);
    box.dispose();
    const planes: [Plane, Plane, Plane, Plane] = [
      Plane.FromPoints(
        Vector3.FromArray(vertices[0]),
        Vector3.FromArray(vertices[1]),
        Vector3.FromArray(vertices[2]),
      ),
      Plane.FromPoints(
        Vector3.FromArray(vertices[4]),
        Vector3.FromArray(vertices[5]),
        Vector3.FromArray(vertices[6]),
      ),
      Plane.FromPoints(
        Vector3.FromArray(vertices[8]),
        Vector3.FromArray(vertices[9]),
        Vector3.FromArray(vertices[10]),
      ),
      Plane.FromPoints(
        Vector3.FromArray(vertices[12]),
        Vector3.FromArray(vertices[13]),
        Vector3.FromArray(vertices[14]),
      ),
    ];
    const distances: number[] = [];
    this.samples[RECT_TUBE_PART.INNER].forEach((point) => {
      distances.push(this.findMinDistanceToPlanes(planes, point.position));
    });
    return median(distances) || 0.01;
  }

  private findMinDistanceToPlanes(planes: [Plane, Plane, Plane, Plane], point: Vector3) {
    return Math.min(
      planes[0].signedDistanceTo(point),
      planes[1].signedDistanceTo(point),
      planes[2].signedDistanceTo(point),
      planes[3].signedDistanceTo(point),
    );
  }
}
