<form class="sidebar__input_row sidebar__input_row--settings" [formGroup]="getForm()">
  <div class="sidebar__label sidebar__label--settings">Accuracy</div>
  <nb-select
    class="sidebar__option"
    data-testId="accuracy-select"
    status="control"
    size="tiny"
    placeholder="None"
    formControlName="accuracy"
  >
    <nb-option class="sidebar__option__select_opt" value="below5cm">Below 5 cm</nb-option>
    <nb-option class="sidebar__option__select_opt" value="over5cm">Over 5 cm</nb-option>
  </nb-select>
  <div class="gcp__error">
    <span *ngIf="!isValid()" class="gcp__error__text">No accuracy provided</span>
  </div>
</form>
