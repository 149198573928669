import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { User } from '@App/app/entities/users/user';
import { NbMenuService, NbThemeService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { RESPONSIVE_CONFIG } from 'src/app/configs/app.config';
import { versions } from 'src/environments/versions';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  companyId: number | null;
  user: User;
  isAuth: boolean;
  isMobile: boolean;
  roles: string;
  version = versions.version;
  mediaQuery = this.themeService.onMediaQueryChange();
  loading = false;
  skyfishLogo = '../assets/svg/logo_skyfish.svg';
  showDropDownMenu = false;
  items: {
    title: string;
    link?: string;
    data: { id: string };
  }[];

  constructor(
    private themeService: NbThemeService,
    private authService: AuthService,
    private nbMenuService: NbMenuService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.authService.currentEndpoint$.pipe(untilDestroyed(this)).subscribe((endpoint) => {
      this.companyId = endpoint?.id || null;
      if (this.companyId) {
        this.items = [
          { title: 'Profile', link: `/${this.companyId}/user`, data: { id: 'profile' } },
          { title: 'Log out', data: { id: 'logout' } },
        ];
      }
    });

    this.authService.authChange.pipe(untilDestroyed(this)).subscribe((authStatus) => {
      this.isAuth = authStatus;
      const user = this.authService.getUser();
      if (user?.id) {
        this.user = user as User;
      }
      this.authService.currentEndpoint$.pipe(untilDestroyed(this)).subscribe((endpoint) => {
        if (endpoint) {
          if (endpoint.userRoles.some((element) => element.name === 'Admin')) {
            this.roles = 'Admin';
          } else {
            this.roles = endpoint.userRoles
              .filter((value) => {
                if (value.name !== 'StandardUser') {
                  return value;
                }
              })
              .map((role) => role.name)
              .join(', ');
          }
        }
      });
    });

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'profile-menu'),
        map(
          ({
            item: {
              data: { id },
            },
          }) => id,
        ),
      )
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        if (id === 'logout') {
          this.onSignOut();
        }
      });

    this.mediaQuery.pipe(untilDestroyed(this)).subscribe(([, currentMediaQuery]) => {
      this.isMobile = currentMediaQuery.width < RESPONSIVE_CONFIG.MIN_WIDTH;
    });
  }

  onSignOut() {
    this.loading = true;
    this.authService
      .logout()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.authService.onLogout();
        },
        () => {
          this.loading = false;
          this.authService.onLogout();
        },
      );
  }

  isUserInOtherCompanies() {
    return this.authService.isUserInOtherCompanies();
  }

  hasAccessToAdminPanel() {
    return this.authService.hasAccessToAdminPanel();
  }

  getCompanyLogo() {
    return this.authService.getCurrentEndpoint()?.logo;
  }

  toggleMenu() {
    this.showDropDownMenu = !this.showDropDownMenu;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const companySearchElement = document.getElementById('company-search');
    const targetElement = this.elementRef.nativeElement?.children[0]?.children[1]?.children[2];

    if (
      targetElement?.contains &&
      !targetElement.contains(event.target) &&
      !(event.target === companySearchElement)
    ) {
      this.showDropDownMenu = false;
    }
  }
}
