<nb-card
  *ngIf="isLoaded || isLoading"
  [ngClass]="{ selected: isSelected() }"
  status="basic"
  class="photos_carousel__item"
  [id]="photo.id"
  (click)="selectPhotoId($event)"
  data-testId="container"
>
  <nb-card-body
    [nbSpinner]="(isSelected() && isDownloading()) || isLoading"
    data-testId="container-body"
    nbSpinnerStatus="control"
  >
    <nb-icon
      *ngIf="isSelected() && !isPhotoClosed"
      class="photos_carousel__icon__close"
      icon="eye-off-outline"
    ></nb-icon>
    <nb-icon
      *ngIf="isSelected() && isPhotoClosed"
      class="photos_carousel__icon__open"
      icon="eye-outline"
    ></nb-icon>
    <img
      [src]="photo | photoThumbnail"
      [ngClass]="{
        'photos_carousel__image--disabled': !photo.data.rotation
      }"
      [nbTooltip]="photo.data.rotation ? photo.name : 'Image position is not available'"
      [alt]="photo.name"
      loading="lazy"
      data-testId="photo-img"
      (load)="hasLoaded()"
    />
    <nb-icon
      *ngIf="isSelected() && !isDownloading()"
      class="photos_carousel__icon"
      icon="download-outline"
      (click)="downloadPhoto($event)"
      data-testId="download-btn"
    ></nb-icon>
  </nb-card-body>
</nb-card>
