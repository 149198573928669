import { selectUiSettings } from '@App/app/auth/store/auth/selectors/auth.selectors';
import { DEFAULT_PER_PAGE_OFFSET } from '@App/app/configs/app.config';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Page } from '@App/app/entities/shared/page.model';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { SitesService } from '../site-services/sites.service';

@UntilDestroy()
@Component({
  template: '',
})
export abstract class BaseSiteComponent<T, SS extends SitesService<T>> implements OnInit {
  companyId: number | null;
  showArchived: boolean;
  private _showArchived$ = new BehaviorSubject<boolean>(false);
  isShowArchived$ = this._showArchived$.asObservable();
  page: Page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  selectedEntities: T[] = [];
  permissions = UserPermissions;
  archiveIcon = '../../../../assets/svg/icons/sf_portal-ui_icon_archive.svg';
  unarchiveIcon = '../../../../assets/svg/icons/sf_portal-ui_icon_unarchive.svg';
  nbLayoutActive = false;

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private permissionsService: NgxPermissionsService,
    protected store: Store,
    @Inject('sitesService') protected sitesService: SS,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      const target = e.target as HTMLElement;
      if (
        !target.closest('.search__wrapper') &&
        !target.closest('nb-calendar-range') &&
        !target.closest('nb-option-list')
      ) {
        this.sitesService.clickOutsideFilterPopover();
      }
    });

    this.authService.currentEndpoint$.pipe(untilDestroyed(this)).subscribe((endpoint) => {
      this.companyId = endpoint?.id || null;
    });

    this.sitesService.showArchived$.pipe(untilDestroyed(this)).subscribe((val: boolean) => {
      this.showArchived = val;
      this._showArchived$.next(val);
    });

    this.sitesService.page$.pipe(untilDestroyed(this)).subscribe((item) => {
      this.page = item;
    });

    this.sitesService.selectedEntities.pipe(untilDestroyed(this)).subscribe((entities) => {
      this.selectedEntities = entities;
    });
  }

  ngOnInit(): void {
    this.store
      .select(selectUiSettings)
      .pipe(
        untilDestroyed(this),
        filter(() => !!this.page),
      )
      .subscribe((uiSettings) => {
        this.page.size = uiSettings?.pageOffset || DEFAULT_PER_PAGE_OFFSET;
      });
  }

  changeArchiveSelect(value: boolean) {
    this._showArchived$.next(value);
    this.sitesService.changeArchiveSelect(value);
  }

  archive() {
    this.sitesService.archiveMultiple(this.selectedEntities);
  }

  delete() {
    this.sitesService.deleteMultiple(this.selectedEntities);
  }

  hasPermission(permission: UserPermissions) {
    return !!this.permissionsService.getPermission(permission);
  }
}
