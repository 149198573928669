import { Component, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import VersionInfo from 'package.json';
import { versions } from 'src/environments/versions';
import { VersionService } from './auth/version.service';
import { VERSION_TYPES } from './entities/shared/version-types.enum';
import { CustomIconsPackService } from './shared/services/custom-icons-pack/custom-icons-pack.service';
import { MetadataService } from './shared/services/metadata/metadata.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  mediaQuery = this.themeService.onMediaQueryChange();

  constructor(
    private themeService: NbThemeService,
    private metadataService: MetadataService,
    private versionService: VersionService,
    private customIconsPackService: CustomIconsPackService,
  ) {}

  ngOnInit(): void {
    this.customIconsPackService.register();

    console.log(`Skyportal frontend revision: ${versions.revision}`);

    this.versionService.checkVersion(VERSION_TYPES.APP, VersionInfo.version);
    this.metadataService
      .getVersion()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (
          response?.data?.version &&
          this.versionService.checkVersion(VERSION_TYPES.API, response.data.version)
        ) {
          console.log(`Skyportal backend revision: ${response.data.version}`);
        }
      });
  }
}
