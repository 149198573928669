/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-namespace */
import { IToolTutorial } from '../entities/viewer/tool-tutorial.model';

export namespace Hints {
  export const DOWNLOAD_PHOTOS_ZIP = `
  The selected photos are being prepared for download. This may take a while.
  When the zipped bundle is ready for download, you will receive an email notification.
  `;
  export const DOWNLOAD_ANNOTATIONS_REPORT =
    'The annotation report is being prepared. This may take a few minutes. You will receive an email notification once the report is ready to be downloaded.';
}

export const EDIT_MODE = 'Please save your layer first!';

export const TOOL_TUTORIALS: IToolTutorial = {
  ADD_DROP_LINE: {
    videoPath: '../../../assets/video-tutorials/Drop-Line.mp4',
    description:
      "Drop Line - a tool for measuring a distance from selected point on the model to it's base plane. To create a drop line, simply click a point on the model.",
  },
  ADD_TUBE: {
    videoPath: '../../../assets/video-tutorials/Tube.mp4',
    description:
      "Tube - a tool for mapping tubes from the model. To map a tube at least 4 sample points are required for both ends of the tube - 3 on the tube's surface and 1 inside the tube.",
  },
  ADD_CUBOID: {
    videoPath: '../../../assets/video-tutorials/Cuboid.mp4',
    description:
      "Cuboid - a tool for mapping antennas or radioheads from the model. To map an antenna or radiohead, sample points are required for 2 of the cuboid's walls - 4 sample points for front or back wall, then either 3 sample points for left or right wall, or 3 sample points for top or bottom wall.",
  },
  ADD_MEASUREMENT: {
    videoPath: '../../../assets/video-tutorials/Measurement.mp4',
    description:
      'Measurement - a tool for measuring the distance between 2 points. To create a measurement, select two points on the model between which the distance is to be measured.',
  },
  ADD_POLYGON: {
    videoPath: '../../../assets/video-tutorials/Area-Polygon.mp4',
    description:
      'Area Polygon - a tool for calculating area of selected plane. To create an area polygon at least 3 sample points are required. Optionally, could be chosen 4, 5, 6 and more points.',
  },
  ADD_SLICE_PLANE: {
    videoPath: '../../../assets/video-tutorials/Slice plane.mp4',
    description:
      'Slice Plane - a tool for changing vertical visibility of the model. Two horizontal planes are used to slice an excerpt of the model.',
  },
  ADD_TUBE_PICKER: {
    videoPath: '../../../assets/video-tutorials/Tube picker.mp4',
    description:
      'Tube Picker - a tool for initialize a tube with some default dimensions from the model. To create a tube picker, simply click first point on one end and second point on second end of tube.',
  },
  ADD_BOUNDING_BOX: {
    videoPath: '../../../assets/video-tutorials/Cuboid picker.mp4',
    description:
      'Cuboid Picker - a tool which automatically adjusts dimensions to rectangular objects on the model. To create a cuboid picker, simply click on rectangular object and resize generated box to wrap the object. The box will adjust dimensions automatically on save.',
  },
  ADD_SKETCH_PLANE: {
    videoPath: '../../../assets/video-tutorials/Sketch plane.mp4',
    description:
      'Sketch Plane - a tool for creating a plane with ability to create on it rectangle, line and circle layers. To create a sketch plane, pick 3 points on selected plane of model.',
  },
  ADD_ANNOTATION: {
    videoPath: '../../../assets/video-tutorials/Annotation.mp4',
    description:
      'Annotation - a tool for adding a note to the model. To create an annotation, click on the model where the annotation is to be placed, select priority and add description if needed.',
  },
  ADD_COORDINATES_LAYER: {
    videoPath: '../../../assets/video-tutorials/Coordinates.mp4',
    description:
      'Coordinates - a tool for adding a layer with selected coordinates from the model. To create a coordinates layer, click on the model.',
  },
  ADD_ANGLE_IRON: {
    videoPath: '../../../assets/video-tutorials/Angle iron.mp4',
    description:
      "Angle Iron - a tool for mapping angle irons from the model. To map an angle iron, sample points are required for 2 of the angle iron's walls - 3 sample points for outside wall, then either 1 sample points for inside wall, and the same points for second side.",
  },
  ADD_RECT_TUBE: {
    videoPath: '../../../assets/video-tutorials/Rectangle tube.mp4',
    description:
      "Rectangle Tube - a tool for mapping rectangular tubes from the model. To map a rectangular tube, sample points are required for 3 of the tube's walls - 3 sample points for surface, then either 3 sample points for adjacent surface wall, and 1 point for opposite surface wall.",
  },
  SHOW_ROI_TUTORIAL: {
    videoPath: '../../../assets/video-tutorials/ROI.mp4',
    description: '',
  },
};
