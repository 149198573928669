import { Injectable } from '@angular/core';
import { Endpoint } from '../entities/auth/endpoint.model';
import { UserPermissions } from '../entities/auth/permissions.enum';

@Injectable({
  providedIn: 'root',
})
export class EndpointsService {
  constructor() {}

  filterAvailableEndpoints(endpoints: Endpoint[]) {
    return endpoints.filter((endpoint) => {
      const permissions = this.getEndpointPermissions(endpoint);
      return (
        permissions.includes(UserPermissions.CAN_ACCESS_MODEL_LIST) ||
        permissions.includes(UserPermissions.CAN_ACCESS_PROCESSING_LIST) ||
        permissions.includes(UserPermissions.CAN_UPLOAD_PHOTOS)
      );
    });
  }

  private getEndpointPermissions(endpoint: Endpoint): UserPermissions[] {
    return endpoint.userRoles.flatMap((role) =>
      role.permissions.map((permission) => permission.name),
    );
  }
}
