<div class="coords_tool">
  <span
    id="local"
    [class]="activeTool === 'local' ? 'chosen' : 'not_chosen'"
    (click)="localButtonClick()"
    >LOCAL
  </span>
  <span id="gps" [class]="activeTool === 'gps' ? 'chosen' : 'not_chosen'" (click)="gpsButtonClick()"
    >GPS
  </span>
  <span>{{ coordsData }}</span>
</div>
