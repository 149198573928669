import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { EditUserForm, Role } from '../../../entities/users/user';
import { FormValidators } from '../../../shared/form.validators';
import { selectUserById } from '../store/selectors/users.selectors';
import { loadUser } from './../store/actions/users.actions';
import { EditUserService } from './edit-user.service';

@UntilDestroy()
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  companyId: number | null;
  initialFormValues: any;
  id: number | null;
  userRoles: Role[] = [];
  requestSended$: Observable<boolean>;
  initialRoles: number[] = [];
  permissions = UserPermissions;
  form: UntypedFormGroup = this.fb.group({
    firstName: ['', [Validators.required, FormValidators.noOnlySpaces]],
    lastName: ['', [Validators.required, FormValidators.noOnlySpaces]],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: ['', [FormValidators.phoneValidator]],
    roleIds: [[]],
  });

  constructor(
    private route: ActivatedRoute,
    private editUserService: EditUserService,
    private authService: AuthService,
    private fb: FormBuilder,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.initialFormValues = this.form.value;
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.id = +id;
      this.store.dispatch(loadUser({ id: +id }));
      this.store.select(selectUserById(+id)).subscribe((user) => {
        if (user) {
          this.form.patchValue({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
          });
          if (user.roles) {
            this.userRoles = user.roles.map((role) => role);
            const roleIds = user.roles.map((role: Role) => role.id);
            this.form.controls['roleIds'].patchValue(roleIds);
            this.initialRoles = roleIds;
          }
          this.initialFormValues = this.form.value;
        }
      });
    }
    this.authService.currentEndpoint$.pipe(untilDestroyed(this)).subscribe((endpoint) => {
      this.companyId = endpoint?.id || null;
    });
    this.editUserService.resetForm$.pipe(untilDestroyed(this)).subscribe(() => {
      this.form.patchValue(this.initialFormValues);
      this.userRoles = [...this.userRoles];
    });
    this.requestSended$ = this.editUserService.isRequestSended$;
  }

  onSubmit(user: EditUserForm) {
    if (this.id) {
      const userWithId = {
        ...user,
        id: this.id,
      };
      this.editUserService.editUser(userWithId);
    }
  }
}
