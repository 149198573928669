import { TemplateRef } from '@angular/core';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { TableColumn } from '@swimlane/ngx-datatable';

export default (
  progressTpl: TemplateRef<any>,
  updatedTpl: TemplateRef<any>,
  actionsTpl: TemplateRef<any>,
  selectAllTpl: TemplateRef<any>,
  selectTpl: TemplateRef<any>,
  sortByProgress: (_: void, __: void, a: BuildProcess, b: BuildProcess) => number,
  sortByNotifications: (_: void, __: void, a: BuildProcess, b: BuildProcess) => number,
): TableColumn[] => [
  {
    sortable: false,
    resizeable: false,
    headerTemplate: selectAllTpl,
    flexGrow: 0.5,
    cellTemplate: selectTpl,
  },
  {
    prop: 'id',
    name: 'ID',
    flexGrow: 0.8,
    cellClass: 'clickable',
  },
  {
    prop: 'model.name',
    name: 'Name',
    flexGrow: 1.6,
    cellClass: 'clickable',
  },
  {
    prop: 'progress',
    name: 'Progress',
    cellTemplate: progressTpl,
    flexGrow: 6,
    cellClass: 'clickable',
    comparator: sortByProgress,
  },
  {
    prop: 'heartbeatAt',
    name: 'Last updated',
    cellTemplate: updatedTpl,
    flexGrow: 1.1,
    cellClass: 'clickable',
  },
  {
    prop: 'users',
    name: '',
    cellTemplate: actionsTpl,
    flexGrow: 1.7,
    cellClass: 'clickable',
    comparator: sortByNotifications,
  },
];
