import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Chart } from 'chart.js';
import { ArcRotateCameraAnimationConfig } from 'src/app/configs/babylon.config';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { CharElement } from './camera-snap.models';

@Component({
  selector: 'app-camera-snap',
  templateUrl: './camera-snap.component.html',
  styleUrls: ['./camera-snap.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CameraSnapComponent implements OnInit, OnDestroy {
  chart: Chart;
  grey = '#D5D5D5';
  isHovered = false;
  segmentsBackgroundColor = new Array(4).fill('#6C6A6B');
  sceneColor = '#AAA8AB';

  dataDonutMenuE = 'DataDonutMenuE';
  dataDonutMenuN = 'DataDonutMenuN';
  dataDonutMenuS = 'DataDonutMenuS';
  dataDonutMenuW = 'DataDonutMenuW';

  constructor(private broadcastService: BroadcastService) {}

  ngOnInit() {
    this.chart = new Chart('canvas', {
      type: 'doughnut',
      data: {
        labels: [
          this.dataDonutMenuE,
          this.dataDonutMenuS,
          this.dataDonutMenuW,
          this.dataDonutMenuN,
        ],
        datasets: [
          {
            data: [1, 1, 1, 1],
            backgroundColor: this.segmentsBackgroundColor,
            fill: false,
            hoverBackgroundColor: this.grey,
            hoverBorderColor: this.sceneColor,
            borderColor: this.sceneColor,
            borderWidth: 2.0,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        rotation: 150,
        animation: undefined,

        onClick: (_: unknown, e: any[]) => {
          if (e.length) {
            this.broadcastService.broadcast(EVENT_TYPE.CAMERA_SNAP, e[0]._view.label);
          }
        },
        //TODO: bind this or replace this function with arrow function and use ngClass
        onHover(e: Event) {
          const chartElement: CharElement[] = this.getElementAtEvent(e);
          if (chartElement.length) {
            const worldDirections = Array.from(document.querySelectorAll(`[id^="DataDonutMenu"]`));
            const labelName = chartElement[0]._view?.label;
            const worldDirection = document.querySelector(`[id*="${labelName}"]`);

            // Change color of hovered segment
            if (worldDirection) {
              worldDirection.className = `camera_snap__direction-text--hovered-${labelName}`;
            }

            // Change color of not hovered segments
            worldDirections.forEach((el) => {
              if (el.id !== labelName) {
                el.className = `camera_snap__direction-text`;
              }
            });
          } else {
            const worldDirections = document.querySelectorAll(`[id^="DataDonutMenu"]`);
            worldDirections.forEach((el) => {
              el.className = `camera_snap__direction-text`;
            });
          }
        },
        cutoutPercentage: 77,
      },
    });
  }

  ngOnDestroy() {
    this.chart.destroy();
  }

  onNadirButton() {
    this.broadcastService.broadcast(
      EVENT_TYPE.CAMERA_SNAP,
      ArcRotateCameraAnimationConfig.TOP.name,
    );
  }
}
