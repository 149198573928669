import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Model, ModelInsert } from '@App/app/entities/models/model.model';
import { ApiService } from '@App/app/shared/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModelsHttpService extends ApiService {
  getModels() {
    return this.http.get(`${this.api}/models2`);
  }

  getModel(id: number): Observable<Model> {
    return this.http.get<Model>(`${this.api}/models2/${id}`);
  }

  postModel(form: ModelInsert): Observable<Model> {
    return this.http.post<Model>(`${this.api}/models2`, form);
  }

  putModel(id: number, form: Model | ModelInsert): Observable<Model> {
    return this.http.put<Model>(`${this.api}/models2/${id}`, form);
  }

  deleteModel(id: number, force = false): Observable<Model> {
    const options = { params: new HttpParams() };
    if (force) {
      options.params = options.params.set('force', `${force}`);
    }
    return this.http.delete<Model>(`${this.api}/models2/${id}`, options);
  }

  getModelAutomapping(path: string): Observable<any> {
    return this.http.get(path, {
      headers: {
        notLogOutOn403: 'true',
      },
    });
  }
}
