import { Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { FileMetadataInfo } from '../../models/uploaded-image-metadata.model';

@Injectable({
  providedIn: 'root',
})
export class GcpService {
  constructor(private papa: Papa) {}

  async getControlPointsFromFile(file: File) {
    const data = await this._getDataFromFile(file);
    return data.filter((row) => row.length > 1).map((row) => this._toFileMetadata(row));
  }

  private _getDataFromFile(file: File) {
    return new Promise<[string, string, string, string][]>((resolve) => {
      this.papa.parse(file, {
        header: false,
        complete: (results) => {
          const data: [string, string, string, string][] = results.data;
          resolve(data.slice(1));
        },
      });
    });
  }

  private _toFileMetadata(row: [string, string, string, string]) {
    const [name, lat, lon, alt] = row;
    const [numLat, numLon, numAlt] = [+lat, +lon, +alt];

    if ([numLat, numLon, numAlt].some((i) => Number.isNaN(i))) {
      throw new Error();
    }

    const coords = {
      latitude: numLat,
      longitude: numLon,
    };

    return new FileMetadataInfo(name, coords, numAlt);
  }
}
