/* eslint-disable @typescript-eslint/unbound-method */
export class Zoom {
  private pointX = 0;
  private pointY = 0;
  private scale = 1.0;
  private ratio = 1.1;
  private maxScale = 20;
  private rect: DOMRect;
  private draggedPosition: [number, number] | null = null;
  private draggedLeftTop: [number, number] | null = null;
  private previousCursor: string | null = null;

  constructor(
    private image: HTMLImageElement,
    private wrapper: HTMLDivElement,
    private callback: () => void,
    private _leftOffset = 0,
  ) {
    this.rect = wrapper.getBoundingClientRect();
    this.wrapper.addEventListener('wheel', this.onWheel);
    this.wrapper.addEventListener('contextmenu', this.onContextMenu);
    this.wrapper.addEventListener('mousemove', this.onMove);
    this.wrapper.addEventListener('mousedown', this.onClick);
    document.addEventListener('mouseup', this.onClick);

    const { offsetHeight, offsetWidth } = wrapper;
    wrapper.style.setProperty('height', `${offsetHeight}px`, 'important');
    wrapper.style.setProperty('width', `${offsetWidth}px`, 'important');
  }

  destroy() {
    this.wrapper.removeEventListener('wheel', this.onWheel);
    this.wrapper.removeEventListener('contextmenu', this.onContextMenu);
    this.wrapper.removeEventListener('mousemove', this.onMove);
    this.wrapper.removeEventListener('mousedown', this.onClick);
    document.removeEventListener('mouseup', this.onClick);
    this.resetStyles();
  }

  private onWheel = (e: WheelEvent) => {
    let { clientX: x, clientY: y } = e;
    x -= (document.documentElement.offsetWidth + this._leftOffset - this.rect.width) / 2;
    y -= (document.documentElement.offsetHeight + this._leftOffset - this.rect.height) / 2;
    x -= this.wrapper.offsetWidth / 2;
    y -= this.wrapper.offsetHeight / 2;
    const xs = (x - this.pointX) / this.scale;
    const ys = (y - this.pointY) / this.scale;
    const { wheelDelta } = e as any;
    const delta = wheelDelta ? wheelDelta : -e.deltaY;
    this.scale = delta > 0 ? this.scale * this.ratio : this.scale / this.ratio;

    // validate scale
    if (this.scale > this.maxScale) {
      this.scale = this.maxScale;
    } else if (this.scale < 1) {
      this.scale = 1;
      this.resetStyles();
      return;
    }

    this.pointX = x - xs * this.scale;
    this.pointY = y - ys * this.scale;
    const newWidth = this.wrapper.offsetWidth * this.scale;
    const newHeight = this.wrapper.offsetHeight * this.scale;
    const xDiff = newWidth - this.wrapper.offsetWidth;
    const yDiff = newHeight - this.wrapper.offsetHeight;
    this.normalizeIfOutOfTheBox(xDiff, yDiff, newWidth, newHeight);
    const newLeft = this.pointX - xDiff / 2;
    const newTop = this.pointY - yDiff / 2;
    this.updateDimensions(newWidth, newHeight);
    this.updatePosition(newLeft, newTop);
    this.callback();
  };

  private normalizeIfOutOfTheBox = (
    xDiff: number,
    yDiff: number,
    newWidth: number,
    newHeight: number,
  ) => {
    let newLeft = this.pointX - xDiff / 2;
    let newTop = this.pointY - yDiff / 2;
    if (newLeft > 0) {
      this.pointX = xDiff / 2;
    }
    if (newTop > 0) {
      this.pointY = yDiff / 2;
    }
    newLeft = this.pointX - xDiff / 2;
    newTop = this.pointY - yDiff / 2;
    if (newLeft + newWidth < this.rect.width) {
      this.pointX += this.rect.width - (newLeft + newWidth);
    }
    if (newTop + newHeight < this.rect.height) {
      this.pointY += this.rect.height - (newTop + newHeight);
    }
  };

  private onClick = (e: MouseEvent) => {
    if (e.which === 3) {
      const { clientX: x, clientY: y } = e;
      if (e.type === 'mousedown') {
        this.draggedPosition = [x, y];
        this.draggedLeftTop = [this.image.offsetLeft, this.image.offsetTop];
        this.previousCursor = this.wrapper.style.cursor;
        this.wrapper.style.cursor = 'grabbing';
      } else if (this.draggedPosition) {
        const xDiff = this.draggedPosition[0] - x;
        const yDiff = this.draggedPosition[1] - y;
        this.pointX -= xDiff;
        this.pointY -= yDiff;
        this.draggedPosition = null;
        this.draggedLeftTop = null;
        this.wrapper.style.cursor = this.previousCursor as string;
        this.previousCursor = null;
      }
    }
  };

  private onMove = (e: MouseEvent) => {
    if (!this.draggedPosition || !this.draggedLeftTop) {
      return;
    }
    const { clientX: x, clientY: y } = e;
    const xDiff = this.draggedPosition[0] - x;
    const yDiff = this.draggedPosition[1] - y;
    let left = this.draggedLeftTop[0] - xDiff;
    let top = this.draggedLeftTop[1] - yDiff;
    left = Math.min(left, 0);
    top = Math.min(top, 0);
    const [W, H] = [this.image.offsetWidth, this.image.offsetHeight];
    if (left + W < this.wrapper.offsetWidth) {
      left = this.wrapper.offsetWidth - W;
    }
    if (top + H < this.wrapper.offsetHeight) {
      top = this.wrapper.offsetHeight - H;
    }
    this.updatePosition(left, top);
    this.callback();
  };

  private onContextMenu = (e: MouseEvent) => {
    e.preventDefault();
  };

  private updateDimensions = (width: number, height: number) => {
    this.image.style.setProperty('width', `${width}px`);
    this.image.style.setProperty('height', `${height}px`);
  };

  private updatePosition = (left: number, top: number) => {
    this.image.style.setProperty('left', `${left}px`);
    this.image.style.setProperty('top', `${top}px`);
  };

  private resetStyles = () => {
    this.updatePosition(0, 0);
    this.updateDimensions(this.wrapper.offsetWidth, this.wrapper.offsetHeight);
    this.callback();
  };
}
