import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@App/app/auth/auth.service';
import { selectIdToken } from '@App/app/auth/store/auth/selectors/auth.selectors';
import { RefreshedTokens } from '@App/app/entities/auth/tokens.model';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<RefreshedTokens | null>(null);

  constructor(private authService: AuthService, private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let api: string | null = '';
    this.authService.endpointPath.pipe(take(1)).subscribe((path) => {
      api = path;
    });

    let idToken = '';
    this.store
      .select(selectIdToken)
      .pipe(take(1))
      .subscribe((token) => {
        idToken = token;
      });

    if (idToken && (request.url.includes(api) || request.url.includes(environment.loginUrl))) {
      request = this.addToken(request, idToken);
    }

    return next.handle(request).pipe(catchError((error) => this.handleError(error, request, next)));
  }

  // eslint-disable-next-line complexity
  private handleError(error: any, request: HttpRequest<any>, next: HttpHandler) {
    switch (error?.status) {
      case 0: {
        return [];
      }
      case 401:
        return this.authService.isAuth() ? this.handle401Error(request, next) : [];
      case 403: {
        if (request.headers.get('notLogOutOn403')) {
          return throwError(error);
        } else {
          if (!request.url.endsWith('logo.svg')) {
            this.authService.onLogout();
          }
          return [];
        }
      }
      default:
        return throwError(error);
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshTokens().pipe(
        switchMap((tokens: RefreshedTokens) => {
          this.refreshTokenSubject.next(tokens);
          return next.handle(this.addToken(request, tokens.idToken));
        }),
        finalize(() => {
          this.isRefreshing = false;
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((tokens) => tokens != null),
        take(1),
        switchMap((tokens) => {
          if (tokens) {
            return next.handle(this.addToken(request, tokens.idToken));
          } else {
            return EMPTY;
          }
        }),
      );
    }
  }
}
