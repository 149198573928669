import { DEFAULT_POSITION } from '@App/app/configs/photos-map.config';
import { FileTypes } from '@App/app/entities/files/files-data.model';
import { UnsavedFile } from '@App/app/entities/files/files.model';
import { Model } from '@App/app/entities/models/model.model';
import { ConfirmationDialogComponent } from '@App/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbDialogService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Coordinate } from 'maptalks';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, of } from 'rxjs';
import { first, mergeMap, take, takeUntil } from 'rxjs/operators';
import { FileMetadataInfo } from '../../../models/uploaded-image-metadata.model';
import { GcpService } from '../../../services/gcp/gcp.service';
import { UploaderStorageService } from '../../../services/uploader-storage-service/uploader-storage.service';
import { State } from '../../../store/files/reducers/files.reducer';
import { selectFilesState, selectGCPFile } from '../../../store/files/selectors/files.selectors';
import { DefaultUploaderComponent } from '../default-uploader.component';
import { PhotosValidationService } from './photos-validation.service';
import { UploadApiService } from './upload-api.service';
import { XlifService } from './xlif.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UploaderUtilsService {
  defaultUploaderComponentDelegate: DefaultUploaderComponent | null;

  constructor(
    private dialogService: NbDialogService,
    private sanitizer: DomSanitizer,
    private uploadApiService: UploadApiService,
    private uploaderStorageService: UploaderStorageService,
    private photosValidatorService: PhotosValidationService,
    private store: Store,
    private gcpService: GcpService,
    private xlifService: XlifService,
  ) {}

  checkUserCanComeBack(): Observable<boolean> {
    return this.uploaderStorageService.uploadStarted$.pipe(
      mergeMap((value) => {
        if (value) {
          return this.dialogService.open(ConfirmationDialogComponent, {
            context: {
              title:
                'There is an active upload in progress. Leaving this page will cause the upload to be canceled. Are you sure you want to leave this page?',
              shouldCancelUpload: true,
            },
          }).onClose;
        } else {
          return of(true);
        }
      }),
    );
  }

  getModelPosition(model: Model) {
    const coords: [number, number] = model?.location
      ? [Number(model.location.longitude), Number(model.location.latitude)]
      : (DEFAULT_POSITION as [number, number]);
    return new Coordinate(...coords);
  }

  async getDroppedFiles(files: NgxFileDropEntry[]) {
    const result: File[] = [];
    for await (const droppedFile of files) {
      const file = await this.getDroppedFile(droppedFile);
      if (file) {
        result.push(file);
      }
    }
    return result;
  }

  loadPendingUploadProcess(id: number) {
    this.uploadApiService
      .getPendingUploadProcess(id)
      .pipe(untilDestroyed(this), takeUntil(this.uploaderStorageService.cancelUploadSubject))
      .subscribe((process) => {
        if (process) {
          this.uploaderStorageService.setPending(process.id, true, +process.progress);
        } else {
          this.uploaderStorageService.resetPending();
        }
      });
  }

  fileToUrl(file: File) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)) as string;
  }

  filesToPayloadObjects(files: File[]) {
    return new Promise<UnsavedFile[]>((resolve) => {
      this.store
        .select(selectFilesState)
        .pipe(first())
        .subscribe(({ flatFileName, controlPointsFileName }) => {
          const payload = files.map(({ name }) => {
            switch (name) {
              case flatFileName:
                return { name, type: FileTypes.FLAT_FILE };
              case controlPointsFileName:
                return { name, type: FileTypes.GCP };
              default:
                return { name, type: FileTypes.PHOTO };
            }
          });
          resolve(payload);
        });
    });
  }

  getGCPFileMetadata() {
    return new Promise<FileMetadataInfo[]>((resolve) => {
      this.store
        .select(selectGCPFile)
        .pipe(take(1))
        .subscribe(async (gcpFile) => {
          this.uploaderStorageService.invalidGCPFormat$.next(false);
          try {
            const gcpData = gcpFile ? await this.gcpService.getControlPointsFromFile(gcpFile) : [];
            resolve(gcpData);
          } catch (_) {
            this.uploaderStorageService.invalidGCPFormat$.next(true);
            resolve([]);
          }
        });
    });
  }

  validateMetadata(state: State) {
    const { flatFileName, controlPointsFileName: gcpFileName, csvFiles } = state;
    let { xlifData } = state;

    return new Promise<FileMetadataInfo[]>(async (resolve) => {
      if (flatFileName) {
        const flatFile = csvFiles.find((file) => file.name === flatFileName) as File;
        const flatFileMetadata = await this.xlifService.getXlifDataFromFlatFile(flatFile);
        xlifData = xlifData.map((i) => {
          const dataFromFlatFile = flatFileMetadata.find((j) => j.name === i.name);
          return dataFromFlatFile || new FileMetadataInfo(i.name);
        });
      }

      const gcpData = gcpFileName ? await this.getGCPFileMetadata() : [];
      const gcpNames = gcpData.map((row) => row.name);

      const res = this.photosValidatorService.validatePhotos([...xlifData, ...gcpData]);
      const noCoords = res.noCoords.filter((n) => !gcpNames.includes(n));
      const wrongCoords = res.wrongCoords.filter((n) => !gcpNames.includes(n));
      const isGCPWrong = gcpNames.some((n) => res.wrongCoords.includes(n));
      const allWrongCoords =
        isGCPWrong && gcpFileName ? [gcpFileName, ...wrongCoords] : wrongCoords;

      this.uploaderStorageService.noCoordsFileNames$.next(noCoords);
      this.uploaderStorageService.wrongCoordsFileNames$.next(allWrongCoords);

      this.uploaderStorageService.updateWrongMetadataInfo();
      resolve(xlifData);
    });
  }

  addBackPrevention() {
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', this._onBeforeBack);
  }

  removeBackPrevention() {
    window.removeEventListener('popstate', this._onBeforeBack);
  }

  private _onBeforeBack = () => {
    this.checkUserCanComeBack()
      .pipe(take(1))
      .subscribe((canComeBack) => {
        if (canComeBack) {
          this.defaultUploaderComponentDelegate?.ngOnDestroy();
          history.back();
        } else {
          this.removeBackPrevention();
          history.go(1);
          setTimeout(() => {
            window.addEventListener('popstate', this._onBeforeBack);
          }, 100);
        }
      });
  };

  private async getDroppedFile(droppedFile: NgxFileDropEntry) {
    return new Promise<File | null>((resolve) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => resolve(file));
      } else {
        resolve(null);
      }
    });
  }
}
