import { Model } from '@App/app/entities/models/model.model';
import { Pipe, PipeTransform } from '@angular/core';
import { ModelStateAbbrPipe } from './model-state-abbr.pipe';

@Pipe({
  name: 'modelAddress',
})
export class ModelAddressPipe implements PipeTransform {
  constructor(private _stateAbbrPipe: ModelStateAbbrPipe) {}

  transform(model: Model): string {
    const { street, city, state, zip } = model.address;

    if (![street, city, state, zip].some(Boolean)) {
      return '';
    }

    return (
      (street ? street + ', ' : '') +
      (city ? city + ', ' : '') +
      this._stateAbbrPipe.transform(state) +
      ' ' +
      zip
    );
  }
}
