import { Injectable } from '@angular/core';
import { Color3, DynamicTexture, Mesh, Scene, StandardMaterial, Vector3 } from 'babylonjs';
import { BabylonNodesService } from '../babylon-nodes-service/babylon-nodes.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateWorldAxisService {
  meshArr: (Mesh | null)[] = [];
  constructor(private babylonNodesService: BabylonNodesService) {}

  /**
   * creates the world axes
   *
   * Source: https://doc.babylonjs.com/snippets/world_axes
   *
   * @param size number
   * @param scene babylonjs's Scene
   */
  showWorldAxis(size: number, scene: Scene) {
    const makeTextPlane = (text: string, color: string, textSize: number) => {
      const dynamicTexture = new DynamicTexture('DynamicTexture', 50, scene, true);
      dynamicTexture.hasAlpha = true;
      dynamicTexture.drawText(text, 5, 40, 'bold 36px Arial', color, 'transparent', true);
      const material = new StandardMaterial('TextPlaneMaterial', scene);
      material.backFaceCulling = false;
      material.specularColor = new BABYLON.Color3(0, 0, 0);
      material.diffuseTexture = dynamicTexture;
      const plane = this.babylonNodesService.createPlane(
        'TextPlane',
        {
          size: textSize,
          updatable: true,
        },
        material,
      );

      return plane;
    };

    const axisX = this.babylonNodesService.createLine(
      [
        Vector3.Zero(),
        new Vector3(size, 0, 0),
        new Vector3(size * 0.95, 0.05 * size, 0),
        new Vector3(size, 0, 0),
        new Vector3(size * 0.95, -0.05 * size, 0),
      ],
      'axisX',
      new BABYLON.Color3(1, 0, 0),
    );

    this.meshArr.push(axisX);
    const xChar = makeTextPlane('X', 'red', size / 10);
    this.meshArr.push(xChar);
    xChar.position = new Vector3(0.9 * size, -0.05 * size, 0);

    const axisY = this.babylonNodesService.createLine(
      [
        Vector3.Zero(),
        new Vector3(0, size, 0),
        new Vector3(-0.05 * size, size * 0.95, 0),
        new Vector3(0, size, 0),
        new Vector3(0.05 * size, size * 0.95, 0),
      ],
      'axisY',
      new Color3(0, 1, 0),
    );

    this.meshArr.push(axisY);
    const yChar = makeTextPlane('Y', 'green', size / 10);
    this.meshArr.push(yChar);
    yChar.position = new Vector3(0, 0.9 * size, -0.05 * size);
    const axisZ = this.babylonNodesService.createLine(
      [
        Vector3.Zero(),
        new Vector3(0, 0, size),
        new Vector3(0, -0.05 * size, size * 0.95),
        new Vector3(0, 0, size),
        new Vector3(0, 0.05 * size, size * 0.95),
      ],
      'axisZ',
      new Color3(0, 0, 1),
    );

    this.meshArr.push(axisZ);
    const zChar = makeTextPlane('Z', 'blue', size / 10);
    this.meshArr.push(zChar);
    zChar.position = new Vector3(0, 0.05 * size, 0.9 * size);

    // Hide it on start of the application
    // this.showHideWorldAxis();
  }

  showHideWorldAxis() {
    this.meshArr.forEach((element) => {
      element?.setEnabled(element.isEnabled() ? false : true);
    });
  }

  destroyAxis() {
    for (let i = 0; i < this.meshArr.length; i++) {
      this.meshArr[i]?.dispose();
      this.meshArr[i] = null;
    }
  }
}
