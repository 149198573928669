import { MaterialService } from '@App/app/engine/services/material-service/material.service';
import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { UtilsService } from '@App/app/engine/services/utils-service/utils.service';
import { Cuboid } from '@App/app/entities/layer/cuboid.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GizmoManager, Mesh, Vector3 } from 'babylonjs';
import { CUBOID_PICKER_NAME, MODEL_MESH_NAME } from 'src/app/configs/babylon.config';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { checkMeshesIntersections, rotationQuaternion } from '../../../../helpers/layers-helpers';
import { getAndAdjustMinMax } from '../../../../helpers/rectangle-tools-helpers';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { GizmoManagerService } from '../../../gizmo-manager/gizmo-manager.service';
import { LoadModelService } from '../../../load-model-service/load-model.service';
import { LayerUtilsService } from '../../layer-utils-service/layer-utils.service';
import { RectangleToolsService } from '../shared/rectangle-tools-service';
import { VolumeUtilsService } from '../shared/volume-utils.service';
import { CuboidUtilsService } from './cuboid-utls.service';

@Injectable({
  providedIn: 'root',
})
export class CuboidPickerService extends GizmoManagerService {
  private _gizmoTmpId = -1;
  private _box: Mesh | null = null;

  constructor(
    private _babylonNodesService: BabylonNodesService,
    private _cuboidUtilsService: CuboidUtilsService,
    private _layerUtilsService: LayerUtilsService,
    private _materialService: MaterialService,
    private _rectangleToolsService: RectangleToolsService,
    private _utilsService: UtilsService,
    private _volumeUtilsService: VolumeUtilsService,
    private _loadModelService: LoadModelService,
    private store: Store,
    broadcastService: BroadcastService,
    sceneService: SceneService,
  ) {
    super(sceneService, broadcastService);
  }

  initCuboidByUser(): void {
    const volumeVerticesPosition = this._box
      ? this._cuboidUtilsService.createVertices(this._box, this._loadModelService.model)
      : [];

    const modelMeshes = this.sceneService.scene.meshes.filter((mesh) =>
      mesh.name.startsWith(MODEL_MESH_NAME),
    );

    if (this._box && !checkMeshesIntersections(this._box, modelMeshes)) {
      this.removeCuboidPicker();
    }

    if (!volumeVerticesPosition.length) {
      return;
    }

    const min = volumeVerticesPosition[0].clone();
    const max = volumeVerticesPosition[0].clone();
    volumeVerticesPosition.forEach((element) => {
      min.x = Math.min(min.x, element.x);
      min.y = Math.min(min.y, element.y);
      min.z = Math.min(min.z, element.z);

      max.x = Math.max(max.x, element.x);
      max.y = Math.max(max.y, element.y);
      max.z = Math.max(max.z, element.z);
    });
    const mesh = this._volumeUtilsService.initBoundingBox(min, max);

    const cuboid = new Cuboid();
    cuboid.isVisible = true;
    cuboid.isSaved = true;
    cuboid.data = this._volumeUtilsService.getCalculatedData(mesh);
    cuboid.data.max = { x: max.x, y: max.y, z: max.z };
    cuboid.data.min = { x: min.x, y: min.y, z: min.z };
    cuboid.data.worldDirection = 0;
    cuboid.data.verticalTilt = 0;
    cuboid.data.azimuth = 0;
    this.store.dispatch(addLayer({ layer: cuboid, commit: false }));
    mesh.dispose();
    this.removeCuboidPicker();
  }

  importCuboid(cuboid: Cuboid, modelCenter: Vector3) {
    const newCuboid = { ...cuboid };
    newCuboid.data = { ...cuboid.data };
    const [min, max] = getAndAdjustMinMax(newCuboid);
    const mesh = this._volumeUtilsService.initBoundingBox(min, max, newCuboid);
    mesh.rotationQuaternion = rotationQuaternion(newCuboid);
    newCuboid.data = this._volumeUtilsService.getCalculatedData(mesh);
    if (cuboid.data.absoluteRotationQuaternion) {
      newCuboid.data.absoluteRotationQuaternion = {
        ...cuboid.data.absoluteRotationQuaternion,
      };
    }
    newCuboid.data.min = { x: min.x, y: min.y, z: min.z };
    newCuboid.data.max = { x: max.x, y: max.y, z: max.z };
    const [azimuth, verticalTilt] = this._rectangleToolsService.calculateAzimuthAndVerticalTilt(
      mesh,
      modelCenter,
    );
    newCuboid.data.verticalTilt = verticalTilt || 0;
    newCuboid.data.azimuth = azimuth || 0;
    mesh.dispose();
    return this._layerUtilsService.createRequestBody(newCuboid);
  }

  removeCuboidPicker() {
    if (this.isPickerActive()) {
      this._box?.dispose();
      this._box = null;
      const gizmo = this.getGizmoManagers()[this._gizmoTmpId];

      if (gizmo) {
        gizmo.positionGizmoEnabled = false;
        gizmo.rotationGizmoEnabled = false;
        gizmo.scaleGizmoEnabled = false;
        gizmo.boundingBoxGizmoEnabled = false;
      }
    }
  }

  onClickActionCuboidPickerService() {
    const hit = this._utilsService.pickRay();

    if (!hit?.pickedPoint) {
      return false;
    }

    let gizmoManager: GizmoManager;
    [this._box, gizmoManager] = this._createCuboidPicker(hit.pickedPoint);

    this.setGizmoManager(this._gizmoTmpId, gizmoManager);
  }

  isPickerActive() {
    return !!this._box;
  }

  private _createCuboidPicker(position: Vector3): [Mesh, GizmoManager] {
    const cuboidPickerMesh = this._babylonNodesService.createBox(
      position,
      { size: 1 },
      null,
      CUBOID_PICKER_NAME,
      this._materialService.darkBlueMaterial,
    );
    cuboidPickerMesh.visibility = 0.2;
    const gizmoManager = this.createGizmoManager();

    this.broadcastService.broadcast(EVENT_TYPE.CHANGE_BOUNDING_BOX_BUTTON_STATUS, false);

    gizmoManager.positionGizmoEnabled = true;
    gizmoManager.rotationGizmoEnabled = false;
    gizmoManager.scaleGizmoEnabled = true;
    gizmoManager.boundingBoxGizmoEnabled = false;

    if (gizmoManager.gizmos.positionGizmo) {
      gizmoManager.gizmos.positionGizmo.scaleRatio = 1.5;
    }

    gizmoManager.attachableMeshes = [cuboidPickerMesh];
    gizmoManager.attachToMesh(cuboidPickerMesh);

    return [cuboidPickerMesh, gizmoManager];
  }
}
