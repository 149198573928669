<div class="change_password__title">
  Change Password
  <nb-icon
    id="icon"
    class="layers__header_svg change_password__icon"
    icon="unlock-outline"
  ></nb-icon>
</div>

<form class="change_password__form" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <div class="change_password__section">
    <div class="change_password__label">Current password</div>
    <div class="change_password__wrapper">
      <input
        class="change_password__input"
        nbInput
        [type]="passwordsVisibility.currentPassword ? 'text' : 'password'"
        placeholder="Current password"
        name="currentPassword"
        formControlName="currentPassword"
        required
      />
      <button
        class="change_password__button"
        nbSuffix
        nbButton
        ghost
        type="button"
        (click)="passwordsVisibility.currentPassword = !passwordsVisibility.currentPassword"
      >
        <nb-icon
          [icon]="passwordsVisibility.currentPassword ? 'eye-outline' : 'eye-off-2-outline'"
        ></nb-icon>
      </button>
    </div>
    <div
      *ngIf="
        changePasswordForm.controls.currentPassword.touched &&
        changePasswordForm.controls.currentPassword.errors
      "
    >
      <div
        class="change_password__text change_password__text--error"
        *ngIf="changePasswordForm.controls.currentPassword.errors.required"
      >
        Current password is required.
      </div>
    </div>
  </div>
  <div class="change_password__section">
    <div class="change_password__label">New password</div>
    <div class="change_password__wrapper">
      <input
        class="change_password__input"
        nbInput
        [type]="passwordsVisibility.newPassword ? 'text' : 'password'"
        placeholder="New password"
        name="newPassword"
        formControlName="newPassword"
        required
      />
      <button
        class="change_password__button"
        nbSuffix
        nbButton
        ghost
        type="button"
        (click)="passwordsVisibility.newPassword = !passwordsVisibility.newPassword"
      >
        <nb-icon
          [icon]="passwordsVisibility.newPassword ? 'eye-outline' : 'eye-off-2-outline'"
        ></nb-icon>
      </button>
    </div>
    <div
      *ngIf="
        changePasswordForm.controls.newPassword.touched &&
        changePasswordForm.controls.newPassword.errors
      "
    >
      <div
        class="change_password__text change_password__text--error"
        *ngIf="changePasswordForm.controls.newPassword.errors.required"
      >
        New password is required.
      </div>
      <div
        class="change_password__text change_password__text--error"
        *ngIf="changePasswordForm.controls.newPassword.errors.mustMatch"
      >
        New password cannot be the same as the current password.
      </div>
      <div
        class="change_password__text change_password__text--error"
        *ngIf="changePasswordForm.controls.newPassword.errors.pattern"
      >
        <app-wrong-password-error></app-wrong-password-error>
      </div>
    </div>
  </div>
  <div class="change_password__section">
    <div class="change_password__label">Confirm new password</div>
    <div class="change_password__wrapper">
      <input
        class="change_password__input"
        nbInput
        [type]="passwordsVisibility.newPasswordConfirm ? 'text' : 'password'"
        placeholder="Confirm new password"
        name="newPasswordConfirm"
        formControlName="newPasswordConfirm"
        required
      />
      <button
        class="change_password__button"
        nbSuffix
        nbButton
        ghost
        type="button"
        (click)="passwordsVisibility.newPasswordConfirm = !passwordsVisibility.newPasswordConfirm"
      >
        <nb-icon
          [icon]="passwordsVisibility.newPasswordConfirm ? 'eye-outline' : 'eye-off-2-outline'"
        ></nb-icon>
      </button>
    </div>
    <div
      *ngIf="
        changePasswordForm.controls.newPasswordConfirm.touched &&
        changePasswordForm.controls.newPasswordConfirm.errors
      "
    >
      <div
        class="change_password__text change_password__text--error"
        *ngIf="changePasswordForm.controls.newPasswordConfirm.errors.required"
      >
        Password confirmation is required.
      </div>
      <div
        class="change_password__text change_password__text--error"
        *ngIf="changePasswordForm.controls.newPasswordConfirm.errors.mustMatch"
      >
        Password and password confirmation must match.
      </div>
    </div>
  </div>
  <button
    nbButton
    status="info"
    class="change_password__submit"
    type="submit"
    [disabled]="changePasswordForm.invalid"
  >
    Change password
  </button>
</form>
