import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProcessingEngineService } from '@App/app/engine/services/engine-services/processing-engine-service/processing-engine.service';
import { ATLog, ProdLog } from '@App/app/entities/files/files.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { NbTrigger } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-tab-actions',
  templateUrl: './tab-actions.component.html',
  styleUrls: ['./tab-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabActionsComponent implements OnInit, OnChanges {
  @ViewChild('dialog', { static: true }) dialog: TemplateRef<any>;
  @Input() ATContinueEnabled: boolean;
  @Input() backAction: () => void;
  @Input() backActionName: string;
  @Input() isSubmit = false;
  @Input() nextAction?: (form?: FormGroup) => void;
  @Input() nextActionName: string;
  @Input() nextActionPermission = true;
  @Input() backActionPermission = true;
  @Input() log: ATLog | ProdLog | null = null;
  @Input() requireLoadedModelForNextAction = true;
  @Input() nextActionDeniedTooltip = 'You do not have permission to this action';
  @Input() backActionDeniedTooltip = 'You do not have permission to this action';

  // this property is only for testing purposes. Normally nb-layout is rendered in app component
  // (which is not rendered in thats components test)
  // when nebular try to append dialog looks for nb-layout and when it is not there error is thrown
  nbLayoutActive = false;
  isModelLoaded: boolean;
  isModelLoading: boolean;
  logsText: string | null = null;
  openDialog = false;
  NbTrigger = NbTrigger;

  constructor(
    private modelsProcessingService: ModelsProcessingService,
    private store: Store,
    private engineService: ProcessingEngineService,
  ) {}

  ngOnInit(): void {
    this.engineService.isModelLoaded$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isModelLoaded = value;

      // when user hovers disabled button, tooltip shows up. Once model is loaded, the tooltip
      // doesn't hide this is why this line is needed over here
      document.querySelector('nb-tooltip')?.remove();
    });

    this.engineService.isModelLoading$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isModelLoading = value;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.log?.currentValue) {
      this.modelsProcessingService.currentBuildProcessLogs
        .pipe(
          untilDestroyed(this),
          takeWhile(() => !this.logsText),
        )
        .subscribe((logs) => {
          if (this.log && logs.hasOwnProperty(this.log.type)) {
            this.logsText = logs[(this.log.type as unknown) as keyof typeof logs] || null;
          }
        });
    }
  }

  openLogsDialog() {
    this.openDialog = true;
  }

  closeLogsDialog() {
    this.openDialog = false;
  }

  getNextActionPermissionDenyMessageIfExists() {
    if (this.shouldWaitForModelToBeLoaded()) {
      return 'Wait for model to be loaded';
    } else if (!this.nextActionPermission) {
      return this.nextActionDeniedTooltip;
    } else {
      return null;
    }
  }

  private shouldWaitForModelToBeLoaded() {
    return (
      ((!this.ATContinueEnabled || this.requireLoadedModelForNextAction) && !this.isModelLoaded) ||
      this.isModelLoading
    );
  }
}
