<nb-card
  class="photo_card_zoom"
  [ngClass]="{ '--large-displaying': isLargePhotoDisplaying && !loading }"
>
  <nb-card-header class="photo_card_zoom__header">
    <span class="photo_card_zoom__header__title">{{ photo.name }}</span>
    <nb-action
      [icon]="annotationsVisible ? 'eye-outline' : 'eye-off-outline'"
      class="photo_card_zoom__header__button"
      (click)="onToggleAnnotationsVisible()"
      [nbTooltip]="
        editingAnnotationsActive
          ? 'Cannot hide annotations during edition'
          : annotationsVisible
          ? 'Hide annotations'
          : 'Show annotations'
      "
      [disabled]="editingAnnotationsActive || loading"
      data-testId="annotations-visibility-toggle-btn"
    ></nb-action>
    <nb-action
      *ngxPermissionsOnly="permissions.CAN_ANNOTATE_PHOTO"
      icon="edit-outline"
      class="photo_card_zoom__header__button"
      (click)="onToggleEditingAnnotationActive()"
      [nbTooltip]="editingAnnotationsActive ? 'Finish Editing' : 'Add/Edit annotations'"
      [ngClass]="{ '--active': editingAnnotationsActive }"
      [disabled]="loading"
      data-testId="edit-btn"
    ></nb-action>
    <nb-action
      [icon]="isLargePhotoDisplaying ? 'collapse-outline' : 'expand-outline'"
      class="photo_card_zoom__header__button"
      (click)="onToggleLargePhoto()"
      [nbTooltip]="isLargePhotoDisplaying ? 'Show thumbnail' : 'Show large photo'"
      [disabled]="loading"
      data-testId="large-photo-toggle-btn"
    ></nb-action>
    <nb-action
      *ngxPermissionsOnly="permissions.CAN_DOWNLOAD_PHOTO"
      icon="download-outline"
      class="photo_card_zoom__header__button"
      (click)="onDownload()"
      [nbTooltip]="'Download'"
      [disabled]="loading"
      data-testId="download-btn"
    ></nb-action>
    <nb-icon
      nbTooltip="Close"
      icon="close-outline"
      class="photo_card_zoom__header__button"
      (click)="onClose()"
      data-testId="close-btn"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body class="photo_card_zoom__photo" [nbSpinner]="loading">
    <app-model-photo-card-zoom-annotations
      [annotations]="annotations"
      [htmlImage]="image"
      [htmlWrapper]="wrapper"
      [parentRef]="getRef()"
      [visible]="annotationsVisible"
      [editingActive]="editingAnnotationsActive"
      (save)="saveAnnotations()"
    ></app-model-photo-card-zoom-annotations>
    <div #wrapper class="photo_card_zoom__photo__wrapper" data-testId="wrapper">
      <img
        #image
        [src]="isLargePhotoDisplaying ? photo.downloadURL : (photo | photoThumbnail)"
        [ngClass]="{ '--dark': loading || editingAnnotationsActive }"
        alt="enlarge site photo"
      />
    </div>
    <app-photo-zoom-legend
      *ngIf="editingAnnotationsActive"
      leftMouseButtonActionLabel="Annotate"
      class="photo_card_zoom__photo__legend"
      data-testId="legend"
    ></app-photo-zoom-legend>
  </nb-card-body>
</nb-card>

<nb-layout *ngIf="nbLayoutActive"></nb-layout>
