import { GcpHttpService } from '@App/app/pages/viewer/services/gcp-points/gcp-points.http.service';
import { AfterViewInit, Component, ElementRef, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AtSetupControlPointsService } from '../services/at-setup-control-points-service/at-setup-control-points.service';
import { DEFAULT_SRS_LIST, Srs } from './models/srs.model';

@Component({
  selector: 'app-srs-dropdown',
  templateUrl: './srs-dropdown.component.html',
  styleUrls: ['./srs-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SrsDropdownComponent),
      multi: true,
    },
  ],
})
export class SrsDropdownComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild('autoInput') input: ElementRef<any>;
  loading = false;
  customSrsList: Srs[] = [];
  defaultSrsList: Srs[] = DEFAULT_SRS_LIST;
  value: Srs | null;
  onChange: (value: Srs | null) => void;

  constructor(
    private _gcpHttpService: GcpHttpService,
    private _atSetupControlPointsService: AtSetupControlPointsService,
  ) {}

  writeValue(obj: Srs | null): void {
    this.value = obj;
  }

  registerOnChange(fn: (value: Srs | null) => void) {
    this.onChange = fn;
  }

  registerOnTouched(_: any) {}

  ngAfterViewInit() {
    const searchText$: Observable<string> = fromEvent<any>(this.input.nativeElement, 'keyup').pipe(
      map((event) => {
        const numReg = new RegExp('^[0-9]+$');
        if (!numReg.test(event.target.value) && numReg.test(event.key)) {
          event.target.value = event.key;
        }
        return numReg.test(event.target.value) ? event.target.value : '';
      }),
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
    );

    searchText$
      .pipe(
        filter((search) => !!search && !this.isDuplicate(search)),
        tap(() => {
          this.loading = true;
        }),
        switchMap((search) => this._gcpHttpService.getHttpSrs(search)),
      )
      .subscribe((srs) => {
        if (srs.code) {
          this.customSrsList.push(srs);
        }
        this.loading = false;
      });
  }

  getForm() {
    return this._atSetupControlPointsService.form;
  }

  onSelectionChange(event: string) {
    const selectedSrs = [...this.customSrsList, ...this.defaultSrsList].find(
      (i) => i.code === event,
    );
    if (selectedSrs) {
      this.onChange(selectedSrs);
    }
  }

  isDuplicate(search: string) {
    return (
      this.defaultSrsList.some((srs) => srs.code.split(':')[1] === search) ||
      this.customSrsList.some((srs) => srs.code.split(':')[1] === search)
    );
  }

  isValid() {
    return !(this.getForm().controls.srs.touched && !this.getForm().controls.srs.valid);
  }

  // eslint-disable-next-line complexity
  viewHandle = (value: string, input = true) => {
    if (input && value === '') {
      this.onChange(null);
      return '';
    }
    if (isNaN(+value)) {
      const srs = [...this.customSrsList, ...this.defaultSrsList].find((i) => i.code === value);
      return srs ? `${srs.code}${srs.projection ? ' - ' + srs.projection : ''}` : '';
    }
    return value;
  };
}
