<app-site-layout
  [filtersTpl]="filtersTpl"
  [leftControlsTpl]="leftControlsTpl"
  [rightControlsTpl]="rightControlsTpl"
  [contentTpl]="contentTpl"
  [companyId]="companyId"
  [pageRangeInfo]="pageRangeInfo$ | async"
  [adjustContentHeight]="mode !== Modes.TABLE"
></app-site-layout>

<ng-template #filtersTpl>
  <app-models-table-filters class="filters_component"></app-models-table-filters>
</ng-template>

<ng-template #leftControlsTpl>
  <ng-container *ngIf="hasPermission(permissions.CAN_ADD_MODEL)">
    <button
      nbButton
      size="tiny"
      status="primary"
      [routerLink]="['/', companyId, 'models', 'add']"
      class="sites-nav__button"
    >
      <nb-icon icon="plus-square-outline"></nb-icon>
      Add model
    </button>
  </ng-container>
  <ng-container *ngIf="hasPermission(permissions.CAN_UPLOAD_PHOTOS)">
    <button
      nbButton
      size="tiny"
      status="primary"
      class="sites-nav__button"
      [routerLink]="['/', companyId, 'uploader', 'selection']"
    >
      <nb-icon icon="camera-outline"></nb-icon>
      Upload photos
    </button>
  </ng-container>
</ng-template>

<ng-template #rightControlsTpl>
  <app-actions-buttons
    *ngIf="mode === Modes.TABLE"
    (archive)="archive()"
    (delete)="delete()"
    [disabled]="!selectedEntities.length"
    [archiveButtonLabel]="showArchived ? 'Unarchive models' : 'Archive models'"
    deleteButtonLabel="Delete models"
  ></app-actions-buttons>
  <div *ngIf="hasPermission(permissions.CAN_ACCESS_ARCHIVED_MODELS)" class="sites-nav__mode">
    <nb-select [selected]="showArchived" size="tiny" (selectedChange)="changeArchiveSelect($event)">
      <nb-option [value]="true">Archived</nb-option>
      <nb-option [value]="false">Active</nb-option>
    </nb-select>
  </div>
</ng-template>

<ng-template #contentTpl>
  <nb-tabset (changeTab)="onChangeTab($event)" class="models_site__tabset">
    <nb-tab
      [tabId]="Modes.TABLE"
      tabIcon="layout-outline"
      tabTitle="Table"
      [active]="mode === Modes.TABLE"
    >
      <app-models-table></app-models-table>
    </nb-tab>
    <nb-tab
      [tabId]="Modes.GRID"
      tabIcon="grid-outline"
      tabTitle="Grid"
      [active]="mode === Modes.GRID"
    >
      <app-models-grid></app-models-grid>
    </nb-tab>
    <nb-tab [tabId]="Modes.MAP" tabIcon="map-outline" tabTitle="Map" [active]="mode === Modes.MAP">
      <app-models-map></app-models-map>
    </nb-tab>
  </nb-tabset>
</ng-template>
