import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { Injectable } from '@angular/core';
import { ArcRotateCamera } from 'babylonjs';

@Injectable({
  providedIn: 'root',
})
export class CypressService {
  constructor(private _sceneService: SceneService) {}

  setupGlobalVarsIfNeeded(camera: ArcRotateCamera): void {
    /**
     * This global vars are used for cypress to get access to
     * Babylon's scene and camera in cypress tests (tools tests).
     * They are needed for assertions.
     */
    if (window.Cypress) {
      window.babylonScene = this._sceneService.scene;
      window.babylonCamera = camera;
    }
  }

  fixLoaderIfNeeded() {
    /**
     * Do not remove this function since is needed for
     * Cypress to see viewer loader (idk why).
     */
    if (window.Cypress) {
      const engineComponent = document.querySelector('app-engine') as HTMLElement;
      engineComponent.style.setProperty('height', 'calc(100vh - 8.75rem)');
    }
  }
}
