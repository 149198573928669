<div class="details__section details__section__header">
  <p>Model</p>
  <a (click)="toggleStructureDataFormat()" class="details__section__header__toggler">
    Show in {{ structureDataUnitsSystem === unitsSystem.Imperial ? 'metric' : 'imperial' }}
  </a>
</div>
<div class="details__section">
  <table>
    <ng-container *ngTemplateOutlet="typeTable; context: { model }"></ng-container>
    <ng-container *ngFor="let param of dataTypeParams[1].params">
      <tr>
        <td>{{ param.title }}</td>
        <td data-testId="model-parameter">
          {{
            !!model.data[param.formControlName] && !param.isRadCenterInput
              ? (model.data[param.formControlName] | lengthUnit: structureDataUnitsSystem)
              : param.isRadCenterInput &&
                !!model.data[param.formControlName].length &&
                !!model.data[param.formControlName][0]
              ? formatRadCenters(model.data.radCenters, structureDataUnitsSystem)
              : '-'
          }}
        </td>
      </tr>
    </ng-container>
  </table>
</div>

<ng-template #typeTable let-model="model">
  <tr>
    <td>Type</td>
    <td>
      {{
        !!model.data.type ? model.data.type.charAt(0).toUpperCase() + model.data.type.slice(1) : '-'
      }}
    </td>
  </tr>
</ng-template>
