<app-layers-back-button (backClick)="onBackClick()"></app-layers-back-button>
<div class="layers__list">
  <div class="layer_details layer_details--folder">
    <app-layer-details-name
      [name]="folder.name"
      [editForm]="nameForm"
      [createdAt]="folder.createdAt"
      [type]="LAYER_TYPES.FOLDERS"
      (changeName)="onChangeName($event)"
    ></app-layer-details-name>
    <div class="layer_details__actions" *ngIf="!isDeleteActive">
      <button
        *ngxPermissionsOnly="permissions.CAN_DELETE_LAYER"
        type="button"
        data-testId="delete-btn"
        class="layer_details__action layer_details__action--delete"
        (click)="onDeleteClick()"
      >
        Delete
      </button>
    </div>
    <div class="layer_details__actions" *ngIf="isDeleteActive">
      <span class="layer_details__info">Are you sure?</span>
      <button
        type="button"
        data-testId="delete-cancel-btn"
        class="layer_details__action layer_details__action--cancel"
        (click)="onDeleteCancel()"
      >
        Cancel
      </button>
      <button
        type="button"
        data-testId="delete-confirm-btn"
        class="layer_details__action layer_details__action--delete"
        (click)="onDeleteConfirm()"
      >
        Delete
      </button>
    </div>
  </div>
</div>
