import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayersService } from '../../../../services/layers/layers.service';

@UntilDestroy()
@Pipe({
  name: 'isLayerVisible',
  pure: false,
})
export class IsLayerVisiblePipe implements PipeTransform {
  private _visibleLayers: LayerUI[];

  constructor(private _layersService: LayersService) {
    this._layersService.visibleLayers$.pipe(untilDestroyed(this)).subscribe((layers) => {
      this._visibleLayers = layers;
    });
  }

  transform(layer: LayerUI) {
    return this._visibleLayers.some(
      (visible) => visible?.id === layer.id && visible?.type === layer.type,
    );
  }
}
