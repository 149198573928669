import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, processesAdapter } from '../reducers/processes.reducer';

export const getProcessesState = createFeatureSelector<State>('processes');

const { selectAll } = processesAdapter.getSelectors(getProcessesState);

export const selectAllProcesses = selectAll;

export const selectProcessesByArchived = (archived: boolean) =>
  createSelector(selectAll, (entities) =>
    entities.filter((entity) => entity.isArchived === archived),
  );

export const selectProcessesByModelId = (modelId: number) =>
  createSelector(selectAll, (entities) => entities.filter((entity) => entity.modelId === modelId));
