import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { disableUser, enableUser } from '../../../../users/store/actions/users.actions';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private store: Store, private router: Router, private authService: AuthService) {}

  disableUser(userId: number) {
    this.store.dispatch(disableUser({ userId }));
  }

  enableUser(userId: number) {
    this.store.dispatch(enableUser({ userId }));
  }

  redirectEditUser(id: number) {
    this.authService.currentEndpoint$.pipe(first()).subscribe((endpoint) => {
      if (endpoint) {
        this.router.navigate([`/${endpoint.id}/edit-user/${id}`]);
      }
    });
  }
}
