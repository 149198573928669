<app-manual-tool-layer-details
  *ngIf="activeLayerEvent === LAYER_EVENTS.ADD_TUBE && isDetailsVisible"
  [samples]="tubeSamples"
  [partName]="TUBE_PART_NAME"
  [sampleHighlighted]="tubeHighlightedSample"
  [generateAvailable]="isGenerateAvailable"
  [activePart]="tubeActivePart"
  (setPartActive)="setTubeActivePart($event)"
  (highlightSample)="toggleHighlightTubeSample($event)"
  (deleteSample)="onDeleteTubeSample($event)"
  (generate)="onGenerate()"
  (cancel)="onCancel()"
>
</app-manual-tool-layer-details>
