import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { TubeData } from '@App/app/entities/layer/tube.model';
import { ThicknessNumberValidatorData } from '@App/app/entities/shared/thickness-number-validator-data';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { toMetricLength } from 'src/app/shared/utils/units-converter';
import { UnitsService } from '../utils/units.service';

@Injectable({
  providedIn: 'root',
})
export class ThicknessFormService {
  private unitsSystem: UnitsSystem;
  private inputSubscriptions: { [id: number]: Subscription[] } = {};
  validatorsData: { [id: number]: ThicknessNumberValidatorData[] } = {};
  forms: { [id: number]: FormGroup } = {};
  layers: { [id: number]: LayerUI } = {};

  constructor(
    private viewerLayerService: ViewerLayerService,
    private fb: FormBuilder,
    private unitsService: UnitsService,
  ) {
    this.unitsService.isImperialActive$.subscribe((value) => {
      this.unitsSystem = value ? UnitsSystem.Imperial : UnitsSystem.Metric;
      for (const id in this.layers) {
        if (this.layers[id]) {
          this.initForm(this.layers[id]);
        }
      }
    });
  }

  setForm(layer: LayerUI, form: FormGroup) {
    this.removeForm(layer);
    this.forms[layer.id] = form;
    this.layers[layer.id] = layer;
    this.inputSubscriptions[layer.id] = [];
    Object.keys(form.controls).forEach((field) => {
      const formControl = form.get(field);
      if (formControl) {
        this.inputSubscriptions[layer.id].push(
          formControl.valueChanges
            .pipe(debounceTime(100))
            .subscribe((value) => this.onInputChange(layer, field, value)),
        );
      }
    });
  }

  removeForm(layer: LayerUI) {
    if (this.layers[layer.id]) {
      delete this.forms[layer.id];
      delete this.layers[layer.id];
    }
    if (this.inputSubscriptions[layer.id]) {
      for (const subscription of this.inputSubscriptions[layer.id]) {
        subscription.unsubscribe();
      }
      this.inputSubscriptions[layer.id] = [];
    }
  }

  removeAllForms() {
    for (const id in this.forms) {
      if (Object.prototype.hasOwnProperty.call(this.forms, id)) {
        this.removeForm(this.layers[id]);
      }
    }
  }

  updateForm(layer: LayerUI) {
    this.validatorsData[layer.id] = this.viewerLayerService.getThicknessFormControls(
      layer,
      this.unitsSystem,
    );
    for (const data of this.validatorsData[layer.id]) {
      const validatorsOfInputData = this.getValidatorsOfInputData(data);
      if (validatorsOfInputData) {
        this.forms[layer.id].get(data.fieldName)?.setValidators(validatorsOfInputData);
      }
    }
  }

  initForm(layer: LayerUI) {
    if (this.viewerLayerService.hasThickness(layer.id)) {
      this.validatorsData[layer.id] = this.viewerLayerService.getThicknessFormControls(
        layer,
        this.unitsSystem,
      );
      const newForm = this.fb.group(this.getControlsFromValidatorsData(layer));
      this.setForm(layer, newForm);
    }
  }

  onInputChange(layer: LayerUI, fieldName: string, newValue: number) {
    const control = this.forms[layer.id].controls[fieldName];
    if (newValue === null) {
      const validationData = this.validatorsData[layer.id].find(
        (data) => data.fieldName === fieldName,
      );
      if (validationData) {
        newValue = validationData.minValue;
        this.forms[layer.id].controls[fieldName].setValue(newValue);
        return;
      }
    }
    if (this.unitsSystem === UnitsSystem.Imperial) {
      newValue = toMetricLength(newValue, 10000, 'in');
      if (newValue === 0) {
        newValue = 0.001;
      }
    }
    this.updateThicknessOnChange(
      control,
      layer,
      fieldName as keyof Omit<TubeData, 'edges' | 'length'>,
      newValue,
    );
  }

  private updateThicknessOnChange(
    control: AbstractControl,
    layer: LayerUI,
    fieldName: keyof Omit<TubeData, 'edges' | 'length'>,
    newValue: number,
  ) {
    if (!control.invalid) {
      this.viewerLayerService.updateThickness(layer, newValue, fieldName);
      this.updateForm(layer);
    } else if (control.errors) {
      const { min, max } = control.errors;
      const thresh = 0.001;
      const nextValue = min ? min.min + thresh : max.max - thresh;
      this.viewerLayerService.updateThickness(layer, nextValue, fieldName);
    }
  }

  private getControlsFromValidatorsData(layer: LayerUI): any {
    return this.validatorsData[layer.id].reduce((res, obj) => {
      (res as any)[obj.fieldName] = [obj.defaultValue, this.getValidatorsOfInputData(obj)];
      return res;
    }, {});
  }

  private getValidatorsOfInputData(data: ThicknessNumberValidatorData) {
    if (data.maxValue) {
      return [Validators.required, Validators.min(data.minValue), Validators.max(data.maxValue)];
    }
  }
}
