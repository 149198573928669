<form [formGroup]="filterForm" class="filterable_list__form">
  <nb-form-field *ngIf="nameField">
    <input
      nbInput
      formControlName="name"
      data-testId="name-field"
      [placeholder]="nameField.placeholder"
    />
    <nb-action icon="close-outline" nbSuffix (click)="resetName()"></nb-action>
  </nb-form-field>
  <nb-form-field *ngIf="dateField">
    <input
      nbInput
      data-testId="date-field"
      [nbDatepicker]="rangePicker"
      formControlName="dateRange"
      [placeholder]="dateField.placeholder"
    />
    <nb-rangepicker #rangePicker></nb-rangepicker>
    <nb-action icon="close-outline" nbSuffix (click)="resetDateRange()"></nb-action>
  </nb-form-field>
</form>
<div
  class="filterable_list__list"
  data-testId="filterable-list-container"
  [nbSpinner]="!entities || !!(entities.length && !selectedEntity)"
>
  <p *ngIf="!getFilteredEntities()?.length && entities" class="filterable_list__list__no_entities">
    {{ noEntitiesPlaceholder }}
  </p>
  <nb-list *ngIf="(entities?.length && selectedEntity) || !entities">
    <nb-list-item
      *ngFor="let entity of getFilteredEntities()"
      data-testId="item"
      class="filterable_list__list__item"
      [ngClass]="{ '--selected': entity.id === selectedEntity?.id }"
      status="info"
      (click)="onEntityChange(entity)"
      >{{ getLabel(entity) }}</nb-list-item
    >
  </nb-list>
</div>
