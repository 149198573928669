<div
  class="layer layers__item layer__visible_on_hover"
  [ngClass]="classes"
  [id]="'layer' + layer.id"
>
  <div class="layer__highlight"></div>
  <div class="layer__type" *ngIf="!!layer.type">
    <nb-icon
      class="layer__icon"
      icon="compass-outline"
      *ngIf="layer.type === layerTypes.BASE_PLANE"
    ></nb-icon>
    <nb-icon
      class="layer__icon"
      icon="expand-outline"
      *ngIf="layer.type === layerTypes.MEASUREMENTS"
    ></nb-icon>
    <nb-icon
      class="layer_details__icon"
      icon="square-outline"
      *ngIf="layer.type === layerTypes.POLYGONS"
    ></nb-icon>
    <nb-icon
      class="layer_details__icon"
      icon="corner-right-down"
      *ngIf="layer.type === layerTypes.DROP_LINES"
    >
    </nb-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.COORDINATES_LAYER"
      [src]="coordsIcon"
      svgClass="layer__svg-icon coord"
    >
    </svg-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.SKETCH_PLANES"
      [src]="planeIcon"
      svgClass="layer__svg-icon"
    >
    </svg-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.SKETCH_LINES"
      [src]="sketchLineIcon"
      svgClass="layer__svg-icon"
    >
    </svg-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.SKETCH_CIRCLES"
      [src]="sketchCircleIcon"
      svgClass="layer__svg-icon"
    >
    </svg-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.SKETCH_RECTANGLE"
      [src]="sketchRectangleIcon"
      svgClass="layer__svg-icon rectangle"
    >
    </svg-icon>
    <svg-icon *ngIf="layer.type === layerTypes.TUBES" [src]="tubeIcon" svgClass="layer__svg-icon">
    </svg-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.ANGLE_IRON"
      [src]="angleIronIcon"
      svgClass="layer__svg-icon"
    >
    </svg-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.CUBOID"
      [src]="rectangularIcon"
      svgClass="layer__svg-icon"
    >
    </svg-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.RECT_TUBE"
      [src]="rectangularTubeIcon"
      svgClass="layer__svg-icon rect_tube"
    >
    </svg-icon>
    <svg-icon
      *ngIf="layer.type === layerTypes.ANNOTATION"
      [src]="annotationIcon"
      svgClass="layer__svg-icon"
    >
    </svg-icon>
  </div>
  <div class="layer__name select-none">{{ layer.name }}</div>
  <ng-container *ngIf="!layerPath.length">
    <div
      *ngIf="layer.isVisible && hasGuiToggler"
      [ngClass]="guiVisibility ? 'layer__select_gui--active' : 'layer__select_gui'"
      (click)="onClickGuiTag($event)"
    >
      <nb-icon
        [nbTooltip]="guiVisibility ? 'Hide a tag' : 'Show a tag'"
        class="layer__icon"
        icon="pricetags-outline"
      ></nb-icon>
    </div>
    <div
      *ngIf="checkDisplayWithCamera(layer)"
      class="layer__show"
      data-testId="set-camera-btn"
      (click)="onSetCameraTarget($event)"
    >
      <nb-icon nbTooltip="Move to layer" class="layer__icon" icon="video"></nb-icon>
    </div>
    <div
      *ngIf="isLayerUi(layer)"
      class="layer__details"
      data-testId="show-details-btn"
      (click)="onShowDetails($event)"
    >
      <nb-icon
        nbTooltip="Show details"
        class="layer__icon layer__invisible"
        icon="info-outline"
      ></nb-icon>
    </div>
  </ng-container>
  <div class="layer__select" (click)="onShowLayer($event)" data-testId="show-layer-btn">
    <nb-icon
      [nbTooltip]="guiVisibility ? 'Hide layer' : 'Show layer'"
      class="layer__icon"
      icon="eye"
    ></nb-icon>
  </div>
  <app-layer-path [path]="layerPath" (clickPath)="onClickLayerPath()"></app-layer-path>
</div>
