<ngx-datatable
  #table
  class="material fullscreen"
  [rowHeight]="rowHeight"
  [headerHeight]="50"
  [columnMode]="ColumnMode.flex"
  [footerHeight]="50"
  [columns]="columns"
  [rows]="rows"
  [count]="page.totalElements"
  [selectAllRowsOnPage]="selectAllRowsOnPage"
  [offset]="page.pageNumber - 1"
  [loadingIndicator]="loading"
  [selectionType]="SelectionType.checkbox"
  [limit]="page.size"
  [selected]="selected"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (page)="onSetPage($event)"
  [attr.rowClass]="getRowClass"
  [attr.sortType]="sortType"
  [sorts]="sorts"
  [scrollbarH]="true"
  data-testId="main-datatable"
>
  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
      let-isVisible="isVisible"
    >
      <div class="analytic_button" *ngIf="showStatisticsButton">
        <app-download-analytics-button
          data-testId="main-datatable-stat-btn"
        ></app-download-analytics-button>
      </div>
      <div class="pagination_footer">
        <div class="select_row">
          <span>Per page </span>
          <select (change)="onLimitChange($event)" data-testId="page-offset">
            <option *ngFor="let offset of defaultOffsets" [selected]="offset === page.size">
              {{ offset }}
            </option>
          </select>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
