import { AuthUser } from '@App/app/entities/users/user';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, map, switchMapTo } from 'rxjs/operators';
import { selectHasDocsAccepted, selectUser } from '../../store/auth/selectors/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class DoesNotHaveDocsAcceptedGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate() {
    return this.store.select(selectUser).pipe(
      filter<AuthUser>(Boolean),
      switchMapTo(this.store.select(selectHasDocsAccepted)),
      first(),
      // eslint-disable-next-line ngrx/avoid-mapping-selectors
      map((value) => !value),
    );
  }
}
