import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerClasses } from '@App/app/entities/layer/layer-classes.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { SelectChildEvent } from '@App/app/entities/layer/select-child-event.model';
import { SketchPlane } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { selectChildLayers } from '../../../store/selectors/layers.selectors';

@UntilDestroy()
@Component({
  selector: 'app-layer-expandable',
  templateUrl: './layer-expandable.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerExpandableComponent implements OnInit {
  @Input() layer: LayerUiExpandable;
  @Input() layerPath = '';
  @Input() dragDisabled = false;
  @Input() showExpandableArrow = true;
  @Input() classes: LayerClasses;
  @Output() clickLayerPath: EventEmitter<LayerUiExpandable> = new EventEmitter();
  @Output() selectLayer: EventEmitter<LayerUiExpandable> = new EventEmitter();
  @Output() clickLayer: EventEmitter<LayerUiExpandable> = new EventEmitter();
  @Output() editLayer: EventEmitter<LayerUiExpandable> = new EventEmitter();
  @Output() expandLayer: EventEmitter<LayerUiExpandable> = new EventEmitter();
  @Output() selectChild: EventEmitter<SelectChildEvent> = new EventEmitter();
  @Output() toggleLayerVisibility: EventEmitter<LayerUiExpandable> = new EventEmitter();
  layerTypes = { ...LAYER_TYPES };
  childLayers: LayerUI[] = [];

  planeIcon = '../../../assets/svg/icons/sf_portal-ui_icon_layers_plane.svg#Layer_1';

  constructor(private viewerLayerService: ViewerLayerService, private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectChildLayers(this.layer.id))
      .pipe(untilDestroyed(this))
      .subscribe((layers) => {
        this.childLayers = layers;
      });
  }

  asSketchPlane(layer: LayerUiExpandable): SketchPlane {
    return (layer as unknown) as SketchPlane;
  }

  onSelect(event: MouseEvent) {
    event.stopPropagation();
    this.toggleLayerVisibility.emit(this.layer);
  }

  onEditClick(event: MouseEvent) {
    event.stopPropagation();
    this.editLayer.emit(this.layer);
  }

  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.clickLayer.emit(this.layer);
  }

  onExpandLayer(event: MouseEvent) {
    event.stopPropagation();
    this.expandLayer.emit(this.layer);
  }

  onChildSelect(layer: LayerUI) {
    this.selectChild.emit({ layer, parent: this.layer });
  }

  onSetCameraTarget(event: MouseEvent) {
    event.stopPropagation();
    this.viewerLayerService.setCameraOnLayer(this.layer);
  }

  onClickLayerPath() {
    this.clickLayerPath.emit(this.layer as LayerUiExpandable);
  }
}
