import { Injectable } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  online$ = fromEvent(window, 'online');
  offline$ = fromEvent(window, 'offline');
  isInternetAvailable$ = merge(
    this.online$.pipe(map(() => true)),
    this.offline$.pipe(map(() => false)),
  ).pipe(startWith(true), shareReplay(1));

  constructor() {}
}
