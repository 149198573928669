import { AuthService } from '@App/app/auth/auth.service';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { Model } from '@App/app/entities/models/model.model';
import { AdvancedSearchModel } from '@App/app/entities/shared/advanced-search.model';
import { loadAllModelsWithThumbnails } from '@App/app/pages/models/store/models/actions/models.actions';
import {
  selectAllModels,
  selectModels,
} from '@App/app/pages/models/store/models/selectors/models.selectors';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import {
  filter,
  first,
  map,
  skip,
  switchMap,
  switchMapTo,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ModelsSitesService } from '../../../shared/site-services/models-sites.service';
import { ModelsTableFiltersService } from '../../models-table-filters/models-table-filters.service';

@UntilDestroy()
@Injectable()
export class ModelsContentService {
  private _allModels: Model[] = [];
  private _filteredModels$ = new BehaviorSubject<Model[]>([]);
  filteredModels$ = this._filteredModels$.asObservable();
  private _companyId$ = new BehaviorSubject<number | null>(null);
  companyId$ = this._companyId$.asObservable();
  private _showStatisticsButton$ = new BehaviorSubject(true);
  showStatisticsButton$ = this._showStatisticsButton$.asObservable();
  private _loading$ = new BehaviorSubject(true);
  loading$ = this._loading$.asObservable();

  constructor(
    private _authService: AuthService,
    private _sitesService: ModelsSitesService,
    private _modelsFilterService: ModelsTableFiltersService,
    private store: Store,
  ) {
    this.store
      .select(selectAllModels)
      .pipe(
        untilDestroyed(this),
        switchMapTo(this._sitesService.showArchived$),
        switchMap((showArchived) => this.store.select(selectModels(showArchived)).pipe(first())),
      )
      .subscribe((models) => {
        this._allModels = models;
        this._filteredModels$.next([...this._allModels]);
      });

    this._sitesService.showArchived$
      .pipe(
        untilDestroyed(this),
        switchMap((showArchived) =>
          this.store
            .select(selectModels(showArchived))
            .pipe(skip(1), take(1))
            .pipe(map((models) => ({ showArchived, models }))),
        ),
        withLatestFrom(this._modelsFilterService.$advancedSearch),
      )
      .subscribe(([{ showArchived, models }, search]) => {
        this._setModels(models, search);
        this._showStatisticsButton$.next(!showArchived);
        setTimeout(() => {
          this._loading$.next(false);
        }, 250);
      });

    this._modelsFilterService.$advancedSearch
      .pipe(untilDestroyed(this))
      .subscribe((search) => this._setModels(this._allModels, search));

    this._authService.currentEndpoint$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((endpoint: Endpoint) => this._onEndpointChange(endpoint));
  }

  private _setModels(models: Model[], search: AdvancedSearchModel) {
    this._allModels = models;
    const filteredModels = this._modelsFilterService.updateFilters(search, models);
    this._filteredModels$.next(filteredModels);
  }

  private _onEndpointChange(endpoint: Endpoint): void {
    this._companyId$.next(endpoint.id);
    this.store.dispatch(loadAllModelsWithThumbnails());
  }
}
