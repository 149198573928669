import { Hints } from '@App/app/configs/hints.config';
import { FAILED, STARTED_GENERATING_PHOTOS, SUCCESS } from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { ModelPhotosService } from '@App/app/pages/models/model-details/model-photos/services/model-photos-service/model-photos.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { PhotosHttpService } from '../../../photos-http.service';
import { createSelectedPhotosZip, createSelectedPhotosZipSuccess } from '../actions/photos.actions';

@Injectable()
export class ZipPhotosEffects {
  constructor(
    private actions: Actions,
    private photosHttpService: PhotosHttpService,
    private modelPhotosService: ModelPhotosService,
    private toastrService: NbToastrService,
  ) {}

  createSelectedPhotosZip = createEffect(() => {
    return this.actions.pipe(
      ofType(createSelectedPhotosZip),
      mergeMap(({ folderName, photos }) => {
        return this.photosHttpService.getPhotosZip(folderName, photos).pipe(
          map(() => createSelectedPhotosZipSuccess()),
          catchError((err) => {
            this.modelPhotosService.setDownloadMessage(null);
            this.toastrService.show(err.message, FAILED, {
              ...WARNING_TOASTR_CONFIG,
            });
            return EMPTY;
          }),
        );
      }),
    );
  });

  createSelectedPhotosZipSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(createSelectedPhotosZipSuccess),
        tap(() => {
          this.modelPhotosService.setDownloadMessage(Hints.DOWNLOAD_PHOTOS_ZIP);
          this.toastrService.show(STARTED_GENERATING_PHOTOS, SUCCESS, {
            ...SUCCESS_TOASTR_CONFIG,
          });
        }),
      );
    },
    { dispatch: false },
  );
}
