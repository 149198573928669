import { Injectable } from '@angular/core';
import { LayerHttpService } from '@App/app/engine/services/layer-services/layer-http-service/layer-http.service';
import { LayerUtilsService } from '@App/app/engine/services/layer-services/layer-utils-service/layer-utils.service';
import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { Automapping } from '@App/app/entities/files/automapping.model';
import { Cuboid } from '@App/app/entities/layer/cuboid.model';
import {
  AUTOMAPPING_IMPORTABLE_LAYER,
  LAYER_TYPES,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { Folder } from '@App/app/entities/layer/folder.model';
import { LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { Tube } from '@App/app/entities/layer/tube.model';
import { MODEL_MESH_NAME } from 'src/app/configs/babylon.config';

@Injectable({
  providedIn: 'root',
})
export class AutomappingUtilsService {
  private _meshCreators = {
    [LAYER_TYPES.CUBOID]: () => new Cuboid(),
    [LAYER_TYPES.TUBES]: () => new Tube(),
  };
  private _moveActions = {
    [LAYER_TYPES.CUBOID]: Cuboid.move,
    [LAYER_TYPES.TUBES]: Tube.move,
  };

  constructor(
    private _layerHttpService: LayerHttpService,
    private _layerUtilsService: LayerUtilsService,
    private _sceneService: SceneService,
  ) {}

  getVersion(pureVersion: string) {
    const version = Number(pureVersion).toFixed(1);
    return version ? `v${version}` : '';
  }

  createFolderData(name: string, parentId?: number) {
    const folder = new Folder();
    folder.name = name;
    if (parentId) {
      folder.parentId = parentId;
    }
    return this._layerUtilsService.createRequestBody(folder);
  }

  createRadCenters(levels: Automapping.RadCenter[], root: LayerUiExpandable) {
    const radCenterBodies = levels.map((level) => {
      const name = `Rad Center ${Number(level.name) + 1}`;
      return this.createFolderData(name, root.id);
    });
    return this._layerHttpService.postBatchLayers(radCenterBodies).toPromise() as Promise<
      LayerUiExpandable[]
    >;
  }

  createSectors(levels: Automapping.RadCenter[], radCenters: LayerUiExpandable[]) {
    const sectorBodies = levels
      .map((level, levelIndex) => {
        return level.sectors.map((sector) => {
          const name = `Sector ${sector.name}`;
          const radCenter = radCenters[levelIndex];
          return this.createFolderData(name, radCenter.id);
        });
      })
      .flat();
    return this._layerHttpService.postBatchLayers(sectorBodies).toPromise() as Promise<
      LayerUiExpandable[]
    >;
  }

  createMountGroups(levels: Automapping.RadCenter[], sectors: LayerUiExpandable[]) {
    let iter = -1;
    const mountGroupBodies = levels
      .map((level) => {
        return level.sectors.map((sector) => {
          iter++;
          const parentSector = sectors[iter];
          if ((sector as any).mount_groups) {
            sector.mountGroups = (sector as any).mount_groups;
          }
          return sector.mountGroups.map((mountGroup) => {
            const name = !isNaN((mountGroup.name as unknown) as number)
              ? `Mount Group ${mountGroup.name}`
              : mountGroup.name;
            return this.createFolderData(name, parentSector.id);
          });
        });
      })
      .flat(2);
    return this._layerHttpService.postBatchLayers(mountGroupBodies).toPromise() as Promise<
      LayerUiExpandable[]
    >;
  }

  getAllMeshes(
    levels: Automapping.RadCenter[],
    mountGroups: LayerUiExpandable[],
  ): AUTOMAPPING_IMPORTABLE_LAYER[] {
    let iter = -1;
    return levels
      .map((level) => {
        return level.sectors.map((sector) => {
          return sector.mountGroups.map((mountGroup) => {
            iter++;
            const parentMountGroup = mountGroups[iter];
            return mountGroup.meshes.map((mesh) => this._toMeshByType(mesh, parentMountGroup.id));
          });
        });
      })
      .flat(3);
  }

  moveLayersToZeroPoint(layers: AUTOMAPPING_IMPORTABLE_LAYER[]) {
    const shiftValue = this._getShiftValue();
    const { _moveActions: moveActions } = this;
    for (const layer of layers) {
      moveActions[layer.type as keyof typeof moveActions](layer, shiftValue);
    }
  }

  private _toMeshByType(row: Automapping.MeshRow, parentId: number) {
    const { _meshCreators: meshCreators } = this;
    const mesh = meshCreators[row.type as keyof typeof meshCreators]();
    mesh.name = row.name;
    mesh.data = row.data;
    mesh.parentId = parentId;
    mesh.isVisible = false;
    mesh.isSaved = false;
    return mesh;
  }

  private _getShiftValue(): [number, number] {
    const [minX, minZ, maxX, maxZ] = this._getPlaneRange();
    const center = [maxX - (maxX - minX) / 2, maxZ - (maxZ - minZ) / 2];
    return [(maxX - minX) / 2 + center[0], (maxZ - minZ) / 2 + center[1]];
  }

  private _getPlaneRange(): [number, number, number, number] {
    let [xValues, zValues]: [number[], number[]] = [[], []];
    const { meshes } = this._sceneService.scene;
    const modelMeshes = meshes.filter((mesh) => mesh.name.startsWith(MODEL_MESH_NAME));
    for (const modelMesh of modelMeshes) {
      const info = modelMesh.getBoundingInfo();
      const box = info.boundingBox;
      xValues = [...xValues, box.minimumWorld.x, box.maximumWorld.x];
      zValues = [...zValues, box.minimumWorld.z, box.maximumWorld.z];
    }
    const [minX, minZ, maxX, maxZ] = [
      Math.min(...xValues),
      Math.min(...zValues),
      Math.max(...xValues),
      Math.max(...zValues),
    ];
    return [minX, minZ, maxX, maxZ];
  }
}
