import { IPresetModel } from '@App/app/entities/processing/processing-preset-settings.model';
import { createAction, props } from '@ngrx/store';

export const loadPresets = createAction('[Presets] Load Presets');

export const loadPresetsSuccess = createAction(
  '[Presets] Load Presets Success',
  props<{ presets: IPresetModel[] }>(),
);

export const addPreset = createAction('[Preset] Add Preset', props<{ preset: IPresetModel }>());

export const addPresetSuccess = createAction(
  '[Preset] Add Preset Success',
  props<{ preset: IPresetModel }>(),
);

export const addPresetFailure = createAction('[Presest] Add Preset Failure');

export const editPreset = createAction(
  '[Preset] Edit Preset',
  props<{ id: number; preset: IPresetModel }>(),
);

export const editPresetSuccess = createAction(
  '[Preset] Edit Preset Success',
  props<{ id: number; preset: IPresetModel }>(),
);

export const editPresetFailure = createAction('[Preset] Edit Preset Failure');

export const deletePreset = createAction('[Preset] Delete Preset', props<{ id: number }>());

export const deletePresetSuccess = createAction(
  '[Preset] Delete Preset Success',
  props<{ id: number }>(),
);

export const deletePresetFailure = createAction('[Preset] Delete Preset Failure');
