import { SCENE_CONFIG } from '@App/app/configs/babylon.config';
import * as utils from '@App/app/engine/utils/engine-utils/engine.utils';
import { SAVE_SKETCH_TYPE } from '@App/app/entities/layer/sketch-tools/save-sketch-types.enum';
import { ISketchPlaneBroadcast } from '@App/app/entities/layer/sketch-tools/sketch-plane-broadcast-info.model';
import { ModelType } from '@App/app/entities/processing/build-process-status.model';
import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { RendersService } from '@App/app/pages/models/services/renders-service/renders.service';
import { TiePointsService } from '@App/app/pages/viewer/services/tie-points/tie-points.service';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { changeElementStyle } from '@App/app/shared/utils/html.utils';
import { Injectable } from '@angular/core';
import { PointerEventTypes } from 'babylonjs';
import { BehaviorSubject, EMPTY, Observable, Subject, from, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { CamerasService } from '../../camera-services/cameras-service/cameras.service';
import { CustomLODService } from '../../custom-lod-service/custom-lod.service';
import { BasePlaneService } from '../../layer-services/base-plane-services/base-plane.service';
import { LayerService } from '../../layer-services/layer-service/layer.service';
import { CuboidPickerService } from '../../layer-services/rectangle-tools-services/cuboid-services/cuboid-picker.service';
import { LoadModelService } from '../../load-model-service/load-model.service';
import { SceneService } from '../../scene-service/scene.service';
import { ViewerPhotoService } from '../../viewer-photo-service/viewer-photo.service';
import { BaseEngineService } from '../base-engine-service/base-engine.service';
import { EngineUtilsService } from '../engine-utils-service/engine-utils.service';
import { CustomerEngineSketchUtilsService } from './services/customer-engine-sketch-utils/customer-engine-sketch-utils.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerEngineService extends BaseEngineService {
  private _isEditMode = SCENE_CONFIG.editMode;
  private _currentRender: ExtendedRender | null = null;
  private _clickedTagLayerId$ = new Subject<number>();
  clickedTagLayerId$ = this._clickedTagLayerId$.asObservable();
  private _target$ = new BehaviorSubject<number | null>(null);
  target$ = this._target$.asObservable();

  constructor(
    private _basePlaneService: BasePlaneService,
    private _cuboidPickerService: CuboidPickerService,
    private _layerService: LayerService,
    private _tiePointsService: TiePointsService,
    private _rendersService: RendersService,
    private _engineSketchUtils: CustomerEngineSketchUtilsService,
    private _viewerPhotoService: ViewerPhotoService,
    broadcastService: BroadcastService,
    camService: CamerasService,
    loadModelService: LoadModelService,
    sceneService: SceneService,
    utilsService: EngineUtilsService,
    lodService: CustomLODService,
  ) {
    super(broadcastService, camService, loadModelService, sceneService, utilsService, lodService);
  }

  isReadyToLoadModel = () => !!this.getEntity();

  getEntity(): ExtendedRender | null {
    return this._currentRender;
  }

  protected initSubscribers() {
    return [
      this.broadcastService.on(EVENT_TYPE.TAKE_BASE_PLANE).subscribe(() => {
        this._basePlaneService.createMesh();
      }),
      this.broadcastService.on(EVENT_TYPE.INIT_CUBOID_PICKER).subscribe(() => {
        if (this._cuboidPickerService.isPickerActive()) {
          this._cuboidPickerService.initCuboidByUser();
        }
      }),
      this.broadcastService
        .on(EVENT_TYPE.EDIT_SKETCHES)
        .subscribe((resp: ISketchPlaneBroadcast) => {
          if (resp.isAddPlaneActive && resp.sketchPlane) {
            const { id } = resp.sketchPlane;
            const [cam, tmp] = [this.camera, this.tmpCameraPosition];
            const [cnv, eng] = [this.canvas, this.engine];
            this._engineSketchUtils.targetCameraToSketchPlane(id, cam, tmp, cnv, eng);
          } else {
            this._engineSketchUtils.finishSketchTool(this.camera, this.tmpCameraPosition);
          }
          this._engineSketchUtils.onPlaneEdit(resp);
        }),
      this.broadcastService.on(SAVE_SKETCH_TYPE.ALL).subscribe(() => {
        this._engineSketchUtils.finishSketchTool(this.camera, this.tmpCameraPosition);
      }),
      this._rendersService.currentRender$.subscribe((render) => (this._currentRender = render)),
    ];
  }

  postInit() {
    this.queryParams$.pipe(filter((params) => !isNaN(params.target))).subscribe(({ target }) => {
      this._target$.next(+target);
    });
  }

  isEditMode() {
    return this._isEditMode;
  }

  importModel(_?: ModelType): Observable<void> {
    return of(null).pipe(
      switchMap(() => {
        const render = this.getEntity();
        this.sceneService.setEditMode(this._isEditMode);
        if (!this.camera) return EMPTY;
        const tilesList = utils.getTilesListFromRender(render);
        const simplifiedTileLevels = utils.getSimplifiedTilesLevelsListFromRender(render);
        const tiles = utils.getTilesToLoad(tilesList, simplifiedTileLevels);
        const tilesRange = this.utilsService.getTilesRangeByDev(this.tilesRange);
        return from(this.loadModelService.loadProdModel(tiles, this.camera, true, tilesRange)).pipe(
          tap(() => {
            this.registerLODIfNeeded(tilesList, simplifiedTileLevels);
            this._layerService.initLayers((render as ExtendedRender).id);
            this._tiePointsService.downloadTiePoints();
          }),
        );
      }),
    );
  }

  initEngineSubscriptions(canvas: HTMLCanvasElement, _: number) {
    return [
      this._rendersService.currentRender$.subscribe((render) => {
        if (render && !this.sceneService.scene) {
          this._viewerPhotoService.setImageDimensionsFromRender(render);
          this._tiePointsService.tiePointsPath = render?.tiePoints?.downloadURL || null;
          this.startScene(canvas);
          this.modelTilesLength = render.tiles.length;
        }
      }),
    ];
  }

  protected destroy(): void {
    super.destroy();
    this._layerService.destroyLayers();
  }

  protected initSceneObservables(): void {
    this.sceneService.scene.onPointerObservable.add((pointerInfo) => {
      if (pointerInfo.type === PointerEventTypes.POINTERTAP) {
        this.addPointerTapObservable(pointerInfo);
      }
      if (pointerInfo.type === PointerEventTypes.POINTERMOVE) {
        this._cursorMoveUpdate$.next(true);
      }
    });
    this.sceneService.scene.__advancedTexture?.onControlPickedObservable.add((info) => {
      if (info.metadata?.layerId) {
        /*
          This Observable is necessary for correct working of onclick event of layers tags.
          There cannot be used onPointerClickObservable cause that approach blocks zooming on hover.
          Each of tags has own metadata filled by layerId property and they are getting and validated here
          for execute _clickedTagLayerId.
          */
        this._clickedTagLayerId$.next(info.metadata.layerId);
      }
    });
  }

  protected setDebugLayerStyles = () => {
    changeElementStyle('viewer__sidebar', 'left', '300px');
    changeElementStyle('viewer__sidebar_layers', 'left', '360px');
    changeElementStyle('viewer__sidebar_photos', 'left', '360px');
    changeElementStyle('viewer__sidebar_photos', 'width', 'calc(100% - 360px)');
  };
}
