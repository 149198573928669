<div class="filter__footer">
  <a class="filter__cancel" (click)="cancel()">Cancel</a>
  <div>
    <button
      data-testId="filter-clear"
      class="filter__clear"
      nbButton
      ghost
      status="info"
      type="button"
      (click)="clear()"
    >
      Clear
    </button>
    <button data-testId="filter-submit" class="filter__submit" nbButton status="info">
      Search
    </button>
  </div>
</div>
