import {
  FAILED,
  LAYERS_DELETE_FAIL,
  LAYERS_DELETE_SUCCESS,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '../../../../configs/toastr-messages.config';
import { LayerHttpService } from '../../../../engine/services/layer-services/layer-http-service/layer-http.service';
import { LayersViewerUtilsService } from '../../services/layers-viewer-utils-service/layers-viewer-utils.service';
import { LayersService } from '../../services/layers/layers.service';
import { deleteLayers, deleteLayersSuccess } from '../actions/layers.actions';

@Injectable()
export class DeleteLayersEffects {
  constructor(
    private actions: Actions,
    private layerHttpService: LayerHttpService,
    private viewerLayerService: ViewerLayerService,
    private toastrService: NbToastrService,
    private layersService: LayersService,
    private _layersViewerUtilsService: LayersViewerUtilsService,
  ) {}

  deleteLayers = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteLayers),
      mergeMap(({ ids, isForce = false }) => {
        return this.layerHttpService.deleteBatchLayers(ids, isForce).pipe(
          finalize(() => {
            this.layersService.setLoading(false);
          }),
          map(
            () => {
              return deleteLayersSuccess({
                ids,
              });
            },
            catchError(() => {
              this.toastrService.show(LAYERS_DELETE_FAIL, FAILED, {
                ...WARNING_TOASTR_CONFIG,
              });
              return EMPTY;
            }),
          ),
        );
      }),
    );
  });

  deleteLayersSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(deleteLayersSuccess),
        map(({ ids }) => {
          ids.forEach((id) => {
            this.viewerLayerService.deleteLayer(id);
          });
          this.layersService.setLoading(false);
          this._layersViewerUtilsService.deleteNestedFromViewer(ids);
          this.toastrService.show(LAYERS_DELETE_SUCCESS, SUCCESS, {
            ...SUCCESS_TOASTR_CONFIG,
          });
        }),
      );
    },
    { dispatch: false },
  );
}
