<app-manual-tool-layer-details
  *ngIf="activeLayerEvent === LAYER_EVENTS.ADD_ANGLE_IRON && isDetailsVisible"
  [samples]="samples"
  [partName]="ANGLE_IRON_PART_NAME"
  [sampleHighlighted]="highlightedSample"
  [generateAvailable]="isGenerateAvailable()"
  [activePart]="activePart"
  (setPartActive)="setActivePart($event)"
  (highlightSample)="toggleHighlightSample($event)"
  (deleteSample)="onDeleteSample($event)"
  (generate)="onGenerate()"
  (cancel)="onCancel()"
>
</app-manual-tool-layer-details>
