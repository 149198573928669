import { REPORT_BUG_CATEGORIES } from '@App/app/configs/report-bug.config';
import { REPORT_BUG_ERROR, REPORT_BUG_SENT } from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { ReportProblemForm } from '@App/app/entities/forms/report-problem-form.model';
import { ICategory, ISubcategory } from '@App/app/entities/models/report-problem.model';
import { environment } from '@App/environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxPermissionsService } from 'ngx-permissions';
import { ReportProblemHttpService } from '../report-problem.http/report-problem.http.service';
@UntilDestroy()
@Component({
  selector: 'app-report-problem-dialog',
  templateUrl: './report-problem-dialog.component.html',
  styleUrls: ['./report-problem-dialog.component.scss'],
})
export class ReportProblemDialogComponent implements OnInit {
  reportForm: FormGroup<ReportProblemForm>;
  categories = REPORT_BUG_CATEGORIES;
  selectedSubcategories: ISubcategory[];
  loading = false;

  constructor(
    private _dialogRef: NbDialogRef<ReportProblemDialogComponent>,
    private _fb: FormBuilder,
    private _reportProblemHttpService: ReportProblemHttpService,
    private _toastrService: NbToastrService,
    private _permissionService: NgxPermissionsService,
  ) {}

  ngOnInit() {
    this.reportForm = this._fb.group<ReportProblemForm>({
      category: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      subcategory: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      title: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      description: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  onCategoryChange(categoryValue: string) {
    const actualCategory = this.categories.find((category) => category.value === categoryValue);

    let availableSubcategories: ISubcategory[] = actualCategory?.subcategories || [];
    if (categoryValue === 'upload' && !this.hasSonyPermission()) {
      availableSubcategories = availableSubcategories.filter((e) => e.value !== 'uploadFromSony');
    }
    this.selectedSubcategories = availableSubcategories;
    (this.reportForm.get('subcategory') as FormControl).setValue('');
  }

  hasSonyPermission() {
    return (
      this._permissionService.getPermission(UserPermissions.CAN_UPLOAD_FROM_SONY) &&
      environment.sonyEnv.enabled
    );
  }

  closeReportForm() {
    this._dialogRef.close();
  }

  confirmReportForm() {
    this.loading = true;
    const reportForm = this.reportForm.value as Partial<ICategory>;
    this._reportProblemHttpService.postReportProblem(reportForm).subscribe(
      (resp) => {
        this.loading = false;
        this._toastrService.success(REPORT_BUG_SENT, 'Report', {
          ...SUCCESS_TOASTR_CONFIG,
        });
        this.closeReportForm();
      },
      () => {
        this.loading = false;
        this._toastrService.show(REPORT_BUG_ERROR, 'Report', {
          ...WARNING_TOASTR_CONFIG,
        });
      },
    );
  }
}
