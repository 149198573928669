import { ICategory } from '../entities/models/report-problem.model';

export const REPORT_BUG_CATEGORIES: ICategory[] = [
  {
    name: 'Upload',
    value: 'upload',
    subcategories: [
      {
        name: 'Upload from disk',
        value: 'uploadFromDisk',
      },
      {
        name: 'Upload from Sony',
        value: 'uploadFromSony',
      },
    ],
  },
  {
    name: 'Processing',
    value: 'processing',
    subcategories: [
      {
        name: 'Aerotriangulation',
        value: 'aeroTriangulation',
      },
      {
        name: 'Production',
        value: 'production',
      },
      {
        name: 'Post processing',
        value: 'postProcessing',
      },
    ],
  },
  {
    name: 'Models',
    value: 'models',
    subcategories: [
      {
        name: 'Photos',
        value: 'photos',
      },
      {
        name: '3D Viewer',
        value: '3dViewer',
      },
      {
        name: 'Automapping',
        value: 'automapping',
      },
    ],
  },
  {
    name: 'Users',
    value: 'users',
    subcategories: [
      {
        name: 'Adding users',
        value: 'addingUsers',
      },
      {
        name: 'Assigning roles',
        value: 'assigningRoles',
      },
      {
        name: 'Disabling users',
        value: 'disablingUsers',
      },
    ],
  },
];
