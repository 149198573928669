export enum UserPermissions {
  CAN_ADD_MODEL = 'canAddModel',
  CAN_EDIT_MODEL = 'canEditModel',
  CAN_ACCESS_MODEL_LIST = 'canAccessModelList',
  CAN_ACCESS_MODEL_OVERVIEW = 'canAccessModelOverview',
  CAN_ACCESS_PHOTOS = 'canAccessPhotos',
  CAN_ACCESS_VIEWER = 'canAccessViewer',
  CAN_ADD_LAYER = 'canAddLayer',
  CAN_EDIT_LAYER = 'canEditLayer',
  CAN_DELETE_LAYER = 'canDeleteLayer',
  CAN_EXPORT_FOLDER_TO_DXF = 'canExportFolderToDXF',
  CAN_UPLOAD_PHOTOS = 'canUploadPhotos',
  CAN_ACCESS_PROCESSING_LIST = 'canAccessProcessingList',
  CAN_ACCESS_PROCESSING_VIEWER = 'canAccessProcessingViewer',
  CAN_SUBSCRIBE_TO_NOTIFICATIONS = 'canSubscribeToNotifications',
  CAN_RETRY_AT = 'canRetryAT',
  CAN_ACCEPT_AT = 'canAcceptAT',
  CAN_RETRY_PRODUCTION = 'canRetryProduction',
  CAN_ACCEPT_PRODUCTION = 'canAcceptProduction',
  CAN_ACCESS_USER_LIST = 'canAccessUserList',
  CAN_ADD_USER = 'canAddUser',
  CAN_EDIT_USER = 'canEditUser',
  CAN_DISABLE_USER = 'canDisableUser',
  CAN_ASSIGN_ROLE = 'canAssignRole',
  CAN_ADD_ROLE = 'canAddRole',
  CAN_EDIT_ROLE = 'canEditRole',
  CAN_REMOVE_ROLE = 'canRemoveRole',
  CAN_ARCHIVE_PROCESSING = 'canArchiveProcessing',
  CAN_ARCHIVE_MODEL = 'canArchiveModel',
  CAN_ACCESS_ARCHIVED_MODELS = 'canAccessArchivedModels',
  CAN_ACCESS_ARCHIVED_PROCESSINGS = 'canAccessArchivedProcessings',
  CAN_DELETE_MODEL = 'canDeleteModel',
  CAN_DELETE_PROCESSING = 'canDeleteProcessing',
  CAN_DOWNLOAD_ANALYTICS = 'canDownloadAnalytics',
  CAN_EDIT_THUMBNAIL = 'canEditThumbnail',
  CAN_DELETE_THUMBNAIL = 'canDeleteThumbnail',
  CAN_CREATE_COLOR_PRESET = 'canCreateColorPreset',
  CAN_DELETE_COLOR_PRESET = 'canDeleteColorPreset',
  CAN_EXPORT_MAPPINGS_TO_CSV = 'canExportMappingsToCSV',
  CAN_IMPORT_AUTOMAPPING = 'canImportAutomapping',
  CAN_ACCESS_PHOTOS_IN_3D_VIEWER = 'canAccessPhotosIn3DViewer',
  CAN_UPLOAD_FROM_SONY = 'canUploadFromSony',
  CAN_FILTER_PHOTOS_BY_TARGET = 'canFilterPhotosByTarget',
  CAN_GENERATE_ANNOTATION_REPORT = 'canGenerateAnnotationReport',
  CAN_DOWNLOAD_PHOTO = 'canDownloadPhoto',
  CAN_DOWNLOAD_PHOTO_BUNDLE = 'canDownloadPhotoBundle',
  CAN_ANNOTATE_PHOTO = 'canAnnotatePhoto',
  CAN_CONFIGURE_GCPS = 'canConfigureGCPs',
  CAN_RUN_AUTOMAPPING = 'canRunAutomapping',
  CAN_UPLOAD_GCPS = 'canUploadGCPs',
  CAN_ACCESS_ADMIN_PANEL = 'canAccessAdminPanel',
}
