<div class="sidebar" data-testId="sidebar" *ngIf="activeStep$ | async as activeStep">
  <nav class="sidebar__nav">
    <div
      class="sidebar__nav_item"
      [ngClass]="{
        'sidebar__nav_item--active': activeStep === ProcessingSteps.AT_SETUP,
        'sidebar__nav_item--disabled': availableSteps < ProcessingSteps.AT_SETUP && !isATWaiting
      }"
      (click)="onNavClick(ProcessingSteps.AT_SETUP)"
    >
      AT Setup
    </div>
    <div
      class="sidebar__nav_item"
      [ngClass]="{
        'sidebar__nav_item--active': activeStep === ProcessingSteps.AT_REVIEW,
        'sidebar__nav_item--disabled': availableSteps < ProcessingSteps.AT_REVIEW && !atHasFailed()
      }"
      (click)="onNavClick(ProcessingSteps.AT_REVIEW)"
    >
      AT Review
    </div>
    <div
      class="sidebar__nav_item"
      [ngClass]="{
        'sidebar__nav_item--active': activeStep === ProcessingSteps.PROD_SETUP,
        'sidebar__nav_item--disabled': availableSteps < ProcessingSteps.PROD_SETUP
      }"
      (click)="onNavClick(ProcessingSteps.PROD_SETUP)"
    >
      Prod Setup
    </div>
    <div
      class="sidebar__nav_item"
      [ngClass]="{
        'sidebar__nav_item--active': activeStep === ProcessingSteps.PROD_REVIEW,
        'sidebar__nav_item--disabled': availableSteps < ProcessingSteps.PROD_REVIEW
      }"
      (click)="onNavClick(ProcessingSteps.PROD_REVIEW)"
    >
      Prod Review
    </div>
    <div
      class="sidebar__nav_item"
      [ngClass]="{
        'sidebar__nav_item--active': activeStep === ProcessingSteps.PP_SETUP,
        'sidebar__nav_item--disabled': availableSteps < ProcessingSteps.PP_SETUP
      }"
      (click)="onNavClick(ProcessingSteps.PP_SETUP)"
    >
      PP Setup
    </div>
  </nav>
  <div class="sidebar__body">
    <app-at-setup-tab
      data-testId="app-at-setup-tab"
      *ngIf="activeStep === ProcessingSteps.AT_SETUP"
      (startNext)="onStartAT($event)"
    ></app-at-setup-tab>
    <app-at-review-tab *ngIf="activeStep === ProcessingSteps.AT_REVIEW"></app-at-review-tab>
    <app-prod-setup-tab
      data-testId="app-prod-setup-tab"
      *ngIf="activeStep === ProcessingSteps.PROD_SETUP"
      (startNext)="onStartProd($event)"
    ></app-prod-setup-tab>
    <app-prod-review-tab
      data-testId="app-prod-review-tab"
      *ngIf="activeStep === ProcessingSteps.PROD_REVIEW"
    ></app-prod-review-tab>
    <app-pp-setup-tab
      data-testId="app-pp-setup-tab"
      *ngIf="activeStep === ProcessingSteps.PP_SETUP"
      (startNext)="onStartPP($event)"
    ></app-pp-setup-tab>
  </div>
</div>
