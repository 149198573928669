import { AuthService } from '@App/app/auth/auth.service';
import {
  AT_START_FAIL,
  AT_START_SUCCESS,
  FAILED,
  PP_START_FAIL,
  PP_START_SUCCESS,
  PROCESS_IS_ALREADY_RUNNING,
  PROD_START_FAIL,
  PROD_START_SUCCESS,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ModelsProcessingHttpService } from '@App/app/pages/processing/services/models-processing-http-service/models-processing-http.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NEVER, Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  startAerotriangulation,
  startPostProcessing,
  startProduction,
} from '../actions/processes.actions';

@Injectable()
export class EmitProcessEventEffects {
  constructor(
    private actions: Actions,
    private toastrService: NbToastrService,
    private modelsProcessingHttpService: ModelsProcessingHttpService,
    private router: Router,
    private authService: AuthService,
  ) {}

  startAerotriangulation = createEffect(
    () => {
      return this.actions.pipe(
        ofType(startAerotriangulation),
        this._checkProcessIsAvailable(),
        withLatestFrom(this.authService.currentEndpoint$),
        mergeMap(([{ process }, endpoint]) => {
          const companyId = endpoint?.id;
          return this.modelsProcessingHttpService
            .putBuildProcess(process)
            .pipe(this._showMessageAndRedirect(AT_START_SUCCESS, AT_START_FAIL, companyId));
        }),
      );
    },
    { dispatch: false },
  );

  startProduction = createEffect(
    () => {
      return this.actions.pipe(
        ofType(startProduction),
        this._checkProcessIsAvailable(),
        withLatestFrom(this.authService.currentEndpoint$),
        mergeMap(([{ process }, endpoint]) => {
          const companyId = endpoint?.id;
          return this.modelsProcessingHttpService
            .putBuildProcess(process)
            .pipe(this._showMessageAndRedirect(PROD_START_SUCCESS, PROD_START_FAIL, companyId));
        }),
      );
    },
    { dispatch: false },
  );

  startPostProcessing = createEffect(
    () => {
      return this.actions.pipe(
        ofType(startPostProcessing),
        this._checkProcessIsAvailable(),
        withLatestFrom(this.authService.currentEndpoint$),
        mergeMap(([{ process }, endpoint]) => {
          const companyId = endpoint?.id;
          return this.modelsProcessingHttpService
            .putBuildProcess(process)
            .pipe(this._showMessageAndRedirect(PP_START_SUCCESS, PP_START_FAIL, companyId));
        }),
      );
    },
    { dispatch: false },
  );

  private _checkProcessIsAvailable() {
    return (source: Observable<{ process: BuildProcess }>) => {
      return source.pipe(
        switchMap(({ process }) => {
          return this.modelsProcessingHttpService.getBuildProcess(process.id).pipe(
            switchMap((resp: any) => {
              const innerProcess = new BuildProcess({ ...resp });
              if (innerProcess.isStarted()) {
                this.toastrService.show(PROCESS_IS_ALREADY_RUNNING, FAILED, {
                  ...WARNING_TOASTR_CONFIG,
                });
                return NEVER;
              }

              return of({ process });
            }),
            catchError(() => NEVER),
          );
        }),
      );
    };
  }

  private _showMessageAndRedirect(
    successMessage: string,
    errorMessage: string,
    companyId?: number,
  ) {
    return (source: Observable<any>) => {
      return source.pipe(
        tap(
          () => {
            this.toastrService.show(successMessage, SUCCESS, {
              ...SUCCESS_TOASTR_CONFIG,
            });
            this.router.navigate([`/${companyId}/processing`]);
          },
          () => {
            this.toastrService.show(errorMessage, FAILED, {
              ...WARNING_TOASTR_CONFIG,
            });
            this.router.navigate([`/${companyId}/processing`]);
          },
        ),
      );
    };
  }
}
