import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { AuthBaseComponent } from '../auth-base-component';

import { AuthService } from '../auth.service';
import { EndpointsService } from '../endpoints.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends AuthBaseComponent implements OnInit {
  error: string;
  permissionError: string;
  loading = false;
  formValueSubscription: Subscription;
  loginForm: FormGroup<{
    email: FormControl<string | null>;
    password: FormControl<string | null>;
  }>;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private endpointsService: EndpointsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: this.fb.control('', { validators: [Validators.required] }),
      password: this.fb.control('', { validators: [Validators.required] }),
    });

    this.onChanges(this.loginForm);
  }

  onSubmit(form: FormGroup) {
    this.loading = true;
    this.authService
      .login({
        email: form.value.email,
        password: form.value.password,
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          if (!this.endpointsService.filterAvailableEndpoints(response.endpoint).length) {
            this.loading = false;
            this.error = 'You have no permission to access the app';
          } else {
            this.authService.onLoginSuccess(response);
            this.loading = false;
          }
        },
        (error) => {
          this.error = error.error.message;
          this.loading = false;
        },
      );
  }
}
