/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, pluck, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthData } from '../entities/auth/auth-data.model';
import { AuthResponse } from '../entities/auth/auth-response.model';
import { Endpoint, UserRole } from '../entities/auth/endpoint.model';
import { LoginData } from '../entities/auth/login-data.model';
import { UserPermissions } from '../entities/auth/permissions.enum';
import { ResetPassword } from '../entities/auth/reset-password.model';
import { AuthTokens } from '../entities/auth/tokens.model';
import { AuthUser } from '../entities/users/user';
import { loadAllPresets } from '../pages/viewer/store/color-presets-store/actions/ui-settings.actions';
import { AuthHttpService } from './auth-http.service';
import { EndpointsService } from './endpoints.service';
import {
  changeCurrentEndpointSuccess,
  loadAuthDataSuccess,
  refreshTokensSuccess,
  removeAuthDataSuccess,
} from './store/auth/actions/auth.actions';
import {
  selectAuthData,
  selectCurrentEndpoint,
  selectTokens,
  selectUser,
} from './store/auth/selectors/auth.selectors';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: AuthUser | null;
  private endpoints: Endpoint[];
  private tokens: AuthTokens | null;
  private loginUrl = `${environment.loginUrl}/auth/login`;
  private resetPasswordUrl = `${environment.loginUrl}/auth/resetPassword`;
  private confirmResetPasswordUrl = `${environment.loginUrl}/auth/confirmResetPassword`;
  private _currentEndpoint$ = new BehaviorSubject<Endpoint | null>(null);
  currentEndpoint$ = this._currentEndpoint$.asObservable();
  authChange = new BehaviorSubject<boolean>(false);
  endpointPath = new BehaviorSubject<string | null>(null);
  originalUrl: string | null = null;

  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private store: Store,
    private endpointsService: EndpointsService,
    private authHttpService: AuthHttpService,
  ) {
    this.store
      .select(selectTokens)
      .pipe(untilDestroyed(this))
      .subscribe((tokens) => {
        this.tokens = tokens;
      });
    this.store
      .select(selectCurrentEndpoint)
      .pipe(untilDestroyed(this))
      .subscribe((endpoint) => {
        this.endpointPath.next(endpoint?.endpoint.slice(0, -1) || null);
        this._currentEndpoint$.next(endpoint);
      });
    this.store
      .select(selectUser)
      .pipe(withLatestFrom(this.store.select(selectAuthData)))
      .subscribe(([_, data]) => {
        if (this.isAuthDataStored(data)) {
          [this.user, this.endpoints, this.tokens] = [data.user, data.endpoints, data.tokens];
          this.setCurrentEndpoint(data.currentEndpoint);
          this.authChange.next(true);
          this.store.dispatch(loadAllPresets());
        } else {
          this.user = null;
        }
      });
    this._currentEndpoint$.pipe(pluck('userRoles')).subscribe((roles: UserRole[]) => {
      this.permissionsService.flushPermissions();
      if (roles) {
        this.permissionsService.loadPermissions(
          roles.flatMap((role) => role.permissions.map((permission) => permission.name)),
        );
      }
    });
  }

  login(loginData: LoginData): Observable<AuthResponse> {
    return this.authHttpService.login(this.loginUrl, loginData);
  }

  // eslint-disable-next-line complexity
  onLoginSuccess(response: AuthResponse) {
    const { user, auth, endpoint } = response;
    this.endpoints = [...this.endpointsService.filterAvailableEndpoints(endpoint)];
    const currentEndpoint = this.endpoints[0];
    [this.user, this.tokens] = [user, auth];
    this.authChange.next(true);
    this.store.dispatch(
      loadAuthDataSuccess({
        authData: {
          user,
          endpoints: endpoint,
          currentEndpoint,
          tokens: auth,
        },
      }),
    );

    const prevUrl = this.router.parseUrl(this.originalUrl || '');
    const prevQueryParams = prevUrl.queryParams;

    this.router.navigate(
      [
        this.hasAccessToAdminPanel()
          ? '/company-selection'
          : this.originalUrl?.split('?')[0] ||
            (this.endpoints.length > 1 || !this._currentEndpoint$.value
              ? '/company-selection'
              : `${this._currentEndpoint$.value.id}/models`),
      ],
      {
        queryParams: {
          ...prevQueryParams,
          freshLogin: 'true',
        },
      },
    );
    this.originalUrl = null;
  }

  hasAccessToAdminPanel() {
    return this.permissionsService.getPermission(UserPermissions.CAN_ACCESS_ADMIN_PANEL);
  }

  refreshTokens() {
    return this.authHttpService
      .refreshTokens(this.endpointPath.value || '', this.tokens?.refreshToken as string)
      .pipe(
        tap((tokens) => {
          this.store.dispatch(refreshTokensSuccess({ tokens }));
        }),
        catchError((error) => {
          this.onLogout();
          return of(error);
        }),
      );
  }

  logout() {
    return this.authHttpService.logout(
      this.endpointPath.value as string,
      this.tokens?.accessToken as string,
    );
  }

  onLogout() {
    this.store.dispatch(removeAuthDataSuccess());
    this.store
      .select(selectAuthData)
      .pipe(first())
      .subscribe(() => {
        [this.user, this.tokens, this.endpoints] = [null, null, []];
        this._currentEndpoint$.next(null);
        this.authChange.next(false);
        this.endpointPath.next(null);
        this.router.navigate(['/login']);
      });
  }

  resetPassword(email: string) {
    return this.authHttpService.resetPassword(this.resetPasswordUrl, email);
  }

  changePassword(old: string, _new: string) {
    const [endpointPath, accessToken] = [this.endpointPath.value, this.tokens?.accessToken];
    return this.authHttpService.changePassword(
      endpointPath as string,
      accessToken as string,
      old,
      _new,
    );
  }

  confirmResetPassword(data: ResetPassword) {
    return this.authHttpService.confirmResetPassword(this.confirmResetPasswordUrl, data);
  }

  getUser() {
    return { ...this.user };
  }

  isAuth() {
    return !!this.user;
  }

  getUserRoles() {
    return this.getCurrentEndpoint()?.userRoles;
  }

  getCurrentEndpoint() {
    return this._currentEndpoint$.value;
  }

  setCurrentEndpoint(endpoint: Endpoint) {
    this.store.dispatch(changeCurrentEndpointSuccess({ endpoint }));
  }

  getAvailableEndpoints() {
    return this.endpointsService.filterAvailableEndpoints(this.endpoints);
  }

  isUserInOtherCompanies() {
    return this.endpoints.length > 1;
  }

  private isAuthDataStored(data: AuthData) {
    const areTokensStored = this.areTokensDataStored(data.tokens);
    return data.user && data.endpoints && data.currentEndpoint && areTokensStored;
  }

  private areTokensDataStored(tokens: AuthTokens) {
    return tokens.accessToken && tokens.idToken && tokens.refreshToken;
  }
}
