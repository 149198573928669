import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PresetsHttpService } from '../../services/presets-http/presets-http.service';
import { loadPresets, loadPresetsSuccess } from '../actions/presets.actions';

@Injectable()
export class LoadPresetsEffects {
  constructor(private actions: Actions, private presetsHttpService: PresetsHttpService) {}

  loadPresets = createEffect(() => {
    return this.actions.pipe(
      ofType(loadPresets),
      mergeMap(() => {
        return this.presetsHttpService.getPresets().pipe(
          map((preset) =>
            loadPresetsSuccess({
              presets: preset,
            }),
          ),
          catchError((error) => of(error)),
        );
      }),
    );
  });
}
