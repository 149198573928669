import { Injectable } from '@angular/core';
import {
  DELETE_MODEL_THUMBNAIL_FAIL,
  DELETE_MODEL_THUMBNAIL_SUCCESS,
  SET_MODEL_THUMBNAIL_FAIL,
  SET_MODEL_THUMBNAIL_SUCCESS,
  SUCCESS,
  WARNING,
} from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG as SC,
  WARNING_TOASTR_CONFIG as WC,
} from '@App/app/configs/toastr-messages.config';
import { FileTypes } from '@App/app/entities/files/files-data.model';
import { Thumbnail } from '@App/app/entities/files/files.model';
import {
  createModelFiles,
  createModelFilesFail,
  createModelFilesSuccess,
  deleteModelFiles,
  deleteModelFilesFail,
  deleteModelFilesSuccess,
  loadModelFilesSuccess,
} from '@App/app/pages/models/store/models/actions/model-files.actions';
import {
  loadCurrentModel,
  removeCurrentModelLocal,
} from '@App/app/pages/models/store/models/actions/models.actions';
import { selectSingleThumbnail } from '@App/app/pages/models/store/models/selectors/model-files.selectors';
import { NbToastrService } from '@nebular/theme';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, merge } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThumbnailService {
  private thumbnail: Thumbnail | null = null;
  private _isLoaded$ = new BehaviorSubject(false);
  isLoaded$ = this._isLoaded$.asObservable();

  constructor(
    private store: Store,
    private actions: Actions,
    private toastrService: NbToastrService,
  ) {
    this.store.select(selectSingleThumbnail).subscribe((thumbnail) => {
      this.thumbnail = thumbnail;
    });

    this.actions.pipe(ofType(loadCurrentModel)).subscribe(() => {
      this._isLoaded$.next(false);
    });

    this.actions.pipe(ofType(removeCurrentModelLocal)).subscribe(() => {
      this._isLoaded$.next(false);
    });

    this.actions.pipe(ofType(loadModelFilesSuccess)).subscribe(() => {
      this._isLoaded$.next(true);
    });
  }

  async change(file: File) {
    this._isLoaded$.next(false);
    this.deleteCurrentIfExists();

    this.store
      .select(selectSingleThumbnail)
      .pipe(filter((t) => !t))
      .pipe(take(1))
      .subscribe(() => this.createNewThumb(file));
  }

  delete() {
    merge(
      this.actions.pipe(
        ofType(deleteModelFilesSuccess),
        tap(() => this.toastrService.show(DELETE_MODEL_THUMBNAIL_SUCCESS, SUCCESS, SC)),
      ),
      this.actions.pipe(
        ofType(deleteModelFilesFail),
        tap(() => this.toastrService.show(DELETE_MODEL_THUMBNAIL_FAIL, WARNING, WC)),
      ),
    )
      .pipe(take(1))
      .subscribe(() => this._isLoaded$.next(true));

    this._isLoaded$.next(false);
    this.deleteCurrentIfExists();
  }

  private deleteCurrentIfExists() {
    if (this.thumbnail?.id) {
      this.store.dispatch(deleteModelFiles({ ids: [this.thumbnail.id] }));
    }
  }

  private createNewThumb(file: File) {
    merge(
      this.actions.pipe(
        ofType(createModelFilesSuccess),
        tap(() => this.toastrService.show(SET_MODEL_THUMBNAIL_SUCCESS, SUCCESS, SC)),
      ),
      this.actions.pipe(
        ofType(createModelFilesFail),
        tap(() => this.toastrService.show(SET_MODEL_THUMBNAIL_FAIL, WARNING, WC)),
      ),
    )
      .pipe(take(1))
      .subscribe(() => this._isLoaded$.next(true));

    this.store.dispatch(
      createModelFiles({
        objects: [
          {
            type: FileTypes.THUMBNAIL,
            name: file.name,
            data: {},
          },
        ],
        files: [file],
      }),
    );
  }
}
