import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { Photo } from '@App/app/entities/files/files.model';
import { Injectable } from '@angular/core';
import { ActionManager, Color4, ExecuteCodeAction, InstancedMesh } from 'babylonjs';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PhotosUtilsService } from '../../../pages/viewer/services/photos-utils/photos-utils.service';
import { BabylonNodesService } from '../babylon-nodes-service/babylon-nodes.service';

@Injectable({
  providedIn: 'root',
})
export class ViewerCameraPointsService {
  photosPointMeshes: {
    [key: number]: InstancedMesh | null;
  } = {};
  photoMeshColor = Color4.FromHexString('#ffffff');
  photoMeshEdgeWidth = 1;
  cameraMeshColor = new BehaviorSubject<string>(this.photoMeshColor.toHexString());
  cameraMeshEdgesWidth = new BehaviorSubject<number>(this.photoMeshEdgeWidth);

  constructor(
    private babylonNodeService: BabylonNodesService,
    private sceneService: SceneService,
    private photosUtilsService: PhotosUtilsService,
  ) {
    this.cameraMeshColor.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.changeViewfinderColor(value);
    });
    this.cameraMeshEdgesWidth.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.changeViewfinderSize(value);
    });
  }

  createCameraPoint(photo: Photo, action: (photoId: number) => void) {
    if (photo.validate()) {
      const cameraPoint = this.babylonNodeService.createCameraMesh(
        photo.name,
        this.photosUtilsService.position(photo.data.position),
        this.photosUtilsService.quaternion(photo.data.rotation),
      );
      cameraPoint.edgesColor = this.photoMeshColor;
      cameraPoint.edgesWidth = this.photoMeshEdgeWidth;
      cameraPoint.actionManager = new ActionManager(this.sceneService.scene);
      cameraPoint.actionManager.registerAction(
        new ExecuteCodeAction(
          {
            trigger: ActionManager.OnPickTrigger,
            parameter: {},
          },
          () => {
            action(+photo.id);
          },
        ),
      );
      this.photosPointMeshes[Number(photo.id)] = cameraPoint;
    }
  }

  createCameraPoints(photos: Photo[], action: (photoId: number) => void) {
    if (this.sceneService.scene) {
      photos.forEach((photo) => {
        this.createCameraPoint(photo, action);
      });
    }
  }

  disposeOne(id: number) {
    this.photosPointMeshes[id]?.dispose();
    this.photosPointMeshes[id] = null;
  }

  disposeAll() {
    for (const key of Object.keys(this.photosPointMeshes)) {
      this.photosPointMeshes[Number(key)]?.dispose();
      this.photosPointMeshes[Number(key)] = null;
    }
  }

  changeViewfinderColor(color: string) {
    if (this.photosPointMeshes) {
      for (const key in this.photosPointMeshes) {
        if (this.photosPointMeshes.hasOwnProperty(key)) {
          const photoPoint = this.photosPointMeshes[key];
          if (photoPoint) {
            photoPoint.edgesColor = Color4.FromHexString(color);
            this.photoMeshColor = Color4.FromHexString(color);
          }
        }
      }
    }
  }

  changeViewfinderSize(rate: number) {
    if (this.photosPointMeshes) {
      for (const key in this.photosPointMeshes) {
        if (this.photosPointMeshes.hasOwnProperty(key)) {
          const photoPoint = this.photosPointMeshes[key];
          if (photoPoint) {
            photoPoint.edgesWidth = rate;
            this.photoMeshEdgeWidth = rate;
          }
        }
      }
    }
  }
}
