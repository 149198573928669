import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LayersService } from '../services/layers/layers.service';

@Component({
  selector: 'app-layers-back-button',
  templateUrl: './layers-back-button.component.html',
  styleUrls: ['./layers-back-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayersBackButtonComponent implements OnInit {
  @Output() backClick = new EventEmitter();

  constructor(private layersService: LayersService) {}

  ngOnInit(): void {}

  isEditModeOnInitMappingTool() {
    return this.layersService.isEditModeOnInitMappingTool;
  }

  onBackClick(): void {
    this.backClick.emit();
  }
}
