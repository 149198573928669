import { CuboidPartsArrays } from '@App/app/entities/layer/cuboid.model';
import {
  CUBOID_PART,
  CUBOID_PART_STRICT,
  LAYER_TYPES,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { Mesh, Vector3 } from 'babylonjs';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { SceneService } from '../../../scene-service/scene.service';
import { UtilsService } from '../../../utils-service/utils.service';
import { BasePlaneService } from '../../base-plane-services/base-plane.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';
import { RectangleToolsEdgeDetectService as EdgeDetectService } from '../shared/rectangle-tools-edge-detect.service';
import { RectangleToolsService } from '../shared/rectangle-tools-service';
import { RectangleToolsUtilsService } from '../shared/rectangle-tools-utls.service';
import { VolumeUtilsService } from '../shared/volume-utils.service';

@Injectable({
  providedIn: 'root',
})
export class CuboidService extends RectangleToolsService {
  activePart: CUBOID_PART = CUBOID_PART.NONE;
  highlightedSample: { part: CUBOID_PART; i: number } = {
    part: CUBOID_PART.NONE,
    i: -1,
  };
  samples: CuboidPartsArrays<Mesh> = { 1: [], 2: [], 3: [] };

  private backPlaneNormal: Vector3;
  private centerPosition: Vector3;
  private topPlaneNormal: Vector3;
  private frontBackLength: number;
  private leftRightLength: number;
  private topBottomLength: number;

  constructor(
    private basePlaneService: BasePlaneService,
    private rectangleToolsUtilsService: RectangleToolsUtilsService,
    private babylonNodesService: BabylonNodesService,
    private sceneService: SceneService,
    private store: Store,
    private toastrService: NbToastrService,
    private utilsService: UtilsService,
    private viewerLayerService: ViewerLayerService,
    private volumeUtilsService: VolumeUtilsService,
    private rectangleToolsEdgeDetectService: EdgeDetectService,
  ) {
    super(
      basePlaneService,
      rectangleToolsUtilsService,
      sceneService,
      store,
      utilsService,
      viewerLayerService,
      volumeUtilsService,
      babylonNodesService,
      rectangleToolsEdgeDetectService,
    );
  }

  generateCuboidBoundingBox() {
    try {
      [
        this.frontBackLength,
        this.leftRightLength,
        this.topBottomLength,
        this.topPlaneNormal,
        this.backPlaneNormal,
        this.centerPosition,
      ] = this.calculateSidesData(
        this.samples[CUBOID_PART.FRONT_BACK],
        this.samples[CUBOID_PART.LEFT_RIGHT],
        this.samples[CUBOID_PART.TOP_BOTTOM],
      );
      this.save(
        LAYER_TYPES.CUBOID,
        this.topPlaneNormal,
        this.backPlaneNormal,
        this.centerPosition,
        this.leftRightLength,
        this.frontBackLength,
        this.topBottomLength,
        this.samples[CUBOID_PART.FRONT_BACK],
      );
      this.clearSamples();
    } catch (err) {
      this.clearSamples();
      this.utilsService.catchToolCreationError(err);
    }
  }

  setActivePart(part: CUBOID_PART) {
    const changeActivePartGuard = () => {
      return (
        (part === CUBOID_PART.LEFT_RIGHT && this.samples[CUBOID_PART.TOP_BOTTOM].length) ||
        (part === CUBOID_PART.TOP_BOTTOM && this.samples[CUBOID_PART.LEFT_RIGHT].length)
      );
    };
    if (!changeActivePartGuard()) {
      this.activePart = this.activePart === part ? CUBOID_PART.NONE : part;
      this._dataChange$.next();
    }
  }

  highlightSample(part: CUBOID_PART_STRICT, i: number) {
    this.highlightSamplePoint(part, i);
    this._dataChange$.next();
  }

  deleteSample(part: CUBOID_PART_STRICT, i: number) {
    this.deleteSamplePoint(part, i);
    this._dataChange$.next();
  }

  clearSamples() {
    this.clearSamplesPoints();
    this._dataChange$.next();
  }
}
