import { createGui } from '@App/app/entities/layer/create-gui.model';
import { PolygonLines } from '@App/app/entities/layer/polygons/polygon-lines.model';
import { Polygon, ViewerPolygonLayer } from '@App/app/entities/layer/polygons/polygon.model';
import { Injectable } from '@angular/core';
import { AbstractMesh, Mesh, PolygonMeshBuilder, TransformNode, Vector2, Vector3 } from 'babylonjs';
import { TextBlock } from 'babylonjs-gui';
import * as MyEarcut from 'earcut';
import {
  CENTER_SPHERES_CONTAINER_NAME,
  CENTER_SPHERE_NAME,
  ELECTRIC_BLUE_COLOR,
  PICKER_SPHERES_CONTAINER_NAME,
  POLYGON_MESH_BUILDER_NAME,
  POLYGON_NAME,
  POLYGON_PICKER_SPHERE_NAME,
} from 'src/app/configs/babylon.config';
import { UnitsService } from 'src/app/pages/viewer/services/utils/units.service';
import { toImperialArea, toImperialLength } from 'src/app/shared/utils/units-converter';
import { Coordinates3 } from '../../../../entities/layer/measurements/coordinates';
import { NameTag } from '../../../../entities/layer/name-tag.model';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { GenerateGUIService } from '../../gui-services/generate-gui-service/generate-gui.service';
import { MaterialService } from '../../material-service/material.service';
import { SceneService } from '../../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export class PolygonUtilsService implements createGui {
  constructor(
    private generateGUIService: GenerateGUIService,
    private babylonNodesService: BabylonNodesService,
    private materialService: MaterialService,
    private sceneService: SceneService,
    private unitsService: UnitsService,
  ) {}

  drawPolygon(
    polygon: Polygon,
    data: { points: Coordinates3[]; squareArea: number },
  ): [ViewerPolygonLayer, TransformNode] {
    const newPolygon = polygon as ViewerPolygonLayer;
    const root = new AbstractMesh(`${POLYGON_NAME}${newPolygon.id}`, this.sceneService.scene);
    const centerSpheresContainer = new AbstractMesh(
      CENTER_SPHERES_CONTAINER_NAME,
      this.sceneService.scene,
    );
    const pickerSpheresContainer = new AbstractMesh(
      PICKER_SPHERES_CONTAINER_NAME,
      this.sceneService.scene,
    );
    root.id = `${newPolygon.id}`;

    root.addChild(centerSpheresContainer);
    root.addChild(pickerSpheresContainer);
    newPolygon.pointsMeshes = data.points.map((point) => {
      return this.createSphere(
        new Vector3(point.x, point.y, point.z),
        pickerSpheresContainer,
        false,
      );
    });

    newPolygon.pointsNameTag = newPolygon.pointsMeshes.map((mesh, index) => {
      return this.generateGUIService.createNameTag(`${++index}`, mesh, false);
    });

    this.initPolygon(newPolygon, root);

    if (newPolygon.gizmoManager) {
      newPolygon.gizmoManager.positionGizmoEnabled = false;
    }
    if (newPolygon.squareAreaTag) {
      this.toggleImperialGui(newPolygon.squareAreaTag, newPolygon.polygonLines, data.squareArea);
    }

    return [newPolygon, root];
  }

  createGui(layer: ViewerPolygonLayer): NameTag | undefined {
    return undefined;
  }

  createSphere(position: Vector3, root: TransformNode, visible: boolean) {
    const sphere = this.babylonNodesService.createSphere(
      position.subtract(root.position),
      `${POLYGON_PICKER_SPHERE_NAME}`,
      this.materialService.electricBlueMaterial,
      { diameter: 0.05, segments: 16 },
    );

    sphere.renderingGroupId = 1;
    sphere.parent = root;
    sphere.isVisible = visible;
    return sphere;
  }

  initPolygon(polygon: ViewerPolygonLayer, root: TransformNode) {
    polygon.mesh = this.createPolygonMesh(polygon.pointsMeshes, root);

    // polygon.data.squareArea = Polygon.calculateSquareArea(polygon);
    polygon.polygonLines = this.createPolygonLinesDescription(
      polygon.mesh,
      polygon.pointsMeshes,
      root,
      true,
    );
    if (polygon.data.squareArea) {
      polygon.squareAreaTag = this.createSquareAreaTag(polygon.mesh, polygon.data.squareArea, true);
    }
  }

  toggleImperialGui(squareAreaTag: TextBlock, polygonLines: PolygonLines, squareArea: number) {
    const isImperial = this.unitsService.getImperialUnitsActive();
    squareAreaTag.text = isImperial ? `${toImperialArea(squareArea)}` : `${squareArea}m²`;
    polygonLines.polygonsText.forEach((tag, i) => {
      tag.text = isImperial
        ? `${toImperialLength(polygonLines.polygonsLinesLength[i])}`
        : `${polygonLines.polygonsLinesLength[i]}m`;
    });
  }

  createSquareAreaTag(polygonMesh: AbstractMesh, squareArea: number, visible: boolean) {
    return this.generateGUIService.createSquareAreaTag(polygonMesh, squareArea, true);
  }

  createPolygonLinesDescription(
    polygonMesh: Mesh,
    pointsMeshes: Mesh[],
    root: TransformNode,
    visible: boolean,
  ): PolygonLines {
    const positionsArr = pointsMeshes.map(
      (sphere) => new Vector3(sphere.position.x, sphere.position.y, sphere.position.z),
    );

    const polygonsLinesLength = this.calculateLineLengths(positionsArr);
    const polygonsLinesCenters = this.calculateLineCenters(positionsArr, polygonMesh.position.y);

    const centerSpheresContainer = root
      .getChildren()
      .filter((mesh) => mesh.id === CENTER_SPHERES_CONTAINER_NAME);

    const polygonsSphereCenters = polygonsLinesCenters.map((center) => {
      const centerSphere = this.initCenterSphere(center);
      centerSphere.parent = centerSpheresContainer.length !== 0 ? centerSpheresContainer[0] : root;

      return centerSphere;
    });

    const polygonsText = polygonsSphereCenters.map((center, i) =>
      this.generateGUIService.createLengthTag(center, polygonsLinesLength[i], visible),
    );

    return {
      polygonsLinesLength,
      polygonsLinesCenters,
      polygonsSphereCenters,
      polygonsText,
    };
  }

  calculateLineLengths(positionsArr: Vector3[]) {
    return positionsArr.map((_, i, arr) => {
      if (arr.length - 1 === i) {
        return +Vector3.Distance(arr[i], arr[0]).toFixed(2);
      } else {
        return +Vector3.Distance(arr[i], arr[i + 1]).toFixed(2);
      }
    });
  }

  calculateLineCenters(positionsArr: Vector3[], yPosition: number): Vector3[] {
    return positionsArr.map((_, i, arr) => {
      const center =
        arr.length - 1 === i ? Vector3.Center(arr[i], arr[0]) : Vector3.Center(arr[i], arr[i + 1]);
      center.y = yPosition;

      return center;
    });
  }

  initCenterSphere(position: Vector3) {
    const centerSphere = this.babylonNodesService.createSphere(
      position,
      CENTER_SPHERE_NAME,
      this.materialService.electricBlueMaterial,
      { diameter: 0.05, segments: 1 },
    );

    centerSphere.visibility = 0;
    centerSphere.position = position;
    return centerSphere;
  }

  /**
   * Calculates and moves lines length text while dragging polygon's vertices
   */
  movePolygonLines(polygon: ViewerPolygonLayer) {
    const positionsArr = polygon.pointsMeshes.map(
      ({ position }) => new Vector3(position.x, position.y, position.z),
    );
    if (polygon.mesh) {
      const polygonsLinesCenters = this.calculateLineCenters(positionsArr, polygon.mesh.position.y);
      polygon.polygonLines.polygonsSphereCenters.map((sphere, i) => {
        sphere.position = polygonsLinesCenters[i];
      });
    }
  }

  movePolygon(polygon: ViewerPolygonLayer, root: TransformNode) {
    polygon.mesh?.dispose();
    delete polygon.mesh;
    polygon.mesh = this.createPolygonMesh(polygon.pointsMeshes, root);
    this.movePolygonLines(polygon);
    this.moveSquareAreaTag(polygon);
    if (polygon.squareAreaTag && polygon.data.squareArea) {
      this.toggleImperialGui(polygon.squareAreaTag, polygon.polygonLines, polygon.data.squareArea);
    }
    this.updateGizmoPickables(polygon);
    return polygon;
  }

  updateGizmoPickables(polygon: ViewerPolygonLayer) {
    if (polygon.gizmoManager) {
      polygon.gizmoManager.attachableMeshes = [];

      polygon.pointsMeshes.forEach((e) => {
        polygon.gizmoManager?.attachableMeshes?.push(e);
      });
    }
    if (polygon.mesh) polygon.gizmoManager?.attachableMeshes?.push(polygon.mesh);

    return polygon.gizmoManager;
  }

  moveSquareAreaTag(polygon: ViewerPolygonLayer) {
    polygon.data.squareArea = Polygon.calculateSquareArea(polygon.pointsMeshes);
    if (polygon.mesh) polygon.squareAreaTag?.linkWithMesh(polygon.mesh);
  }

  createPolygonMesh(pointsMeshes: Mesh[], root: TransformNode) {
    /**Changed y and z axes because model is rotated 90 degrees in x axis */
    const spheresPositionsArr = pointsMeshes.map((e) => {
      return new Vector2(e.position.x, e.position.z);
    });
    const polygonMeshBuilder = new PolygonMeshBuilder(
      POLYGON_MESH_BUILDER_NAME,
      spheresPositionsArr,
      this.sceneService.scene,
      MyEarcut,
    );
    const polygonMesh = polygonMeshBuilder.build();

    polygonMesh.overrideMaterialSideOrientation = Mesh.DOUBLESIDE;
    polygonMesh.position.y = pointsMeshes[0].position.y + 0.2;
    polygonMesh.renderingGroupId = 2;
    polygonMesh.visibility = 0.45;
    polygonMesh.material = this.materialService.electricBlueMaterial;
    polygonMesh.enableEdgesRendering();
    polygonMesh.edgesColor = BABYLON.Color4.FromHexString(ELECTRIC_BLUE_COLOR + 'FF');
    polygonMesh.parent = root;

    return polygonMesh;
  }

  recomputeRootSpace(polygon: ViewerPolygonLayer, root: TransformNode) {
    const mid = new BABYLON.Vector3(0, 0, 0);
    polygon.pointsMeshes.forEach((e) => {
      mid.x += e.position.x;
      mid.y += e.position.y;
      mid.z += e.position.z;
    });
    mid.x /= polygon.pointsMeshes.length;
    mid.y /= polygon.pointsMeshes.length;
    mid.z /= polygon.pointsMeshes.length;

    root.position.x += mid.x;
    root.position.y += mid.y;
    root.position.z += mid.z;

    polygon.pointsMeshes.forEach((e) => {
      e.position.x -= mid.x;
      e.position.y -= mid.y;
      e.position.z -= mid.z;
    });

    this.movePolygon(polygon, root);

    return { pointsMeshes: polygon.pointsMeshes, root };
  }
}
