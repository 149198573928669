import { GeoCoords } from '@App/app/entities/shared/geo-coords';

export class FileMetadataInfo {
  constructor(public name: string, public coords?: GeoCoords, public altitude?: number) {}
}

export class FileMetadataInfoStrict implements FileMetadataInfo {
  constructor(public name: string, public coords: GeoCoords, public altitude: number) {}
}

export enum ALTITUDE_REF {
  ABOVE_SEA_LEVEL,
  BELOW_SEA_LEVEL,
}
