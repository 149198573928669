<app-control-points-list
  (activePoint)="setActivePoint($event)"
  [controlPoints]="value"
></app-control-points-list>

<app-measurement-list
  *ngIf="selectedControlPointName | async"
  [measurements]="filteredMeasurements"
  (deleteMeasurement)="deleteMeasurement($event)"
  data-testId="cp-input-measurements-list"
></app-measurement-list>

<div class="gcp__error">
  <span *ngIf="!isControlPointsSettingValid()" class="gcp__error__text"
    >There are some control points with not enough measurements</span
  >
</div>
