import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { AuthBaseComponent } from '../auth-base-component';
import { AuthService } from '../auth.service';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordRecoveryComponent extends AuthBaseComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: TemplateRef<any>;

  error: string;
  formValueSubscription: Subscription;
  loading = false;
  passwordResetForm = this.fb.group({
    email: this.fb.control('', { validators: [Validators.required] }),
  });

  constructor(
    private authService: AuthService,
    private dialogService: NbDialogService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.onChanges(this.passwordResetForm);
  }

  onSubmit(form: FormGroup) {
    this.loading = true;
    this.authService
      .resetPassword(form.value.email)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.dialogService.open(this.dialog, { closeOnBackdropClick: false });
          this.loading = false;
        },
        (error) => {
          this.error = error.error.message;
          this.loading = false;
        },
      );
  }

  nextStep(ref: NbDialogRef<any>) {
    ref.close();
    this.router.navigate(['/password-recovery/confirm']);
  }
}
