/* eslint-disable max-lines */
import { AuthService } from '@App/app/auth/auth.service';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { ProcessingTableFilterForm } from '@App/app/entities/forms/processing-table-filter-form.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { AdvancedSearchProcessing } from '@App/app/entities/shared/advanced-search.model';
import { NgxTableEvent } from '@App/app/entities/shared/ngx-table-event.model';
import { loadAllProcesses } from '@App/app/pages/processing/store/processes/actions/processes.actions';
import {
  selectAllProcesses,
  selectProcessesByArchived,
} from '@App/app/pages/processing/store/processes/selectors/processes.selectors';
import { ProcessingTableFiltersService } from '@App/app/pages/sites/processing-site/processing-table-filters/processing-table-filters.service';
import { BaseTableComponent } from '@App/app/shared/components/base-table/base-table.component';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NbTooltipDirective, NbTrigger } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { SortType } from '@swimlane/ngx-datatable';
import { of, timer } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ProcessesSitesService } from '../../shared/site-services/processes-sites.service';
import getColumns from './helpers/processing-table-columns';
import { getBuildProcessUsers, sortByProgress } from './utils/models-processing-table.utils';

@UntilDestroy()
@Component({
  selector: 'app-processing-table',
  templateUrl: './processing-table.component.html',
  styleUrls: [
    './processing-table.component.scss',
    '../../../../shared/components/base-table/base-table.component.scss',
  ],
})
export class ProcessingTableComponent
  extends BaseTableComponent<
    BuildProcess,
    ProcessingTableFilterForm,
    ProcessesSitesService,
    AdvancedSearchProcessing,
    ProcessingTableFiltersService
  >
  implements OnInit, OnDestroy {
  @ViewChild(NbTooltipDirective) rowTooltip: NbTooltipDirective;
  @ViewChild('updatedTpl', { static: true }) updatedTpl: TemplateRef<any>;
  @ViewChild('progressTpl', { static: true }) progressTpl: TemplateRef<any>;
  @ViewChild('actionsTpl', { static: true }) actionsTpl: TemplateRef<any>;
  @ViewChild('selectTpl', { static: true }) selectTpl: TemplateRef<any>;
  @ViewChild('selectAllTpl', { static: true }) selectAllTpl: TemplateRef<any>;
  private tooltipTimer: ReturnType<typeof setTimeout>;
  private timer = timer(0, 60000);
  SortType = SortType;
  tooltipConfig = { x: 0, y: 0, text: '' };
  nbTriggerClick = NbTrigger.CLICK;

  constructor(
    sitesService: ProcessesSitesService,
    authService: AuthService,
    router: Router,
    store: Store,
    filtersService: ProcessingTableFiltersService,
    cdr: ChangeDetectorRef,
  ) {
    super(authService, router, store, cdr, sitesService, filtersService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.sitesService) {
      this.store
        .select(selectAllProcesses)
        .pipe(untilDestroyed(this), withLatestFrom(this.sitesService.showArchived$))
        .subscribe(([buildProcesses, archived]) =>
          this.setEntities(buildProcesses.filter((item) => item.isArchived === archived)),
        );
    }
    this.sitesService?.showArchived$.pipe(untilDestroyed(this)).subscribe((showArchived) => {
      this.store
        .select(selectProcessesByArchived(showArchived))
        .pipe(take(1))
        .subscribe((buildProcesses) => this.setEntities(buildProcesses));
    });

    this.timer
      .pipe(
        untilDestroyed(this),
        switchMap((val) => {
          this.loading = true;
          this.store.dispatch(loadAllProcesses());
          return of(val);
        }),
      )
      .subscribe();

    this.sitesService?.selectedEntities.pipe(untilDestroyed(this)).subscribe((processes) => {
      this.selectedRows = [...processes];
    });
  }

  ngOnDestroy(): void {
    if (this.sitesService) {
      this.sitesService.setSelectedEntities([]);
    }
  }

  onActivate(event: NgxTableEvent<BuildProcess>) {
    if (event.type === 'click' && !event.column.checkboxable) {
      if (!event.row.isAccessible()) {
        this.triggerRowTooltip(event, 'Process in progress, please wait');
      } else {
        event.cellElement?.blur();
        const endpoint = this.authService.getCurrentEndpoint();
        if (endpoint) {
          this.router.navigate([`/${endpoint.id}/processing`, event.row.id]);
        }
      }
      this.isProcessingPaused(event);
    }
  }

  isProcessingPaused(event: NgxTableEvent<BuildProcess>) {
    const endpoint = this.authService.getCurrentEndpoint();
    if (event.row.buildProcessStatusId === 2 && endpoint) {
      this.router.navigate([`/${endpoint.id}/uploader`, event.row.modelId]);
    }
  }

  onSelectRow(event: NgxTableEvent<BuildProcess>) {
    if (event.selected && this.sitesService) {
      this.selectedRows = event.selected;
      this.sitesService.setSelectedEntities(this.selectedRows);
    }
  }

  getRowClass = (row: BuildProcess) => {
    return { clickable: row.isAccessible() };
  };

  private setPageByRows() {
    if (this.table?.limit) {
      const page = {
        count: this.rows.length,
        pageSize: this.table.pageSize,
        limit: this.table.limit,
        offset: this.table.offset,
      };

      this.setPage(page);
    }
  }

  private triggerRowTooltip(event: NgxTableEvent<BuildProcess>, text: string) {
    clearTimeout(this.tooltipTimer);
    this.tooltipConfig = {
      x: event.event.pageX,
      y: event.event.pageY,
      text,
    };
    this.rowTooltip.show();
    this.tooltipTimer = setTimeout(() => {
      this.rowTooltip.hide();
    }, 1000);
  }

  private sortByNotifications = (_: void, __: void, a: BuildProcess, b: BuildProcess) => {
    const userId = this.authService.getUser().id;
    if (!userId) {
      return 0;
    }
    const [ownerA, subscribersA] = getBuildProcessUsers(a);
    const [ownerB, subscribersB] = getBuildProcessUsers(b);
    return (
      +(ownerA === userId) - +(ownerB === userId) ||
      subscribersA.includes(userId) - subscribersB.includes(userId)
    );
  };

  protected setEntities(buildProcesses: BuildProcess[]) {
    this.entities = [...buildProcesses];
    this.rows = [...buildProcesses];
    if (this.table) {
      this.filtersService?.$advancedSearch.pipe(take(1)).subscribe((advancedSearch) => {
        this.updateFilters(advancedSearch);
      });
      this.setPageByRows();
    }
  }

  protected updateFilters(advancedSearch: AdvancedSearchProcessing) {
    if (this.filtersService) {
      this.rows = this.filtersService.updateProcessingTableFilters(advancedSearch, this.entities);
    }
    this.setPageByRows();
    if (this.table) {
      this.table.offset = 0;
    }
    this.loading = false;
  }

  protected initColumns(): void {
    this.columns = getColumns(
      this.progressTpl,
      this.updatedTpl,
      this.actionsTpl,
      this.selectAllTpl,
      this.selectTpl,
      sortByProgress,
      this.sortByNotifications,
    );
  }

  protected onEndpointChange(_: Endpoint): void {
    this.store.dispatch(loadAllProcesses());
  }
}
