import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { LAYER_NAMES } from '@App/app/entities/layer/enums/layer-names.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayersService } from '../layers/layers.service';
import { ThicknessFormService } from '../thickness-utils/thickness-form.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveLayerToolService {
  private _activeTool$ = new BehaviorSubject<LAYER_NAMES>(LAYER_NAMES.NULL);
  activeTool$ = this._activeTool$.asObservable();

  constructor(
    private engineService: CustomerEngineService,
    private _layersService: LayersService,
    private _thicknessFormService: ThicknessFormService,
  ) {}

  setActiveTool(name?: LAYER_NAMES) {
    if (name) {
      if (this._activeTool$.value === name) {
        this._activeTool$.next(LAYER_NAMES.NULL);
      } else {
        this._activeTool$.next(name);
      }
    } else {
      this._activeTool$.next(LAYER_NAMES.NULL);
    }
    this.engineService.activeCursorMove(!!name, false);
  }

  activateEditMode(value: boolean, layer?: LayerUI) {
    if (!value) {
      this._layersService.isEditModeOnInitMappingTool = false;

      if (layer) {
        this._thicknessFormService.removeForm(layer);
      }
    }
  }
}
