import { GizmoManager, LinesMesh, Mesh } from 'babylonjs';
import { LAYER_TYPES } from '../enums/layer-types.enum';
import { Layer } from '../layer.model';
import { NameTag } from '../name-tag.model';
import { Coordinates3 } from './coordinates';

export class Measurement implements Layer<MeasurementData> {
  createdAt: string;
  editedAt: string;
  id: number;
  isVisible = false;
  isSaved = true;
  name: string = LAYER_TYPES.MEASUREMENTS;
  type = LAYER_TYPES.MEASUREMENTS;
  parentId: number;
  data: MeasurementData = {};
  description: string;
}

export interface MeasurementData {
  length?: number;
  meshesPoints?: Coordinates3[];
}

export interface ViewerMeasurementLayer extends Measurement {
  gizmoManager?: GizmoManager;
  gui?: NameTag;
  meshes?: Mesh[];
  linesMesh?: LinesMesh;
}
