import { ApiService } from '@App/app/shared/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpAddUser, HttpEditUser, UiSettings, User } from '../../entities/users/user';

@Injectable({
  providedIn: 'root',
})
export class UsersHttpService extends ApiService {
  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.api}/users`);
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(`${this.api}/users/${id}`);
  }

  postUser(body: HttpAddUser): Observable<User> {
    return this.http.post<User>(`${this.api}/users`, body);
  }

  putUser(body: HttpEditUser): Observable<User> {
    return this.http.put<User>(`${this.api}/users/${body.id}`, body);
  }

  disableUser(id: number): Observable<number> {
    return this.http.post<number>(`${this.api}/users/${id}/disable`, {});
  }

  enableUser(id: number): Observable<number> {
    return this.http.post<number>(`${this.api}/users/${id}/enable`, {});
  }

  putUserUiSettings(body: UiSettings): Observable<boolean> {
    return this.http.put<boolean>(`${this.api}/users/me/ui-settings`, body);
  }
}
