import { RectType } from '@App/app/engine/helpers/rectangle-tools-helpers';
import { UnitsService } from '@App/app/pages/viewer/services/utils/units.service';
import { ClipboardService } from '@App/app/shared/services/clipboard/clipboard.service';
import { Component } from '@angular/core';
import { LayerDetailsService } from '../../../services/layer-details-service/layer-details.service';
import { BaseLayerPropertiesComponent } from '../base-layer-properties/base-layer-properties.component';

@Component({
  template: '',
})
export abstract class RectLayerPropertiesComponent<
  T extends RectType
> extends BaseLayerPropertiesComponent<T> {
  constructor(
    private _clipboardService: ClipboardService,
    unitsService: UnitsService,
    layerDetailsService: LayerDetailsService,
  ) {
    super(unitsService, layerDetailsService);
  }

  copyDimensions() {
    this._clipboardService.copyRectLayerDimensions(this.layer, this.unitsSystem);
  }
}
