import { BasePlane } from '@App/app/entities/layer/base-plane.model';
import { createGui } from '@App/app/entities/layer/create-gui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractMesh, Color3, DirectionalLight, GizmoManager, Mesh, Vector3 } from 'babylonjs';
import { take } from 'rxjs/operators';
import { BASE_PLANE_NAME, DIRECTIONAL_LIGHT_NAME } from 'src/app/configs/babylon.config';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { selectBasePlane } from 'src/app/pages/viewer/store/selectors/layers.selectors';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { Coordinates3 } from '../../../../entities/layer/measurements/coordinates';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { GizmoManagerService } from '../../gizmo-manager/gizmo-manager.service';
import { GenerateGUIService } from '../../gui-services/generate-gui-service/generate-gui.service';
import { MaterialService } from '../../material-service/material.service';
import { SceneService } from '../../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export class BasePlaneService extends GizmoManagerService implements createGui {
  constructor(
    private generateGUIService: GenerateGUIService,
    private babylonNodesService: BabylonNodesService,
    private materialService: MaterialService,
    private store: Store,
    broadcastService: BroadcastService,
    sceneService: SceneService,
  ) {
    super(sceneService, broadcastService);
  }

  createMesh(basePlane?: BasePlane, visible?: boolean): [BasePlane, GizmoManager] | void {
    const basePlaneMesh = this.createBasePlaneMesh(basePlane);
    basePlaneMesh.material = this.materialService.redMaterial;
    basePlaneMesh.visibility = 0.8;
    let viewerBasePlane = new BasePlane();
    if (basePlane) {
      viewerBasePlane = basePlane;
      const positionVector3 = new Vector3(
        basePlane.data.position?.x,
        basePlane.data.position?.y,
        basePlane.data.position?.z,
      );
      basePlaneMesh.position = positionVector3;
    }

    if (!basePlane) {
      this.postBasePlaneInDatabase(viewerBasePlane, basePlaneMesh);
      basePlaneMesh.dispose();
      return;
    }

    viewerBasePlane.mesh = basePlaneMesh;
    this.createLightFromBottom([basePlaneMesh]);
    const gizmoManager = this.initGizmos();
    this.setBasePlaneEditMode(gizmoManager, false);
    this.setGizmoManager(basePlane.id, gizmoManager);
    BasePlane.setVisibility(viewerBasePlane, !!visible);
    viewerBasePlane.gui = this.createGui(viewerBasePlane, basePlaneMesh, false);
    return [viewerBasePlane, gizmoManager];
  }

  createGui(layer: BasePlane, basePlaneMesh?: Mesh, visible = true) {
    return this.generateGUIService.createNameTag(
      layer.name ? layer.name : 'Base Plane',
      basePlaneMesh ? basePlaneMesh : (layer.mesh as Mesh),
      visible,
      layer.id,
    );
  }

  postBasePlaneInDatabase(layer: BasePlane, basePlaneMesh: Mesh) {
    layer.data.diameter = this.getDiameterOfCylinder(basePlaneMesh);
    layer.data.position = {
      x: basePlaneMesh.position.x,
      y: basePlaneMesh.position.y,
      z: basePlaneMesh.position.z,
    };
    layer.name = 'Base Plane';
    layer.isVisible = true;
    layer.isSaved = true;
    this.store.dispatch(addLayer({ layer }));
  }

  createLightFromBottom(abstractMesh: AbstractMesh[]) {
    const lightFromBottom = new DirectionalLight(
      DIRECTIONAL_LIGHT_NAME,
      new Vector3(0, 1, 0),
      this.sceneService.scene,
    );
    lightFromBottom.specular = Color3.Black();
    lightFromBottom.includedOnlyMeshes = abstractMesh;
  }

  setBasePlaneEditMode(gizmoManager: GizmoManager, value: boolean) {
    gizmoManager.positionGizmoEnabled = value;
  }

  setBasePlaneVisibility(basePlane: BasePlane, value: boolean) {
    BasePlane.setVisibility(basePlane, value);
    this.broadcastService.broadcast(EVENT_TYPE.UPDATE_BASE_PLANE, [basePlane]);
  }

  // TODO: Put those methods in base plane utility service or make utility services for meshes
  getDiameterOfCylinder(mesh: Mesh) {
    const diameter = (
      (mesh.getBoundingInfo().boundingBox.maximum.z -
        mesh.getBoundingInfo().boundingBox.minimum.z) *
      mesh.scaling.z
    ).toFixed(2);
    return +diameter;
  }

  getBasePlanesPosition(): Vector3 {
    let position: Coordinates3 = { x: 0, y: 0, z: 0 };
    this.store
      .select(selectBasePlane())
      .pipe(take(1))
      .subscribe((layer) => {
        const { data } = layer[0] as BasePlane;
        if (data.position) {
          position = data.position;
        }
      });
    return new Vector3(position.x, position.y, position.z);
  }

  updateBasePlane(basePlane: BasePlane, basePlaneMesh: Mesh) {
    const { position } = basePlaneMesh;
    basePlane.data.diameter = this.getDiameterOfCylinder(basePlaneMesh);
    basePlane.data.position = { x: position.x, y: position.y, z: position.z };
  }

  private createBasePlaneMesh(basePlane?: BasePlane) {
    return this.babylonNodesService.createCylinder(BASE_PLANE_NAME, {
      diameterTop: basePlane ? basePlane.data.diameter : 2,
      diameterBottom: basePlane ? basePlane.data.diameter : 2,
      height: 0.0001,
      tessellation: 96,
    });
  }

  private initGizmos(): GizmoManager {
    const gizmoManager = this.createGizmoManager();
    gizmoManager.positionGizmoEnabled = true;
    gizmoManager.rotationGizmoEnabled = false;
    gizmoManager.scaleGizmoEnabled = false;
    gizmoManager.boundingBoxGizmoEnabled = false;
    gizmoManager.usePointerToAttachGizmos = false;
    return gizmoManager;
  }
}
