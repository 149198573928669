import { Mesh } from 'babylonjs';
import { BaseDxfExportableViewerLayer } from './base-dxf-exportable-viewer-layer.model';
import { AUTOMAPPING_IMPORTABLE_LAYER, LAYER_TYPES, TUBE_PART } from './enums/layer-types.enum';
import { Layer } from './layer.model';
import { Coordinates3 } from './measurements/coordinates';

export class Tube implements Layer<TubeData> {
  id: number;
  name: string;
  createdAt: string;
  editedAt: string;
  data: TubeData = {};
  parentId: number;
  type = LAYER_TYPES.TUBES;
  isVisible = false;
  isSaved = true;
  description: string;

  static move(layer: AUTOMAPPING_IMPORTABLE_LAYER, shiftValue: [number, number]) {
    const tube = layer as Tube;
    if (tube.data.edges) {
      for (const edge of tube.data.edges) {
        edge.x -= shiftValue[0];
        edge.y -= shiftValue[1];
      }
    }
  }
}
export interface TubeData {
  edges?: [Coordinates3, Coordinates3];
  radiusInner?: number;
  radiusOuter?: number;
  length?: number;
}

export interface ViewerTubeLayer extends Tube, BaseDxfExportableViewerLayer {
  data: TubeData;
  centerLine?: Mesh;
}

export interface TubePartsArrays<T> {
  [TUBE_PART.ENDPOINT_1_OUTER]: T[];
  [TUBE_PART.ENDPOINT_1_INNER]: T[];
  [TUBE_PART.ENDPOINT_2_OUTER]: T[];
  [TUBE_PART.ENDPOINT_2_INNER]: T[];
}

export interface TubePartsValues<T> {
  [TUBE_PART.ENDPOINT_1_OUTER]: T;
  [TUBE_PART.ENDPOINT_1_INNER]: T;
  [TUBE_PART.ENDPOINT_2_OUTER]: T;
  [TUBE_PART.ENDPOINT_2_INNER]: T;
}
