import { percentagesToPixels } from '@App/app/shared/utils/scaling.utils';
import { Coordinates2 } from '../layer/measurements/coordinates';
import { AnnotationPriorities } from '../shared/annotation-priorities.enum';

export class Annotation {
  min: Coordinates2;
  max: Coordinates2;
  title: string;
  description: string;
  priority: AnnotationPriorities;
  localId: string | null = null;
  localMinPx: Coordinates2 | null = null;

  update(
    min: Coordinates2,
    max: Coordinates2,
    title: string,
    description: string,
    priority: AnnotationPriorities,
  ) {
    this.min = min;
    this.max = max;
    this.title = title;
    this.description = description;
    this.priority = priority;
  }

  updateUI(viewBox: Element | null, image: HTMLImageElement) {
    const sel = `[data-id="${this.localId}"]`;
    const annotationElement = viewBox?.querySelector(sel);
    if (annotationElement) {
      // color
      (annotationElement as HTMLElement).dataset.priority = this.priority.toString();
      // dimensions and position
      const [W, H] = [image.offsetWidth, image.offsetHeight];
      const [L, T] = [image.offsetLeft, image.offsetTop];
      const { min, max } = this;
      const [minX, minY] = [min.x * W + L, min.y * H + T];
      const [maxX, maxY] = [max.x * W + L, max.y * H + T];
      const rects = [...annotationElement.querySelectorAll('rect')];
      for (const rect of rects) {
        rect.setAttribute('x', minX.toString());
        rect.setAttribute('y', minY.toString());
        rect.setAttribute('width', (maxX - minX).toString());
        rect.setAttribute('height', (maxY - minY).toString());
      }
      const handles = [...annotationElement.querySelectorAll('.a9s-handle')];
      const points = [
        [minX, minY],
        [minX, maxY],
        [maxX, maxY],
        [maxX, minY],
      ];
      this.localMinPx = { x: minX, y: minY };
      points.forEach(([cx, cy], index) => {
        const circles =
          index < handles.length ? [...handles[index].querySelectorAll('circle')] : [];
        circles.forEach((circle) => {
          circle.setAttribute('cx', cx.toString());
          circle.setAttribute('cy', cy.toString());
          circle.setAttribute('r', `6`);
        });
      });
    }
  }

  toHttp() {
    const { title, description, priority, min, max } = this;
    return {
      title,
      priority,
      description,
      min: { x: min.x, y: min.y },
      max: { x: max.x, y: max.y },
    };
  }

  copy() {
    return Object.assign(new Annotation(), { ...this });
  }

  toAnnotoriousAnnotation(id: string, source: string, image: HTMLImageElement) {
    const { min, max } = percentagesToPixels(
      this.min,
      this.max,
      image.offsetWidth,
      image.offsetHeight,
    );
    return {
      id,
      type: 'Annotation',
      '@context': 'http://www.w3.org/ns/anno.jsonld',
      target: {
        source,
        selector: {
          type: 'FragmentSelector',
          conformsTo: 'http://www.w3.org/TR/media-frags/',
          value: `xywh=pixel:${min.x},${min.y},${max.x - min.x},${max.y - min.y}`,
        },
      },
      body: [
        {
          purpose: 'commenting',
          type: 'TextualBody',
          value: this.title,
        },
      ],
    } as AnnotoriousAnnotation;
  }
}

export interface AnnotoriousAnnotation {
  '@context': string;
  id?: string;
  type: string;
  body: {
    purpose: string;
    type: string;
    value: string;
  }[];
  target: {
    source: string;
    selector: {
      type: string;
      conformsTo: string;
      value: string;
    };
  };
}
