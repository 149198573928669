import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { ArcRotateCamera, Camera, Engine, Vector3 } from 'babylonjs';
import { Subscription } from 'rxjs';
import {
  ARC_ROTATE_CAMERA_CONFIG,
  CAMERAS_CONFIG,
  ORTHOPROJECTION_CONFIG,
  SLIDER_OPTIONS,
} from 'src/app/configs/babylon.config';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { ViewerLayerService } from '../../layer-services/viewer-layer-service/viewer-layer.service';
import { SceneService } from '../../scene-service/scene.service';
import { CameraAnimationsService } from '../camera-animations-service/camera-animations.service';
import { OrthoProjectionService } from '../orthoprojection-service/orthoprojection.service';

@Injectable({
  providedIn: 'root',
})
export class CamerasService {
  subscription: Subscription;
  private updateCamera: any;

  constructor(
    private broadcastService: BroadcastService,
    private orthoProjectionService: OrthoProjectionService,
    private sceneService: SceneService,
    private viewerLayerService: ViewerLayerService,
    private cameraAnimationsService: CameraAnimationsService,
  ) {
    this.subscription = this.broadcastService
      .on(EVENT_TYPE.CAMERA_SNAP)
      .subscribe((name: string) => {
        this.cameraAnimationsService.chooseCameraAnimation(name);
      });
  }

  initiateCameras(canvas: HTMLCanvasElement, distanceLimit = true) {
    const cameraArcRotate = new ArcRotateCamera(
      'Camera',
      (3 * Math.PI) / 2,
      1.2,
      50,
      Vector3.Zero(),
      this.sceneService.scene,
    );
    cameraArcRotate.useFramingBehavior = false;
    cameraArcRotate.wheelPrecision = 101 - SLIDER_OPTIONS.DEFAULT_SPEED_LEVEL;

    cameraArcRotate.attachControl(canvas, true);
    cameraArcRotate.inertia = ARC_ROTATE_CAMERA_CONFIG.INERTIA;
    cameraArcRotate.wheelPrecision = ARC_ROTATE_CAMERA_CONFIG.WHEEL_PRECISION;
    cameraArcRotate.useBouncingBehavior = ARC_ROTATE_CAMERA_CONFIG.USE_BOUNCING_BEHAVIOR;
    cameraArcRotate.angularSensibilityX = ARC_ROTATE_CAMERA_CONFIG.ANGULAR_SENSIBILITY_X;
    cameraArcRotate.angularSensibilityY = ARC_ROTATE_CAMERA_CONFIG.ANGULAR_SENSIBILITY_Y;
    cameraArcRotate.lowerBetaLimit = ARC_ROTATE_CAMERA_CONFIG.LOWER_BETA_LIMIT;
    cameraArcRotate.upperBetaLimit = ARC_ROTATE_CAMERA_CONFIG.UPPER_BETA_LIMIT;
    cameraArcRotate.lowerRadiusLimit = ARC_ROTATE_CAMERA_CONFIG.LOWER_RADIUS_LIMIT;
    cameraArcRotate.upperRadiusLimit = distanceLimit
      ? ARC_ROTATE_CAMERA_CONFIG.UPPER_RADIUS_LIMIT
      : null;
    cameraArcRotate.inertialPanningX = ARC_ROTATE_CAMERA_CONFIG.INERTIAL_PANNING_X;
    cameraArcRotate.inertialPanningY = ARC_ROTATE_CAMERA_CONFIG.INERTIAL_PANNING_Y;

    // Set panningSensibility depending on radius(distance to model)
    this.initProjectionModeObservers(cameraArcRotate);

    // Set panning on middle mouse button. Delete line to set panning on right mouse button
    cameraArcRotate._panningMouseButton = 1;

    return cameraArcRotate;
  }

  removeProjectionModeObservers() {
    this.sceneService.scene?.onBeforeRenderObservable.remove(this.updateCamera);
    this.updateCamera = null;
  }

  private initProjectionModeObservers(cameraArcRotate: ArcRotateCamera) {
    cameraArcRotate.minZ = CAMERAS_CONFIG.perspectiveMinZ;
    this.updateCamera = () => {
      cameraArcRotate.panningSensibility = (1 / cameraArcRotate.radius) * 5000;
    };
    this.sceneService.scene.onBeforeRenderObservable.add(this.updateCamera);
  }

  initOrthoprojection(
    canvas: HTMLCanvasElement,
    engine: Engine,
    cameraArcRotate: ArcRotateCamera,
    maxZoom?: number,
  ) {
    cameraArcRotate.minZ = CAMERAS_CONFIG.orthoGraphicMinZ;
    this.orthoProjectionService.initOrthoprojection(canvas, engine, cameraArcRotate, maxZoom);
    this.removeProjectionModeObservers();
  }

  setCameraOnSketchPlane(
    canvas: HTMLCanvasElement,
    engine: Engine,
    cameraArcRotate: ArcRotateCamera,
    id: number,
  ) {
    const data = this.viewerLayerService.getDataToSetCameraPosition(id);
    if (data) {
      const { fillingPlane, pointsMeshes } = data;
      const cam = this.sceneService.scene.activeCamera as ArcRotateCamera;
      cam.position = new Vector3(
        fillingPlane.position.x,
        fillingPlane.position.y + 30,
        fillingPlane.position.z,
      );
      this.cameraAnimationsService.animateCameraOnEditMode(cam, fillingPlane, pointsMeshes);
      const distanceFromCameraToPlane = Vector3.Distance(cam.position, fillingPlane.position);
      this.initOrthoprojection(
        canvas,
        engine,
        cameraArcRotate,
        distanceFromCameraToPlane + ORTHOPROJECTION_CONFIG.camStartingPosition,
      );
      this.orthoProjectionService.zoom2DView(cameraArcRotate, ORTHOPROJECTION_CONFIG.maxZoom);
    }
  }

  switchToPerspectiveCamera(cameraArcRotate: ArcRotateCamera) {
    cameraArcRotate.mode = Camera.PERSPECTIVE_CAMERA;
    cameraArcRotate.lowerRadiusLimit = ARC_ROTATE_CAMERA_CONFIG.LOWER_RADIUS_LIMIT;
    cameraArcRotate.upperRadiusLimit = ARC_ROTATE_CAMERA_CONFIG.UPPER_RADIUS_LIMIT;
    this.orthoProjectionService.removeOrthoprojectionObservables();
    this.initProjectionModeObservers(cameraArcRotate);
  }
}
