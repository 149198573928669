import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AxisCoordsToolService } from '@App/app/engine/services/coords-tool-services/axis-coords-tool-service/axis-coords-tool.service';
import { GeoCoordsToolService } from '@App/app/engine/services/coords-tool-services/geo-coords-tool-service/geo-coords-tool.service';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Vector3 } from 'babylonjs';
import { Observable, Subscription } from 'rxjs';
import { LayersComponent } from 'src/app/pages/viewer/layers/layers.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-coords-tool',
  templateUrl: './coords-tool.component.html',
  styleUrls: ['./coords-tool.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoordsToolComponent implements OnInit, OnDestroy {
  axisCoords$: Observable<Vector3>;
  geoCoords$: Observable<GeoCoords>;
  coordsData: string;
  cordsSubscription: Subscription;
  activeTool = 'local';

  constructor(
    private toolsToggle: LayersComponent,
    private coordsToolService: AxisCoordsToolService,
    private geoCoordsToolService: GeoCoordsToolService,
  ) {}

  ngOnInit(): void {
    this.axisCoords$ = this.coordsToolService.axisCurrentCoords$;
    this.geoCoords$ = this.geoCoordsToolService.currentGeoCoords$;
    this.geoCoordsToolService.geoCoordsToolActive$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.cordsSubscription?.unsubscribe();
        this.cordsSubscription = this.geoCoordsToolService.currentGeoCoords$.subscribe((coords) => {
          this.coordsData = `Lat: ${coords.latitude.toFixed(
            Math.max(8, (coords.latitude.toString().split('.')[1] || []).length),
          )}, lng: ${coords.longitude.toFixed(
            Math.max(8, (coords.longitude.toString().split('.')[1] || []).length),
          )}`;
        });
      }
    });
    this.coordsToolService.axisCoordsToolActive.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.cordsSubscription?.unsubscribe();
        this.cordsSubscription = this.coordsToolService.axisCurrentCoords$.subscribe((coords) => {
          this.coordsData = `X: ${this.toFixed(coords.x)}, Y: ${this.toFixed(
            coords.y,
          )}, Z: ${this.toFixed(coords.z)}`;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.turnOffTool();
  }

  gpsButtonClick() {
    if (this.activeTool === 'local') {
      this.toolsToggle.toggleGeoCoordsTool();
      this.activeTool = 'gps';
    }
  }

  localButtonClick() {
    if (this.activeTool === 'gps') {
      this.toolsToggle.toggleAxisCoordsTool();
      this.activeTool = 'local';
    }
  }

  turnOffTool() {
    if (this.toolsToggle.isGeoCoordsToolActive) {
      this.toolsToggle.toggleGeoCoordsTool();
    }
    if (this.toolsToggle.isCoordsToolActive) {
      this.toolsToggle.toggleAxisCoordsTool();
    }
  }

  toFixed(num: number) {
    return num.toFixed(3);
  }
}
