<form [formGroup]="getForm()">
  <div class="description">
    For positioning modes using control points, a valid control point set is needed on selected
    photos:<br />
    at least 3 control points with more than 2 measurements each.
  </div>

  <app-control-points-input
    class="measurements_selection"
    formControlName="controlPoints"
  ></app-control-points-input>

  <app-accuracy-input></app-accuracy-input>

  <app-srs-dropdown formControlName="srs"></app-srs-dropdown>

  <label class="sidebar__input_row sidebar__input_row--settings">
    <span class="sidebar__label sidebar__label--settings">Use Image GPS Metadata</span>
    <nb-checkbox formControlName="useImageGPSMetadata" checked="true"></nb-checkbox>
  </label>
</form>
