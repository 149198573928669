import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isIncludedIn',
  pure: false,
})
export class IsIncludedInPipe<T extends { id: number }> implements PipeTransform {
  transform(item: T, array: T[]): boolean {
    return array.some((i) => i.id === item.id);
  }
}
