import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenName',
})
export class ShortenNamePipe implements PipeTransform {
  transform(name: string, limit: number): string {
    return name.length >= limit ? name.slice(0, limit) + '...' : name;
  }
}
