import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-wrong-password-error',
  templateUrl: './wrong-password-error.component.html',
  styleUrls: ['./wrong-password-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WrongPasswordErrorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
