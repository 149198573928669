import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { Injectable } from '@angular/core';
import { getCenter, getDistance } from 'geolib';
import { median } from 'src/app/shared/utils/utils';
import { FileMetadataInfo } from '../../../models/uploaded-image-metadata.model';

@Injectable({ providedIn: 'root' })
export class PhotosValidationService {
  validatePhotos(photos: FileMetadataInfo[]) {
    const allCoordinates: GeoCoords[] = [];
    const allAltitudes: number[] = [];
    const filesToValidate: FileMetadataInfo[] = [];
    const noCoords: string[] = [];
    for (const photo of photos) {
      if (photo.coords && photo.altitude) {
        allCoordinates.push(photo.coords);
        allAltitudes.push(photo.altitude);
        filesToValidate.push(photo);
      } else {
        noCoords.push(photo.name);
      }
    }
    const center = getCenter(allCoordinates);
    const wrongCoords = center
      ? this.findTooDistancedCoords(
          filesToValidate,
          center,
          this.getMeanDistanceToCenter(filesToValidate, center),
          median(allAltitudes),
        )
      : [];
    return { noCoords, wrongCoords };
  }

  private findTooDistancedCoords(
    files: FileMetadataInfo[],
    center: GeoCoords,
    meanDistance: number,
    altitudeMedian: number,
    threshold = 100,
  ): string[] {
    return files
      .filter((file) => {
        return (
          file.coords &&
          file.altitude &&
          (getDistance(file.coords, center) > meanDistance + threshold ||
            file.altitude < altitudeMedian - threshold ||
            file.altitude > altitudeMedian + threshold)
        );
      })
      .map((file) => file.name);
  }

  private getMeanDistanceToCenter(files: FileMetadataInfo[], center: GeoCoords): number {
    return (
      files.reduce((sum, file) => sum + (file.coords ? getDistance(file.coords, center) : 0), 0) /
      files.length
    );
  }
}
