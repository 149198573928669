import { Annotation } from '@App/app/entities/layer/annotation.model';
import { AnnotationPriorities } from '@App/app/entities/shared/annotation-priorities.enum';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Mesh, Quaternion } from 'babylonjs';
import { ANNOTATION_NAME } from 'src/app/configs/babylon.config';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../material-service/material.service';
import { UtilsService } from '../../utils-service/utils.service';

@Injectable({
  providedIn: 'root',
})
export class AnnotationService {
  priority: AnnotationPriorities;
  private box: Mesh | null = null;

  constructor(
    private utilsService: UtilsService,
    private babylonNodesService: BabylonNodesService,
    private materialService: MaterialService,
    private broadcastService: BroadcastService,
    private store: Store,
  ) {}

  removeAnnotation() {
    if (this.box) {
      this.box?.dispose();
      this.box = null;
    }
  }

  onClickActionAnnotationService() {
    const hit = this.utilsService.pickRay();

    if (!hit?.pickedPoint) {
      return false;
    }

    const annotationMesh = this.babylonNodesService.createBox(
      hit.pickedPoint,
      { size: 2 },
      null,
      ANNOTATION_NAME,
      this.materialService.darkBlueMaterial,
    );

    this.createAnnotation(annotationMesh);
    annotationMesh.dispose();
  }

  createAnnotation(box: Mesh) {
    const clonedMesh = box.clone();
    clonedMesh.visibility = 0;
    clonedMesh.rotationQuaternion = new Quaternion(0, 0, 0, 0);
    const minimum = clonedMesh.getHierarchyBoundingVectors().min;
    const maximum = clonedMesh.getHierarchyBoundingVectors().max;
    const min = { x: minimum.x, y: minimum.y, z: minimum.z };
    const max = { x: maximum.x, y: maximum.y, z: maximum.z };
    const { x, y, z, w } = clonedMesh.rotationQuaternion;
    const annotation = new Annotation();

    annotation.description = annotation.description;
    annotation.isVisible = true;
    annotation.isSaved = true;
    annotation.data.max = { x: max.x, y: max.y, z: max.z };
    annotation.data.min = { x: min.x, y: min.y, z: min.z };
    annotation.data.absoluteRotationQuaternion = { x, y, z, w };
    annotation.data.priority = this.priority;

    if (!this.priority) {
      annotation.data.priority = AnnotationPriorities.LOW;
    }

    this.store.dispatch(
      addLayer({
        layer: annotation,
        commit: false,
      }),
    );

    this.broadcastService.broadcast(EVENT_TYPE.INIT_ANNOTATION, false);
    this.removeAnnotation();
    clonedMesh.dispose();
  }
}
