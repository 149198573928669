<button
  *ngxPermissionsOnly="permissions.CAN_ARCHIVE_MODEL"
  nbButton
  size="tiny"
  status="primary"
  (click)="$event.stopPropagation(); onArchive()"
  class="sites-nav__button"
  [disabled]="disabled"
  data-testId="actions-buttons-archive"
>
  <nb-icon icon="archive-outline"></nb-icon>
  {{ archiveButtonLabel }}
</button>
<button
  *ngxPermissionsOnly="permissions.CAN_DELETE_MODEL"
  class="sites-nav__button"
  [disabled]="disabled"
  nbButton
  (click)="$event.stopPropagation(); onDelete()"
  size="tiny"
  status="primary"
  data-testId="actions-buttons-delete"
>
  <nb-icon icon="trash-2-outline"></nb-icon>
  {{ deleteButtonLabel }}
</button>
