import { AuthService } from '@App/app/auth/auth.service';
import { SPINNER_COLOR } from '@App/app/configs/viewer.config';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { AdvancedFiltersService } from '@App/app/entities/shared/advanced-filters-service.model';
import { BaseAdvancedSearch } from '@App/app/entities/shared/advanced-search.model';
import { NgxTableEvent } from '@App/app/entities/shared/ngx-table-event.model';
import { PageInfo } from '@App/app/entities/shared/page-info.model';
import { Page } from '@App/app/entities/shared/page.model';
import { SitesService } from '@App/app/pages/sites/shared/site-services/sites.service';
import { MainDatatableComponent } from '@App/app/shared/components/main-datatable/main-datatable.component';
import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TableColumn } from '@swimlane/ngx-datatable';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  template: '',
})
export abstract class BaseTableComponent<
  MODEL_TYPE,
  FORM_TYPE extends FormGroup,
  SITE_SERVICE extends SitesService<MODEL_TYPE> = SitesService<MODEL_TYPE>,
  BASE_ADVANCED_SEARCH extends BaseAdvancedSearch = BaseAdvancedSearch,
  FILTER_SERVICE extends AdvancedFiltersService<
    BASE_ADVANCED_SEARCH,
    FORM_TYPE
  > = AdvancedFiltersService<BASE_ADVANCED_SEARCH, FORM_TYPE>
> implements OnInit {
  @ViewChild(MainDatatableComponent) mainTable: MainDatatableComponent<MODEL_TYPE> | undefined;
  permissions = UserPermissions;
  spinnerBdColor = SPINNER_COLOR;
  rows: MODEL_TYPE[] = [];
  selectedRows: MODEL_TYPE[] = [];
  entities: MODEL_TYPE[] = [];
  temp: MODEL_TYPE[] = [];
  loading = true;
  columns: TableColumn[] = [];
  archiveIcon = '../../../../assets/svg/icons/sf_portal-ui_icon_archive.svg';
  unarchiveIcon = '../../../../assets/svg/icons/sf_portal-ui_icon_unarchive.svg';
  page: Page;

  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected store: Store,
    protected cdr: ChangeDetectorRef,
    @Inject('sitesService') protected sitesService: SITE_SERVICE,
    @Optional() @Inject('filtersService') protected filtersService: FILTER_SERVICE | null = null,
  ) {}

  get table() {
    return this.mainTable?.table;
  }

  ngOnInit() {
    this.initColumns();

    this.sitesService?.page$.pipe(untilDestroyed(this)).subscribe((item) => {
      this.page = item;
      if (this.table) {
        this.table.offset = item.pageNumber - 1;
      }
    });

    this.authService.currentEndpoint$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((endpoint: Endpoint) => this.onEndpointChange(endpoint));

    this.filtersService?.$advancedSearch
      .pipe(
        untilDestroyed(this),
        filter((advancedSearch: BASE_ADVANCED_SEARCH) => !!(advancedSearch && this.table)),
      )
      .subscribe((advancedSearch) => this.updateFilters(advancedSearch));
  }

  setPage(pageInfo: PageInfo, updateOffset = false) {
    this.sitesService?.setPage(pageInfo, updateOffset);
    this.cdr.detectChanges();
  }

  setPageOffset(value: number) {
    this.sitesService?.setPageOffset(value);
  }

  abstract onSelectRow(event: NgxTableEvent<MODEL_TYPE>): void;

  protected abstract onEndpointChange(endpoint: Endpoint): void;

  protected abstract initColumns(): void;

  protected abstract setEntities(entities: MODEL_TYPE[]): void;

  protected abstract updateFilters(advancedSearch: BASE_ADVANCED_SEARCH): void;
}
