import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { THUMBNAIL_PLACEHOLDER } from '@App/app/configs/app.config';
import { THUMBNAIL_SHAPE } from '@App/app/configs/models.config';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Thumbnail } from '@App/app/entities/files/files.model';
import { ConfirmationDialogComponent } from '@App/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ImageCropperDialogComponent } from '@App/app/shared/components/image-cropper-dialog/image-cropper-dialog.component';
import { ThumbnailService } from '@App/app/shared/services/thumbnail/thumbnail.service';
import { compressImage, dataURLtoFile } from '@App/app/shared/utils/files.utils';
import { NbDialogService } from '@nebular/theme';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-model-overview-thumbnail',
  templateUrl: './model-overview-thumbnail.component.html',
  styleUrls: ['./model-overview-thumbnail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelOverviewThumbnailComponent {
  @Input() thumbnail: Thumbnail | null;
  isLoaded$ = this.thumbnailService.isLoaded$;
  placeholder = `../../../../${THUMBNAIL_PLACEHOLDER}`;
  permissions = UserPermissions;

  constructor(private dialogService: NbDialogService, private thumbnailService: ThumbnailService) {}

  onChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const image = input.files?.item(0);
    if (image) {
      this.openCropDialog(image);
    }
  }

  onDelete() {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Are you sure you want to delete this thumbnail?',
          status: 'danger',
        },
      })
      .onClose.subscribe((value) => {
        if (value) {
          this.thumbnailService.delete();
        }
      });
  }

  private openCropDialog(image: File) {
    this.dialogService
      .open(ImageCropperDialogComponent, { context: { image } })
      .onClose.pipe(filter<string>(Boolean))
      .subscribe(async (base64) => {
        const [W, H] = THUMBNAIL_SHAPE;
        const compressedBase64 = await compressImage(base64, W, H);
        const file = dataURLtoFile(compressedBase64, 'thumbnail.png');
        this.thumbnailService.change(file);
      });
  }
}
