import { AuthUser } from '@App/app/entities/users/user';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import {
  ACCESS_TOKEN,
  CURRENT_ENDPOINT,
  ENDPOINTS_DATA,
  ID_TOKEN,
  REFRESH_TOKEN,
  USER_DATA,
} from 'src/app/configs/auth.config';
import {
  changeCurrentEndpointSuccess,
  loadAuthDataSuccess,
  refreshTokensSuccess,
  removeAuthDataSuccess,
  updateUserUiSettingsLocally,
} from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(private actions: Actions) {}

  loadAuthDataSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(loadAuthDataSuccess),
        tap(({ authData }) => {
          const { user, tokens, endpoints, currentEndpoint } = authData;
          localStorage.setItem(ENDPOINTS_DATA, JSON.stringify(endpoints));
          localStorage.setItem(ACCESS_TOKEN, tokens.accessToken);
          localStorage.setItem(ID_TOKEN, tokens.idToken);
          localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
          localStorage.setItem(USER_DATA, JSON.stringify(user));
          localStorage.setItem(CURRENT_ENDPOINT, JSON.stringify(currentEndpoint));
        }),
      );
    },
    { dispatch: false },
  );

  updateUserUiSettingsLocally = createEffect(
    () => {
      return this.actions.pipe(
        ofType(updateUserUiSettingsLocally),
        tap(({ uiSettings }) => {
          const user = JSON.parse(localStorage.getItem(USER_DATA) as string) as AuthUser;
          user.uiSettings = uiSettings;
          localStorage.setItem(USER_DATA, JSON.stringify(user));
        }),
      );
    },
    { dispatch: false },
  );

  changeCurrentEndpointSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(changeCurrentEndpointSuccess),
        tap(({ endpoint }) => {
          localStorage.setItem(CURRENT_ENDPOINT, JSON.stringify(endpoint));
        }),
      );
    },
    { dispatch: false },
  );

  refreshTokensSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(refreshTokensSuccess),
        tap(({ tokens }) => {
          localStorage.setItem(ACCESS_TOKEN, tokens.accessToken);
          localStorage.setItem(ID_TOKEN, tokens.idToken);
        }),
      );
    },
    { dispatch: false },
  );

  removeAuthDataSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(removeAuthDataSuccess),
        tap(() => {
          localStorage.removeItem(USER_DATA);
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem(ID_TOKEN);
          localStorage.removeItem(REFRESH_TOKEN);
          localStorage.removeItem(ENDPOINTS_DATA);
          localStorage.removeItem(CURRENT_ENDPOINT);
        }),
      );
    },
    { dispatch: false },
  );
}
