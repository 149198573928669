import { Injectable } from '@angular/core';
import { Animation, ArcRotateCamera, Mesh, Plane, Vector3 } from 'babylonjs';
import {
  ArcRotateCameraAnimationConfig,
  ArcRotateCameraAnimationConfig as arcAnimationConfig,
} from 'src/app/configs/babylon.config';
import { SceneService } from '../../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export class CameraAnimationsService {
  constructor(private sceneService: SceneService) {}

  chooseCameraAnimation(name: string) {
    const cam = this.sceneService.scene.activeCamera as ArcRotateCamera;

    const cameraAnimations = {
      [ArcRotateCameraAnimationConfig.TOP.name]: () =>
        this.initCameraAnimation(
          cam,
          arcAnimationConfig.TOP.camEndPos,
          arcAnimationConfig.TOP.targetEndPos,
        ),
      [ArcRotateCameraAnimationConfig.LATERAL_LEFT.name]: () =>
        this.initCameraAnimation(
          cam,
          arcAnimationConfig.LATERAL_LEFT.camEndPos,
          arcAnimationConfig.LATERAL_LEFT.targetEndPos,
        ),
      [ArcRotateCameraAnimationConfig.FRONT.name]: () =>
        this.initCameraAnimation(
          cam,
          arcAnimationConfig.FRONT.camEndPos,
          arcAnimationConfig.FRONT.targetEndPos,
        ),
      [ArcRotateCameraAnimationConfig.LATERAL_RIGHT.name]: () =>
        this.initCameraAnimation(
          cam,
          arcAnimationConfig.LATERAL_RIGHT.camEndPos,
          arcAnimationConfig.LATERAL_RIGHT.targetEndPos,
        ),
      [ArcRotateCameraAnimationConfig.BACK.name]: () =>
        this.initCameraAnimation(
          cam,
          arcAnimationConfig.BACK.camEndPos,
          arcAnimationConfig.BACK.targetEndPos,
        ),
    };

    if (cameraAnimations.hasOwnProperty(name)) {
      cameraAnimations[name]();
    }
  }

  initCameraAnimation(cam: ArcRotateCamera, camEndPos: Vector3, targetEndPos: Vector3) {
    Animation.CreateAndStartAnimation(
      'at4',
      cam,
      'position',
      arcAnimationConfig.SPEED,
      arcAnimationConfig.FRAME_COUNT,
      cam.position,
      camEndPos,
      0,
      arcAnimationConfig.EASE,
    );
    Animation.CreateAndStartAnimation(
      'at5',
      cam,
      'target',
      arcAnimationConfig.SPEED,
      arcAnimationConfig.FRAME_COUNT,
      cam.target,
      targetEndPos,
      0,
      arcAnimationConfig.EASE,
    );
  }

  animateCameraOnEditMode = (cam: ArcRotateCamera, fillingPlane: Mesh, pointsMeshes: Mesh[]) => {
    const targetEndPos = fillingPlane.getAbsolutePosition();
    fillingPlane.computeWorldMatrix();
    const matrix = fillingPlane.getWorldMatrix();
    const localPosition = new Vector3(0, 0, this.isPlaneFacedUpward(pointsMeshes) ? 5 : -5);
    const globalPosition = Vector3.TransformCoordinates(localPosition, matrix);
    Animation.CreateAndStartAnimation(
      'at4',
      cam,
      'position',
      arcAnimationConfig.SPEED,
      1,
      cam.position,
      globalPosition,
      0,
    );
    Animation.CreateAndStartAnimation(
      'at5',
      cam,
      'target',
      arcAnimationConfig.SPEED,
      1,
      cam.target,
      targetEndPos,
      0,
    );
  };

  private isPlaneFacedUpward(pointsMeshes: Mesh[]) {
    const plane = Plane.FromPoints(
      pointsMeshes[0].position,
      pointsMeshes[1].position,
      pointsMeshes[2].position,
    );
    return plane.isFrontFacingTo(new Vector3(0, 1000, 0), 0);
  }
}
