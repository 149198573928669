import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
  @Output() changeColor = new EventEmitter<string>();
  @Input() color: string;

  constructor() {}

  acceptColor($event: string) {
    this.changeColor.emit($event);
  }
}
