import { Render } from '@App/app/entities/processing/render.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/renders.actions';

export const rendersAdapter = createEntityAdapter<Render>();

export type State = EntityState<Render>;

const defaultRenders: State = {
  ids: [],
  entities: {},
};

export const initialState: State = rendersAdapter.getInitialState(defaultRenders);

export const rendersReducer = createReducer(
  initialState,
  on(actions.loadAllRendersSuccess, (state, { renders }) => {
    return rendersAdapter.setAll(renders, state);
  }),
);
