import { Model } from '@App/app/entities/models/model.model';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';

// Check if the coordinates inside the Model are within the valid range
export const validateMapLocation = (models: Model[]) => {
  return models.filter((m) => areValidCoordinates(m.location as GeoCoords));
};

const areValidCoordinates = (coord: GeoCoords): boolean => {
  const validLatRange = { min: -90, max: 90 };
  const validLngRange = { min: -180, max: 180 };

  const isValid =
    isValidCoordinate(coord.latitude, validLatRange) &&
    isValidCoordinate(coord.longitude, validLngRange);

  return isValid;
};

const isValidCoordinate = (value: number, range: { min: number; max: number }): boolean => {
  return value >= range.min && value <= range.max;
};
