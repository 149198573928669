<div class="layer_details__subtitle">Position:</div>
<div class="layer_details__row left">
  <div class="layer_details__label">x:</div>
  <div class="layer_details__value">
    {{ position.x }}
  </div>
</div>
<div class="layer_details__row left">
  <div class="layer_details__label">y:</div>
  <div class="layer_details__value">
    {{ position.y }}
  </div>
</div>
<div class="layer_details__row left">
  <div class="layer_details__label">z:</div>
  <div class="layer_details__value">
    {{ position.z }}
  </div>
</div>
<button
  type="button"
  class="layer_details__action layer_details__action--edit layer_details__copy select-none"
  (click)="copyPosition(position)"
>
  Copy
</button>
