/* eslint-disable max-len */
import { NebularModule } from '@App/app/modules';
import { ModelsTableFiltersService } from '@App/app/pages/sites/models-site/models-table-filters/models-table-filters.service';
import { SharedModule } from '@App/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonGroupModule } from '@nebular/theme';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Ng5SliderModule } from 'ng5-slider';
import { LightboxModule } from 'ngx-lightbox';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModelSiteHeaderModule } from '../../shared/components/model-site-header/model-site-header.module';
import { ModelsSitesService } from '../sites/shared/site-services/models-sites.service';
import { ModelDetailsComponent } from './model-details/model-details.component';
import { ModelMapComponent } from './model-details/model-overview/model-map/model-map.component';
import { ModelOverviewDetailsComponent } from './model-details/model-overview/model-overview-details/model-overview-details.component';
import { ModelOverviewThumbnailComponent } from './model-details/model-overview/model-overview-thumbnail/model-overview-thumbnail.component';
import { ModelOverviewComponent } from './model-details/model-overview/model-overview.component';
import { ModelRendersComponent } from './model-details/model-overview/model-renders/model-renders.component';
// eslint-disable-next-line max-len
import { ModelSectionComponent } from './model-details/model-overview/model-overview-details/model-section/model-section.component';
import { ModelPhotoCardZoomAnnotationsComponent } from './model-details/model-photos/model-photo-card/model-photo-card-zoom/model-photo-card-zoom-annotations/model-photo-card-zoom-annotations.component';
import { ModelPhotoCardZoomComponent } from './model-details/model-photos/model-photo-card/model-photo-card-zoom/model-photo-card-zoom.component';
import { ModelPhotoCardComponent } from './model-details/model-photos/model-photo-card/model-photo-card.component';
import { PhotoAnnotationsNumberPipe } from './model-details/model-photos/model-photo-card/pipes/photo-annotations-number/photo-annotations-number.pipe';
import { ModelPhotosComponent } from './model-details/model-photos/model-photos.component';
import { AnnotationsReportAvailablePipe } from './model-details/model-photos/pipes/annotations-report-available/annotations-report-available.pipe';
import { ModelsRoutingModule } from './models-routing.module';
import { ModelsService } from './services/models-service/models.service';

@NgModule({
  declarations: [
    ModelDetailsComponent,
    ModelOverviewComponent,
    ModelPhotosComponent,
    ModelPhotoCardComponent,
    ModelMapComponent,
    ModelPhotoCardZoomComponent,
    ModelPhotoCardZoomAnnotationsComponent,
    ModelOverviewDetailsComponent,
    ModelRendersComponent,
    ModelOverviewThumbnailComponent,
    ModelSectionComponent,
    AnnotationsReportAvailablePipe,
    PhotoAnnotationsNumberPipe,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    CommonModule,
    ModelsRoutingModule,
    HttpClientModule,
    NgxDatatableModule,
    NbButtonGroupModule,
    FormsModule,
    ReactiveFormsModule,
    NebularModule,
    Ng5SliderModule,
    NgxPaginationModule,
    LightboxModule,
    SharedModule,
    ModelSiteHeaderModule,
  ],
  providers: [ModelsSitesService, ModelsService, ModelsTableFiltersService],
})
export class ModelsModule {}
