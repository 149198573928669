import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Photo } from '../entities/files/files.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PhotosHttpService extends ApiService {
  getAllPhotos(modelId: number): Observable<Photo[]> {
    return this.http.get<Photo[]>(`${this.api}/models/${modelId}/images`);
  }

  getPhotosZip(folderName: string, photos: Photo[]) {
    const names = photos.map((photo) => photo.name).join(',');
    const url = `${this.api}/models/zip?folderName=${folderName}&imagesName=${names}`;
    return this.http.get(url);
  }
}
