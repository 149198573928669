import { Pipe, PipeTransform } from '@angular/core';
import { SortingOptions } from '../../models/sorting-options.model';

@Pipe({
  name: 'sortLabel',
})
export class SortLabelPipe implements PipeTransform {
  transform(value: string): string {
    const groups = Object.entries(SortingOptions);
    const group = groups.find(([_, val]) => Object.values(val).includes(value));

    if (!group) {
      return '';
    }

    const [key, _] = group;
    return `Sort By: ${key} (${value})`;
  }
}
