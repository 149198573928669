import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

export const exponentialBackoffRetry = <E>(
  checkRetry: (error: E) => boolean,
  onRetry: (error: E) => void,
) => {
  return <T>(source: Observable<T>): Observable<T> => {
    return source.pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((error: E, index) => {
            if (checkRetry(error)) {
              onRetry(error);
              return of(error).pipe(delay(2 ** index * 1000));
            }
            return throwError(error);
          }),
        );
      }),
    );
  };
};
