import { RectType } from '@App/app/engine/helpers/rectangle-tools-helpers';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayersService } from '../../services/layers/layers.service';

@Component({
  selector: 'app-layer-details-recalculate-props',
  templateUrl: './layer-details-recalculate-props.component.html',
})
export class LayerDetailsRecalculatePropsComponent {
  @Input() layer: RectType;
  @Output() clicked = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor(private _layersService: LayersService) {}

  onRecalculateClick(): void {
    this.clicked.emit();
  }

  onRecalculateCancel(): void {
    this.cancel.emit();
  }

  isEditModeOnInitMappingTool() {
    return this._layersService.isEditModeOnInitMappingTool;
  }
}
