import { ArcRotateCameraAnimationConfig, MODEL_MESH_NAME } from '@App/app/configs/babylon.config';
import { GCPPoint } from '@App/app/entities/files/gcp-point.model';
import { ModelType } from '@App/app/entities/processing/build-process-status.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { LoadedMeshes } from '@App/app/entities/viewer/loaded-meshes';
import { PhotosUtilsService } from '@App/app/pages/viewer/services/photos-utils/photos-utils.service';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import {
  ArcRotateCamera,
  AssetContainer,
  Axis,
  ISceneLoaderAsyncResult,
  ISceneLoaderProgressEvent,
  SceneLoader,
  Space,
  TransformNode,
  Vector3,
} from 'babylonjs';
import { GLTFFileLoader } from 'babylonjs-loaders';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { GenerateGUIService } from '../../../gui-services/generate-gui-service/generate-gui.service';
import { MaterialService } from '../../../material-service/material.service';
import { SceneService } from '../../../scene-service/scene.service';
import { AssetContainerWithGui } from '../../asset-with-gui-container/asset-with-gui-container';
import { Roots } from '../../models/roots.model';

@Injectable()
export class LoadModelUtilsService {
  constructor(
    private _photosUtilsService: PhotosUtilsService,
    private _broadcastService: BroadcastService,
    private _sceneService: SceneService,
    private _materialService: MaterialService,
    private _babylonNodesService: BabylonNodesService,
    private _generateGUIService: GenerateGUIService,
  ) {}

  setCameraOnRoot(root: TransformNode, camera: ArcRotateCamera) {
    const { center, updatedRootVectors } = this._getRootCenter(root);
    root.setPivotPoint(center);
    camera.position = center.add(new Vector3(50, 10, 10));
    camera.target = center;
    const y = updatedRootVectors.max.y + ArcRotateCameraAnimationConfig.ADDITIONAL_HEIGHT;
    ArcRotateCameraAnimationConfig.TOP.camEndPos = new Vector3(0, y, 0);
  }

  translateRoot(root: TransformNode) {
    const rootVectors = root.getHierarchyBoundingVectors();
    const xDiff = (-rootVectors.min.x + -rootVectors.max.x) / 2;
    const zDiff = (-rootVectors.min.z + -rootVectors.max.z) / 2;
    root.translate(Axis.Y, -rootVectors.min.y, Space.WORLD);
    const photosModifier = {
      xDiff: 0,
      zDiff: 0,
      yDiff: rootVectors.min.y,
    };
    if (xDiff > 100 || xDiff < -100) {
      root.translate(Axis.X, xDiff, Space.WORLD);
      photosModifier.xDiff = xDiff;
    }
    if (zDiff > 100 || zDiff < -100) {
      root.translate(Axis.Z, zDiff, Space.WORLD);
      photosModifier.zDiff = zDiff;
    }
    this._photosUtilsService.photosModifier = photosModifier;
  }

  updateProgressBar(total: number, progress: number) {
    this._broadcastService.broadcast(
      EVENT_TYPE.CHECK_PROGRESS,
      Math.round((progress / total) * 100),
    );
  }

  disposeModelLoadedElement(element: LoadedMeshes) {
    element.meshes[0]?.dispose(true, true);
    element.meshes[1]?.dispose(true, true);
    element.meshes = [];
  }

  afterAssetLoadTransforms(
    root: TransformNode | null,
    camera: ArcRotateCamera,
    loadedMeshes?: ISceneLoaderAsyncResult[],
  ) {
    if (root) {
      this.translateRoot(root);
      this.setCameraOnRoot(root, camera);
      if (loadedMeshes && !!loadedMeshes.length) {
        this._broadcastService.broadcast(
          EVENT_TYPE.MODEL_MAX_HEIGHT,
          root.getHierarchyBoundingVectors(),
        );
      }
    }
  }

  loadAssetAsync(
    asset: string,
    loaders: GLTFFileLoader[],
    container: AssetContainer | null,
    root: TransformNode | null,
    model: LoadedMeshes[],
    meshPickable = true,
    onProgress?: (e: ISceneLoaderProgressEvent) => void,
  ) {
    SceneLoader.OnPluginActivatedObservable.add((loader: GLTFFileLoader) => loaders.push(loader));
    const srcDir = asset.slice(0, asset.lastIndexOf('/') + 1);
    const fileName = asset.slice(asset.lastIndexOf('/') + 1);
    const { scene } = this._sceneService;

    return SceneLoader.ImportMeshAsync('', srcDir, fileName, scene, onProgress).then((result) => {
      const parts = srcDir.split('/');
      const tileNumber = parts[parts.length - 2].match(/\d+/)?.[0];
      // First rotate model 90 degrees, model originally is set horizontally
      result.meshes[0].rotate(Axis.X, Math.PI / 2, Space.LOCAL);
      result.meshes[0].parent = root;
      result.meshes.forEach((node) => {
        container?.meshes.push(node);
        node.name = `${MODEL_MESH_NAME}_${tileNumber}`;
        node.isPickable = meshPickable;
      });
      model.push(result);
      return result;
    });
  }

  createGCP3DMarker(point: GCPPoint, atContainer: AssetContainerWithGui | null) {
    const { x, y, z } = point.position;
    const vector = new Vector3(x, y, z);
    const mat = this._materialService.redLightMaterial;
    const options = { diameter: 2 };
    const { name } = point;
    const sphere = this._babylonNodesService.createSphere(vector, name, mat, options, false);
    sphere.metadata = { isGcpWithGui: true };
    atContainer?.meshes.push(sphere);
    atContainer?.GUIs.push(this._generateGUIService.createNameTag(name, sphere, true));
  }

  disposeRootsByTypes(types: ModelType[], roots: Roots) {
    for (const _type of types) {
      roots[_type]?.dispose(true, true);
      roots[_type] = null;
    }
  }

  private _getRootCenter(root: TransformNode) {
    const updatedRootVectors = root.getHierarchyBoundingVectors();
    const center = updatedRootVectors.max.add(updatedRootVectors.min).scale(0.5);
    return { center, updatedRootVectors };
  }
}
