import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { ClipboardService } from '@App/app/shared/services/clipboard/clipboard.service';

@Component({
  selector: 'app-layer-details-position',
  templateUrl: './layer-details-position.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LayerDetailsPositionComponent implements OnInit {
  @Input() position: Coordinates3;

  constructor(private _clipboardService: ClipboardService) {}

  ngOnInit(): void {}

  copyPosition(position: Coordinates3) {
    this._clipboardService.copyPosition(position);
  }
}
