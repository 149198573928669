import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Measurement } from '@App/app/entities/layer/measurements/measurement.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractMesh, LinesMesh, Mesh, Vector3 } from 'babylonjs';
import {
  ELECTRIC_BLUE_COLOR3,
  MEASUREMENT_NAME,
  MEASUREMENT_SPHERES_NAMES,
  MIDDLE_SPHERE_NAME,
  NEW_MEASUREMENT_NAME,
} from 'src/app/configs/babylon.config';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { initLODSpheres } from '../../../helpers/layers-helpers';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../material-service/material.service';
import { SceneService } from '../../scene-service/scene.service';
import { UtilsService } from '../../utils-service/utils.service';
import { MeasurementUtilsService } from './measurement-utils.service';

@Injectable({
  providedIn: 'root',
})
export class DraftMeasurementService {
  private draftMeasurement: Measurement | null = null;
  private draftRoot: AbstractMesh | null = null;
  private dragLine: LinesMesh | null = null;
  private dragSphere: Mesh | null = null;
  private meshPointsArray: Mesh[] = [];

  constructor(
    private materialService: MaterialService,
    private measurementUtilsService: MeasurementUtilsService,
    private babylonNodesService: BabylonNodesService,
    private sceneService: SceneService,
    private store: Store,
    private utilsService: UtilsService,
    private broadcastService: BroadcastService,
  ) {}

  private createMeasurement(): void {
    const measurement = new Measurement();
    const path = this.meshPointsArray.map((point) => {
      return point.position;
    });
    this.meshPointsArray.forEach((point) => {
      point.parent = this.draftRoot;
    });

    const middlePointPosition = Vector3.Center(path[0], path[1]);
    const middleMesh = this.measurementUtilsService.createSphere(middlePointPosition, true);
    middleMesh.position = middlePointPosition;
    middleMesh.parent = this.draftRoot;
    middleMesh.id = MIDDLE_SPHERE_NAME;
    if (this.draftRoot) {
      this.draftRoot.id = NEW_MEASUREMENT_NAME;
    }
    this.meshPointsArray.push(middleMesh);

    measurement.name = MEASUREMENT_NAME;
    measurement.isVisible = true;
    measurement.isSaved = true;
    measurement.data.meshesPoints = this.meshPointsArray.map((mesh: Mesh) => {
      return { x: mesh.position.x, y: mesh.position.y, z: mesh.position.z };
    });
    measurement.data.length = this.utilsService.getLengthBetweenPoints(path[0], path[1]);
    this.store.dispatch(addLayer({ layer: measurement as LayerUI, commit: false }));
    this.broadcastService.broadcast(EVENT_TYPE.INIT_MEASUREMENT, false);
    this.removeDraftMeasurement();
  }

  onClickAction() {
    const hit = this.utilsService.pickRay();
    if (!hit?.pickedPoint) {
      return false;
    }
    if (!this.draftRoot) {
      this.draftRoot = new AbstractMesh(NEW_MEASUREMENT_NAME, this.sceneService.scene);
    }
    const mesh = this.measurementUtilsService.createSphere(hit.pickedPoint, false);
    mesh.parent = this.draftRoot;
    initLODSpheres(
      mesh,
      this.sceneService.scene,
      this.materialService.electricBlueMaterial,
      MEASUREMENT_SPHERES_NAMES.LOD_SPHERES_NAME,
      MEASUREMENT_SPHERES_NAMES.LOD_SPHERE_NAME,
    );
    this.meshPointsArray.push(mesh);
    if (this.meshPointsArray.length === 2) {
      this.removeDragMeshes();
      this.createMeasurement();
    }
  }

  onMoveAction() {
    if (this.meshPointsArray.length === 1) {
      const hit = this.utilsService.pickRay();
      if (!hit?.pickedPoint) {
        this.removeDragMeshes();
        return false;
      }
      if (!this.dragSphere) {
        this.dragSphere = this.measurementUtilsService.createSphere(hit.pickedPoint, false);
        initLODSpheres(
          this.dragSphere,
          this.sceneService.scene,
          this.materialService.electricBlueMaterial,
          MEASUREMENT_SPHERES_NAMES.LOD_SPHERES_NAME,
          MEASUREMENT_SPHERES_NAMES.LOD_SPHERE_NAME,
        );
      } else {
        this.dragSphere.position = hit.pickedPoint;
      }

      this.dragLine = this.babylonNodesService.createLine(
        [this.meshPointsArray[0].position, this.dragSphere.position],
        MEASUREMENT_SPHERES_NAMES.LINE_NAME,
        ELECTRIC_BLUE_COLOR3,
        {
          updatable: true,
          instance: this.dragLine || undefined,
        },
      );
    }
  }

  removeDragMeshes(): void {
    this.dragSphere?.dispose();
    this.dragSphere = null;
    this.dragLine?.dispose();
    this.dragLine = null;
  }

  removeDraftMeasurement(): void {
    this.removeDragMeshes();
    this.draftMeasurement = null;
    this.draftRoot?.dispose();
    this.draftRoot = null;
    if (this.meshPointsArray.length) {
      this.meshPointsArray.forEach((mesh) => mesh.dispose());
      this.meshPointsArray = [];
    }
  }
}
