import { Endpoint } from '../auth/endpoint.model';
import { CurrentPreset, IPreset } from '../viewer/custom-preset.model';

export class Role {
  id: number;
  name: RoleName;
  description: string;
}

class BaseUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export class User extends BaseUser {
  roles: UserRole[];
  isDisabled: boolean;
}

export class AuthUser extends BaseUser {
  companies: Endpoint[];
  uiSettings?: UiSettings;
}

export class UiSettings {
  pageOffset?: number;
  viewFinderSize?: number;
  currentPreset?: CurrentPreset;
  presets?: IPreset[];
  hasDocsAccepted?: boolean;
}

export class HttpAddUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
}

export class AddUserForm extends HttpAddUser {
  roleIds: number[];
  confirmPassword: string;
}

export class EditUserForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  roleIds: number[];
}

export class EditUserFormExt extends EditUserForm {
  id: number;
}

export class HttpEditUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface HttpEditRoles {
  userId: number;
  roleIds: number[];
}
interface UserRole {
  id: number;
  name: RoleName;
  description: string;
}

export enum RoleName {
  COMPANY_MANAGER = 'Company Manager',
  STANDARD_USER = 'StandardUser',
  PILOT = 'Pilot',
  ADMIN = 'Admin',
  ENGINEER = 'Engineer',
  PROCESSING_MANAGER = 'Processing Manager',
  PROCESSING_MACHINE = 'ProcessingMachine',
}

export enum Roles {
  'StandardUser' = 1,
  'Admin',
  'Engineer',
  'Processing Manager',
  'Pilot',
  'Company Manager',
  'ProcessingMachine',
}
