<nb-card>
  <nb-card-body class="custom-preset-container" [formGroup]="presetForm">
    <input
      type="text"
      data-testid="presetName"
      nbInput
      fullWidth
      autofocus
      fieldSize="small"
      placeholder="Name preset"
      class="input-name-preset"
      formControlName="presetName"
      [maxlength]="maxPresetNameLength"
    />
    <div class="validation_error" *ngIf="presetForm.controls.presetName.errors?.required">
      Name cannot be empty
    </div>
    <div class="validation_error" *ngIf="sameName">This name already exist</div>
    <div class="colors-inputs-container">
      <div class="color-viewer">
        <span [style.background]="backgroundColor"></span>
        <div>- Background color</div>
      </div>
      <div class="color-viewer">
        <span [style.background]="viewfinderColor"></span>
        <div>- Viewfinder color</div>
      </div>
    </div>
  </nb-card-body>
  <div class="button-container">
    <button nbButton status="basic" size="small" (click)="closeWindow()">Cancel</button>
    <button
      [disabled]="presetForm.invalid || sameName"
      nbButton
      status="info"
      size="small"
      (click)="savePreset()"
    >
      Confirm
    </button>
  </div>
</nb-card>
