import { environment } from '@App/environments/environment';
import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { VIEWER_CONTROL } from '../configs/viewer.config';
import { LAYER_EVENTS } from '../entities/layer/enums/layer-types.enum';
import { EVENT_TYPE } from '../entities/shared/event-types.enum';
import { BroadcastService } from '../shared/broadcast.service';
import { TiePointsService } from './../pages/viewer/services/tie-points/tie-points.service';
import { AnimateService } from './services/animate-service/animate.service';
import { CamerasService } from './services/camera-services/cameras-service/cameras.service';
import { BaseEngineService } from './services/engine-services/base-engine-service/base-engine.service';
import { LayerEventsService } from './services/layer-services/layer-events-service/layer-events.service';
import { MaterialService } from './services/material-service/material.service';
import { UtilsService } from './services/utils-service/utils.service';

@UntilDestroy({ arrayName: 'activeSubscriptions' })
@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.scss'],
})
export class EngineComponent implements OnInit, OnDestroy {
  @Input() private engineService: BaseEngineService;
  @ViewChild('loadingError', { static: true })
  private loadingErrorRef: TemplateRef<any>;
  @ViewChild('rendererCanvas', { static: true })
  private rendererCanvas: ElementRef<HTMLCanvasElement>;
  private dialogRef: NbDialogRef<any>;
  private activeSubscriptions: Subscription[] = [];
  activeLayerEvent = false;
  tiePointsModeActive$: Observable<boolean>;
  loadedPercent = 0;
  loadingText: string | null = null;
  modelIsNotAvailable: string | null = null;
  lostContext$: Observable<boolean>;
  allowToShowModelPlaceholder$: Observable<boolean>;

  constructor(
    private broadcastService: BroadcastService,
    private dialogService: NbDialogService,
    private layerEventsService: LayerEventsService,
    private location: Location,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private utilsService: UtilsService,
    private tiePointsService: TiePointsService,
    private materialService: MaterialService,
    private cameraService: CamerasService,
    private animateService: AnimateService,
  ) {}

  ngOnInit() {
    this.tiePointsModeActive$ = this.tiePointsService.tiePointsModeActive$;
    this.lostContext$ = this.engineService.lostContext$;
    this.allowToShowModelPlaceholder$ = this.engineService.allowToShowModelPlaceholder$;

    this.activeSubscriptions.push(
      this.broadcastService.on(EVENT_TYPE.CHECK_PROGRESS).subscribe((value: number) => {
        this.loadedPercent = value;
      }),
      this.broadcastService
        .on(EVENT_TYPE.LOAD_MODEL)
        .pipe(delay(100))
        .subscribe(() => {
          this.engineService.loadModel();
        }),
      this.engineService.loadingText$.subscribe((text) => {
        this.loadingText = text;
      }),
      this.engineService.modelIsNotAvailable$.subscribe((text) => {
        this.modelIsNotAvailable = text;
      }),
      this.layerEventsService.activeLayerEvent.asObservable().subscribe((layerEvent) => {
        this.activeLayerEvent = layerEvent !== LAYER_EVENTS.NULL;
      }),
      this.engineService.loadingError$.subscribe((message) => {
        if (message) {
          this.showLoadingError(message);
        } else {
          this.closeDialog();
        }
      }),
      this.engineService.isModelLoading$.subscribe((val: boolean) => {
        if (val) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      }),
    );

    this.utilsService.setModelId(this.getModelId());

    if (environment.production) {
      this.engineService.setModelLoad$(true, false);
    }

    this.activeSubscriptions.push(
      ...this.engineService.initEngineSubscriptions(
        this.rendererCanvas.nativeElement,
        this.getModelId(),
      ),
    );
  }

  prevPage() {
    this.closeDialog();
    this.location.back();
  }

  ngOnDestroy() {
    this.cameraService.removeProjectionModeObservers();
    this.animateService.destroyListeners();
    this.engineService?.camera?.dispose();
    if (this.engineService) {
      this.engineService.camera = null;
    }
    this.materialService.destroyMaterials();
    if (this.engineService) {
      this.engineService.canvas = null;
    }
    this.engineService?.destroyViewer();
    this.closeDialog();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === VIEWER_CONTROL.onTargetChange) {
      this.broadcastService.broadcast(EVENT_TYPE.SPACE_PRESSED, true);
      document.addEventListener(
        'click',
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.engineService.handleMovingArcRotateCameraTarget,
        true,
      );
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    if (event.code === VIEWER_CONTROL.onTargetChange) {
      this.broadcastService.broadcast(EVENT_TYPE.SPACE_PRESSED, false);
      document.removeEventListener(
        'click',
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.engineService.handleMovingArcRotateCameraTarget,
        true,
      );
    }
  }

  private getModelId() {
    return parseInt(this.route.parent?.parent?.snapshot.params.id, 10);
  }

  private closeDialog() {
    this.dialogRef?.close();
  }

  private showLoadingError(message: string) {
    if (!this.dialogRef) {
      this.dialogRef = this.dialogService.open(this.loadingErrorRef, {
        closeOnBackdropClick: false,
        context: { loadingErrorMessage: message },
      });
    }
  }
}
