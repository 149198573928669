<nb-card status="primary" class="content-card sites">
  <nb-card-body>
    <app-main-datatable
      [columns]="columns"
      [rows]="rows"
      [page]="page"
      [loading]="loading"
      [getRowClass]="getRowClass"
      [sortType]="SortType.single"
      [selected]="selectedRows"
      [sorts]="[{ prop: 'heartbeatAt', dir: 'desc' }]"
      (selectRow)="onSelectRow($event)"
      (activate)="onActivate($event)"
      (setPage)="setPage($event, true)"
      (setPageOffset)="setPageOffset($event)"
      rowHeight="75"
    ></app-main-datatable>
    <div>
      <ngx-spinner
        [bdColor]="spinnerBdColor"
        [zIndex]="10"
        size="large"
        color="#94959a"
        [fullScreen]="false"
        type="ball-clip-rotate"
      ></ngx-spinner>
    </div>
  </nb-card-body>
</nb-card>
<div
  class="tooltip-wrapper"
  [style.top]="this.tooltipConfig.y + 'px'"
  [style.left]="this.tooltipConfig.x + 'px'"
  [nbTooltip]="this.tooltipConfig.text"
  [nbTooltipTrigger]="nbTriggerClick"
></div>

<ng-template
  ngx-datatable-header-template
  #selectAllTpl
  let-value="value"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
  <label class="lbl-checkbox">
    <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
  </label>
</ng-template>

<ng-template
  #selectTpl
  ngx-datatable-cell-template
  let-value="value"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
  let-isSelected="isSelected"
>
  <label class="lbl-checkbox" (click)="$event.stopPropagation()">
    <input
      type="checkbox"
      [checked]="isSelected"
      (change)="onCheckboxChangeFn($event)"
      (click)="$event.stopPropagation(); onSelectRow(value)"
    />
  </label>
</ng-template>

<ng-template #updatedTpl let-row="row" let-value="value">
  <div *ngIf="value; else emptyTpl">{{ value | date: 'MM/dd/yyyy,' }}</div>
  <div *ngIf="value; else emptyTpl">{{ value | date: 'hh:mm:ss a' }}</div>
</ng-template>

<ng-template #emptyTpl>
  <span>-</span>
</ng-template>

<ng-template #progressTpl let-row="row" let-value="value">
  <app-segmented-progress-bar [process]="row"></app-segmented-progress-bar>
</ng-template>

<ng-template #actionsTpl let-row="row">
  <app-model-processing-actions [process]="row"></app-model-processing-actions>
</ng-template>
