import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-actions-buttons',
  templateUrl: './actions-buttons.component.html',
  styleUrls: ['./actions-buttons.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ActionsButtonsComponent {
  @Input() disabled: boolean;
  @Input() archiveButtonLabel: string;
  @Input() deleteButtonLabel: string;
  @Output() archive = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  permissions = UserPermissions;

  onArchive() {
    this.archive.emit();
  }

  onDelete() {
    this.delete.emit();
  }
}
