import { Analytics } from '@App/app/entities/processing/analytics.model';
import { Duration } from '@App/app/entities/shared/duration.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CostsService {
  constructor() {}

  calculateCosts(analytics: Analytics) {
    const atCost = this._calculateAtCost(analytics);
    const prodCost = this._calculateProdCost(analytics);
    const ppCost = this._calculatePpCost(analytics);
    const totalCost = this._calculateTotalRenderCosts(atCost, prodCost, ppCost);
    return {
      atCost,
      prodCost,
      ppCost,
      totalCost,
    };
  }

  private _getDateRangeOf(duration: Duration | undefined) {
    return duration === undefined ? null : this._getDateRangeInSeconds(duration);
  }

  private _getDateRangeInSeconds(rangeDates: Duration) {
    return (
      (new Date(
        rangeDates?.finishedAt.slice(0, rangeDates?.finishedAt.lastIndexOf('.')),
      ).getTime() -
        new Date(
          rangeDates?.startedAt.slice(0, rangeDates?.startedAt.lastIndexOf('.')),
        ).getTime()) /
      1000
    );
  }

  private _calculateAtCost(analyticsModel?: Analytics) {
    const atDuration = this._getDateRangeOf(analyticsModel?.aerotriangulation);
    return atDuration === null ? null : 4 * 0.768 * (atDuration / 3600);
  }

  private _calculateProdCost(analyticsModel?: Analytics) {
    const prodDuration = this._getDateRangeOf(analyticsModel?.production);
    const analytics = analyticsModel;
    return typeof prodDuration === 'number' && analytics?.prodWorkerCount
      ? analytics?.prodWorkerCount * 0.752 * (prodDuration / 3600)
      : null;
  }

  private _calculatePpCost(analyticsModel?: Analytics) {
    const ppDuration = this._getDateRangeOf(analyticsModel?.postProduction);
    return ppDuration === null ? null : 0.752 * (ppDuration / 3600);
  }

  private _calculateTotalRenderCosts(
    atCost: number | null,
    prodCost: number | null,
    ppCost: number | null,
  ) {
    return atCost !== null && prodCost !== null && ppCost !== null
      ? atCost + prodCost + ppCost
      : null;
  }
}
