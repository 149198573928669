import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export const match = (controlName: string, matchingControlName: string, shouldMatch: boolean) => {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    // check if matchingControl has errors and return if another validator has already found an error on the matchingControl
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    // set error on matchingControl if validation fails
    const isError = shouldMatch
      ? control.value !== matchingControl.value
      : control.value === matchingControl.value;
    matchingControl.setErrors(isError ? { mustMatch: true } : null);
  };
};
