import { CUSTOM_ICONS_PACK } from '@App/app/configs/app.config';
import { Injectable } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class CustomIconsPackService {
  private _icons: { name: string; svg: string }[] = [
    { name: 'target', svg: require(`!!raw-loader!../../../../assets/svg/target_icon.svg`).default },
    {
      name: 'cuboid',
      svg: require(`!!raw-loader!../../../../assets/svg/icons/cuboid.svg`).default,
    },
    {
      name: 'dxf-label',
      svg: require(`!!raw-loader!../../../../assets/svg/icons/sf_portal-ui_icon_dxf.svg`).default,
    },
  ];

  constructor(private _iconLibraries: NbIconLibraries) {}

  register() {
    this._iconLibraries.registerSvgPack(
      CUSTOM_ICONS_PACK,
      this._icons.reduce((res, icon) => ({ ...res, [icon.name]: icon.svg }), {}),
    );
  }
}
