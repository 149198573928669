import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SonyAuthData } from '../../../models/sony/sony-auth-data.model';
import * as fromSonyAuth from '../reducers/sony-auth.reducer';

export const selectSonyAuthState = createFeatureSelector<SonyAuthData>('sonyAuth');

export const selectCodeSet = createSelector(selectSonyAuthState, fromSonyAuth.getCodeSet);

export const selectLoginStep = createSelector(selectSonyAuthState, fromSonyAuth.getLoginStep);

export const selectTokens = createSelector(selectSonyAuthState, fromSonyAuth.getTokens);
