import { User } from '@App/app/entities/users/user';
import { UsersSitesService } from '@App/app/pages/sites/shared/site-services/users-sites.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UsersHttpService } from '../../users-http.service';
import { loadAllUsers, loadAllUsersSuccess } from '../actions/users.actions';

@Injectable()
export class LoadUsersEffects {
  constructor(
    private actions: Actions,
    private usersHttpService: UsersHttpService,
    private sitesService: UsersSitesService,
  ) {}

  loadAllUsers = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAllUsers),
      mergeMap(() => {
        return this.usersHttpService.getAllUsers().pipe(
          map(
            (usersResp: User[]) => {
              this.sitesService.loading.next(false);
              return loadAllUsersSuccess({
                users: usersResp,
                totalCount: usersResp.length,
              });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });
}
