import { Coordinates3 } from '../layer/measurements/coordinates';
import { GeoCoords } from '../shared/geo-coords';
import { RPYAngles } from '../shared/parameters.model';
import { Annotation } from './annotation.model';

export enum FileTypes {
  PHOTO = 'image',
  TILE = 'tile',
  SIMPLIFIED_TILE = 'tile-s',
  GCP = 'gcp',
  FLAT_FILE = 'flat-file',
  AT_LOG = 'at-log',
  PROD_LOG = 'prod-log',
  AUTOMAPPING = 'automapping',
  TIE_POINTS = 'tie-points',
  AT_MESH = 'at-mesh',
  THUMBNAIL = 'model-thumbnail',
  GCP_RELATIVE = 'gcp-relative',
  ORTHO_DSM = 'ortho-dsm',
  POINT_CLOUD = 'point-cloud',
}

export enum LogFileTypes {
  AT_LOG = FileTypes.AT_LOG,
  PROD_LOG = FileTypes.PROD_LOG,
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FileData {}

export type GCPData = FileData;

export type FlatFileData = FileData;

export type ATLogData = FileData;

export type ProdLogData = FileData;

export type AutomappingData = FileData;

export type TiePointsData = FileData;

export type GCPListData = FileData;

export type ATMeshData = FileData;

export type ThumbnailData = FileData;

export type TileData = FileData;

export interface TileSData extends TileData {
  level?: number;
}

export interface PhotoData extends FileData {
  position: Coordinates3;
  rotation: RPYAngles;
  nearDepth: number;
  thumbnailURL: string;
  annotations: Annotation[];
  size?: number;
  gpsPosition?: GeoCoords;
}
