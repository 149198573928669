<nb-card *ngIf="model" class="content-card overview">
  <nb-card-header class="card-header">
    <div>
      <div class="overview__name_container">
        <span class="overview__name">
          <span class="overview__address"> {{ model.name }}, {{ model.address.country }} </span>
          <app-model-type-badge [modelType]="model.modelType"></app-model-type-badge>
        </span>
        <span class="overview__zip">
          {{ model.address.state | modelStateAbbr }} {{ model.address.zip }}
        </span>
      </div>
    </div>
    <div>
      <ng-container *ngxPermissionsOnly="permissions.CAN_ACCESS_VIEWER">
        <a
          *ngIf="lastBuildProcess"
          class="overview__actions--link"
          [routerLink]="['/', companyId, 'processing', lastBuildProcess.id]"
        >
          <span>
            <nb-icon icon="external-link-outline"></nb-icon>
            Last Build Process
          </span>
        </a>
      </ng-container>
      <ng-container *ngxPermissionsOnly="permissions.CAN_ARCHIVE_MODEL">
        <a
          class="overview__actions--buttons"
          nbButton
          status="primary"
          size="small"
          (click)="onArchiveModel(model)"
        >
          <span> {{ model.isArchived ? 'Unarchive model' : 'Archive model' }} </span>
        </a>
      </ng-container>
      <ng-container *ngxPermissionsOnly="permissions.CAN_DELETE_MODEL">
        <a
          class="overview__actions--buttons"
          nbButton
          status="danger"
          size="small"
          (click)="onDeleteModel(model)"
        >
          <span> Delete model </span>
        </a>
      </ng-container>
    </div>
  </nb-card-header>
  <nb-card-body class="overview__body">
    <div class="overview__col">
      <div class="overview__header">
        <nb-card size="tiny" class="overview__header__thumbnail">
          <app-model-overview-thumbnail [thumbnail]="thumbnail"></app-model-overview-thumbnail>
        </nb-card>
        <div size="tiny" class="overview__header__map">
          <app-model-map data-testId="model-map"></app-model-map>
        </div>
      </div>
    </div>
    <div class="overview__col">
      <nb-card size="small" class="overview__details">
        <nb-card-body>
          <app-model-overview-details
            data-testId="overview-details"
            [model]="model"
          ></app-model-overview-details>
        </nb-card-body>
        <nb-card-footer>
          <div class="overview__actions">
            <ng-container *ngxPermissionsOnly="permissions.CAN_EDIT_MODEL">
              <button
                class="overview__actions--buttons"
                nbButton
                status="info"
                size="small"
                [routerLink]="['/', companyId, 'models', model.id, 'edit']"
              >
                <nb-icon icon="edit-outline"></nb-icon>
                <span> Edit </span>
              </button>
            </ng-container>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
    <div class="overview__col">
      <div class="overview__renders">
        <app-model-renders
          [companyId]="companyId"
          [model]="model"
          [currentRender]="currentRender"
          [renders]="renders"
          data-testId="model-renders"
        ></app-model-renders>
      </div>
    </div>
  </nb-card-body>
</nb-card>
