import { Photo } from '@App/app/entities/files/files.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'photoThumbnail',
})
export class PhotoThumbnailPipe implements PipeTransform {
  transform(photo: Photo): string {
    return photo.data.thumbnailURL;
  }
}
