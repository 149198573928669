<ng-container *ngIf="value; else noValue">
  <ng-container [ngSwitch]="type">
    <span *ngSwitchCase="OPTIONS.length">{{ value | lengthUnit: unitsSystem }}</span>
    <span *ngSwitchCase="OPTIONS.area">{{ value | areaUnit: unitsSystem }}</span>
  </ng-container>
</ng-container>

<ng-template #noValue>
  <span> No data </span>
</ng-template>
