<div class="content-card entities own-scroll">
  <nb-card class="entities__card">
    <nb-card-header *ngIf="headerContentTpl">
      <ng-container *ngTemplateOutlet="headerContentTpl"></ng-container>
    </nb-card-header>
    <nb-card-body [nbSpinner]="loading">
      <div class="entities__body">
        <nb-list
          *ngIf="entities.length || loading; else empty"
          class="entities__grid"
          nbInfiniteList
          [threshold]="threshold"
          (bottomThreshold)="onLoadNext()"
          data-testId="grid-list"
        >
          <nb-list-item *ngFor="let entity of virtualScrollData.data; trackBy: trackById">
            <ng-container *ngTemplateOutlet="cellContentTpl; context: { entity }"></ng-container>
          </nb-list-item>
        </nb-list>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="footerContentTpl" class="entities__footer">
      <ng-container *ngTemplateOutlet="footerContentTpl"></ng-container>
    </nb-card-footer>
  </nb-card>
</div>

<ng-template #empty>
  <div class="entities__grid entities__empty">{{ noEntitiesPlaceholder }}</div>
</ng-template>
