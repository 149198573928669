<div
  *ngIf="!(isBasePlaneCreated$ | async) && (layersLoaded$ | async)"
  class="layers__disable_cover"
>
  Create a base plane
</div>

<div class="layers__filter layers__filter-container">
  <div class="layers__input">
    <input
      type="text"
      class="layers__input__inner"
      data-testId="layers-list-keyword-input"
      placeholder="Search"
      [value]="keywords$ | async"
      (input)="onChangeKeyword($event)"
    />
    <span
      *ngIf="!!(keywords$ | async)?.length"
      class="layers__filter__close"
      (click)="clearKeyword()"
    >
      <nb-icon class="layers__icon" icon="close-outline"></nb-icon>
    </span>
  </div>
  <div class="layers__filter-container" [ngClass]="{ '--disabled': !!(keywords$ | async)?.length }">
    <span
      class="layers__toggle"
      [nbTooltip]="(isAnyGuiLayerVisible$ | async) ? 'Show all tags' : 'Hide all tags'"
      data-testId="toggle-all-tags"
      nbTooltipStatus="primary"
      [ngClass]="{ '--active': isAnyGuiLayerVisible$ | async }"
      (click)="onAllGuiLayersToggleVisibility()"
    >
      <nb-icon class="layers__icon" icon="pricetags-outline"></nb-icon>
    </span>
    <span
      class="layers__toggle"
      data-testId="toggle-all-layers"
      nbTooltip="Display all layers"
      nbTooltipStatus="primary"
      [ngClass]="{ '--active': isAnyLayerVisible$ | async }"
      (click)="onAllLayersToggleVisibility()"
    >
      <nb-icon class="layers__icon" icon="eye"></nb-icon>
    </span>
  </div>
</div>

<div class="layers__list">
  <ng-container *ngIf="children$ | async">
    <div *ngIf="rootFolder$ | async as rootFolder" [nbSpinner]="(layersLoading$ | async) ?? false">
      <app-layers-sortable #layersList [rootLayer]="rootFolder"></app-layers-sortable>
    </div>
  </ng-container>
</div>

<div class="layers__actions">
  <button
    [disabled]="!(isAnyLayerSelected$ | async)"
    nbButton
    status="info"
    size="tiny"
    class="layers__button"
    (click)="onAnalyzeClick()"
  >
    <nb-icon size="medium" icon="info-outline"></nb-icon>Analyze
  </button>
  <button
    nbButton
    status="info"
    size="tiny"
    class="layers__button layers__button__show"
    (click)="onToggleShowLayersClick()"
    [disabled]="!(isAnyLayerSelected$ | async)"
  >
    <nb-icon
      [icon]="(isAtLeastOneSelectedLayerVisible$ | async) ? 'eye-off-outline' : 'eye-outline'"
    ></nb-icon>
    {{ (isAtLeastOneSelectedLayerVisible$ | async) ? 'Hide' : 'Show' }}
  </button>
  <button
    nbButton
    status="danger"
    size="tiny"
    class="layers__button"
    (click)="onSelectedDeleteClick()"
    [disabled]="!(isAnyLayerSelected$ | async)"
    *ngxPermissionsOnly="permissions.CAN_DELETE_LAYER"
  >
    <nb-icon icon="trash-2-outline"></nb-icon> Delete
  </button>
</div>

<div class="layers__total">selected: {{ (selectedLayers$ | async)?.length || 0 }}</div>
