import { ControlPointsForm } from '@App/app/entities/forms/at-setup-tab-form.model';

import { Injectable } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AtSetupTabs } from '../../models/at-setup-tab.enum';

@Injectable({
  providedIn: 'root',
})
export class AtSetupTabService {
  private _activeTab$ = new BehaviorSubject<AtSetupTabs>(AtSetupTabs.SETTINGS);
  activeTab$ = this._activeTab$.asObservable();

  constructor() {}

  setActiveTab(activeTab: AtSetupTabs) {
    this._activeTab$.next(activeTab);
  }

  getAtSettingsForm(fb: NonNullableFormBuilder) {
    return fb.group({
      photoSamplingRate: fb.control(0.8, { validators: [Validators.required] }),
      useGCP: fb.control(false, { validators: [Validators.required] }),
      settingsPreset: fb.control(0, {
        validators: [Validators.required],
      }),
      photoLocationFilePath: fb.control('', { validators: [Validators.required] }),
      keyPointsDensity: fb.control('high', { validators: [Validators.required] }),
      targetsExtraction: fb.control('disabled', { validators: [Validators.required] }),
      splatsPreprocessing: fb.control('atlas', { validators: [Validators.required] }),
      pairSelectionMode: fb.control('default', { validators: [Validators.required] }),
      componentConstructionMode: fb.control('onePass', { validators: [Validators.required] }),
      colorEqualizationPreprocessing: fb.control('blockwise', {
        validators: [Validators.required],
      }),
      tiePointsPolicy: fb.control('compute', { validators: [Validators.required] }),
      rotationPolicy: fb.control('compute', { validators: [Validators.required] }),
      centerPolicy: fb.control('compute', { validators: [Validators.required] }),
      photogroupEstimationMode: fb.control('multiPass', { validators: [Validators.required] }),
      focalPolicy: fb.control('adjust', { validators: [Validators.required] }),
      principalPolicy: fb.control('adjust', { validators: [Validators.required] }),
      radialPolicy: fb.control('adjust', { validators: [Validators.required] }),
      tangentialPolicy: fb.control('adjust', { validators: [Validators.required] }),
      aspectRatioPolicy: fb.control('keep', { validators: [Validators.required] }),
      skewPolicy: fb.control('keep', { validators: [Validators.required] }),
      blockType: fb.control('generic', { validators: [Validators.required] }),
      controlPointSettings: fb.control<ControlPointsForm['value'] | null>(null),
    });
  }
}
