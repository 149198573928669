import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayersService } from '../../../../services/layers/layers.service';

@UntilDestroy()
@Pipe({
  name: 'isFolderVisible',
})
export class IsFolderVisiblePipe implements PipeTransform {
  private _folders: LayerUI[];
  private _visibleLayers: LayerUI[];

  constructor(private _layersService: LayersService) {
    this._layersService.folders$.pipe(untilDestroyed(this)).subscribe((folders) => {
      this._folders = folders;
    });

    this._layersService.visibleLayers$.pipe(untilDestroyed(this)).subscribe((layers) => {
      this._visibleLayers = layers;
    });
  }

  transform(folder: LayerUI): boolean {
    return this._isVisible(folder);
  }

  private _isVisible(folder: LayerUI): boolean {
    if (this._visibleLayers.some((selected) => selected.parentId === folder.id)) {
      return true;
    }
    const subFolders = this._folders.filter((f) => f.parentId === folder.id);
    return subFolders.some((f) => this._isVisible(f));
  }
}
