import { SlicePlane } from '@App/app/entities/layer/slice-plane.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BoundingVectors } from '@App/app/entities/viewer/bounding-vectors.model';
import { selectAllPhotos } from '@App/app/shared/store/photos/selectors/photos.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { PhotosUtilsService } from 'src/app/pages/viewer/services/photos-utils/photos-utils.service';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { GenerateGUIService } from '../../gui-services/generate-gui-service/generate-gui.service';

@Injectable({
  providedIn: 'root',
})
export class SliceToolService {
  bottomSlice = new SlicePlane('bottomSlice');
  topSlice = new SlicePlane('topSlice');
  private maxHeight: number;
  private minHeight: number;

  constructor(
    private broadcastService: BroadcastService,
    private generateGUIService: GenerateGUIService,
    private photosUtilsService: PhotosUtilsService,
    private store: Store,
  ) {
    broadcastService.on(EVENT_TYPE.SHOW_SLICE_PLANES).subscribe((visible: boolean) => {
      this.topSlice.showPlanes(this.topSlice, visible);
      this.bottomSlice.showPlanes(this.bottomSlice, visible);
    });

    broadcastService.on(EVENT_TYPE.MODEL_MAX_HEIGHT).subscribe((vec3: BoundingVectors) => {
      this.maxHeight = vec3.max.y;
      this.minHeight = vec3.min.y;
    });
  }

  initSlicePlanes() {
    let photosMaxHeigh = 0;
    this.store
      .select(selectAllPhotos)
      .pipe(take(1))
      .subscribe((photos) => {
        const validPhotos = photos.filter((photo) => photo.data?.position);
        if (validPhotos) {
          photosMaxHeigh = Math.max(
            ...validPhotos.map((photo) => this.photosUtilsService.position(photo.data.position).y),
          );
        }
      });
    const { fillingTopPlane, gridTopPlane } = this.generateGUIService.createHorizontalTopSliceTool(
      Math.max(this.maxHeight, photosMaxHeigh) + 10,
    );
    const {
      fillingBottomPlane,
      gridBottomPlane,
    } = this.generateGUIService.createHorizontalBottomSliceTool(this.minHeight - 1);
    this.topSlice.fillingPlane = fillingTopPlane;
    this.topSlice.gridPlane = gridTopPlane;
    this.bottomSlice.fillingPlane = fillingBottomPlane;
    this.bottomSlice.gridPlane = gridBottomPlane;
  }
}
