import { getVertexPositions } from '@App/app/engine/utils/babylon-utils/babylon.utils';
import { SketchLine } from '@App/app/entities/layer/sketch-tools/tools/sketch-line.model';
import { SketchRectangle } from '@App/app/entities/layer/sketch-tools/tools/sketch-rectangle.model';
import { Injectable } from '@angular/core';
import {
  AbstractMesh,
  ActionManager,
  ExecuteCodeAction,
  LinesMesh,
  Mesh,
  TransformNode,
  Vector3,
} from 'babylonjs';
import {
  DRAFT_SKETCH_LINE_TAG_NAME,
  DRAG_MESH_MATERIAL_NAME,
  SKETCH_LINE_BOX_NAME,
} from 'src/app/configs/babylon.config';
import { SceneService } from '../../../scene-service/scene.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';
import { SketchLineMeshUtilsService } from '../shared/sketch-line-mesh-utils.service';
import { SketchesUtilsService } from '../shared/sketches-utils.service';

@Injectable()
export class SketchLineUtilsService {
  constructor(
    private _sceneService: SceneService,
    private _sketchesUtilsService: SketchesUtilsService,
    private _viewerLayerService: ViewerLayerService,
    private _sketchLineMeshUtilsService: SketchLineMeshUtilsService,
  ) {}

  createLine(sketch: SketchLine | SketchRectangle, pointer?: Mesh): LinesMesh {
    return this._sketchLineMeshUtilsService.createLine(sketch, pointer);
  }

  /** Use if a new position is added to a path */
  finishLine(sketch: SketchLine | SketchRectangle): LinesMesh {
    return this._sketchLineMeshUtilsService.finishLine(sketch);
  }

  /** Use if a path has the same size */
  updateLine(sketch: SketchLine | SketchRectangle, pointerMesh?: Mesh): LinesMesh {
    return this._sketchLineMeshUtilsService.updateLine(sketch, pointerMesh);
  }

  registerHoverActionForMesh(mesh: Mesh): void {
    const action = new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
      this._sceneService.scene.hoverCursor = 'pointer';
    });
    mesh.actionManager = new ActionManager(this._sceneService.scene);
    mesh.actionManager.registerAction(action);
    mesh.getChildMeshes().forEach((m) => {
      m.actionManager = new ActionManager(this._sceneService.scene);
      m.actionManager.registerAction(action);
    });
  }

  vertexToMeshes(sketchLine: SketchLine, root: AbstractMesh, boxId: number): number {
    if (sketchLine.data.vertexPositions) {
      sketchLine.pointsMeshes =
        sketchLine.data.vertexPositions.map((vertexPos) => {
          const pos = new Vector3(vertexPos.x, vertexPos.y, vertexPos.z);
          const name = `${SKETCH_LINE_BOX_NAME}${++boxId}`;
          return this._sketchesUtilsService.createMesh(pos, root, name, true);
        }) || [];
    }
    return boxId;
  }

  updateSketchLinesMesh(sketchLine: SketchLine) {
    sketchLine.pointsMeshes
      .filter((pointMesh) => pointMesh.material?.name === DRAG_MESH_MATERIAL_NAME)
      .forEach(() => {
        sketchLine.linesMesh = this.updateLine(sketchLine);
      });
  }

  updateVertexPositions(sketchLine: SketchLine, draftSketchLines: SketchLine[]) {
    this.updateLine(sketchLine);
    draftSketchLines?.forEach((line) => this.updateLine(line));
    sketchLine.data.vertexPositions = getVertexPositions(sketchLine.pointsMeshes);
  }

  finishSketch(
    draftSketchLine: SketchLine,
    draftSketchLines: SketchLine[],
    draftRoot: TransformNode | null,
  ): void {
    draftSketchLine.linesMesh = this.finishLine(draftSketchLine);
    draftSketchLine.linesMesh.parent = draftRoot;
    draftSketchLine.data.closed = true;
    draftSketchLines.push({ ...draftSketchLine });
  }

  removeDraftSketch(
    draftSketchLine: SketchLine | null,
    draftSketchLines: SketchLine[],
    draftRoot: TransformNode | null,
  ): [SketchLine | null, SketchLine[], TransformNode | null] {
    if (draftSketchLines?.length) {
      draftSketchLines.forEach(({ id }) => this._viewerLayerService.deleteLayer(id));
      this._sketchesUtilsService.disposeDraftSketchNodesByTag(DRAFT_SKETCH_LINE_TAG_NAME);
    }
    draftSketchLines = [];

    if (draftSketchLine) {
      this._viewerLayerService.deleteLayer(draftSketchLine.id);
      draftSketchLine = null;
    }

    if (draftRoot) {
      draftRoot.dispose();
      draftRoot = null;
    }

    return [draftSketchLine, draftSketchLines, draftRoot];
  }

  initSphere(point: Vector3, root: TransformNode, boxId: number) {
    return this._sketchesUtilsService.createMesh(
      point,
      root,
      `${SKETCH_LINE_BOX_NAME}${boxId}`,
      true,
    );
  }

  createLinesMesh(
    boxMesh: Mesh,
    draftSketchLine: SketchLine | null,
    pointer: Mesh,
    draftRoot: TransformNode | null,
  ) {
    if (draftSketchLine?.pointsMeshes.length === 1) {
      pointer.position.copyFrom(boxMesh.position);
      this.registerHoverActionForMesh(boxMesh);
      draftSketchLine.linesMesh = this.createLine(draftSketchLine, pointer);
    } else if (draftSketchLine && draftSketchLine.pointsMeshes.length >= 2) {
      if (draftSketchLine.linesMesh) {
        draftSketchLine.linesMesh.dispose();
        draftSketchLine.linesMesh = null;
      }
      draftSketchLine.linesMesh = this.createLine(draftSketchLine, pointer);
    }
    this._setLineMeshParent(draftSketchLine, draftRoot);
  }

  private _setLineMeshParent(draftSketchLine: SketchLine | null, draftRoot: TransformNode | null) {
    if (draftSketchLine?.linesMesh) {
      draftSketchLine.linesMesh.parent = draftRoot;
    }
  }
}
