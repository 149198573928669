<form data-testId="srs-dropdown" class="gcp_dropdown__section" [formGroup]="getForm()">
  <label>SRS</label>
  <div [nbSpinner]="loading" nbSpinnerSize="tiny" class="gcp_dropdown__input">
    <input
      digitOnly
      data-testId="srs-input"
      #autoInput
      nbInput
      type="text"
      placeholder="Type EPSG code to add custom SRS"
      [nbAutocomplete]="auto"
      status="control"
      [ngClass]="{ '--loading': loading }"
    />
  </div>
  <div class="gcp__error">
    <span *ngIf="!isValid()" class="gcp__error__text">No SRS provided</span>
  </div>
</form>

<nb-autocomplete
  size="tiny"
  #auto
  [handleDisplayFn]="viewHandle"
  (selectedChange)="onSelectionChange($event)"
>
  <nb-option-group title="Default" class="gcp_dropdown__section__option_group">
    <nb-option
      *ngFor="let srs of defaultSrsList"
      [value]="srs.code"
      [disabled]="loading"
      class="gcp_dropdown__section__option_group__option"
    >
      {{ viewHandle(srs.code, false) }}
    </nb-option>
  </nb-option-group>
  <nb-option-group
    *ngIf="customSrsList.length"
    title="Custom"
    class="gcp_dropdown__section__option_group"
  >
    <nb-option
      *ngFor="let srs of customSrsList"
      [value]="srs.code"
      [disabled]="loading"
      class="gcp_dropdown__section__option_group__option"
    >
      {{ viewHandle(srs.code, false) }}
    </nb-option>
  </nb-option-group>
</nb-autocomplete>
