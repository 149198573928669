<app-layers-back-button (backClick)="onBackClick()"></app-layers-back-button>
<div class="layers__list">
  <ng-container *ngFor="let layer of layers">
    <app-layer-details
      [layer]="layer"
      [unitsSystem]="unitsSystem"
      class="layers__item_details"
      [nbSpinner]="layer | isIncludedIn: loadingLayers"
      data-testId="layers-details-layer"
    ></app-layer-details>
  </ng-container>
</div>
