import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Annotation } from '@App/app/entities/files/annotation.model';
import { Photo } from '@App/app/entities/files/files.model';
import { DownloadPhotoService } from '@App/app/shared/download-photo.service';
import { resizeImageInWrapper } from '@App/app/shared/utils/scaling.utils';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NbDialogRef, NbTrigger } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { fromEvent } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { Zoom } from '../../../../../../shared/utils/zoom.utils';
import { RendersService } from '../../../../services/renders-service/renders.service';
import { updateCurrentRenderFiles } from '../../../../store/renders/actions/renders.actions';
import { ModelPhotoCardZoomAnnotationsComponent } from './model-photo-card-zoom-annotations/model-photo-card-zoom-annotations.component';

@UntilDestroy()
@Component({
  selector: 'app-model-photo-card-zoom',
  templateUrl: './model-photo-card-zoom.component.html',
  styleUrls: ['./model-photo-card-zoom.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModelPhotoCardZoomComponent implements OnInit {
  @ViewChild('image', { static: true }) image: ElementRef<HTMLImageElement>;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLDivElement>;
  @ViewChild(ModelPhotoCardZoomAnnotationsComponent)
  private annotationsComponent: ModelPhotoCardZoomAnnotationsComponent;
  private zoom: Zoom | null = null;
  NbTrigger = NbTrigger;
  photo: Photo;
  annotations: Annotation[];
  loading = false;
  annotationsVisible = true;
  editingAnnotationsActive = false;
  isLargePhotoDisplaying = false;
  downloading = false;
  nbLayoutActive = false;
  permissions = UserPermissions;

  constructor(
    private downloadPhotoService: DownloadPhotoService,
    private dialogRef: NbDialogRef<ModelPhotoCardZoomComponent>,
    private ref: ElementRef<HTMLElement>,
    private store: Store,
    private rendersService: RendersService,
  ) {}

  ngOnInit() {
    this.rendersService.currentRender$
      .pipe(
        untilDestroyed(this),
        map((currentRender) => currentRender?.photos.find((photo) => photo.id === this.photo?.id)),
        filter<Photo>(Boolean),
      )
      .subscribe((photo) => {
        this.photo = photo;
      });

    let annotationsVisible: boolean;
    fromEvent(this.image.nativeElement, 'load')
      .pipe(
        tap(() => {
          annotationsVisible = this.annotationsVisible;
          this.annotationsVisible = false;
          resizeImageInWrapper(
            this.image.nativeElement,
            this.wrapper.nativeElement,
            this.isLargePhotoDisplaying,
          );
        }),
        delay(50),
        tap(() => {
          this.annotationsVisible = annotationsVisible;
          this.updateViewBox();
        }),
        delay(50),
        tap(() => {
          this.setZoom();
          this.annotationsComponent.updateAnnotationsUI();
        }),
      )
      .subscribe(() => {
        this.loading = false;
      });

    this.annotations = this.photo.copyAnnotations();
  }

  onToggleLargePhoto() {
    if (!this.loading) {
      this.isLargePhotoDisplaying = !this.isLargePhotoDisplaying;
      this.loading = true;
      this.zoom?.destroy();
    }
  }

  onToggleAnnotationsVisible() {
    if (!this.loading && !this.editingAnnotationsActive) {
      this.annotationsVisible = !this.annotationsVisible;
    }
  }

  onToggleEditingAnnotationActive() {
    if (!this.loading) {
      this.editingAnnotationsActive = !this.editingAnnotationsActive;
      this.wrapper.nativeElement.style.cursor = this.editingAnnotationsActive
        ? 'crosshair'
        : 'auto';
      this.annotationsVisible = true;
    }
  }

  onDownload() {
    if (!this.loading) {
      this.loading = true;
      this.downloadPhotoService.downloadPhoto(this.photo).finally(() => {
        this.loading = false;
      });
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getRef() {
    return this.ref.nativeElement;
  }

  saveAnnotations() {
    this.store.dispatch(
      updateCurrentRenderFiles({
        files: [
          {
            id: this.photo.id,
            name: this.photo.name,
            type: this.photo.type,
            data: {
              annotations: this.annotations.map((ann) => ann.toHttp()),
            },
          },
        ],
      }),
    );
  }

  private updateViewBox() {
    const { offsetWidth, offsetHeight } = this.image.nativeElement;
    const view = this.ref.nativeElement.querySelector('.a9s-annotationlayer');
    view?.setAttribute('viewBox', `0 0 ${offsetWidth} ${offsetHeight}`);
  }

  private setZoom() {
    this.zoom = new Zoom(
      this.image.nativeElement,
      this.wrapper.nativeElement,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      this.annotationsComponent.updateAnnotationsUI,
    );
  }
}
