import { UsersHttpService } from 'src/app/pages/users/users-http.service';
import { loadUser, loadUserSuccess } from './../actions/users.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Injectable()
export class LoadUserEffects {
  constructor(private actions: Actions, private usersHttpService: UsersHttpService) {}

  loadUser = createEffect(() => {
    return this.actions.pipe(
      ofType(loadUser),
      mergeMap(({ id }) => {
        return this.usersHttpService.getUser(id).pipe(
          map(
            (user) => {
              return loadUserSuccess({ user });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });
}
