import { Cuboid } from '../cuboid.model';
import { Tube } from '../tube.model';

export enum LAYER_TYPES {
  ANGLE_IRON = 'angle-iron',
  BASE_PLANE = 'base-plane',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  COORDINATES_LAYER = 'coordinates',
  CUBOID = 'cuboid',
  DROP_LINES = 'drop-line',
  FOLDERS = 'folder',
  MEASUREMENTS = 'measurement',
  POLYGONS = 'polygon',
  RECT_TUBE = 'rect-tube',
  SKETCH_PLANES = 'sketch-plane',
  SKETCH_CIRCLES = 'sketch-circle',
  SKETCH_LINES = 'sketch-line',
  SKETCH_RECTANGLE = 'sketch-rectangle',
  TUBES = 'tube',
  TUBES_PICKER = 'tube picker',
  ANNOTATION = 'annotation',
}

export enum MAPPING_LAYER_TYPES {
  ANGLE_IRON = LAYER_TYPES.ANGLE_IRON,
  CUBOID = LAYER_TYPES.CUBOID,
  RECT_TUBE = LAYER_TYPES.RECT_TUBE,
  TUBES = LAYER_TYPES.TUBES,
  TUBES_PICKER = LAYER_TYPES.TUBES_PICKER,
  ANNOTATION = LAYER_TYPES.ANNOTATION,
}

export enum LAYER_TYPES_DXF_EXPORTABLE {
  ANGLE_IRON = LAYER_TYPES.ANGLE_IRON,
  CUBOID = LAYER_TYPES.CUBOID,
  ANNOTATION = LAYER_TYPES.ANNOTATION,
  POLYGONS = LAYER_TYPES.POLYGONS,
  RECT_TUBE = LAYER_TYPES.RECT_TUBE,
  SKETCH_PLANES = LAYER_TYPES.SKETCH_PLANES,
  SKETCH_CIRCLES = LAYER_TYPES.SKETCH_CIRCLES,
  SKETCH_RECTANGLE = LAYER_TYPES.SKETCH_RECTANGLE,
  SKETCH_LINES = LAYER_TYPES.SKETCH_LINES,
  TUBES = LAYER_TYPES.TUBES,
}

export enum LAYER_WITH_THICKNESS {
  ANGLE_IRON = LAYER_TYPES.ANGLE_IRON,
  RECT_TUBE = LAYER_TYPES.RECT_TUBE,
  TUBE = LAYER_TYPES.TUBES,
}

export enum RECT_LAYER_TYPES {
  CUBOID = LAYER_TYPES.CUBOID,
  RECT_TUBE = LAYER_TYPES.RECT_TUBE,
}

export enum LAYER_TYPES_AUTO_MAPPING_IMPORTABLE {
  CUBOID = LAYER_TYPES.CUBOID,
  TUBE = LAYER_TYPES.TUBES,
}

export type AUTOMAPPING_IMPORTABLE_LAYER = Cuboid | Tube;

export enum LAYER_EXPANDABLE_TYPES {
  SKETCH_PLANES = 'sketch-plane',
}

export enum POINTER_INFO_BUTTONS {
  LEFT,
  MIDDLE,
  RIGHT,
}

export enum LAYER_EVENTS {
  NULL = 'NULL',
  ADD_ANGLE_IRON = 'ADD_ANGLE_IRON',
  ADD_BASE_PLANE = 'ADD_BASE_PLANE',
  ADD_BOUNDING_BOX = 'ADD_BOUNDING_BOX',
  ADJUST_BOUNDING_BOX = 'ADJUST_BOUNDING_BOX',
  ADD_COORDINATES_LAYER = 'ADD_COORDINATES_LAYER',
  ADD_CUBOID = 'ADD_CUBOID',
  ADD_DROP_LINE = 'ADD_DROP_LINE',
  ADD_MEASUREMENT = 'ADD_MEASUREMENT',
  ADD_POLYGON = 'ADD_POLYGON',
  ADD_RECT_TUBE = 'ADD_RECT_TUBE',
  ADD_SKETCH_CIRCLE = 'ADD_SKETCH_CIRCLE',
  ADD_SKETCH_LINE = 'ADD_SKETCH_LINE',
  ADD_SKETCH_PLANE = 'ADD_SKETCH_PLANE',
  ADD_SKETCH_RECTANGLE = 'ADD_SKETCH_RECTANGLE',
  ADD_SLICE_PLANE = 'ADD_SLICE_PLANE',
  ADD_TUBE = 'ADD_TUBE',
  ADD_TUBE_PICKER = 'ADD_TUBE_PICKER',
  ADD_CUBOID_PICKER = 'ADD_CUBOID_PICKER',
  EDIT_POLYGON = 'EDIT_POLYGON',
  FIND_CUBOID_NORMAL = 'FIND_CUBOID_NORMAL',
  FIND_RECT_TUBE_NORMAL = 'FIND_RECT_TUBE_NORMAL',
  ADD_ANNOTATION = 'ADD_ANNOTATION',
}

/**
 * Mapping Tools Configs
 */

// Tube Config
export enum TUBE_PART {
  NONE,
  ENDPOINT_1_OUTER,
  ENDPOINT_1_INNER,
  ENDPOINT_2_OUTER,
  ENDPOINT_2_INNER,
}

export type TUBE_PART_STRICT = Exclude<TUBE_PART, TUBE_PART.NONE>;

export enum TUBE_PART_NAME {
  'OUTER 1' = TUBE_PART.ENDPOINT_1_OUTER,
  'INNER 1' = TUBE_PART.ENDPOINT_1_INNER,
  'OUTER 2' = TUBE_PART.ENDPOINT_2_OUTER,
  'INNER 2' = TUBE_PART.ENDPOINT_2_INNER,
}

// Cuboid Config
export enum CUBOID_PART {
  NONE,
  FRONT_BACK,
  TOP_BOTTOM,
  LEFT_RIGHT,
}

export type CUBOID_PART_STRICT = Exclude<CUBOID_PART, CUBOID_PART.NONE>;

export enum CUBOID_PART_NAME {
  'FRONT/BACK' = CUBOID_PART.FRONT_BACK,
  'TOP/BOTTOM' = CUBOID_PART.TOP_BOTTOM,
  'LEFT/RIGHT' = CUBOID_PART.LEFT_RIGHT,
}

// AngleIron Config
export enum ANGLE_IRON_PART {
  NONE,
  SIDE_1_OUTER,
  SIDE_1_INNER,
  SIDE_2_OUTER,
  SIDE_2_INNER,
}

export type ANGLE_IRON_STRICT = Exclude<ANGLE_IRON_PART, ANGLE_IRON_PART.NONE>;

export enum ANGLE_IRON_PART_NAME {
  'OUTER 1' = ANGLE_IRON_PART.SIDE_1_OUTER,
  'INNER 1' = ANGLE_IRON_PART.SIDE_1_INNER,
  'OUTER 2' = ANGLE_IRON_PART.SIDE_2_OUTER,
  'INNER 2' = ANGLE_IRON_PART.SIDE_2_INNER,
}

// RectTube Config
export enum RECT_TUBE_PART {
  NONE,
  SURFACE,
  ADJACENT_SURFACE,
  INNER,
}

export type RECT_TUBE_STRICT = Exclude<RECT_TUBE_PART, RECT_TUBE_PART.NONE>;

export enum RECT_TUBE_PART_NAME {
  'SURFACE' = RECT_TUBE_PART.SURFACE,
  'ADJACENT SURFACE' = RECT_TUBE_PART.ADJACENT_SURFACE,
  'INNER' = RECT_TUBE_PART.INNER,
}
