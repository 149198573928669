import { Injectable } from '@angular/core';
import { BaseFile, FileHttp } from '@App/app/entities/files/files.model';
import { ApiService } from '@App/app/shared/api.service';

@Injectable({
  providedIn: 'root',
})
export class ModelFilesHttpService extends ApiService {
  getModelFiles(modelId: number) {
    return this.http.get<BaseFile[]>(`${this.api}/models/${modelId}/files`);
  }

  postModelFiles(modelId: number, files: FileHttp[]) {
    return this.http.post<BaseFile[]>(`${this.api}/models/${modelId}/files`, files);
  }

  deleteModelFiles(modelId: number, ids: number[]) {
    return this.http.delete(`${this.api}/models/${modelId}/files`, {
      body: ids.map((id) => ({ id })),
    });
  }

  getModelThumbnails() {
    return this.http.get<BaseFile[]>(`${this.api}/model-thumbnails`);
  }
}
