import { httpLayer } from '@App/app/entities/layer/layer.model';
import { sketchPlanePredicate } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { SketchCircle } from '@App/app/entities/layer/sketch-tools/tools/sketch-circle.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { ExecuteCodeAction, Mesh, PointerDragBehavior, StandardMaterial, Vector3 } from 'babylonjs';
import { PickingInfo } from 'babylonjs/Collisions/index';
import { DRAFT_SKETCH_CIRCLE_TAG_NAME } from 'src/app/configs/babylon.config';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { BabylonNodesService as NodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../../material-service/material.service';
import { SceneService } from '../../../scene-service/scene.service';
import { UtilsService } from '../../../utils-service/utils.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';
import { SketchToolService } from '../shared/sketch-tool.service';
import { SketchesUtilsService } from '../shared/sketches-utils.service';
import { SketchCircleActionsService } from './sketch-circle-actions.service';

@Injectable()
export class SketchCircleService extends SketchToolService<SketchCircle> {
  handleTakingEvent = EVENT_TYPE.TAKE_SKETCH_CIRCLE;
  /** Allows clicking only on specific plane */
  predicate: sketchPlanePredicate = () => false;

  constructor(
    private sketchCircleActionsService: SketchCircleActionsService,
    broadcastService: BroadcastService,
    materialService: MaterialService,
    sketchesUtilsService: SketchesUtilsService,
    utilsService: UtilsService,
    babylonNodesService: NodesService,
    sceneService: SceneService,
    viewerLayerService: ViewerLayerService,
  ) {
    super(
      broadcastService,
      materialService,
      sketchesUtilsService,
      utilsService,
      babylonNodesService,
      sceneService,
      viewerLayerService,
    );
  }

  setMeshesDragBehavior(sketch: SketchCircle): void {
    const [plane, pick] = [this.currentSketchPlane, this.onPickAction];
    this.sketchCircleActionsService.setCenterMeshDragBehavior(sketch, plane, pick);
    this.sketchCircleActionsService.setEdgeMeshDragBehavior(sketch, plane, pick);
  }

  getDraftSketches(): SketchCircle[] {
    const result: httpLayer[] = [];
    if (this.draftSketches && this.draftSketches.length) {
      const createHttp = SketchCircle.createBodyForHttpRequest;
      this.draftSketches.forEach((circle) => result.push(createHttp(circle)));
      this.sketchesUtilsService.disposeDraftSketchNodesByTag(DRAFT_SKETCH_CIRCLE_TAG_NAME);
      this.draftSketches = [];
      this.currentSketchPlane = null;
      this.removeDraftSketch();
    }
    return result as SketchCircle[];
  }

  onPickAction = (dragBehavior: PointerDragBehavior, draggableMaterial: StandardMaterial) => {
    return new ExecuteCodeAction(
      {
        trigger: BABYLON.ActionManager.OnPickTrigger,
        parameter: {},
      },
      (e) => {
        this.draftSketches?.forEach((draftSketch) =>
          [draftSketch.centerMesh, draftSketch.centerMesh].filter(Boolean).forEach((mesh: Mesh) => {
            mesh.behaviors.forEach((behavior) => mesh.removeBehavior(behavior));
            this.setStandardMaterial(mesh);
          }),
        );
        if (this.currentSketchPlane) {
          const { id } = this.currentSketchPlane;
          this.broadcastService.broadcast(EVENT_TYPE.MESH_RESTORE_DEFAULT, id);
        }
        this.handleDragBehavior(e, dragBehavior, draggableMaterial);
      },
    );
  };

  removeDraftSketch() {
    if (this.draftSketches?.length) {
      this.draftSketches.forEach((c) => this.sketchesUtilsService.deleteDraftSketch(c));
      this.draftSketches = [];
    }
    if (this.draftSketch) {
      this.removeUnfinishedDraftSketch();
    }
  }

  removeUnfinishedDraftSketch() {
    this._removeUnfinishedCircle();
    this.removePointerMeshEventListener();
  }

  showSketch(sketchCircle: SketchCircle): SketchCircle {
    return this.sketchCircleActionsService.showSketch(sketchCircle);
  }

  finishSketch(pickedPoint: Vector3) {
    this.removePointerMeshEventListener();
    const [circle, circles] = [this.draftSketch, this.draftSketches];
    const [plane, pick] = [this.currentSketchPlane, this.onPickAction];
    this.sketchCircleActionsService.initializeFirstEdgeMesh(pickedPoint, circle, circles);
    // updating already created edgeMesh
    this.sketchCircleActionsService.finishCircle(circle, pickedPoint, plane, pick);
    this.draftSketch = null;
  }

  onClickAction() {
    if (!this.currentSketchPlane) {
      return false;
    }
    const hit = this.utilsService.pickRay(this.predicate);
    if (hit?.pickedPoint) {
      if (this.draftSketch) {
        this.finishSketch(hit.pickedPoint);
        return true;
      }
      this._removeUnfinishedCircle();
      this.draftSketch = this.sketchCircleActionsService.initDraftCircle(
        hit.pickedPoint,
        this.currentSketchPlane,
        this.onPickAction,
      );
      this.addPointerMeshEventListener();
    }
    return true;
  }

  handleMouseMove = () => {
    const hit = this.utilsService.pickRay(this.predicate);
    if (hit?.pickedPoint) {
      const [point, circle, plane] = [hit.pickedPoint, this.draftSketch, this.currentSketchPlane];
      this.sketchCircleActionsService.updateDraftCircleByPoint(circle, point, plane);
    }
  };

  handleTakingSketch(): void {
    this._removeUnfinishedCircle();
  }

  handleClickAction(_: PickingInfo): void {}

  // remove unfinished circle. happens when the user creates circle on click
  // and then makes a click again instead of finish it with right click
  private _removeUnfinishedCircle() {
    if (this.draftSketch && !this.draftSketch.edgeMesh) {
      this.sketchesUtilsService.deleteDraftSketch(this.draftSketch);
      this.draftSketch = null;
    }
  }
}
