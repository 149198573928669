<nb-card class="models_map__content_card">
  <div data-testId="models-map" id="map"></div>
  <div class="models_map__pane">
    <button nbTooltip="Fit the map extent to points" (click)="onResetMap()">
      <svg-icon [src]="targetIcon"></svg-icon>
    </button>
  </div>
  <div class="models_map__selector">
    <nb-select
      placeholder="Select Base Map"
      size="small"
      (selectedChange)="onBaseMapChange($event)"
      [(selected)]="defaultBaseMapIndex"
      data-testId="base-map-select"
    >
      <nb-option *ngFor="let baseMap of baseMaps; let i = index" [value]="i">{{
        baseMap.name
      }}</nb-option>
    </nb-select>
  </div>
  <div *ngIf="showStatisticsButton$ | async" class="models_map__download_analytics_btn_wrapper">
    <app-download-analytics-button></app-download-analytics-button>
  </div>
</nb-card>
