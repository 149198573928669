import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { AUTOMAPPING_IMPORTABLE_LAYER } from '@App/app/entities/layer/enums/layer-types.enum';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LayerService } from '../../../../engine/services/layer-services/layer-service/layer.service';
import { LayerUI } from '../../../../entities/layer/layer-ui.model';
import { selectVisibleLayers } from '../../store/selectors/layers.selectors';
import { getLayerParents } from '../../utils/layers.utils';
import { LayersHttpActionsService } from '../layers-http-actions-service/layers-http-actions.service';
import { LayersUtilsService } from '../layers-utils-service/layers-utils.service';
import { LayersViewerUtilsService } from '../layers-viewer-utils-service/layers-viewer-utils.service';
import { UnitsService } from '../utils/units.service';

@UntilDestroy()
@Injectable()
export class LayersService {
  private _defaultFolderId$ = new BehaviorSubject<number>(-1);
  defaultFolderId$ = this._defaultFolderId$.asObservable();
  private _loadingLayers$ = new BehaviorSubject<LayerUI[]>([]);
  loadingLayers$ = this._loadingLayers$.asObservable();
  private _detailsViewLayers$ = new BehaviorSubject<LayerUI[]>([]);
  detailsViewLayers$ = this._detailsViewLayers$.asObservable();
  private _editingFolder$ = new BehaviorSubject<LayerUI | null>(null);
  editingFolder$ = this._editingFolder$.asObservable();
  private _recalculatedLayers$ = new BehaviorSubject<LayerUI[]>([]);
  recalculatedLayers$ = this._recalculatedLayers$.asObservable();
  private _selectedLayers$ = new BehaviorSubject<LayerUI[]>([]);
  selectedLayers$ = this._selectedLayers$.asObservable();
  private _folders$ = new BehaviorSubject<LayerUI[]>([]);
  folders$ = this._folders$.asObservable();
  private _isBasePlaneCreated$ = new BehaviorSubject<boolean>(true);
  isBasePlaneCreated$ = this._isBasePlaneCreated$.asObservable();
  private _isCreatingFolderActive$ = new BehaviorSubject<boolean>(false);
  isCreatingFolderActive$ = this._isCreatingFolderActive$.asObservable();
  private _layers$ = new BehaviorSubject<LayerUI[]>([]);
  layers$ = this._layers$.asObservable();
  private _visibleLayers$ = new BehaviorSubject<LayerUI[]>([]);
  visibleLayers$ = this._visibleLayers$.asObservable();
  editedLayers: LayerUI[] = [];
  editedNamesLayers: { [id: string]: string | null } = {};
  isEditModeOnInitMappingTool = false;
  loading$ = this._layersHttpActionsService.loading$;

  constructor(
    private _layerService: LayerService,
    private _permissionsService: NgxPermissionsService,
    private _unitsService: UnitsService,
    private _layersHttpActionsService: LayersHttpActionsService,
    private _layersUtilsService: LayersUtilsService,
    private _layersViewerUtilsService: LayersViewerUtilsService,
    private store: Store,
  ) {
    this.store
      .select(selectVisibleLayers)
      .pipe(delay(10), untilDestroyed(this))
      .subscribe((layers) => this._visibleLayers$.next(layers));
  }

  initLayersUI() {
    this._layerService.layers.pipe(untilDestroyed(this)).subscribe((layers: LayerUI[]) => {
      const { _editingFolder$: ed, _selectedLayers$: sel, _detailsViewLayers$: det } = this;
      const res = this._layersUtilsService.getNewLayersSet(layers, ed.value, sel.value, det.value);
      this._isBasePlaneCreated$.next(res.isBasePlaneCreated);
      this._folders$.next(res.folders);
      this._layers$.next(res.layers);
      this._selectedLayers$.next(res.selectedLayers);
      this._detailsViewLayers$.next(res.detailsViewLayers);
      if (res.defaultFolderId) this._defaultFolderId$.next(res.defaultFolderId);
      if (res.visibleLayers) this._visibleLayers$.next(res.visibleLayers);
      if (this._editingFolder$.value) this._editingFolder$.next(res.editingFolder);
    });
  }

  destroyLayersUI() {
    this._loadingLayers$.next([]);
    this._detailsViewLayers$.next([]);
    this._visibleLayers$.value.forEach((layer) => this.setLayerVisibility(layer, false));
    this._visibleLayers$.next([]);
    this._folders$.next([]);
    this._defaultFolderId$.next(-1);
    this._layers$.next([]);
    this._unitsService.setImperialUnitsActive(false);
    this._editingFolder$.next(null);
    this._isCreatingFolderActive$.next(false);
    this._isBasePlaneCreated$.next(false);
  }

  setLoadingLayers(layers: LayerUI[]) {
    this._loadingLayers$.next(layers);
  }

  setRecalculatedLayers(layers: LayerUI[]) {
    this._recalculatedLayers$.next(layers);
  }

  setDetailsViewLayers(layers: LayerUI[]) {
    this._detailsViewLayers$.next(layers);
  }

  setLoading(value: boolean) {
    this._layersHttpActionsService.setLoading(value);
  }

  setEditingFolder(folder: LayerUI | null) {
    this._editingFolder$.next(folder);
  }

  setVisibleLayers(layers: LayerUI[]) {
    this._visibleLayers$.next(layers);
  }

  setCreatingFolderActive(value: boolean) {
    this._isCreatingFolderActive$.next(value);
  }

  setSelectedLayers(layers: LayerUI[]) {
    this._selectedLayers$.next(layers);
  }

  importLayers(layers: AUTOMAPPING_IMPORTABLE_LAYER[]) {
    this._layersHttpActionsService.importLayers(layers);
  }

  getLayerParents(layer: LayerUI) {
    return getLayerParents(layer, this._folders$.value);
  }

  saveLayer(layer: LayerUI) {
    this._layersHttpActionsService.saveLayer(layer);
    this.deleteLayer(layer);
  }

  createBackupLayer(layer: LayerUI) {
    this._layersViewerUtilsService.createBackupLayer(layer);
  }

  loadBackupLayer(id: number) {
    this._layersViewerUtilsService.loadBackupLayer(id);
  }

  setLayerEditMode(layer: LayerUI, value: boolean, pushToEdited = true) {
    const [{ editedLayers: edited }, allLayers] = [this, this._layers$.value];
    this._layersViewerUtilsService.setLayerEditMode(layer, value, allLayers, edited, pushToEdited);
  }

  setLayerGuiVisibility(id: number, value: boolean) {
    this._layersViewerUtilsService.setLayerGuiVisibility(id, value);
  }

  setLayerVisibility(layer: LayerUI, value: boolean) {
    this._layersViewerUtilsService.setLayerVisibility(layer, value, this._layers$.value);
  }

  updateLayer(layer: LayerUI, children?: LayerUI[]) {
    if (!!this._permissionsService.getPermission(UserPermissions.CAN_EDIT_LAYER)) {
      this._setLayerAsLoading(layer);
      this._layersHttpActionsService.updateLayer(layer, children);
    }
  }

  changeLayerName(name: string, layer: LayerUI) {
    this._setLayerAsLoading(layer);
    this._layersHttpActionsService.changeLayerName(name, layer);
  }

  changeLayersFolder(layerIds: number[], folderId: number) {
    this._layersHttpActionsService.changeLayersFolder(layerIds, folderId, this._layers$.value);
  }

  deleteLayer(layer: LayerUI) {
    if (!!this._permissionsService.getPermission(UserPermissions.CAN_DELETE_LAYER)) {
      this._setLayerAsLoading(layer);
      const [layers, folders] = [this._layers$.value, this._folders$.value];
      this._layersUtilsService.deleteLayerFromViewer(layer, layers, folders);
    }
  }

  deleteLayers(layers: LayerUI[]) {
    if (!!this._permissionsService.getPermission(UserPermissions.CAN_DELETE_LAYER)) {
      this._layersHttpActionsService.setLoading(true);
      this._layersHttpActionsService.deleteLayers(layers);
    }
  }

  clearDetailsViewLayers() {
    this._layersUtilsService.clearDetailsView(this._detailsViewLayers$.value, this.editedLayers);
    this._detailsViewLayers$.next([]);
    this.editedLayers = [];
  }

  private _setLayerAsLoading(layer: LayerUI) {
    this._loadingLayers$.next([...this._loadingLayers$.value, layer]);
  }
}
