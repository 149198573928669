import { Photo } from '@App/app/entities/files/files.model';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { DownloadPhotoService } from 'src/app/shared/download-photo.service';
import { PhotosService } from './../services/photos/photos.service';
import { PhotosCarouselImageComponent } from './photos-carousel-image/photos-carousel-image.component';

@UntilDestroy()
@Component({
  selector: 'app-photos-carousel',
  templateUrl: './photos-carousel.component.html',
  styleUrls: ['./photos-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PhotosCarouselComponent implements AfterViewInit {
  @ViewChild('carousel', { read: ElementRef }) private carousel: ElementRef<HTMLElement>;
  @ViewChildren(PhotosCarouselImageComponent)
  private children: QueryList<PhotosCarouselImageComponent>;
  @ViewChildren(PhotosCarouselImageComponent, { read: ElementRef })
  private childElements: QueryList<ElementRef<HTMLElement>>;
  photos: Photo[] = [];
  downloadingPhotoIds: number[] = [];
  activePhotoId: number;
  activePhotoIndex: number;

  constructor(
    private photosService: PhotosService,
    private downloadPhotoService: DownloadPhotoService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.photosService.photos$.pipe(untilDestroyed(this)).subscribe((photos: Photo[]) => {
      this.photos = photos;
      this.calculatePhotosToShow();
    });

    this.photosService.activePhotoId$
      .pipe(untilDestroyed(this))
      .pipe(filter(Boolean))
      .subscribe((id: number) => {
        this.activePhotoId = id;
        this.calculatePhotosToShow();
      });

    this.photosService.activePhotoIndex$.subscribe((index: number) => {
      this.activePhotoIndex = index;
    });

    this.calculatePhotosToShow();

    fromEvent(this.carousel.nativeElement, 'scroll')
      .pipe(untilDestroyed(this))
      .pipe(debounceTime(100))
      .subscribe(() => this.calculatePhotosToShow());

    this.setFirstPhoto();
  }

  selectPhoto(photoId: number) {
    const activeIndex = this.photos.findIndex((photo) => photo.id === photoId);
    const correctIndex = this.photos.slice(activeIndex).find((photo) => photo.validate());
    if (correctIndex) {
      this.photosService.setActivePhotoIndex(this.photos.indexOf(correctIndex));
      this.photosService.setActivePhoto(+photoId, true);
    }
  }

  setFirstPhoto() {
    const correctPhotos = this.photos.filter((photo) => photo.data.rotation);
    this.activePhotoIndex = 0;
    this.cdr.detectChanges();
    this.photosService.setActivePhoto(correctPhotos[0].id);
  }

  closePhoto(photoId: number) {
    this.photosService.closeActivePhoto(photoId);
  }

  openPhoto(photoId: number) {
    this.photosService.setActivePhoto(photoId, true);
  }

  centerPhoto(id: number) {
    document.getElementById(id.toString())?.scrollIntoView({ inline: 'center' });
  }

  prev() {
    if (this.activePhotoId) {
      const currentIndex = this.photos.findIndex((photo) => +photo.id === this.activePhotoId);
      const prevPhoto = this.photos
        .slice(0, currentIndex)
        .reverse()
        .find((photo) => photo.validate());

      if (prevPhoto) {
        this.activePhotoIndex = this.photos.indexOf(prevPhoto);
        this.photosService.setActivePhoto(+prevPhoto.id);
        this.centerPhoto(+prevPhoto.id);
      }
    }
  }

  next() {
    if (this.activePhotoId) {
      const currentIndex = this.photos.findIndex((photo) => +photo.id === this.activePhotoId);
      const nextPhoto = this.photos.slice(currentIndex + 1).find((photo) => photo.validate());

      if (nextPhoto) {
        this.activePhotoIndex = this.photos.indexOf(nextPhoto);
        this.photosService.setActivePhoto(+nextPhoto.id);
        this.centerPhoto(+nextPhoto.id);
      }
    }
  }

  download(photoId: number) {
    this.downloadingPhotoIds.push(photoId);
    const photo = this.photos.find((item) => +item.id === photoId);
    if (photo) {
      this.downloadPhotoService.downloadPhoto(photo).then(() => {
        this.downloadingPhotoIds = this.downloadingPhotoIds.filter((id) => id !== photoId);
      });
    }
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault();
    if (this.carousel) {
      this.carousel.nativeElement.scrollLeft += event.deltaY + event.deltaX;
    }
  }

  private calculatePhotosToShow() {
    this.cdr.detectChanges();
    for (const [index, element] of [...this.childElements].entries()) {
      if (this.checkPhotoElementIsVisibleInScreen(element.nativeElement)) {
        this.children.get(index)?.startLoadingIfNotStarted();
      }
    }
    this.cdr.detectChanges();
  }

  private checkPhotoElementIsVisibleInScreen(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth);
    return rect.right > 0 && rect.left < viewWidth;
  }
}
