/* eslint-disable max-lines */
import { ViewerAngleIronLayer } from '@App/app/entities/layer/angle-iron.model';
import { createGui } from '@App/app/entities/layer/create-gui.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { Layer } from '@App/app/entities/layer/layer.model';
import { ViewerTubeLayer } from '@App/app/entities/layer/tube.model';
import { ThicknessNumberValidatorData } from '@App/app/entities/shared/thickness-number-validator-data';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Injector } from '@angular/core';
import { Vector3 } from 'babylonjs';
import { ViewerCuboidLayer } from '../../entities/layer/cuboid.model';
import { ViewerPolygonLayer } from '../../entities/layer/polygons/polygon.model';
import { getCameraTargetDataByMesh } from '../helpers/layers-helpers';
import { ViewerLayer } from './viewer-layer';

export abstract class BaseViewerLayer implements ViewerLayer {
  protected layerUtilsService: createGui;
  backupLayer: LayerUI | LayerUiExpandable | null;
  layerViewer: Layer;
  hasGuiToggler = true;

  constructor(protected injector: Injector) {}

  showLayer(layer: LayerUI | LayerUiExpandable) {}

  hideLayer() {
    this.deleteLayer();
  }

  setParentId(id: number) {
    this.layerViewer.parentId = id;
  }

  showLayerGui() {
    if (this.layerViewer && this.hasGuiToggler) {
      this.layerViewer.gui = this.layerUtilsService.createGui(this.layerViewer);
    }
  }

  hideLayerGui() {
    if (this.layerViewer) {
      if (this.layerViewer.gui) {
        this.layerViewer.gui.bg?.dispose();
        this.layerViewer.gui.arrow?.dispose();
        this.layerViewer.gui.text?.dispose();
        this.layerViewer.gui.bg = null;
        this.layerViewer.gui.arrow = null;
        this.layerViewer.gui.text = null;
        this.layerViewer.gui = null;
      }
    }
  }

  changeLayerViewerName(name: string) {
    if (this.layerViewer) {
      this.layerViewer.name = name;
    }
    if (this.backupLayer) {
      this.backupLayer.name = name;
    }
  }

  changeLayerNameOnGui(text: string) {
    if (this.layerViewer) {
      const { gui } = this.layerViewer;
      if (gui?.text) {
        gui.text.text = text;
      }
    }
  }

  deleteLayer() {}

  getThicknessFormControls(unitsSystem: UnitsSystem): ThicknessNumberValidatorData[] {
    return [];
  }

  updateThickness(value: number, fieldName?: string) {}

  createBackupLayer(layer: LayerUI | LayerUiExpandable) {
    this.backupLayer = { ...layer, data: { ...layer.data } };
  }

  hasActiveLayerViewer() {
    return !!this.layerViewer;
  }

  loadBackupLayer() {
    this.hideLayer();
    if (this.backupLayer) {
      this.showLayer(this.backupLayer);
    }
    this.backupLayer = null;
  }

  setLayerEditMode(value: boolean, id?: number) {}

  getData() {
    return this.layerViewer?.data;
  }

  getBackupData() {
    return this.backupLayer?.data;
  }

  getCameraTargetData(): { center: Vector3; radius: number } | undefined {
    return { center: Vector3.Zero(), radius: 1 };
  }

  toggleImperialGui(isImperial: boolean) {}

  checkGuiIsVisible() {
    return !!(this.layerViewer && this.layerViewer.gui);
  }
}

export abstract class dxfExportViewerLayer extends BaseViewerLayer {
  layerViewer: ViewerAngleIronLayer | ViewerCuboidLayer | ViewerPolygonLayer | ViewerTubeLayer;

  deleteLayer() {
    if (this.layerViewer) {
      this.layerViewer.mesh?.dispose();
      delete this.layerViewer.mesh;
      this.layerViewer.gizmoManager?.dispose();
      delete this.layerViewer.gizmoManager;
      if ((this.layerViewer as any).centerLine) {
        (this.layerViewer as any).centerLine.dispose();
        (this.layerViewer as any).centerLine = null;
      }
      this.hideLayerGui();
    }
  }

  getCameraTargetData(): { center: Vector3; radius: number } | undefined {
    if (this.layerViewer.mesh) {
      return getCameraTargetDataByMesh(this.layerViewer.mesh);
    }
  }
}
