<app-control-point-table title="Measurements">
  <nb-list data-testid="measurements-list">
    <nb-list-item *ngIf="!measurements.length" class="list-item"
      ><div class="list-item__wrapper">No measurements</div></nb-list-item
    >
    <nb-list-item *ngFor="let measurement of measurements" class="list-item">
      <div class="list-item__wrapper measurement-list-item">
        <span class="list-item__name">{{ measurement.imageName }}</span>
        <span class="list-item__coords">
          <span class="list-item__coords__item">x: {{ measurement.pos.x }}</span>
          <span class="list-item__coords__item">y: {{ measurement.pos.y }}</span>
        </span>
        <button
          nbButton
          size="tiny"
          (click)="showOnGrid($event, measurement.imageName)"
          nbTooltip="Show measurement"
          ghost
          class="list-item__button list-item__button--eye"
        >
          <div>
            <nb-icon icon="eye-outline"></nb-icon>
          </div>
        </button>
        <button
          nbButton
          size="tiny"
          (click)="onDeleteMeasurement(measurement)"
          nbTooltip="Delete measurement"
          ghost
          class="list-item__button list"
          data-testId="measurement-delete"
        >
          <div>
            <nb-icon icon="trash-2-outline"></nb-icon>
          </div>
        </button>
      </div>
    </nb-list-item>
  </nb-list>
</app-control-point-table>
