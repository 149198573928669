import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SelectedCompanyGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const urlParts = state.url.split('/');
    const companyIdFromUrl = +urlParts[1];
    this.redirectToModelsIsNecessary(urlParts);
    const currentEndpoint = this.authService.getCurrentEndpoint();
    if (currentEndpoint?.id === companyIdFromUrl) {
      return true;
    } else if (this.authService.getAvailableEndpoints().length > 1) {
      const newEndpoint = this.authService
        .getAvailableEndpoints()
        .find((endpoint: Endpoint) => endpoint.id === companyIdFromUrl);
      const hasPermission = newEndpoint?.userRoles.flatMap((role) =>
        role.permissions.map((permission) => permission.name),
      ).length;
      if (newEndpoint && hasPermission) {
        this.authService.setCurrentEndpoint(newEndpoint);
        return true;
      } else {
        this.router.navigate(['/company-selection']);
      }
    } else {
      this.authService.onLogout();
    }
    return false;
  }

  redirectToModelsIsNecessary(urlParts: string[]) {
    if (urlParts.length < 3) {
      this.router.navigate([`/${Number(urlParts[1])}/models`]);
    }
  }
}
