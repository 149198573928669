import { NebularModule } from '@App/app/modules';
import { ProcessingTableFiltersService } from '@App/app/pages/sites/processing-site/processing-table-filters/processing-table-filters.service';
import { UsersTableFiltersService } from '@App/app/pages/sites/users-site/users-table-filters/users-table-filters.service';
import { ModelSiteHeaderModule } from '@App/app/shared/components/model-site-header/model-site-header.module';
import { SharedModule } from '@App/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonGroupModule } from '@nebular/theme';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Ng5SliderModule } from 'ng5-slider';
import { LightboxModule } from 'ngx-lightbox';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModelsRoutingModule } from '../models/models-routing.module';
import { ProcessesSitesService } from '../sites/shared/site-services/processes-sites.service';
import { ModelProcessingDetailsComponent } from './model-processing-details/model-processing-details.component';

@NgModule({
  declarations: [ModelProcessingDetailsComponent],
  imports: [
    AngularSvgIconModule.forRoot(),
    CommonModule,
    ModelsRoutingModule,
    HttpClientModule,
    NgxDatatableModule,
    NbButtonGroupModule,
    FormsModule,
    ReactiveFormsModule,
    NebularModule,
    Ng5SliderModule,
    NgxPaginationModule,
    LightboxModule,
    SharedModule,
    ModelSiteHeaderModule,
  ],
  providers: [ProcessesSitesService, ProcessingTableFiltersService, UsersTableFiltersService],
})
export class ProcessingModule {}
