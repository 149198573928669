import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const isNullOrHasBothDates = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (value && !(value.start && value.end)) {
      return {
        noEndDate: true,
      };
    }
    return null;
  };
};
