import { Model, ModelDates } from '@App/app/entities/models/model.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { DateRange } from '@App/app/entities/shared/advanced-search.model';
import { Duration, IModelDuration } from '@App/app/entities/shared/duration.model';
import { User } from '@App/app/entities/users/user';
import { TableColumnProp } from '@swimlane/ngx-datatable';

export const isAtLeastOneValueTrue = (element: { [name: string]: boolean | null }) => {
  return Object.values(element).some((value) => value);
};

export const filterDateRange = (
  items: (BuildProcess | Model)[],
  dateRange: DateRange | null,
  type: keyof ModelDates | keyof Duration,
) => {
  if (dateRange) {
    // eslint-disable-next-line complexity
    return items.filter((item) => {
      const modelDates = (item as Model)[type as keyof IModelDuration];
      const buildProcessDates = (item as BuildProcess)[type as keyof Duration];
      return checkDateWithinRange(
        new Date(dateRange.start),
        new Date(dateRange.end),
        item.hasOwnProperty('flown' || 'processed') && (modelDates || buildProcessDates)
          ? new Date(
              item.hasOwnProperty('flown' || 'processed')
                ? (modelDates as string)
                : (buildProcessDates as string),
            )
          : undefined,
      );
    });
  }
  return items;
};

export const checkDateWithinRange = (startDate: Date, endDate: Date, date?: Date) => {
  return date
    ? createDate(startDate) <= createDate(date) && createDate(date) <= createDate(endDate)
    : false;
};

export const updateByQuery = (
  elements: (User | BuildProcess | Model)[],
  query: string | null,
  elementKeys: TableColumnProp[],
) => {
  if (query) {
    const val = query.toLowerCase();
    return elements.filter((elem) => {
      const valuesToCheck = getValues(elem, elementKeys);
      return valuesToCheck.some((value) => value.toLowerCase().indexOf(val) !== -1);
    });
  }
  return elements;
};

export const resetFilter = (elemToClear: Partial<{ [x: string]: boolean | null }>) => {
  for (const item in elemToClear) {
    if (elemToClear.hasOwnProperty(item)) {
      elemToClear[item] = false;
    }
  }
};

const getValues = (elem: User | BuildProcess | Model, elemKeys: TableColumnProp[]) => {
  const elemKeysList = elemKeys
    .map((prop) => (prop ? (prop as string).split('.') : null))
    .filter((listOfStrings): listOfStrings is string[] => listOfStrings !== null);
  return getElemValuesFromKeysList(elem, elemKeysList);
};

const getElemValuesFromKeysList = (elem: User | BuildProcess | Model, elemKeysList: string[][]) => {
  const result: string[] = [];
  elemKeysList.forEach((keys) => {
    if (keys) {
      let tmpData = elem;
      keys.forEach((key) => {
        tmpData = (tmpData as any)[key];
      });
      if (tmpData) {
        result.push(tmpData.toString());
      }
    }
  });
  return result;
};

const createDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
