import { ApiService } from '@App/app/shared/api.service';
import { Injectable } from '@angular/core';
import { TiePoint } from '../../../../entities/files/tie-point-model';

@Injectable({
  providedIn: 'root',
})
export class TiePointsHttpService extends ApiService {
  download(tiePointsPath: string) {
    return this.http.get<TiePoint[] | null>(tiePointsPath, {
      /**
       * the file can be very large so that http client can't
       * load it and throws null instead. This is why the
       * response is typed as points list or null
       */
      headers: {
        notLogOutOn403: 'true',
      },
    });
  }
}
