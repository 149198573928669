import { EDIT_USER_SUCCESS, SUCCESS } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { UsersHttpService } from 'src/app/pages/users/users-http.service';
import { EDIT_USER_FAILED, FAILED } from '../../../../configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '../../../../configs/toastr-messages.config';
import { EditUserService } from '../../edit-user/edit-user.service';
import { AuthService } from './../../../../auth/auth.service';
import { RolesHttpService } from './../../roles-http.service';
import { editUser, editUserFailed, editUserSuccess } from './../actions/users.actions';

@Injectable()
export class EditUserEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    private usersHttpService: UsersHttpService,
    private rolesHttpService: RolesHttpService,
    private toastrService: NbToastrService,
    private editUserService: EditUserService,
    private router: Router,
  ) {}

  editUser = createEffect(() => {
    return this.actions.pipe(
      ofType(editUser),
      mergeMap(({ user }) => {
        this.editUserService.setRequestSended(true);
        return forkJoin([
          this.usersHttpService.putUser(this.editUserService.mapUserFormToHttpUser(user)),
          this.rolesHttpService.assignRoles(this.editUserService.mapUserToHttpEditRoles(user)),
        ]).pipe(
          map(() => {
            return editUserSuccess();
          }),
          catchError(() => of(editUserFailed())),
        );
      }),
    );
  });

  editUserSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(editUserSuccess),
        tap(() => {
          this.editUserService.setRequestSended(false);
          this.authService.currentEndpoint$.pipe(first()).subscribe((endpoint) => {
            if (endpoint) {
              this.router.navigate([`/${endpoint.id}/users`]);
            }
            this.toastrService.show(EDIT_USER_SUCCESS, SUCCESS, {
              ...SUCCESS_TOASTR_CONFIG,
            });
          });
        }),
      );
    },
    { dispatch: false },
  );

  editUserFailed = createEffect(
    () => {
      return this.actions.pipe(
        ofType(editUserFailed),
        tap(() => {
          this.editUserService.setRequestSended(false);
          this.editUserService.resetForm();
          this.toastrService.show(EDIT_USER_FAILED, FAILED, {
            ...WARNING_TOASTR_CONFIG,
          });
        }),
      );
    },
    { dispatch: false },
  );
}
