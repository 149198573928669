import { translatePxDimensionsToPercents } from './scaling.utils';

export const getPosAndDimFromAnnotoriousSelector = (value: string) => {
  const valuesString = value.replace('xywh=pixel:', '');
  const [x, y, width, height] = valuesString.split(',').map((val) => Number(val));
  return { x, y, width, height };
};

export const getMinMaxFromSelector = (selector: string, image: HTMLImageElement) => {
  const { x, y, width, height } = getPosAndDimFromAnnotoriousSelector(selector);
  return translatePxDimensionsToPercents(image, { x, y }, { x: width, y: height });
};
