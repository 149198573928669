<div
  class="layer_details__actions"
  *ngIf="!isEditModeOnInitMappingTool() && !(layer | isRecalculateActive)"
>
  <button
    type="button"
    class="layer_details__action layer_details__action--edit select-none"
    [ngClass]="{ 'layer_details__action--disabled': layer | isLayerBeingEdited }"
    (click)="onRecalculateClick()"
    [disabled]="layer | isLayerBeingEdited"
  >
    Recalculate properties
  </button>
</div>

<div class="layer_details__actions" *ngIf="layer | isRecalculateActive">
  <span class="layer_details__info">Pick a side</span>
  <button
    type="button"
    class="layer_details__action layer_details__action--cancel select-none"
    (click)="onRecalculateCancel()"
  >
    Cancel
  </button>
</div>
