<nb-card status="primary" class="content-card sites">
  <nb-card-body>
    <app-main-datatable
      [columns]="columns"
      [rows]="rows"
      [page]="page"
      [selected]="selectedRows"
      [loading]="loading"
      [showStatisticsButton]="false"
      (setPage)="setPage($event, true)"
      (setPageOffset)="setPageOffset($event)"
    ></app-main-datatable>
  </nb-card-body>
</nb-card>

<ng-template #rolesTpl let-row="row" let-value="value">
  <ng-container *ngFor="let role of value">
    <nb-badge
      *ngIf="role.name !== Role.STANDARD_USER"
      class="role-type"
      [text]="role.name"
      [ngStyle]="{ 'background-color': '#9b9b9b', color: 'white' }"
      [nbTooltip]="role.description"
      nbTooltipPlacement="top"
    ></nb-badge>
  </ng-container>
</ng-template>

<ng-template #actionsTpl let-row="row">
  <div class="actions">
    <button
      nbButton
      class="edit"
      (click)="$event.stopPropagation(); editUser(row)"
      nbTooltip="Edit user"
      data-testId="edit-user"
      ghost
    >
      <nb-icon status="basic" icon="edit-outline"></nb-icon>
    </button>
    <button
      nbButton
      ghost
      *ngxPermissionsOnly="permissions.CAN_DISABLE_USER"
      (click)="$event.stopPropagation(); disableUserHandler(row)"
      [nbTooltip]="row.isDisabled ? 'Enable user' : 'Disable user'"
      class="action"
      data-testId="enabling-user"
    >
      <div>
        <nb-icon
          [status]="'basic'"
          [icon]="row.isDisabled ? 'person-add-outline' : 'person-remove-outline'"
        ></nb-icon>
      </div>
    </button>
  </div>
</ng-template>
