import { Photo } from '@App/app/entities/files/files.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/photos.actions';

export const photosAdapter = createEntityAdapter<Photo>();
export type State = EntityState<Photo>;

const defaultPhoto: State = {
  ids: [],
  entities: {},
};
export const initialState: State = photosAdapter.getInitialState(defaultPhoto);

export const photosReducer = createReducer(
  initialState,
  on(actions.loadAllPhotosSuccess, (state, { photos }) => {
    return photosAdapter.setAll(photos, state);
  }),
  on(actions.removeAllPhotosLocal, (state) => {
    return photosAdapter.removeAll(state);
  }),
);
