import { IPreset } from '@App/app/entities/viewer/custom-preset.model';
import { createAction, props } from '@ngrx/store';

export const loadAllPresets = createAction('[Pres] Load all presets');

export const updatePageOffset = createAction(
  '[Pres] Update page offset success',
  props<{ pageOffset: number }>(),
);

export const updateCurrentBGColor = createAction(
  '[Pres] Update current background color',
  props<{ backgroundColor: string }>(),
);

export const updateCurrentVFColor = createAction(
  '[Pres] Update current viewfinder color',
  props<{ viewFinderColor: string }>(),
);

export const loadAllPresetsSuccess = createAction(
  '[Pres] Load all presets success',
  props<{ presets: IPreset[] }>(),
);

export const addCustomPreset = createAction(
  '[Pres] Add custom preset',
  props<{ preset: IPreset }>(),
);

export const addCustomPresetsFail = createAction('[Pres] Add custom presets fail');

export const deletePreset = createAction('[Pres] Delete preset', props<{ name: string }>());

export const acceptDocuments = createAction('[Pres] Accept documents');
