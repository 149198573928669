<app-filter-layout
  [filterForm]="filterForm"
  [isFilterPopoverActive]="isFilterPopoverActive"
  [loading]="loadingPilots"
  [contentTpl]="contentTpl"
  [textInputValue]="getKeywordsValue()"
  [showClearButton]="isFormTouched()"
  textInputPlaceholder="Type to search model"
  (clearFilters)="clearFilters()"
  (queryChange)="queryChange($event)"
  (submitHandler)="onSubmit($event)"
  (toggleFilterPopover)="toggleFilterPopover()"
>
</app-filter-layout>

<ng-template #contentTpl>
  <div [formGroup]="filterForm">
    <div class="filter__section">
      <div class="filter__title">Model type</div>
      <div class="filter__values grid" formArrayName="type">
        <nb-checkbox
          *ngFor="let type of basicModelTypes"
          class="filter__checkbox checkbox-grid"
          [formControlName]="type.abbr"
        >
          {{ type.name }}
        </nb-checkbox>
      </div>
    </div>
    <div class="filter__section">
      <div class="filter__title">Date flown</div>
      <div class="filter__values">
        <input
          nbInput
          data-testId="date-flown"
          placeholder="Pick Date Range"
          autocomplete="off"
          [nbDatepicker]="dateFlown"
          [status]="checkInputHasNoEndDate('dateFlown') ? 'danger' : 'basic'"
          name="dateFlown"
          formControlName="dateFlown"
        />
        <nb-rangepicker #dateFlown></nb-rangepicker>
        <span *ngIf="checkInputHasNoEndDate('dateFlown')" class="filter__date_error">
          Set End Date
        </span>
      </div>
    </div>
    <div class="filter__section">
      <div class="filter__title">Date processed</div>
      <div class="filter__values">
        <input
          nbInput
          data-testId="date-processed"
          placeholder="Pick Date Range"
          autocomplete="off"
          [nbDatepicker]="dateProcessed"
          [status]="checkInputHasNoEndDate('dateProcessed') ? 'danger' : 'basic'"
          name="dateProcessed"
          formControlName="dateProcessed"
        />
        <nb-rangepicker #dateProcessed></nb-rangepicker>
        <span *ngIf="checkInputHasNoEndDate('dateProcessed')" class="filter__date_error">
          Set End Date
        </span>
      </div>
    </div>
    <div class="filter__section">
      <div class="filter__title">Pilot</div>
      <div class="filter__values">
        <nb-select
          data-testId="pilot-select"
          placeholder="Choose a pilot"
          [selected]="''"
          name="pilot"
          formControlName="pilot"
        >
          <nb-option value="">Any pilot</nb-option>
          <nb-option *ngFor="let pilot of pilots" [value]="pilot.id"
            >{{ pilot.firstName }} {{ pilot.lastName }}</nb-option
          >
        </nb-select>
      </div>
    </div>
  </div>
</ng-template>

<nb-layout *ngIf="nbLayoutActive"></nb-layout>
