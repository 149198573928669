import { getPicketPointGeoCoords } from '@App/app/engine/helpers/layers-helpers';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { CoordsTool } from '@App/app/entities/viewer/coords-tool.model';
import { selectCurrentModelLocation } from '@App/app/pages/models/store/models/selectors/models.selectors';
import { ClipboardService } from '@App/app/shared/services/clipboard/clipboard.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BasePlaneService } from '../../layer-services/base-plane-services/base-plane.service';
import { UtilsService } from '../../utils-service/utils.service';
import { CoordsToolService } from '../coords-tool-service/coords-tool.service';

@Injectable({
  providedIn: 'root',
})
export class GeoCoordsToolService extends CoordsToolService implements CoordsTool {
  baseGeoPosition: GeoCoords;

  protected _coordsToolActive$ = new BehaviorSubject<boolean>(false);
  geoCoordsToolActive$ = this._coordsToolActive$.asObservable();
  protected _currentCoords$ = new BehaviorSubject<GeoCoords>({ latitude: 0.0, longitude: 0.0 });
  currentGeoCoords$ = this._currentCoords$.asObservable();

  constructor(
    private utilsService: UtilsService,
    private basePlaneService: BasePlaneService,
    private store: Store,
    private _clipboardService: ClipboardService,
  ) {
    super();
    this.store
      .select(selectCurrentModelLocation)
      .pipe(filter(Boolean))
      .subscribe((location: GeoCoords) => {
        this.baseGeoPosition = location;
      });
  }

  updateCoords() {
    const hit = this.utilsService.pickRay();
    if (!hit?.pickedPoint) {
      this._currentCoords$.next({ latitude: 0.0, longitude: 0.0 });
    } else {
      const baseAxePos = this.basePlaneService.getBasePlanesPosition();
      const coords = getPicketPointGeoCoords(hit.pickedPoint, baseAxePos, this.baseGeoPosition);
      this._currentCoords$.next(coords);
    }
  }

  saveCoords() {
    this.setCoordsToolActive(false);
    this.updateCoords();
    this._clipboardService.copyGeoCoords(this._currentCoords$.value);
  }
}
