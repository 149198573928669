import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Tube } from '@App/app/entities/layer/tube.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Mesh, Vector3 } from 'babylonjs';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { getAndAdjustEdges } from '../../../helpers/tubes-helpers';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { UtilsService } from '../../utils-service/utils.service';
import { LayerUtilsService } from '../layer-utils-service/layer-utils.service';
import { TubeUtilsService } from './tube-utils.service';

@Injectable({
  providedIn: 'root',
})
export class CreateTubePickerService {
  mesh: Mesh | null = null;
  firstEdge: Vector3 | null = null;

  constructor(
    private utilsService: UtilsService,
    private store: Store,
    private broadcastService: BroadcastService,
    private babylonNodesService: BabylonNodesService,
    private tubeUtilsService: TubeUtilsService,
    private layerUtilsService: LayerUtilsService,
  ) {}

  onClickAction() {
    const hit = this.utilsService.pickRay();
    if (hit?.pickedPoint) {
      if (!this.firstEdge) {
        this.firstEdge = hit.pickedPoint;
        this.mesh = this.babylonNodesService.createSphere(hit.pickedPoint);
      } else {
        this.createTube([this.firstEdge, hit.pickedPoint], 0.03, 0.024);
        this.clear();
      }
    }
  }

  createTube(edges: [Vector3, Vector3], radiusOuter: number, radiusInner: number) {
    const tube = this.tubeUtilsService.createTube(edges, radiusOuter, radiusInner);
    this.store.dispatch(addLayer({ layer: tube as LayerUI, commit: false }));
    this.broadcastService.broadcast(EVENT_TYPE.INIT_TUBE_PICKER, false);
  }

  clear() {
    this.firstEdge = null;
    this.mesh?.dispose();
    this.mesh = null;
  }

  importTube(tube: Tube) {
    const newTube = { ...tube };
    newTube.data = { ...tube.data };
    const newEdges = getAndAdjustEdges(newTube);
    newTube.data.edges = [
      {
        x: newEdges[0].x,
        y: newEdges[0].y,
        z: newEdges[0].z,
      },
      {
        x: newEdges[1].x,
        y: newEdges[1].y,
        z: newEdges[1].z,
      },
    ];
    newTube.data.length = Vector3.Distance(newEdges[0], newEdges[1]);
    return this.layerUtilsService.createRequestBody(newTube);
  }
}
