<div class="sidebar__buttons sidebar__content">
  <div
    class="sidebar__buttons__view"
    [nbTooltip]="backActionDeniedTooltip"
    [nbTooltipTrigger]="!backActionPermission ? NbTrigger.HINT : NbTrigger.NOOP"
  >
    <button
      *ngIf="backAction"
      (click)="backAction()"
      nbButton
      class="sidebar__button"
      type="button"
      status="primary"
      [disabled]="!backActionPermission"
    >
      {{ backActionName || 'Cancel' }}
    </button>
  </div>
  <div
    class="sidebar__buttons__view"
    [nbTooltip]="getNextActionPermissionDenyMessageIfExists() || ''"
    [nbTooltipTrigger]="
      !!getNextActionPermissionDenyMessageIfExists() ? NbTrigger.HINT : NbTrigger.NOOP
    "
  >
    <button
      *ngIf="isSubmit || nextAction"
      nbButton
      (click)="nextAction ? nextAction() : null"
      [disabled]="!!getNextActionPermissionDenyMessageIfExists()"
      class="sidebar__button"
      data-testId="submit-btn"
      [type]="isSubmit ? 'submit' : 'button'"
      status="info"
    >
      {{ nextActionName || 'Continue' }}
    </button>
  </div>
</div>

<button
  *ngIf="log"
  (click)="openLogsDialog()"
  nbButton
  class="sidebar__button sidebar__button--logs"
  type="button"
  status="primary"
>
  Show logs
</button>

<app-logs-dialog
  *ngIf="logsText && openDialog"
  [logs]="logsText"
  (closeLogsDialog)="closeLogsDialog()"
></app-logs-dialog>

<nb-layout *ngIf="nbLayoutActive"> </nb-layout>
