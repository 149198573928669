import { CHANGE_PASSWORD_SUCCESS, FAILED, SUCCESS } from '@App/app/configs/toastr-events.config';
import {
  FAILED_TOASTR_CONFIG,
  SUCCESS_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { match } from '@App/app/shared/match-form-validator';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { of, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { passwordRegExpPattern } from 'src/app/shared/password-pattern';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent implements OnInit {
  passwordsVisibility = {
    currentPassword: false,
    newPassword: false,
    newPasswordConfirm: false,
  };
  changePasswordForm = this.fb.group(
    {
      currentPassword: this.fb.control('', { validators: [Validators.required] }),
      newPassword: this.fb.control('', {
        validators: [Validators.required, Validators.pattern(passwordRegExpPattern)],
      }),
      newPasswordConfirm: this.fb.control('', { validators: [Validators.required] }),
    },
    {
      validator: [
        match('currentPassword', 'newPassword', false),
        match('newPassword', 'newPasswordConfirm', true),
      ],
    },
  );

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.authService
      .changePassword(
        this.changePasswordForm.controls['currentPassword'].value,
        this.changePasswordForm.controls['newPassword'].value,
      )
      .pipe(
        take(1),
        catchError((err) => {
          this.changePasswordForm.reset();
          if (err.status === 400) {
            this.toastrService.show(err.error.message, FAILED, {
              ...FAILED_TOASTR_CONFIG,
            });
            return of();
          } else {
            return throwError(err);
          }
        }),
      )
      .subscribe((resp: boolean) => {
        if (resp) {
          this.changePasswordForm.reset();
          this.toastrService.show(CHANGE_PASSWORD_SUCCESS, SUCCESS, {
            ...SUCCESS_TOASTR_CONFIG,
          });
        }
      });
  }
}
