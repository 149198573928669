import { createAction, props } from '@ngrx/store';
import { RoiLimit } from '../../models/roi-limits.model';

export const loadRoiLimits = createAction('[Roi Limits] Load Roi Limits');

export const loadRoiLimitsSuccess = createAction(
  '[Roi Limits] Load Roi Limits Success',
  props<{ limits: RoiLimit[] }>(),
);

export const loadRoiLimit = createAction('[Roi Limit] Load Roi Limit', props<{ id: number }>());

export const loadRoiLimitSuccess = createAction(
  '[Roi Limit] Load Roi Limit Success',
  props<{ limit: RoiLimit }>(),
);

export const deleteRoiLimit = createAction('[Roi Limit] Delete Roi Limit', props<{ id: number }>());

export const deleteRoiLimitSuccess = createAction(
  '[Roi Limit] Delete Roi Limit Success',
  props<{ id: number }>(),
);

export const deleteRoiLimitFailure = createAction('[Roi Limit] Delete Roi Limit Failure');

export const addRoiLimit = createAction('[Roi Limit] Add Roi Limit', props<{ limit: RoiLimit }>());

export const addRoiLimitSuccess = createAction(
  '[Roi Limit] Add Roi Limit Success',
  props<{ limit: RoiLimit }>(),
);

export const addRoiLimitFailure = createAction('[Roi Limit] Add Roi Limit Failure');

export const updateRoiLimit = createAction(
  '[Roi Limit] Update Roi Limit',
  props<{ id: number; limit: RoiLimit }>(),
);
export const updateRoiLimitSuccess = createAction(
  '[Roi Limit] Update Roi Limit Success',
  props<{ id: number; limit: RoiLimit }>(),
);
export const updateRoiLimitFailure = createAction('[Roi Limit] Update Roi Limit Failure');
