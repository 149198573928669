import {
  ADD_MODELS_TO_ARCHIVED_SUCCESS,
  ADD_MODEL_TO_ARCHIVED_SUCCESS,
  FAILED,
  MODELS_ARCHIVED_FAILED,
  REMOVED_MODELS_FROM_ARCHIVED_SUCCESS,
  REMOVED_MODEL_FROM_ARCHIVED_SUCCESS,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { Model } from '@App/app/entities/models/model.model';
import { ModelsHttpService } from '@App/app/pages/models/services/models-http-service/models-http.service';
import { ModelsSitesService } from '@App/app/pages/sites/shared/site-services/models-sites.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, combineLatest } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { changeModels, loadAllModels, updateModel } from '../actions/models.actions';

@Injectable()
export class ChangeModelEffects {
  constructor(
    private actions: Actions,
    private modelsHttpService: ModelsHttpService,
    private toastrService: NbToastrService,
    private sitesService: ModelsSitesService,
  ) {}

  changeModels = createEffect(() => {
    return this.actions.pipe(
      ofType(changeModels),
      mergeMap(({ models }) => {
        const archiveAction = !models[0].isArchived;
        this.sitesService.setSelectedEntities([]);
        const newModels = models.map((model: Model) => {
          return { ...model, isArchived: !model.isArchived };
        });
        return combineLatest(
          newModels.map((newModel) => {
            return this.modelsHttpService.putModel(Number(newModel.id), newModel);
          }),
        )
          .pipe(
            map(
              (resp: Model[]) => {
                const archiveSuccess = resp.every(
                  (res, index) => res.isArchived !== models[index].isArchived,
                );
                if (archiveSuccess) {
                  this.toastrService.show(
                    archiveAction
                      ? ADD_MODELS_TO_ARCHIVED_SUCCESS
                      : REMOVED_MODELS_FROM_ARCHIVED_SUCCESS,
                    SUCCESS,
                    {
                      ...SUCCESS_TOASTR_CONFIG,
                    },
                  );
                } else {
                  this.toastrService.show(MODELS_ARCHIVED_FAILED, FAILED, {
                    ...WARNING_TOASTR_CONFIG,
                  });
                }
                return loadAllModels();
              },
              catchError(() => EMPTY),
            ),
          )
          .pipe(
            finalize(() => {
              this.sitesService.hideSpinner();
            }),
          );
      }),
    );
  });

  changeModel = createEffect(() => {
    return this.actions.pipe(
      ofType(updateModel),
      mergeMap(({ model }) => {
        const modelToUpdate = { ...model };
        if (!modelToUpdate.location?.latitude || !modelToUpdate.location?.longitude) {
          modelToUpdate.location = null;
        }
        return this.modelsHttpService.putModel(Number(model.id), modelToUpdate).pipe(
          map(
            (resp: any) => {
              this.toastrService.show(
                resp.isArchived
                  ? ADD_MODEL_TO_ARCHIVED_SUCCESS
                  : REMOVED_MODEL_FROM_ARCHIVED_SUCCESS,
                SUCCESS,
                {
                  ...SUCCESS_TOASTR_CONFIG,
                },
              );
              return loadAllModels();
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });
}
