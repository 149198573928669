import { GizmoManager, Mesh } from 'babylonjs';
import { LAYER_TYPES, RECT_TUBE_PART } from './enums/layer-types.enum';
import { Layer } from './layer.model';
import { Coordinates3, Coordinates4 } from './measurements/coordinates';
import { NameTag } from './name-tag.model';

export class RectTube implements Layer<RectTubeData> {
  id: number;
  name: string;
  createdAt: string;
  editedAt: string;
  data: RectTubeData = {};
  parentId: number;
  type = LAYER_TYPES.RECT_TUBE;
  isVisible = false;
  isSaved = true;
  description: string;
}

export interface RectTubeData {
  absoluteRotationQuaternion?: Coordinates4;
  min?: Coordinates3;
  max?: Coordinates3;
  thickness?: number;
  volume?: number;
  onHeightDistance?: number;
  towerCenterDistance?: number;
  azimuth?: number;
  verticalTilt?: number;
  worldDirection?: number;
  edges?: [Coordinates3, Coordinates3];
}

export interface ViewerRectTubeLayer extends RectTube {
  mesh?: Mesh;
  gui?: NameTag;
  gizmoManager?: GizmoManager;
  data: RectTubeData;
  centerLine?: Mesh;
}

export interface RectTubePartsArrays<T> {
  [RECT_TUBE_PART.SURFACE]: T[];
  [RECT_TUBE_PART.ADJACENT_SURFACE]: T[];
  [RECT_TUBE_PART.INNER]: T[];
}
