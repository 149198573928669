/* eslint-disable ngrx/no-dispatch-in-effects */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@App/app/auth/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ModelsHttpService } from '../../../services/models-http-service/models-http.service';
import {
  loadCurrentModelFiles,
  removeCurrentModelFilesLocal,
} from '../actions/model-files.actions';
import {
  loadCurrentModel,
  loadCurrentModelSuccess,
  removeCurrentModelLocal,
} from '../actions/models.actions';

@Injectable()
export class LoadCurrentModelEffects {
  constructor(
    private actions: Actions,
    private modelsHttpService: ModelsHttpService,
    private authService: AuthService,
    private router: Router,
    private store: Store,
  ) {}

  loadCurrentModel = createEffect(() => {
    return this.actions.pipe(
      ofType(loadCurrentModel),
      mergeMap(({ id }) => {
        return this.modelsHttpService.getModel(id).pipe(
          map(
            (model) => loadCurrentModelSuccess({ model }),
            catchError(() => EMPTY),
          ),
          catchError((error) => {
            const endpoint = this.authService.getCurrentEndpoint();
            const sitesUrl = endpoint ? `${endpoint.id}/models` : '';
            this.router.navigate([sitesUrl]);
            return of(error);
          }),
        );
      }),
    );
  });

  loadCurrentModelSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(loadCurrentModelSuccess),
        tap(() => {
          this.store.dispatch(loadCurrentModelFiles());
        }),
      );
    },
    { dispatch: false },
  );

  removeCurrentModelLocal = createEffect(
    () => {
      return this.actions.pipe(
        ofType(removeCurrentModelLocal),
        tap(() => {
          this.store.dispatch(removeCurrentModelFilesLocal());
        }),
      );
    },
    { dispatch: false },
  );
}
