<app-model-site-header
  *ngIf="model"
  [label]="model.name + ' ' + (model | modelAddress)"
  [backUrl]="getBackUrl()"
  style="display: flex; justify-content: space-between; width: 100% !important;"
>
  <span info>
    <span class="site_header__info">{{ model.id }}</span>
    <span class="site_header__separator">•</span>
    <span class="site_header__info">
      {{ model.address.state | modelStateAbbr }}, {{ model.address.country }}
    </span>
  </span>
  <span forwardArrow class="site_header__forward" *ngIf="!isOverviewActive()">
    <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
  </span>
  <span renderSelect class="site_header__renders" *ngIf="!isOverviewActive()">
    <div>
      <nb-select
        data-testId="renders-select"
        status="control"
        [(selected)]="selectedRender"
        (selectedChange)="onRenderChange($event)"
        [nbSpinner]="!selectedRender"
      >
        <nb-select-label *ngIf="selectedRender">
          Render: {{ selectedRender.createdAtDate.getTime() | date }}
        </nb-select-label>
        <nb-option *ngFor="let render of getRenders()" [value]="render">{{
          render.createdAtDate.getTime() | date
        }}</nb-option>
      </nb-select>
    </div>
  </span>
  <nav menu class="site_header__menu" *ngIf="!isOverviewActive()">
    <a
      *ngxPermissionsOnly="permissions.CAN_ACCESS_VIEWER; else elseViewerBlock"
      class="site_header__menu_item"
      routerLinkActive="site_header__menu_item--active"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLink]="['/', companyId, 'models', modelId, 'renders', renderId, 'viewer']"
    >
      Viewer
    </a>
    <a
      *ngxPermissionsOnly="permissions.CAN_ACCESS_PHOTOS; else elsePhotosBlock"
      class="site_header__menu_item"
      routerLinkActive="site_header__menu_item--active"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLink]="['/', companyId, 'models', modelId, 'renders', renderId, 'photos']"
    >
      Photos
    </a>
  </nav>
</app-model-site-header>

<router-outlet *ngIf="model"></router-outlet>

<ng-template #elseViewerBlock>
  <a class="site_header__menu_item site_header__menu_item--disabled" [nbTooltip]="'Not authorized'">
    Viewer
  </a>
</ng-template>
<ng-template #elsePhotosBlock>
  <a class="site_header__menu_item site_header__menu_item--disabled" [nbTooltip]="'Not authorized'">
    Photos
  </a>
</ng-template>

<nb-layout *ngIf="nbLayoutActive"></nb-layout>
