<nb-card
  data-testId="download-message"
  accent="success"
  class="async_action_dispatched_info"
  [ngClass]="{ '--dark': theme === 'dark' }"
>
  <nb-card-body>
    <div>
      <nb-icon
        class="async_action_dispatched_info__checkmark"
        icon="checkmark-outline"
        status="success"
      ></nb-icon>
      <ng-container *ngIf="inline; else blockTpl">
        {{ message }}
      </ng-container>
      <ng-template #blockTpl>
        <div class="async_action_dispatched_info__block">{{ message }}</div>
      </ng-template>
    </div>
    <div>
      <nb-icon
        (click)="onClose()"
        data-testId="async-action-dispatched-info-close"
        class="async_action_dispatched_info__close"
        icon="close-outline"
      ></nb-icon>
    </div>
  </nb-card-body>
</nb-card>
