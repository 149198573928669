<button
  [nbPopover]="isEditMode ? EDIT_MODE : description"
  nbTooltipPlacement="top"
  nbPopoverTrigger="hint"
  [disabled]="!isModelLoaded || isEditMode"
  type="button"
  [attr.data-testid]="dataTestId"
  [attr.data-cy]="dataTestCypressId"
  [class]="
    activeTool === toolName
      ? 'create_layer__button create_layer__button--active'
      : isEditMode
      ? 'create_layer__button create_layer__button--disabled'
      : 'create_layer__button'
  "
  (click)="func()"
  [class.create_layer__button--hovered]="isModelLoaded"
>
  <nb-icon *ngIf="iconType === 'nb'" class="create_layer__type" [icon]="icon"></nb-icon>
  <svg-icon *ngIf="iconType === 'svg'" [src]="icon" svgClass="create_layer__svg"></svg-icon>
</button>
