import { Hints } from '@App/app/configs/hints.config';
import {
  MAX_SELECTABLE_PHOTOS_NUMBER,
  RANGE_SELECTION_ACTIVATE_KEYS,
} from '@App/app/configs/photos.config';
import { MAX_SELECTED_PHOTOS_NUMBER_REACHED, WARNING } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { Photo } from '@App/app/entities/files/files.model';
import { Model } from '@App/app/entities/models/model.model';
import { AnnotationPriorities } from '@App/app/entities/shared/annotation-priorities.enum';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ModelsService } from '../../../../services/models-service/models.service';
import { RendersService } from '../../../../services/renders-service/renders.service';

@Injectable({
  providedIn: 'root',
})
export class ModelPhotosService {
  private _loadingPhotos$ = new BehaviorSubject<boolean>(true);
  loadingPhotos$ = this._loadingPhotos$.asObservable();
  private _downloadMessage$ = new BehaviorSubject<string | null>(null);
  downloadMessage$ = this._downloadMessage$.asObservable();
  isRangeSelectionActive = false;

  constructor(
    private toastrService: NbToastrService,
    private modelsService: ModelsService,
    private rendersService: RendersService,
  ) {}

  selectRange(photo: Photo, lastPhoto: Photo | null, allPhotos: Photo[], selectedPhotos: Photo[]) {
    const photos = [...allPhotos];
    const currPhoto = photos.find((p) => p.id === photo.id);
    const lastSelectedPhoto = photos.find((p) => p.id === lastPhoto?.id);
    if (lastSelectedPhoto && currPhoto) {
      const rect = this.getPhotosByRange(photos, currPhoto, lastSelectedPhoto);
      const newPhotos = selectedPhotos.concat(rect);
      selectedPhotos = this.removeDuplicatedPhotos(newPhotos);
    }
    if (selectedPhotos.length > MAX_SELECTABLE_PHOTOS_NUMBER) {
      selectedPhotos = selectedPhotos.slice(0, MAX_SELECTABLE_PHOTOS_NUMBER);
      this.showMaxPhotosNumberReachedMessage();
    }
    return selectedPhotos;
  }

  showMaxPhotosNumberReachedMessage() {
    this.toastrService.show(MAX_SELECTED_PHOTOS_NUMBER_REACHED, WARNING, {
      ...WARNING_TOASTR_CONFIG,
    });
  }

  setLoading(value: boolean) {
    this._loadingPhotos$.next(value);
  }

  setDownloadMessage(value: string | null) {
    this._downloadMessage$.next(value);
  }

  sortPhotosByIndex(photos: Photo[]) {
    return photos.sort((a, b) => this.getIndexFromName(a.name) - this.getIndexFromName(b.name));
  }

  startListeningRangeSelection() {
    const pressInfo = RANGE_SELECTION_ACTIVATE_KEYS.reduce((res, key) => {
      return { ...res, [key]: false };
    }, {} as any);

    merge(
      fromEvent<KeyboardEvent>(document, 'keydown'),
      fromEvent<KeyboardEvent>(document, 'keyup'),
    )
      .pipe(
        filter(({ code }) => RANGE_SELECTION_ACTIVATE_KEYS.includes(code)),
        tap((event) => (pressInfo[event.code] = event.type === 'keydown')),
        map(() => Object.values(pressInfo)),
      )
      .subscribe((values) => (this.isRangeSelectionActive = values.some(Boolean)));
  }

  downloadAnnotationsReport() {
    const { model } = this.modelsService;
    this.rendersService.currentRenderId$
      .pipe(
        filter((renderId) => !!(model && renderId)),
        map((renderId: number) => [+(model as Model).id, renderId]),
        take(1),
        switchMap(([modelId, renderId]) =>
          this.rendersService.getAnnotationsReport(modelId, renderId),
        ),
      )
      .subscribe(
        () => this.setDownloadMessage(Hints.DOWNLOAD_ANNOTATIONS_REPORT),
        () => this.setDownloadMessage(null),
      );
  }

  recalculateTotalSizeByPhotos(photos: Photo[]) {
    return photos.reduce((n, photo) => n + (photo.data.size ? photo.data.size : 0), 0);
  }

  filterPhotos(
    photos: Photo[],
    form: FormGroup<{
      keywords: FormControl<string | null>;
      showOnlyWithAnnotations: FormControl<boolean | null>;
      priority: FormControl<AnnotationPriorities | null>;
    }>,
  ) {
    photos = [...photos];
    const { keywords, showOnlyWithAnnotations, priority } = form.controls;
    if (keywords.value?.length) {
      const val = keywords.value.toLocaleLowerCase();
      photos = photos.filter(
        (photo) =>
          photo.name.toLocaleLowerCase().includes(val) ||
          photo.data.annotations?.some(
            (ann) =>
              ann.title.toLocaleLowerCase().includes(val) ||
              ann.description.toLocaleLowerCase().includes(val),
          ),
      );
    }
    if (showOnlyWithAnnotations.value) {
      photos = photos.filter((photo) => photo.data.annotations?.length);
    }
    if (priority.value) {
      photos = photos.filter((photo) =>
        photo.data.annotations?.some((ann) => ann.priority === priority.value),
      );
    }
    return photos;
  }

  getFolderNameFromPhoto(photo: Photo) {
    const linkParts = photo.downloadURL.split('/');
    return linkParts.find((part) => part.startsWith('tower_'));
  }

  private removeDuplicatedPhotos(photos: Photo[]) {
    return photos.filter((photo, i) => {
      const instance = photos.find((p2) => p2.id === photo.id);
      return instance ? photos.indexOf(instance) === i : false;
    });
  }

  private getPhotosByRange(photos: Photo[], currentPhoto: Photo, lastSelectedPhoto: Photo) {
    const currIndex = photos.indexOf(currentPhoto);
    const lastIndex = photos.indexOf(lastSelectedPhoto);
    const minIndex = Math.min(currIndex, lastIndex);
    const maxIndex = Math.max(currIndex, lastIndex);
    return photos.slice(minIndex, maxIndex + 1);
  }

  private getIndexFromName(name: string) {
    const digits = name.match(/\d/g)?.join('');
    return Number(digits);
  }
}
