import { ProcessingTableFilterForm } from '@App/app/entities/forms/processing-table-filter-form.model';
import { SubscriptionsStatusId } from '@App/app/entities/processing/build-process-status.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { AdvancedFiltersService } from '@App/app/entities/shared/advanced-filters-service.model';
import {
  AdvancedSearchProcessing,
  DateRange,
} from '@App/app/entities/shared/advanced-search.model';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { filterDateRange, isAtLeastOneValueTrue, updateByQuery } from '../../shared/utils';
import { isNullOrHasBothDates } from '../../shared/validators/models-list.validators';

@Injectable({
  providedIn: 'root',
})
export class ProcessingTableFiltersService
  implements AdvancedFiltersService<AdvancedSearchProcessing, ProcessingTableFilterForm> {
  private _advanceSearch = new BehaviorSubject<AdvancedSearchProcessing>(
    new AdvancedSearchProcessing(),
  );
  $advancedSearch = this._advanceSearch.asObservable();
  filterForm: ProcessingTableFilterForm = this.fb.group({
    query: this.fb.control(''),
    type: this.fb.group<{
      [name: string]: boolean;
    }>({}),
    subscription: this.fb.group<{
      [name: string]: boolean;
    }>({}),
    status: this.fb.group<{
      [name: string]: boolean;
    }>({}),
    dateStart: this.fb.control<DateRange | null>(null, {
      validators: isNullOrHasBothDates(),
      updateOn: 'blur',
    }),
    dateEnd: this.fb.control<DateRange | null>(null, {
      validators: isNullOrHasBothDates(),
      updateOn: 'blur',
    }),
  });

  constructor(private authService: AuthService, private fb: FormBuilder) {}

  updateProcessingTableFilters(
    advancedSearch: AdvancedSearchProcessing,
    buildProcesses: BuildProcess[],
  ) {
    let temp = [...buildProcesses];
    const props = ['model.name', 'id'];
    temp = updateByQuery(temp, advancedSearch.query, props) as BuildProcess[];
    temp = this.filterProcessingType(temp, advancedSearch.type);
    temp = this.filterProcessingStatus(temp, advancedSearch.status);
    temp = this.filterSubscriptions(temp, advancedSearch.subscription);
    temp = filterDateRange(temp, advancedSearch.dateStart, 'startedAt') as BuildProcess[];
    temp = filterDateRange(temp, advancedSearch.dateEnd, 'finishedAt') as BuildProcess[];
    return temp;
  }

  advancedSearch(values: AdvancedSearchProcessing) {
    this._advanceSearch.next(values);
  }

  filterSubscriptions(buildProcesses: BuildProcess[], subscription: { [name: string]: boolean }) {
    if (!subscription || !isAtLeastOneValueTrue(subscription)) {
      return buildProcesses;
    }

    const result: BuildProcess[] = [];
    const userId = this.authService.getUser().id;

    if (subscription[SubscriptionsStatusId.Subscribed]) {
      result.push(
        ...buildProcesses.filter((buildProcess: BuildProcess) => {
          const users = buildProcess.getUsers();
          return users?.subscribers?.includes(userId) || users.owner === userId;
        }),
      );
    }
    if (subscription[SubscriptionsStatusId.Unsubscribed]) {
      result.push(
        ...buildProcesses.filter((buildProcess: BuildProcess) => {
          const users = buildProcess.getUsers();
          return !users?.subscribers?.includes(userId) && users.owner !== userId;
        }),
      );
    }
    return result;
  }

  filterProcessingStatus(buildProcesses: BuildProcess[], status: { [name: string]: boolean }) {
    if (status && isAtLeastOneValueTrue(status)) {
      return buildProcesses.filter((process) => status[process.buildProcessStatusId]);
    }
    return buildProcesses;
  }

  filterProcessingType(buildProcesses: BuildProcess[], types: { [name: string]: boolean }) {
    if (types && isAtLeastOneValueTrue(types)) {
      return buildProcesses.filter((process) => types[process.buildProcessTypeId]);
    }
    return buildProcesses;
  }
}
