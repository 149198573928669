import { TiePoint, TiePointMeasurement } from '@App/app/entities/files/tie-point-model';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { Vector3 } from 'babylonjs';
import { kdTree } from 'kd-tree-javascript';
import { BehaviorSubject } from 'rxjs';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { PhotosUtilsService } from './../photos-utils/photos-utils.service';
import { TiePointsHttpService } from './tie-points-http.service';

@Injectable({
  providedIn: 'root',
})
export class TiePointsService {
  tiePointsPath: string | null = null;
  tiePoints: { [key: string]: TiePoint } = {};
  kdTree: kdTree<Coordinates3>;
  spacePressed: boolean;

  private _tiePointsModeActive$ = new BehaviorSubject<boolean>(false);
  tiePointsModeActive$ = this._tiePointsModeActive$.asObservable();

  private _tiePointsLoading$ = new BehaviorSubject<boolean>(false);
  tiePointsLoading$ = this._tiePointsLoading$.asObservable();

  private _tiePointPhotos$ = new BehaviorSubject<TiePointMeasurement[]>([]);
  tiePointPhotos$ = this._tiePointPhotos$.asObservable();

  private _tiePointAccessible$ = new BehaviorSubject<boolean>(false);
  tiePointAccessible$ = this._tiePointAccessible$.asObservable();

  constructor(
    private broadcastService: BroadcastService,
    private photosUtilsService: PhotosUtilsService,
    private tiePointsHttpService: TiePointsHttpService,
  ) {
    this.broadcastService.on(EVENT_TYPE.SPACE_PRESSED).subscribe((pressed: boolean) => {
      this.spacePressed = pressed;
    });
  }

  downloadTiePoints() {
    if (this.tiePointsPath) {
      this._tiePointsLoading$.next(true);
      this._tiePointAccessible$.next(true);
      this.tiePointsHttpService.download(this.tiePointsPath).subscribe(
        (tiePoints) => {
          if (tiePoints) {
            const points: Coordinates3[] = [];
            tiePoints.forEach((tiePoint) => this.convertTiePointOldFormatProperties(tiePoint));
            const updatedTiePoints = this.updateTiePointsToNamingConvention(tiePoints);
            updatedTiePoints.forEach((tiePoint) => {
              const updatedCoordinates3: Coordinates3 = {
                x: -tiePoint.position.x - this.photosUtilsService.photosModifier.xDiff,
                y: tiePoint.position.z - this.photosUtilsService.photosModifier.yDiff,
                z: -tiePoint.position.y - this.photosUtilsService.photosModifier.zDiff,
              };
              this.tiePoints[this.hashCoordinates3(updatedCoordinates3)] = tiePoint;
              this.tiePoints[
                this.hashCoordinates3(updatedCoordinates3)
              ].position = updatedCoordinates3;
              points.push(updatedCoordinates3);
            });
            this.kdTree = this.createKDimensionalTree(points);
            this._tiePointsLoading$.next(false);
          } else {
            this._tiePointAccessible$.next(false);
          }
        },
        () => this._tiePointAccessible$.next(false),
      );
    }
  }

  onClickModelHandler(pickedPoint: Vector3) {
    if (this._tiePointsModeActive$.value && !this.spacePressed) {
      const nearest = this.kdTree.nearest(
        {
          x: pickedPoint.x,
          y: pickedPoint.y,
          z: pickedPoint.z,
        },
        1,
      );
      const pointRelatedImages = this.getPhotosInfo(nearest[0][0]);
      this._tiePointPhotos$.next(pointRelatedImages);
    }
  }

  setTiePointsModeActive(active: boolean) {
    this._tiePointsModeActive$.next(active);
  }

  // temporary function. Delete once all tie points JSON files are converted.
  private updateTiePointsToNamingConvention(tiePoints: TiePoint[]) {
    if (tiePoints[0]?.position) {
      return tiePoints.map((tiePoint) => {
        return {
          position: tiePoint.position,
          color: {
            red: tiePoint.color.red,
            green: tiePoint.color.green,
            blue: tiePoint.color.blue,
          },
          measurements: tiePoint.measurements.map((measurement) => {
            return {
              photoId: measurement.photoId,
              x: measurement.x,
              y: measurement.y,
            };
          }),
        };
      });
    } else return tiePoints;
  }

  private getPhotosInfo(point: Coordinates3): TiePointMeasurement[] {
    return this.tiePoints[this.hashCoordinates3(point)].measurements;
  }

  private createKDimensionalTree(points: Coordinates3[]): kdTree<Coordinates3> {
    const distance = (a: Coordinates3, b: Coordinates3) => {
      return Vector3.Distance(new Vector3(a.x, a.y, a.z), new Vector3(b.x, b.y, b.z));
    };
    return new kdTree(points, distance, ['x', 'y', 'z']);
  }

  private hashCoordinates3(coordinates3: Coordinates3) {
    return `${coordinates3.x}+${coordinates3.y}+${coordinates3.z}`;
  }

  private convertTiePointOldFormatProperties(tiePoint: TiePoint) {
    this.convertObjectPropsToLowerCase(tiePoint, ['Color', 'Position', 'Measurements']);
    this.convertObjectPropsToLowerCase(tiePoint.color, ['Red', 'Green', 'Blue']);
    for (const measurement of tiePoint.measurements) {
      this.convertObjectPropsToLowerCase(measurement, ['PhotoId']);
    }
  }

  private convertObjectPropsToLowerCase(obj: any, props: string[]) {
    for (const prop of props) {
      if (obj.hasOwnProperty(prop)) {
        const newProp = prop.charAt(0).toLowerCase() + prop.slice(1);
        obj[newProp] = obj[prop];
        delete obj[prop];
      }
    }
  }
}
