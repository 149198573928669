import { DEFAULT_TILE_LOAD } from '@App/app/configs/babylon.config';
import { NO_MODEL_TO_SHOW } from '@App/app/configs/models.config';
import { POINTER_INFO_BUTTONS } from '@App/app/entities/layer/enums/layer-types.enum';
import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { CameraPosAndDir } from '@App/app/entities/viewer/camera-pos-and-dir.model';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ArcRotateCamera, AssetContainer, Engine, Nullable, PointerInfo, Vector3 } from 'babylonjs';
import { BehaviorSubject, EMPTY, Observable, Subscription, interval } from 'rxjs';
import { catchError, throttle } from 'rxjs/operators';
import { ModelType } from '../../../../entities/processing/build-process-status.model';
import { TilesInfo } from '../../../../entities/viewer/tiles-info.model';
import { BroadcastService } from '../../../../shared/broadcast.service';
import { CamerasService } from '../../camera-services/cameras-service/cameras.service';
import { CustomLODService } from '../../custom-lod-service/custom-lod.service';
import { LoadModelService } from '../../load-model-service/load-model.service';
import { SceneService } from '../../scene-service/scene.service';
import { EngineUtilsService } from '../engine-utils-service/engine-utils.service';

@Injectable({ providedIn: 'root' })
export abstract class BaseEngineService {
  protected engine: Engine | null = null;
  protected tmpCameraPosition: CameraPosAndDir = { position: new Vector3(), target: new Vector3() };
  protected _cursorMoveActive$ = new BehaviorSubject<[boolean, boolean]>([false, false]);
  protected _cursorMoveUpdate$ = new BehaviorSubject(false);
  protected modelTilesLength = 150;
  protected tilesRange = [...DEFAULT_TILE_LOAD.DEFAULT_TILE_LOAD];
  protected _loadingError$ = new BehaviorSubject<string | null>(null);
  loadingError$ = this._loadingError$.asObservable();
  protected _loadingText$ = new BehaviorSubject<string | null>(null);
  loadingText$ = this._loadingText$.asObservable();
  protected _modelIsNotAvailable$ = new BehaviorSubject<string | null>(null);
  modelIsNotAvailable$ = this._modelIsNotAvailable$.asObservable();
  protected loadingModelSubscription: Subscription;
  private _spacePressed = false;
  private _cursorMoveSubscription: Subscription | null = null;
  lostContext$ = this.utilsService.lostContext$;
  private _isModelLoading$ = new BehaviorSubject(false);
  isModelLoading$ = this._isModelLoading$.asObservable();
  private _isModelLoaded$ = new BehaviorSubject(false);
  isModelLoaded$ = this._isModelLoaded$.asObservable();
  private _allowToShowModelPlaceholder$ = new BehaviorSubject(true);
  allowToShowModelPlaceholder$ = this._allowToShowModelPlaceholder$.asObservable();
  private _isCameraControlsTabOpen$ = new BehaviorSubject(true);
  isCameraControlsTabOpen$ = this._isCameraControlsTabOpen$.asObservable();
  camera: Nullable<ArcRotateCamera>;
  canvas: Nullable<HTMLCanvasElement>;
  queryParams$: Observable<Params>;
  isHQModelOnly: boolean;

  constructor(
    protected broadcastService: BroadcastService,
    protected camService: CamerasService,
    protected loadModelService: LoadModelService,
    protected sceneService: SceneService,
    protected utilsService: EngineUtilsService,
    protected customLODService: CustomLODService,
  ) {
    setTimeout(() => {
      this.initSubscribers();
      this.handleMovingArcRotateCameraTarget = this.handleMovingArcRotateCameraTarget.bind(this);
      this.broadcastService.on(EVENT_TYPE.SPACE_PRESSED).subscribe((pressed: boolean) => {
        this._spacePressed = pressed;
      });
      this.broadcastService.on(EVENT_TYPE.CHANGE_CAMERA).subscribe(() => {
        this.utilsService.switchCamera(this.engine, this.canvas, this.camera);
      });
      this._cursorMoveActive$.subscribe(([value, withDebounce]) => {
        if (value && !this._cursorMoveSubscription) {
          this._cursorMoveSubscription = this._cursorMoveUpdate$
            .pipe(throttle(() => interval(withDebounce ? 100 : 0)))
            .subscribe(() => this.utilsService.onCursorMove());
        } else {
          this._cursorMoveSubscription?.unsubscribe();
          this._cursorMoveSubscription = null;
        }
      });
      this.isHQModelOnly = this.customLODService.getFreezed();
    });
  }

  handleMovingArcRotateCameraTarget() {
    this.utilsService.handleMovingArcRotateCameraTarget(this.camera);
  }

  activeCursorMove(value: boolean, withDebounce: boolean) {
    this._cursorMoveActive$.next([value, withDebounce]);
  }

  setModelLoad$(loading: boolean, loaded: boolean) {
    this._isModelLoading$.next(loading);
    this._isModelLoaded$.next(loaded);
  }

  setAllowToShowModelPlaceholder(value: boolean) {
    this._allowToShowModelPlaceholder$.next(value);
  }

  setIsCameraControlsTabOpen(value: boolean) {
    this._isCameraControlsTabOpen$.next(value);
  }

  setCameraSpeed(value: number) {
    this.utilsService.setCameraSpeed(this.camera, value);
  }

  getTilesInfo(): TilesInfo {
    return this.utilsService.getTilesInfo(this.modelTilesLength);
  }

  setTilesRange(range: number[]): void {
    this.tilesRange = [...range];
  }

  destroyViewer(): void {
    this.loadModelService.removeModel([ModelType.AT, ModelType.Prod], true);
    this.destroy();
    this.utilsService.setAxisVisibility(false);
    this.utilsService.destroyScene();
    this.engine?.dispose();
    this.engine = null;
    this.setModelLoad$(false, false);
  }

  postInit() {}

  isEditMode() {
    return false;
  }

  loadModel(type?: ModelType): void {
    this.setModelLoad$(true, false);
    this.loadingModelSubscription?.unsubscribe();
    this.loadingModelSubscription = this.importModel(type)
      .pipe(
        catchError((error) => {
          this._modelIsNotAvailable$.next(NO_MODEL_TO_SHOW);
          this.setModelLoad$(false, true);
          console.log(error);
          return EMPTY;
        }),
      )
      .subscribe(() => this.setModelLoad$(false, true));
  }

  toggleOnlyHQModel(value: boolean) {
    if (this.camera) {
      this.customLODService.toggleOnlyHQModel(value, this.camera);
    }
    this.isHQModelOnly = value;
  }

  abstract getEntity(): ExtendedRender | null;

  abstract importModel(type?: ModelType): Observable<void>;

  abstract isReadyToLoadModel: () => boolean;

  abstract initEngineSubscriptions(canvas: HTMLCanvasElement, modelId: number): Subscription[];

  protected abstract initSubscribers(): Subscription[];

  protected registerLODIfNeeded(tiles: string[], simplLevels: string[][], ctr?: AssetContainer) {
    if (simplLevels[0]?.length && this.camera)
      this.customLODService.register([tiles, ...simplLevels], this.camera, ctr);
  }

  protected destroy() {
    this.customLODService.unregister(this.camera);
  }

  protected abstract setDebugLayerStyles: () => void;

  protected initSceneObservables() {}

  protected startScene(canvas: HTMLCanvasElement, distanceLimit = true): void {
    this.utilsService.clearPreviousScene(this.engine, this.canvas);
    this.canvas = canvas;
    if (this.engine && this.sceneService.scene) {
      this.utilsService.resetCanvasForEngine(this.engine, this.canvas);
    } else {
      const { engine, camera } = this.utilsService.initEngineAndCamera(canvas, distanceLimit);
      [this.engine, this.camera] = [engine, camera];
      this.initSceneObservables();
    }
    this.utilsService.runEngineAnimation(this.engine);
    this.utilsService.startModelLoadingIfProd();
    this.utilsService.showDebugLayerSync(this.setDebugLayerStyles);
  }

  protected addPointerTapObservable(pointerInfo: PointerInfo) {
    if (pointerInfo.event.button === POINTER_INFO_BUTTONS.LEFT && !this._spacePressed)
      this.utilsService.onSceneClick();
  }
}
