export const CHANGE_PASSWORD_SUCCESS = 'Password changed successfully';

export const USER_CREATE_SUCCESS = 'User has been created';

export const USER_CREATE_FAILED = 'User has not been created';

export const EDIT_USER_SUCCESS = 'User has been updated';

export const EDIT_USER_FAILED = 'User has not been edited';

export const ENABLED_USER_SUCCESS = 'User has been enabled';

export const ENABLED_USER_FAILED = 'User has not been enabled';

export const DISABLE_USER_SUCCESS = 'User has been disabled';

export const DISABLE_USER_FAILED = 'User has not been disabled';

export const LAYER_CREATE_SUCCESS = 'Layer has been created';

export const LAYERS_CREATE_SUCCESS = 'Layers has been created';

export const PRESET_CREATE_SUCCESS = 'Preset has been created';

export const PRESET_CREATE_FAIL = 'This preset name already exist in list';

export const LAYER_CREATE_FAIL = 'Layer has not been created. Try again';

export const LAYER_EDITION_SUCCESS = 'Changes has been saved';

export const LAYER_EDITION_FAIL = 'Changes has not been saved';

export const LAYER_DELETE_SUCCESS = 'Layer has been deleted';

export const LAYER_DELETE_FAIL = 'Layer has not been deleted';

export const LAYERS_DELETE_SUCCESS = 'Layers have been deleted';

export const LAYERS_DELETE_FAIL = 'Layers have not been deleted';

export const LAYER_PROP_SUCCESS = 'Layer has been updated';

export const LAYER_PROP_FAIL = 'Layer has not been updated';

export const LAYERS_PROP_SUCCESS = 'Layers have been updated';

export const LAYERS_PROP_FAIL = 'Layers have not been updated';

export const FOLDER_CREATE_SUCCESS = 'Folder has been created';

export const FOLDER_CREATE_FAIL = 'Folder has not been created';

export const FOLDER_DELETE_SUCCESS = 'Folder has been deleted';

export const FOLDER_DELETE_FAIL = 'Folder has not been deleted';

export const FOLDER_EDIT_SUCCESS = 'Folder has been changed';

export const FOLDER_EDIT_FAIL = 'Folder has not been changed';

export const PROD_START_SUCCESS = 'Production process has started';

export const PROD_START_FAIL = 'Production process start has failed';

export const PP_START_SUCCESS = 'Post-processing has started';

export const PP_START_FAIL = 'Post-processing start has failed';

export const AT_START_SUCCESS = 'Aerotriangulation process has started';

export const AT_START_FAIL = 'Aerotriangulation process start has failed';

export const CONTEXT_LOST = 'Lost context, please reload the page';

export const COORDS_COPIED = 'The coordinates have been copied to the clipboard';

export const POSITION_COPIED = 'Position has been copied to the clipboard';

export const DIMENSIONS_COPIED = 'Dimensions have been copied to the clipboard';

export const CANNOT_FIND_DIMENSIONS = 'Cannot find dimensions for this layer';

export const WRONG_FILE_TYPE = 'Wrong file type, please select again';

export const NOT_AN_ARRAY = 'Data in JSON file should be wrapped in array';

export const NO_MATCHED_LAYERS = 'There is no layers to import in selected file';

export const AUTOMAPPING_NOT_AVAILABLE = 'Automapping is not available for this model';

export const SCRIPT_LOG_NOT_AVAILABLE = 'Logs are not available for this process';

export const ADD_TO_NOTIFICATIONS_SUCCESS = 'Subscribed to notifications';

export const REMOVED_TO_NOTIFICATIONS_SUCCESS = 'Unsubscribed to notifications';

export const ADD_PROCESS_TO_ARCHIVED_SUCCESS = 'Production process archived';

export const ADD_PROCESSES_TO_ARCHIVED_SUCCESS = 'Production processes archived';

export const PROCESS_DELETE_SUCCESS = 'Process has been deleted';

export const MODEL_DELETE_SUCCESS = 'Model has been deleted';

export const MODELS_DELETE_SUCCESS = 'Models have been deleted';

export const SETTING_DELETE_SUCCESS = 'Setting has been deleted';

export const PRESET_DELETE_SUCCESS = 'Preset has been deleted';

export const PROCESSES_DELETE_SUCCESS = 'Processes have been deleted';

export const REMOVED_PROCESS_FROM_ARCHIVED_SUCCESS = 'Production process unarchived';

export const REMOVED_PROCESSES_FROM_ARCHIVED_SUCCESS = 'Production processes unarchived';

export const ADD_MODEL_TO_ARCHIVED_SUCCESS = 'Model archived';

export const ADD_MODELS_TO_ARCHIVED_SUCCESS = 'Models archived';

export const REMOVED_MODEL_FROM_ARCHIVED_SUCCESS = 'Model unarchived';

export const REMOVED_MODELS_FROM_ARCHIVED_SUCCESS = 'Models unarchived';

export const MODELS_ARCHIVED_FAILED = 'Archive/Unarchive process failed';

export const PROCESSES_ARCHIVED_FAILED = 'Archive/Unarchive process failed';

export const MODEL_DOES_NOT_EXIST = 'Model does not exist';

export const RENDER_DOES_NOT_EXIST = 'Render does not exist';

export const NO_MODEL_SELECTED = 'No model selected';

export const NO_AT_MESH = 'There is no AT mesh related to this model';

export const UPLOAD_PAUSED = 'Upload paused';

export const UPLOAD_CANCELLED = 'Upload cancelled';

export const PHOTOS_UPLOADED_SUCCESSFULLY = 'Photos uploaded successfully';

export const ALL_FILES_WERE_ALREADY_UPLOADED = 'All files were already uploaded';

export const REMOVE_XLIF_SUCCESSFULLY = 'Metadata removed successfully';

export const REMOVE_XLIF_FAIL = 'Metadata deletion failed';

export const STARTED_GENERATING_PHOTOS = 'Selected photos started generating successfully';

export const MAX_SELECTED_PHOTOS_NUMBER_REACHED = 'Max selected photos number reached';

export const NO_DATA_TO_EXPORT = 'No data to export';

export const NO_TITLE_ERROR = 'You have to provide the title';

export const SET_MODEL_THUMBNAIL_SUCCESS = "Model's thumbnail is set successfully";

export const SET_MODEL_THUMBNAIL_FAIL = "Model's thumbnail saving failed";

export const DELETE_MODEL_THUMBNAIL_SUCCESS = "Model's thumbnail is deleted successfully";

export const DELETE_MODEL_THUMBNAIL_FAIL = "Model's thumbnail deleting failed";

export const START_SONY_UPLOAD_SUCCESS = 'Sony Cloud upload has started successfully';

export const OPEN_NEW_WINDOW_ERROR = 'Unable to open new window';

export const WRONG_PICKED_POINTS = 'Wrong picked points. Please try again';

export const ACCEPTED_DOCUMENTS = 'You have accepted agreements';

export const NO_LAYERS_SELECTED = 'No layers selected';

export const INTERNET_CONNECTION_LOST = 'Internet connection lost';

export const INTERNET_CONNECTION_ESTABLISHED = 'Internet connection has been established';

export const REPORT_BUG_SENT = 'Report has been sent';

export const REPORT_BUG_ERROR = 'Report has not been sent. Try again';

export const PROCESS_IS_ALREADY_RUNNING = 'Process is already running';

export const RESOURCE_IS_GENERATING =
  'Your {resourceName} is generating. You will receive an email notification as soon as the file is ready';

export const SUCCESS = 'Success';

export const FAILED = 'Failed';

export const WARNING = 'Warning';
