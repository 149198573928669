import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { FolderData } from '@App/app/entities/layer/folder.model';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { splitItemsToFoldersAndLayers } from '../../utils/layers.utils';
import { State, layersAdapter } from '../reducers/layers.reducer';

export const getLayersState = createFeatureSelector<State>('layers');

const { selectAll } = layersAdapter.getSelectors(getLayersState);

export const selectAllLayers = selectAll;

export const selectBasePlane = () =>
  createSelector(selectAll, (entities) =>
    entities.filter((entity) => entity.type === LAYER_TYPES.BASE_PLANE),
  );

export const selectAllNestedChildLayers = (folderIds: number[]) =>
  createSelector(selectAll, (entities) =>
    entities.filter((entity) => folderIds.includes(entity.parentId)),
  );

export const selectAllChildrenByLayerIds = (layerIds: number[]) =>
  createSelector(selectAll, (entities): LayerUI[] => {
    const getLayersByParentId = (id: number): LayerUI[] => {
      const children = entities.filter((entity) => entity.parentId === id);
      return children.length
        ? [...children, ...children.map((child) => getLayersByParentId(child.id)).flat()]
        : [];
    };
    return layerIds.map((id) => getLayersByParentId(id)).flat();
  });

export const selectRootFolder = () =>
  createSelector(selectAll, (entities) =>
    entities.find(
      (entity) => entity.type === LAYER_TYPES.FOLDERS && (entity.data as FolderData).isRoot,
    ),
  );

export const selectChildLayers = (parentLayerId: number) =>
  createSelector(selectAll, (entities) =>
    entities.filter((entity) => entity.parentId === parentLayerId),
  );

export const selectVisibleLayers = createSelector(selectAll, (entities) =>
  entities.filter((entity) => entity.isVisible),
);

export const selectFolders = createSelector(
  selectAll,
  (entities) => splitItemsToFoldersAndLayers(entities).folders,
);
