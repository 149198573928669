export interface PostProcessingSettings {
  runAutomapping: boolean;
}

export enum BuildProcessTypeId {
  Upload = 1,
  AT,
  Reconstruction,
  Prod,
  PP,
}

export enum BuildProcessStatusId {
  Started = 1,
  Paused,
  Failed,
  Finished,
  Canceled,
}

export enum ModelType {
  None,
  AT,
  Prod,
}

export enum SubscriptionsStatusId {
  Subscribed = 1,
  Unsubscribed = 2,
}
