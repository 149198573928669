import {
  FOLDER_CREATE_SUCCESS,
  LAYER_CREATE_SUCCESS,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { LayerUtilsService } from '@App/app/engine/services/layer-services/layer-utils-service/layer-utils.service';
import { FolderData } from '@App/app/entities/layer/folder.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, startWith, tap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { LayerHttpService } from '../../../../engine/services/layer-services/layer-http-service/layer-http.service';
import { ViewerLayerService } from '../../../../engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LayersService } from '../../services/layers/layers.service';
import { selectAllLayers } from '../selectors/layers.selectors';
import {
  addFolderLayer,
  addFolderLayerSuccess,
  addLayer,
  addLayerSuccess,
  addTmpLayer,
  saveTmpLayer,
} from './../actions/layers.actions';

@Injectable()
export class AddLayerEffects {
  constructor(
    private actions: Actions,
    private layerHttpService: LayerHttpService,
    private layerUtilsService: LayerUtilsService,
    private store: Store,
    private toastrService: NbToastrService,
    private viewerLayerService: ViewerLayerService,
    private layersService: LayersService,
  ) {}

  saveTmpLayer = createEffect(() => {
    return this.actions.pipe(
      ofType(saveTmpLayer),
      map(({ layer }) => {
        const layerData = this.viewerLayerService.getTmpLayerData(layer.id);
        layer = { ...layer, data: { ...layerData } };
        return addLayer({ layer, tmpId: layer.id });
      }),
    );
  });

  addLayer = createEffect(() => {
    return this.actions.pipe(
      ofType(addLayer),
      withLatestFrom(this.store.select(selectAllLayers)),
      mergeMap(([{ layer, tmpId, commit = true }, layers]) => {
        if (!tmpId) {
          tmpId = (uuidv4() as any) as number;
          this.viewerLayerService.initViewerLayer({ ...layer, id: tmpId });
          this.viewerLayerService.showLayer({ ...layer, id: tmpId });
        }
        const nameRegExp = new RegExp(`^${layer.type}\\s[0-9]+$`);
        const filteredLayers = layers.filter(
          (entity) => entity.type === layer.type && nameRegExp.test(entity.name),
        );
        const max = filteredLayers.length
          ? Math.max(
              ...filteredLayers.map((entity) =>
                parseInt(entity.name.replace(new RegExp(`${layer.type}\\s`), ''), 10),
              ),
            ) + 1
          : 1;

        const name = layer.isSaved === false ? layer.name : `${layer.type} ${max}`;
        const body = this.layerUtilsService.createRequestBody({ ...layer, name });
        delete body.id;
        if (commit) {
          return this.layerHttpService.postBatchLayers([body]).pipe(
            map(
              ([respLayer]) => {
                return addLayerSuccess({
                  layer: { ...respLayer, isSaved: true },
                  tmpLayerId: tmpId,
                });
              },
              catchError(() => EMPTY),
            ),
          );
        } else {
          return EMPTY.pipe(
            startWith(true),
            withLatestFrom(this.layersService.defaultFolderId$),
            map(
              ([_, defaultFolderId]) => {
                const newLayer = {
                  ...layer,
                  name,
                  id: (uuidv4() as any) as number,
                  parentId: defaultFolderId,
                  isSaved: false,
                };
                return addLayerSuccess({ layer: newLayer, tmpLayerId: tmpId });
              },
              catchError(() => EMPTY),
            ),
          );
        }
      }),
    );
  });

  addLayerSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(addLayerSuccess),
        tap(({ layer, tmpLayerId }) => {
          if (tmpLayerId) {
            this.viewerLayerService.deleteLayer(tmpLayerId);
            this.viewerLayerService.initViewerLayer(layer);
            this.viewerLayerService.showLayer(layer);
            this.layersService.setSelectedLayers([layer]);
          }
          if (layer.isSaved) {
            this.layersService.setLoadingLayers([]);
            this.toastrService.show(LAYER_CREATE_SUCCESS, SUCCESS, {
              ...SUCCESS_TOASTR_CONFIG,
            });
          }
        }),
      );
    },
    { dispatch: false },
  );

  addFolderLayer = createEffect(() => {
    return this.actions.pipe(
      ofType(addFolderLayer),
      mergeMap(({ layer }) => {
        const body = this.layerUtilsService.createRequestBody({ ...layer });
        return this.layerHttpService.postBatchLayers([body]).pipe(
          map(
            ([respLayer]) => {
              return addFolderLayerSuccess({
                layer: { ...respLayer, isSaved: true },
              });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  addFolderLayerSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(addFolderLayerSuccess),
        tap(({ layer }) => {
          if (!(layer.data as FolderData).isRoot) {
            this.toastrService.show(FOLDER_CREATE_SUCCESS, SUCCESS, {
              ...SUCCESS_TOASTR_CONFIG,
            });
            this.layersService.setCreatingFolderActive(false);
          }
        }),
      );
    },
    { dispatch: false },
  );

  addTmpLayer = createEffect(
    () => {
      return this.actions.pipe(
        ofType(addTmpLayer),
        tap(({ layer }) => {
          this.viewerLayerService.addTmpLayer({ ...layer });
        }),
      );
    },
    { dispatch: false },
  );
}
