import { ProcessingTableFilterForm } from '@App/app/entities/forms/processing-table-filter-form.model';
import {
  BuildProcessStatusId,
  BuildProcessTypeId,
  SubscriptionsStatusId,
} from '@App/app/entities/processing/build-process-status.model';
import { AdvancedSearchProcessing } from '@App/app/entities/shared/advanced-search.model';
import { User } from '@App/app/entities/users/user';
import { ProcessesSitesService } from '@App/app/pages/sites/shared/site-services/processes-sites.service';
import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseFilterComponent } from '../../shared/base-filter/base-filter.component';
import { resetFilter } from '../../shared/utils';
import { ProcessingTableFiltersService } from './processing-table-filters.service';

@UntilDestroy()
@Component({
  selector: 'app-processing-table-filters',
  templateUrl: './processing-table-filters.component.html',
  styleUrls: ['../../shared/base-filter/base-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ProcessingTableFiltersComponent extends BaseFilterComponent<
  AdvancedSearchProcessing,
  ProcessingTableFilterForm,
  ProcessingTableFiltersService,
  ProcessesSitesService
> {
  owners: User[];
  subscriptionStatusId = SubscriptionsStatusId;
  buildProcessTypeId = BuildProcessTypeId;
  buildProcessStatusId = BuildProcessStatusId;

  constructor(
    cdr: ChangeDetectorRef,
    filterService: ProcessingTableFiltersService,
    siteService: ProcessesSitesService,
  ) {
    super(cdr, siteService, filterService);
  }

  onSubmit(form: ProcessingTableFilterForm, dismissPopover = true) {
    this.setAdvancedSearch(form.value as AdvancedSearchProcessing);
    this.previousSearch = { ...form.value } as AdvancedSearchProcessing;
    if (dismissPopover) {
      this.isFilterPopoverActive = false;
    }
  }

  isFormTouched() {
    return (
      super.isFormTouched() ||
      this.isSomeFieldSelected(this.filterForm.value.type) ||
      this.isSomeFieldSelected(this.filterForm.value.status) ||
      this.isSomeFieldSelected(this.filterForm.value.subscription)
    );
  }

  typeOf(item: { key: string; value: string | number }) {
    return typeof item.value;
  }

  initAdvancedSearch() {
    Object.keys(BuildProcessTypeId).forEach((element) => {
      if (!isNaN(Number(element))) {
        const typeFormGroup = this.filterForm.controls['type'] as UntypedFormGroup;
        typeFormGroup.addControl(element + '', new UntypedFormControl(false));
      }
    });
    Object.keys(BuildProcessStatusId).forEach((element) => {
      if (!isNaN(Number(element))) {
        const typeFormGroup = this.filterForm.controls['status'] as UntypedFormGroup;
        typeFormGroup.addControl(element + '', new UntypedFormControl(false));
      }
    });
    Object.keys(SubscriptionsStatusId).forEach((element) => {
      if (isNaN(Number(element))) {
        const subscriptionFormGroup = this.filterForm.controls['subscription'] as UntypedFormGroup;
        subscriptionFormGroup.addControl(
          (SubscriptionsStatusId as any)[element] + '',
          new UntypedFormControl(false),
        );
      }
    });
  }

  resetFilters() {
    if (this.previousSearch) {
      this.filterForm.reset({
        ...this.previousSearch,
      });
    } else {
      const { query, type, status } = this.filterForm.value;
      if (type) resetFilter(type);
      if (status) resetFilter(status);
      this.filterForm.reset({
        query,
        type,
        status,
        dateStart: null,
        dateEnd: null,
      });
    }
  }
}
