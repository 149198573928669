import { RECT_LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isLayerRectType',
})
export class IsLayerRectTypePipe implements PipeTransform {
  transform(layer: LayerUI): boolean {
    return Object.values(RECT_LAYER_TYPES).includes(layer.type as any);
  }
}
