import { AuthData } from '@App/app/entities/auth/auth-data.model';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { RefreshedTokens } from '@App/app/entities/auth/tokens.model';
import { UiSettings } from '@App/app/entities/users/user';
import { createAction, props } from '@ngrx/store';

export const loadAuthDataSuccess = createAction(
  '[Auth] Load user data success',
  props<{ authData: AuthData }>(),
);

export const removeAuthDataSuccess = createAction('[Auth] Remove auth data success');

export const refreshTokensSuccess = createAction(
  '[Auth] Refresh tokens success',
  props<{ tokens: RefreshedTokens }>(),
);

export const changeCurrentEndpointSuccess = createAction(
  '[Auth] Change current endpoint success',
  props<{ endpoint: Endpoint }>(),
);

export const updateUserUiSettingsLocally = createAction(
  '[Auth] Update user ui settings locally',
  props<{ uiSettings: UiSettings }>(),
);
