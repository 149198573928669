import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class S3Service extends ApiService {
  uploadFile(url: string, file: File) {
    return this.http.put(url, file);
  }
}
