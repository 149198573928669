export interface Srs {
  code: string;
  projection: string;
}

export const DEFAULT_SRS = {
  code: `East-North-Up (ENU)`,
  projection: '',
};

export const DEFAULT_SRS_LIST = [
  DEFAULT_SRS,
  {
    code: `EPSG:4978`,
    projection: 'ECEF (Earth-Centered, Earth-Fixed)',
  },
  {
    code: `EPSG:4326`,
    projection: 'WGS84 (World Geodetic System 1984)',
  },
  {
    code: `EPSG:32631`,
    projection: 'WGS84 / UTM Zone 31N',
  },
];
