import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { compressImage, dataURLtoFile } from '@App/app/shared/utils/files.utils';
import { NbDialogRef } from '@nebular/theme';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageCropperDialogComponent implements OnInit {
  image: File;
  compressed = false;
  loaded = false;
  croppedBlob: string | null = null;

  constructor(protected dialogRef: NbDialogRef<ImageCropperDialogComponent>) {}

  async ngOnInit() {
    this.image = await this.reduceFileDimensions(this.image);
    this.compressed = true;
  }

  onCrop(event: ImageCroppedEvent) {
    this.croppedBlob = event.base64 || null;
  }

  onLoad() {
    this.loaded = true;
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  onConfirm() {
    if (this.croppedBlob) {
      this.dialogRef.close(this.croppedBlob);
    }
  }

  private reduceFileDimensions(file: File) {
    return new Promise<File>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = async () => {
          const { src, height, width } = img;
          const reduceRate = width / (document.documentElement.clientWidth / 2);
          const newWidth = width / reduceRate;
          const newHeight = height / reduceRate;
          const newBlob = await compressImage(src, newWidth, newHeight);
          const newFile = dataURLtoFile(newBlob, file.name);
          resolve(newFile);
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    });
  }
}
