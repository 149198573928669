import { WARNING } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { Photo } from '@App/app/entities/files/files.model';
import { Coordinates2 } from '@App/app/entities/layer/measurements/coordinates';
import {
  calculateAbsoluteToRelativeCoordsByImage,
  calculateRelativeToAbsoluteCoordsByImage,
  resizeImageInWrapper,
} from '@App/app/shared/utils/scaling.utils';
import { Zoom } from '@App/app/shared/utils/zoom.utils';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Subscription, combineLatest, fromEvent, merge } from 'rxjs';
import { delay, filter, first, tap } from 'rxjs/operators';
import { TargetPhotoDialogComponent } from './target-photo-dialog/target-photo-dialog.component';

@UntilDestroy({ arrayName: '_activeSubscriptions' })
@Component({
  selector: 'app-processing-photo-dialog',
  templateUrl: './processing-photo-dialog.component.html',
  styleUrls: ['./processing-photo-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingPhotoDialogComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', { static: true })
  private _wrapper: ElementRef<HTMLDivElement>;
  @ViewChild('target')
  private _target: TargetPhotoDialogComponent;
  @ViewChild('wrapperImg', { static: true })
  private _image: ElementRef<HTMLImageElement>;
  private _zoom: Zoom | null = null;
  private _activeSubscriptions: Subscription[] = [];
  private _croppingImage: HTMLImageElement;
  @Input() photo: Photo;
  pickTarget = new EventEmitter<Coordinates2 | null>();
  loading = true;
  target: Coordinates2 | null = null;
  sidebarWidthPx = 200;
  croppingImageData = new BehaviorSubject<ImageData | null>(null);

  constructor(
    private _dialogRef: NbDialogRef<ProcessingPhotoDialogComponent>,
    private _cdr: ChangeDetectorRef,
    private _toastrService: NbToastrService,
  ) {}

  ngOnInit() {
    this._prepareCroppingImage();

    this._activeSubscriptions.push(
      fromEvent(this._croppingImage, 'load').subscribe(() => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const { width, height } = this._croppingImage;
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(this._croppingImage, 0, 0);
        const data = ctx?.getImageData(0, 0, width, height) || null;
        this.croppingImageData.next(data);
      }),
      combineLatest([
        fromEvent(this._image.nativeElement, 'load'),
        this.croppingImageData.pipe(filter(Boolean)),
      ])
        .pipe(
          tap(() =>
            resizeImageInWrapper(this._image.nativeElement, this._wrapper.nativeElement, true),
          ),
          delay(50),
          tap(() => this._setZoom()),
          first(),
          delay(50),
          tap(() => {
            setTimeout(() => {
              if (this.target) {
                const { x, y } = this.target;
                this._updateTarget(x, y);
              }
            });
          }),
        )
        .subscribe(() => {
          this.loading = false;
          this._addTargetPickingEvents();
          this._cdr.detectChanges();
        }),
    );
  }

  ngOnDestroy(): void {
    this._zoom?.destroy();
  }

  onClose() {
    this._dialogRef.close();
  }

  initTarget(x: number, y: number) {
    this.target = { x, y };
  }

  removeTarget() {
    this.target = null;
    this.pickTarget.next(null);
  }

  private _prepareCroppingImage() {
    if (!window.Worker) {
      this._toastrService.show('Cropping feature is unavailable', WARNING, WARNING_TOASTR_CONFIG);
    }

    this._croppingImage = new Image();
    this._croppingImage.crossOrigin = 'Anonymous';
    this._croppingImage.src = this.photo.downloadURL + '?---';
  }

  private _setZoom() {
    this._zoom = new Zoom(
      this._image.nativeElement,
      this._wrapper.nativeElement,
      () => this._updateTargetOnZoomChange(),
      this.sidebarWidthPx,
    );
  }

  private _addTargetPickingEvents() {
    const targetHTML = this._wrapper.nativeElement;
    this._activeSubscriptions.push(
      merge(
        fromEvent(targetHTML, 'mousemove'),
        fromEvent(targetHTML, 'mouseup'),
        fromEvent(targetHTML, 'mousedown'),
      )
        .pipe(filter((e: MouseEvent) => e.buttons === 1))
        .subscribe((e: MouseEvent) => this._updateTargetFromEvent(e)),
    );
  }

  private _updateTargetFromEvent(e: MouseEvent) {
    e.preventDefault();
    const { offsetX, offsetY } = e;
    const [absX, absY] = calculateRelativeToAbsoluteCoordsByImage(
      { x: offsetX, y: offsetY },
      this._image.nativeElement,
    );
    this._updateTarget(absX, absY);
    this.pickTarget.next({ x: absX, y: absY });
  }

  private _updateTargetOnZoomChange() {
    if (!this.target) {
      return;
    }

    const { x, y } = this.target;
    this._updateTarget(x, y);
  }

  private _updateTarget(absX: number, absY: number) {
    /**
     * absX and absY mean those values should be positioned by
     * real image dimensions (e.g. center for img 9000x6000 would
     * be like absX=4500, absY=3000)
     */
    this.target = { x: absX, y: absY };
    const [relX, relY] = calculateAbsoluteToRelativeCoordsByImage(
      { ...this.target },
      this._image.nativeElement,
    );
    this._cdr.detectChanges();
    this._target?.update(relX, relY);
  }
}
