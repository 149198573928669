import { Injectable } from '@angular/core';
import exifr from 'exifr';
import { Papa } from 'ngx-papaparse';
import piexifjs from 'piexifjs';
import { ALTITUDE_REF, FileMetadataInfo } from '../../../models/uploaded-image-metadata.model';

@Injectable({ providedIn: 'root' })
export class XlifService {
  constructor(private papa: Papa) {}

  async getXlifDataFromFiles(photos: File[]) {
    const result = [];
    for (const photo of photos) {
      const coordInfo = await this.getCoordsFromExifData(photo);
      result.push(new FileMetadataInfo(photo.name, coordInfo?.coords, coordInfo?.altitude));
    }
    return result;
  }

  async getXlifDataFromFlatFile(csvFile: File) {
    const result: FileMetadataInfo[] = [];
    const parsedData = (await this.readCSV(csvFile)) as string[][];
    const indexes = this.flatFileIndexes(parsedData);

    if (indexes) {
      parsedData.forEach((element, index) => {
        if (index > indexes.headerIndex && element.length > 3) {
          result.push(
            new FileMetadataInfo(
              element[0],
              { latitude: +element[indexes.lat], longitude: +element[indexes.long] },
              +element[indexes.alt],
            ),
          );
        }
      });
    }
    return result;
  }

  private findIndex(row: string[], name: string) {
    return row.findIndex((item) => item.toLowerCase().includes(name));
  }

  private flatFileIndexes(data: string[][]) {
    const headerIndex = data.findIndex(
      (row) => row.length > 3 && row.join().toLowerCase().includes('long'),
    );
    if (headerIndex === -1) {
      return null;
    }
    return {
      headerIndex,
      lat: this.findIndex(data[headerIndex], 'lat'),
      long: this.findIndex(data[headerIndex], 'long'),
      alt: this.findIndex(data[headerIndex], 'alt'),
    };
  }

  async removeXlif(photo: File | undefined): Promise<File> {
    if (!photo) {
      return Promise.reject();
    }
    return new Promise((resolve, reject) => {
      const type = photo.type;
      const name = photo.name;
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (!result) {
          return reject();
        }
        const noMetadataImage = piexifjs.remove(result);
        fetch(noMetadataImage)
          .then((res) => {
            if (res.ok) {
              return res.blob();
            }
            reject();
          })
          .then((blob) => {
            if (!blob) {
              reject();
            } else {
              resolve(new File([blob], name, { type }));
            }
          });
      };
      reader.readAsDataURL(photo);
    });
  }

  private async readCSV(csvFile: File) {
    return new Promise((resolve) => {
      this.papa.parse(csvFile, {
        header: false,
        complete: (results) => {
          resolve(results.data);
        },
      });
    });
  }

  private async getCoordsFromExifData(file: File) {
    const altitude = await this.altitude(file);
    const coords = await exifr.gps(file);
    if (coords && altitude) {
      return { coords, altitude };
    } else {
      return null;
    }
  }

  private async altitude(file: File) {
    const altitude = (await exifr.parse(file, ['GPSAltitude']))?.GPSAltitude;
    const altitudeRef = Number((await exifr.parse(file, ['GPSAltitudeRef']))?.GPSAltitudeRef);
    return altitudeRef === ALTITUDE_REF.BELOW_SEA_LEVEL ? -altitude : altitude;
  }
}
