import { BaseFile } from '@App/app/entities/files/files.model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/model-files.actions';

export const modelFilesAdapter = createEntityAdapter<BaseFile>();

export type State = EntityState<BaseFile>;

const defaultModels: State = {
  ids: [],
  entities: {},
};

export const initialState: State = modelFilesAdapter.getInitialState(defaultModels);

export const modelFilesReducer = createReducer(
  initialState,
  on(actions.loadModelFilesSuccess, (state, { objects: files }) => {
    return modelFilesAdapter.setAll(files, state);
  }),
  on(actions.createModelFilesSuccess, (state, { objects: files }) => {
    return modelFilesAdapter.addMany(files, state);
  }),
  on(actions.deleteModelFilesSuccess, (state, { ids }) => {
    return modelFilesAdapter.removeMany(ids, state);
  }),
  on(actions.removeCurrentModelFilesLocal, (state) => {
    return modelFilesAdapter.removeAll(state);
  }),
);
