import { RESOURCE_IS_GENERATING, SUCCESS, WARNING } from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { ExportsHttpService } from '@App/app/engine/services/exports-http-service/exports-http.service';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import formatUnicorn from 'format-unicorn/safe';
import { throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { selectAllLayers } from '../../store/selectors/layers.selectors';
import { isLayerDXFExportable } from '../../utils/layers.utils';

@Injectable({
  providedIn: 'root',
})
export class DxfExportService {
  constructor(
    private _exportsHttpService: ExportsHttpService,
    private _toastrService: NbToastrService,
    private store: Store,
  ) {}

  exportAllLayersToDXF() {
    let allLayers: LayerUI[] = [];
    this.store
      .select(selectAllLayers)
      .pipe(first())
      .subscribe((layers) => {
        allLayers = layers;
      });

    return this.exportLayersToDXF(allLayers);
  }

  exportLayersToDXF(layers: LayerUI[]) {
    const exportableLayers = layers.filter((layer) => isLayerDXFExportable(layer));

    if (!exportableLayers.length) {
      this._toastrService.show('No layers to export', WARNING, WARNING_TOASTR_CONFIG);
      return;
    }

    const ids = exportableLayers.map((layer) => layer.id);
    this._exportsHttpService
      .postDxfExport(ids)
      .pipe(catchError((err) => throwError(err)))
      .subscribe(() => {
        this._toastrService.show(
          formatUnicorn(RESOURCE_IS_GENERATING, { resourceName: '.dxf file' }),
          SUCCESS,
          { ...SUCCESS_TOASTR_CONFIG, duration: 5000 },
        );
      });
  }
}
