import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { CustomScene, gizmoManagerTypes } from '@App/app/entities/viewer/custom-scene.model';
import {
  GizmoManager,
  LinesMesh,
  Mesh,
  TransformNode,
  UtilityLayerRenderer,
  Vector3,
} from 'babylonjs';
import { LAYER_NAMES } from '../../enums/layer-names.enum';
import { LAYER_TYPES } from '../../enums/layer-types.enum';
import { httpLayer } from '../../layer.model';
import { NameTag } from '../../name-tag.model';
import { SketchBaseTool } from './sketch-base-tool.model';

export class SketchCircle extends SketchBaseTool<SketchCircleData> {
  name = LAYER_NAMES.SKETCH_CIRCLE;
  type = LAYER_TYPES.SKETCH_CIRCLES;
  data: SketchCircleData;

  // babylon
  gizmoManager?: GizmoManager;
  circleMesh: LinesMesh | null;
  centerMesh: Mesh | null;
  edgeMesh: Mesh | null;
  root: TransformNode | null;
  rotate: Vector3;

  // layer
  gui: NameTag;

  static createBodyForHttpRequest(sketchCircle: SketchCircle): httpLayer {
    return {
      name: sketchCircle.name,
      isVisible: sketchCircle.isVisible || false,
      type: sketchCircle.type,
      parentId: sketchCircle.parentId,
      data: {
        type: LAYER_TYPES.SKETCH_CIRCLES,
        radius: sketchCircle.data.radius,
        edgePosition: sketchCircle.edgeMesh
          ? {
              x: sketchCircle.edgeMesh.absolutePosition.x,
              y: sketchCircle.edgeMesh.absolutePosition.y,
              z: sketchCircle.edgeMesh.absolutePosition.z,
            }
          : sketchCircle.data.edgePosition,
        centerPosition: sketchCircle.centerMesh
          ? {
              x: sketchCircle.centerMesh.absolutePosition.x,
              y: sketchCircle.centerMesh.absolutePosition.y,
              z: sketchCircle.centerMesh.absolutePosition.z,
            }
          : sketchCircle.data.centerPosition,
        rotation: sketchCircle.rotate
          ? {
              x: sketchCircle.rotate.x,
              y: sketchCircle.rotate.y,
              z: sketchCircle.rotate.z,
            }
          : sketchCircle.data.rotation,
      },
    };
  }

  static getInitialData(): SketchCircleData {
    return {
      type: LAYER_TYPES.SKETCH_CIRCLES,
      radius: null,
      edgePosition: null,
      centerPosition: null,
      rotation: null,
    };
  }

  constructor(
    sketchPlaneId: number,
    id: number,
    rootName?: string,
    scene?: CustomScene,
    utilityLayer?: UtilityLayerRenderer,
  ) {
    super(sketchPlaneId, id);
    if (rootName && scene && utilityLayer) {
      this.id = id;
      this.gizmoManager = new GizmoManager(scene, 1, utilityLayer, utilityLayer);
      this.gizmoManager.attachableMeshes = [];
      this.gizmoManager.clearGizmoOnEmptyPointerEvent = true;
      this.root = new TransformNode(rootName, scene);
      scene.__gizmoManagerList[gizmoManagerTypes.sketchCircles].push(this.gizmoManager);
    }
  }
}

export interface SketchCircleData {
  type: string;
  radius: number | null;
  edgePosition: Coordinates3 | null;
  centerPosition: Coordinates3 | null;
  rotation: Coordinates3 | null;
}
