import { AuthService } from '@App/app/auth/auth.service';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CompanySelectionService {
  constructor(private router: Router, private authService: AuthService) {}

  onAdminPanelClick() {
    this.router.navigate([`/admin-panel`]);
  }

  onClick(endpoint: Endpoint) {
    this.authService.setCurrentEndpoint(endpoint);
    this.router.navigate([`/${endpoint.id}/models`]);
  }
}
