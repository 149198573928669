import { AuthService } from '@App/app/auth/auth.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Thumbnail } from '@App/app/entities/files/files.model';
import { Model } from '@App/app/entities/models/model.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ExtendedRender, Render } from '@App/app/entities/processing/render.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { loadAllProcesses } from '@App/app/pages/processing/store/processes/actions/processes.actions';
import { selectProcessesByModelId } from '@App/app/pages/processing/store/processes/selectors/processes.selectors';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { filter, map, skip, switchMap, take } from 'rxjs/operators';
import { ModelsService } from '../../services/models-service/models.service';
import { RendersService } from '../../services/renders-service/renders.service';
import { selectSingleThumbnail } from '../../store/models/selectors/model-files.selectors';
import { selectCurrentModel } from '../../store/models/selectors/models.selectors';

@UntilDestroy({ arrayName: 'activeSubscriptions' })
@Component({
  selector: 'app-model-overview',
  templateUrl: './model-overview.component.html',
  styleUrls: ['./model-overview.component.scss'],
})
export class ModelOverviewComponent implements OnInit {
  private activeSubscriptions: Subscription[] = [];
  companyId: number | null = null;
  model: Model | null = null;
  renders: Render[] | null = null;
  currentRender: ExtendedRender | null = null;
  lastBuildProcess: BuildProcess | null = null;
  permissions = UserPermissions;
  thumbnail: Thumbnail | null = null;

  constructor(
    private authService: AuthService,
    private store: Store,
    private modelsService: ModelsService,
    private rendersService: RendersService,
    private modelsProcessingService: ModelsProcessingService,
    private cdr: ChangeDetectorRef,
    private _permissionsService: NgxPermissionsService,
  ) {}

  ngOnInit() {
    this.activeSubscriptions.push(
      this.authService.currentEndpoint$.subscribe((endpoint) => {
        this.companyId = endpoint?.id || null;
        this.cdr.detectChanges();
      }),
      this.store.select(selectSingleThumbnail).subscribe((thumbnail) => {
        this.thumbnail = thumbnail;
      }),
      this.rendersService.currentRender$.subscribe((render) => {
        this.currentRender = render;
      }),
      this.rendersService.renders$.pipe(filter(Boolean), take(1)).subscribe((renders: Render[]) => {
        this.renders = renders;
        this.cdr.detectChanges();
      }),
      this.store.select(selectCurrentModel).subscribe((model: Model) => {
        this.model = { ...model };
        this.cdr.detectChanges();
        this._loadLastBuildProcessIfIsAvailable();
      }),
    );
  }

  onArchiveModel(model: Model) {
    this.modelsService.archiveModel(model);
  }

  onDeleteModel(model: Model) {
    this.modelsService.deleteSingleModelHandler(model);
  }

  private _loadLastBuildProcessIfIsAvailable() {
    const hasPermissions = this._permissionsService.getPermission(
      this.permissions.CAN_ACCESS_PROCESSING_VIEWER,
    );
    if (this.model && !!hasPermissions) {
      this.store
        .select(selectProcessesByModelId(+this.model.id))
        .pipe(
          skip(1),
          // eslint-disable-next-line ngrx/avoid-mapping-selectors
          map((processes) => this._getLastBuildProcess(processes)),
          take(1),
          filter<BuildProcess>(Boolean),
          switchMap((process) =>
            this.modelsProcessingService.isBuildProcessAccessible(process.id).pipe(
              filter(Boolean),
              map(() => process),
            ),
          ),
        )
        .subscribe((process) => {
          this.lastBuildProcess = process;
        });

      this.store.dispatch(loadAllProcesses());
    }
  }

  private _getLastBuildProcess(processes: BuildProcess[]) {
    const sortedProcesses = [...processes].sort(
      (a, b) => new Date(b.heartbeatAt as any).getTime() - new Date(a.heartbeatAt as any).getTime(),
    );
    return sortedProcesses.length ? sortedProcesses[0] : null;
  }
}
