import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Role } from '../../../entities/users/user';
import { RolesHttpService } from './../roles-http.service';

@UntilDestroy()
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent {
  @Input() parentForm: FormGroup;
  @Input() editUser: boolean;
  @Input() requestSended: boolean | null;
  @Input() initialRoles: number[] = [];
  @Output() submitted: EventEmitter<any> = new EventEmitter();

  allRoles: Role[];
  permissions = UserPermissions;
  showPassword = false;
  showConfirmPassword = false;

  constructor(private rolesHttpService: RolesHttpService) {
    this.rolesHttpService.getAllRoles().subscribe((roles) => {
      this.allRoles = roles;
    });
  }

  onSubmit() {
    this.submitted.emit(this.parentForm.value);
  }

  required(...path: string[]) {
    return !!(
      this.parentForm.get(`${path.join('.')}`)?.hasError('required') &&
      this.parentForm.get(`${path.join('.')}`)?.touched
    );
  }
}
