import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Folder } from '@App/app/entities/layer/folder.model';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import {
  addFolderLayer,
  deleteLayerSuccess,
} from '@App/app/pages/viewer/store/actions/layers.actions';
import { selectAllLayers } from '@App/app/pages/viewer/store/selectors/layers.selectors';
import {
  copyLayers,
  getBasePlane,
  getLayersByIdsFromOtherLayers,
  getRootFolder,
  splitItemsToFoldersAndLayers,
} from '@App/app/pages/viewer/utils/layers.utils';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { LayersHttpActionsService } from '../layers-http-actions-service/layers-http-actions.service';
import { LayersViewerUtilsService } from '../layers-viewer-utils-service/layers-viewer-utils.service';

@Injectable()
export class LayersUtilsService {
  constructor(
    private store: Store,
    private _layersViewerUtilsService: LayersViewerUtilsService,
    private _layersHttpActionsService: LayersHttpActionsService,
    private _broadcastService: BroadcastService,
  ) {}

  createRootFolder(renderId: number) {
    this.createFolder('Root', true, renderId);
  }

  createFolder(name: string, root?: boolean, renderId?: number) {
    const newFolder = new Folder();
    newFolder.name = name;
    newFolder.data = {
      isRoot: root || newFolder.data.isRoot,
      renderId,
    };
    this.store.dispatch(
      addFolderLayer({
        layer: newFolder as LayerUI,
      }),
    );
  }

  clearDetailsView(detailsViewLayers: LayerUI[], editedLayers: LayerUI[]) {
    let allLayers: LayerUI[];
    this.store
      .select(selectAllLayers)
      .pipe(take(1))
      .subscribe((layers) => {
        allLayers = layers;
      });

    detailsViewLayers
      .filter((layer) => editedLayers.includes(layer))
      .forEach((layer) => {
        this._layersViewerUtilsService.setLayerEditMode(
          layer,
          false,
          allLayers,
          editedLayers,
          true,
        );
        this._layersViewerUtilsService.loadBackupLayer(layer.id);
        if (layer.type === LAYER_TYPES.SKETCH_PLANES) {
          this._broadcastService.broadcast(EVENT_TYPE.EDIT_SKETCHES, {
            sketchPlane: layer,
            isAddPlaneActive: false,
            resetSketchesCurrentPlane: true,
            removeSketchDrafts: true,
          });
        }
      });
  }

  deleteLayerFromViewer(layer: LayerUI, allLayers: LayerUI[], editedLayers: LayerUI[]) {
    if (layer.isSaved) {
      this._layersHttpActionsService.deleteLayer(layer);
    } else {
      this._layersViewerUtilsService.setLayerEditMode(layer, false, allLayers, editedLayers, true);
      this.store.dispatch(deleteLayerSuccess({ id: layer.id }));
    }
  }

  getNewLayersSet(
    allLayers: LayerUI[],
    editingFolder: LayerUI | null,
    selectedLayers: LayerUI[],
    detailsViewLayers: LayerUI[],
  ) {
    const { folders, layers } = splitItemsToFoldersAndLayers(allLayers);
    const isBasePlaneCreated = !!getBasePlane(layers);
    const root = getRootFolder(folders);
    let defaultFolderId: number | null = null;
    if (root) defaultFolderId = root.id;
    const foldersCopy = copyLayers(folders);
    const layersCopy = copyLayers(layers);
    const newFolder = foldersCopy.find(({ id }) => id && id === editingFolder?.id);
    editingFolder = newFolder || null;
    selectedLayers = getLayersByIdsFromOtherLayers(selectedLayers, allLayers);
    detailsViewLayers = detailsViewLayers.reduce((acc, item) => {
      const layer = layers.find((i) => item.id === i.id && item.type === i.type);
      return layer ? [...acc, layer] : acc;
    }, []);
    let visibleLayers: LayerUI[] | null = null;
    if (!detailsViewLayers.length) {
      visibleLayers = layers.filter((layer) => layer.isVisible);
    }
    return {
      isBasePlaneCreated,
      defaultFolderId,
      folders: foldersCopy,
      layers: layersCopy,
      editingFolder,
      selectedLayers,
      detailsViewLayers,
      visibleLayers,
    };
  }
}
