<div class="layer_details" [nbSpinner]="loading">
  <app-layer-details-name
    [name]="layer.name"
    [createdAt]="layer.createdAt"
    [type]="layer.type"
    [editForm]="editForm"
    [isEditModeOnInitMappingTool]="isEditModeOnInitMappingTool()"
    [isChangeNameActive]="layer | isLayerBeingEdited"
    (changeName)="onChangeName($event)"
  ></app-layer-details-name>

  <div class="layer_details__body">
    <app-layer-details-properties
      [layer]="layer"
      [editForm]="editForm"
    ></app-layer-details-properties>

    <app-layer-details-description
      [layer]="layer"
      [editForm]="editForm"
      class="layer_details__row left description"
    ></app-layer-details-description>
  </div>

  <div class="layer_details__actions" *ngIf="layer | isLayerRectType">
    <app-layer-details-recalculate-props
      class="layer_details__actions__recalculate_props"
      [layer]="layer | asType"
      (clicked)="onRecalculateClick()"
      (cancel)="onRecalculateCancel()"
    ></app-layer-details-recalculate-props>
  </div>

  <div
    class="layer_details__actions"
    *ngIf="!(isDeleteActive$ | async) && !(layer | isLayerBeingEdited)"
  >
    <ng-container *ngxPermissionsOnly="permissions.CAN_EDIT_LAYER">
      <button
        type="button"
        class="layer_details__action layer_details__action--edit select-none"
        (click)="onEditClick()"
      >
        Edit
      </button>
    </ng-container>
    <ng-container *ngxPermissionsOnly="permissions.CAN_DELETE_LAYER">
      <button
        type="button"
        class="layer_details__action layer_details__action--delete select-none"
        (click)="onDeleteClick()"
        *ngIf="layer | isLayerDeletable"
      >
        Delete
      </button>
    </ng-container>
  </div>

  <div
    class="layer_details__actions"
    *ngIf="(isDeleteActive$ | async) && (layer | isLayerDeletable)"
  >
    <span class="layer_details__info select-none">Are you sure?</span>
    <button
      type="button"
      class="layer_details__action layer_details__action--cancel select-none"
      (click)="onDeleteCancel()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="layer_details__action layer_details__action--delete select-none"
      (click)="onDeleteConfirm()"
    >
      Delete
    </button>
  </div>
  <div class="layer_details__actions" *ngIf="layer | isLayerBeingEdited">
    <button
      type="button"
      *ngIf="!isEditModeOnInitMappingTool()"
      class="layer_details__action layer_details__action--cancel select-none"
      (click)="onEditCancel()"
    >
      Cancel
    </button>
    <button
      type="button"
      *ngIf="isEditModeOnInitMappingTool()"
      class="layer_details__action layer_details__action--delete select-none"
      (click)="onDeleteConfirm()"
    >
      Delete
    </button>
    <button
      type="button"
      nbButton
      status="info"
      size="tiny"
      class="layer_details__save select-none"
      [disabled]="hasThickness() ? !isThicknessFormValid() : false"
      (click)="onEditConfirm()"
    >
      {{ layer.isSaved === false ? 'Save' : 'Save Changes' }}
    </button>
  </div>
</div>
