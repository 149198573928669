import { BaseFile } from '@App/app/entities/files/files.model';
import { Model } from '@App/app/entities/models/model.model';
import { BuildProcessStatusId } from '@App/app/entities/processing/build-process-status.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ModelsProcessingHttpService } from '@App/app/pages/processing/services/models-processing-http-service/models-processing-http.service';
import { exponentialBackoffRetry } from '@App/app/shared/rxjs-operators/exponential-backoff-retry/exponential-backoff-retry';
import { S3Service } from '@App/app/shared/services/s3/s3.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { StartUploadDTO } from '../../../models/start-upload-dto.model';
import { UploaderHttpService } from '../../../services/uploader-http-service/uploader-http.service';
import { UploaderStorageService } from '../../../services/uploader-storage-service/uploader-storage.service';
import { selectFileByName } from '../../../store/files/selectors/files.selectors';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UploadApiService {
  constructor(
    private modelsProcessingHttpService: ModelsProcessingHttpService,
    private uploaderHttpService: UploaderHttpService,
    private uploaderStorageService: UploaderStorageService,
    private s3Service: S3Service,
    private store: Store,
  ) {}

  startStage(startUploadingObject: StartUploadDTO) {
    return this.uploaderHttpService.startUpload(startUploadingObject);
  }

  pauseStage(buildProcessId: number) {
    return this.uploaderHttpService.pauseUpload(buildProcessId);
  }

  cancelStage(buildProcessId: number) {
    return this.uploaderHttpService.cancelUpload(buildProcessId);
  }

  getPendingUploadProcess(id: number) {
    return this.uploaderHttpService.getPendingUploadStatus(id);
  }

  updateProgress(buildProcessId: number, modelId: number, progress: number, finish = false) {
    const data: Partial<BuildProcess> = {
      modelId,
      id: buildProcessId,
      buildProcessStatusId: finish ? BuildProcessStatusId.Finished : BuildProcessStatusId.Started,
      buildProcessTypeId: 1,
      progress,
    };

    if (finish) {
      data.finishedAt = new Date().toString();
    }

    return this.modelsProcessingHttpService.putBuildProcess(new BuildProcess(data));
  }

  getModels(): Observable<Model[]> {
    return this.uploaderHttpService.getModels().pipe(
      map(({ data: models }: { data: Model[] }) => {
        models
          .sort((a, b) => {
            return +new Date(a.createdAt) - +new Date(b.createdAt);
          })
          .reverse();
        return models;
      }),
    );
  }

  uploadFile(processFile: BaseFile, progressIncrease: number) {
    return this.store.select(selectFileByName(processFile.name)).pipe(
      take(1),
      filter<File>(Boolean),
      switchMap((file) => {
        this.uploaderStorageService.updateLogs(`Uploading image ${processFile.name} to S3`);
        const observableItem = from(this.uploadFileToS3(processFile.uploadURL, file)).pipe(
          untilDestroyed(this),
          takeUntil(this.uploaderStorageService.cancelUploadSubject),
          mergeMap((obs) => {
            if (obs === false) {
              return of();
            }
            this.uploaderStorageService.updateLogs(`Image ${processFile.name} uploaded`);
            let progress = this.uploaderStorageService.uploadProgress$.value + progressIncrease;
            progress = Math.min(100, progress);

            return this.updateProgress(
              this.uploaderStorageService.buildProcessId as number,
              this.uploaderStorageService.selectedModelId$.value as number,
              progress,
            ).pipe(
              first(),
              takeUntil(this.uploaderStorageService.cancelUploadSubject),
              tap(() => {
                this.uploaderStorageService.updateLogs(`Image ${processFile.name} metadata saved`);
                this.uploaderStorageService.uploadProgress$.next(progress);
              }),
            );
          }),
        );
        return observableItem;
      }),
    );
  }

  private uploadFileToS3(url: string, file: File) {
    return this.s3Service.uploadFile(url, file).pipe(
      exponentialBackoffRetry<HttpErrorResponse>(
        (error) => error.status >= 500,
        (error) => {
          this.uploaderStorageService.updateLogs(`Error occurred!: ${error.message}`);
          this.uploaderStorageService.updateLogs(`Retrying...`);
        },
      ),
      catchError((error: HttpErrorResponse) =>
        of(this.uploaderStorageService.updateLogs(`Error occurred!: ${error.message}`)),
      ),
    );
  }
}
