import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-filter-footer',
  templateUrl: './filter-footer.component.html',
  styleUrls: ['./filter-footer.component.scss'],
})
export class FilterFooterComponent {
  @Output() toggleFilterPopover = new EventEmitter();
  @Output() clearFilters = new EventEmitter();

  cancel() {
    this.toggleFilterPopover.emit();
  }

  clear() {
    this.clearFilters.emit();
    this.cancel();
  }
}
