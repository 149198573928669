import { LAYER_EDITION_SUCCESS, SUCCESS } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { LayerHttpService } from '@App/app/engine/services/layer-services/layer-http-service/layer-http.service';
import { LayerUtilsService } from '@App/app/engine/services/layer-services/layer-utils-service/layer-utils.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { httpLayer } from '@App/app/entities/layer/layer.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { LayersHttpActionsService } from '../../services/layers-http-actions-service/layers-http-actions.service';
import { LayersService } from '../../services/layers/layers.service';
import { selectAllLayers } from '../selectors/layers.selectors';
import { editLayers, editLayersSuccess, updateLayers } from './../actions/layers.actions';

@Injectable()
export class EditLayersEffects {
  constructor(
    private viewerLayerService: ViewerLayerService,
    private actions: Actions,
    private layerHttpService: LayerHttpService,
    private layerUtilsService: LayerUtilsService,
    private layersService: LayersService,
    private store: Store,
    private _engineService: CustomerEngineService,
    private _toastrService: NbToastrService,
    private _layersHttpActionsService: LayersHttpActionsService,
  ) {}

  editLayers = createEffect(() => {
    return this.actions.pipe(
      ofType(editLayers),
      withLatestFrom(this.store.select(selectAllLayers)),
      mergeMap(([{ layers: layersToUpdate }, layers]) => {
        const data = layersToUpdate.map((updateData) => {
          const { id, editedData, props } = updateData;
          const changes = editedData || this.viewerLayerService.getData(id);
          const updatedLayer = layers.find((layer) => layer.id === id);
          if (!updatedLayer) {
            return null;
          }
          const body = this.layerUtilsService.createRequestBody(updatedLayer);
          body.data = { ...changes };
          if (props) {
            props.forEach((prop) => {
              (body[prop.key as keyof httpLayer] as typeof prop.value) = prop.value;
            });
          }
          return body;
        });
        const nonNullData = data.filter((i) => !!i) as httpLayer[];
        return this.layerHttpService.putBatchLayers(nonNullData).pipe(
          map(
            (editedLayers) => {
              return editLayersSuccess({ layers: editedLayers });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  editLayerSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(editLayersSuccess),
      map(({ layers }) => {
        for (const layer of layers) {
          if (layer.type === LAYER_TYPES.SKETCH_PLANES) {
            this._layersHttpActionsService.updateSketchTools();
          }
          this.viewerLayerService.deleteLayer(layer.id);
          this.viewerLayerService.initViewerLayer(layer);
          this.viewerLayerService.showLayer(layer);
        }
        this.layersService.setLoadingLayers([]);
        this.layersService.setRecalculatedLayers([]);
        this._engineService.activeCursorMove(false, false);
        this._toastrService.show(LAYER_EDITION_SUCCESS, SUCCESS, SUCCESS_TOASTR_CONFIG);
        return updateLayers({
          updates: layers.map((layer) => ({
            id: layer.id,
            changes: { name: layer.name, data: layer.data },
          })),
        });
      }),
    );
  });
}
