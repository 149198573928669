import {
  INCLUDES_BASE_PLANE,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { SketchCircleService } from '@App/app/engine/services/layer-services/sketches-services/sketch-circle/sketch-circle.service';
import { SketchLineService } from '@App/app/engine/services/layer-services/sketches-services/sketch-line/sketch-line.service';
import { SketchRectangleService } from '@App/app/engine/services/layer-services/sketches-services/sketch-rectangle/sketch-rectangle.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import {
  AUTOMAPPING_IMPORTABLE_LAYER,
  LAYER_TYPES,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import {
  addLayer,
  addLayers,
  changeLayerProp,
  changeLayersProp,
  deleteLayer,
  deleteLayers,
  editLayer,
  editLayers,
  importLayers,
  updateLayer,
} from '@App/app/pages/viewer/store/actions/layers.actions';
import { selectChildLayers } from '@App/app/pages/viewer/store/selectors/layers.selectors';
import { checkCanBeDeleted } from '@App/app/pages/viewer/utils/layers.utils';
import { ConfirmationDialogComponent } from '@App/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { Injectable } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class LayersHttpActionsService {
  private _loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this._loading$.asObservable();

  constructor(
    private store: Store,
    private _viewerLayerService: ViewerLayerService,
    private _sketchCircleService: SketchCircleService,
    private _sketchLineService: SketchLineService,
    private _sketchRectangleService: SketchRectangleService,
    private _dialogService: NbDialogService,
    private _toastrService: NbToastrService,
  ) {}

  setLoading(value: boolean) {
    this._loading$.next(value);
  }

  importLayers(layers: AUTOMAPPING_IMPORTABLE_LAYER[]) {
    this.store.dispatch(importLayers({ layers }));
  }

  changeLayerName(name: string, layer: LayerUI) {
    this.store.dispatch(changeLayerProp({ id: layer.id, prop: { key: 'name', value: name } }));
  }

  changeLayersFolder(layerIds: number[], folderId: number, allLayers: LayerUI[]) {
    this.store.dispatch(
      changeLayersProp({ ids: layerIds, prop: { key: 'parentId', value: folderId } }),
    );
    const basePlane = allLayers.find((layer) => layer.type === LAYER_TYPES.BASE_PLANE);
    if (basePlane && layerIds.includes(basePlane.id)) {
      this._viewerLayerService.setNewParentId(basePlane.id, folderId);
    }
  }

  saveLayer(layer: LayerUI) {
    const data = this._viewerLayerService.getData(layer.id) as LayerUI['data'];
    const newLayer = { ...layer, data };
    this.store.dispatch(addLayer({ layer: newLayer }));
  }

  updateLayer(layer: LayerUI, children?: LayerUI[]) {
    if (layer.type === LAYER_TYPES.SKETCH_PLANES && children) {
      this.store.dispatch(
        editLayers({
          layers: [
            layer.name
              ? { id: layer.id, props: [{ key: 'name', value: layer.name }] }
              : { id: layer.id },
            ...children.map((child) => ({ id: child.id })),
          ],
        }),
      );
    }
    this.store.dispatch(
      editLayer({
        id: layer.id,
        props: [
          { key: 'name', value: layer.name },
          { key: 'description', value: layer.description || '' },
        ],
      }),
    );
  }

  deleteLayers(layers: LayerUI[]) {
    this.store.dispatch(deleteLayers({ ids: layers.map((layer) => layer.id), isForce: true }));
  }

  deleteLayer(layer: LayerUI) {
    this.store.dispatch(
      deleteLayer({
        id: layer.id,
        _type: layer.type,
        isForce: layer.type === LAYER_TYPES.SKETCH_PLANES,
      }),
    );
  }

  updateSketchTools() {
    const sketchRectangles = this._sketchRectangleService.getDraftSketches();
    const sketchCircles = this._sketchCircleService.getDraftSketches();
    const sketchLines = this._sketchLineService.getDraftSketches();
    const newLayers = [...sketchRectangles, ...sketchCircles, ...sketchLines];
    if (newLayers.length) {
      this.store.dispatch(
        addLayers({
          layers: newLayers,
          showToast: false,
        }),
      );
    }
  }

  openDeleteParentLayerDialog(id: number, layers: LayerUI[], folders: LayerUI[]) {
    if (checkCanBeDeleted(id, layers, folders)) {
      this._dialogService
        .open(ConfirmationDialogComponent, {
          context: {
            title: 'Are you sure you want to delete this folder?',
            details: 'There are layers assigned to this folder, decide what to do with them:',
            options: ['Move layers to the parent folder', 'Delete layers along with this folder'],
            status: 'danger',
          },
        })
        .onClose.subscribe((options: [boolean, boolean]) => {
          if (options && options.some((option) => option)) {
            const [isHoist, isForce] = options;
            this.store.dispatch(deleteLayer({ id, _type: LAYER_TYPES.FOLDERS, isForce, isHoist }));
            if (isHoist) {
              const parentId = folders.find((folder) => folder.id === id)?.parentId;
              if (parentId) {
                this.store
                  .select(selectChildLayers(id))
                  .pipe(take(1))
                  .subscribe((children) => {
                    children.forEach((layer) => {
                      this.store.dispatch(
                        updateLayer({ update: { id: layer.id, changes: { parentId } } }),
                      );
                    });
                  });
              }
            }
          } else {
            this._loading$.next(false);
          }
        });
    } else {
      this._loading$.next(false);
      const { message, title } = INCLUDES_BASE_PLANE;
      this._toastrService.warning(message, title, {
        ...WARNING_TOASTR_CONFIG,
      });
    }
  }
}
