import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { PpSetupForm } from '../../../../../entities/forms/pp-setup-tab-form.model';
import { ProcessingService } from '../../processing.service';
import { ppSettings } from '../../shared/models/pp-settings';
import { SetupTabComponent } from '../base-tabs/setup-tab.component';
@UntilDestroy()
@Component({
  selector: 'app-pp-setup-tab',
  templateUrl: './pp-setup-tab.component.html',
  styleUrls: ['./pp-setup-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PpSetupTabComponent extends SetupTabComponent<PpSetupForm> implements OnInit {
  static step = ProcessingSteps.PP_SETUP;
  static settings = ppSettings;
  protected cancelStep = ProcessingSteps.PROD_REVIEW;

  constructor(
    fb: NonNullableFormBuilder,
    processingService: ProcessingService,
    store: Store,
    _modelsProcessingService: ModelsProcessingService,
    private _permissionsService: NgxPermissionsService,
  ) {
    super(fb, processingService, store, _modelsProcessingService);
  }
  ngOnInit() {
    super.ngOnInit();
    this.isFailed =
      !!this.processingService.buildProcess?.isFailed() &&
      this.processingService.buildProcess.steps.pp.isCurrent();
    ppSettings[1].settings[0].tooltipText = !this.processingService.buildProcess?.withObj
      ? 'Cannot run Automapping without OBJ model'
      : '';
  }

  hasRunAutomappingPermission() {
    return (
      this._permissionsService.getPermission(UserPermissions.CAN_RUN_AUTOMAPPING) &&
      this.processingService.buildProcess?.withObj
    );
  }

  getSettings() {
    return ppSettings;
  }

  onSubmit(form: PpSetupForm) {
    this.startNext.emit({
      runAutomapping: form.controls.runAutomapping.value,
    });
  }

  restoreDefault() {
    this.form.reset();
  }

  protected createForm(): PpSetupForm {
    return this.fb.group({
      settingsPreset: this.fb.control(0, { validators: [Validators.required] }),
      runAutomapping: this.hasRunAutomappingPermission()
        ? this.fb.control(true, { validators: [Validators.required] })
        : this.fb.control({ value: false, disabled: true }),
    });
  }
}
