import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected api: string | null;

  constructor(protected authService: AuthService, protected http: HttpClient) {
    this.authService.endpointPath.subscribe((path) => {
      this.api = path;
    });
  }
}
