import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { EDIT_MODE } from '@App/app/configs/hints.config';

@Component({
  selector: 'app-create-layer-button',
  templateUrl: './create-layer-button.component.html',
  styleUrls: ['./create-layer-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateLayerButtonComponent implements OnInit {
  @Input() isEditMode: boolean;
  @Input() isModelLoaded: boolean;
  @Input() description: string;
  @Input() toolName: string;
  @Input() dataTestId: string;
  @Input() dataTestCypressId: string;
  @Input() activeTool: string;
  @Input() icon: string;
  @Input() iconType: string;
  @Input() func: () => void;
  EDIT_MODE = EDIT_MODE;

  constructor() {}

  ngOnInit(): void {}
}
