import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_EVENTS, LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Injectable } from '@angular/core';
import { NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { LayersService } from '../../../services/layers/layers.service';
import { ThicknessFormService } from '../../../services/thickness-utils/thickness-form.service';

@Injectable()
export class LayerDetailsUtilsService {
  constructor(
    private _toastrService: NbToastrService,
    private _layerEventsService: LayerEventsService,
    private _layersService: LayersService,
    private _viewerLayerService: ViewerLayerService,
    private _thicknessFormService: ThicknessFormService,
  ) {}

  recalculateCanceled() {
    this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    this._layersService.setRecalculatedLayers([]);
  }

  isThicknessFormValid(layer: LayerUI) {
    return this._thicknessFormService.forms[layer.id].valid;
  }

  hasThickness(layer: LayerUI) {
    return this._viewerLayerService.hasThickness(layer.id);
  }

  showNoEditionToast() {
    this._toastrService.show(
      'To edit a layer, you need to turn on edit mode first.',
      'Edit mode is not activated',
      { status: 'warning', position: 'bottom-right' as NbGlobalPosition },
    );
  }

  assignFormPropsToLayer(layer: LayerUI, name: string | null, description: string | null) {
    layer.name = name || '';
    layer.description = description || '';
  }

  isSketchPlane(layer: LayerUI) {
    return layer.type === LAYER_TYPES.SKETCH_PLANES;
  }
}
