import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@App/app/auth/auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModelsProcessingService } from '../../services/models-processing-service/models-processing.service';

@Injectable({
  providedIn: 'root',
})
export class ModelsProcessingGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private modelsProcessingService: ModelsProcessingService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const companyId = this.authService.getCurrentEndpoint()?.id;
    if (companyId) {
      if (route.routeConfig?.path?.includes('processing') && route.params.id) {
        return this.modelsProcessingService
          .isBuildProcessAccessible(parseInt(route.params.id, 10))
          .pipe(
            tap((value) => {
              if (!value) {
                this.router.navigate([`${companyId}/processing`]);
              }
            }),
          );
      }
      return true;
    } else {
      this.router.navigate([`${companyId}/models`]);
    }
    return false;
  }
}
