import { LAYER_PROP_SUCCESS, SUCCESS } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { LayerUtilsService } from '@App/app/engine/services/layer-services/layer-utils-service/layer-utils.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { httpLayer } from '@App/app/entities/layer/layer.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { LayerHttpService } from '../../../../engine/services/layer-services/layer-http-service/layer-http.service';
import { LayersService } from '../../services/layers/layers.service';
import { changeLayerProp, changeLayerPropSuccess, updateLayer } from '../actions/layers.actions';
import { selectAllLayers } from '../selectors/layers.selectors';

@Injectable()
export class ChangeLayerPropEffects {
  constructor(
    private actions: Actions,
    private layerHttpService: LayerHttpService,
    private layerUtilsService: LayerUtilsService,
    private toastrService: NbToastrService,
    private layersService: LayersService,
    private viewerLayerService: ViewerLayerService,
    private store: Store,
  ) {}

  changeLayerProp = createEffect(
    () => {
      return this.actions.pipe(
        ofType(changeLayerProp),
        withLatestFrom(this.store.select(selectAllLayers)),
        mergeMap(([{ id, prop }, layers]) => {
          const { key, value } = prop;
          const layerToUpdate = layers.find((layer) => layer.id === id);
          if (!layerToUpdate) {
            return EMPTY;
          }
          const body = this.layerUtilsService.createRequestBody(layerToUpdate);
          body.data = layerToUpdate.data;
          (body[key as keyof httpLayer] as typeof value) = value;
          return this.layerHttpService.putBatchLayers([body]).pipe(
            map(([layer]) => changeLayerPropSuccess({ layer, key })),
            catchError(() => EMPTY),
          );
        }),
      );
    },
    { dispatch: true },
  );

  changeLayerPropSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(changeLayerPropSuccess),
      withLatestFrom(this.layersService.loadingLayers$),
      map(([{ layer, key }, loadingLayers]) => {
        this.layersService.setLoadingLayers(loadingLayers.filter((item) => item.id !== layer.id));
        this.toastrService.show(LAYER_PROP_SUCCESS, SUCCESS, {
          ...SUCCESS_TOASTR_CONFIG,
        });
        if (key === 'name' && layer.type !== LAYER_TYPES.FOLDERS) {
          this.viewerLayerService.changeLayerViewerName(layer.id, layer.name);
        }
        return updateLayer({
          update: {
            id: layer.id,
            changes: { data: layer.data, [key]: layer[key] },
          },
        });
      }),
    );
  });
}
