import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FileMetadataInfo } from '../../../models/uploaded-image-metadata.model';
import { State, filesAdapter } from '../reducers/files.reducer';

export const selectFilesState = createFeatureSelector<State>('files');

const { selectAll } = filesAdapter.getSelectors(selectFilesState);

export const selectFiles = selectAll;

export const selectXlifData = createSelector(selectFilesState, ({ xlifData }) => xlifData);

export const selectCsvFiles = createSelector(selectFilesState, ({ csvFiles }) => csvFiles);

export const selectFlatFileName = createSelector(
  selectFilesState,
  ({ flatFileName }) => flatFileName,
);

export const selectGCPFileName = createSelector(
  selectFilesState,
  ({ controlPointsFileName }) => controlPointsFileName,
);

export const selectIsGCPAdded = createSelector(
  selectFilesState,
  ({ controlPointsFileName }) => !!controlPointsFileName,
);

export const selectGCPFile = createSelector(
  selectFilesState,
  ({ controlPointsFileName, csvFiles }) =>
    csvFiles.find((file) => file.name === controlPointsFileName),
);

export const selectFlatFile = createSelector(selectFilesState, ({ flatFileName, csvFiles }) =>
  csvFiles.find((file) => file.name === flatFileName),
);

export const selectAllFiles = createSelector(
  selectFilesState,
  selectFiles,
  ({ flatFileName, controlPointsFileName, csvFiles }, files) => {
    if (flatFileName) {
      const flatFile = csvFiles.find((f) => f.name === flatFileName) as File;
      const file = new File([flatFile], flatFileName);
      files = [file, ...files];
    }
    if (controlPointsFileName) {
      const controlPointsFile = csvFiles.find((f) => f.name === controlPointsFileName) as File;
      const file = new File([controlPointsFile], controlPointsFileName);
      files = [file, ...files];
    }
    return files;
  },
);

export const selectFilteredFiles = (keywords: string) =>
  createSelector(selectFiles, (files) => {
    return files.filter((file) => file.name.toLowerCase().includes(keywords.toLowerCase()));
  });

export const selectFileIndexByName = (name: string) =>
  createSelector(selectFiles, (files) => {
    return files.findIndex((data) => data.name === name);
  });

export const selectFileByName = (name: string) =>
  createSelector(selectAllFiles, (files) => {
    return files.find((data) => data.name === name);
  });

export const selectValidXlifData = (noCoordsFileNames: string[]) =>
  createSelector(
    selectFilesState,
    selectFiles,
    ({ xlifData }, files) =>
      files
        .map((file) => xlifData.find((i) => i.name === file.name))
        .filter((i) => i && !noCoordsFileNames.includes(i.name)) as FileMetadataInfo[],
  );
