import { Model } from '@App/app/entities/models/model.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/models.actions';

export const modelsAdapter = createEntityAdapter<Model>();

export interface State extends EntityState<Model> {
  totalCount: number;
  currentModel: Model | null;
}

const defaultModels: State = {
  ids: [],
  entities: {},
  totalCount: 0,
  currentModel: null,
};

export const initialState: State = modelsAdapter.getInitialState(defaultModels);

export const modelsReducer = createReducer(
  initialState,
  on(actions.loadAllModelsSuccess, (state, { models, totalCount }) => {
    return modelsAdapter.setAll(models, { ...state, totalCount });
  }),
  on(actions.removeModelLocal, (state, { id }) => {
    return modelsAdapter.removeOne(id, state);
  }),
  on(actions.removeAllModelsLocal, (state) => {
    return modelsAdapter.removeAll(state);
  }),
  on(actions.deleteModelsSuccess, (state, { ids }) => {
    return modelsAdapter.removeMany(ids, state);
  }),
  on(actions.deleteModelsFailed, (state, { deletedModels }) => {
    const ids = deletedModels.map((deletedModel) => deletedModel.id);
    return modelsAdapter.removeMany(ids, state);
  }),
  on(
    actions.loadCurrentModelSuccess,
    (state, { model }): State => {
      return { ...state, currentModel: model };
    },
  ),
  on(
    actions.removeCurrentModelLocal,
    (state): State => {
      return { ...state, currentModel: null };
    },
  ),
);

export const getTotalCount = (state: State) => state.totalCount;

export const getCurrentModel = (state: State) => state.currentModel;

export const getCurrentModelLocation = (state: State) => state.currentModel?.location || null;

export const getCurrentModelLocationIsSpecified = (state: State) =>
  !!(
    state.currentModel?.location &&
    state.currentModel?.location.latitude &&
    state.currentModel?.location.longitude
  );
