import { createGui } from '@App/app/entities/layer/create-gui.model';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import {
  Measurement,
  ViewerMeasurementLayer,
} from '@App/app/entities/layer/measurements/measurement.model';
import { NameTag } from '@App/app/entities/layer/name-tag.model';
import { Injectable } from '@angular/core';
import { AbstractMesh, Mesh, Vector3 } from 'babylonjs';
import {
  MEASUREMENT,
  MEASUREMENT_NAME,
  MEASUREMENT_SPHERES_NAMES,
} from 'src/app/configs/babylon.config';
import { UnitsService } from 'src/app/pages/viewer/services/utils/units.service';
import { toImperialLength } from 'src/app/shared/utils/units-converter';
import { initLODSpheres } from '../../../helpers/layers-helpers';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { GenerateGUIService } from '../../gui-services/generate-gui-service/generate-gui.service';
import { MaterialService } from '../../material-service/material.service';
import { SceneService } from '../../scene-service/scene.service';
import { MeasurementsGizmosService } from './measurements-gizmos.service';

@Injectable({
  providedIn: 'root',
})
export class MeasurementUtilsService implements createGui {
  constructor(
    public generateGUIService: GenerateGUIService,
    public unitsService: UnitsService,
    private materialService: MaterialService,
    private babylonNodesService: BabylonNodesService,
    private measurementGizmosService: MeasurementsGizmosService,
    private sceneService: SceneService,
  ) {}

  showMeasurement(measurement: Measurement): ViewerMeasurementLayer {
    const newMeasurement = measurement as ViewerMeasurementLayer;
    const root = new AbstractMesh(`${MEASUREMENT_NAME}${measurement.id}`, this.sceneService.scene);
    root.id = `${measurement.id}`;

    newMeasurement.meshes = measurement.data.meshesPoints?.map((meshPoint, index) => {
      const mesh = this.createSphere(meshPoint, index === 2);
      initLODSpheres(
        mesh,
        this.sceneService.scene,
        this.materialService.electricBlueMaterial,
        MEASUREMENT_SPHERES_NAMES.LOD_SPHERES_NAME,
        MEASUREMENT_SPHERES_NAMES.LOD_SPHERE_NAME,
      );
      mesh.id =
        index === 2
          ? MEASUREMENT_SPHERES_NAMES.MIDDLE_SPHERE_NAME
          : MEASUREMENT_SPHERES_NAMES.PICKER_SPHERE_NAME;
      mesh.parent = root;
      return mesh;
    });

    const { meshesPoints } = measurement.data;
    if (meshesPoints?.length && meshesPoints.length >= 2) {
      const [startPointMeasurement, endPointMeasurement] = meshesPoints;
      const path = [startPointMeasurement as Vector3, endPointMeasurement as Vector3];
      newMeasurement.linesMesh = this.createLineBetweenPoints(path, root);
    }

    if (newMeasurement.meshes?.length) {
      newMeasurement.gizmoManager = this.measurementGizmosService.initGizmoManager(
        newMeasurement.id,
        newMeasurement.meshes[0],
      );
    }

    newMeasurement.gui = this.createGui(newMeasurement, measurement.data.length);
    this.toggleImperialGui(
      measurement,
      newMeasurement.gui,
      this.unitsService.getImperialUnitsActive(),
    );

    return newMeasurement;
  }

  createGui(measurement: ViewerMeasurementLayer, length?: number) {
    if (!length) {
      length = measurement.data.length;
    }
    const mesh = measurement.meshes?.at(2) as Mesh;
    return this.generateGUIService.createNameTag(`${length}m`, mesh, true, measurement.id);
  }

  createSphere(position: Coordinates3, middle: boolean) {
    const positionTest = new Vector3(position.x, position.y, position.z);

    const sphere = this.babylonNodesService.createSphere(
      positionTest,
      'picker_sphere',
      this.materialService.electricBlueMaterial,
      MEASUREMENT.spheresOptions,
    );

    sphere.renderingGroupId = MEASUREMENT.sphereRenderGroupId;
    if (middle) {
      sphere.scaling = new Vector3(0, 0, 0);
    }

    return sphere;
  }

  createLineBetweenPoints(path: Vector3[], parent: AbstractMesh) {
    const linesMesh = this.babylonNodesService.createLine(
      [path[0], path[1]],
      MEASUREMENT_SPHERES_NAMES.LINE_NAME,
      MEASUREMENT.lineColor,
      { updatable: true },
    );

    linesMesh.parent = parent;
    linesMesh.renderingGroupId = MEASUREMENT.lineRenderGroupId;
    return linesMesh;
  }

  toggleImperialGui(measurement: Measurement, gui: NameTag, isImperial: boolean) {
    if (gui.text && measurement.data.length) {
      gui.text.text = isImperial
        ? `${toImperialLength(measurement.data.length)}`
        : `${measurement.data.length}m`;
    }
  }
}
