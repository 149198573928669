import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { Photo } from '@App/app/entities/files/files.model';
import { TiePointMeasurement } from '@App/app/entities/files/tie-point-model';
import { selectAllPhotos } from '@App/app/shared/store/photos/selectors/photos.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ArcRotateCamera, Vector3 } from 'babylonjs';
import { BehaviorSubject } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { TiePointsService } from 'src/app/pages/viewer/services/tie-points/tie-points.service';
import { CameraTargetService } from '../../../../engine/services/camera-services/camera-target-service/camera-target.service';
import { ViewerCameraPointsService } from '../../../../engine/services/viewer-camera-point-service/viewer-camera-point.service';
import { ViewerPhotoService } from '../../../../engine/services/viewer-photo-service/viewer-photo.service';
import { PhotosUtilsService } from './../photos-utils/photos-utils.service';

@Injectable({
  providedIn: 'root',
})
export class PhotosService {
  allPhotos: Photo[] = [];
  private photoActiveMode = false;

  private _photos$ = new BehaviorSubject<Photo[]>([]);
  photos$ = this._photos$.asObservable();

  private _activePhotoId$ = new BehaviorSubject<number | null>(null);
  activePhotoId$ = this._activePhotoId$.asObservable();

  private _activePhotoIndex$ = new BehaviorSubject<number>(0);
  activePhotoIndex$ = this._activePhotoIndex$.asObservable();

  constructor(
    private cameraTargetService: CameraTargetService,
    private photosUtilsService: PhotosUtilsService,
    private sceneService: SceneService,
    private store: Store,
    private tiePointsService: TiePointsService,
    private viewerCameraPointsService: ViewerCameraPointsService,
    private viewerPhotoService: ViewerPhotoService,
  ) {
    this.store.select(selectAllPhotos).subscribe((photos) => {
      this.allPhotos = photos;
      this._photos$.next(photos);
    });

    this.tiePointsService.tiePointsModeActive$.subscribe((active) => {
      this.clearAll();
      this._photos$.next(active ? [] : this.allPhotos);
      this.showPhotosPoints();
    });

    this.tiePointsService.tiePointPhotos$.subscribe(
      (tiePointMeasurements: TiePointMeasurement[]) => {
        this._photos$.next(
          this.allPhotos.filter((photo) => {
            return tiePointMeasurements.find((tiePoint) => tiePoint.photoId === +photo.id);
          }),
        );
        this.clearAll();
        this.showPhotosPoints();
      },
    );

    // eslint-disable-next-line complexity
    this.activePhotoId$.pipe(pairwise()).subscribe(([previousPhotoId, currentPhotoId]) => {
      const prevPhoto = this._photos$.value.find((item) => +item.id === previousPhotoId);
      if (!currentPhotoId && previousPhotoId && prevPhoto) {
        const nearDepth = prevPhoto.data.nearDepth;
        if (this.sceneService.scene) {
          this.cameraTargetService.moveCameraTargetToTheFront(nearDepth * 1.5 || 0);
        }
      }
    });
  }

  setPhotoActiveMode(active: boolean) {
    this.photoActiveMode = active;
  }

  setActivePhoto(id: number, center?: boolean) {
    this.showPhotoOnModel(id, center);
  }

  closeActivePhoto(id: number) {
    if (this._activePhotoId$.value === id) {
      this._activePhotoId$.next(null);
      this.disposeActivePhoto();
    }
  }

  toggleCameraPoints(visible: boolean) {
    this.clearAll();
    if (visible) {
      this.showPhotosPoints();
    }
  }

  setActivePhotoIndex(index: number) {
    this._activePhotoIndex$.next(index);
  }

  private showPhotoOnModel(photoId: number, center?: boolean) {
    const photo = this._photos$.value.find((item) => +item.id === photoId);
    if (photo?.validate() && this._activePhotoId$.value !== photoId) {
      const photoCameraPosition = this.photosUtilsService.position(photo.data.position);
      const photoCameraQuaternion = this.photosUtilsService.quaternion(photo.data.rotation);
      const photoCameraNormal = this.photosUtilsService.normal(photoCameraQuaternion);
      this.disposeActivePhoto();
      this.viewerPhotoService.createPhoto(photo);
      this.viewerCameraPointsService.disposeOne(Number(photo.id));
      this._activePhotoId$.next(+photo.id);
      const newActiveIndex = this._photos$.value.findIndex((item) => +item.id === photoId);
      this._activePhotoIndex$.next(newActiveIndex);
      this.setCameraToCameraPosition(photoCameraPosition, photoCameraNormal);
      if (!center) {
        document.getElementById(photoId.toString())?.scrollIntoView({ inline: 'center' });
      }
    }
  }

  private clearAll() {
    this.viewerCameraPointsService.disposeAll();
    this.viewerPhotoService.disposePhoto();
    this._activePhotoId$.next(null);
  }

  private showPhotosPoints() {
    if (this.photoActiveMode) {
      this.viewerCameraPointsService.createCameraPoints(
        this._photos$.value,
        this.showPhotoOnModel.bind(this),
      );
    }
  }

  private disposeActivePhoto() {
    const photo = this._photos$.value.find(
      (item) => item.name === this.viewerPhotoService.photo?.name,
    );
    if (photo) {
      if (this.sceneService.scene) {
        this.viewerCameraPointsService.createCameraPoint(photo, this.showPhotoOnModel.bind(this));
      }
      this.viewerPhotoService.disposePhoto();
    }
  }

  private setCameraToCameraPosition(position: Vector3, normal: Vector3) {
    const cam = this.sceneService.scene.activeCamera as ArcRotateCamera;
    cam.position = position.clone().add(normal.scale(10));
    cam.target = position;
  }
}
