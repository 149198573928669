import { Model } from '@App/app/entities/models/model.model';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Component, Input, OnInit } from '@angular/core';
import { toImperialLength } from 'src/app/shared/utils/units-converter';
@Component({
  selector: 'app-model-section',
  templateUrl: './model-section.component.html',
  styleUrls: ['../model-overview-details.component.scss'],
})
export class ModelSectionComponent implements OnInit {
  @Input() model: Model;
  @Input() dataTypeParams: any;
  siteDataUnitsSystem = UnitsSystem.Imperial;
  structureDataUnitsSystem = UnitsSystem.Imperial;
  unitsSystem = UnitsSystem;
  constructor() {
    this.toggleStructureDataFormat = this.toggleStructureDataFormat.bind(this);
  }

  ngOnInit() {}

  toggleStructureDataFormat() {
    this.structureDataUnitsSystem =
      this.structureDataUnitsSystem === UnitsSystem.Imperial
        ? UnitsSystem.Metric
        : UnitsSystem.Imperial;
  }

  formatRadCenters(radCenters: number[], unitsSystem: UnitsSystem) {
    const isImperial = unitsSystem === UnitsSystem.Imperial;
    const sorted = [...radCenters].sort((a, b) => a - b);
    return sorted.reduce((formatted, radCenter, index, array) => {
      const rad = isImperial ? `${toImperialLength(radCenter)}` : `${radCenter}m`;
      return index < array.length - 1 ? `${formatted} ${rad} /` : `${formatted} ${rad}`;
    }, '');
  }
}
