/* eslint-disable ngrx/no-dispatch-in-effects */
import { FAILED, SCRIPT_LOG_NOT_AVAILABLE } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { LogFileTypes } from '@App/app/entities/files/files-data.model';
import { ATLog, BaseFile, ProdLog } from '@App/app/entities/files/files.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ModelsProcessingHttpService } from '../../../services/models-processing-http-service/models-processing-http.service';
import { ModelsProcessingService } from '../../../services/models-processing-service/models-processing.service';
import {
  downloadProcessLogs,
  downloadProcessLogsSuccess,
  loadCurrentProcessFiles,
  loadCurrentProcessFilesSuccess,
} from '../actions/processes.actions';

@Injectable()
export class LoadCurrentProcessFilesEffects {
  constructor(
    private actions: Actions,
    private modelsProcessingHttpService: ModelsProcessingHttpService,
    private modelsProcessingService: ModelsProcessingService,
    private toastrService: NbToastrService,
    private store: Store,
  ) {}

  loadCurrentProcessFiles = createEffect(() => {
    return this.actions.pipe(
      ofType(loadCurrentProcessFiles),
      mergeMap(({ processId }) => {
        return this.modelsProcessingHttpService.getBuildProcessFiles(processId).pipe(
          map(
            (files: BaseFile[]) => loadCurrentProcessFilesSuccess({ files }),
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  loadCurrentProcessFilesSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(loadCurrentProcessFilesSuccess),
        tap(({ files }) => {
          this.modelsProcessingService.setCurrentBuildProcessFiles(files);
        }),
        map(() => this.modelsProcessingService.getCurrentBuildProcess()),
        filter((process) => !!process),
        map((process: BuildProcess) => {
          if (process.atLog) {
            this.store.dispatch(downloadProcessLogs({ logType: LogFileTypes.AT_LOG }));
          }
          if (process.prodLog) {
            this.store.dispatch(downloadProcessLogs({ logType: LogFileTypes.PROD_LOG }));
          }
        }),
      );
    },
    { dispatch: false },
  );

  downloadProcessLogs = createEffect(() => {
    return this.actions.pipe(
      ofType(downloadProcessLogs),
      map(({ logType }) => {
        const process = this.modelsProcessingService.getCurrentBuildProcess();
        if (logType === LogFileTypes.AT_LOG) {
          return process?.atLog;
        } else {
          return process?.prodLog;
        }
      }),
      filter((log) => !!log),
      mergeMap((log: ATLog | ProdLog) => {
        return this.modelsProcessingHttpService.getBuildProcessLogs(log.downloadURL).pipe(
          map(
            (value: string) => {
              const logType = (log.type as unknown) as LogFileTypes;
              return downloadProcessLogsSuccess({ logType, value });
            },
            () => {
              this.toastrService.show(SCRIPT_LOG_NOT_AVAILABLE, FAILED, {
                ...WARNING_TOASTR_CONFIG,
              });
            },
          ),
        );
      }),
    );
  });

  downloadProcessLogsSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(downloadProcessLogsSuccess),
        tap(({ logType, value }) => {
          this.modelsProcessingService.setCurrentBuildProcessLog(logType, value);
        }),
      );
    },
    { dispatch: false },
  );
}
