import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { UtilsService } from '@App/app/engine/services/utils-service/utils.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { LayerDetailsEditForm } from '@App/app/entities/forms/layer-details-edit-form.model';
import { LAYER_EVENTS, LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { SAVE_SKETCH_TYPE } from '@App/app/entities/layer/sketch-tools/save-sketch-types.enum';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { debounceTime, filter } from 'rxjs/operators';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { CreateLayerBarService } from '../create-layers-bar/create-layer-bar.service';
import { ActiveLayerToolService } from '../services/active-layer-tool/active-layer-tool.service';
import { LayersService } from '../services/layers/layers.service';
import { selectChildLayers } from '../store/selectors/layers.selectors';
import { LayerDetailsService } from './services/layer-details-service/layer-details.service';
import { LayerDetailsUtilsService } from './services/layer-details-utils-service/layer-details-utils.service';

@UntilDestroy()
@Component({
  selector: 'app-layer-details',
  templateUrl: './layer-details.component.html',
  styleUrls: ['./layer-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayerDetailsComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() layer: LayerUI;
  @Input() unitsSystem: UnitsSystem;
  isDeleteActive$ = this._layerDetailsService.isDeleteActive$;
  LAYER_TYPES = { ...LAYER_TYPES };
  loading = false;
  permissions = UserPermissions;
  editForm: LayerDetailsEditForm;
  private _recalculatedLayers: LayerUI[] = [];

  constructor(
    private _layerEventsService: LayerEventsService,
    private _layersService: LayersService,
    private _broadcastService: BroadcastService,
    private _viewerLayerService: ViewerLayerService,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _layerDetailsService: LayerDetailsService,
    private _layerDetailsUtilsService: LayerDetailsUtilsService,
    private _activeLayerToolService: ActiveLayerToolService,
    private _createLayerBarService: CreateLayerBarService,
    private store: Store,
  ) {
    this._layersService.recalculatedLayers$.pipe(untilDestroyed(this)).subscribe((layers) => {
      this._recalculatedLayers = layers;
    });
  }

  ngAfterContentChecked() {
    this._cdr.detectChanges();
  }

  ngOnInit(): void {
    this._broadcastService
      .on(SAVE_SKETCH_TYPE.ALL)
      .pipe(untilDestroyed(this))
      .subscribe(() => this._layerDetailsService.setIsAddPlaneActive(false));

    this._utilsService.updateActiveLayerDetails$
      .pipe(
        untilDestroyed(this),
        debounceTime(10),
        filter(() => this._viewerLayerService.checkViewerLayerExists(this.layer.id)),
      )
      .subscribe(() => (this.layer.data = this._viewerLayerService.getData(this.layer.id)));

    this.editForm = this._fb.group({
      name: this._fb.control(this.layer.name, [Validators.required, Validators.minLength(1)]),
      description: this._fb.control(this.layer.description || '', { nonNullable: true }),
    });

    if (this.layer) {
      if (this._layersService.editedLayers.includes(this.layer)) {
        if (this.layer.type === LAYER_TYPES.SKETCH_PLANES) {
          this._layerDetailsService.setIsEditChildrenActive(true);
        } else {
          this._activeLayerToolService.activateEditMode(true);
        }
      }
    }

    this.store
      .select(selectChildLayers(this.layer.id))
      .pipe(untilDestroyed(this))
      .subscribe((layers) => this._layerDetailsService.setChildLayers(layers));
  }

  ngOnDestroy(): void {
    if (this._isRecalculateActive()) {
      this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    }
  }

  onDeleteClick(layer?: LayerUI): void {
    this._layerDetailsService.deleteClicked(layer);
  }

  onDeleteCancel(layer?: LayerUI): void {
    this._layerDetailsService.deleteCanceled(layer);
  }

  onDeleteConfirm(): void {
    this._layersService.deleteLayer(this.layer);
    this._layerDetailsService.deleteCanceled();
    this._activeLayerToolService.setActiveTool();
    this._createLayerBarService.setEditModeValue(false);
  }

  isThicknessFormValid() {
    return this._layerDetailsUtilsService.isThicknessFormValid(this.layer);
  }

  hasThickness() {
    return this._layerDetailsUtilsService.hasThickness(this.layer);
  }

  isEditModeOnInitMappingTool() {
    return this._layersService.isEditModeOnInitMappingTool;
  }

  onChangeName(name: string) {
    this._layersService.changeLayerName(name, this.layer);
  }

  onEditClick(): void {
    this._layerDetailsService.editClicked(this.layer);
  }

  onEditCancel(): void {
    this._layerDetailsService.editCanceled(this.layer);
    this.resetEditForm();
  }

  onEditConfirm(): void {
    const { name, description } = this.editForm.controls;
    if (name.valid) {
      this._layerDetailsService.editConfirmed(this.layer, name.value, description.value);
      this.resetEditForm();
      this._activeLayerToolService.setActiveTool();
      this._createLayerBarService.setEditModeValue(false);
    }
  }

  onRecalculateClick(): void {
    const eventsByType = {
      [LAYER_TYPES.CUBOID]: LAYER_EVENTS.FIND_CUBOID_NORMAL,
      [LAYER_TYPES.RECT_TUBE]: LAYER_EVENTS.FIND_RECT_TUBE_NORMAL,
    };

    this._layerEventsService.activeLayerEvent.next(
      eventsByType[this.layer.type as keyof typeof eventsByType],
    );
    this._layersService.setRecalculatedLayers([this.layer]);
  }

  onRecalculateCancel(): void {
    this._layerDetailsUtilsService.recalculateCanceled();
  }

  private resetEditForm() {
    this.editForm.reset({
      name: this.layer.name,
      description: this.layer.description,
    });
  }

  private _isRecalculateActive() {
    return this._recalculatedLayers.includes(this.layer);
  }
}
