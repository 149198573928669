import { UtilsService } from '@App/app/engine/services/utils-service/utils.service';
import { GridPageInfo } from '@App/app/entities/shared/grid-page-info.model';
import { VirtualScroll } from '@App/app/entities/shared/virtual-scroll.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent<T extends { id: number | string }> implements OnChanges {
  @Input() entities: T[];
  @Input() loading: boolean;
  @Input() noEntitiesPlaceholder: string;
  @Input() headerContentTpl: TemplateRef<HTMLElement>;
  @Input() cellContentTpl: TemplateRef<HTMLElement>;
  @Input() footerContentTpl: TemplateRef<HTMLElement>;
  @Input() threshold = 35;
  @Output() loadNext = new EventEmitter<GridPageInfo>();
  private _groupedEntities: T[][];
  virtualScrollData: VirtualScroll<T> = { data: [], offset: 1 };

  constructor(private _utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entities) {
      this._initVirtualScroll(changes.entities.currentValue);
    }
  }

  trackById(_: number, entity: T) {
    return entity.id;
  }

  onLoadNext() {
    if (this._groupedEntities && this.virtualScrollData.offset < this._groupedEntities?.length) {
      const nextEntitiesSet = this._groupedEntities[this.virtualScrollData.offset];
      this.virtualScrollData.data.push(...nextEntitiesSet);
      this.virtualScrollData.offset++;
      this._emitLoadNextEvent();
    }
  }

  private _initVirtualScroll(entities: T[]) {
    this._groupedEntities = this._utilsService.split<T>(entities, this.threshold);
    this.virtualScrollData.data = [...(this._groupedEntities[0] || [])];
    this.virtualScrollData.offset = 1;
    this._emitLoadNextEvent();
  }

  private _emitLoadNextEvent() {
    this.loadNext.emit({
      offset: this.virtualScrollData.offset,
      threshold: this.threshold,
    });
  }
}
