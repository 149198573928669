import { Annotation } from '@App/app/entities/layer/annotation.model';
import { CalculatedData } from '@App/app/entities/layer/calculated-data';
import { Cuboid } from '@App/app/entities/layer/cuboid.model';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { ModelsService } from '@App/app/pages/models/services/models-service/models.service';
import { Injectable } from '@angular/core';
import { Angle, BoundingBox, Color4, Mesh, Quaternion, Tools, Vector3 } from 'babylonjs';
import * as geomag from 'geomag';
import {
  ANNOTATION_NAME,
  CUBOID_OBJECT_NAME,
  ELECTRIC_BLUE_COLOR,
  NORTH_VECTOR,
} from 'src/app/configs/babylon.config';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../../material-service/material.service';
import { BasePlaneService } from '../../base-plane-services/base-plane.service';

@Injectable({
  providedIn: 'root',
})
export class VolumeUtilsService {
  constructor(
    private basePlaneService: BasePlaneService,
    private babylonNodesService: BabylonNodesService,
    private materialService: MaterialService,
    private modelsService: ModelsService,
  ) {}

  initBoundingBox(min: Vector3, max: Vector3, layer?: Cuboid | Annotation) {
    const name = layer?.type === LAYER_TYPES.CUBOID ? CUBOID_OBJECT_NAME : ANNOTATION_NAME;
    const boundingBox = new BoundingBox(min, max);
    const mesh = this.babylonNodesService.createBox(
      boundingBox.centerWorld.clone(),
      {
        width: boundingBox.extendSize.x * 2,
        height: boundingBox.extendSize.y * 2,
        depth: boundingBox.extendSize.z * 2,
      },
      null,
      `${name}${layer?.id}`,
      this.materialService.electricBlueMaterial,
    );

    mesh.renderingGroupId = 0;
    mesh.visibility = 0.6;
    mesh.enableEdgesRendering();
    mesh.edgesWidth = 0.8;
    mesh.edgesColor = Color4.FromHexString(`${ELECTRIC_BLUE_COLOR}FF`);
    return mesh;
  }

  /**
   * @param vec1 - center(base plane)
   * @param vec2 - object
   */
  private calculateVector(vec1: Vector3, vec2: Vector3) {
    return new Vector3(vec2.x - vec1.x, vec2.y - vec1.y, vec2.z - vec1.z);
  }

  getMeshVolume(mesh: any) {
    const x =
      mesh.getBoundingInfo().boundingBox.maximum.x - mesh.getBoundingInfo().boundingBox.minimum.x;
    const y =
      mesh.getBoundingInfo().boundingBox.maximum.y - mesh.getBoundingInfo().boundingBox.minimum.y;
    const z =
      mesh.getBoundingInfo().boundingBox.maximum.z - mesh.getBoundingInfo().boundingBox.minimum.z;

    const meshVolume = (x * y * z * mesh.scaling.x * mesh.scaling.y * mesh.scaling.z).toFixed(5);

    return +meshVolume;
  }

  calculateDistanceToModelCenter(position1: Vector3, position2: Vector3) {
    const { x: x1, z: z1 } = position1;
    const { x: x2, z: z2 } = position2;
    const lengthXZ = Math.sqrt(Math.pow(z2 - z1, 2) + Math.pow(x2 - x1, 2)).toFixed(5);

    return +lengthXZ;
  }

  calculateHeightBetweenMeshes(position1: Vector3, position2: Vector3) {
    return +Math.abs(position1.y - position2.y).toFixed(5);
  }

  calculateWorldDirectionAngle(position1: Vector3, position2: Vector3): number {
    //STEP 1 calculate vector from center to red sphere
    const vec = this.calculateVector(position1, position2);

    //STEP 2 normalize this vector
    // so the `y` doesn't take part in the azimuth calculations. `y` should be always 0.
    vec.y = 0;
    const normalizedVec = Vector3.Normalize(vec);

    //STEP 3 dot vector of north and normalizedVec
    const dotVec = Vector3.Dot(new Vector3(0, 0, 1), normalizedVec);

    //STEP 4 arcus cosines of dot vector
    const arcCosDotVec = Math.acos(dotVec);

    //STEP 5 convert radians to degrees
    let angle = Angle.FromRadians(arcCosDotVec).degrees();

    //STEP 6 handle 2nd and 4rd quadrant of coordinate system
    if (normalizedVec.x < position1.x) {
      angle = 360 - angle;
    }

    return +angle.toFixed(2);
  }

  calculateAzimuth(normal: Vector3) {
    const vec = normal.clone();
    vec.y = 0;

    const normalizedVec = Vector3.Normalize(vec);
    const dotVec = Vector3.Dot(this.magneticNorth() || NORTH_VECTOR, normalizedVec);
    const angleRadians = Math.acos(dotVec);
    let angleDegrees = Angle.FromRadians(angleRadians).degrees();

    // To make 0-360 scale, not only 0-180
    if (normalizedVec.x > 0) {
      angleDegrees = 360 - angleDegrees;
    }
    return +angleDegrees.toFixed(2);
  }

  calculateVerticalTilt(normal: Vector3) {
    const direction = new Vector3(normal.x, 0, normal.z);
    const dotVec = Vector3.Dot(direction, normal);
    const angleRadians = Math.acos(dotVec);
    const angleDegrees = Angle.FromRadians(angleRadians).degrees();

    return normal.y > 0 ? -angleDegrees.toFixed(2) : +angleDegrees.toFixed(2);
  }

  verticalTiltByMesh(box: Mesh) {
    const test = box.absoluteRotationQuaternion.toEulerAngles();
    const testDegrees = Tools.ToDegrees(test.x);
    return -testDegrees.toFixed(2);
  }

  getCalculatedData(mesh: Mesh): CalculatedData {
    const onHeightDistance = this.calculateHeightBetweenMeshes(
      mesh.position,
      this.basePlaneService.getBasePlanesPosition(),
    );
    const modelCenterDistance = this.calculateDistanceToModelCenter(
      mesh.position,
      this.basePlaneService.getBasePlanesPosition(),
    );
    const volume = this.getMeshVolume(mesh);
    const worldDirection = this.calculateWorldDirectionAngle(
      mesh.position,
      this.basePlaneService.getBasePlanesPosition(),
    );
    const color = '#fff';
    const absoluteRotationQuaternion = {
      x: mesh.absoluteRotationQuaternion.x,
      y: mesh.absoluteRotationQuaternion.y,
      z: mesh.absoluteRotationQuaternion.z,
      w: mesh.absoluteRotationQuaternion.w,
    };
    const clonedMesh = mesh.clone();
    clonedMesh.visibility = 0;
    clonedMesh.rotationQuaternion = new Quaternion(0, 0, 0, 0);
    const minimum = clonedMesh.getHierarchyBoundingVectors().min;
    const maximum = clonedMesh.getHierarchyBoundingVectors().max;
    const min = { x: minimum.x, y: minimum.y, z: minimum.z };
    const max = { x: maximum.x, y: maximum.y, z: maximum.z };
    clonedMesh.dispose();
    return {
      volume,
      absoluteRotationQuaternion,
      min,
      max,
      onHeightDistance,
      towerCenterDistance: modelCenterDistance,
      worldDirection,
      color,
    };
  }

  private magneticNorth() {
    let result: Vector3 | undefined;
    const { model } = this.modelsService;
    if (model?.location?.latitude && model?.location.longitude) {
      result = Vector3.Zero();
      const magneticDeclination = geomag.field(model.location.latitude, model.location.longitude)
        .declination;
      const declinationInDegrees = Tools.ToRadians(magneticDeclination);
      const q = Quaternion.FromEulerAngles(0, declinationInDegrees, 0);
      NORTH_VECTOR.rotateByQuaternionToRef(q, result);
    }
    return result;
  }
}
