import { THUMBNAIL_PLACEHOLDER } from '@App/app/configs/app.config';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Model } from '@App/app/entities/models/model.model';
import { GridPageInfo } from '@App/app/entities/shared/grid-page-info.model';
import { PageRangeInfo } from '@App/app/entities/shared/page-info.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModelsView } from '../models/models-view.model';
import { ModelsContentService } from '../services/models-content-service/models-content.service';

@UntilDestroy()
@Component({
  selector: 'app-models-grid',
  templateUrl: './models-grid.component.html',
  styleUrls: ['./models-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelsGridComponent implements ModelsView {
  private _companyId: number;
  private _gridPageInfo$ = new BehaviorSubject<GridPageInfo>({
    threshold: 0,
    offset: 0,
  });
  gridPageInfo$ = this._gridPageInfo$.asObservable();
  loading$: Observable<boolean>;
  showStatisticsButton$: Observable<boolean>;
  models: Model[] = [];
  THUMBNAIL_PLACEHOLDER = THUMBNAIL_PLACEHOLDER;
  permissions = UserPermissions;

  constructor(
    private _modelsContentService: ModelsContentService,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
  ) {
    this.loading$ = this._modelsContentService.loading$;
    this.showStatisticsButton$ = this._modelsContentService.showStatisticsButton$;

    this._modelsContentService.filteredModels$.pipe(untilDestroyed(this)).subscribe((models) => {
      this.models = models;
      this._cdr.markForCheck();
    });

    this._modelsContentService.companyId$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((companyId: number) => {
        this._companyId = companyId;
      });
  }

  onClick(model: Model) {
    this._router.navigate([this._companyId, 'models', model.id]);
  }

  onLoadNext(info: GridPageInfo) {
    this._gridPageInfo$.next({
      ...info,
      total: this.models.length,
    });
  }

  getPageRangeInfo(): PageRangeInfo | null {
    const info = this._gridPageInfo$.value;
    return {
      from: 1,
      to: Math.min(info.offset * info.threshold, info.total as number),
      total: info.total as number,
    };
  }
}
