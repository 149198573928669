import { UserTableFilterForm } from '@App/app/entities/forms/users-table-filter-form.model';
import { AdvancedSearchUsers } from '@App/app/entities/shared/advanced-search.model';
import { UsersSitesService } from '@App/app/pages/sites/shared/site-services/users-sites.service';
import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Roles } from '../../../../entities/users/user';
import { BaseFilterComponent } from '../../shared/base-filter/base-filter.component';
import { resetFilter } from '../../shared/utils';
import { UsersTableFiltersService } from './users-table-filters.service';

@UntilDestroy()
@Component({
  selector: 'app-users-table-filters',
  templateUrl: './users-table-filters.component.html',
  styleUrls: ['../../shared/base-filter/base-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UsersTableFiltersComponent extends BaseFilterComponent<
  AdvancedSearchUsers,
  UserTableFilterForm,
  UsersTableFiltersService,
  UsersSitesService
> {
  roles = Roles;

  constructor(
    cdr: ChangeDetectorRef,
    filterService: UsersTableFiltersService,
    siteService: UsersSitesService,
  ) {
    super(cdr, siteService, filterService);
  }

  typeOf(item: { key: keyof typeof Roles; value: number }) {
    return typeof item.value;
  }

  isFormTouched() {
    return super.isFormTouched() || this.isSomeFieldSelected(this.filterForm.value.role);
  }

  initAdvancedSearch() {
    Object.keys(Roles).forEach((role) => {
      if (!isNaN(Number(role))) {
        const typeFormGroup = this.filterForm.controls['role'];
        typeFormGroup.addControl(role + '', new FormControl(false));
      }
    });
  }

  resetFilters() {
    if (this.previousSearch) {
      this.filterForm.reset({
        ...this.previousSearch,
      });
    } else {
      const { query, role } = this.filterForm.value;
      if (role) resetFilter(role);
      this.filterForm.reset({
        query,
        role,
      });
    }
  }
}
