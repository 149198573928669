import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerClasses } from '@App/app/entities/layer/layer-classes.model';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { SketchLine } from '@App/app/entities/layer/sketch-tools/tools/sketch-line.model';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SketchCircle } from '../../../../../entities/layer/sketch-tools/tools/sketch-circle.model';

@Component({
  selector: 'app-layer',
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayerComponent {
  @Input() layer: LayerUI | SketchLine | SketchCircle;
  @Input() guiVisibility: boolean;
  @Input() hasGuiToggler: boolean;
  @Input() layerPath = '';
  @Input() dragDisabled = false;
  @Input() classes: LayerClasses;
  @Output() selectLayer: EventEmitter<LayerUI | SketchLine | SketchCircle> = new EventEmitter();
  @Output() clickLayerPath: EventEmitter<LayerUI | SketchLine | SketchCircle> = new EventEmitter();
  @Output() toggleLayerVisibility: EventEmitter<
    LayerUI | SketchLine | SketchCircle
  > = new EventEmitter();
  @Output() toggleLayerGuiVisibility: EventEmitter<
    LayerUI | SketchLine | SketchCircle
  > = new EventEmitter();
  @Output() showDetails: EventEmitter<LayerUI> = new EventEmitter();
  layerTypes = { ...LAYER_TYPES };
  planeIcon = '../../../assets/svg/icons/sf_portal-ui_icon_layers_plane.svg#Layer_1';
  sketchCircleIcon = '../../../assets/svg/icons/sf_portal-ui_icon_tools_circle.svg';
  sketchLineIcon = '../../../assets/svg/icons/sf_portal-ui_icon_tools_line.svg';
  sketchRectangleIcon = '../../../assets/svg/icons/sf_portal-ui_icon_tools_rectangle.svg';
  tubeIcon = '../../../assets/svg/icons/sf_portal-ui_icon_tube.svg';
  angleIronIcon = '../../../assets/svg/icons/sf_portal-ui_icon_angle_iron.svg';
  coordsIcon = '../../../assets/svg/icons/sf_portal-ui_icon_axis.svg';
  rectangularIcon = '../../../assets/svg/icons/sf_portal-ui_icon_rectangular.svg';
  rectangularTubeIcon = '../../../assets/svg/icons/sf_portal-ui_icon_layers_rect_tube.svg';
  annotationIcon = '../../../assets/svg/icons/sf_portal-ui_icon_layers_annotation.svg';

  constructor(private viewerLayerService: ViewerLayerService) {}

  isLayerUi(layer: LayerUI | SketchLine | SketchCircle) {
    return (
      layer.type !== LAYER_TYPES.SKETCH_CIRCLES &&
      layer.type !== LAYER_TYPES.SKETCH_LINES &&
      layer.type !== LAYER_TYPES.SKETCH_RECTANGLE
    );
  }

  checkDisplayWithCamera(layer: LayerUI | SketchLine | SketchCircle) {
    return layer.isVisible && this.isLayerUi(layer);
  }

  onShowLayer(event: MouseEvent) {
    event.stopPropagation();
    this.toggleLayerVisibility.emit(this.layer as LayerUI);
  }

  onClickGuiTag(event: MouseEvent) {
    event.stopPropagation();
    this.toggleLayerGuiVisibility.emit(this.layer as LayerUI);
  }

  onShowDetails(event: MouseEvent) {
    event.stopPropagation();
    this.showDetails.emit(this.layer as LayerUI);
  }

  onSetCameraTarget(event: MouseEvent) {
    event.stopPropagation();
    this.viewerLayerService.setCameraOnLayer(this.layer as LayerUI);
  }

  onClickLayerPath() {
    this.clickLayerPath.emit(this.layer as LayerUI);
  }
}
