import {
  FOLDER_DELETE_SUCCESS,
  LAYER_DELETE_SUCCESS,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { LayerHttpService } from '../../../../engine/services/layer-services/layer-http-service/layer-http.service';
import { LayersHttpActionsService } from '../../services/layers-http-actions-service/layers-http-actions.service';
import { LayersViewerUtilsService } from '../../services/layers-viewer-utils-service/layers-viewer-utils.service';
import { LayersService } from '../../services/layers/layers.service';
import { deleteLayer, deleteLayerFailed, deleteLayerSuccess } from '../actions/layers.actions';

@Injectable()
export class DeleteLayerEffects {
  constructor(
    private actions: Actions,
    private layerHttpService: LayerHttpService,
    private viewerLayerService: ViewerLayerService,
    private toastrService: NbToastrService,
    private layersService: LayersService,
    private _layersHttpActionsService: LayersHttpActionsService,
    private _layersViewerUtilsService: LayersViewerUtilsService,
  ) {}

  deleteLayer = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteLayer),
      mergeMap(({ id, _type, isForce, isHoist }) => {
        return this.layerHttpService.deleteBatchLayers([id], isForce, isHoist).pipe(
          map(() => {
            return deleteLayerSuccess({
              id,
              isFolder: _type === LAYER_TYPES.FOLDERS,
            });
          }),
          catchError(async () => {
            return deleteLayerFailed({ id });
          }),
        );
      }),
    );
  });

  deleteLayerSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(deleteLayerSuccess),
        map(({ id, isFolder }) => {
          this.layersService.isEditModeOnInitMappingTool = false;
          if (this.viewerLayerService.isLayerSaved(id)) {
            this.toastrService.show(LAYER_DELETE_SUCCESS, SUCCESS, {
              ...SUCCESS_TOASTR_CONFIG,
            });
          }
          if (isFolder) {
            this.toastrService.show(FOLDER_DELETE_SUCCESS, SUCCESS, {
              ...SUCCESS_TOASTR_CONFIG,
            });
          }
          this.layersService.setDetailsViewLayers([]);
          this._layersViewerUtilsService.deleteNestedFromViewer([id]);
          this.layersService.setLoading(false);
        }),
      );
    },
    { dispatch: false },
  );

  deleteLayerFailed = createEffect(
    () => {
      return this.actions.pipe(
        ofType(deleteLayerFailed),
        withLatestFrom(this.layersService.layers$, this.layersService.folders$),
        map(([{ id }, layers, folders]) => {
          this._layersHttpActionsService.openDeleteParentLayerDialog(id, layers, folders);
        }),
      );
    },
    { dispatch: false },
  );
}
