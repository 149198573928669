import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { httpLayer } from '@App/app/entities/layer/layer.model';
import { ApiService } from '@App/app/shared/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LayerHttpService extends ApiService {
  constructor(private store: Store, authService: AuthService, http: HttpClient) {
    super(authService, http);
  }

  getLayersByRenderId(renderId: number): Observable<(LayerUI | LayerUiExpandable)[]> {
    return this.http.get<(LayerUI | LayerUiExpandable)[]>(
      `${this.api}/layers?renderId=${renderId}`,
    );
  }

  postBatchLayers(body: httpLayer[]): Observable<(LayerUI | LayerUiExpandable)[]> {
    return this.http.post<(LayerUI | LayerUiExpandable)[]>(`${this.api}/layers/batch`, body);
  }

  putBatchLayers(body: httpLayer[]): Observable<(LayerUI | LayerUiExpandable)[]> {
    return this.http.put<(LayerUI | LayerUiExpandable)[]>(`${this.api}/layers/batch`, body);
  }

  deleteBatchLayers(
    ids: number[],
    force = false,
    hoistChildren = false,
  ): Observable<{ deletedCount: number }> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: new HttpParams(),
      body: { ids },
    };
    if (force) {
      options.params = options.params.set('force', `${force}`);
    } else if (hoistChildren) {
      options.params = options.params.set('hoistChildren', `${hoistChildren}`);
    }
    return this.http.delete<{ deletedCount: number }>(`${this.api}/layers/batch`, options);
  }
}
