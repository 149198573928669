import { createReducer, on } from '@ngrx/store';
import { SonyAuthData } from '../../../models/sony/sony-auth-data.model';
import { SonyLoginSteps as Steps } from '../../../models/sony/sony-login-steps.model';
import {
  fetchUserLoginCodeSuccess,
  getLoginUrl,
  getLoginUrlSuccess,
  loadTokens,
  loadTokensSuccess,
  login,
  resetSonyAuthData,
} from '../actions/sony-auth.actions';

type State = SonyAuthData;

const initialState: State = {
  codeSet: null,
  loginStep: null,
  refreshToken: null,
  accessToken: null,
};

export const sonyAuthReducer = createReducer(
  initialState,
  on(login, (state): State => ({ ...state, loginStep: Steps.WAITING_FOR_REDIRECT_MESSAGE })),
  on(
    getLoginUrl,
    fetchUserLoginCodeSuccess,
    (state, { codeSet }): State => ({ ...state, codeSet }),
  ),
  on(
    getLoginUrlSuccess,
    (state): State => ({ ...state, loginStep: Steps.WAITING_FOR_LOGIN_TO_SONY_MESSAGE }),
  ),
  on(
    loadTokensSuccess,
    (state, { refreshToken, accessToken }): State => ({
      ...state,
      refreshToken,
      accessToken,
      loginStep: null,
    }),
  ),
  on(loadTokens, (state): State => ({ ...state, loginStep: Steps.WAITING_FOR_FETCH_TOKEN })),
  on(resetSonyAuthData, (): State => initialState),
);

export const getCodeSet = (state: State) => state.codeSet;

export const getLoginStep = (state: State) => state.loginStep;

export const getTokens = ({ accessToken, refreshToken }: State) => ({
  accessToken,
  refreshToken,
});
