/* eslint-disable @typescript-eslint/naming-convention */
import { ApiService } from '@App/app/shared/api.service';
import { environment } from '@App/environments/environment';
import { Injectable } from '@angular/core';
import { NgHttpCachingHeaders } from 'ng-http-caching';
import { SonyCodeSet } from '../../models/sony/sony-code-set.model';
import { SonyLoginUrlResponse } from '../../models/sony/sony-login-url-response.model';
import { SonyMissionsResponse } from '../../models/sony/sony-missions-response.model';
import { SonyTokenResponse } from '../../models/sony/sony-token-response.model';

@Injectable({
  providedIn: 'root',
})
export class SonyHttpService extends ApiService {
  getMissions() {
    return this.http.get<SonyMissionsResponse>(`${environment.sonyEnv.url}/api/v1/missions`, {
      headers: {
        sonyRequest: 'true',
        [NgHttpCachingHeaders.ALLOW_CACHE]: '1',
      },
    });
  }

  startUpload(modelId: number, sourceImagesPath: string, runATAutomatically: boolean) {
    return this.http.post(`${this.api}/buildProcess/startUploadFromSony`, {
      modelId,
      sourceImagesPath: `s3://${environment.sonyEnv.bucket}${sourceImagesPath}`,
      runAerotriangulationAutomatically: runATAutomatically,
      viewerURL: `${this.api}/processing/`,
    });
  }

  generateCodeChallenge() {
    return this.http.post<SonyCodeSet>(
      `${environment.loginUrl}/auth/sony/generate-code-challenge`,
      {},
    );
  }

  getCodeChallenge(state: string) {
    return this.http.get<SonyCodeSet>(
      `${environment.loginUrl}/auth/sony/get-code-challenge?state=${state}`,
    );
  }

  getLoginUrl(codeChallenge: string, state: string) {
    return this.http.get<SonyLoginUrlResponse>(`${environment.sonyEnv.url}/api/v1/auth`, {
      params: {
        lang: 'en',
        country: 'US',
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
        state,
        redirect_uri: `${environment.loginUrl}/auth/sony/callback`,
      },
    });
  }

  getToken(codeVerifier: string, code: string) {
    return this.http.post<SonyTokenResponse>(`${environment.sonyEnv.url}/api/v1/token`, {
      code_verifier: codeVerifier,
      code,
    });
  }

  refreshToken(refreshToken: string) {
    return this.http.post<SonyTokenResponse>(
      `${environment.sonyEnv.url}/api/v1/token:refresh`,
      {
        refresh_token: refreshToken,
      },
      {
        headers: {
          sonyRequest: 'true',
        },
      },
    );
  }
}
