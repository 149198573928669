<ng-template #dialog let-ref="dialogRef">
  <nb-card class="log-dialog">
    <nb-card-header>
      <span>Logs</span>
      <button nbButton (click)="closeDialog()" status="basic" size="tiny" data-testid="close-logs">
        <nb-icon icon="close-outline"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body>
      <pre>{{ logs }}</pre>
    </nb-card-body>
  </nb-card>
</ng-template>
