import { NO_AT_MESH, WARNING } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { ATMesh } from '@App/app/entities/files/files.model';
import { ModelType } from '@App/app/entities/processing/build-process-status.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { TileInfo } from '@App/app/entities/viewer/tiles-info.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { GcpPointsService } from '@App/app/pages/viewer/services/gcp-points/gcp-points.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import {
  AssetContainer,
  ArcRotateCamera as Cam,
  ISceneLoaderAsyncResult,
  Mesh,
  ISceneLoaderProgressEvent as ProgressEvent,
  TransformNode,
} from 'babylonjs';
import { GLTFFileLoader } from 'babylonjs-loaders';
import { MODEL_AT_NAME, MODEL_NAME } from 'src/app/configs/babylon.config';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { abortLoaderRequests, clearLoaders } from '../../utils/babylon-utils/babylon.utils';
import { SceneService } from '../scene-service/scene.service';
import { AssetContainerWithGui } from './asset-with-gui-container/asset-with-gui-container';
import { Roots } from './models/roots.model';
import { LoadModelUtilsService } from './services/load-model-utils-service/load-model-utils.service';

@Injectable()
export class LoadModelService {
  private _progress = 0;
  private _atContainer: AssetContainerWithGui | null;
  private _prodContainer: AssetContainer | null;
  model: ISceneLoaderAsyncResult[] = [];
  loaders: GLTFFileLoader[] = [];
  roots: Roots = { [ModelType.AT]: null, [ModelType.Prod]: null };
  loadedTiles: TileInfo[] = [];
  isATModelLoaded = false;
  isProdModelLoaded = false;

  constructor(
    private _broadcastService: BroadcastService,
    private _sceneService: SceneService,
    private _toastrService: NbToastrService,
    private _loadModelUtilsService: LoadModelUtilsService,
    private _modelsProcessingService: ModelsProcessingService,
    private _gcpPointsService: GcpPointsService,
  ) {}

  async loadProdModel(tiles: string[], camera: Cam, updateLayers: boolean, tilesRange?: number[]) {
    this.removeModel([ModelType.Prod]);
    const { isProdModelLoaded } = this;
    const useContainer = this._shouldUseAssetContainer(camera, ModelType.Prod, isProdModelLoaded);
    if (!updateLayers && useContainer) return Promise.resolve();
    const root = new TransformNode(MODEL_NAME, this._sceneService.scene);
    this.roots[ModelType.Prod] = root;
    this._prodContainer?.rootNodes.push(this.roots[ModelType.Prod] as TransformNode);
    const tilesArr = tilesRange ? tiles.slice(tilesRange[0], tilesRange[1]) : [...tiles];
    return await Promise.all(
      tilesArr.map(async (asset) => {
        let promise: ISceneLoaderAsyncResult;
        if (updateLayers) {
          promise = await this.loadAssetAsync(asset, ModelType.Prod);
          this._loadModelUtilsService.updateProgressBar(tilesArr.length + 1, ++this._progress);
        } else {
          promise = await this.loadAssetAsync(asset, ModelType.Prod, this._prodContainer);
          this._loadModelUtilsService.updateProgressBar(tilesArr.length, ++this._progress);
        }
        const assetParts = asset.split('/');
        const [tileName] = [(assetParts[assetParts.length - 2] || asset).replace('_s', '')];
        const [_root, meshes] = [promise.meshes[0] as Mesh, promise.meshes.slice(1) as Mesh[]];
        this.loadedTiles.push({ tileName, root: _root, meshes });
        return promise;
      }),
    ).then((loadedMeshes) => {
      const meshes = updateLayers ? loadedMeshes : undefined;
      this._loadModelUtilsService.afterAssetLoadTransforms(root, camera, meshes);
    });
  }

  async loadATModel(atMesh: ATMesh | null, camera: Cam) {
    this.removeModel([ModelType.AT]);
    if (!atMesh) {
      this._toastrService.show(NO_AT_MESH, WARNING, WARNING_TOASTR_CONFIG);
      return Promise.resolve();
    }
    if (this._shouldUseAssetContainer(camera, ModelType.AT, this.isATModelLoaded)) {
      return Promise.resolve();
    }
    const root = new TransformNode(MODEL_AT_NAME, this._sceneService.scene);
    this.roots[ModelType.AT] = root;
    this._atContainer?.rootNodes.push(this.roots[ModelType.AT] as TransformNode);
    return await this.loadAssetAsync(atMesh.downloadURL, ModelType.AT, this._atContainer, false)
      .then(() => this._loadModelUtilsService.afterAssetLoadTransforms(root, camera))
      .then(() => this._createGcpPoint())
      .catch((err) => console.log(err));
  }

  removeModel(rootTypes: ModelType[], full = false) {
    this._progress = 0;
    this._broadcastService.broadcast(EVENT_TYPE.CHECK_PROGRESS, 0);
    this._clearLoaders();
    this.loadedTiles = [];
    if (this._sceneService.scene) {
      if (this._atContainer || this._prodContainer) {
        this._atContainer?.removeAllFromScene();
        this._prodContainer?.removeAllFromScene();
      } else {
        this._sceneService.scene.blockfreeActiveMeshesAndRenderingGroups = true;
        this._loadModelUtilsService.disposeRootsByTypes(rootTypes, this.roots);
        this.model?.forEach((i) => this._loadModelUtilsService.disposeModelLoadedElement(i));
        this.model = [];
        this._sceneService.scene.blockfreeActiveMeshesAndRenderingGroups = false;
      }
    }
    if (full) this._clearContainers();
  }

  abortAllLoaderRequests() {
    abortLoaderRequests(this.loaders);
    this.loaders = [];
  }

  getContainerByType(key: ModelType) {
    return key === ModelType.AT ? this._atContainer : this._prodContainer;
  }

  clearContainerByType(key: ModelType) {
    const prop = key === ModelType.AT ? '_atContainer' : '_prodContainer';
    const isLoaded = key === ModelType.AT ? this.isATModelLoaded : this.isProdModelLoaded;
    if (!isLoaded) {
      const container = (this[prop] as unknown) as AssetContainer | null;
      container?.removeAllFromScene();
      container?.meshes.forEach((mesh) => mesh.dispose());
      container?.dispose();
      this[prop] = null;
    }
  }

  loadAssetAsync(
    asset: string,
    rootKey: ModelType,
    ctr: AssetContainer | null = null,
    pickable = true,
    onProg?: (e: ProgressEvent) => void,
  ) {
    const root = this.roots[rootKey] as TransformNode | null;
    const { loaders: l, model } = this;
    return this._loadModelUtilsService.loadAssetAsync(asset, l, ctr, root, model, pickable, onProg);
  }

  private _createGcpPoint() {
    const process = this._modelsProcessingService.getCurrentBuildProcess();
    if (process?.gcpList?.downloadURL) {
      this._gcpPointsService
        .downloadGcpPoints(process?.gcpList?.downloadURL)
        .subscribe((gcpPoints) => {
          gcpPoints.forEach((point) =>
            this._loadModelUtilsService.createGCP3DMarker(point, this._atContainer),
          );
        });
    }
  }

  private _clearLoaders() {
    if (this.loaders?.length) {
      const loading = clearLoaders(this.loaders);
      this.loaders = [];
      if (loading) {
        this._clearContainers();
        window.stop();
      }
    }
  }

  private _clearContainers() {
    this._atContainer?.dispose();
    this._prodContainer?.dispose();
    this._atContainer = null;
    this._prodContainer = null;
    this.isATModelLoaded = false;
    this.isProdModelLoaded = false;
  }

  private _shouldUseAssetContainer(camera: Cam, containerKey: ModelType, isModelLoaded: boolean) {
    const prop = containerKey === ModelType.AT ? '_atContainer' : '_prodContainer';
    const container = (this[prop] as unknown) as AssetContainerWithGui | null;
    if (!container) {
      const newContainer = new AssetContainerWithGui(this._sceneService.scene);
      this[prop] = newContainer;
    } else if (container?.meshes.length && isModelLoaded) {
      container.addAllToScene();
      this._loadModelUtilsService.setCameraOnRoot(container.rootNodes[0] as TransformNode, camera);
      return true;
    }
    return false;
  }
}
