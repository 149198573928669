import { AuthData } from '@App/app/entities/auth/auth-data.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../reducers/auth.reducer';

export const selectAuthState = createFeatureSelector<AuthData>('auth');

export const selectAuthData = createSelector(selectAuthState, fromAuth.getAuthData);

export const selectUser = createSelector(selectAuthState, fromAuth.getUser);

export const selectIdToken = createSelector(selectAuthState, fromAuth.getIdToken);

export const selectTokens = createSelector(selectAuthState, fromAuth.getTokens);

export const selectEndpoints = createSelector(selectAuthState, fromAuth.getEndpoints);

export const selectCurrentEndpoint = createSelector(selectAuthData, fromAuth.getCurrentEndpoint);

export const selectUiSettings = createSelector(selectAuthData, fromAuth.getUiSettings);

export const selectHasDocsAccepted = createSelector(selectAuthData, fromAuth.checkHasDocsAccepted);
