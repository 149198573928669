<div class="layer_details__row">
  <div class="layer_details__label">Length</div>
  <div class="layer_details__value">
    <app-layer-details-optional-measurement-field
      [value]="layer.data.length"
      [unitsSystem]="unitsSystem"
      type="length"
    ></app-layer-details-optional-measurement-field>
  </div>
</div>
<div class="layer_details__row">
  <div class="layer_details__label">Radius outer</div>
  <div class="layer_details__value">
    <span *ngIf="!(layer | isLayerBeingEdited)">
      <app-layer-details-optional-measurement-field
        [value]="layer.data.radiusOuter"
        [unitsSystem]="unitsSystem"
        type="length"
      ></app-layer-details-optional-measurement-field>
    </span>
    <app-layer-thickness-input
      *ngIf="layer | isLayerBeingEdited"
      field="radiusOuter"
      [layer]="layer"
    ></app-layer-thickness-input>
  </div>
</div>
<div class="layer_details__row" *ngIf="layer.data.radiusInner">
  <div class="layer_details__label">Radius inner</div>
  <div class="layer_details__value">
    <span *ngIf="!(layer | isLayerBeingEdited)">
      {{ layer.data.radiusInner | lengthUnit: unitsSystem }}
    </span>
    <app-layer-thickness-input
      *ngIf="layer | isLayerBeingEdited"
      field="radiusInner"
      [layer]="layer"
    ></app-layer-thickness-input>
  </div>
</div>
