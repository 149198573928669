import { MODEL_TYPES } from '@App/app/entities/models/model-types.enum';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import {
  IPresetModel,
  IProcessingSettings,
} from '@App/app/entities/processing/processing-preset-settings.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { loadAllModels } from '@App/app/pages/models/store/models/actions/models.actions';
import {
  selectAllModels,
  selectModelById,
} from '@App/app/pages/models/store/models/selectors/models.selectors';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { skip, switchMapTo, take } from 'rxjs/operators';
import { ProcessingService } from '../../processing.service';
import { BaseTabComponent } from './base-tab.component';
@UntilDestroy()
@Component({
  template: '',
})
export abstract class SetupTabComponent<T extends FormGroup> extends BaseTabComponent
  implements OnInit {
  static step: ProcessingSteps;
  static settings: IProcessingSettings[];
  @Output() protected startNext = new EventEmitter<T['value']>();
  form: T;
  modelType: MODEL_TYPES | undefined;
  process: BuildProcess | null;
  presets: IPresetModel[];

  constructor(
    protected fb: NonNullableFormBuilder,
    processingService: ProcessingService,
    protected store: Store,
    protected _modelsProcessingService: ModelsProcessingService,
  ) {
    super(processingService);
    this.onSubmit = this.onSubmit.bind(this);
    this.store.dispatch(loadAllModels());
    this.process = this._modelsProcessingService.getCurrentBuildProcess();
  }

  ngOnInit() {
    this.form = this.createForm();
    this.store
      .select(selectAllModels)
      .pipe(skip(1), take(1))
      .pipe(switchMapTo(this.store.select(selectModelById(Number(this.process?.modelId)))))
      .pipe(take(1))
      .subscribe((model) => {
        this.modelType = model?.modelType;
        this.presets = this.processingService.getPresetsByType(
          this.modelType as MODEL_TYPES,
          (this.constructor as typeof SetupTabComponent).step,
          (this.constructor as typeof SetupTabComponent).settings,
        );

        this.form.patchValue({ settingsPreset: 0 });
        this.form.controls.settingsPreset.valueChanges
          .pipe(untilDestroyed(this))
          .subscribe((value) => {
            const settings = this.presets?.find((preset) => preset.id === value)?.settings;
            this.form.patchValue(settings);
          });
      });
  }

  protected abstract createForm(): T;

  protected abstract getSettings(): IProcessingSettings[];

  abstract onSubmit(form: T): void;
}
