import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { Injector } from '@angular/core';
import { GizmoManager } from 'babylonjs';
import { BasePlane } from '../../entities/layer/base-plane.model';
import { getCameraTargetDataByMesh } from '../helpers/layers-helpers';
import { BasePlaneService } from '../services/layer-services/base-plane-services/base-plane.service';
import { BaseViewerLayer } from './base-viewer-layer';
import { ViewerLayer } from './viewer-layer';

export class BasePlaneViewerLayer extends BaseViewerLayer implements ViewerLayer {
  protected layerUtilsService: BasePlaneService;
  private gizmoManager?: GizmoManager;
  backupLayer: LayerUI | null;
  layerViewer: BasePlane;

  constructor(injector: Injector) {
    super(injector);
    this.layerUtilsService = this.injector.get(BasePlaneService);
  }

  showLayer(layer?: LayerUI | LayerUiExpandable) {
    if (!this.layerViewer && layer) {
      this.layerViewer = { ...layer } as BasePlane;
    }
    this.layerUtilsService.setBasePlaneVisibility(this.layerViewer, true);
    this.showLayerGui();
  }

  hideLayer() {
    this.layerUtilsService.setBasePlaneVisibility(this.layerViewer, false);
    this.hideLayerGui();
  }

  deleteLayer() {
    this.hideLayer();
  }

  createBackupLayer(layer: LayerUI | LayerUiExpandable) {
    this.backupLayer = this.copyBasePlane(layer as BasePlane);
  }

  hasActiveLayerViewer() {
    return this.layerViewer.isVisible;
  }

  loadBackupLayer() {
    this.hideLayer();
    this.createBasePlane(this.backupLayer as BasePlane);
    this.showLayer();
    this.backupLayer = null;
  }

  setLayerEditMode(value: boolean) {
    if (this.gizmoManager) {
      this.layerUtilsService.setBasePlaneEditMode(this.gizmoManager, value);
    }
  }

  getData() {
    if (this.layerViewer.mesh) {
      this.layerUtilsService?.updateBasePlane(this.layerViewer, this.layerViewer.mesh);
    }
    return this.layerViewer?.data;
  }

  getCameraTargetData() {
    if (this.layerViewer.mesh) {
      return getCameraTargetDataByMesh(this.layerViewer.mesh);
    }
  }

  toggleImperialGui() {}

  destroyLayer() {
    if (this.layerViewer.gui) {
      this.layerViewer.gui.text?.dispose();
      this.layerViewer.gui.bg?.dispose();
      this.layerViewer.gui.arrow?.dispose();
      this.layerViewer.gui.text = null;
      this.layerViewer.gui.bg = null;
      this.layerViewer.gui.arrow = null;
      delete this.layerViewer.gui;
    }
    this.gizmoManager?.dispose();
    delete this.gizmoManager;
    this.layerViewer.mesh?.dispose();
    delete this.layerViewer.mesh;
  }

  initBasePlane(basePlane: BasePlane) {
    this.createBasePlane(this.copyBasePlane(basePlane));
    this.hideLayerGui();
  }

  private createBasePlane(basePlane: BasePlane) {
    const meshes = this.layerUtilsService.createMesh(basePlane);
    if (meshes) {
      [this.layerViewer, this.gizmoManager] = meshes;
    }
    if (this.layerViewer.mesh) {
      this.gizmoManager?.attachToMesh(this.layerViewer.mesh);
    }
  }

  private copyBasePlane(basePlane: BasePlane) {
    const copyBasePlane = {
      ...basePlane,
      data: {
        ...basePlane.data,
      },
    };
    if (basePlane.data.position) {
      copyBasePlane.data.position = {
        ...basePlane.data.position,
      };
    }
    return copyBasePlane;
  }
}
