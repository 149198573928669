import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHoursAndMinutes',
})
export class SecondsToHoursAndMinutesPipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value / 60) - hours * 60;
    return hours + 'h ' + minutes + 'm';
  }
}
