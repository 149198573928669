<div class="details">
  <div class="details__section">
    <div class="details__section__header">
      <p>Coordinates</p>
      <ng-container
        *ngTemplateOutlet="
          unitToggler;
          context: {
            unit: isGeoLocationInDecimal ? 'degrees' : 'decimal',
            toggler: toggleGeoLocationFormat
          }
        "
      ></ng-container>
    </div>
    <table>
      <tr>
        <td>Latitude</td>
        <td data-testId="coords-latitude">
          {{
            model.location?.latitude
              ? isGeoLocationInDecimal
                ? model.location?.latitude
                : decimalToDegrees(model.location?.latitude || 0)
              : '-'
          }}
        </td>
      </tr>
      <tr>
        <td>Longitude</td>
        <td>
          {{
            model.location?.longitude
              ? isGeoLocationInDecimal
                ? model.location?.longitude
                : decimalToDegrees(model.location?.longitude || 0)
              : '-'
          }}
        </td>
      </tr>
    </table>
  </div>
  <div class="details__section">
    <div class="details__section__header">
      <p>Site</p>
      <div class="details__section__toggler" (click)="toggleSiteDataFormat()"></div>
      <ng-container
        *ngTemplateOutlet="
          unitToggler;
          context: {
            unit: siteDataUnitsSystem === unitsSystem.Imperial ? 'metric' : 'imperial',
            toggler: toggleSiteDataFormat
          }
        "
      ></ng-container>
    </div>
    <table>
      <tr>
        <td>Site area</td>
        <td data-testId="site-site-area">
          {{ !!model.siteArea ? (model.siteArea | areaUnit: siteDataUnitsSystem) : '-' }}
        </td>
      </tr>
      <tr>
        <td>Gate width</td>
        <td>
          {{ !!model.gateWidth ? (model.gateWidth | lengthUnit: siteDataUnitsSystem) : '-' }}
        </td>
      </tr>
      <tr>
        <td>Fenceline</td>
        <td>
          {{ !!model.fenceline ? (model.fenceline | lengthUnit: siteDataUnitsSystem) : '-' }}
        </td>
      </tr>
      <tr>
        <td>Pad size</td>
        <td>
          {{ !!model.padSize ? (model.padSize | lengthUnit: siteDataUnitsSystem) : '-' }}
        </td>
      </tr>
      <tr>
        <td>Distance between pads</td>
        <td>
          {{ !!model.padsDistance ? (model.padsDistance | lengthUnit: siteDataUnitsSystem) : '-' }}
        </td>
      </tr>
    </table>
  </div>
  <app-model-section [dataTypeParams]="getTypeParams()" [model]="model"></app-model-section>
  <div class="details__section">
    <div class="details__section__header">
      <p>Key Dates</p>
    </div>
    <table>
      <tr>
        <td>Flown</td>
        <td>
          {{ model.flown ? (model.flown | date: 'MM/dd/yyyy') : '-' }}
        </td>
      </tr>
      <tr>
        <td>Processed</td>
        <td>
          {{ model.processed ? (model.processed | date: 'MM/dd/yyyy') : '-' }}
        </td>
      </tr>
      <tr>
        <td>UAS Operator</td>
        <td>Skyfish</td>
      </tr>
    </table>
  </div>
  <div class="details__section">
    <div class="details__section__header">
      <p>Personnel</p>
    </div>
    <table>
      <tr>
        <td>Site Manager</td>
        <td>
          {{ manager ? manager.firstName + ' ' + manager.lastName : '-' }}
        </td>
      </tr>
      <tr>
        <td>Pilot</td>
        <td>
          {{ pilot ? pilot.firstName + ' ' + pilot.lastName : '-' }}
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #unitToggler let-unit="unit" let-toggler="toggler">
  <a (click)="toggler()" class="details__section__header__toggler"> Show in {{ unit }} </a>
</ng-template>
