<nb-form-field [formGroup]="formGroup" class="input-email">
  <nb-icon *ngIf="prefixIcon" nbPrefix [icon]="prefixIcon"></nb-icon>
  <input
    type="email"
    nbInput
    fullWidth
    placeholder="Email"
    name="email"
    [formControlName]="controlName"
    email
    required
    [status]="error ? 'danger' : ''"
  />
</nb-form-field>
