import { GizmoManager, Mesh, Vector3 } from 'babylonjs';
import { TextBlock } from 'babylonjs-gui';
import { BaseDxfExportableViewerLayer } from '../base-dxf-exportable-viewer-layer.model';
import { LAYER_TYPES } from '../enums/layer-types.enum';
import { Layer } from '../layer.model';
import { Coordinates3 } from '../measurements/coordinates';
import { NameTag } from '../name-tag.model';
import { PolygonLines } from './polygon-lines.model';

export class Polygon implements Layer<PolygonData> {
  id: number;
  createdAt: string;
  editedAt: string;
  isVisible: boolean;
  isSaved = true;
  name: string = LAYER_TYPES.POLYGONS;
  type = LAYER_TYPES.POLYGONS;
  data: PolygonData = {
    points: [],
  };
  description: string;

  parentId: number;

  static calculateSquareArea(pointsMeshes: Mesh[]) {
    const spheresPositionsArr = pointsMeshes.map((e) => {
      return new Vector3(e.position.x, e.position.y, e.position.z);
    });

    let area = 0;
    let j = spheresPositionsArr.length - 1;
    const X = spheresPositionsArr.map((vector) => vector.x);
    const Z = spheresPositionsArr.map((vector) => vector.z);

    for (let i = 0; i < spheresPositionsArr.length; i++) {
      area += (X[j] + X[i]) * (Z[j] - Z[i]);
      j = i;
    }

    return +(Math.abs(area) / 2).toFixed(2);
  }
}

export interface PolygonData {
  points?: Coordinates3[];
  squareArea?: number;
}

export interface ViewerPolygonLayer extends Polygon, BaseDxfExportableViewerLayer {
  gui: NameTag;
  gizmoManager?: GizmoManager;
  pointsMeshes: Mesh[];
  pointsNameTag: NameTag[];
  mesh?: Mesh;
  polygonLines: PolygonLines;
  squareAreaTag?: TextBlock;
  data: PolygonData;
}
