import { GCPPoint } from '@App/app/entities/files/gcp-point.model';
import { ApiService } from '@App/app/shared/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Srs } from '../../processing/tabs/at-setup-tab/at-setup-control-points/srs-dropdown/models/srs.model';

@Injectable({
  providedIn: 'root',
})
export class GcpHttpService extends ApiService {
  download(gcpPointsPath: string) {
    return this.http.get<GCPPoint[]>(gcpPointsPath, {
      headers: {
        notLogOutOn403: 'true',
      },
    });
  }

  getHttpSrs(srsCode: string): Observable<Srs> {
    return this.http.get<Srs>(`${this.api}/srs/${srsCode}`);
  }
}
