<div class="renders">
  <div class="renders__content">
    <nb-card class="renders__content__current_render">
      <nb-card-body>
        <div
          class="renders__content__current_render__details"
          [nbSpinner]="!renders || !!(renders.length && !currentRender)"
        >
          <div
            *ngIf="renders && !renders.length"
            class="renders__content__current_render__details__no_renders"
          >
            <span>Viewer and photos are not available since this model doesn't have renders</span>
          </div>
          <table *ngIf="currentRender && analyticsData">
            <tr class="renders__content__current_render__details__render_name">
              <td>Current Render</td>
              <td data-testId="current-render-value">
                {{ currentRender.createdAtDate.getTime() | date }}
              </td>
            </tr>
            <tr>
              <td>Gigapixels</td>
              <td>{{ analyticsData.pixelsTotal || '-' }}</td>
            </tr>
            <tr>
              <td>Model Size</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="memorySizeTpl; context: { value: analyticsData.modelSize }"
                ></ng-container>
              </td>
            </tr>
            <tr>
              <td>Image Size Total</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="
                    memorySizeTpl;
                    context: { value: analyticsData.imageSizeTotal }
                  "
                ></ng-container>
              </td>
            </tr>
            <ng-container *ngIf="currentRender.orthoDSM || currentRender.pointCloud">
              <ng-container
                *ngTemplateOutlet="dividerTpl; context: { icon: 'archive-outline' }"
              ></ng-container>
              <tr *ngIf="currentRender.orthoDSM && currentRender.orthoDSM.isUploaded">
                <td>Orthophoto/DSM</td>
                <td>
                  <a [href]="currentRender.orthoDSM.downloadURL" nbTooltip="Download TIFF files"
                    ><nb-icon icon="download-outline"></nb-icon>
                  </a>
                </td>
              </tr>
              <tr *ngIf="currentRender.pointCloud && currentRender.pointCloud.isUploaded">
                <td>Point Cloud</td>
                <td>
                  <a [href]="currentRender.pointCloud.downloadURL" nbTooltip="Download LAS files"
                    ><nb-icon icon="download-outline"></nb-icon
                  ></a>
                </td>
              </tr>
            </ng-container>
            <ng-container
              *ngTemplateOutlet="dividerTpl; context: { icon: 'clock-outline' }"
            ></ng-container>
            <tr>
              <td>Aerotriangulation Duration</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="
                    durationTpl;
                    context: { value: analyticsData.durations.atDuration }
                  "
                ></ng-container>
              </td>
            </tr>
            <tr>
              <td>Production Duration</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="
                    durationTpl;
                    context: { value: analyticsData.durations.prodDuration }
                  "
                ></ng-container>
              </td>
            </tr>
            <tr>
              <td>Post-Production Duration</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="
                    durationTpl;
                    context: { value: analyticsData.durations.ppDuration }
                  "
                ></ng-container>
              </td>
            </tr>
            <tr>
              <td>Total Duration</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="
                    durationTpl;
                    context: { value: analyticsData.durations.totalDuration }
                  "
                ></ng-container>
              </td>
            </tr>
            <ng-container
              *ngTemplateOutlet="dividerTpl; context: { icon: 'credit-card-outline' }"
            ></ng-container>
            <tr>
              <td>Aerotriangulation Cost</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="currencyTpl; context: { value: analyticsData.costs.atCost }"
                ></ng-container>
              </td>
            </tr>
            <tr>
              <td>Production Cost</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="currencyTpl; context: { value: analyticsData.costs.prodCost }"
                ></ng-container>
              </td>
            </tr>
            <tr>
              <td>Post-Production Cost</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="currencyTpl; context: { value: analyticsData.costs.ppCost }"
                ></ng-container>
              </td>
            </tr>
            <tr>
              <td>Total Render Cost</td>
              <td>
                <ng-container
                  *ngTemplateOutlet="currencyTpl; context: { value: analyticsData.costs.totalCost }"
                ></ng-container>
              </td>
            </tr>
          </table>
        </div>
      </nb-card-body>
      <nb-card-footer class="renders__links" *ngIf="currentRender">
        <ng-container *ngIf="currentRender">
          <a
            [routerLink]="[
              '/',
              companyId,
              'models',
              model.id,
              'renders',
              currentRender.id,
              'viewer'
            ]"
            nbButton
            status="primary"
          >
            <nb-icon icon="layers-outline"></nb-icon>
            Viewer
          </a>
          <a
            [routerLink]="[
              '/',
              companyId,
              'models',
              model.id,
              'renders',
              currentRender.id,
              'photos'
            ]"
            nbButton
            status="primary"
          >
            <nb-icon icon="image-outline"></nb-icon>
            Photos
          </a>
        </ng-container>
      </nb-card-footer>
    </nb-card>
    <nb-card class="renders__content__selection">
      <nb-card-body>
        <app-filterable-list
          [entities]="renders"
          [selectedEntity]="currentRender"
          [dateField]="{
            key: 'createdAtDate',
            placeholder: 'Search render\'s by date'
          }"
          noEntitiesPlaceholder="No render to show"
          (changeEntity)="onRenderChange($event)"
        ></app-filterable-list>
      </nb-card-body>
      <nb-card-footer class="renders__links" *ngIf="!model.isArchived">
        <button
          nbButton
          *ngxPermissionsOnly="permissions.CAN_UPLOAD_PHOTOS"
          (click)="onAddRenderClick()"
          status="primary"
          class="renders__links__add_render"
        >
          <nb-icon icon="plus-outline"></nb-icon>
          <span>Add Render</span>
        </button>
      </nb-card-footer>
    </nb-card>
  </div>
</div>

<ng-template #memorySizeTpl let-value="value">
  {{ (value | isNumeric) ? (value | memorySizeUnit) : '-' }}
</ng-template>

<ng-template #durationTpl let-value="value">
  {{ (value | isNumeric) ? (value | secondsToHoursAndMinutes) : '-' }}
</ng-template>

<ng-template #currencyTpl let-value="value">
  {{ (value | isNumeric) ? (value | currency) : '-' }}
</ng-template>

<ng-template #dividerTpl let-icon="icon">
  <tr class="renders__content__current_render__details__divider">
    <td>
      <nb-icon [icon]="icon"></nb-icon>
      <hr />
    </td>
  </tr>
</ng-template>
