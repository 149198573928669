import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { EditUserFormExt, HttpEditRoles, HttpEditUser } from '../../../entities/users/user';
import { editUser } from '../store/actions/users.actions';

@Injectable({
  providedIn: 'root',
})
export class EditUserService {
  private _resetForm$ = new Subject<void>();
  resetForm$ = this._resetForm$.asObservable();

  private _isRequestSended$ = new BehaviorSubject<boolean>(false);
  isRequestSended$ = this._isRequestSended$.asObservable();

  constructor(private store: Store) {}

  editUser(user: EditUserFormExt) {
    this.store.dispatch(editUser({ user }));
  }

  mapUserFormToHttpUser(user: EditUserFormExt): HttpEditUser {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    };
  }

  mapUserToHttpEditRoles(user: EditUserFormExt): HttpEditRoles {
    return {
      userId: user.id,
      roleIds: user.roleIds,
    };
  }

  resetForm() {
    this._resetForm$.next();
  }

  setRequestSended(send: boolean) {
    this._isRequestSended$.next(send);
  }
}
