import { TOOL_TUTORIALS } from '@App/app/configs/hints.config';
import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NbDialogService, NbTrigger } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { RegionOfInterestService } from '@App/app/engine/services/region-of-interest-service/region-of-interest.service';
import { RegionOfInterest } from '@App/app/entities/processing/region-of-interest.model';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Observable } from 'rxjs';
import { UnitsService } from '../../../services/utils/units.service';
import { TutorialVideoPopupComponent } from '../../../tutorials-video/tutorial-video-popup/tutorial-video-popup.component';
@UntilDestroy()
@Component({
  selector: 'app-processing-settings',
  templateUrl: './processing-settings.component.html',
  styleUrls: ['./processing-settings.component.scss'],
})
export class ProcessingSettingsComponent implements AfterViewChecked, OnInit {
  @Input() form: UntypedFormGroup;
  @Input() settings: any;
  @Input() prodTab = false;
  NbTrigger = NbTrigger;
  roi$: Observable<RegionOfInterest | null>;
  @Output() resetSettings = new EventEmitter();
  isUnitImperial: boolean;
  unitSystem: UnitsSystem;

  constructor(
    private dialogService: NbDialogService,
    private regionOfInterestService: RegionOfInterestService,
    private unitsService: UnitsService,
  ) {}

  restoreDefault() {
    this.resetSettings.emit();
  }

  ngOnInit(): void {
    this.roi$ = this.regionOfInterestService.regionOfInterest$;
    this.unitsService.isImperialActive$.pipe(untilDestroyed(this)).subscribe(() => {
      this.checkWhichUnitSystem();
    });
  }

  ngAfterViewChecked(): void {
    this.form
      ?.get('settingsPreset')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          document.querySelectorAll('.ng-trigger-showTooltip').forEach((el) => {
            (el as HTMLElement).style.display = 'none';
          });
        }
      });
  }

  toggleROIVideo() {
    this.dialogService.open(TutorialVideoPopupComponent, {
      context: { path: TOOL_TUTORIALS.SHOW_ROI_TUTORIAL.videoPath },
    });
  }

  checkWhichUnitSystem(): void {
    this.isUnitImperial = this.unitsService.getImperialUnitsActive();
    if (this.isUnitImperial) {
      this.unitSystem = UnitsSystem.Imperial;
    } else {
      this.unitSystem = UnitsSystem.Metric;
    }
  }
}
