import { BaseFile, Thumbnail } from '@App/app/entities/files/files.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, modelFilesAdapter } from '../reducers/model-files.reducer';

export const getModelFilesState = createFeatureSelector<State>('modelFiles');

const { selectAll } = modelFilesAdapter.getSelectors(getModelFilesState);

export const selectAllModelFiles = selectAll;

export const selectThumbnails = createSelector(
  selectAll,
  (entities: BaseFile[]) => entities.filter((entity) => entity instanceof Thumbnail) as Thumbnail[],
);

export const selectSingleThumbnail = createSelector(
  selectThumbnails,
  (thumbnails) => thumbnails[0] || null,
);

export const selectThumbnailByModelId = (modelId: number) =>
  createSelector(
    selectThumbnails,
    (thumbnails) => thumbnails.find((t) => t.modelId === modelId) || null,
  );
