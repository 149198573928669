import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NebularModule } from '../modules';
import { AsyncActionDispatchedInfoComponent } from './components/async-action-dispatched-info/async-action-dispatched-info.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FilterableListComponent } from './components/filterable-list/filterable-list.component';
import { GridComponent } from './components/grid/grid.component';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';
import { InternetConnectionLostInfoComponent } from './components/internet-connection-status/internet-connection-status.component';
import { LayerDetailsNameComponent } from './components/layer-details-name/layer-details-name.component';
import { ModelProcessingActionsComponent } from './components/model-processing-actions/model-processing-actions.component';
import { ModelTypeBadgeComponent } from './components/model-type-badge/model-type-badge.component';
import { PhotoZoomLegendComponent } from './components/photo-zoom-legend/photo-zoom-legend.component';
import { ReportProblemDialogComponent } from './components/report-problem-dialog/report-problem-dialog/report-problem-dialog.component';
import { SegmentedProgressBarComponent } from './components/segmented-progress-bar/segmented-progress-bar.component';
import { WrongPasswordErrorComponent } from './components/wrong-password-error/wrong-password-error.component';
import { AutofocusDirective } from './directives/auto-focus-directive.directive';
import { AreaUnitPipe } from './pipes/area-unit.pipe';
import { AsTypePipe } from './pipes/as-type.pipe';
import { IsIncludedInPipe } from './pipes/is-included-in.pipe';
import { IsNumericPipe } from './pipes/is-numeric.pipe';
import { LengthUnitPipe } from './pipes/length-unit.pipe';
import { MemorySizeUnitPipe } from './pipes/memory-size-unit.pipe';
import { ModelAddressPipe } from './pipes/model-address.pipe';
import { ModelStateAbbrPipe } from './pipes/model-state-abbr.pipe';
import { PhotoThumbnailPipe } from './pipes/photo-thumbnail.pipe';
import { SecondsToHoursAndMinutesPipe } from './pipes/seconds-to-hours-and-minutes.pipe';
import { ShortenNamePipe } from './pipes/shorten-name/shorten-name.pipe';

@NgModule({
  declarations: [
    LengthUnitPipe,
    SecondsToHoursAndMinutesPipe,
    MemorySizeUnitPipe,
    AreaUnitPipe,
    SegmentedProgressBarComponent,
    ConfirmationDialogComponent,
    ModelProcessingActionsComponent,
    AutofocusDirective,
    WrongPasswordErrorComponent,
    ImageCropperDialogComponent,
    FilterableListComponent,
    ModelTypeBadgeComponent,
    PhotoZoomLegendComponent,
    AsTypePipe,
    LayerDetailsNameComponent,
    GridComponent,
    IsIncludedInPipe,
    PhotoThumbnailPipe,
    ModelAddressPipe,
    ModelStateAbbrPipe,
    ShortenNamePipe,
    AsyncActionDispatchedInfoComponent,
    ReportProblemDialogComponent,
    InternetConnectionLostInfoComponent,
    IsNumericPipe,
  ],
  imports: [
    CommonModule,
    NebularModule,
    AngularSvgIconModule.forRoot(),
    ReactiveFormsModule,
    NgxPermissionsModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    ImageCropperModule,
  ],
  exports: [
    LengthUnitPipe,
    NgxSpinnerModule,
    SecondsToHoursAndMinutesPipe,
    MemorySizeUnitPipe,
    AreaUnitPipe,
    SegmentedProgressBarComponent,
    NgxPermissionsModule,
    ModelProcessingActionsComponent,
    AutofocusDirective,
    WrongPasswordErrorComponent,
    FilterableListComponent,
    ModelTypeBadgeComponent,
    PhotoZoomLegendComponent,
    AsTypePipe,
    LayerDetailsNameComponent,
    GridComponent,
    IsIncludedInPipe,
    PhotoThumbnailPipe,
    ModelAddressPipe,
    ModelStateAbbrPipe,
    ShortenNamePipe,
    AsyncActionDispatchedInfoComponent,
    InternetConnectionLostInfoComponent,
    IsNumericPipe,
  ],
  providers: [ModelStateAbbrPipe],
})
export class SharedModule {}
