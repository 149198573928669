<app-filter-layout
  [filterForm]="filterForm"
  [loading]="false"
  [contentTpl]="contentTpl"
  [isFilterPopoverActive]="isFilterPopoverActive"
  [textInputValue]="getKeywordsValue()"
  textInputPlaceholder="Type to search processing"
  [showClearButton]="isFormTouched()"
  (clearFilters)="clearFilters()"
  (submitHandler)="onSubmit($event)"
  (queryChange)="queryChange($event)"
  (toggleFilterPopover)="toggleFilterPopover()"
>
</app-filter-layout>

<ng-template #contentTpl>
  <div [formGroup]="filterForm">
    <div class="filter__section">
      <div class="filter__title">Processing step</div>
      <div class="filter__values grid" formArrayName="type">
        <ng-container *ngFor="let type of buildProcessTypeId | keyvalue">
          <nb-checkbox
            *ngIf="typeOf(type) !== 'number' && +type.key !== 3"
            class="filter__checkbox checkbox-grid"
            [formControlName]="type.key"
          >
            {{ buildProcessTypeId[type.key] }}
          </nb-checkbox>
        </ng-container>
      </div>
    </div>
    <div class="filter__section">
      <div class="filter__title">Processing status</div>
      <div class="filter__values grid" formArrayName="status">
        <ng-container *ngFor="let status of buildProcessStatusId | keyvalue">
          <nb-checkbox
            *ngIf="typeOf(status) !== 'number'"
            class="filter__checkbox"
            [formControlName]="status.key"
          >
            {{ buildProcessStatusId[status.key] }}
          </nb-checkbox>
        </ng-container>
      </div>
    </div>

    <div class="filter__section">
      <div class="filter__title">Subscriptions</div>
      <div class="filter__values grid" formArrayName="subscription">
        <ng-container *ngFor="let status of subscriptionStatusId | keyvalue">
          <nb-checkbox
            *ngIf="typeOf(status) !== 'number'"
            class="filter__checkbox"
            [formControlName]="status.key"
          >
            {{ subscriptionStatusId[status.key] }}
          </nb-checkbox>
        </ng-container>
      </div>
    </div>

    <div class="filter__section">
      <div class="filter__title">Start date range</div>
      <div class="filter__values">
        <input
          nbInput
          placeholder="Pick Date Range"
          autocomplete="off"
          [nbDatepicker]="dateStart"
          [status]="checkInputHasNoEndDate('dateStart') ? 'danger' : 'basic'"
          name="dateStart"
          formControlName="dateStart"
        />
        <nb-rangepicker #dateStart></nb-rangepicker>
        <span *ngIf="checkInputHasNoEndDate('dateStart')" class="filter__date_error">
          Set End Date
        </span>
      </div>
    </div>

    <div class="filter__section">
      <div class="filter__title">End date range</div>
      <div class="filter__values">
        <input
          nbInput
          placeholder="Pick Date Range"
          autocomplete="off"
          [nbDatepicker]="dateEnd"
          [status]="checkInputHasNoEndDate('dateEnd') ? 'danger' : 'basic'"
          name="dateEnd"
          formControlName="dateEnd"
        />
        <nb-rangepicker #dateEnd></nb-rangepicker>
        <span *ngIf="checkInputHasNoEndDate('dateEnd')" class="filter__date_error">
          Set End Date
        </span>
      </div>
    </div>
  </div>
</ng-template>
