import { Analytics } from '@App/app/entities/processing/analytics.model';
import { Duration } from '@App/app/entities/shared/duration.model';
import { CostsService } from '@App/app/shared/services/costs/costs.service';
import { getDurationAsNumber } from '@App/app/shared/utils/date.utils';
import { Injectable } from '@angular/core';

@Injectable()
export class AnalyticsDataService {
  constructor(private _costsService: CostsService) {}

  getAllData(analytics: Analytics) {
    const costsData = this._costsService.calculateCosts(analytics);
    const durationData = this._getAnalyticsDates(analytics);
    const modelSize =
      typeof analytics.modelSizeTotal === 'number' ? analytics.modelSizeTotal : null;
    const imageSizeTotal =
      typeof analytics.imageSizeTotal === 'number' ? analytics.imageSizeTotal : null;
    const pixelsTotal = this._calculateTotalPixelsNumber(analytics);
    return {
      costsData,
      durationData,
      modelSize,
      imageSizeTotal,
      pixelsTotal,
    };
  }

  private _calculateTotalPixelsNumber(analytics: Analytics) {
    return typeof analytics.pixelsTotal === 'number'
      ? +(analytics.pixelsTotal / 1_000_000_000).toFixed(2)
      : null;
  }

  private _getAnalyticsDates(analytics: Analytics) {
    const atDuration = analytics.aerotriangulation
      ? getDurationAsNumber(analytics.aerotriangulation as Duration)
      : null;
    const prodDuration = analytics.production
      ? getDurationAsNumber(analytics.production as Duration)
      : null;
    const ppDuration = analytics.postProduction
      ? getDurationAsNumber(analytics.postProduction as Duration)
      : null;
    const totalDuration = this._calculateTotalDuration(atDuration, prodDuration, ppDuration);
    return {
      atDuration,
      prodDuration,
      ppDuration,
      totalDuration,
    };
  }

  private _calculateTotalDuration(
    atDuration: number | null,
    prodDuration: number | null,
    ppDuration: number | null,
  ) {
    return atDuration !== null && prodDuration !== null && ppDuration !== null
      ? atDuration + prodDuration + ppDuration
      : null;
  }
}
