import {
  LAYER_TYPES,
  LAYER_TYPES_DXF_EXPORTABLE,
  MAPPING_LAYER_TYPES,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { Folder, FolderData } from '@App/app/entities/layer/folder.model';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';

export const isLayerDXFExportable = (layer: LayerUI) => {
  return Object.values(LAYER_TYPES_DXF_EXPORTABLE).includes(
    (layer.type as unknown) as LAYER_TYPES_DXF_EXPORTABLE,
  );
};

export const isMappingTool = (layer: LayerUI) => {
  return Object.values(MAPPING_LAYER_TYPES).includes(
    (layer.type as unknown) as MAPPING_LAYER_TYPES,
  );
};

export const getLayersByParentId = (layers: LayerUI[], parentId: number) => {
  return layers.filter((layer) => layer.parentId === parentId);
};

export const splitItemsToFoldersAndLayers = (layers: LayerUI[]) => {
  return {
    folders: layers.filter((layer) => layer.type === LAYER_TYPES.FOLDERS),
    layers: layers.filter((layer) => layer.type !== LAYER_TYPES.FOLDERS),
  };
};

export const getBasePlane = (layers: LayerUI[]) => {
  return layers.find((layer) => layer.type === LAYER_TYPES.BASE_PLANE);
};

export const getRootFolder = (folders: LayerUI[]) => {
  return folders.find((folder) => (folder.data as FolderData).isRoot);
};

export const copyLayers = (layers: LayerUI[]) => {
  return layers.map((layer) => ({
    ...layer,
    data: { ...layer.data },
  }));
};

export const getLayersByIdsFromOtherLayers = (layers: LayerUI[], otherLayers: LayerUI[]) => {
  return layers.filter((layer) => otherLayers.find(({ id }) => id === layer.id));
};

export const getLayerParents = (layer: LayerUI, folders: LayerUI[]) => {
  const root = getRootFolder(folders);
  const parents: Folder[] = [];
  let parentId = layer.parentId;
  while (parentId && parentId !== root?.id) {
    const nextParent = folders.find((folder) => folder.id === parentId);
    if (!nextParent) {
      break;
    }
    parents.push(nextParent as Folder);
    parentId = nextParent.parentId;
  }
  return parents;
};

export const checkCanBeDeleted = (id: number, layers: LayerUI[], folders: LayerUI[]) => {
  const basePlane = getBasePlane(layers);
  if (basePlane) {
    const parents = getLayerParents(basePlane, folders);
    const parentIds = parents.map((parent) => parent.id);
    return !parentIds.includes(id);
  }
  return false;
};

export const getChildFoldersAsFlatList = (id: number, folders: LayerUI[]): LayerUI[] => {
  const children = getLayersByParentId(folders, id);
  return children.concat(...children.map((child) => getChildFoldersAsFlatList(child.id, folders)));
};
