import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { Pipe, PipeTransform } from '@angular/core';
import { LayersListService } from '../../../services/layers-list-service/layers-list.service';
import { IsGuiLayerVisiblePipe } from '../is-gui-layer-visible/is-gui-layer-visible.pipe';

@Pipe({
  name: 'isAnyGuiLayerInFolder',
})
export class IsAnyGuiLayerInFolderPipe implements PipeTransform {
  constructor(
    private _layersListService: LayersListService,
    private _isGuiLayerVisiblePipe: IsGuiLayerVisiblePipe,
  ) {}

  transform(folder: LayerUI | LayerUiExpandable): boolean {
    return this._isAnyGuiLayerInFolder(folder);
  }

  private _isAnyGuiLayerInFolder(folder: LayerUI | LayerUiExpandable): boolean {
    if (
      this._layersListService.layersByFolders[folder.id]?.some((layer) =>
        this._isGuiLayerVisiblePipe.transform(layer),
      )
    ) {
      return true;
    }
    const subFolders = this._layersListService.folders.filter((f) => f.parentId === folder.id);
    return subFolders.some((f) => this._isAnyGuiLayerInFolder(f));
  }
}
