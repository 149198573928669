import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RegionOfInterestHttpService } from '../../services/region-of-interest-http-service/region-of-interest-http.service';
import { loadRoiLimits, loadRoiLimitsSuccess } from '../actions/limits.actions';

@Injectable()
export class LoadLimitsEffects {
  constructor(
    private actions: Actions,
    private regionOfInterestHttpService: RegionOfInterestHttpService,
  ) {}

  loadLimits = createEffect(() => {
    return this.actions.pipe(
      ofType(loadRoiLimits),
      mergeMap(() => {
        return this.regionOfInterestHttpService.getRoiLimits().pipe(
          map((roiLimits) =>
            loadRoiLimitsSuccess({
              limits: roiLimits,
            }),
          ),
          catchError((error) => of(error)),
        );
      }),
    );
  });
}
