import { NebularModule } from '@App/app/modules';
import { SharedModule } from '@App/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SortablejsModule } from '@everymundo/ngx-sortablejs';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LayersListComponent } from './layers-list.component';
import { FolderComponent } from './layers-sortable/folder/folder.component';
import { LayerExpandableComponent } from './layers-sortable/layer-expandable/layer-expandable.component';
import { LayerPathComponent } from './layers-sortable/layer-path/layer-path.component';
import { LayerComponent } from './layers-sortable/layer/layer.component';
import { LayersSortableComponent } from './layers-sortable/layers-sortable.component';
import { HasGuiTogglerPipe } from './layers-sortable/pipes/has-gui-toggler/has-gui-toggler.pipe';
import { IncludesOnlyFoldersPipe } from './layers-sortable/pipes/includes-only-folders/includes-only-folders.pipe';
import { IsAnyGuiLayerInFolderPipe } from './layers-sortable/pipes/is-any-gui-layer-in-folder/is-any-gui-layer-in-folder.pipe';
import { IsExpandablePipe } from './layers-sortable/pipes/is-expandable/is-expandable.pipe';
import { IsFolderVisiblePipe } from './layers-sortable/pipes/is-folder-visible/is-folder-visible.pipe';
import { IsGuiLayerVisiblePipe } from './layers-sortable/pipes/is-gui-layer-visible/is-gui-layer-visible.pipe';
import { IsLayerSelectedPipe } from './layers-sortable/pipes/is-layer-selected/is-layer-selected.pipe';
import { IsLayerVisiblePipe } from './layers-sortable/pipes/is-layer-visible/is-layer-visible.pipe';
import { LayerPathPipe } from './layers-sortable/pipes/layer-path/layer-path.pipe';
import { LayersListActionsService } from './services/layers-list-actions-service/layers-list-actions.service';
import { LayersListService } from './services/layers-list-service/layers-list.service';
import { LayersListUtilsService } from './services/layers-list-utils-service/layers-list-utils.service';

@NgModule({
  imports: [
    CommonModule,
    NebularModule,
    SortablejsModule.forRoot({ animation: 150 }),
    AngularSvgIconModule.forRoot(),
    SharedModule,
  ],
  declarations: [
    LayersListComponent,
    LayersSortableComponent,
    LayerComponent,
    LayerExpandableComponent,
    LayerPathComponent,
    FolderComponent,
    IsFolderVisiblePipe,
    IsLayerVisiblePipe,
    IsGuiLayerVisiblePipe,
    IsLayerSelectedPipe,
    HasGuiTogglerPipe,
    LayerPathPipe,
    IsExpandablePipe,
    IncludesOnlyFoldersPipe,
    IsAnyGuiLayerInFolderPipe,
  ],
  exports: [LayersListComponent],
  providers: [
    LayersListService,
    LayersListActionsService,
    LayersListUtilsService,
    IsGuiLayerVisiblePipe,
    IsFolderVisiblePipe,
  ],
})
export class LayersListModule {}
