import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ModelsProcessingHttpService as ProcessingHTTPService } from '../../../services/models-processing-http-service/models-processing-http.service';
import { ModelsProcessingService as ProcessingService } from '../../../services/models-processing-service/models-processing.service';
import { loadCurrentProcess, loadCurrentProcessSuccess } from '../actions/processes.actions';

@Injectable()
export class LoadCurrentProcessEffects {
  constructor(
    private actions: Actions,
    private modelsProcessingHttpService: ProcessingHTTPService,
    private modelsProcessingService: ProcessingService,
  ) {}

  loadCurrentProcess = createEffect(() => {
    return this.actions.pipe(
      ofType(loadCurrentProcess),
      mergeMap(({ id }) => {
        return this.modelsProcessingHttpService.getBuildProcess(id).pipe(
          map(
            (process: any) => {
              delete process.tiles;
              return loadCurrentProcessSuccess({
                process: new BuildProcess({ ...process }),
              });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  loadCurrentProcessSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(loadCurrentProcessSuccess),
        tap(({ process }) => {
          this.modelsProcessingService.setCurrentBuildProcess(process);
        }),
      );
    },
    { dispatch: false },
  );
}
