/* eslint-disable max-lines */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@App/app/auth/auth.service';

import {
  FAILED,
  MODEL_DELETE_SUCCESS,
  MODEL_DOES_NOT_EXIST,
  SUCCESS,
  WARNING,
} from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { Model } from '@App/app/entities/models/model.model';
import { IErrorIdentification } from '@App/app/entities/shared/error-identificator.model';
import { ConfirmationDialogComponent } from '@App/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { slugify } from '@App/app/shared/utils/utils';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, take } from 'rxjs/operators';
import {
  changeModels,
  deleteModels,
  loadCurrentModel,
  removeAllModelsLocal,
  removeCurrentModelLocal,
  removeModelLocal,
  updateModel,
} from '../../store/models/actions/models.actions';
import { selectCurrentModel } from '../../store/models/selectors/models.selectors';
import { ModelsHttpService } from '../models-http-service/models-http.service';

@Injectable()
export class ModelsService {
  model: Model | null = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastrService: NbToastrService,
    private store: Store,
    private modelsHttpService: ModelsHttpService,
    private dialogService: NbDialogService,
    private spinner: NgxSpinnerService,
  ) {
    this.store
      .select(selectCurrentModel)
      .pipe(filter(Boolean))
      .subscribe((model: Model) => {
        this.model = model;
      });
  }

  loadModel(id: number) {
    const endpoint = this.authService.getCurrentEndpoint();
    const sitesUrl = endpoint ? `${endpoint.id}/models` : '';
    if (!Number.isNaN(id)) {
      this.store.dispatch(loadCurrentModel({ id }));
    } else {
      this.router.navigate([sitesUrl]);
      this.store.dispatch(removeCurrentModelLocal());
      this.toastrService.show(MODEL_DOES_NOT_EXIST, WARNING, WARNING_TOASTR_CONFIG);
    }
  }

  archiveModel(model: Model) {
    this.store.dispatch(updateModel({ model: { ...model, isArchived: !model.isArchived } }));
    const endpoint = this.authService.getCurrentEndpoint();
    const sitesUrl = endpoint ? `${endpoint.id}/models` : '';
    this.router.navigate([sitesUrl]);
  }

  deleteModel(model: Model, force = false) {
    this.modelsHttpService
      .deleteModel(Number(model.id), force)
      .pipe(take(1))
      .subscribe(
        () => {
          this.store.dispatch(removeModelLocal({ id: Number(model.id) }));
          this.toastrService.show(MODEL_DELETE_SUCCESS, SUCCESS, SUCCESS_TOASTR_CONFIG);
          const endpoint = this.authService.getCurrentEndpoint();
          const sitesUrl = endpoint ? `${endpoint.id}/models` : '';
          this.router.navigate([sitesUrl]);
        },
        (error: HttpErrorResponse) => {
          const msg = error.error.message as string;
          if (
            msg ===
            "There are some existing build processes for this model. If you are sure about deleting all of them along with the model, please pass additional query param 'force=true'"
          ) {
            this.dialogService
              .open(ConfirmationDialogComponent, {
                context: {
                  title:
                    'There are some existing processes associated with this model. Do you want to delete it anyway?',
                  status: 'danger',
                },
              })
              .onClose.subscribe((value) => {
                if (value) {
                  this.deleteModel(model, true);
                }
              });
          } else {
            this.toastrService.show(msg, FAILED, WARNING_TOASTR_CONFIG);
          }
        },
      );
  }

  archiveModels(models: Model[]) {
    this.spinner.show();
    this.store.dispatch(changeModels({ models }));
  }

  deleteSingleModelHandler(model: Model) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Are you sure you want to delete this model? All data related to it will be lost.',
          status: 'danger',
          keywordMode: true,
          keyword: `${model.id}/${slugify(model.name)}`,
        },
      })
      .onClose.subscribe((value) => {
        if (value) {
          this.deleteModel(model);
        }
      });
  }

  deleteHandler(models: Model[]) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Delete Models',
          details: `Are you sure you want to delete <b>${models.length}</b> models? All data related to them will be lost.`,
          status: 'danger',
          keywordMode: true,
          keyword: `permanently delete`,
          useInnerHTML: true,
        },
      })
      .onClose.subscribe((value) => {
        if (value) {
          this.deleteModels(models);
        }
      });
  }

  deleteModels(models: Model[]) {
    this.spinner.show();
    this.store.dispatch(deleteModels({ ids: models.map((model) => +model.id) }));
  }

  openDeleteModelsConfirmationDialog(
    deletableModels: IErrorIdentification[],
    indelibleModels: IErrorIdentification[],
    deletedModels: IErrorIdentification[],
  ) {
    const deletedModelsNames = deletedModels.map((item) => item.name).join(', ');
    const deletableModelsString = deletableModels.map((item) => item.name).join(', ');
    const indelibleModelsString = indelibleModels.map((item) => item.name).join(', ');

    let detailMessage = '';
    if (deletedModels.length) {
      detailMessage += `Models<b> ${deletedModelsNames}</b> are already deleted.\n`;
    }
    if (indelibleModels.length) {
      detailMessage += `Models <b>${indelibleModelsString}</b> cannot be deleted.\n`;
    }
    if (deletableModels.length) {
      detailMessage += `Models <b>${deletableModelsString}</b> have some existing build processes.\n`;
    }
    detailMessage += '\nDo you want to delete them anyway?';

    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Delete Models',
          details: detailMessage,
          status: 'danger',
          useInnerHTML: true,
          showConfirmButton: !!deletableModels.length,
        },
      })
      .onClose.pipe(filter(Boolean))
      .subscribe(() => {
        this.spinner.show();
        this.store.dispatch(
          deleteModels({ ids: deletableModels.map((model) => model.id), force: true }),
        );
      });
  }

  removeModelsLocal() {
    this.store.dispatch(removeAllModelsLocal());
  }
}
