import { ModelsTableFilterForm } from '@App/app/entities/forms/models-table-filter-form.model';
import { MODEL_TYPES } from '@App/app/entities/models/model-types.enum';
import { Model } from '@App/app/entities/models/model.model';
import { AdvancedFiltersService } from '@App/app/entities/shared/advanced-filters-service.model';
import { AdvancedSearchModel, DateRange } from '@App/app/entities/shared/advanced-search.model';
import { filterDateRange, updateByQuery } from '@App/app/pages/sites/shared/utils';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TableColumn, TableColumnProp } from '@swimlane/ngx-datatable';
import { BehaviorSubject } from 'rxjs';
import { isNullOrHasBothDates } from '../../shared/validators/models-list.validators';

@Injectable({
  providedIn: 'root',
})
export class ModelsTableFiltersService
  implements AdvancedFiltersService<AdvancedSearchModel, ModelsTableFilterForm> {
  private _advanceSearch = new BehaviorSubject<AdvancedSearchModel>(new AdvancedSearchModel());
  $advancedSearch = this._advanceSearch.asObservable();
  modelColumns: TableColumn[];
  filterForm: ModelsTableFilterForm = this.fb.group({
    query: this.fb.control(''),
    type: this.fb.group<{
      [name: string]: boolean;
    }>({}),
    dateFlown: this.fb.control<DateRange | null>(null, {
      validators: isNullOrHasBothDates(),
      updateOn: 'blur',
    }),
    dateProcessed: this.fb.control<DateRange | null>(null, {
      validators: isNullOrHasBothDates(),
      updateOn: 'blur',
    }),
    pilot: this.fb.control<number | null>(null),
  });

  constructor(private fb: FormBuilder) {}

  advancedSearch(values: AdvancedSearchModel) {
    this._advanceSearch.next(values);
  }

  updateFilters(advancedSearch: AdvancedSearchModel, entities: Model<any>[]) {
    let temp = entities;
    const props = this.modelColumns?.map((column) => column.prop) || [];
    props.push('address.street');
    temp = updateByQuery(temp, advancedSearch.query, props as TableColumnProp[]) as Model[];
    if ('pilot' in advancedSearch) {
      temp = this.filterPilot(temp, advancedSearch.pilot);
    }
    if ('type' in advancedSearch) {
      temp = this.filterModelType(temp, advancedSearch.type);
    }
    temp = filterDateRange(temp, advancedSearch.dateFlown, 'flown') as Model[];
    temp = filterDateRange(temp, advancedSearch.dateProcessed, 'processed') as Model[];
    return temp;
  }

  // eslint-disable-next-line complexity
  filterModelType(models: Model[], types: { [name: string]: boolean | null }) {
    if (
      types &&
      (types[MODEL_TYPES.CELL_TOWER] ||
        types[MODEL_TYPES.ATCT] ||
        types[MODEL_TYPES.POWER_LINE] ||
        types[MODEL_TYPES.BRIDGE] ||
        types[MODEL_TYPES.ROADWAY] ||
        types[MODEL_TYPES.PIPELINE] ||
        types[MODEL_TYPES.BUILDING] ||
        types[MODEL_TYPES.GENERIC])
    ) {
      return models.filter((model) => types[model.modelType]);
    }
    return models;
  }

  filterPilot(models: Model[], pilotId: number | null) {
    if (pilotId) {
      return models.filter((model) => model.pilot?.id === pilotId);
    }
    return models;
  }
}
