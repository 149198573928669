import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { ATLog } from '@App/app/entities/files/files.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProcessingService } from '../../processing.service';
import { ReviewTabComponent } from '../base-tabs/review-tab.component';

@UntilDestroy()
@Component({
  selector: 'app-at-review-tab',
  templateUrl: './at-review-tab.component.html',
  styleUrls: ['./at-review-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AtReviewTabComponent extends ReviewTabComponent implements OnInit {
  protected retryPermission = UserPermissions.CAN_RETRY_AT;
  protected acceptPermission = UserPermissions.CAN_ACCEPT_AT;
  protected continueStep = ProcessingSteps.PROD_SETUP;
  log: ATLog;

  constructor(
    processingService: ProcessingService,
    permissionsService: NgxPermissionsService,
    modelsProcessingService: ModelsProcessingService,
  ) {
    super(processingService, permissionsService, modelsProcessingService);
    this.onRetry = this.onRetry.bind(this);
    this.isFailed = !!this.processingService.buildProcess?.isFailed();
  }

  protected getLogFromProcess(process: BuildProcess) {
    return process.atLog;
  }

  onRetry() {
    this.redirectToStep(ProcessingSteps.AT_SETUP);
  }
}
