import { environment } from '@App/environments/environment';
import { BaseMapType } from '../pages/uploader/models/photos-map.models';

export const PHOTO_MARKER_COLOR = '#ff4f00';
export const GCP_MARKER_COLOR = '#00ddff';
export const BLUE_MODELS_MARKER = '#0089dd';

export const PHOTO_CLUSTER_SYMBOL = {
  markerType: 'ellipse',
  markerFill: {
    property: 'count',
    type: 'interval',
    stops: [[0, PHOTO_MARKER_COLOR]],
  },
  markerFillOpacity: 0.7,
  markerLineWidth: 0,
  markerWidth: {
    property: 'count',
    type: 'interval',
    stops: [
      [0, 40],
      [9, 60],
      [99, 80],
    ],
  },
  markerHeight: {
    property: 'count',
    type: 'interval',
    stops: [
      [0, 40],
      [9, 60],
      [99, 80],
    ],
  },
};

export const GCP_CLUSTER_SYMBOL = {
  ...PHOTO_CLUSTER_SYMBOL,
  markerFill: {
    ...PHOTO_CLUSTER_SYMBOL.markerFill,
    stops: [[0, GCP_MARKER_COLOR]],
  },
};

export const MODELS_MAP_CLUSTER_SYMBOL = {
  ...PHOTO_CLUSTER_SYMBOL,
  markerFill: {
    ...PHOTO_CLUSTER_SYMBOL.markerFill,
    stops: [[0, BLUE_MODELS_MARKER]],
  },
};

export const DEFAULT_SYMBOL = {
  markerType: 'ellipse',
  markerFill: PHOTO_MARKER_COLOR,
  markerFillOpacity: 1,
  markerLineColor: PHOTO_MARKER_COLOR,
  markerLineWidth: 1,
  markerLineOpacity: 1,
  markerLineDasharray: [],
  markerWidth: 5,
  markerHeight: 5,
  markerDx: 0,
  markerDy: 0,
  markerOpacity: 1,
};

export const GCP_SYMBOL = {
  ...DEFAULT_SYMBOL,
  markerWidth: 15,
  markerHeight: 15,
  markerFill: GCP_MARKER_COLOR,
  markerLineColor: GCP_MARKER_COLOR,
};

export const SUPPORTED_BASE_MAPS: BaseMapType[] = [
  {
    name: 'Satellite Map',
    urlTemplate: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${environment.mapboxAccessToken}`,
    subdomains: ['a', 'b', 'c'],
    maxZoom: 20,
  },
  {
    name: 'Light Map',
    urlTemplate: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c', 'd'],
    maxZoom: 24,
  },
  {
    name: 'Dark Map',
    urlTemplate: 'https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c', 'd'],
    maxZoom: 24,
  },
];

export const DEFAULT_POSITION: [number, number] = [-101, 40];
