import { VIEWER_LOD_CONFIG } from '@App/app/configs/viewer.config';
import { abortLoaderRequests } from '@App/app/engine/utils/babylon-utils/babylon.utils';
import { TileInfo, TileLevel } from '@App/app/entities/viewer/tiles-info.model';
import { ArcRotateCamera, Camera, Mesh, Plane, Vector3, VertexBuffer } from 'babylonjs';
import { kdTree } from 'kd-tree-javascript';
import { calculateExtents, reduceVoxel } from 'pointcloud-3d';
import { BehaviorSubject } from 'rxjs';
import { LoaderWithRoot } from './loader-with-root.model';

export class LODTileInfo {
  private _tree: kdTree<Vector3>;
  private _currentDisplayingTile: string;
  private _currentHash = -1;
  private _loadersWithRoots: LoaderWithRoot[] = [];
  private _progressChange$ = new BehaviorSubject<number | null>(null);
  progressChange$ = this._progressChange$.asObservable();

  constructor(private _info: TileInfo, private _baseTile: string, private _levels: TileLevel[]) {
    this._levels.sort((level) => -level.dist);
    this._currentDisplayingTile = this._baseTile;
    this._createPointCloudTree();
  }

  getHQTile() {
    return this._levels[this._levels.length - 1].tile;
  }

  getTileByCamera(camera: ArcRotateCamera) {
    const distance = this._getDistanceToCamera(camera);
    return this._getTileToLoadBaseOnDistance(distance);
  }

  getCurrentDisplayingTile() {
    return this._currentDisplayingTile;
  }

  getMeshes() {
    return [this._info.root, ...this._info.meshes];
  }

  isInFrustum(frustum: Plane[]) {
    return this.getMeshes()
      .slice(1)
      .some((j) => j.isInFrustum(frustum));
  }

  setCurrentDisplayingTile(tile: string) {
    this._currentDisplayingTile = tile;
    this._currentHash = this._getNewHash();
    this._progressChange$.next(0.01);
  }

  setNewMeshes(meshes: Mesh[]) {
    this._info.root = meshes[0];
    this._info.meshes = [...meshes].slice(1);
    this._resetLoading();
  }

  checkShouldSwap(tile: string) {
    return tile !== this._currentDisplayingTile;
  }

  checkHashIsValid(hash: number) {
    return this._currentHash === hash;
  }

  addLoaderWithRoot(loaderWithRoot: LoaderWithRoot) {
    this._loadersWithRoots.push(loaderWithRoot);
  }

  getName() {
    return this._info.tileName;
  }

  getHash() {
    return this._currentHash;
  }

  abort(loaderWithRoot: LoaderWithRoot) {
    const { loader, root } = loaderWithRoot;
    abortLoaderRequests([loader]);
    root.dispose(false, true);
    this.updateProgress(null);
  }

  abortPendingImports() {
    for (const loaderWithRoot of this._loadersWithRoots) {
      this.abort(loaderWithRoot);
    }
    this._resetLoading();
  }

  updateProgress(progress: number | null) {
    const prevValue = this._progressChange$.value;
    if (progress === null || !prevValue || prevValue < progress) {
      this._progressChange$.next(progress);
    }
  }

  private _getDistanceToCamera(camera: Camera): number {
    return this._tree.nearest(camera.position, 1)[0][1];
  }

  private _getTileToLoadBaseOnDistance(d: number): string {
    return this._levels.reduce((acc, lev) => (d <= lev.dist ? lev.tile : acc), this._baseTile);
  }

  private _getHeadMesh() {
    return this._info.meshes[0];
  }

  private _resetLoading() {
    this._loadersWithRoots = [];
    this._currentHash = -1;
    this.updateProgress(null);
  }

  private _getNewHash() {
    return Date.now();
  }

  private _createPointCloudTree() {
    const headMesh = this._getHeadMesh();
    headMesh.computeWorldMatrix(true);
    const vertices = headMesh.getVerticesData(VertexBuffer.PositionKind) as number[];
    const matrix = headMesh.getWorldMatrix();

    const points: [number, number, number][] = [];
    for (let i = 0; i < vertices.length; i += 3) {
      const point = new Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
      Vector3.TransformCoordinatesToRef(point, matrix, point);
      points.push([point.x, point.y, point.z]);
    }

    const reducedPoints = this.reducePoints(points);
    this._tree = new kdTree(reducedPoints, Vector3.Distance, ['x', 'y', 'z']);
  }

  private reducePoints(points: [number, number, number][]): Vector3[] {
    const extent = calculateExtents(points);
    let results: [number, number, number][];

    if (extent.diff.every((val) => val < 100)) {
      //
      // reduceVoxel function doesn't handle large tiles, at least given the current value of VERTICES_SEPARATION.
      // So the reduction is applied only when all of the tile's dimensions are less than 100.
      //
      // This is more like a workaround than final solution.
      // Either pointcloud-3d should be replaced with more popular library,
      // or more robust in-house solution should be implemented.

      results = reduceVoxel(points, VIEWER_LOD_CONFIG.VERTICES_SEPARATION);
    } else {
      results = points;
    }

    return results.map(([x, y, z]) => new Vector3(x, y, z));
  }
}
