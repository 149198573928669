import { AuthEffects } from './auth/store/auth/effects/auth.effects';
import { AddLimitEffects } from './engine/store/effects/add-limit.effects';
import { DeleteLimitEffects } from './engine/store/effects/delete-limit.effect';
import { EditLimitEffects } from './engine/store/effects/edit-limit.effects';
import { LoadLimitsEffects } from './engine/store/effects/load-limits.effects';
import { AddPresetEffects } from './pages/admin-panel/settings/presets-details/store/effects/add-preset.effects';
import { DeletePresetEffects } from './pages/admin-panel/settings/presets-details/store/effects/delete-preset.effects';
import { EditPresetEffects } from './pages/admin-panel/settings/presets-details/store/effects/edit-preset.effects';
import { LoadPresetsEffects } from './pages/admin-panel/settings/presets-details/store/effects/load-presets.effects';
import { ChangeModelEffects } from './pages/models/store/models/effects/change-model.effect';
import { DeleteModelsEffects } from './pages/models/store/models/effects/delete-models.effect';
import { LoadCurrentModelEffects } from './pages/models/store/models/effects/load-current-model.effects';
import { LoadModelThumbnailsEffects } from './pages/models/store/models/effects/load-model-thumbnails.effects';
import { LoadModelsEffects } from './pages/models/store/models/effects/load-models.effects';
import { ModelFilesEffects } from './pages/models/store/models/effects/model-files.effects';
import { LoadCurrentRenderEffects } from './pages/models/store/renders/effects/load-current-render.effects';
import { LoadRendersEffects } from './pages/models/store/renders/effects/load-renders.effects';
import { UpdateRendersEffects } from './pages/models/store/renders/effects/update-renders.effects';
import { DeleteProcessEffects } from './pages/processing/store/processes/effects/delete-process.effects';
import { EmitProcessEventEffects } from './pages/processing/store/processes/effects/emit-process-events.effects';
import { LoadCurrentProcessFilesEffects } from './pages/processing/store/processes/effects/load-current-process-files.effects';
import { LoadCurrentProcessEffects } from './pages/processing/store/processes/effects/load-current-process.effects';
import { LoadProcessesEffects } from './pages/processing/store/processes/effects/load-processes.effects';
import { UpdateProcessEffects } from './pages/processing/store/processes/effects/update-process.effects';
import { UpdateFilesEffects } from './pages/uploader/store/files/effects/update-files.effects';
import { SonyLoginEffects } from './pages/uploader/store/sony-auth/effects/sony-login.effects';
import { SonyMissionsEffects } from './pages/uploader/store/sony-missions/effects/sony-missions.effects';
import { AddUserEffects } from './pages/users/store/effects/add-user.effects';
import { DisableUserEffects } from './pages/users/store/effects/disable-user.effects';
import { EditUserEffects } from './pages/users/store/effects/edit-user.effects';
import { EnableUserEffects } from './pages/users/store/effects/enable-user.effects';
import { LoadUserEffects } from './pages/users/store/effects/load-user.effect';
import { LoadUsersEffects } from './pages/users/store/effects/load-users.effects';
import { UiSettingsEffects } from './pages/viewer/store/color-presets-store/effects/ui-settings.effects';
import { AddLayerEffects } from './pages/viewer/store/effects/add-layer-effects';
import { AddLayersEffects } from './pages/viewer/store/effects/add-layers.effects';
import { AddPointToPolygonEffects } from './pages/viewer/store/effects/add-point-to-polygon.effects';
import { ChangeLayerPropEffects } from './pages/viewer/store/effects/change-layer-prop.effects';
import { ChangeLayersPropEffects } from './pages/viewer/store/effects/change-layers-prop.effects';
import { DeleteLayerEffects } from './pages/viewer/store/effects/delate-layer.effects';
import { DeleteLayersEffects } from './pages/viewer/store/effects/delate-layers.effects';
import { EditLayerEffects } from './pages/viewer/store/effects/edit-layer.effects';
import { EditLayersEffects } from './pages/viewer/store/effects/edit-layers.effects';
import { ImportLayerEffects } from './pages/viewer/store/effects/import-layer-effect';
import { WorkspaceLoadItemsEffects } from './pages/viewer/store/effects/load-layers.effects';
import { RemoveAllLayersLocal } from './pages/viewer/store/effects/remove-layers.effect';
import { VisibilityLayerEffects } from './pages/viewer/store/effects/visibility-layer.effects';
import { LoadPhotosEffects } from './shared/store/photos/effects/load-photos.effects';
import { ZipPhotosEffects } from './shared/store/photos/effects/zip-photos.effects';

export const effects = [
  AddLayerEffects,
  AddLayersEffects,
  AddPointToPolygonEffects,
  ChangeLayerPropEffects,
  ChangeLayersPropEffects,
  DeleteLayerEffects,
  DeleteLayersEffects,
  EditLayerEffects,
  EditLayersEffects,
  ImportLayerEffects,
  RemoveAllLayersLocal,
  VisibilityLayerEffects,
  WorkspaceLoadItemsEffects,
  LoadModelsEffects,
  LoadCurrentModelEffects,
  EmitProcessEventEffects,
  DeleteProcessEffects,
  LoadProcessesEffects,
  LoadCurrentProcessEffects,
  LoadCurrentProcessFilesEffects,
  LoadRendersEffects,
  UpdateRendersEffects,
  LoadCurrentRenderEffects,
  ChangeModelEffects,
  UpdateProcessEffects,
  LoadUsersEffects,
  LoadUserEffects,
  AddUserEffects,
  EditUserEffects,
  DisableUserEffects,
  EnableUserEffects,
  LoadPhotosEffects,
  ZipPhotosEffects,
  AuthEffects,
  UpdateFilesEffects,
  UiSettingsEffects,
  DeleteModelsEffects,
  ModelFilesEffects,
  SonyLoginEffects,
  SonyMissionsEffects,
  LoadModelThumbnailsEffects,
  LoadLimitsEffects,
  AddLimitEffects,
  DeleteLimitEffects,
  EditLimitEffects,
  LoadPresetsEffects,
  AddPresetEffects,
  DeletePresetEffects,
  EditPresetEffects,
];
