import { createAction, props } from '@ngrx/store';
import { FileMetadataInfo } from '../../../models/uploaded-image-metadata.model';

export const setFiles = createAction(
  '[Files] set files',
  props<{ files: File[]; xlifData: FileMetadataInfo[] }>(),
);

export const removeAll = createAction('[Files] remove all');

export const removeFile = createAction('[Files] remove file', props<{ name: string }>());

export const setXlifData = createAction(
  '[Files] set xlif data',
  props<{ xlifData: FileMetadataInfo[] }>(),
);

export const setXlifDataLocally = createAction(
  '[Files] set xlif data locally',
  props<{ xlifData: FileMetadataInfo[] }>(),
);

export const setCsvFiles = createAction('[Files] set csv files', props<{ csvFiles: File[] }>());

export const setFlatFileName = createAction(
  '[Files] set flat file name',
  props<{ name: string | null }>(),
);

export const setControlPointsFileName = createAction(
  '[Files] set control points file name',
  props<{ name: string | null }>(),
);
