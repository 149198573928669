<nb-layout>
  <nb-layout-header fixed>
    <app-header></app-header>
  </nb-layout-header>
  <nb-layout-column>
    <app-internet-connection-status></app-internet-connection-status>
    <router-outlet></router-outlet>
  </nb-layout-column>
  <nb-layout-footer>
    <app-footer></app-footer>
  </nb-layout-footer>
</nb-layout>
