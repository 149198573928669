import { Coordinates2 } from '@App/app/entities/layer/measurements/coordinates';
import { imageBitmapToBase64 } from '@App/app/shared/utils/files.utils';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WorkerEvent } from './models/crop-worker-event.model';

@Pipe({
  name: 'imgRectOverviewSrc',
  pure: false,
})
export class ImgRectOverviewSrcPipe implements PipeTransform, OnDestroy {
  private _imageData: ImageData;
  private _sub = new Subject<{ target: Coordinates2 }>();
  private _padding = 100;
  private _prevTarget: Coordinates2 | null = null;
  private _val: string;
  private _worker: Worker;
  private _canvas: HTMLCanvasElement;
  //@ts-ignore
  private _offscreenCanvas: OffscreenCanvas;

  constructor(private _cdr: ChangeDetectorRef) {
    if (!window.Worker) {
      return;
    }

    this._worker = new Worker(new URL('./workers/cropper.worker', import.meta.url));
    this._canvas = document.createElement('canvas');
    this._canvas.width = 200;
    this._canvas.height = 200;

    this._worker.addEventListener('message', (event: MessageEvent<ImageBitmap>) => {
      const { data } = event;
      this._val = imageBitmapToBase64(data);
      this._cdr.markForCheck();
    });

    this._sub.pipe(debounceTime(500)).subscribe(({ target }) => {
      this._worker.postMessage({ command: 'crop', target } as WorkerEvent);
    });
  }

  transform(imageData: ImageData | null, target: Coordinates2) {
    if (!window.Worker || !imageData) {
      return '';
    }

    if (!this._imageData) {
      this._imageData = imageData;
      this._sendInitCommandToWorker();
    }

    if (target === this._prevTarget) {
      return this._val;
    }

    this._prevTarget = target;
    this._sub.next({ target });
    return this._val;
  }

  ngOnDestroy(): void {
    this._worker.terminate();
  }

  private _sendInitCommandToWorker() {
    this._offscreenCanvas = (this._canvas as any).transferControlToOffscreen();
    //@ts-ignore
    this._worker.postMessage(
      {
        command: 'init',
        cnv: this._offscreenCanvas,
        padding: this._padding,
        imageData: this._imageData,
      } as WorkerEvent,
      [this._offscreenCanvas],
    );
  }
}
