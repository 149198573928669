import { Model } from '@App/app/entities/models/model.model';
import { IErrorIdentification } from '@App/app/entities/shared/error-identificator.model';
import { createAction, props } from '@ngrx/store';

export const loadAllModels = createAction('[Models] Load all models');

export const loadAllModelsWithThumbnails = createAction('[Models] Load all models with thumbnails');

export const loadAllModelsSuccess = createAction(
  '[Models] Load all models success',
  props<{ models: Model[]; totalCount: number }>(),
);

export const loadCurrentModel = createAction(
  '[Models] Load current model',
  props<{ id: number }>(),
);

export const loadCurrentModelSuccess = createAction(
  '[Models] Load current model success',
  props<{ model: Model }>(),
);

export const changeModel = createAction('[Models] Change model', props<{ model: Model }>());

export const changeModels = createAction('[Models] Change models', props<{ models: Model[] }>());

export const removeModelLocal = createAction(
  '[Models] Remove model local',
  props<{ id: number }>(),
);

export const deleteModelsFailed = createAction(
  '[Models] Delete models failed',
  props<{
    deletableModels: IErrorIdentification[];
    indelibleModels: IErrorIdentification[];
    deletedModels: IErrorIdentification[];
  }>(),
);

export const deleteModels = createAction(
  '[Models] Delete models',
  props<{ ids: number[]; force?: boolean }>(),
);

export const deleteModelsSuccess = createAction(
  '[Models] Delete models success',
  props<{ ids: number[] }>(),
);
export const removeCurrentModelLocal = createAction('[Models] Remove current model local');

export const removeAllModelsLocal = createAction('[Models] Remove all models local');

export const updateModel = createAction('[Models] Update model', props<{ model: Model }>());
