import { SKYFISH_DOCS } from '@App/app/configs/app.config';
import { ACCEPTED_DOCUMENTS, SUCCESS } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { acceptDocuments } from '@App/app/pages/viewer/store/color-presets-store/actions/ui-settings.actions';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { selectHasDocsAccepted } from '../store/auth/selectors/auth.selectors';

@Component({
  selector: 'app-documents-acceptance',
  templateUrl: './documents-acceptance.component.html',
  styleUrls: ['./documents-acceptance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsAcceptanceComponent {
  DOCS = SKYFISH_DOCS;

  constructor(
    private store: Store,
    private _router: Router,
    private _toastrService: NbToastrService,
    private _authService: AuthService,
  ) {}

  asIsOrder() {
    return 1;
  }

  onAcceptClick() {
    this.store
      .select(selectHasDocsAccepted)
      .pipe(filter(Boolean), take(1))
      .pipe(switchMapTo(this._authService.currentEndpoint$))
      .pipe(filter(Boolean), take(1))
      .subscribe((endpoint: Endpoint) => {
        this._toastrService.show(ACCEPTED_DOCUMENTS, SUCCESS, SUCCESS_TOASTR_CONFIG);
        this._router.navigate([endpoint?.id]);
      });

    this.store.dispatch(acceptDocuments());
  }
}
