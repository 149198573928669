import { LogFileTypes } from '@App/app/entities/files/files-data.model';
import { BaseFile } from '@App/app/entities/files/files.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { IErrorIdentification } from '@App/app/entities/shared/error-identificator.model';
import { createAction, props } from '@ngrx/store';

export const loadAllProcesses = createAction('[Processes] Load all processes');

export const loadAllProcessesSuccess = createAction(
  '[Processes] Load all processes success',
  props<{ processes: BuildProcess[] }>(),
);

export const loadCurrentProcess = createAction(
  '[Processes] Load current process',
  props<{ id: number }>(),
);

export const loadCurrentProcessSuccess = createAction(
  '[Processes] Load current process success',
  props<{ process: BuildProcess }>(),
);

export const loadCurrentProcessFiles = createAction(
  '[Processes] Load current process files',
  props<{ processId: number }>(),
);

export const loadCurrentProcessFilesSuccess = createAction(
  '[Processes] Load current process files success',
  props<{ files: BaseFile[] }>(),
);

export const archiveProcess = createAction(
  '[Processes] Archive processing',
  props<{ process: BuildProcess; isArchived: boolean }>(),
);

export const archiveProcesses = createAction(
  '[Processes] Archive processes',
  props<{ processes: BuildProcess[] }>(),
);

export const archiveProcessSuccess = createAction(
  '[Processing] Archive processing success',
  props<{ process: BuildProcess }>(),
);

export const changeProcessSubscription = createAction(
  '[Processes] Change processing subscription',
  props<{ process: BuildProcess; subscribe: boolean }>(),
);

export const updateProcess = createAction(
  '[Processing] Update process',
  props<{ process: BuildProcess }>(),
);

export const updateProcessSuccess = createAction(
  '[Processing] Update process success',
  props<{ process: BuildProcess }>(),
);

export const startAerotriangulation = createAction(
  '[Processing] Start Aerotriangulation',
  props<{ process: BuildProcess }>(),
);

export const startProduction = createAction(
  '[Processing] Start Production',
  props<{ process: BuildProcess }>(),
);

export const startPostProcessing = createAction(
  '[Processing] Start Post Processing',
  props<{ process: BuildProcess }>(),
);

export const deleteProcess = createAction(
  '[Processing] Delete processing',
  props<{ processId: number; force: boolean }>(),
);

export const deleteProcesses = createAction(
  '[Processing] Delete processes',
  props<{ processesId: number[]; force?: boolean }>(),
);

export const deleteProcessesSuccess = createAction(
  '[Processing] Delete processes success',
  props<{ processesId: number[] }>(),
);

export const deleteProcessesFailed = createAction(
  '[Processing] Delete processes failed',
  props<{
    deletableProcesses: IErrorIdentification[];
    indelibleProcesses: IErrorIdentification[];
    deletedProcesses: IErrorIdentification[];
  }>(),
);

export const updateProcessSuccessLocal = createAction(
  '[Processing] Update process success local',
  props<{ process: BuildProcess }>(),
);

export const removeProcessLocal = createAction(
  '[Processing] Delete processing local',
  props<{ id: number }>(),
);

export const removeAllProcessesLocal = createAction('[Processes] Remove all processes local');

export const downloadProcessLogs = createAction(
  '[Processing] Download process logs',
  props<{ logType: LogFileTypes }>(),
);

export const downloadProcessLogsSuccess = createAction(
  '[Processing] Download process logs success',
  props<{ logType: LogFileTypes; value: string }>(),
);
