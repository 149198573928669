export enum MODEL_TYPES {
  CELL_TOWER = 'cell-tower',
  ATCT = 'atct',
  POWER_LINE = 'power-line',
  BRIDGE = 'bridge',
  ROADWAY = 'roadway',
  PIPELINE = 'pipeline',
  BUILDING = 'building',
  GENERIC = 'generic',
}
