import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-layer-details-name',
  templateUrl: './layer-details-name.component.html',
  styleUrls: ['./layer-details-name.component.scss'],
})
export class LayerDetailsNameComponent implements OnInit {
  @Input() createdAt: string;
  @Input() isChangeNameActive = false;
  @Input() isEditModeOnInitMappingTool = false;
  @Input() name: string;
  @Input() editForm: FormGroup;
  @Input() type: LAYER_TYPES;
  @Output() changeName = new EventEmitter<string>();

  LAYER_TYPES = LAYER_TYPES;

  coordsIcon = '../../../assets/svg/icons/sf_portal-ui_icon_axis.svg';
  rectangularIcon = '../../../assets/svg/icons/sf_portal-ui_icon_rectangular.svg';
  tubeIcon = '../../../assets/svg/icons/sf_portal-ui_icon_tube.svg';
  angleIronIcon = '../../../assets/svg/icons/sf_portal-ui_icon_angle_iron.svg';
  rectTubeIcon = '../../../assets/svg/icons/sf_portal-ui_icon_layers_rect_tube.svg';

  constructor() {}

  ngOnInit(): void {}

  onChangeNameClick(): void {
    this.editForm.controls.name.setValue(this.name);
    this.isChangeNameActive = true;
  }

  onChangeNameCancel(): void {
    this.isChangeNameActive = false;
  }

  onChangeNameConfirm(): void {
    if (this.editForm.controls.name.valid) {
      this.changeName.emit(this.editForm.controls.name.value as string);
      this.isChangeNameActive = false;
    }
  }

  isEmpty() {
    const { name } = this.editForm.controls;
    return name.invalid && (name.dirty || name.touched);
  }
}
