import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SonyMission } from '../../../models/sony/sony-mission.model';
import * as actions from '../actions/sony-missions.actions';

export const sonyMissionsAdapter = createEntityAdapter<SonyMission>();
export type State = EntityState<SonyMission>;

const defaultMissions: State = {
  ids: [],
  entities: {},
};
export const initialState: State = sonyMissionsAdapter.getInitialState(defaultMissions);

export const sonyMissionsReducer = createReducer(
  initialState,
  on(actions.loadSonyMissionsSuccess, (state, { missions }) => {
    return sonyMissionsAdapter.setAll(missions, state);
  }),
  on(actions.removeSonyMissionsLocally, (state) => {
    return sonyMissionsAdapter.removeAll(state);
  }),
);
