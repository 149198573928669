import { Injectable } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { BehaviorSubject } from 'rxjs';
import { RESPONSIVE_CONFIG } from 'src/app/configs/app.config';

@Injectable()
export class LayersBarService {
  private minWidth = RESPONSIVE_CONFIG.MIN_SIDEBAR_WIDTH;
  private _visible$ = new BehaviorSubject(true);
  visible$ = this._visible$.asObservable();
  private _width$ = new BehaviorSubject(RESPONSIVE_CONFIG.MIN_SIDEBAR_WIDTH);
  width$ = this._width$.asObservable();

  validate = (event: ResizeEvent) => {
    return event.rectangle.width ? event.rectangle.width >= this.minWidth : false;
  };

  onResize(event: ResizeEvent) {
    if (this._visible$.value && event.rectangle.width) {
      this._width$.next(event.rectangle.width);
    }
  }

  toggle() {
    this._visible$.next(!this._visible$.value);
    if (this._visible$.value) {
      this._width$.next(this.minWidth);
    }
  }
}
