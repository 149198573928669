import { IPreset } from '../entities/viewer/custom-preset.model';

export const VIEWER_LOD_CONFIG = {
  CACHE_LIMIT: 800, // in MB
  HQ_DISTANCE_THRESHOLD: 15,
  NEXT_LEVELS_THRESHOLD: 15,
  VERTICES_SEPARATION: 0.5,
};

export const VIEWER_CONTROL = {
  onTargetChange: 'Space',
};

export const DEFAULT_PRESETS: IPreset[] = [
  {
    id: 1,
    name: 'Preset 1',
    backgroundColor: '#761067',
    viewFinderColor: '#f6fd07',
    default: true,
  },
  {
    id: 2,
    name: 'Preset 2',
    backgroundColor: '#161826',
    viewFinderColor: '#f84600',
    default: true,
  },
  {
    id: 3,
    name: 'Preset 3',
    backgroundColor: '#3b3d4a',
    viewFinderColor: '#eaecf4',
    default: true,
  },
  {
    id: 4,
    name: 'Preset 4',
    backgroundColor: '#aaa8ab',
    viewFinderColor: '#000000',
    default: true,
  },
  {
    id: 5,
    name: 'Default',
    backgroundColor: '#aaa8ab',
    viewFinderColor: '#ffffff',
    default: true,
  },
];

export const SPINNER_COLOR = 'rgba(237, 241, 247, 0.8)';
