import { LAYER_EVENTS, LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { CreateAngleIronService } from '../angle-iron-services/create-angle-iron.service';
import { AnnotationService } from '../annotation-services/annotation.service';
import { CreateCoordinatesLayerService } from '../coordinates-layer-services/create-coordinates-layer.service';
import { CreateDropLineService } from '../drop-line-services/create-drop-line.service';
import { DraftMeasurementService } from '../measurement-services/draft-measurement.service';
import { CreateNewPolygonService } from '../polygon-services/create-new-polygon.service';
import { CuboidPickerService } from '../rectangle-tools-services/cuboid-services/cuboid-picker.service';
import { CuboidService } from '../rectangle-tools-services/cuboid-services/cuboid.service';
import { RectTubeService } from '../rectangle-tools-services/rect-tube-services/rect-tube.service';
import { SketchCircleService } from '../sketches-services/sketch-circle/sketch-circle.service';
import { SketchLineService } from '../sketches-services/sketch-line/sketch-line.service';
import { SketchPlaneService } from '../sketches-services/sketch-plane.service';
import { SketchRectangleService } from '../sketches-services/sketch-rectangle/sketch-rectangle.service';
import { CreateTubePickerService } from '../tube-services/create-tube-picker.service';
import { CreateTubeService } from '../tube-services/create-tube.service';

@Injectable({
  providedIn: 'root',
})
export class LayerEventsService {
  activeLayerEvent = new BehaviorSubject<LAYER_EVENTS>(LAYER_EVENTS.NULL);

  constructor(
    private createAngleIronService: CreateAngleIronService,
    private createCoordinatesLayerService: CreateCoordinatesLayerService,
    private createTubePickerService: CreateTubePickerService,
    private createDropLineService: CreateDropLineService,
    private createNewPolygonService: CreateNewPolygonService,
    private createTubeService: CreateTubeService,
    private cuboidService: CuboidService,
    private draftMeasurementService: DraftMeasurementService,
    private rectTubeService: RectTubeService,
    private sketchCircleService: SketchCircleService,
    private sketchLineService: SketchLineService,
    private sketchPlaneService: SketchPlaneService,
    private sketchRectangleService: SketchRectangleService,
    private cuboidPickerService: CuboidPickerService,
    private annotationService: AnnotationService,
  ) {
    this.activeLayerEvent.pipe(pairwise()).subscribe(([prevEvent, _]) => {
      this.clearEvent(prevEvent);
    });
  }

  private clearEvent(event: LAYER_EVENTS) {
    const layerEvents = {
      [LAYER_EVENTS.ADD_ANGLE_IRON]: () => this.createAngleIronService.clear(),
      [LAYER_EVENTS.ADJUST_BOUNDING_BOX]: () => this.cuboidPickerService.removeCuboidPicker(),
      [LAYER_EVENTS.ADD_RECT_TUBE]: () => this.rectTubeService.clearSamples(),
      [LAYER_EVENTS.ADD_CUBOID]: () => this.cuboidService.clearSamples(),
      [LAYER_EVENTS.ADD_MEASUREMENT]: () => this.draftMeasurementService.removeDraftMeasurement(),
      [LAYER_EVENTS.ADD_POLYGON]: () => this.createNewPolygonService.finishTakingPolygon(),
      [LAYER_EVENTS.ADD_SKETCH_CIRCLE]: () =>
        this.sketchCircleService.removeUnfinishedDraftSketch(),
      [LAYER_EVENTS.ADD_SKETCH_LINE]: () => this.sketchLineService.removeUnfinishedDraftSketch(),
      [LAYER_EVENTS.ADD_TUBE]: () => this.createTubeService.clear(),
      [LAYER_EVENTS.ADD_TUBE_PICKER]: () => this.createTubePickerService.clear(),
      [LAYER_EVENTS.EDIT_POLYGON]: () => this.createNewPolygonService.finishTakingPolygon(true),
      [LAYER_EVENTS.ADD_SKETCH_RECTANGLE]: () =>
        this.sketchRectangleService.removeUnfinishedDraftSketch(),
    };
    if (layerEvents.hasOwnProperty(event)) {
      layerEvents[
        event as Exclude<
          LAYER_EVENTS,
          | LAYER_EVENTS.ADD_BASE_PLANE
          | LAYER_EVENTS.ADD_BOUNDING_BOX
          | LAYER_EVENTS.ADD_COORDINATES_LAYER
          | LAYER_EVENTS.ADD_DROP_LINE
          | LAYER_EVENTS.ADD_SKETCH_PLANE
          | LAYER_EVENTS.ADD_CUBOID_PICKER
          | LAYER_EVENTS.FIND_CUBOID_NORMAL
          | LAYER_EVENTS.FIND_RECT_TUBE_NORMAL
          | LAYER_EVENTS.ADD_ANNOTATION
          | LAYER_EVENTS.ADD_SLICE_PLANE
          | LAYER_EVENTS.NULL
        >
      ]();
    }
  }

  onSingleTapLayerEvents() {
    const leyerEvents = {
      [LAYER_EVENTS.ADD_ANGLE_IRON]: () => this.createAngleIronService.onClickAction(),
      [LAYER_EVENTS.ADD_BOUNDING_BOX]: () => {
        this.cuboidPickerService.onClickActionCuboidPickerService();
        this.activeLayerEvent.next(LAYER_EVENTS.ADJUST_BOUNDING_BOX);
      },
      [LAYER_EVENTS.ADD_COORDINATES_LAYER]: () =>
        this.createCoordinatesLayerService.onClickAction(),
      [LAYER_EVENTS.ADD_DROP_LINE]: () => this.createDropLineService.onClickAction(),
      [LAYER_EVENTS.FIND_RECT_TUBE_NORMAL]: () =>
        this.rectTubeService.findAzimuthAndVerticalTilt(LAYER_TYPES.RECT_TUBE),
      [LAYER_EVENTS.ADD_MEASUREMENT]: () => this.draftMeasurementService.onClickAction(),
      [LAYER_EVENTS.ADD_POLYGON]: () => this.createNewPolygonService.onClickActionPolygonService(),
      [LAYER_EVENTS.ADD_RECT_TUBE]: () => this.rectTubeService.onClickAction(),
      [LAYER_EVENTS.ADD_CUBOID]: () => this.cuboidService.onClickAction(),
      [LAYER_EVENTS.ADD_SKETCH_RECTANGLE]: () => this.sketchRectangleService.onClickAction(),
      [LAYER_EVENTS.ADD_SKETCH_CIRCLE]: () => this.sketchCircleService.onClickAction(),
      [LAYER_EVENTS.ADD_SKETCH_LINE]: () => this.sketchLineService.onClickAction(),
      [LAYER_EVENTS.ADD_SKETCH_PLANE]: () => this.sketchPlaneService.onClickAction(),
      [LAYER_EVENTS.ADD_TUBE]: () => this.createTubeService.onClickAction(),
      [LAYER_EVENTS.ADD_TUBE_PICKER]: () => this.createTubePickerService.onClickAction(),
      [LAYER_EVENTS.EDIT_POLYGON]: () => this.createNewPolygonService.onClickActionPolygonService(),
      [LAYER_EVENTS.FIND_CUBOID_NORMAL]: () =>
        this.cuboidService.findAzimuthAndVerticalTilt(LAYER_TYPES.CUBOID),
      [LAYER_EVENTS.ADD_ANNOTATION]: () => this.annotationService.onClickActionAnnotationService(),
    };

    if (leyerEvents.hasOwnProperty(this.activeLayerEvent.value)) {
      leyerEvents[
        this.activeLayerEvent.value as Exclude<
          LAYER_EVENTS,
          | LAYER_EVENTS.ADD_BASE_PLANE
          | LAYER_EVENTS.ADD_CUBOID_PICKER
          | LAYER_EVENTS.ADJUST_BOUNDING_BOX
          | LAYER_EVENTS.ADD_ANNOTATION
          | LAYER_EVENTS.ADD_SLICE_PLANE
          | LAYER_EVENTS.NULL
        >
      ]();
    }
  }

  onMouseMoveLayerEvents() {
    const leyerEvents = {
      [LAYER_EVENTS.ADD_MEASUREMENT]: () => this.draftMeasurementService.onMoveAction(),
    };

    if (leyerEvents.hasOwnProperty(this.activeLayerEvent.value)) {
      leyerEvents[LAYER_EVENTS.ADD_MEASUREMENT]();
    }
  }
}
