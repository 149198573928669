import { Address, Model } from '@App/app/entities/models/model.model';

const createNameElement = (name: string) => {
  const divNameElement = document.createElement('div');
  divNameElement.textContent = name;
  divNameElement.classList.add('name-context-style');
  return divNameElement;
};

const createStateElement = (state: string | null) => {
  const divStateElement = document.createElement('div');
  divStateElement.classList.add('address-item');
  divStateElement.textContent = state ? `State: ${state}` : null;
  return divStateElement;
};

const createCityElement = (city: string) => {
  const divCityElement = document.createElement('div');
  divCityElement.classList.add('address-item');
  divCityElement.textContent = city ? `City: ${city}` : null;
  return divCityElement;
};

const createButtonElement = (onClick: () => void) => {
  const buttonElement = document.createElement('button');
  buttonElement.classList.add('button-style');
  buttonElement.textContent = 'Model details';
  buttonElement.addEventListener('click', onClick);
  return buttonElement;
};

const createAddressElement = (address: Address | undefined) => {
  const { state, city } = address as Address;
  const divAddressElement = document.createElement('div');
  divAddressElement.classList.add('address-context-style');
  divAddressElement.appendChild(createStateElement(state));
  divAddressElement.appendChild(createCityElement(city));
  return divAddressElement;
};

const createThumbnailElement = (thumbnail: string) => {
  const imgElement = document.createElement('img');
  imgElement.classList.add('img-context-style');
  imgElement.src = thumbnail;
  imgElement.alt = 'model thumbnail';
  imgElement.width = 200;
  imgElement.height = 200;
  return imgElement;
};

export const createModelContext = (
  model: Model | null,
  onLinkClick: () => void,
  thumbnail: string,
) => {
  const divContextElement = document.createElement('div');
  divContextElement.classList.add('div-context-style-row');
  divContextElement.appendChild(createNameElement(model?.name || ''));
  divContextElement.appendChild(createThumbnailElement(thumbnail));
  divContextElement.appendChild(createAddressElement(model?.address));
  divContextElement.appendChild(createButtonElement(onLinkClick));
  return divContextElement;
};
