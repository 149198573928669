import { AuthService } from '@App/app/auth/auth.service';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import {
  ChangeDetectionStrategy,
  Component,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { loadAllProcesses } from '../../processing/store/processes/actions/processes.actions';
import { BaseSiteComponent } from '../shared/base-site/base-site.component';
import { ProcessesSitesService } from '../shared/site-services/processes-sites.service';
import { ProcessingTableComponent } from './processing-table/processing-table.component';

@Component({
  selector: 'app-processing-site',
  templateUrl: './processing-site.component.html',
  styleUrls: ['../shared/base-site/base-site.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingSiteComponent extends BaseSiteComponent<
  BuildProcess,
  ProcessesSitesService
> {
  @ViewChild(ProcessingTableComponent) private _processingTable: ProcessingTableComponent;

  constructor(
    authService: AuthService,
    renderer: Renderer2,
    permissionsService: NgxPermissionsService,
    store: Store,
    sitesService: ProcessesSitesService,
  ) {
    super(authService, renderer, permissionsService, store, sitesService);
  }

  onRefresh(): void {
    this._processingTable.loading = true;
    this.store.dispatch(loadAllProcesses());
  }
}
