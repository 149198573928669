<form class="search__form" [formGroup]="filterForm" (ngSubmit)="onSubmit(filterForm)">
  <div class="search__wrapper">
    <app-filter-input
      (toggleFilterPopover)="onToggleFilterPopover()"
      (queryChange)="onQueryChange($event)"
      (resetFilters)="onClearFilters()"
      [value]="textInputValue"
      [placeholder]="textInputPlaceholder"
      [showClearButton]="showClearButton"
    ></app-filter-input>

    <div class="sites-top__popover filter" *ngIf="isFilterPopoverActive">
      <div class="filter__header">Advanced Search</div>
      <div class="filter__body" [nbSpinner]="loading">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </div>
      <app-filter-footer
        (clearFilters)="onClearFilters()"
        (toggleFilterPopover)="onToggleFilterPopover()"
      ></app-filter-footer>
    </div>
  </div>
</form>
