import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ProcessesSitesService } from '@App/app/pages/sites/shared/site-services/processes-sites.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ModelsProcessingHttpService } from '../../../services/models-processing-http-service/models-processing-http.service';
import { ModelsProcessingService } from '../../../services/models-processing-service/models-processing.service';
import { loadAllProcesses, loadAllProcessesSuccess } from '../actions/processes.actions';

@Injectable()
export class LoadProcessesEffects {
  constructor(
    private actions: Actions,
    private modelsProcessingHttpService: ModelsProcessingHttpService,
    private modelsProcessingService: ModelsProcessingService,
    private sitesService: ProcessesSitesService,
  ) {}

  loadAllProcesses = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAllProcesses),
      mergeMap(() => {
        return this.modelsProcessingHttpService.getBuildProcesses().pipe(
          map(
            (processes: BuildProcess[]) => {
              this.sitesService.loading.next(false);
              return loadAllProcessesSuccess({
                processes: processes.map((data) => new BuildProcess({ ...data })),
              });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  loadAllProcessesSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(loadAllProcessesSuccess),
        tap(({ processes }) => {
          this.modelsProcessingService.setCurrentBuildProcesses(processes);
        }),
      );
    },
    { dispatch: false },
  );
}
