export enum LAYER_NAMES {
  NULL = 'Null',
  ANGLE_IRON = 'Angle Iron',
  AREA_POLYGON = 'Area Polygon',
  BASE_PLANE = 'Base Plane',
  BOUNDING_BOX = 'Bounding box',
  COORDINATES_LAYER = 'Coordinates layer',
  CUBOID = 'Cuboid',
  CUBOID_PICKER = 'Cuboid Picker',
  DROP_LINE = 'Drop Line',
  MEASUREMENT = 'Measurement',
  RECT_TUBE = 'Rectangle Tube',
  SKETCH_CIRCLE = 'Sketch Circle',
  SKETCH_LINE = 'Sketch Line',
  SKETCH_PLANE = 'Plane',
  SKETCH_RECTANGLE = 'Sketch Rectangle',
  SLICE_PLANE = 'Slice Plane',
  TUBE = 'Tube',
  TUBE_PICKER = 'Tube Picker',
  ANNOTATION = 'Annotation',
}
