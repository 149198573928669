import { BaseFile, FileHttp } from '@App/app/entities/files/files.model';
import { createAction, props } from '@ngrx/store';

export const loadCurrentModelFiles = createAction('[Model Files] Load current model files');

export const loadModelFilesSuccess = createAction(
  '[Model Files] Load model files success',
  props<{ objects: BaseFile[] }>(),
);

export const removeCurrentModelFilesLocal = createAction(
  '[Model Files] Remove current model files local',
);

export const createModelFiles = createAction(
  '[Model Files] Create model files',
  props<{ objects: FileHttp[]; files: File[] }>(),
);

export const uploadModelFiles = createAction(
  '[Model Files] Upload model files',
  props<{ objects: BaseFile[]; files: File[] }>(),
);

export const createModelFilesSuccess = createAction(
  '[Model Files] Create model files success',
  props<{ objects: BaseFile[] }>(),
);

export const createModelFilesFail = createAction(
  '[Model Files] Create model files fail',
  props<{ objects: FileHttp[] }>(),
);

export const deleteModelFiles = createAction(
  '[Model Files] Delete model files',
  props<{ ids: number[] }>(),
);

export const deleteModelFilesSuccess = createAction(
  '[Model Files] Delete model files success',
  props<{ ids: number[] }>(),
);

export const deleteModelFilesFail = createAction(
  '[Model Files] Delete model files fail',
  props<{ ids: number[] }>(),
);
