import { LoadModelService } from '@App/app/engine/services/load-model-service/load-model.service';
import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { ModelType } from '@App/app/entities/processing/build-process-status.model';
import { Injectable } from '@angular/core';
import {
  ArcRotateCamera,
  AssetContainer,
  ISceneLoaderProgressEvent,
  Mesh,
  SceneLoader,
} from 'babylonjs';
import { GLTFFileLoader } from 'babylonjs-loaders';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { map, throttleTime, withLatestFrom } from 'rxjs/operators';
import { LoaderWithRoot } from './models/loader-with-root.model';
import { LODTileInfo } from './models/lod-tile-info.model';
import { ProgressInfo } from './models/progress.model';
import { Tile } from './models/tile.model';
import { CustomLodCacheService } from './services/custom-lod-cache-service/custom-lod-cache.service';
import * as utils from './utils/lod.utils';

@Injectable()
export class CustomLODService {
  private _registered$ = new BehaviorSubject(false);
  registered$ = this._registered$.asObservable();
  private _freezed = false;
  private _lodTileInfos: LODTileInfo[];
  private _assetContainer: AssetContainer | null = null;
  private _onReadyCallback: (() => void) | null;
  private _progressesChange$ = new BehaviorSubject<ProgressInfo[]>([]);
  private _subscriptions: Subscription[] = [];
  totalCacheSize$ = this._cacheService.totalCacheSize$;
  totalProgressChange$: Observable<number | null>;

  constructor(
    private _loadModelService: LoadModelService,
    private _sceneService: SceneService,
    private _cacheService: CustomLodCacheService,
  ) {
    this.totalProgressChange$ = this._progressesChange$.pipe(
      map((progresses: ProgressInfo[]) =>
        !progresses.length
          ? null
          : (progresses.reduce((total, p) => total + p.value, 0) / progresses.length) * 100,
      ),
    );
  }

  isRegistered() {
    return this._registered$.value;
  }

  setFreezed(value: boolean) {
    this._freezed = value;
    this._progressesChange$.next([]);
  }

  getFreezed() {
    return this._freezed;
  }

  register(tileLevels: string[][], camera: ArcRotateCamera, assetContainer?: AssetContainer) {
    this._sceneService.scene.autoClear = true;
    this._registered$.next(true);
    this._assetContainer = assetContainer || null;
    const baseTiles = tileLevels.pop() || [];
    const levels = utils.tilesArraysToLODLevels(tileLevels);
    // inits LODTileInfo objects for handling tile's operations
    this._lodTileInfos = this._loadModelService.loadedTiles.map((tileInfo) => {
      const data = utils.getBaseTileAndLevelsForInfo(tileInfo, baseTiles, levels);
      return new LODTileInfo(tileInfo, data.baseTile, data.levels);
    });
    // pushes all default tiles to cache
    this._cacheService.initializeFromLODTileInfos(this._lodTileInfos, this._assetContainer);
    // calculates all tiles total progress
    this._subscriptions.push(
      combineLatest(this._lodTileInfos.map((info) => info.progressChange$))
        .pipe(
          withLatestFrom(this._progressesChange$),
          map(([newProgresses, prevProgressInfos]) =>
            newProgresses.every((p) => p === null)
              ? []
              : utils.populateNewProgressInfos(prevProgressInfos, newProgresses),
          ),
        )
        .subscribe((progresses) => {
          this._progressesChange$.next(progresses);
        }),
    );
    // catches Babylon's loaders to handle aborts if needed
    SceneLoader.OnPluginActivatedObservable.add((loader: GLTFFileLoader) => {
      loader.onLoaderStateChangedObservable.addOnce(() => this._onLoaderDetected(loader));
    });
    // checks camera position to handle tiles swapping
    const checkCameraPosition$ = new Subject<void>();
    camera.onViewMatrixChangedObservable.add(() => checkCameraPosition$.next());
    this._subscriptions.push(
      checkCameraPosition$.pipe(throttleTime(500)).subscribe(() => {
        this._onCameraPositionChanged(camera);
      }),
    );
    // callback to sender that LOD is ready
    this._onReadyCallback?.();
  }

  unregister(camera: ArcRotateCamera | null) {
    camera?.onViewMatrixChangedObservable.clear();
    SceneLoader.OnPluginActivatedObservable.clear();
    for (const loader of this._loadModelService.loaders) {
      loader.onLoaderStateChangedObservable.clear();
    }
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
    this._onReadyCallback = null;
    this._lodTileInfos = [];
    this._progressesChange$.next([]);
    this._cacheService.clear();
    this._assetContainer = null;
    this._registered$.next(false);
  }

  registerOnStartCallback(callback: () => void) {
    this._onReadyCallback = callback;
  }

  toggleOnlyHQModel(value: boolean, camera: ArcRotateCamera) {
    for (const info of this._lodTileInfos) {
      const tile = value ? info.getHQTile() : info.getTileByCamera(camera);
      this._swap(info, tile);
    }
    this.setFreezed(value);
  }

  private _onLoaderDetected(loader: GLTFFileLoader) {
    const fileName: string = (loader as any)._loader._fileName;
    const root: Mesh = (loader as any)._loader._rootBabylonMesh;
    const { tile, hash } = utils.getTileAndHashFromFileName(fileName);
    const info = this._lodTileInfos.find((i) => i.getName() === tile);
    if (info) {
      const loaderWithRoot: LoaderWithRoot = { loader, root, hash };
      if (!info.checkHashIsValid(hash)) {
        return setTimeout(() => info.abort(loaderWithRoot));
      }
      info.addLoaderWithRoot(loaderWithRoot);
    }
  }

  private _onCameraPositionChanged(camera: ArcRotateCamera) {
    if (this._freezed) return;
    const frustum = utils.getCameraFrustum(camera);

    for (const info of this._lodTileInfos) {
      if (!info.isInFrustum(frustum)) {
        continue;
      }

      const tileToLoad = info.getTileByCamera(camera);
      this._swap(info, tileToLoad);
    }
  }

  private _swap(info: LODTileInfo, tileUrl: string) {
    if (!info.checkShouldSwap(tileUrl)) {
      return;
    }
    info.abortPendingImports();
    info.setCurrentDisplayingTile(tileUrl);
    this._loadTile(info, tileUrl).then(({ meshes }) => {
      utils.clearPickedMeshFromScene(this._sceneService.scene);
      info.getMeshes().forEach((mesh) => this._cacheService.cacheMesh(mesh));
      meshes.forEach((mesh) => this._cacheService.uncacheMesh(mesh));
      info.setNewMeshes(meshes);
      this._cacheService.recalculate(this._assetContainer);
    });
  }

  private async _loadTile(info: LODTileInfo, tileUrl: string): Promise<Tile> {
    const cachedTile = this._cacheService.findByUrl(tileUrl);
    if (cachedTile) return cachedTile;

    let sizeInMB = 0;
    let firstLoadingSize: number | null = null;
    const url = `${tileUrl}?hash=${info.getHash()}&tile=${info.getName()}`;
    const onProgress = (e: ISceneLoaderProgressEvent) => {
      if (!firstLoadingSize) {
        firstLoadingSize = e.total;
      } else if (firstLoadingSize !== e.total) {
        const progress = +(e.loaded / e.total).toFixed(2);
        info.updateProgress(progress);
        sizeInMB = Math.max(sizeInMB, e.total / (1024 * 1024));
      }
    };
    return this._loadModelService
      .loadAssetAsync(url, ModelType.Prod, this._assetContainer, true, onProgress)
      .then((result) => {
        const meshes = result.meshes as Mesh[];
        const tile: Tile = { url: tileUrl, meshes, sizeInMB };
        this._cacheService.addTile(tile);
        return tile;
      });
  }
}
