<div class="layer_details__label">Description:</div>
<div class="layer_details__value">
  <div *ngIf="layer | isLayerBeingEdited; else overview" [formGroup]="editForm">
    <textarea
      formControlName="description"
      data-testId="layer-details-description-textarea"
      placeholder="Type description"
      autofocus
    ></textarea>
  </div>

  <ng-template #overview>
    <span>{{ layer.description || '-' }}</span>
  </ng-template>
</div>
