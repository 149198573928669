import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Pipe, PipeTransform } from '@angular/core';
import { LayersService } from '../../../services/layers/layers.service';

@Pipe({
  name: 'isLayerBeingEdited',
  pure: false,
})
export class IsLayerBeingEditedPipe implements PipeTransform {
  constructor(private _layersService: LayersService) {}

  transform(layer: LayerUI): boolean {
    return (
      this._layersService.editedLayers.map((l) => l.id).includes(layer.id) ||
      this._layersService.isEditModeOnInitMappingTool
    );
  }
}
