import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { BuildProcessStatusId } from '@App/app/entities/processing/build-process-status.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import {
  ATProgressSegment,
  PPProgressSegment,
  ProdProgressSegment,
  ProgressSegment,
  UploadProgressSegment,
} from '@App/app/entities/processing/progress-segment.model';
import moment from 'moment';

@Component({
  selector: 'app-segmented-progress-bar',
  templateUrl: './segmented-progress-bar.component.html',
  styleUrls: ['./segmented-progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SegmentedProgressBarComponent implements OnInit, OnChanges {
  @Input() process: BuildProcess;
  elapsedTime: string;
  segments: ProgressSegment[];
  started: boolean;

  ngOnInit() {
    this.updateData();
  }

  ngOnChanges() {
    this.updateData();
  }

  getProcessInformation() {
    const { failMessage, currentProcessingActivity } = this.process;
    const noInfoMsg = 'No information about this process';
    const message = this.process.isFailed()
      ? failMessage
      : currentProcessingActivity !== '1'
      ? currentProcessingActivity
      : noInfoMsg;

    return message || noInfoMsg;
  }

  getElapsedTime() {
    return this.elapsedTime ? `(${this.elapsedTime})` : '';
  }

  getBorderRadius(segment: ProgressSegment) {
    return !segment.isStarting() ? 0 : null;
  }

  showValue(segment: ProgressSegment) {
    return !this.hasProgressZeroYet(segment);
  }

  getValue(segment: ProgressSegment) {
    const showOnlyName = segment.isFinished() || segment.hasFullProgress();
    const failedStatus = BuildProcessStatusId[3].toUpperCase();
    const progress = this.roundProgress(segment.progress) + '%';
    return segment.isFailed() ? failedStatus : showOnlyName ? segment.name : progress;
  }

  isInitializing(segment: ProgressSegment, index: number) {
    return (
      this.checkPreviousSegmentIsFinished(index) &&
      this.hasProgressZeroYet(segment) &&
      !(segment instanceof ATProgressSegment && this.isATWaiting())
    );
  }

  isLastSegment(segment: ProgressSegment) {
    return segment instanceof PPProgressSegment;
  }

  isStarting(segment: ProgressSegment, index: number) {
    return this.checkPreviousSegmentIsFinished(index) && this.hasProgressZeroYet(segment);
  }

  isPaused(segment: ProgressSegment) {
    return segment.isPaused();
  }

  isInterrupted(segment: ProgressSegment) {
    return segment.isInterrupted() && !this.isInitializing;
  }

  isSuccess(segment: ProgressSegment) {
    return segment.isSuccess();
  }

  isFailed(segment: ProgressSegment) {
    return segment.isFailed();
  }

  isPointSuccess(segment: ProgressSegment) {
    return segment.isFinished() && !this.isATWaiting();
  }

  isPointWarning(segment: ProgressSegment) {
    return segment.isCanceled() || (segment instanceof UploadProgressSegment && this.isATWaiting());
  }

  isCanceled(segment: ProgressSegment) {
    return segment.isCanceled() && segment instanceof UploadProgressSegment && !this.isATWaiting();
  }

  isPointFailed(segment: ProgressSegment) {
    return this.isFailed(segment);
  }

  getPointIcon(segment: ProgressSegment) {
    return segment.getStatusIcon();
  }

  private hasProgressZeroYet(segment: ProgressSegment) {
    return segment.isStarting() && segment.hasEmptyProgress();
  }

  private isATWaiting() {
    return this.process.steps.at.isWaiting();
  }

  private roundProgress(progress: number) {
    return Math.round(progress * 10) / 10;
  }

  private updateData() {
    if (this.process) {
      this.segments = this.getSegments();
      this.started = this.process.steps.at.isStarted();
      const elapsedTime = this.calculateElapsedTime(new Date(this.process.startedAt), new Date());
      if (elapsedTime) {
        this.elapsedTime = elapsedTime;
      }
    }
  }

  private calculateElapsedTime(start: Date, lastUpdate: Date) {
    const duration = moment.duration(moment(lastUpdate).diff(moment(start)));
    return duration.asMilliseconds() > 0
      ? `${Math.floor(duration.asHours())}h ${duration.minutes()}m`
      : null;
  }

  private getSegments(): ProgressSegment[] {
    return [
      new UploadProgressSegment(this.process),
      new ATProgressSegment(this.process),
      new ProdProgressSegment(this.process),
      new PPProgressSegment(this.process),
    ];
  }

  private checkPreviousSegmentIsFinished(index: number) {
    return index === 0 || this.segments[index - 1].isFinished();
  }
}
