import { LinesMesh, Mesh } from 'babylonjs';
import { Layer, httpLayer } from '../../layer.model';

export class SketchBaseTool<T> implements Layer<T> {
  pointsMeshes: Mesh[] = [];
  isSaved = true;
  name: string;
  linesMesh: LinesMesh | null;
  isVisible: boolean;
  parentId: number;
  id: number;
  createdAt: string;
  editedAt: string;
  data: T;

  constructor(sketchPlaneId: number, id: number) {
    this.id = id;
    this.parentId = sketchPlaneId;
    this.data = (this.constructor as any).getInitialData();
  }

  protected static getInitialData(): any | null {
    return null;
  }

  static createBodyForHttpRequest(sketchTool: SketchBaseTool<any>): httpLayer | null {
    return null;
  }
}
