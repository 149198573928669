import { AdvancedFiltersService } from '@App/app/entities/shared/advanced-filters-service.model';
import { BaseAdvancedSearch } from '@App/app/entities/shared/advanced-search.model';
import { SitesService } from '@App/app/pages/sites/shared/site-services/sites.service';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { Filter } from '../models/filter';

@UntilDestroy()
@Component({ template: '' })
export abstract class BaseFilterComponent<
  BASE_ADVANCED_SEARCH extends BaseAdvancedSearch,
  FORM_TYPE extends FormGroup,
  FILTER_SERVICE extends AdvancedFiltersService<BASE_ADVANCED_SEARCH, FORM_TYPE>,
  SITES_SERVICE extends SitesService<unknown>
> implements Filter<FORM_TYPE, BASE_ADVANCED_SEARCH> {
  previousSearch: BASE_ADVANCED_SEARCH;
  isFilterPopoverActive = false;
  nbLayoutActive = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    @Inject('siteService') private siteService: SITES_SERVICE,
    @Inject('filterService') protected filterService: FILTER_SERVICE,
  ) {
    this.siteService.clickOutsideFilterPopover$
      .pipe(
        untilDestroyed(this),
        filter(() => this.isFilterPopoverActive),
      )
      .subscribe(() => {
        this.isFilterPopoverActive = false;
        this.cdr.detectChanges();
      });

    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  get filterForm() {
    return this.filterService.filterForm;
  }

  getKeywordsValue(): string {
    return this.filterService.filterForm.value.query;
  }

  isFormTouched() {
    return !!(this.filterForm.touched || this.getKeywordsValue());
  }

  checkInputHasNoEndDate(key: string) {
    return !!this.filterForm.get(key)?.hasError('noEndDate');
  }

  queryChange(query: string) {
    this.filterForm.reset({ ...this.previousSearch });
    this.filterForm.controls['query'].setValue(query);
    this.onSubmit(this.filterForm, false);
  }

  toggleFilterPopover() {
    if (!this.isFilterPopoverActive) {
      this.initAdvancedSearch();
    }
    this.isFilterPopoverActive = !this.isFilterPopoverActive;
  }

  clearFilters() {
    this.filterForm.reset();
    this.previousSearch = { ...this.filterForm.value };
    this.setAdvancedSearch(this.filterForm.value);
  }

  onSubmit(form: FORM_TYPE, dismissPopover = true) {
    this.filterService.advancedSearch(form.getRawValue());
    this.previousSearch = { ...form.getRawValue() };
    if (dismissPopover) {
      this.isFilterPopoverActive = false;
    }
  }

  abstract initAdvancedSearch(): void;

  abstract resetFilters(): void;

  protected isSomeFieldSelected(obj: any) {
    return Object.values(obj).some(Boolean);
  }

  protected setAdvancedSearch(values: BASE_ADVANCED_SEARCH) {
    this.filterService.advancedSearch(values);
  }
}
