import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { SketchPlane } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { LayersService } from '@App/app/pages/viewer/services/layers/layers.service';
import { UnitsService } from '@App/app/pages/viewer/services/utils/units.service';
import { Component } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { LayerDetailsService } from '../../../services/layer-details-service/layer-details.service';
import { BaseLayerPropertiesComponent } from '../base-layer-properties/base-layer-properties.component';

@Component({
  selector: 'app-sketch-plane-properties',
  templateUrl: './sketch-plane-properties.component.html',
})
export class SketchPlanePropertiesComponent extends BaseLayerPropertiesComponent<SketchPlane> {
  childLayers: LayerUI[] = [];
  isEditChildrenActive$ = this._layerDetailsService.isEditChildrenActive$;

  constructor(
    private _layersService: LayersService,
    unitsService: UnitsService,
    layerDetailsService: LayerDetailsService,
  ) {
    super(unitsService, layerDetailsService);

    this._layerDetailsService.childLayers$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.childLayers = value;
    });
  }

  onDeleteClick(layer?: LayerUI): void {
    this._layerDetailsService.deleteClicked(layer);
  }

  onDeleteCancel(layer?: LayerUI): void {
    this._layerDetailsService.deleteCanceled(layer);
  }

  onDeleteSketchConfirm(sketch: LayerUI): void {
    this._layersService.deleteLayer(sketch);
  }
}
