import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Vector3 } from 'babylonjs';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { CoordinatesLayer } from '../../../../entities/layer/coordinates-layer.model';
import { UtilsService } from '../../utils-service/utils.service';

@Injectable({
  providedIn: 'root',
})
export class CreateCoordinatesLayerService {
  constructor(
    private store: Store,
    private utilsService: UtilsService,
    private broadcastService: BroadcastService,
  ) {}

  onClickAction() {
    const hit = this.utilsService.pickRay();
    if (hit?.pickedMesh && hit.pickedPoint) {
      this.createCoordinatesLayer(hit.pickedPoint.clone());
    }
  }

  createCoordinatesLayer(position: Vector3) {
    const coordinatesLayer = new CoordinatesLayer();
    coordinatesLayer.name = `coordinates`;
    coordinatesLayer.isVisible = true;
    coordinatesLayer.isSaved = true;
    coordinatesLayer.parentId = undefined;
    coordinatesLayer.data.position = { x: position.x, y: position.y, z: position.z };
    this.store.dispatch(addLayer({ layer: coordinatesLayer as LayerUI, commit: false }));
    this.broadcastService.broadcast(EVENT_TYPE.INIT_COORDINATES, false);
  }
}
