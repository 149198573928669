import {
  ADD_PROCESSES_TO_ARCHIVED_SUCCESS,
  ADD_PROCESS_TO_ARCHIVED_SUCCESS,
  ADD_TO_NOTIFICATIONS_SUCCESS,
  FAILED,
  PROCESSES_ARCHIVED_FAILED,
  REMOVED_PROCESSES_FROM_ARCHIVED_SUCCESS,
  REMOVED_PROCESS_FROM_ARCHIVED_SUCCESS,
  REMOVED_TO_NOTIFICATIONS_SUCCESS,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ModelsProcessingHttpService } from '@App/app/pages/processing/services/models-processing-http-service/models-processing-http.service';
import { ProcessesSitesService } from '@App/app/pages/sites/shared/site-services/processes-sites.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, combineLatest } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { ModelsProcessingService } from '../../../services/models-processing-service/models-processing.service';
import {
  archiveProcess,
  archiveProcessSuccess,
  archiveProcesses,
  changeProcessSubscription,
  loadAllProcesses,
  updateProcess,
  updateProcessSuccess,
  updateProcessSuccessLocal,
} from '../actions/processes.actions';

@Injectable()
export class UpdateProcessEffects {
  constructor(
    private actions: Actions,
    private toastrService: NbToastrService,
    private modelsProcessingService: ModelsProcessingService,
    private modelsProcessingHttpService: ModelsProcessingHttpService,
    private sitesService: ProcessesSitesService,
  ) {}

  updateProcess = createEffect(() => {
    return this.actions.pipe(
      ofType(updateProcess),
      mergeMap(({ process }) => {
        return this.modelsProcessingHttpService.putBuildProcess(process).pipe(
          map((resp: BuildProcess) => {
            return updateProcessSuccess({ process: resp });
          }),
          catchError(() => EMPTY),
        );
      }),
    );
  });

  updateProcessSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(updateProcessSuccess),
        tap(({ process }) => {
          this.modelsProcessingService.setCurrentBuildProcess(process);
        }),
      );
    },
    { dispatch: false },
  );

  archiveProcess = createEffect(() => {
    return this.actions.pipe(
      ofType(archiveProcess),
      mergeMap(({ process, isArchived }) => {
        const processToUpdate = new BuildProcess({ ...process, isArchived });
        return this.modelsProcessingHttpService.putBuildProcess(processToUpdate).pipe(
          map(
            (resp: BuildProcess) => {
              return archiveProcessSuccess({
                process: new BuildProcess({
                  ...resp,
                  modelId: process.modelId,
                }),
              });
            },
            catchError(() => EMPTY),
          ),
        );
      }),
    );
  });

  archiveProcessSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(archiveProcessSuccess),
        tap(({ process }) => {
          this.toastrService.show(
            process.isArchived
              ? ADD_PROCESS_TO_ARCHIVED_SUCCESS
              : REMOVED_PROCESS_FROM_ARCHIVED_SUCCESS,
            SUCCESS,
            {
              ...SUCCESS_TOASTR_CONFIG,
            },
          );
          this.sitesService.loading.next(false);
        }),
      );
    },
    { dispatch: false },
  );

  changeProcessSubscription = createEffect(() => {
    return this.actions.pipe(
      ofType(changeProcessSubscription),
      mergeMap(({ process, subscribe }) => {
        return this.modelsProcessingHttpService
          .toggleSubscribeBuildProcess(process.id, subscribe)
          .pipe(
            map(
              (resp: BuildProcess) => {
                this.toastrService.show(
                  subscribe ? ADD_TO_NOTIFICATIONS_SUCCESS : REMOVED_TO_NOTIFICATIONS_SUCCESS,
                  SUCCESS,
                  { ...SUCCESS_TOASTR_CONFIG },
                );
                this.sitesService.loading.next(false);
                return updateProcessSuccessLocal({
                  process: new BuildProcess({
                    ...resp,
                    modelId: process.modelId,
                    model: { ...process.model },
                  }),
                });
              },
              catchError(() => EMPTY),
            ),
          );
      }),
    );
  });

  archiveProcesses = createEffect(() => {
    return this.actions.pipe(
      ofType(archiveProcesses),
      mergeMap(({ processes }) => {
        const archiveAction = !processes[0].isArchived;
        this.sitesService.setSelectedEntities([]);
        const newProcesses = processes.map((process: BuildProcess) => {
          return new BuildProcess({ ...process, isArchived: !process.isArchived });
        });
        return combineLatest(
          newProcesses.map((newProcess: BuildProcess) => {
            return this.modelsProcessingHttpService.putBuildProcess(newProcess);
          }),
        )
          .pipe(
            map(
              (resp: BuildProcess[]) => {
                const archiveSuccess = resp.every(
                  (res, index) => res.isArchived !== processes[index].isArchived,
                );
                if (archiveSuccess) {
                  this.toastrService.show(
                    archiveAction
                      ? ADD_PROCESSES_TO_ARCHIVED_SUCCESS
                      : REMOVED_PROCESSES_FROM_ARCHIVED_SUCCESS,
                    SUCCESS,
                    {
                      ...SUCCESS_TOASTR_CONFIG,
                    },
                  );
                } else {
                  this.toastrService.show(PROCESSES_ARCHIVED_FAILED, FAILED, {
                    ...WARNING_TOASTR_CONFIG,
                  });
                }
                return loadAllProcesses();
              },
              catchError(() => EMPTY),
            ),
          )
          .pipe(
            finalize(() => {
              this.sitesService.hideSpinner();
            }),
          );
      }),
    );
  });
}
