import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { ProfileInfoForm } from '@App/app/entities/forms/profile-info-form.model';
import { User } from '@App/app/entities/users/user';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileInfoComponent implements OnInit {
  user: User;
  editUserPermission = UserPermissions.CAN_EDIT_USER;
  profileForm: ProfileInfoForm = this.fb.group({
    firstName: new FormControl({ value: '', disabled: true }, Validators.required),
    lastName: new FormControl({ value: '', disabled: true }, Validators.required),
    email: new FormControl({ value: '', disabled: true }, Validators.required),
  });

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  ngOnInit(): void {
    const user = this.authService.getUser();
    if (user?.id) {
      this.user = user as User;
    }
    this.profileForm.controls.firstName.patchValue(this.user.firstName);
    this.profileForm.controls.lastName.patchValue(this.user.lastName);
    this.profileForm.controls.email.patchValue(this.user.email);
  }

  onSubmit(form: ProfileInfoForm) {}
}
