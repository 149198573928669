import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layer-details-thickness-field',
  templateUrl: './layer-details-thickness-field.component.html',
})
export class LayerDetailsThicknessFieldComponent {
  @Input() layer: LayerUI;
  @Input() value?: number;
  @Input() unitsSystem: UnitsSystem;
}
