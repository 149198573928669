import { Injectable } from '@angular/core';
import { Color3, Mesh, Plane, Vector3 } from 'babylonjs';
import { FILLING_COLOR_PLANE_NAME, SLICE_TOOL_CONFIG } from 'src/app/configs/babylon.config';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../material-service/material.service';

@Injectable({
  providedIn: 'root',
})
export class GuiUtilsService {
  constructor(
    private materialService: MaterialService,
    private babylonNodesService: BabylonNodesService,
  ) {}

  // SLICE TOOL METHODS

  /** creates main color plane for the grid material.
   *  babylonjs doesn't support opacity for the main color.
   */
  createSliceGridPlane(name: string, mainColor: Color3): Mesh {
    const plane = this.babylonNodesService.createPlane(
      name,
      { size: 40 },
      mainColor === SLICE_TOOL_CONFIG.TOP_SLICE.MAIN_COLOR
        ? this.materialService.topGridMaterial
        : this.materialService.bottomGridMaterial,
      SLICE_TOOL_CONFIG.CLIP_PLANE_NORMAL,
    );

    plane.isVisible = false;
    return plane;
  }

  createFillingColorPlane(clipPlane: Plane, color: Color3): Mesh {
    const fillingPlane = this.babylonNodesService.createPlane(
      FILLING_COLOR_PLANE_NAME,
      { size: 40 },
      color === SLICE_TOOL_CONFIG.TOP_SLICE.MAIN_COLOR
        ? this.materialService.topFillingPlaneMaterial
        : this.materialService.bottomFillingPlaneMaterial,
      clipPlane.normal,
    );

    fillingPlane.isPickable = false;
    fillingPlane.rotation = new Vector3(Math.PI, 0, 0);
    fillingPlane.isVisible = false;
    return fillingPlane;
  }
}
