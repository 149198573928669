import { LabelOptions, MarkerOptions } from 'maptalks';

export const MARKER_SYMBOL: NonNullable<MarkerOptions['symbol']> = {
  markerFile: '/assets/img/marker-icon-2x-blue.png',
  markerWidth: 25,
  markerHeight: 41,
};

export const SHADOW_SYMBOL: NonNullable<MarkerOptions['symbol']> = {
  markerFile: '/assets/img/marker-shadow.png',
  markerWidth: 41,
  markerHeight: 41,
  markerDx: 8,
};

export const MARKER_SKYFISH_SYMBOL: NonNullable<MarkerOptions['symbol']> = {
  markerFile: '/assets/img/marker-icon-2x-skyfish.png',
  markerWidth: 25,
  markerHeight: 41,
};

export const MODEL_LABEL_SYMBOL: NonNullable<MarkerOptions['symbol']> = {
  textFaceName: 'arial',
  textSize: 12,
  textFill: '#fff',
  textDy: -60,
};

export const MODEL_LABEL_BOX_STYLE: NonNullable<LabelOptions['boxStyle']> = {
  padding: [5, 5],
  symbol: {
    markerType: 'square',
    markerFill: '#333',
    markerLineWidth: 0,
  },
};
