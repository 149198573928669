import { SketchPlane } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { Mesh, TransformNode } from 'babylonjs';
import { GizmoManagerService } from '../../gizmo-manager/gizmo-manager.service';
import { SceneService } from '../../scene-service/scene.service';
import { SketchPlaneUtilsService } from './sketch-plane-utils.service';

@Injectable()
export class SketchPlaneGizmoService extends GizmoManagerService {
  constructor(
    private sketchPlaneUtilsService: SketchPlaneUtilsService,
    sceneService: SceneService,
    broadcastService: BroadcastService,
  ) {
    super(sceneService, broadcastService);
  }

  initGizmo(sketchPlane: SketchPlane, root: TransformNode) {
    if (!sketchPlane.gizmoManager) {
      sketchPlane.gizmoManager = this.createGizmoManager();
    }
    const pointsMeshes = sketchPlane.pointsMeshes ? sketchPlane.pointsMeshes : [];
    if (pointsMeshes) {
      sketchPlane.gizmoManager.attachableMeshes = [
        ...pointsMeshes,
        sketchPlane.triangleMesh,
      ].filter((mesh): mesh is Mesh => !!mesh);
    }

    sketchPlane.gizmoManager.positionGizmoEnabled = true;
    this.setupGizmoObservables(sketchPlane, root);
  }

  setupGizmoObservables(sketchPlane: SketchPlane, root: TransformNode) {
    if (sketchPlane.gizmoManager?.gizmos.positionGizmo) {
      sketchPlane.gizmoManager.gizmos.positionGizmo.yPlaneGizmo.isEnabled = true;

      sketchPlane.gizmoManager.gizmos.positionGizmo.xGizmo.dragBehavior.onDragObservable.add(() => {
        this.sketchPlaneUtilsService.moveSketchPlane(sketchPlane, root);
      });
      sketchPlane.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
        this.sketchPlaneUtilsService.moveSketchPlane(sketchPlane, root);
      });
      sketchPlane.gizmoManager.gizmos.positionGizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
        this.sketchPlaneUtilsService.moveSketchPlane(sketchPlane, root);
      });
      sketchPlane.gizmoManager.gizmos.positionGizmo.yPlaneGizmo.dragBehavior.onDragObservable.add(
        () => {
          this.sketchPlaneUtilsService.moveSketchPlane(sketchPlane, root);
        },
      );
      sketchPlane.gizmoManager.gizmos.positionGizmo.xGizmo.dragBehavior.onDragEndObservable.add(
        () => {
          this.sketchPlaneUtilsService.recomputeRootSpace(sketchPlane, root);
        },
      );
      sketchPlane.gizmoManager.gizmos.positionGizmo.yGizmo.dragBehavior.onDragEndObservable.add(
        () => {
          this.sketchPlaneUtilsService.recomputeRootSpace(sketchPlane, root);
        },
      );
      sketchPlane.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragEndObservable.add(
        () => {
          this.sketchPlaneUtilsService.recomputeRootSpace(sketchPlane, root);
        },
      );
      sketchPlane.gizmoManager.gizmos.positionGizmo.yPlaneGizmo.dragBehavior.onDragEndObservable.add(
        () => {
          this.sketchPlaneUtilsService.recomputeRootSpace(sketchPlane, root);
        },
      );
    }

    sketchPlane.gizmoManager?.onAttachedToMeshObservable.add((e) => {
      if (e && e.name === sketchPlane.triangleMesh?.name) {
        this.sketchPlaneUtilsService.recomputeRootSpace(sketchPlane, root);
        sketchPlane.gizmoManager?.attachToNode(root);
      }
    });

    if (sketchPlane.gizmoManager) {
      this.setGizmoManager(sketchPlane.id, sketchPlane.gizmoManager);
    }
  }
}
