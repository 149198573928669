import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasGuiToggler',
})
export class HasGuiTogglerPipe implements PipeTransform {
  constructor(private _viewerLayerService: ViewerLayerService) {}

  transform(layer: LayerUI) {
    return this._viewerLayerService.hasGuiToggler(layer.id);
  }
}
