import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { FilterableListForm } from '@App/app/entities/forms/filterable-list-form.model';
import { Model } from '@App/app/entities/models/model.model';
import { ExtendedRender, Render } from '@App/app/entities/processing/render.model';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RendersService } from '../../../services/renders-service/renders.service';
import { AnalyticsData } from './models/analytics-data.model';
import { AnalyticsDataService } from './services/analytics-data/analytics-data.service';

@UntilDestroy()
@Component({
  selector: 'app-model-renders',
  templateUrl: './model-renders.component.html',
  styleUrls: ['./model-renders.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [AnalyticsDataService],
})
export class ModelRendersComponent implements OnChanges {
  @ViewChild('selectionInput') selectionInput: ElementRef<HTMLInputElement>;
  @Input() companyId: number | null;
  @Input() model: Model;
  @Input() currentRender: ExtendedRender | null;
  @Input() renders: Render[] | null;
  filterForm: FormGroup<FilterableListForm>;
  permissions = UserPermissions;
  analyticsData: AnalyticsData;

  constructor(
    private rendersService: RendersService,
    private router: Router,
    private ref: ElementRef,
    private analyticsDataService: AnalyticsDataService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentRender) {
      this._setCurrentRender(changes.currentRender.currentValue);
    }

    if (changes.renders) {
      this._setRenders(changes.renders.currentValue);
    }
  }

  onRenderChange(render: Render) {
    this.rendersService.setCurrentRenderId(render.id);
    this.rendersService.loadCurrentRender(+this.model.id, render.id);
  }

  onAddRenderClick() {
    this.router.navigate([`/${this.companyId}/uploader/${+this.model.id}/selection`]);
  }

  private _setCurrentRender(value: ExtendedRender | null) {
    this.currentRender = value;
    this._recalculateCurrentRenderProperties();
    this._scrollToSelectedRender();
  }

  private _setRenders(value: Render[]) {
    this.renders = value;
    this._selectNewestRender();
  }

  private _recalculateCurrentRenderProperties() {
    const analytics = this.currentRender?.analyticsObject;
    if (analytics) {
      const data = this.analyticsDataService.getAllData(analytics);
      this.analyticsData = {
        durations: { ...data.durationData },
        costs: { ...data.costsData },
        pixelsTotal: data.pixelsTotal,
        modelSize: data.modelSize,
        imageSizeTotal: data.imageSizeTotal,
      };
    } else {
      this.analyticsData = {
        durations: {
          atDuration: null,
          prodDuration: null,
          ppDuration: null,
          totalDuration: null,
        },
        costs: {
          atCost: null,
          prodCost: null,
          ppCost: null,
          totalCost: null,
        },
        pixelsTotal: null,
        modelSize: null,
        imageSizeTotal: null,
      };
    }
  }

  private _selectNewestRender() {
    if (this.renders?.length && !this.currentRender) {
      const newestRender = this.renders?.reduce((prev, curr) =>
        curr.createdAtDate.getTime() > prev.createdAtDate.getTime() ? curr : prev,
      );
      if (newestRender) {
        this.rendersService.setCurrentRenderId(newestRender.id);
        this.rendersService.loadCurrentRender(+this.model.id, newestRender.id);
      }
    }
  }

  private _scrollToSelectedRender() {
    setTimeout(() => {
      const selected: HTMLElement | undefined = this.ref.nativeElement.querySelector('.--selected');
      selected?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }
}
