import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserComponent implements OnInit {
  companyId: number | null;
  showTopBar = false;

  constructor(private authService: AuthService) {
    this.authService.currentEndpoint$.pipe(untilDestroyed(this)).subscribe((endpoint) => {
      this.companyId = endpoint?.id || null;
    });
  }

  ngOnInit(): void {}
}
