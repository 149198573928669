import { CAMERAS_CONFIG } from '@App/app/configs/babylon.config';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { Camera, GizmoManager, UtilityLayerRenderer } from 'babylonjs';
import { SceneService } from '../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export abstract class GizmoManagerService<T = GizmoManager | null> {
  protected scaleRatio = CAMERAS_CONFIG.perspectiveGizmoScaleRatio;
  private _gizmoManagers: { [id: number]: T } = {};

  constructor(protected sceneService: SceneService, protected broadcastService: BroadcastService) {
    this.broadcastService.on(EVENT_TYPE.CHANGE_CAMERA).subscribe((value) => {
      this.scaleRatio =
        value === Camera.ORTHOGRAPHIC_CAMERA
          ? CAMERAS_CONFIG.orthoGraphicGizmoScaleRatio
          : CAMERAS_CONFIG.perspectiveGizmoScaleRatio;
      this.updateGizmoSizes();
    });
  }

  protected createGizmoManager() {
    const renderer = new UtilityLayerRenderer(this.sceneService.scene);
    return new GizmoManager(this.sceneService.scene, 1, renderer);
  }

  protected getGizmoManagers() {
    return this._gizmoManagers;
  }

  protected setGizmoManager(id: number, gizmoManager: T) {
    this._gizmoManagers[id] = gizmoManager;
    this.updateGizmoSizes();
  }

  protected removeGizmoManager(id: number) {
    if (this._gizmoManagers[id]) {
      delete this._gizmoManagers[id];
    }
    this.updateGizmoSizes();
  }

  protected updateGizmoSizes() {
    for (const gizmoManager of Object.values(this._gizmoManagers)) {
      if (gizmoManager) {
        ((gizmoManager as unknown) as GizmoManager).scaleRatio = this.scaleRatio;
      }
    }
  }
}
