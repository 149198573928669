import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SonyMission } from '../../../models/sony/sony-mission.model';
import { SonyHttpService } from '../../../services/sony-http-service/sony-http.service';
import { loadSonyMissions, loadSonyMissionsSuccess } from '../actions/sony-missions.actions';

@Injectable()
export class SonyMissionsEffects {
  constructor(private actions: Actions, private sonyHttpService: SonyHttpService) {}

  loadSonyMissions = createEffect(() => {
    return this.actions.pipe(
      ofType(loadSonyMissions),
      mergeMap(() => {
        return this.sonyHttpService.getMissions().pipe(
          map((resp) =>
            loadSonyMissionsSuccess({
              missions: resp.body.missions.map((data: any) => new SonyMission(data)),
            }),
          ),
          catchError(() => EMPTY),
        );
      }),
    );
  });
}
