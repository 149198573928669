import { ViewerCoordinatesLayer } from '@App/app/entities/layer/coordinates-layer.model';
import { ViewerDropLineLayer } from '@App/app/entities/layer/drop-line.model';
import { ViewerMeasurementLayer } from '@App/app/entities/layer/measurements/measurement.model';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Mesh } from 'babylonjs';
import { GizmoManagerService } from '../services/gizmo-manager/gizmo-manager.service';
import { SceneService } from '../services/scene-service/scene.service';
import { UtilsService } from '../services/utils-service/utils.service';

export abstract class LayerGizmoManager extends GizmoManagerService {
  constructor(
    protected utilsService: UtilsService,
    sceneService: SceneService,
    broadcastService: BroadcastService,
  ) {
    super(sceneService, broadcastService);
  }

  initGizmoManager(id: number, sphere: Mesh) {
    const gizmoManager = this.createGizmoManager();
    gizmoManager.attachableMeshes = [sphere];
    gizmoManager.attachToMesh(sphere);
    this.setGizmoManager(id, gizmoManager);
    return gizmoManager;
  }

  toggleGizmo(
    layer: ViewerCoordinatesLayer | ViewerDropLineLayer | ViewerMeasurementLayer,
    mesh: Mesh,
    value: boolean,
  ) {
    if (layer.gizmoManager) {
      layer.gizmoManager.positionGizmoEnabled = value;
      this.setupGizmoObservables(layer);
      layer.gizmoManager.attachToMesh(mesh);
      this.setGizmoManager(layer.id, layer.gizmoManager);
    }
  }

  private setupGizmoObservables(
    layer: ViewerCoordinatesLayer | ViewerDropLineLayer | ViewerMeasurementLayer,
  ) {
    if (layer.gizmoManager?.gizmos.positionGizmo) {
      layer.gizmoManager.gizmos.positionGizmo.xGizmo.dragBehavior.onDragObservable.add(() => {
        this.onDragHandler(layer);
        this.utilsService.updateActiveLayerDetails$.next();
      });

      layer.gizmoManager.gizmos.positionGizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
        this.onDragHandler(layer);
        this.utilsService.updateActiveLayerDetails$.next();
      });

      layer.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
        this.onDragHandler(layer);
        this.utilsService.updateActiveLayerDetails$.next();
      });
    }
  }

  onDragHandler(layer: ViewerCoordinatesLayer | ViewerDropLineLayer | ViewerMeasurementLayer) {}
}
