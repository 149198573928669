import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { LAYER_NAMES } from '@App/app/entities/layer/enums/layer-names.enum';
import { LAYER_EVENTS } from '@App/app/entities/layer/enums/layer-types.enum';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { LayersService } from 'src/app/pages/viewer/services/layers/layers.service';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { ActiveLayerToolService } from '../services/active-layer-tool/active-layer-tool.service';

@Injectable({
  providedIn: 'root',
})
export class CreateLayerBarService {
  activeTool: LAYER_NAMES = LAYER_NAMES.NULL;
  private _isEditMode$ = new BehaviorSubject<boolean>(false);
  isEditMode$ = this._isEditMode$.asObservable();

  constructor(
    private broadcastService: BroadcastService,
    private layerEventsService: LayerEventsService,
    private layersService: LayersService,
    private activeLayerToolService: ActiveLayerToolService,
  ) {}

  toggleActiveLayer(event: LAYER_EVENTS, name: LAYER_NAMES, activeTool: LAYER_NAMES) {
    if (this.isSketchLayer(name)) {
      this.broadcastService.broadcast(this.checkEventType(name), activeTool === name);
    }
    this.layerEventsService.activeLayerEvent.next(
      this.layerEventsService.activeLayerEvent.value === event ? LAYER_EVENTS.NULL : event,
    );
    this.activeLayerToolService.setActiveTool(name);
  }

  generateManualTool() {
    this.layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);

    //set edit mode immediately after create new instance
    this.layersService.layers$.pipe(take(1)).subscribe((layers) => {
      const layer = layers[layers.length - 1];
      if (!layer.isSaved) {
        this.layersService.setDetailsViewLayers([layer]);
        this.layersService.selectedLayers$
          .pipe(
            mergeMap((selectedLayers) => iif(() => !!selectedLayers.length, of(selectedLayers))),
            take(1),
          )
          .subscribe(() => {
            this.layersService.isEditModeOnInitMappingTool = true;
            this.activeLayerToolService.activateEditMode(true);
            this.layersService.setLayerEditMode(layer, true, false);
            this.setEditModeValue(true);
          });
      }
    });
  }

  createNewFolder() {
    this.activeLayerToolService.setActiveTool();
    this.layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    this.layersService.setCreatingFolderActive(true);
  }

  setEditModeValue(value: boolean) {
    this._isEditMode$.next(value);
  }

  private isSketchLayer(name: LAYER_NAMES) {
    return (
      name === LAYER_NAMES.SKETCH_PLANE ||
      name === LAYER_NAMES.SKETCH_LINE ||
      name === LAYER_NAMES.SKETCH_RECTANGLE ||
      name === LAYER_NAMES.SKETCH_CIRCLE
    );
  }

  private checkEventType(name: LAYER_NAMES): EVENT_TYPE {
    switch (name) {
      case LAYER_NAMES.SKETCH_RECTANGLE:
        return EVENT_TYPE.TAKE_SKETCH_RECTANGLE;
      case LAYER_NAMES.SKETCH_CIRCLE:
        return EVENT_TYPE.TAKE_SKETCH_CIRCLE;
      case LAYER_NAMES.SKETCH_LINE:
        return EVENT_TYPE.TAKE_SKETCH_LINE;
      case LAYER_NAMES.SKETCH_PLANE:
        return EVENT_TYPE.TAKE_SKETCH_PLANE;
      default:
        return EVENT_TYPE.TAKE_SKETCH_PLANE;
    }
  }
}
