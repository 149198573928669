import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { selectEndpoints } from '../store/auth/selectors/auth.selectors';

@Component({
  selector: 'app-company-selection',
  templateUrl: './company-selection.component.html',
  styleUrls: ['./company-selection.component.scss'],
})
export class CompanySelectionComponent implements OnInit {
  endpoints: Endpoint[];
  buttonAppears = true;
  searchText: string;
  permissions = UserPermissions;

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectEndpoints)
      .pipe(take(1))
      .subscribe((endpoints) => {
        this.endpoints = endpoints;
      });

    this.route.queryParams.subscribe((params) => {
      if (params['freshLogin']) {
        this.buttonAppears = false;
      }
    });
  }

  onClick(endpoint: Endpoint) {
    this.authService.setCurrentEndpoint(endpoint);
    this.router.navigate([`/${endpoint.id}/models`]);
  }

  onAdminPanelClick() {
    this.router.navigate([`/admin-panel`]);
  }
}
