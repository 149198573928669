import { SUCCESS } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { UsersHttpService } from 'src/app/pages/users/users-http.service';
import {
  ENABLED_USER_FAILED,
  ENABLED_USER_SUCCESS,
  FAILED,
} from '../../../../configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '../../../../configs/toastr-messages.config';
import { enableUser, enableUserFailed, enableUserSuccess } from '../actions/users.actions';

@Injectable()
export class EnableUserEffects {
  constructor(
    private actions: Actions,
    private usersHttpService: UsersHttpService,
    private toastrService: NbToastrService,
  ) {}

  enableUser = createEffect(() => {
    return this.actions.pipe(
      ofType(enableUser),
      mergeMap(({ userId }) => {
        return this.usersHttpService.enableUser(userId).pipe(
          map(() => {
            return enableUserSuccess({ id: userId });
          }),
          catchError(() => of(enableUserFailed())),
        );
      }),
    );
  });

  enableUserSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(enableUserSuccess),
        tap(() => {
          this.toastrService.show(ENABLED_USER_SUCCESS, SUCCESS, {
            ...SUCCESS_TOASTR_CONFIG,
          });
        }),
      );
    },
    { dispatch: false },
  );

  enabledUserFailed = createEffect(
    () => {
      return this.actions.pipe(
        ofType(enableUserFailed),
        tap(() => {
          this.toastrService.show(ENABLED_USER_FAILED, FAILED, {
            ...WARNING_TOASTR_CONFIG,
          });
        }),
      );
    },
    { dispatch: false },
  );
}
