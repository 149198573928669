import { Model } from '@App/app/entities/models/model.model';
import { ModelsService } from '@App/app/pages/models/services/models-service/models.service';
import { removeCurrentModelLocal } from '@App/app/pages/models/store/models/actions/models.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { ModelsDisplayingModes } from '../models/models-displaying-modes.model';
import { SitesService } from './sites.service';

@Injectable({
  providedIn: 'root',
})
export class ModelsSitesService extends SitesService<Model> {
  private _displayingMode$ = new BehaviorSubject(ModelsDisplayingModes.TABLE);
  displayingMode$ = this._displayingMode$.asObservable();

  constructor(store: Store, spinner: NgxSpinnerService, private modelsService: ModelsService) {
    super(store, spinner);
  }

  setDisplayingMode(mode: ModelsDisplayingModes) {
    this._displayingMode$.next(mode);
  }

  archiveMultiple(entities: Model[]): void {
    this.modelsService.archiveModels(entities);
  }

  deleteMultiple(entities: Model[]): void {
    this.modelsService.deleteHandler(entities);
  }

  resetCurrentModel() {
    this.store.dispatch(removeCurrentModelLocal());
  }
}
