import { SketchPlane } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { SketchCircle } from '@App/app/entities/layer/sketch-tools/tools/sketch-circle.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import {
  ActionManager,
  Axis,
  ExecuteCodeAction,
  LinesMesh,
  PointerDragBehavior,
  Space,
  StandardMaterial,
  Tags,
  TransformNode,
  UtilityLayerRenderer,
  Vector3,
} from 'babylonjs';
import {
  DRAFT_SKETCH_CIRCLE_TAG_NAME,
  SKETCH_CIRCLE_CENTER_MESH_NAME as centerName,
  NEW_SKETCH_CIRCLE_NAME as circleName,
  SKETCH_CIRCLE_EDGE_MESH_NAME as edgesName,
} from 'src/app/configs/babylon.config';
import { v4 as uuidv4 } from 'uuid';
import { MaterialService } from '../../../material-service/material.service';
import { SceneService } from '../../../scene-service/scene.service';
import { SketchesUtilsService } from '../shared/sketches-utils.service';
import { SketchCircleUtilsService } from './sketch-circle-utils.service';

type PickAction = (p: PointerDragBehavior, m: StandardMaterial) => ExecuteCodeAction;

@Injectable()
export class SketchCircleActionsService {
  constructor(
    private _sceneService: SceneService,
    private _sketchesUtilsService: SketchesUtilsService,
    private _broadcastService: BroadcastService,
    private _materialService: MaterialService,
    private _sketchCircleUtilsService: SketchCircleUtilsService,
  ) {}

  showSketch(sketchCircle: SketchCircle): SketchCircle {
    this._sceneService.scene.getNodeByName(`${circleName}${sketchCircle.id}`)?.dispose();
    const name = `${circleName}${sketchCircle.id}`;
    sketchCircle.root = new TransformNode(name, this._sceneService.scene);
    sketchCircle.root.id = `${sketchCircle.id}`;
    if (sketchCircle.data.centerPosition) {
      const { x, y, z } = sketchCircle.data.centerPosition;
      const [pos, root] = [new Vector3(x, y, z), sketchCircle.root];
      sketchCircle.centerMesh = this._sketchesUtilsService.createMesh(pos, root, centerName, true);
    }
    if (sketchCircle.data.edgePosition) {
      const { x, y, z } = sketchCircle.data.edgePosition;
      const [pos, root] = [new Vector3(x, y, z), sketchCircle.root];
      sketchCircle.edgeMesh = this._sketchesUtilsService.createMesh(pos, root, edgesName, true);
    }
    const radius = sketchCircle.data.radius || undefined;
    const circle = this._sketchCircleUtilsService.updateCircle(radius, undefined, true);
    sketchCircle.circleMesh = circle;
    sketchCircle.circleMesh.isVisible = true;
    if (sketchCircle.data.rotation) {
      const { x, y, z } = sketchCircle.data.rotation;
      sketchCircle.rotate = new Vector3(x, y, z);
      sketchCircle.circleMesh.rotation = new Vector3(x, y, z);
    }
    sketchCircle.circleMesh.parent = sketchCircle.root;
    sketchCircle.circleMesh.rotate(Axis.X, Math.PI / 2, Space.LOCAL);
    this._sketchCircleUtilsService.recomputeRootSpace(sketchCircle);
    return sketchCircle;
  }

  initializeFirstEdgeMesh(point: Vector3, circle: SketchCircle | null, circles: SketchCircle[]) {
    if (circle && !circle.edgeMesh && circle.root) {
      const { root } = circle;
      const mesh = this._sketchesUtilsService.createMesh(point, root, edgesName);
      circle.edgeMesh = mesh;
      circle.edgeMesh.parent = circle.root;
      this._broadcastService.broadcast(EVENT_TYPE.REFRESH_CUSTOM_LOD, null);
      circles.push(circle);
    }
  }

  initDraftCircle(sourcePoint: Vector3, plane: SketchPlane, pick: PickAction) {
    const utilityLayer = new UtilityLayerRenderer(this._sceneService.scene);
    const id = (uuidv4() as any) as number;
    const [planeId, name] = [plane.id, `${circleName}${id}`];
    const { scene } = this._sceneService;
    const draftCircle = new SketchCircle(planeId, id, name, scene, utilityLayer);
    Tags.AddTagsTo(draftCircle.root, `${DRAFT_SKETCH_CIRCLE_TAG_NAME}`);
    const mesh = this._initCenterMeshOnClick(draftCircle, sourcePoint);
    draftCircle.centerMesh = mesh || draftCircle.centerMesh;
    if (draftCircle.centerMesh) {
      draftCircle.centerMesh.parent = draftCircle.root;
    }
    this._broadcastService.broadcast(EVENT_TYPE.REFRESH_CUSTOM_LOD, null);
    this.setCenterMeshDragBehavior(draftCircle, plane, pick);
    this._sketchCircleUtilsService.recomputeRootSpace(draftCircle);
    return draftCircle;
  }

  finishCircle(
    circle: SketchCircle | null,
    point: Vector3,
    plane: SketchPlane | null,
    pick: PickAction,
  ) {
    if (circle?.circleMesh && circle.centerMesh && circle.edgeMesh) {
      circle.edgeMesh.position.copyFrom(point);
      this._sketchCircleUtilsService.recomputeRootSpace(circle);
      const radius = Vector3.Distance(circle.centerMesh.position, circle.edgeMesh.position);
      circle.data.radius = radius;
      circle.circleMesh = this._sketchCircleUtilsService.updateCircle(radius, circle.circleMesh);
      this.setEdgeMeshDragBehavior(circle, plane, pick);
    }
  }

  // eslint-disable-next-line complexity
  updateDraftCircleByPoint(circle: SketchCircle | null, point: Vector3, plane: SketchPlane | null) {
    if (circle && !circle.circleMesh) {
      circle.circleMesh = this._sketchCircleUtilsService.updateCircle();
      circle.circleMesh.parent = circle.root;
      if (plane?.plane) {
        circle.rotate = plane.plane.fillingPlane.rotation;
      }
      circle.circleMesh.rotation.copyFrom(circle.rotate);
      circle.circleMesh.rotate(Axis.X, Math.PI / 2, Space.LOCAL);
    } else if (circle?.circleMesh && circle.root) {
      // circle mesh update
      const { position } = circle.root;
      circle.data.radius = Vector3.Distance(position, point);
      const [radius, circleMesh] = [circle.data.radius, circle.circleMesh];
      circle.circleMesh = this._sketchCircleUtilsService.updateCircle(radius, circleMesh);
    }
  }

  setCenterMeshDragBehavior(circle: SketchCircle, plane: SketchPlane | null, pick: PickAction) {
    if (plane?.plane && circle.centerMesh) {
      this._sketchesUtilsService.addPointerEventListener(plane.plane);
      const dragBehavior = new PointerDragBehavior({ dragPlaneNormal: plane.normal });
      dragBehavior.dragDeltaRatio = 1;
      dragBehavior.onDragObservable.add(() => {
        if (circle.root) {
          circle.root.position = this._sketchesUtilsService.pointer.position;
        }
      });
      dragBehavior.onDragEndObservable.add(() => {
        if (circle.root) {
          circle.root.position = this._sketchesUtilsService.pointer.position;
        }
        const { x, y, z } = this._sketchesUtilsService.pointer.position;
        circle.data.centerPosition = { x, y, z };
      });
      circle.centerMesh.actionManager = new ActionManager(this._sceneService.scene);
      circle.centerMesh.actionManager.registerAction(
        pick(dragBehavior, this._materialService.dragMeshMaterial),
      );
    }
  }

  setEdgeMeshDragBehavior(circle: SketchCircle, plane: SketchPlane | null, pick: PickAction) {
    if (plane?.plane) {
      this._sketchesUtilsService.addPointerEventListener(plane.plane);
      const edgeDragBehavior = new PointerDragBehavior({ dragPlaneNormal: plane.normal });
      edgeDragBehavior.dragDeltaRatio = 1;
      edgeDragBehavior.onDragObservable.add(() => {
        const pos = this._sketchesUtilsService.pointer.position;
        if (circle.root) {
          circle.data.radius = Vector3.Distance(circle.root.position, pos);
        }
        const { x, y, z } = pos;
        circle.data.edgePosition = { x, y, z };
        if (circle.data.radius) {
          const circleMesh = circle.circleMesh as LinesMesh;
          this._sketchCircleUtilsService.updateCircle(circle.data.radius, circleMesh);
        }
      });
      if (circle.edgeMesh) {
        circle.edgeMesh.actionManager = new ActionManager(this._sceneService.scene);
        circle.edgeMesh.actionManager.registerAction(
          pick(edgeDragBehavior, this._materialService.dragMeshMaterial),
        );
      }
    }
  }

  private _initCenterMeshOnClick(circle: SketchCircle, position: Vector3) {
    if (circle?.root) {
      const { root } = circle;
      return this._sketchesUtilsService.createMesh(position, root, centerName);
    }
  }
}
