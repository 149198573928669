import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Pipe, PipeTransform } from '@angular/core';
import { toImperialLength } from '../utils/units-converter';

@Pipe({
  name: 'lengthUnit',
})
export class LengthUnitPipe implements PipeTransform {
  transform(value: number, system: UnitsSystem): string {
    return system === UnitsSystem.Imperial
      ? `${toImperialLength(value)}`
      : system === UnitsSystem.Metric
      ? `${value.toFixed(3)} m`
      : '-';
  }
}
