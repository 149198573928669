<nb-card status="primary" class="content-card sites">
  <nb-card-body>
    <app-main-datatable
      *ngIf="showTable"
      [columns]="columns"
      [rows]="rows"
      [page]="page"
      [loading]="(loading$ | async) ?? true"
      [showStatisticsButton]="(showStatisticsButton$ | async) ?? true"
      [selected]="selectedRows"
      (selectRow)="onSelectRow($event)"
      (activate)="onActivate($event)"
      (setPage)="setPage($event, true)"
      (setPageOffset)="setPageOffset($event)"
    ></app-main-datatable>
    <div>
      <ngx-spinner
        [bdColor]="spinnerBdColor"
        [zIndex]="10"
        size="large"
        [fullScreen]="false"
        color="#94959a"
        type="ball-clip-rotate"
      ></ngx-spinner>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #typeTpl let-row="row" let-value="value">
  <span data-testId="model-type" *ngIf="value; else notValueTpl"
    ><app-model-type-badge [modelType]="value"></app-model-type-badge
  ></span>
</ng-template>

<ng-template #editedTpl let-row="row" let-value="value">
  <span data-testId="editedAt" *ngIf="value; else notValueTpl">{{
    value | date: 'MM/dd/yyyy'
  }}</span>
</ng-template>

<ng-template
  ngx-datatable-header-template
  #selectAllTpl
  let-value="value"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
  <label class="lbl-checkbox">
    <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
  </label>
</ng-template>

<ng-template
  #selectTpl
  ngx-datatable-cell-template
  let-value="value"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
>
  <label class="lbl-checkbox" (click)="$event.stopPropagation()">
    <input
      type="checkbox"
      [checked]="isSelected"
      (change)="onCheckboxChangeFn($event)"
      (click)="$event.stopPropagation(); onSelectRow(value)"
    />
  </label>
</ng-template>

<ng-template #stateTpl let-row="row" let-value="value">
  <span *ngIf="value; else notValueTpl">{{ row | modelAddress }}</span>
</ng-template>

<ng-template #renderTpl let-row="row" let-value="value">
  <div class="render">
    <span
      data-testId="render-counter"
      class="render__render-counter"
      [ngClass]="value ? 'is_value' : 'not_value'"
      >{{ value || 0 }}</span
    >
  </div>
</ng-template>

<ng-template #createdAtTpl let-row="row" let-value="value">
  <span data-testId="createdAt" *ngIf="value; else notValueTpl">{{
    row.createdAt | date: 'MM/dd/yyyy'
  }}</span>
</ng-template>

<ng-template #notValueTpl>
  <span>-</span>
</ng-template>

<ng-template #nameTpl let-row="row" let-value="value">
  <ng-container *ngIf="value"
    ><span>{{ value }}</span></ng-container
  >
</ng-template>

<ng-template #actionsTpl let-row="row">
  <div class="actions">
    <button
      nbButton
      *ngxPermissionsOnly="permissions.CAN_ARCHIVE_MODEL"
      data-testId="archive-model"
      (click)="archiveHandler($event, row)"
      [nbTooltip]="row.isArchived ? 'Unarchive model' : 'Archive model'"
      ghost
      class="action"
    >
      <svg-icon [src]="row.isArchived ? unarchiveIcon : archiveIcon"></svg-icon>
    </button>
    <button
      *ngxPermissionsOnly="permissions.CAN_DELETE_MODEL"
      data-testId="delete-model"
      nbButton
      (click)="deleteHandler($event, row)"
      [nbTooltip]="'Delete model'"
      ghost
      class="action"
    >
      <div>
        <nb-icon icon="trash-2-outline"></nb-icon>
      </div>
    </button>
  </div>
</ng-template>
