export class SonyMission {
  id: string;
  date: Date;
  name: string;
  targetType: number;
  location: [number, number] | null;
  dataPath: string;
  status: number;
  uploadedNumber: number;
  totalNumber: number;

  constructor(data: { [key: string]: any }) {
    this.id = data.mission_id;
    this.status = data.mission_status;
    this.date = new Date(data.mission_date);
    this.name = data.mission_name;
    this.targetType = data.target_type;
    this.location = data.location;
    this.dataPath = data.data_path;
    this.uploadedNumber = data.uploaded_number;
    this.totalNumber = data.total_number;
  }
}
