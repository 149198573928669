import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-logs-dialog',
  templateUrl: './logs-dialog.component.html',
  styleUrls: ['./logs-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogsDialogComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: TemplateRef<any>;
  @Output() closeLogsDialog = new EventEmitter();
  @Input() logs: string;

  dialogRef: NbDialogRef<any>;

  constructor(private dialogService: NbDialogService) {}

  ngOnInit() {
    this.dialogRef = this.dialogService.open(this.dialog, { closeOnBackdropClick: false });
    this.dialogRef.onBackdropClick.pipe(take(1)).subscribe(() => {
      this.closeDialog();
    });
  }

  closeDialog() {
    this.dialogRef.close();
    this.closeLogsDialog.emit();
  }
}
