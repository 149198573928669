import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, presetsAdapter } from '../reducers/ui-settings.reducers';

export const getPresetsState = createFeatureSelector<State>('presets');

const { selectAll } = presetsAdapter.getSelectors(getPresetsState);

export const selectAllPresets = selectAll;

export const selectCustomPresets = createSelector(selectAll, (entities) =>
  entities.filter((entity) => entity.default === false),
);
