import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LayerHttpService } from '../../../../engine/services/layer-services/layer-http-service/layer-http.service';
import { ViewerLayerService } from '../../../../engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LayersUtilsService } from '../../services/layers-utils-service/layers-utils.service';
import { loadAllLayers, loadAllLayersSuccess } from '../actions/layers.actions';

@Injectable()
export class WorkspaceLoadItemsEffects {
  constructor(
    private _viewerLayerService: ViewerLayerService,
    private _actions: Actions,
    private _layerHttpService: LayerHttpService,
    private _layersUtilsService: LayersUtilsService,
  ) {}

  loadAllLayers = createEffect(() => {
    return this._actions.pipe(
      ofType(loadAllLayers),
      mergeMap(({ renderId }) =>
        this._layerHttpService.getLayersByRenderId(renderId).pipe(
          map(
            (layers) => {
              if (!layers.length) {
                this._layersUtilsService.createFolder('Root', true, renderId);
              }
              layers.forEach((layer) => {
                if (
                  layer.type === LAYER_TYPES.FOLDERS ||
                  layer.type === LAYER_TYPES.SKETCH_PLANES
                ) {
                  layer.open = false;
                }
                layer.isVisible = false;
                layer.isSaved = true;
              });
              return loadAllLayersSuccess({ layers });
            },
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
  });

  loadAllLayersSuccess = createEffect(
    () => {
      return this._actions.pipe(
        ofType(loadAllLayersSuccess),
        tap((payload) => {
          this._viewerLayerService.initViewerLayers(payload.layers);
        }),
      );
    },
    { dispatch: false },
  );
}
