<div class="layers_dxf_export_bar">
  <div
    class="layers_dxf_export_bar__button_wrapper"
    [attr.nbTooltipTrigger]="exportableChildLayers.length ? nbTrigger.NOOP : nbTrigger.HOVER"
  >
    <button
      nbButton
      status="info"
      size="tiny"
      class="layers_dxf_export_bar__button"
      [disabled]="!exportableChildLayers.length"
      data-testId="layers-dxf-export-bar-export-button"
      (click)="onExportDXFClick()"
    >
      <nb-icon icon="dxf-label" [pack]="CUSTOM_ICONS_PACK"></nb-icon>
      <div data-testId="dxf-export-btn">Export DXF</div>
    </button>
  </div>
</div>
