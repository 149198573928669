import { FAILED, OPEN_NEW_WINDOW_ERROR } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { interval } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { SonyHttpService } from '../../../services/sony-http-service/sony-http.service';
import { removeSonyMissionsLocally } from '../../sony-missions/actions/sony-missions.actions';
import {
  fetchUserLoginCodeSuccess,
  generateCodeChallenge,
  getLoginUrl,
  getLoginUrlSuccess,
  loadTokens,
  loadTokensSuccess,
  login,
  resetSonyAuthData,
  waitForUserToLogin,
} from '../actions/sony-auth.actions';
import { selectCodeSet } from '../selectors/sony-auth.selectors';

@Injectable()
export class SonyLoginEffects {
  constructor(
    private actions: Actions,
    private sonyHttpService: SonyHttpService,
    private store: Store,
    private router: Router,
    private toastService: NbToastrService,
  ) {}

  login = createEffect(() => {
    return this.actions.pipe(
      ofType(login),
      map(() => generateCodeChallenge()),
    );
  });

  generateCodeChallenge = createEffect(() => {
    return this.actions.pipe(
      ofType(generateCodeChallenge),
      switchMap(() => this.sonyHttpService.generateCodeChallenge()),
      map((codeSet) => getLoginUrl({ codeSet })),
    );
  });

  getLoginUrl = createEffect(() => {
    return this.actions.pipe(
      ofType(getLoginUrl),
      switchMap(({ codeSet }) =>
        this.sonyHttpService.getLoginUrl(codeSet.codeChallenge, codeSet.state),
      ),
      map((resp) => getLoginUrlSuccess({ loginUrl: resp.body.location })),
    );
  });

  getLoginUrlSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(getLoginUrlSuccess),
      map(({ loginUrl }) => {
        const loginWindow = window.open(loginUrl, '_blank');
        if (loginWindow) {
          loginWindow.focus();
          return waitForUserToLogin();
        } else {
          const urlParts = this.router.url.split('/');
          urlParts[urlParts.length - 1] = 'selection';
          this.router.navigate(urlParts);
          this.toastService.show(OPEN_NEW_WINDOW_ERROR, FAILED, WARNING_TOASTR_CONFIG);
          return resetSonyAuthData();
        }
      }),
    );
  });

  waitForUserToLogin = createEffect(() => {
    return this.actions.pipe(
      ofType(waitForUserToLogin),
      switchMap(() => this.store.select(selectCodeSet).pipe(first())),
      filter(Boolean),
      switchMap(({ state }) =>
        interval(2000).pipe(
          switchMap(() => this.sonyHttpService.getCodeChallenge(state)),
          filter((resp) => !!resp.code),
          take(1),
          map((codeSet) => fetchUserLoginCodeSuccess({ codeSet })),
        ),
      ),
    );
  });

  fetchUserLoginCodeSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(fetchUserLoginCodeSuccess),
      map(({ codeSet }) => loadTokens({ codeSet })),
    );
  });

  loadTokens = createEffect(() => {
    return this.actions.pipe(
      ofType(loadTokens),
      mergeMap(({ codeSet }) => {
        return this.sonyHttpService.getToken(codeSet.codeVerifier, codeSet.code as string).pipe(
          map((resp) =>
            loadTokensSuccess({
              refreshToken: resp.body.refresh_token,
              accessToken: resp.body.access_token,
            }),
          ),
        );
      }),
    );
  });

  resetSonyAuthData = createEffect(() => {
    return this.actions.pipe(
      ofType(resetSonyAuthData),
      map(() => removeSonyMissionsLocally()),
    );
  });
}
