import { LayerDetailsEditForm } from '@App/app/entities/forms/layer-details-edit-form.model';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layer-details-description',
  templateUrl: './layer-details-description.component.html',
  styleUrls: ['./layer-details-description.component.scss'],
})
export class LayerDetailsDescriptionComponent {
  @Input() layer: LayerUI;
  @Input() editForm: LayerDetailsEditForm;
}
