import { Color3, Mesh, Vector3 } from 'babylonjs';

export class SlicePlane {
  id: string;
  name: string;

  position: Vector3;
  isVisible: boolean;
  mainColor: Color3;
  lineColor: Color3;
  fillingPlane?: Mesh;
  gridPlane?: Mesh;

  constructor(name: string) {
    this.name = name;
    this.isVisible = true;
  }

  public showPlanes(slicePlane: SlicePlane, visible: boolean) {
    if (slicePlane.fillingPlane) {
      this.showPlane(slicePlane.fillingPlane, visible);
    }
    if (slicePlane.gridPlane) {
      this.showPlane(slicePlane.gridPlane, visible);
    }
  }

  private showPlane(plane: Mesh, visible: boolean) {
    plane.isVisible = visible;
  }
}
