<div class="layer_details__row">
  <div class="layer_details__label">Length</div>
  <div class="layer_details__value">
    <app-layer-details-optional-measurement-field
      [value]="layer.data.length"
      [unitsSystem]="unitsSystem"
      type="length"
    ></app-layer-details-optional-measurement-field>
  </div>
</div>
