import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AddUserForm } from '../../../entities/users/user';
import { addNewUser } from '../store/actions/users.actions';

@Injectable({
  providedIn: 'root',
})
export class AddUserService {
  private _isRequestSended$ = new BehaviorSubject<boolean>(false);
  isRequestSended$ = this._isRequestSended$.asObservable();

  constructor(private store: Store) {}

  addNewUser(user: AddUserForm) {
    this.store.dispatch(addNewUser({ user }));
  }

  setRequestSended(sended: boolean) {
    this._isRequestSended$.next(sended);
  }
}
