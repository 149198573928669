import { Injectable } from '@angular/core';
import { ApiService } from '@App/app/shared/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpEditRoles, Role } from '../../entities/users/user';
import { getFilteredRoles } from './shared/roles.mapper';

@Injectable({
  providedIn: 'root',
})
export class RolesHttpService extends ApiService {
  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.api}/roles`).pipe(map((roles) => getFilteredRoles(roles)));
  }

  assignRoles(body: HttpEditRoles): Observable<any> {
    return this.http.post<any>(`${this.api}/roles/assign`, body);
  }
}
