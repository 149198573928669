import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { SitesService } from './sites.service';

@Injectable({
  providedIn: 'root',
})
export class ProcessesSitesService extends SitesService<BuildProcess> {
  constructor(
    store: Store,
    spinner: NgxSpinnerService,
    private processingService: ModelsProcessingService,
  ) {
    super(store, spinner);
  }

  archiveMultiple(entities: BuildProcess[]): void {
    this.processingService.archiveProcesses(entities);
  }

  deleteMultiple(entities: BuildProcess[]): void {
    this.processingService.deleteHandler(entities);
  }
}
