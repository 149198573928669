import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from './auth/auth.guard';
import { CompanySelectionComponent } from './auth/company-selection/company-selection.component';
import { ConfirmPasswordRecoveryComponent } from './auth/confirm-password-recovery/confirm-password-recovery.component';
import { DefaultRouteGuard } from './auth/default-route.guard';
import { DocumentsAcceptanceComponent } from './auth/documents-acceptance/documents-acceptance.component';
import { DoesNotHaveDocsAcceptedGuard } from './auth/guards/does-not-have-docs-accepted/does-not-have-docs-accepted.guard';
import { HasDocsAcceptedGuard } from './auth/guards/has-docs-accepted/has-docs-accepted.guard';
import { LoggedInAuthGuard } from './auth/logged-in-auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { OtherCompaniesGuard } from './auth/other-companies.guard';
import { PasswordRecoveryComponent } from './auth/password-recovery/password-recovery.component';
import { SelectedCompanyGuard } from './auth/selected-company.guard';

import { UserPermissions } from './entities/auth/permissions.enum';
import { ModelsListGuard } from './pages/models/guards/models-list-guard/models-list.guard';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { UserComponent } from './pages/user/user.component';
import { AddUserComponent } from './pages/users/add-user/add-user.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { ViewerComponent } from './pages/viewer/viewer.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', children: [], canActivate: [DefaultRouteGuard] },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: 'password-recovery/confirm',
    component: ConfirmPasswordRecoveryComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: 'documents-acceptance',
    component: DocumentsAcceptanceComponent,
    canActivate: [AuthGuard, DoesNotHaveDocsAcceptedGuard],
  },
  {
    path: 'company-selection',
    component: CompanySelectionComponent,
    canActivate: [AuthGuard, OtherCompaniesGuard, HasDocsAcceptedGuard],
  },
  {
    path: 'admin-panel',
    loadChildren: () =>
      import('./pages/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule),
    canActivate: [AuthGuard, OtherCompaniesGuard, HasDocsAcceptedGuard],
    data: {
      permissions: {
        only: [UserPermissions.CAN_ACCESS_ADMIN_PANEL],
      },
    },
  },
  {
    path: ':CompanyId',
    canActivate: [AuthGuard, SelectedCompanyGuard],
    canActivateChild: [HasDocsAcceptedGuard],
    children: [
      { path: 'viewer', component: ViewerComponent, canActivate: [AuthGuard] },
      { path: 'viewer/:id', component: ViewerComponent, canActivate: [AuthGuard] },
      {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', redirectTo: 'profile', pathMatch: 'full' },
          { path: 'profile', component: ProfileComponent },
        ],
      },
      {
        path: 'add-user',
        component: AddUserComponent,
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: [UserPermissions.CAN_ADD_USER],
          },
        },
      },
      {
        path: 'edit-user/:id',
        component: EditUserComponent,
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: [UserPermissions.CAN_EDIT_USER],
          },
        },
      },
      {
        path: 'uploader',
        loadChildren: () =>
          import('./pages/uploader/uploader.module').then((m) => m.UploaderModule),
        canActivate: [AuthGuard, ModelsListGuard],
        data: {
          permission: UserPermissions.CAN_UPLOAD_PHOTOS,
          redirectPermissions: [
            UserPermissions.CAN_ACCESS_MODEL_LIST,
            UserPermissions.CAN_ACCESS_PROCESSING_LIST,
          ],
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, LoggedInAuthGuard],
})
export class AppRoutingModule {}
