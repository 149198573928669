import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';

@Component({
  selector: 'app-company-selection-row',
  templateUrl: './company-selection-row.component.html',
  styleUrls: ['./company-selection-row.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySelectionRowComponent {
  @Input() endpoint: Endpoint;

  constructor() {}
}
