<div class="viewer">
  <app-engine [engineService]="engineService"></app-engine>
  <div *ngIf="viewerType === VIEWER_TYPE.CUSTOMER" class="viewer__sidebar">
    <div class="viewer__sidebar_mode">
      <button
        nbTooltip="Layers"
        nbTooltipPlacement="right"
        [ngClass]="{ active: activeMode === VIEWER_MODE.LAYERS_MODE }"
        (click)="changeMode(VIEWER_MODE.LAYERS_MODE)"
        size="large"
        ghost
        nbButton
        data-testId="layers-mode-button"
      >
        <nb-icon icon="layers-outline"></nb-icon>
      </button>
      <button
        *ngxPermissionsOnly="permissions.CAN_ACCESS_PHOTOS_IN_3D_VIEWER"
        nbTooltip="Photos"
        nbTooltipPlacement="right"
        [ngClass]="{ active: activeMode === VIEWER_MODE.PHOTOS_MODE }"
        (click)="changeMode(VIEWER_MODE.PHOTOS_MODE)"
        size="large"
        ghost
        nbButton
        data-testId="photos-mode-button"
      >
        <nb-icon icon="image-outline"></nb-icon>
      </button>
    </div>
  </div>
  <div
    [ngClass]="{
      hidden: activeMode === VIEWER_MODE.PHOTOS_MODE,
      left: viewerType === VIEWER_TYPE.PROCESSING,
      'force-display': viewerType === VIEWER_TYPE.PROCESSING
    }"
    class="viewer__sidebar_layers"
  >
    <app-layers *ngIf="viewerType === VIEWER_TYPE.CUSTOMER"></app-layers>
    <app-processing-sidebar *ngIf="viewerType === VIEWER_TYPE.PROCESSING"></app-processing-sidebar>
  </div>

  <div
    class="viewer__loading_hq_info"
    [ngClass]="{ top: activeMode === VIEWER_MODE.PHOTOS_MODE }"
    *ngIf="tilesLoadingProgress$ | async as progress"
  >
    <div class="viewer__loading_hq_info__wrapper">
      <nb-spinner nbSpinnerStatus="primary" message=""></nb-spinner>
      <span>Tiles loading ({{ progress | number: '1.0-0' }}%)</span>
    </div>
  </div>

  <ng-container *ngIf="controlPointActive | async">
    <app-processing-gcp-map></app-processing-gcp-map>
    <app-processing-photos-grid></app-processing-photos-grid>
  </ng-container>
  <div
    [ngClass]="{ hidden: activeMode === VIEWER_MODE.LAYERS_MODE }"
    class="viewer__sidebar_photos"
  >
    <div class="viewer__sidebar_wrapper">
      <button
        *ngIf="tiePointAccessible$ | async"
        (click)="toggleTiePointsMode()"
        class="viewer__sidebar_button"
        size="medium"
        status="primary"
        nbButton
        [disabled]="tiePointsLoading$ | async"
        [nbSpinner]="(tiePointsLoading$ | async) ?? false"
        [nbTooltip]="
          tiePointsModeActive ? 'Deactivate Tie Point Picker' : 'Activate Tie Point Picker'
        "
        nbTooltipPlacement="top"
      >
        <svg-icon
          *ngIf="!(tiePointsLoading$ | async)"
          [src]="tiePointsModeActive ? cursorIconCrossed : cursorIcon"
          svgClass="viewer__sidebar_button_svg"
        ></svg-icon>
      </button>
      <button
        *ngIf="tiePointsModeActive"
        (click)="toggleMarkerVisibility()"
        class="viewer__sidebar_button"
        size="medium"
        status="primary"
        nbButton
        [nbTooltip]="(photoMarkerActive$ | async) ? 'Hide Photo Marker' : 'Show Photo Marker'"
        nbTooltipPlacement="top"
      >
        <svg-icon
          [src]="(photoMarkerActive$ | async) ? targetIconCrossed : targetIcon"
          svgClass="viewer__sidebar_button_svg"
        ></svg-icon>
      </button>
    </div>
    <app-photos-carousel *ngIf="activeMode === VIEWER_MODE.PHOTOS_MODE"></app-photos-carousel>
  </div>

  <div *ngIf="showTools" class="viewer__camera-controls-inner">
    <app-camera-controls
      [photoMode]="photoMode"
      [engineService]="engineService"
    ></app-camera-controls>
  </div>

  <div
    *ngIf="showTools"
    class="viewer__navigation_controls"
    [ngClass]="{
      '--photos': activeMode === VIEWER_MODE.PHOTOS_MODE,
      '--default': activeMode !== VIEWER_MODE.PHOTOS_MODE
    }"
  >
    <div
      class="viewer__navigation_controls__compass"
      *ngIf="activeMode !== VIEWER_MODE.PHOTOS_MODE"
    >
      <app-camera-snap></app-camera-snap>
    </div>
    <div class="viewer__navigation_controls__legends">
      <app-viewer-gcp-legend *ngIf="showGcpLegend$ | async"></app-viewer-gcp-legend>
      <app-photo-zoom-legend
        leftMouseButtonActionLabel="Rotate"
        [isViewerMode]="true"
      ></app-photo-zoom-legend>
    </div>
  </div>
</div>
