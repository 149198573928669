/* eslint-disable ngrx/no-dispatch-in-effects */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { delay, first, map, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { UploaderFilesActionsService } from '../../../components/default-uploader/services/uploader-files-actions.service';
import { UploaderUtilsService } from '../../../components/default-uploader/services/uploader-utils.service';
import { UploaderStorageService } from '../../../services/uploader-storage-service/uploader-storage.service';
import {
  removeAll,
  removeFile,
  setControlPointsFileName,
  setFiles,
  setFlatFileName,
  setXlifData,
  setXlifDataLocally,
} from '../actions/files.actions';
import { selectFiles, selectFilesState } from '../selectors/files.selectors';

@Injectable()
export class UpdateFilesEffects {
  constructor(
    private actions: Actions,
    private store: Store,
    private uploaderStorageService: UploaderStorageService,
    private uploaderFilesActionsService: UploaderFilesActionsService,
    private uploaderUtilsService: UploaderUtilsService,
  ) {}

  removeAll = createEffect(
    () => {
      return this.actions.pipe(
        ofType(removeAll),
        tap(() => {
          this.uploaderStorageService.wrongCoordsFileNames$.next([]);
          this.uploaderStorageService.noCoordsFileNames$.next([]);
          this.uploaderStorageService.validXlifData$.next([]);
          this.uploaderStorageService.isPendingUpload$.next(false);
          this.uploaderStorageService.updateWrongMetadataInfo();
          this.uploaderStorageService.keywords.next('');
          this.uploaderStorageService.showProblematicFiles.next(false);
        }),
      );
    },
    { dispatch: false },
  );

  removeFile = createEffect(
    () => {
      return this.actions.pipe(
        ofType(removeFile),
        // eslint-disable-next-line rxjs/no-unsafe-switchmap
        switchMap(({ name }) =>
          this.store.select(selectFilesState).pipe(
            first(),
            // eslint-disable-next-line ngrx/avoid-mapping-selectors
            map((state) => ({ state, name })),
          ),
        ),
        tap(({ state, name }) => {
          const { noCoordsFileNames$, wrongCoordsFileNames$ } = this.uploaderStorageService;
          noCoordsFileNames$.next(noCoordsFileNames$.value.filter((i) => i !== name));
          wrongCoordsFileNames$.next(wrongCoordsFileNames$.value.filter((i) => i !== name));

          if (state.flatFileName === name) {
            this.store.dispatch(setFlatFileName({ name: null }));
          }

          if (state.controlPointsFileName === name) {
            this.store.dispatch(setControlPointsFileName({ name: null }));
          }

          const xlifIndex = state.xlifData.findIndex((i) => i.name === name);
          if (xlifIndex !== -1) {
            const xlifData = [...state.xlifData];
            xlifData.splice(xlifIndex, 1);
            this.store.dispatch(setXlifData({ xlifData }));
          }
          this.uploaderStorageService.updateWrongMetadataInfo();
        }),
      );
    },
    { dispatch: false },
  );

  setFlatFileName = createEffect(
    () => {
      return this.actions.pipe(
        ofType(setFlatFileName),
        // eslint-disable-next-line rxjs/no-unsafe-switchmap
        switchMap(() => this.store.select(selectFiles).pipe(take(1))),
        delay(10),
        tap(() => {
          this.store.dispatch(setFiles({ files: [], xlifData: [] }));
        }),
        delay(10),
        tap(async (files) => {
          await this.uploaderFilesActionsService.addFiles([...files]);
        }),
      );
    },
    { dispatch: false },
  );

  setFiles = createEffect(() => {
    return this.actions.pipe(
      ofType(setFiles),
      map(({ xlifData }) => setXlifData({ xlifData })),
    );
  });

  updateFilesMetadata = createEffect(() => {
    return this.actions.pipe(
      ofType(setXlifData, setControlPointsFileName, setFlatFileName),
      delay(10),
      switchMapTo(this.store.select(selectFilesState).pipe(take(1))),
      // eslint-disable-next-line rxjs/no-unsafe-switchmap
      switchMap((state) => from(this.uploaderUtilsService.validateMetadata(state)).pipe(take(1))),
      map((xlifData) => setXlifDataLocally({ xlifData })),
    );
  });
}
