import { Injectable } from '@angular/core';
import { CustomScene } from '@App/app/entities/viewer/custom-scene.model';
import { Color3, Color4, Engine, HemisphericLight, Vector3 } from 'babylonjs';
import { AdvancedDynamicTexture } from 'babylonjs-gui';
import { GREY_COLOR, MAIN_LIGHT_NAME, SCENE_CONFIG } from 'src/app/configs/babylon.config';

@Injectable({
  providedIn: 'root',
})
export class SceneService {
  // Scene is public and it has to be because of methods which creates meshes (they modify scene).
  scene: CustomScene;
  sceneBackgroundColor = Color4.FromHexString(GREY_COLOR);

  createScene(engine: Engine): CustomScene {
    this.scene = new CustomScene(engine, {
      useGeometryUniqueIdsMap: true,
      useMaterialMeshMap: true,
      useClonedMeshMap: true,
    });

    this.changeSceneColor();
    this.scene.__editMode = SCENE_CONFIG.editMode;
    this.createGUI();
    const light = new HemisphericLight(MAIN_LIGHT_NAME, new Vector3(0, 1, 0), this.scene);
    light.specular = Color3.Black();
    light.groundColor = new Color3(0.67, 0.67, 0.67);
    return this.scene;
  }

  changeSceneColor(color = this.sceneBackgroundColor) {
    this.scene.clearColor = color;
  }

  createGUI() {
    this.scene.__advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI(
      'Fullscreen GUI',
      true,
      this.scene,
    );
    this.scene.__advancedTexture.useInvalidateRectOptimization = false;
  }

  setEditMode(editMode: boolean) {
    this.scene.__editMode = editMode;
  }

  resetAdvancedTexture() {
    this.scene.__advancedTexture?.dispose();
    this.scene.__advancedTexture = null;
    this.createGUI();
  }

  toggleDebugLayer() {
    this.scene?.debugLayer.hide();
    this.scene?.debugLayer.show();
  }
}
