/* eslint-disable max-lines */
import {
  BUTTON_LAYERS_CONFIG,
  CIRCLE_ICON,
  LINE_ICON,
  RECTANGLE_ICON,
} from '@App/app/configs/button-layers.config';
import { EDIT_MODE } from '@App/app/configs/hints.config';
import { EDIT_INIT_TOOLS } from '@App/app/configs/layers-bar.config';
import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { LAYER_NAMES } from '@App/app/entities/layer/enums/layer-names.enum';
import { LAYER_EVENTS } from '@App/app/entities/layer/enums/layer-types.enum';
import { SAVE_SKETCH_TYPE } from '@App/app/entities/layer/sketch-tools/save-sketch-types.enum';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { LayerButtonFunc } from '@App/app/entities/viewer/layer-button.model';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { LayersService } from 'src/app/pages/viewer/services/layers/layers.service';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { RendersService } from '../../models/services/renders-service/renders.service';
import { CreateLayerButtonService } from '../create-layer-button/create-layer-button.service';
import { ActiveLayerToolService } from '../services/active-layer-tool/active-layer-tool.service';
import { AutomappingService } from '../services/automapping/automapping.service';
import { TutorialVideoPopupComponent } from '../tutorials-video/tutorial-video-popup/tutorial-video-popup.component';
import { HintsService } from './../hints/hints.service';
import { CreateLayerBarService } from './create-layer-bar.service';

@UntilDestroy()
@Component({
  selector: 'app-create-layers-bar',
  templateUrl: './create-layers-bar.component.html',
  styleUrls: ['./create-layers-bar.component.scss'],
})
export class CreateLayersBarComponent implements OnInit, OnDestroy {
  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  private editInitTools = EDIT_INIT_TOOLS;
  LAYER_NAMES = LAYER_NAMES;
  LAYER_EVENTS = LAYER_EVENTS;
  activeTool: LAYER_NAMES = LAYER_NAMES.NULL;
  activeEvent: LAYER_EVENTS = LAYER_EVENTS.NULL;
  isBasePlaneCreated = true;
  isEditMode: boolean;
  buttonsDisabled = true;
  sketchToSave: SAVE_SKETCH_TYPE;
  isModelLoaded = false;
  layersLoaded = false;
  permissions = UserPermissions;
  isPlaneEditing$: Observable<boolean>;
  hintsActive$: Observable<boolean>;
  currentHint$: Observable<string | null>;
  actualPopoverIndex: number;
  circleIcon = CIRCLE_ICON;
  lineIcon = LINE_ICON;
  rectangleIcon = RECTANGLE_ICON;
  EDIT_MODE = EDIT_MODE;
  BUTTON_LAYERS: LayerButtonFunc[] = [];

  constructor(
    private broadcastService: BroadcastService,
    private engineService: CustomerEngineService,
    private hintsService: HintsService,
    private layerEventsService: LayerEventsService,
    private layersService: LayersService,
    private viewerLayerService: ViewerLayerService,
    private automappingService: AutomappingService,
    private dialogService: NbDialogService,
    private activeLayerToolService: ActiveLayerToolService,
    private createLayerBarService: CreateLayerBarService,
    private rendersService: RendersService,
    private cdr: ChangeDetectorRef,
    private createLayerButtonService: CreateLayerButtonService,
  ) {
    this.activeLayerToolService.activeTool$.pipe(untilDestroyed(this)).subscribe((activeTool) => {
      this.activeTool = activeTool;
      if (activeTool === LAYER_NAMES.CUBOID_PICKER) {
        this.activeTool = LAYER_NAMES.BOUNDING_BOX;
      }
    });

    this.layerEventsService.activeLayerEvent.pipe(untilDestroyed(this)).subscribe((value) => {
      this.activeEvent = value;
    });

    this.createLayerBarService.isEditMode$.subscribe((editMode) => {
      this.isEditMode = editMode;
    });

    this.broadcastService
      .on(EVENT_TYPE.TAKE_BASE_PLANE)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.rendersService.currentRender$.pipe(take(1)).subscribe((render) => {
          if (render?.automapping) {
            this.dialogService
              .open(ConfirmationDialogComponent, {
                context: {
                  title:
                    'There are some autogenerated cuboids for this model. Would you like to import them now?',
                  status: 'info',
                },
              })
              .onClose.pipe(untilDestroyed(this))
              .subscribe((value) => {
                if (value && render.automapping) {
                  this.automappingService.importMeshes(render.automapping?.downloadURL);
                }
              });
          }
        });
      });

    this.broadcastService
      .on(EVENT_TYPE.CHANGE_BOUNDING_BOX_BUTTON_STATUS)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.activeLayerToolService.setActiveTool(LAYER_NAMES.CUBOID_PICKER);
        this.layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
      });

    this.editInitTools.forEach((tool) => {
      this.generateEditMode(tool);
    });

    this.broadcastService
      .on(EVENT_TYPE.FINISH_SKETCH_PLANE)
      .pipe(untilDestroyed(this))
      .subscribe((val: boolean) => {
        this.createLayerBarService.toggleActiveLayer(
          LAYER_EVENTS.ADD_SKETCH_PLANE,
          LAYER_NAMES.SKETCH_PLANE,
          this.activeTool,
        );
      });

    this.broadcastService
      .on(EVENT_TYPE.ACTIVATE_SAVE_BUTTON)
      .pipe(untilDestroyed(this))
      .subscribe((name: SAVE_SKETCH_TYPE) => {
        this.sketchToSave = name;
        this.buttonsDisabled = false;
      });

    broadcastService
      .on(EVENT_TYPE.REMOVE_ACTIVE_LAYER_BUTTON)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.activeLayerToolService.setActiveTool();
      });

    this.isPlaneEditing$ = this.broadcastService
      .on(EVENT_TYPE.EDIT_SKETCHES)
      .pipe(untilDestroyed(this), pluck('isAddPlaneActive'));
    this.hintsActive$ = this.hintsService.hintsActive$;
    this.currentHint$ = this.hintsService.currentHint$;

    this.layerEventsService.activeLayerEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.hidePopover();
    });
  }

  ngOnInit(): void {
    this.viewerLayerService.layersLoaded$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.layersLoaded = value;
    });
    this.engineService.isModelLoaded$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isModelLoaded = value;
    });
    this.layersService.isBasePlaneCreated$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isBasePlaneCreated = value;
    });
    this.BUTTON_LAYERS = BUTTON_LAYERS_CONFIG.map((config) => {
      return {
        ...config,
        func: this.getFunctionForTool(config.toolName),
      };
    });
  }

  ngOnDestroy() {
    this.activeLayerToolService.setActiveTool();
  }

  generateEditMode(eventType: string) {
    this.broadcastService
      .on(eventType)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.generateManualTool();
      });
  }

  toggleActiveLayer(event: LAYER_EVENTS, name: LAYER_NAMES) {
    this.createLayerButtonService.toggleActiveLayer(event, name);
    this.showToolInfo();
  }

  showToolInfo() {
    if (this.activeTool === LAYER_NAMES.NULL) {
      this.cdr.detectChanges();
      this.hidePopover();
    }
  }

  toggleTakeBasePlane() {
    this.broadcastService.broadcast(EVENT_TYPE.TAKE_BASE_PLANE, null);
  }

  generateManualTool() {
    this.createLayerButtonService.generateManualTool();
  }

  toggleVideo() {
    this.dialogService.open(TutorialVideoPopupComponent, {
      context: { toolEvent: this.activeEvent },
    });
  }

  onSaveClick() {
    this.broadcastService.broadcast(this.sketchToSave, null);
    this.buttonsDisabled = true;
    this.broadcastService.broadcast(EVENT_TYPE.CAMERA_MODE_BUTTON_STATUS, false);
  }

  onCancelClick() {
    this.buttonsDisabled = true;
    this.broadcastService.broadcast(EVENT_TYPE.TAKE_SKETCH_PLANE, false);
    this.broadcastService.broadcast(EVENT_TYPE.CAMERA_MODE_BUTTON_STATUS, false);
  }

  createNewFolder() {
    this.createLayerBarService.createNewFolder();
  }

  clear() {
    this.activeLayerToolService.setActiveTool();
    this.layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
  }

  hidePopover() {
    if (this.actualPopoverIndex >= 0) {
      this.popovers.get(this.actualPopoverIndex)?.hide();
    }
  }

  getFunctionForTool(toolName: string) {
    return this.createLayerButtonService.buttonFunctions[toolName] ?? (() => {});
  }
}
