import { AuthService } from '@App/app/auth/auth.service';
import { LogFileTypes } from '@App/app/entities/files/files-data.model';
import { BaseFile } from '@App/app/entities/files/files.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { IErrorIdentification } from '@App/app/entities/shared/error-identificator.model';
import { ProcessingService } from '@App/app/pages/viewer/processing/processing.service';
import { ConfirmationDialogComponent } from '@App/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, of } from 'rxjs';
import { filter, first, map, pairwise, skip } from 'rxjs/operators';
import {
  archiveProcesses,
  deleteProcesses,
  loadAllProcesses,
  loadCurrentProcessFiles,
} from '../../store/processes/actions/processes.actions';
import { selectAllProcesses } from '../../store/processes/selectors/processes.selectors';

@Injectable({
  providedIn: 'root',
})
export class ModelsProcessingService {
  private _currentBuildProcess = new BehaviorSubject<BuildProcess | null>(null);
  private _currentBuildProcesses = new BehaviorSubject<BuildProcess[] | null>(null);
  private _currentBuildProcessLogs = new BehaviorSubject<{ [key in LogFileTypes]?: string }>({});
  private _areFilesLoaded = new BehaviorSubject(false);
  currentBuildProcess = this._currentBuildProcess.asObservable();
  currentBuildProcesses = this._currentBuildProcesses.asObservable();
  areFilesLoaded = this._areFilesLoaded.asObservable();
  currentBuildProcessLogs = this._currentBuildProcessLogs.asObservable();

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private dialogService: NbDialogService,
    private processingService: ProcessingService,
    private authService: AuthService,
  ) {
    this.currentBuildProcess.pipe(pairwise()).subscribe(([oldProcess, newProcess]) => {
      if (newProcess && newProcess.id !== oldProcess?.id) {
        this.store.dispatch(loadCurrentProcessFiles({ processId: newProcess.id }));
      }
      this._currentBuildProcessLogs.next({});
      this._areFilesLoaded.next(false);
    });

    this.authService.currentEndpoint$.subscribe(() => {
      this._currentBuildProcess.next(null);
      this._currentBuildProcesses.next(null);
      this._currentBuildProcessLogs.next({});
      this._areFilesLoaded.next(false);
    });
  }

  setCurrentBuildProcess(process: BuildProcess | null) {
    this._currentBuildProcess.next(process);
  }

  setCurrentBuildProcesses(processes: BuildProcess[] | null) {
    this._currentBuildProcesses.next(processes);
  }

  setCurrentBuildProcessFiles(files: BaseFile[]) {
    const currProcess = this.getCurrentBuildProcess();
    this.setCurrentBuildProcess(new BuildProcess({ ...currProcess, files }));
    this._areFilesLoaded.next(true);
  }

  setCurrentBuildProcessLog(type: LogFileTypes, value: string) {
    this._currentBuildProcessLogs.next({ ...this._currentBuildProcessLogs.value, [type]: value });
  }

  getCurrentBuildProcess() {
    return this._currentBuildProcess.value;
  }

  clearCurrentBuildProcess() {
    this._currentBuildProcess.next(null);
  }

  isBuildProcessAccessible(id: number) {
    if (!this._currentBuildProcesses.value) {
      this.store.dispatch(loadAllProcesses());
      return this.store.select(selectAllProcesses).pipe(
        skip(1),
        first(),
        // eslint-disable-next-line ngrx/avoid-mapping-selectors
        map((processes) => {
          this.setCurrentBuildProcesses(processes || []);
          const noProc = !processes || !processes.length;
          return noProc ? false : this.getBuildProcessAccessibilityById(id);
        }),
      );
    } else if (!this._currentBuildProcesses.value?.length) {
      return of(false);
    } else {
      return of(this.getBuildProcessAccessibilityById(id));
    }
  }

  private getBuildProcessAccessibilityById(id: number) {
    const process = this._currentBuildProcesses.value?.find((el) => el.id === id);
    return !process
      ? false
      : process.steps.at.isWaiting() ||
          (process.isAccessible() && process.steps.upload.isNotAlready());
  }

  deleteHandler(processes: BuildProcess[]) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Delete Processes',
          details: `Are you sure you want to delete <b>${processes.length}</b> processes? All data related to them will be lost.`,
          status: 'danger',
          keywordMode: true,
          keyword: `permanently delete`,
          useInnerHTML: true,
        },
      })
      .onClose.subscribe((value) => {
        if (value) {
          this.deleteProcesses(processes);
        }
      });
  }

  archiveProcesses(processes: BuildProcess[]) {
    this.spinner.show();
    this.store.dispatch(archiveProcesses({ processes }));
  }

  deleteProcesses(processes: BuildProcess[]) {
    this.spinner.show();
    this.store.dispatch(deleteProcesses({ processesId: processes.map((process) => process.id) }));
  }

  openDeleteProcessesConfirmationDialog(
    deletableProcesses: IErrorIdentification[],
    indelibleProcesses: IErrorIdentification[],
    deletedProcesses: IErrorIdentification[],
  ) {
    const deledProcessesName = deletedProcesses.map((item) => item.name).join(', ');
    const deletableProcessName = deletableProcesses.map((item) => item.name).join(', ');
    const indelibleProcessesName = indelibleProcesses.map((item) => item.name).join(', ');

    let detailMessage = '';
    if (deletedProcesses.length) {
      detailMessage += `Processes <b>${deledProcessesName}</b> are already deleted.\n`;
    }
    if (indelibleProcesses.length) {
      detailMessage += `Processes <b>${indelibleProcessesName}</b> cannot be deleted.\n`;
    }
    if (deletableProcesses.length) {
      detailMessage += `Processes <b>${deletableProcessName}</b> are already accepted, but can be deleted after confirmation.\n`;
    }

    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Delete Processes',
          details: detailMessage,
          status: 'danger',
          useInnerHTML: true,
          showConfirmButton: !!deletableProcesses.length,
        },
      })
      .onClose.pipe(filter(Boolean))
      .subscribe(() => {
        this.spinner.show();
        this.store.dispatch(
          deleteProcesses({
            processesId: deletableProcesses.map((process) => process.id),
            force: true,
          }),
        );
      });
  }

  waitForLogsToBeLoaded() {
    return new Promise<void>(async (resolve) => {
      const process = this.processingService.buildProcess as BuildProcess;
      const logFiles = [process.atLog, process.prodLog];
      for await (const file of logFiles) {
        if (file) {
          await this.currentBuildProcessLogs
            .pipe(map((logs) => logs[(file.type as unknown) as LogFileTypes]))
            .pipe(filter(Boolean), first())
            .toPromise();
        }
      }
      resolve();
    });
  }
}
