import { RESOURCE_IS_GENERATING, SUCCESS, WARNING } from '@App/app/configs/toastr-events.config';
import {
  INFINITE_SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { ExportsHttpService } from '@App/app/engine/services/exports-http-service/exports-http.service';
import { RendersService } from '@App/app/pages/models/services/renders-service/renders.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import formatUnicorn from 'format-unicorn/safe';

@Injectable()
export class ObjExportService {
  constructor(
    private _rendersService: RendersService,
    private _exportsHttpService: ExportsHttpService,
    private _toastrService: NbToastrService,
  ) {}

  export() {
    const renderId = this._rendersService.getCurrentRenderId();
    if (!renderId) {
      return;
    }

    this._exportsHttpService.postObjExport(renderId).subscribe(
      () => {
        this._toastrService.show(
          formatUnicorn(RESOURCE_IS_GENERATING, { resourceName: '.obj bundle' }),
          SUCCESS,
          INFINITE_SUCCESS_TOASTR_CONFIG,
        );
      },
      (error) => {
        this._toastrService.show(error.error.message, WARNING, WARNING_TOASTR_CONFIG);
      },
    );
  }
}
