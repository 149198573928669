import { Observable } from 'rxjs';
import { HintsService } from './hints.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-hints',
  templateUrl: './hints.component.html',
  styleUrls: ['./hints.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HintsComponent implements OnInit {
  currentHint$: Observable<string | null>;

  constructor(private hintsSevice: HintsService) {
    this.currentHint$ = this.hintsSevice.currentHint$;
  }

  ngOnInit(): void {}

  toggleHints(): void {
    this.hintsSevice.toggleHints();
  }
}
