import { LAYER_TYPES } from './enums/layer-types.enum';
import { Layer } from './layer.model';

export class Folder implements Layer<FolderData> {
  id: number;
  name: string;
  createdAt: string;
  editedAt: string;
  data: FolderData = {
    isRoot: false,
  };
  parentId: number;
  type = LAYER_TYPES.FOLDERS;
  isVisible = false;
  isSaved = true;
  open: boolean;
}

export interface FolderData {
  isRoot: boolean;
  renderId?: number;
}
