import * as toastrEvents from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import {
  RectType,
  getAngleIronDimensions,
  getDimensions,
} from '@App/app/engine/helpers/rectangle-tools-helpers';
import { AngleIron } from '@App/app/entities/layer/angle-iron.model';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Vector3 } from 'babylonjs';
import { toImperialLength } from '../../utils/units-converter';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private _clipboard: Clipboard, private _toastrService: NbToastrService) {}

  copyCoords(coords: Vector3) {
    this._copyCoords(coords);
    this._toastrService.show(toastrEvents.COORDS_COPIED, toastrEvents.SUCCESS, {
      ...SUCCESS_TOASTR_CONFIG,
    });
  }

  copyPosition(coords: Coordinates3) {
    this._copyCoords(coords);
    this._toastrService.show(toastrEvents.POSITION_COPIED, toastrEvents.SUCCESS, {
      ...SUCCESS_TOASTR_CONFIG,
    });
  }

  copyGeoCoords({ latitude, longitude }: GeoCoords) {
    this._clipboard.copy(`lat: ${latitude}, lng: ${longitude}`);
    this._toastrService.show(toastrEvents.COORDS_COPIED, toastrEvents.SUCCESS, {
      ...SUCCESS_TOASTR_CONFIG,
    });
  }

  copyAngleIronDimensions(layer: AngleIron, unitsSystem: UnitsSystem) {
    const { boxes } = layer.data;
    if (boxes?.length) {
      const dimensions = getAngleIronDimensions(boxes);
      if (unitsSystem === UnitsSystem.Metric) {
        this._clipboard.copy(
          `length: ${dimensions.length}m, leg1: ${dimensions.leg1}m, leg2: ${dimensions.leg2}m, thickness: ${dimensions.thickness}m`,
        );
      } else if (unitsSystem === UnitsSystem.Imperial) {
        this._clipboard.copy(
          `length: ${toImperialLength(dimensions.length)}, leg1: ${toImperialLength(
            dimensions.leg1,
          )}, leg2: ${toImperialLength(dimensions.leg2)}, thickness: ${toImperialLength(
            dimensions.thickness,
          )}`,
        );
      }
      this._toastrService.show(toastrEvents.DIMENSIONS_COPIED, toastrEvents.SUCCESS, {
        ...SUCCESS_TOASTR_CONFIG,
      });
    } else {
      this._toastrService.show(toastrEvents.CANNOT_FIND_DIMENSIONS, toastrEvents.FAILED, {
        ...WARNING_TOASTR_CONFIG,
      });
    }
  }

  copyRectLayerDimensions(layer: RectType, unitsSystem: UnitsSystem) {
    const dimensions = getDimensions(layer);
    if (unitsSystem === UnitsSystem.Metric) {
      this._clipboard.copy(
        `width: ${dimensions.width}m, height: ${dimensions.height}m, depth: ${dimensions.depth}m`,
      );
    } else if (unitsSystem === UnitsSystem.Imperial) {
      this._clipboard.copy(
        `width: ${toImperialLength(dimensions.width)}, height: ${toImperialLength(
          dimensions.height,
        )}, depth: ${toImperialLength(dimensions.depth)}`,
      );
    }
    this._toastrService.show(toastrEvents.DIMENSIONS_COPIED, toastrEvents.SUCCESS, {
      ...SUCCESS_TOASTR_CONFIG,
    });
  }

  private _copyCoords(coords: Vector3 | Coordinates3) {
    const { x, y, z } = coords;
    this._clipboard.copy(`{x: ${x}, y: ${y}, z: ${z}}`);
  }
}
