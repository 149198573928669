import { ExtendedRender, Render } from '@App/app/entities/processing/render.model';
import { loadAllPhotosSuccess } from '@App/app/shared/store/photos/actions/photos.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { loadAllRenders, loadCurrentRender } from '../../store/renders/actions/renders.actions';
import { selectAllRenders } from '../../store/renders/selectors/renders.selectors';
import { RendersHttpService } from '../renders-http-service/renders-http.service';

@Injectable({
  providedIn: 'root',
})
export class RendersService {
  private _currentRenderId$ = new BehaviorSubject<number | null>(null);
  private _currentRender$ = new BehaviorSubject<ExtendedRender | null>(null);
  private _renders$ = new BehaviorSubject<Render[] | null>(null);
  currentRenderId$ = this._currentRenderId$.asObservable();
  currentRender$ = this._currentRender$.asObservable();
  renders$ = this._renders$.asObservable();

  constructor(private store: Store, private rendersHttpService: RendersHttpService) {
    this.store
      .select(selectAllRenders)
      .pipe(skip(1))
      .subscribe((renders) => this.setRenders(renders));

    this._currentRender$.subscribe((currentRender) => {
      this.store.dispatch(loadAllPhotosSuccess({ photos: currentRender?.photos || [] }));
    });
  }

  loadAllRenders(modelId: number) {
    this.store.dispatch(loadAllRenders({ modelId }));
  }

  loadCurrentRender(modelId: number, renderId: number) {
    this.store.dispatch(loadCurrentRender({ modelId, renderId }));
  }

  getAnnotationsReport(modelId: number, renderId: number) {
    return this.rendersHttpService.getAnnotationsReport(modelId, renderId);
  }

  setCurrentRenderId(value: number | null) {
    this._currentRenderId$.next(value);
  }

  getCurrentRenderId() {
    return this._currentRenderId$.value;
  }

  setCurrentRender(value: ExtendedRender | null) {
    this._currentRender$.next(value);
  }

  getCurrentRender() {
    return this._currentRender$.value;
  }

  setRenders(value: Render[] | null) {
    this._renders$.next(value);
  }

  getRenders() {
    return this._renders$.value;
  }

  resetAll() {
    this.setCurrentRender(null);
    this.setCurrentRenderId(null);
    this.setRenders(null);
  }
}
