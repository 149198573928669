import {
  FAILED,
  LAYERS_PROP_FAIL,
  LAYERS_PROP_SUCCESS,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import { LayerUtilsService } from '@App/app/engine/services/layer-services/layer-utils-service/layer-utils.service';
import { httpLayer } from '@App/app/entities/layer/layer.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '../../../../configs/toastr-messages.config';
import { LayerHttpService } from '../../../../engine/services/layer-services/layer-http-service/layer-http.service';
import { LayersService } from '../../services/layers/layers.service';
import { changeLayersProp, changeLayersPropSuccess, updateLayers } from '../actions/layers.actions';
import { selectAllLayers } from '../selectors/layers.selectors';

@Injectable()
export class ChangeLayersPropEffects {
  constructor(
    private actions: Actions,
    private layerHttpService: LayerHttpService,
    private layerUtilsService: LayerUtilsService,
    private toastrService: NbToastrService,
    private layersService: LayersService,
    private store: Store,
  ) {}

  changeLayersProp = createEffect(
    () => {
      return this.actions.pipe(
        ofType(changeLayersProp),
        withLatestFrom(this.store.select(selectAllLayers)),
        mergeMap(([{ ids, prop }, layers]) => {
          this.layersService.setLoading(true);
          const { key, value } = prop;
          const data = ids.map((id) => {
            const layerToUpdate = layers.find((layer) => layer.id === id);
            if (!layerToUpdate) {
              return null;
            }
            const body = this.layerUtilsService.createRequestBody(layerToUpdate);
            body.data = layerToUpdate.data;
            (body[key as keyof httpLayer] as typeof value) = value;
            return body;
          });
          const nonNullData = data.filter((i) => !!i) as httpLayer[];
          return this.layerHttpService.putBatchLayers(nonNullData).pipe(
            map((res) => {
              return changeLayersPropSuccess({
                layers: res,
                key,
              });
            }),
            catchError(() => {
              this.layersService.setLoading(false);
              this.toastrService.show(LAYERS_PROP_FAIL, FAILED, {
                ...WARNING_TOASTR_CONFIG,
              });
              return EMPTY;
            }),
          );
        }),
      );
    },
    { dispatch: true },
  );

  changeLayersPropSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(changeLayersPropSuccess),
      map(({ layers, key }) => {
        this.layersService.setLoading(false);
        this.toastrService.show(LAYERS_PROP_SUCCESS, SUCCESS, {
          ...SUCCESS_TOASTR_CONFIG,
        });
        const updates = layers.map((layer) => {
          return {
            id: layer.id,
            changes: { data: layer.data, [key]: layer[key] },
          };
        });
        return updateLayers({ updates });
      }),
    );
  });
}
