import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Photo } from '@App/app/entities/files/files.model';
@Component({
  selector: 'app-photos-carousel-image',
  templateUrl: './photos-carousel-image.component.html',
  styleUrls: ['./photos-carousel-image.component.scss'],
})
export class PhotosCarouselImageComponent {
  @Input() photo: Photo;
  @Input() activePhotoId: number;
  @Input() downloadingPhotoIds: number[];
  @Output() selectPhoto: EventEmitter<number> = new EventEmitter();
  @Output() closePhoto: EventEmitter<number> = new EventEmitter();
  @Output() togglePhoto: EventEmitter<number> = new EventEmitter();
  @Output() openPhoto: EventEmitter<number> = new EventEmitter();
  @Output() download: EventEmitter<number> = new EventEmitter();
  isLoaded = false;
  isLoading = false;
  isPhotoClosed = false;

  isSelected() {
    return +this.activePhotoId === +this.photo.id;
  }

  isDownloading() {
    return this.downloadingPhotoIds.includes(+this.activePhotoId);
  }

  selectPhotoId(event: Event) {
    event.stopPropagation();
    if (this.activePhotoId !== this.photo.id) {
      this.isPhotoClosed = false;
      this.selectPhoto.emit(this.photo.id);
    } else {
      this.isPhotoClosed = !this.isPhotoClosed;
    }

    if (this.isPhotoClosed) {
      this.closePhotoId(event);
    } else {
      this.openPhotoId(event);
    }
  }

  closePhotoId(event: Event) {
    event.stopPropagation();
    this.closePhoto.emit(this.photo.id);
    this.isPhotoClosed = true;
  }

  openPhotoId(event: Event) {
    event.stopPropagation();
    this.openPhoto.emit(this.photo.id);
    this.isPhotoClosed = false;
  }

  downloadPhoto(event: Event) {
    event.stopPropagation();
    this.download.emit(this.photo.id);
  }

  startLoadingIfNotStarted() {
    if (!this.isLoading && !this.isLoaded) {
      this.isLoading = true;
    }
  }

  hasLoaded() {
    this.isLoading = false;
    this.isLoaded = true;
  }
}
