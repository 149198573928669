import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-target-photo-dialog',
  templateUrl: './target-photo-dialog.component.html',
  styleUrls: ['./target-photo-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetPhotoDialogComponent {
  @ViewChild('viewfinder', { static: true })
  private _viewfinder: ElementRef<HTMLDivElement>;
  @ViewChild('container', { static: true })
  private _container: ElementRef<HTMLDivElement>;

  update(x: number, y: number) {
    this._updateContainerGridStyle(x, y);
  }

  private _updateContainerGridStyle(x: number, y: number) {
    const vfDim = this._getViewfinderDimension();
    const { style } = this._container.nativeElement;
    style.setProperty('grid-template-columns', `calc(${x - vfDim / 2}px + 5rem) ${vfDim}px 100%`);
    style.setProperty('grid-template-rows', `calc(${y - vfDim / 2}px + 5rem) ${vfDim}px 100%`);
  }

  private _getViewfinderDimension() {
    return this._viewfinder.nativeElement.offsetWidth;
  }
}
