import { FAILED, LAYER_CREATE_FAIL } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { CreationToolError } from '@App/app/shared/exceptions/creation-tool-error';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Matrix, Mesh, PickingInfo, Plane, Ray, Vector3, VertexBuffer } from 'babylonjs';
import { Subject } from 'rxjs';
import { MEASUREMENT_PRECISION } from 'src/app/configs/babylon.config';
import { modelMeshPredicate } from '../../helpers/layers-helpers';
import { SceneService } from '../scene-service/scene.service';

type customPredicate = (Mesh: Mesh) => boolean;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private modelId: number;
  updateActiveLayerDetails$ = new Subject();

  constructor(private sceneService: SceneService, private toastrService: NbToastrService) {}

  median(arr: number[]) {
    const mid = Math.floor(arr.length / 2);
    const numbers = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? numbers[mid] : (numbers[mid - 1] + numbers[mid]) / 2;
  }

  medianVector(vectors: Vector3[]) {
    const x = this.median(vectors.map((pos) => pos.x));
    const y = this.median(vectors.map((pos) => pos.y));
    const z = this.median(vectors.map((pos) => pos.z));
    return new Vector3(x, y, z);
  }

  getLengthBetweenPoints(positionA: Vector3, positionB: Vector3): number {
    // TODO: In the future make possible to user to chose which precision he wants to use
    return (
      +Vector3.Distance(positionA, positionB).toFixed(
        length > MEASUREMENT_PRECISION.precision ? 2 : 5,
      ) || 0
    );
  }

  pickRay(customPredicate?: customPredicate): PickingInfo | null {
    const planes: Plane[] = [];
    if (this.sceneService.scene.clipPlane2) planes.push(this.sceneService.scene.clipPlane2);
    if (this.sceneService.scene.clipPlane3) planes.push(this.sceneService.scene.clipPlane3);
    let world: Matrix;

    const isPickablePredicate = (mesh: Mesh): boolean => {
      if (mesh.isPickable) {
        world = mesh.computeWorldMatrix();
      }

      return customPredicate
        ? !!(mesh.isPickable && customPredicate(mesh))
        : mesh.isPickable && modelMeshPredicate(mesh);
    };

    const trianglePredicate = (p0: Vector3, p1: Vector3, p2: Vector3, ray: Ray) => {
      if (planes.length === 0) {
        return true;
      }

      const intersectInfo = ray.intersectsTriangle(p0, p1, p2);
      if (!intersectInfo) {
        return false;
      }

      const worldOrigin = Vector3.TransformCoordinates(ray.origin, world);
      const direction = ray.direction.scale(intersectInfo.distance);
      const worldDirection = Vector3.TransformNormal(direction, world);
      const pickedPoint = worldDirection.addInPlace(worldOrigin);
      for (const plane of planes) {
        if (plane.signedDistanceTo(pickedPoint) > 0) {
          return false;
        }
      }
      return true;
    };

    const ray = this.sceneService.scene.createPickingRay(
      this.sceneService.scene.pointerX,
      this.sceneService.scene.pointerY,
      Matrix.Identity(),
      null,
    );

    const pickResult = this.sceneService.scene.pickWithRay(
      ray,
      isPickablePredicate,
      false,
      trianglePredicate,
    );
    return pickResult && !this.isMeshOrPointPicked(pickResult) ? null : pickResult;
  }

  findMiddlePointOfTwoMeshes(positionA: Vector3, positionB: Vector3) {
    const x = (positionA.x + positionB.x) / 2;
    const y = (positionA.y + positionB.y) / 2;
    const z = (positionA.z + positionB.z) / 2;

    return new Vector3(x, y, z);
  }

  getTriangleCombinations(vectors: Vector3[]): [Vector3, Vector3, Vector3][] {
    const results: [Vector3, Vector3, Vector3][] = [];
    for (let i = 0; i < vectors.length - 2; i++) {
      for (let j = i + 1; j < vectors.length - 1; j++) {
        for (let k = j + 1; k < vectors.length; k++) {
          results.push([vectors[i], vectors[j], vectors[k]]);
        }
      }
    }
    return results;
  }

  getCircleData(positions: [Vector3, Vector3, Vector3]): [Vector3, number] {
    const t = positions[1].subtract(positions[0]);
    const u = positions[2].subtract(positions[0]);
    const v = positions[2].subtract(positions[1]);

    const w = Vector3.Cross(t, u);
    const wsl = Vector3.Dot(w, w);
    const iwsl2 = 1 / (2 * wsl);
    const tt = Vector3.Dot(t, t);
    const uu = Vector3.Dot(u, u);

    const p1 = u.scale(tt * Vector3.Dot(u, v));
    const p2 = t.scale(uu * Vector3.Dot(t, v));
    const circCenter = positions[0].add(p1.subtract(p2).scale(iwsl2));
    const circRadius = Math.sqrt(tt * uu * Vector3.Dot(v, v) * iwsl2 * 0.5);
    return [circCenter, circRadius];
  }

  setModelId(id: number) {
    this.modelId = id;
  }

  getModelId() {
    return this.modelId;
  }

  getRawVertices(mesh: Mesh): [number, number, number][] {
    const data = mesh.getVerticesData(VertexBuffer.PositionKind);
    const vertices: [number, number, number][] = [];
    if (data) {
      for (let i = 0; i < data.length; i += 3) {
        const point: [number, number, number] = [data[i], data[i + 1], data[i + 2]];
        vertices.push(point);
      }
    }
    return vertices;
  }

  getVectorVertices(mesh: Mesh): Vector3[] {
    const rawVertices = this.getRawVertices(mesh);
    return rawVertices.map((vertex) => new Vector3(vertex[0], vertex[1], vertex[2]));
  }

  catchToolCreationError(error: Error) {
    const msg = error instanceof CreationToolError ? error.message : LAYER_CREATE_FAIL;
    this.toastrService.show(msg, FAILED, { ...WARNING_TOASTR_CONFIG });
  }
  split<T>(array: T[], n: number) {
    const [...arr] = array;
    const res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  }

  private isMeshOrPointPicked(pickResult: PickingInfo) {
    return pickResult.pickedMesh !== null || pickResult.pickedPoint !== null;
  }
}
