import { Annotation } from './annotation.model';
import {
  ATLogData,
  ATMeshData,
  AutomappingData,
  FileData,
  FileTypes,
  FlatFileData,
  GCPData,
  PhotoData,
  ProdLogData,
  ThumbnailData,
  TiePointsData,
  TileData,
  TileSData,
} from './files-data.model';

export class UnsavedFile {
  type: FileTypes;
  name: string;
}

export class FileHttp<T extends FileData = FileData> extends UnsavedFile {
  id?: number;
  data: T;
}

export abstract class BaseFile<T extends FileData = FileData> extends FileHttp<T> {
  static type: FileTypes;
  id: number;
  downloadURL: string;
  uploadURL: string;
  isUploaded: boolean;

  constructor(props: Partial<BaseFile<T>>) {
    super();
    if (props.data) {
      props.data = { ...props.data };
    }
    Object.assign(this, props);
  }

  static fromHttpFiles(httpFiles: BaseFile[]): BaseFile[] {
    return httpFiles.reduce((res, file) => {
      const cls = FILE_CLASSES.find((c: any) => c.type === file.type);
      return cls ? [...res, new cls({ ...file })] : [...res];
    }, []);
  }
}

export class Tile extends BaseFile<TileData> {
  static type = FileTypes.TILE;
}

export class SimplifiedTile extends BaseFile<TileSData> {
  static type = FileTypes.SIMPLIFIED_TILE;
}

export class GCPFile extends BaseFile<GCPData> {
  static type = FileTypes.GCP;
}

export class FlatFile extends BaseFile<FlatFileData> {
  static type = FileTypes.FLAT_FILE;
}

export class ATLog extends BaseFile<ATLogData> {
  static type = FileTypes.AT_LOG;
}

export class ProdLog extends BaseFile<ProdLogData> {
  static type = FileTypes.PROD_LOG;
}

export class Automapping extends BaseFile<AutomappingData> {
  static type = FileTypes.AUTOMAPPING;
}

export class TiePoints extends BaseFile<TiePointsData> {
  static type = FileTypes.TIE_POINTS;
}

export class GCPList extends BaseFile<GCPData> {
  static type = FileTypes.GCP_RELATIVE;
}

export class ATMesh extends BaseFile<ATMeshData> {
  static type = FileTypes.AT_MESH;
}

export class Thumbnail extends BaseFile<ThumbnailData> {
  static type = FileTypes.THUMBNAIL;
  modelId?: number;
}

export class OrthoDSM extends BaseFile<FileData> {
  static type = FileTypes.ORTHO_DSM;
}

export class PointCloud extends BaseFile<FileData> {
  static type = FileTypes.POINT_CLOUD;
}

export class Photo extends BaseFile<PhotoData> {
  static type = FileTypes.PHOTO;

  constructor(props: Partial<Photo>) {
    const data = {
      ...props.data,
      annotations:
        props.data?.annotations?.map(({ title, description, priority, min, max }) => {
          const ann = new Annotation();
          ann.update(min, max, title, description, priority);
          return ann;
        }) || [],
    } as PhotoData;
    super({ ...props, data });
  }

  validate() {
    return (
      this.data.position && this.data.rotation && this.isPositionValid() && this.isRotationValid()
    );
  }

  copyAnnotations(): Annotation[] {
    return this.data.annotations.map((a) => a.copy());
  }

  isPositionValid() {
    const { position } = this.data;
    return position.x !== null && position.y !== null && position.z !== null;
  }

  isRotationValid() {
    const { rotation } = this.data;
    return rotation.pitch !== null && rotation.roll !== null && rotation.yaw !== null;
  }
}

export const FILE_CLASSES = [
  Tile,
  SimplifiedTile,
  GCPFile,
  FlatFile,
  ATLog,
  ProdLog,
  Automapping,
  TiePoints,
  ATMesh,
  Thumbnail,
  Photo,
  GCPList,
  OrthoDSM,
  PointCloud,
];
