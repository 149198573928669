<div class="layer_details__label">Thickness:</div>
<div class="layer_details__value">
  <app-layer-thickness-input
    *ngIf="layer | isLayerBeingEdited; else overview"
    [layer]="layer"
  ></app-layer-thickness-input>
  <ng-template #overview>
    <app-layer-details-optional-measurement-field
      [value]="value"
      [unitsSystem]="unitsSystem"
      type="length"
    ></app-layer-details-optional-measurement-field>
  </ng-template>
</div>
