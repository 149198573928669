import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbTagModule,
  NbThemeModule,
  NbToggleModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';

@NgModule({
  imports: [
    CommonModule,
    NbThemeModule.forRoot({ name: 'skyportal-theme' }),
    NbCardModule,
    NbLayoutModule,
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbInputModule,
    NbButtonModule,
    NbListModule,
    NbTagModule,
    NbTreeGridModule,
    NbAlertModule,
    NbActionsModule,
    NbTabsetModule,
    NbDialogModule.forRoot(),
    NbSpinnerModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    NbUserModule,
    NbContextMenuModule,
    NbEvaIconsModule,
    NbIconModule,
    NbAccordionModule,
    NbPopoverModule,
    NbCheckboxModule,
    NbBadgeModule,
    NbDatepickerModule.forRoot(),
    NbAutocompleteModule,
    NbToggleModule,
    NbProgressBarModule,
    NbFormFieldModule,
  ],
  exports: [
    NbThemeModule,
    NbCardModule,
    NbLayoutModule,
    NbSidebarModule,
    NbInputModule,
    NbButtonModule,
    NbListModule,
    NbTagModule,
    NbTreeGridModule,
    NbAlertModule,
    NbActionsModule,
    NbTabsetModule,
    NbDialogModule,
    NbSpinnerModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    NbUserModule,
    NbContextMenuModule,
    NbIconModule,
    NbEvaIconsModule,
    NbMenuModule,
    NbAccordionModule,
    NbPopoverModule,
    NbCheckboxModule,
    NbBadgeModule,
    NbDatepickerModule,
    NbAutocompleteModule,
    NbToggleModule,
    NbProgressBarModule,
    NbFormFieldModule,
  ],
})
export class NebularModule {}
