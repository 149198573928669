import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromModel from '../reducers/models.reducer';
import { State, modelsAdapter } from '../reducers/models.reducer';

export const getModelsState = createFeatureSelector<State>('models');

const { selectAll } = modelsAdapter.getSelectors(getModelsState);

export const selectAllModels = selectAll;

export const selectModelsState = createFeatureSelector<fromModel.State>('models');

export const selectTotalCount = createSelector(selectModelsState, fromModel.getTotalCount);

export const selectCurrentModel = createSelector(selectModelsState, fromModel.getCurrentModel);

export const selectCurrentModelLocation = createSelector(
  selectModelsState,
  fromModel.getCurrentModelLocation,
);

export const selectCurrentModelLocationIsSpecified = createSelector(
  selectModelsState,
  fromModel.getCurrentModelLocationIsSpecified,
);

export const selectModels = (archived: boolean) =>
  createSelector(selectAll, (entities) =>
    entities.filter((entity) => entity.isArchived === archived),
  );

export const selectModelById = (id: number) =>
  createSelector(selectAll, (entities) => entities.find((entity) => +entity.id === id));
