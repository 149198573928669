import { BaseFile, FileHttp } from '@App/app/entities/files/files.model';
import { ExtendedRender, Render } from '@App/app/entities/processing/render.model';
import { createAction, props } from '@ngrx/store';

export const loadAllRenders = createAction(
  '[Renders] Load all renders',
  props<{ modelId: number }>(),
);

export const loadAllRendersSuccess = createAction(
  '[Renders] Load all renders success',
  props<{ renders: Render[] }>(),
);

export const loadCurrentRender = createAction(
  '[Renders] Load current render',
  props<{ modelId: number; renderId: number }>(),
);

export const loadCurrentRenderSuccess = createAction(
  '[Renders] Load current render success',
  props<{ render: ExtendedRender }>(),
);

export const updateCurrentRenderFiles = createAction(
  '[Renders] Update current render files',
  props<{ files: FileHttp[] }>(),
);

export const updateCurrentRenderFilesSuccess = createAction(
  '[Renders] Update current render files success',
  props<{ files: BaseFile[] }>(),
);
