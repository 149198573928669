import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { CompanySelectionService } from '@App/app/shared/services/company-selection/company-selection.service';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { selectEndpoints } from '../store/auth/selectors/auth.selectors';

@Component({
  selector: 'app-company-selection-drop-down',
  templateUrl: './company-selection-drop-down.component.html',
  styleUrls: ['./company-selection-drop-down.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySelectionDropDownComponent implements OnInit {
  endpoints: Endpoint[];
  buttonAppears = true;
  searchText: string;
  permissions = UserPermissions;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private companySelectionService: CompanySelectionService,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectEndpoints)
      .pipe(take(1))
      .subscribe((endpoints) => {
        this.endpoints = endpoints;
      });

    this.route.queryParams.subscribe((params) => {
      if (params['freshLogin']) {
        this.buttonAppears = false;
      }
    });
  }

  onClick(endpoint: Endpoint) {
    this.companySelectionService.onClick(endpoint);
  }

  onAdminPanelClick() {
    this.companySelectionService.onAdminPanelClick();
  }
}
