import { CUSTOM_ICONS_PACK } from '@App/app/configs/app.config';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { NbMenuItem } from '@nebular/theme';
import { ExportableItemData } from '../models/exportable-item-data.model';

export const getExportOptions = (
  render: ExtendedRender | null,
  checkPermission: (permission: string) => boolean,
  onObjExport: () => void,
  onCSVExport: () => void,
  onDXFExport: () => void,
): NbMenuItem[] => {
  const options: NbMenuItem[] = [
    {
      title: 'Export model .obj files',
      icon: 'cube-outline',
      data: { click: onObjExport } as ExportableItemData,
    },
    {
      title: 'Export all layers to .dxf file',
      icon: {
        pack: CUSTOM_ICONS_PACK,
        icon: 'dxf-label',
      },
      data: { click: onDXFExport } as ExportableItemData,
    },
  ];
  if (checkPermission(UserPermissions.CAN_EXPORT_MAPPINGS_TO_CSV)) {
    options.push({
      title: 'Export mapping data to .csv',
      icon: {
        pack: CUSTOM_ICONS_PACK,
        icon: 'cuboid',
      },
      data: { click: onCSVExport } as ExportableItemData,
    });
  }

  if (render?.pointCloud?.isUploaded) {
    options.push({
      title: 'Export Point Cloud as .las files',
      icon: 'keypad-outline',
      data: { click: () => window.open(render.pointCloud?.downloadURL) } as ExportableItemData,
    });
  }

  if (render?.orthoDSM?.isUploaded) {
    options.push({
      title: 'Export Orthophoto/DSM',
      icon: 'map-outline',
      data: { click: () => window.open(render.orthoDSM?.downloadURL) } as ExportableItemData,
    });
  }
  return options;
};
