<div class="center">
  <nb-card class="company-selection">
    <nb-card-header
      ><input
        style="width: 100%;"
        type="search"
        [(ngModel)]="searchText"
        nbInput
        placeholder="Search company"
        id="company-search"
    /></nb-card-header>
    <nb-card-body *ngIf="endpoints | filterByName: searchText as filteredEndpoints">
      <nb-list *ngIf="filteredEndpoints.length; else noCompany">
        <nb-list-item *ngFor="let endpoint of filteredEndpoints" (click)="onClick(endpoint)">
          <app-company-selection-row [endpoint]="endpoint"></app-company-selection-row>
        </nb-list-item>
      </nb-list>
      <ng-template #noCompany>
        <p class="company-selection__no_company">No companies</p>
      </ng-template>
    </nb-card-body>
  </nb-card>
  <nb-card
    class="admin_selector"
    data-testId="admin-panel"
    *ngxPermissionsOnly="permissions.CAN_ACCESS_ADMIN_PANEL"
  >
    <nb-card-body class="admin_selector__company-selection">
      <nb-list>
        <nb-list-item (click)="onAdminPanelClick()" class="admin_selector__item">
          <h6 class="admin_selector__title">Admin Panel</h6>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>
</div>
