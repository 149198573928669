import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RegionOfInterestHttpService } from '../../services/region-of-interest-http-service/region-of-interest-http.service';
import {
  deleteRoiLimit,
  deleteRoiLimitFailure,
  deleteRoiLimitSuccess,
} from '../actions/limits.actions';

@Injectable()
export class DeleteLimitEffects {
  constructor(
    private actions: Actions,
    private regionOfInterestHttpService: RegionOfInterestHttpService,
  ) {}

  deleteLimit = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteRoiLimit),
      mergeMap(({ id }) => {
        return this.regionOfInterestHttpService.deleteRoiLimit(id).pipe(
          map(() => {
            return deleteRoiLimitSuccess({ id });
          }),
          catchError(() => of(deleteRoiLimitFailure())),
        );
      }),
    );
  });
}
