import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_EVENTS } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { ActiveLayerToolService } from '../../../services/active-layer-tool/active-layer-tool.service';
import { LayersService } from '../../../services/layers/layers.service';
import { LayerDetailsUtilsService } from '../layer-details-utils-service/layer-details-utils.service';

@UntilDestroy()
@Injectable()
export class LayerDetailsService {
  private _isAddPlaneActive$ = new BehaviorSubject(false);
  isAddPlaneActive$ = this._isAddPlaneActive$.asObservable();
  private _isDeleteActive$ = new BehaviorSubject(false);
  isDeleteActive$ = this._isDeleteActive$.asObservable();
  private _isEditChildrenActive$ = new BehaviorSubject(false);
  isEditChildrenActive$ = this._isEditChildrenActive$.asObservable();
  private _childLayers$ = new BehaviorSubject<LayerUI[]>([]);
  childLayers$ = this._childLayers$.asObservable();
  private _activeDeletes$ = new BehaviorSubject<LayerUI[]>([]);
  activeDeletes$ = this._activeDeletes$.asObservable();
  private _recalculatedLayers: LayerUI[] = [];

  constructor(
    private _layerEventsService: LayerEventsService,
    private _layersService: LayersService,
    private _viewerLayerService: ViewerLayerService,
    private _broadcastService: BroadcastService,
    private _layerDetailsUtilsService: LayerDetailsUtilsService,
    private _engineService: CustomerEngineService,
    private _activeLayerToolService: ActiveLayerToolService,
  ) {
    this._layersService.recalculatedLayers$.pipe(untilDestroyed(this)).subscribe((layers) => {
      this._recalculatedLayers = layers;
    });
  }

  setIsAddPlaneActive(value: boolean) {
    this._isAddPlaneActive$.next(value);
  }

  setIsEditChildrenActive(value: boolean) {
    this._isEditChildrenActive$.next(value);
  }

  setChildLayers(value: LayerUI[]) {
    this._childLayers$.next(value);
  }

  editClicked(layer: LayerUI) {
    if (this._layerDetailsUtilsService.isSketchPlane(layer)) {
      this._editChildrenClicked(layer);
    }

    if (this._isRecalculateActive(layer)) {
      this._layerDetailsUtilsService.recalculateCanceled();
    }
    this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    if (this._engineService.isEditMode()) {
      this._activeLayerToolService.activateEditMode(true);
      this._layersService.createBackupLayer(layer);

      this._layersService.setLayerEditMode(layer, true);
    } else {
      this._layerDetailsUtilsService.showNoEditionToast();
    }
  }

  editCanceled(layer: LayerUI): void {
    if (this._layerDetailsUtilsService.isSketchPlane(layer)) {
      this._editChildrenCanceled(layer);
    }

    const backupData = this._viewerLayerService.getBackupData(layer.id);
    if (backupData) {
      layer.data = backupData;
    }

    this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    this._layersService.setLayerEditMode(layer, false);
    this._layersService.loadBackupLayer(layer.id);
    this._activeLayerToolService.activateEditMode(false, layer);
    this._filterEditedLayers(layer.id);
  }

  editConfirmed(layer: LayerUI, name: string | null, description: string | null) {
    if (this._layerDetailsUtilsService.isSketchPlane(layer)) {
      this._editChildrenConfirmed(layer);
    }
    if (
      this._layerDetailsUtilsService.hasThickness(layer) &&
      !this._layerDetailsUtilsService.isThicknessFormValid(layer)
    ) {
      return;
    }
    this._layersService.setLayerEditMode(layer, false);
    this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    this._layerDetailsUtilsService.assignFormPropsToLayer(layer, name, description);
    if (layer.isSaved) {
      this._filterEditedLayers(layer.id);
      this._layersService.updateLayer(layer);
    } else {
      this._layersService.saveLayer(layer);
    }
    this._activeLayerToolService.activateEditMode(false, layer);
  }

  deleteClicked(layer?: LayerUI): void {
    if (layer) {
      this._activeDeletes$.next([...this._activeDeletes$.value, layer]);
    } else {
      this._isDeleteActive$.next(true);
    }
  }

  deleteCanceled(layer?: LayerUI): void {
    if (layer) {
      this._activeDeletes$.next(
        this._activeDeletes$.value.filter(
          (item) => item.id !== layer.id && item.type !== layer.type,
        ),
      );
    } else {
      this._isDeleteActive$.next(false);
    }
  }

  private _editChildrenClicked(layer: LayerUI): void {
    const sketchPlane = this._viewerLayerService.getSketchLayer(layer.id);
    this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    if (this._engineService.isEditMode()) {
      // to make sketch plane accessible in sketch circle and sketch line services
      this._broadcastService.broadcast(EVENT_TYPE.ADD_SKETCH, {
        sketchPlane,
        isAddPlaneActive: this._isAddPlaneActive$.value,
      });
      this.setIsEditChildrenActive(true);
      this._layersService.createBackupLayer(layer);
      this._layersService.setLayerEditMode(layer, true);
      this._childLayers$.value.forEach((child) => {
        this._layersService.createBackupLayer(child);
        this._layersService.setLayerEditMode(child, true);
      });
      this._broadcastService.broadcast(EVENT_TYPE.EDIT_SKETCHES, {
        sketchPlane,
        isAddPlaneActive: true,
        resetSketchesCurrentPlane: false,
      });
    } else {
      this._layerDetailsUtilsService.showNoEditionToast();
    }
  }

  private _editChildrenCanceled(layer: LayerUI): void {
    this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    this._layersService.setLayerEditMode(layer, false);
    this._layersService.loadBackupLayer(layer.id);
    this._broadcastService.broadcast(EVENT_TYPE.EDIT_SKETCHES, {
      sketchPlane: layer,
      isAddPlaneActive: false,
      resetSketchesCurrentPlane: true,
      removeSketchDrafts: true,
    });
    this.setIsEditChildrenActive(false);
    this._filterEditedLayers(layer.id);
    this._childLayers$.value.forEach((child) => {
      this._layersService.loadBackupLayer(child.id);
    });
  }

  private _editChildrenConfirmed(layer: LayerUI): void {
    this._filterEditedLayers(layer.id);
    this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    this._layersService.setLayerEditMode(layer, false);
    this._layersService.updateLayer(layer, this._childLayers$.value);
    this._broadcastService.broadcast(EVENT_TYPE.EDIT_SKETCHES, {
      sketchPlane: layer,
      isAddPlaneActive: false,
      resetSketchesCurrentPlane: true,
    });
    this.setIsEditChildrenActive(false);
  }

  private _filterEditedLayers(id: number): void {
    const editedLayers = this._layersService.editedLayers.filter((layer) => layer.id !== id);
    this._layersService.editedLayers = editedLayers;
  }

  private _isRecalculateActive(layer: LayerUI) {
    return this._recalculatedLayers.includes(layer);
  }
}
