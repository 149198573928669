/* eslint-disable max-lines */
import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { Photo } from '@App/app/entities/files/files.model';
import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { PhotoDimensions } from '@App/app/entities/shared/parameters.model';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Angle,
  Axis,
  Mesh,
  MeshBuilder,
  Plane,
  Space,
  StandardMaterial,
  Texture,
  Vector3,
} from 'babylonjs';
import { AdvancedDynamicTexture, TextBlock } from 'babylonjs-gui';
import { first } from 'rxjs/operators';
import { ENGINE_BACKGROUND } from 'src/app/configs/babylon.config';
import { PhotosUtilsService } from '../../../pages/viewer/services/photos-utils/photos-utils.service';
import { TiePointsService } from '../../../pages/viewer/services/tie-points/tie-points.service';
import { PhotoMarkerService } from '../photo-marker-service/photo-marker.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ViewerPhotoService {
  private photoRequests: XMLHttpRequest[] = [];
  private advancedTexture: AdvancedDynamicTexture | null;
  private photoDimensions: PhotoDimensions;
  originalPhotoDimensions: PhotoDimensions;
  photo: Mesh | null;
  tiePointsModeActive: boolean;
  material: StandardMaterial | null;

  constructor(
    private sceneService: SceneService,
    private photoMarkerService: PhotoMarkerService,
    private tiePointsService: TiePointsService,
    private photosUtilsService: PhotosUtilsService,
  ) {
    this.tiePointsService.tiePointsModeActive$.pipe(untilDestroyed(this)).subscribe((active) => {
      this.tiePointsModeActive = active;
    });
  }

  createPhoto(photo: Photo) {
    const position = this.photosUtilsService.position(photo.data.position);
    const normal = this.photosUtilsService.normal(
      this.photosUtilsService.quaternion(photo.data.rotation),
    );
    const plane = new Plane(0, 1, 0, 0);
    const viewerPhoto = MeshBuilder.CreatePlane(
      photo.name,
      {
        ...this.photoDimensions,
        sourcePlane: plane,
      },
      this.sceneService.scene,
    );
    viewerPhoto.isPickable = true;
    viewerPhoto.enableEdgesRendering();
    viewerPhoto.edgesWidth = 1;
    viewerPhoto.edgesColor = ENGINE_BACKGROUND.color;
    viewerPhoto.renderingGroupId = 2;
    viewerPhoto.position = position.clone();
    viewerPhoto.rotationQuaternion = this.photosUtilsService.quaternion(photo.data.rotation);
    viewerPhoto.rotate(Axis.Y, Angle.FromDegrees(180).radians(), Space.LOCAL);
    viewerPhoto.rotate(
      Axis.Z,
      -(viewerPhoto.rotationQuaternion.toEulerAngles().z * 2),
      Space.LOCAL,
    );
    this.displayLoading(viewerPhoto);
    viewerPhoto.convertToFlatShadedMesh();
    this.abortPreviousRequests();
    //load HQ photo
    this.loadTexture(photo, normal, true);
    //load LQ photo
    this.loadTexture(photo, normal);
    this.photo = viewerPhoto;
  }

  disposePhoto() {
    if (this.material) {
      this.material.dispose(true, true);
      this.material = null;
    }
    this.photoMarkerService.disposeMarker();
    if (this.photo) {
      this.photo.dispose(true, true);
      this.photo = null;
    }
  }

  setImageDimensionsFromRender(render: ExtendedRender) {
    const height = render.imageDimensionsHeight || 0;
    const width = render.imageDimensionsWidth || 0;
    this.originalPhotoDimensions = { height, width };
    this._setPhotoDimensions(width, height);
  }

  private _setPhotoDimensions(width: number, height: number) {
    const baseWidth = 3.75;
    const baseHeight = 2.5;
    this.photoDimensions = {
      width: baseWidth,
      height: width && height ? (height * baseWidth) / width : baseHeight,
    };
  }

  private showMarker(photoId: number, normal: Vector3) {
    if (this.tiePointsModeActive) {
      this.tiePointsService.tiePointPhotos$.pipe(first()).subscribe((photos) => {
        const measurement = photos.find((p) => p.photoId === photoId);
        if (this.photo && measurement) {
          const markerPosition = this.photoMarkerService.markerPosition(
            this.photo,
            this.originalPhotoDimensions,
            measurement,
          );
          this.photoMarkerService.createMarker(this.photo, markerPosition, normal);
        }
      });
    }
  }

  private loadTexture(photo: Photo, normal: Vector3, HQ = false) {
    const url = `${HQ ? photo.downloadURL : photo.data.thumbnailURL}?CorsWorkaround=ok`;
    const request = new XMLHttpRequest();
    if (HQ) {
      this.photoRequests.push(request);
    }
    request.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        const texture = new Texture(base64, this.sceneService.scene, false, true);
        texture.onLoadObservable.add((_texture) => {
          if (this.photo?.name === photo.name) {
            this.advancedTexture?.dispose();
            this.photo.material = this.getLoadingMaterial();
            (this.photo.material as StandardMaterial).diffuseTexture?.dispose();
            (this.photo.material as StandardMaterial).diffuseTexture = _texture;
            this.photo.material.alpha = 1;
            this.photo.disableEdgesRendering();
            if (!HQ) {
              this.showMarker(+photo.id, normal);
            }
          } else {
            _texture.dispose();
          }
        });
      };
      reader.readAsDataURL(request.response);
    };
    request.open('GET', url);
    request.responseType = 'blob';
    request.send();
  }

  private displayLoading(viewerPhoto: Mesh) {
    const text = new TextBlock('loading-text', 'Loading...');
    text.color = 'white';
    text.fontSize = 60;
    this.advancedTexture = AdvancedDynamicTexture.CreateForMesh(viewerPhoto);
    this.advancedTexture.addControl(text);
  }

  private getLoadingMaterial() {
    if (this.material) {
      this.material.dispose(true, true);
      this.material = null;
    }
    const material = new StandardMaterial('mat', this.sceneService.scene);
    material.alpha = 0.5;
    this.material = material;
    return material;
  }

  private abortPreviousRequests() {
    for (const request of this.photoRequests) {
      request.abort();
    }
    this.photoRequests = [];
  }
}
