import { createFeatureSelector, createSelector } from '@ngrx/store';
import { photosAdapter, State } from '../reducer/photos.reducer';

export const getPhotosState = createFeatureSelector<State>('photos');

const { selectAll } = photosAdapter.getSelectors(getPhotosState);

export const selectAllPhotos = selectAll;

export const selectPhotoByName = (name: string) =>
  createSelector(selectAll, (photos) => {
    return photos.find((photo) => photo.name === name);
  });
