import { MAX_PRESET_NAME_LENGTH } from '@App/app/configs/app.config';
import { GREY_COLOR, WHITE_COLOR } from '@App/app/configs/babylon.config';
import { CustomPresetForm } from '@App/app/entities/forms/custom-preset-form.model';
import { IPreset } from '@App/app/entities/viewer/custom-preset.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { addCustomPreset } from '../../store/color-presets-store/actions/ui-settings.actions';

import { selectUiSettings } from '@App/app/auth/store/auth/selectors/auth.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectAllPresets } from '../../store/color-presets-store/selectors/ui-settings.selectors';
@UntilDestroy()
@Component({
  selector: 'app-custom-color-preset',
  templateUrl: './custom-color-preset.component.html',
  styleUrls: ['./custom-color-preset.component.scss'],
})
export class CustomColorPresetComponent implements OnInit {
  presetForm: FormGroup<CustomPresetForm>;
  private allPresets: IPreset[];
  backgroundColor: string;
  viewfinderColor: string;
  sameName = false;
  maxPresetNameLength = MAX_PRESET_NAME_LENGTH;
  private currentName: string;
  constructor(
    private dialogRef: NbDialogRef<CustomColorPresetComponent>,
    private store: Store,
    private fb: FormBuilder,
  ) {}
  ngOnInit() {
    this.store
      .select(selectUiSettings)
      .pipe(untilDestroyed(this))
      .subscribe((uiSettings) => {
        if (uiSettings) {
          this.backgroundColor = uiSettings.currentPreset?.backgroundColor || GREY_COLOR;
          this.viewfinderColor = uiSettings.currentPreset?.viewFinderColor || WHITE_COLOR;
        }
      });
    this.presetForm = this.fb.group<CustomPresetForm>({
      presetName: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(this.maxPresetNameLength)],
        nonNullable: true,
      }),
    });
    this.store
      .select(selectAllPresets)
      .pipe(filter<IPreset[]>(Boolean), take(1))
      .subscribe((items) => {
        this.allPresets = items;
      });
    this.presetForm.controls.presetName.valueChanges.subscribe((value) => {
      this.currentName = value;
      this.sameName = this.allPresets.some((item) => item.name === this.currentName);
    });
  }

  savePreset() {
    this.sameName = this.allPresets.some(
      (item) => item.name === this.presetForm.controls.presetName.value,
    );
    const preset = {
      name: this.presetForm.controls.presetName.value,
      backgroundColor: this.backgroundColor as string,
      viewFinderColor: this.viewfinderColor as string,
      default: false,
    };
    this.store.dispatch(addCustomPreset({ preset }));
    this.dialogRef.close();
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
