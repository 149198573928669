import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Polygon } from '@App/app/entities/layer/polygons/polygon.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractMesh, Mesh, TransformNode, Vector3 } from 'babylonjs';
import { NEW_POLYGON_NAME } from 'src/app/configs/babylon.config';
import {
  addPointToPolygon,
  addTmpLayer,
  saveTmpLayer,
} from 'src/app/pages/viewer/store/actions/layers.actions';
import { v4 as uuidv4 } from 'uuid';
import { SceneService } from '../../scene-service/scene.service';
import { UtilsService } from '../../utils-service/utils.service';
import { PolygonUtilsService } from './polygon-utils.service';

@Injectable({
  providedIn: 'root',
})
export class CreateNewPolygonService {
  private draftPolygon: Polygon | null;
  private editedPolygon: number | null;
  private newPolygonId: number;
  private pointsMeshes: (Mesh | null)[] = [];
  private root: TransformNode;

  constructor(
    private polygonUtilsService: PolygonUtilsService,
    private sceneService: SceneService,
    private store: Store,
    private utilsService: UtilsService,
  ) {}

  finishTakingPolygon(editMode?: boolean) {
    if (this.pointsMeshes.length !== 3) {
      this.resetPoints();
    } else {
      this.finishCreatingPolygon(editMode);
    }
  }

  setEditedPolygon(value: boolean, id: number) {
    if (value) {
      this.editedPolygon = id;
    } else {
      this.clearTmpData();
    }
  }

  onClickActionPolygonService() {
    const hit = this.utilsService.pickRay();
    if (hit?.pickedPoint) {
      if (!this.editedPolygon) {
        if (!this.draftPolygon) {
          this.draftPolygon = new Polygon();
          this.newPolygonId = (uuidv4() as any) as number;
          this.draftPolygon.id = this.newPolygonId;
          this.draftPolygon.isVisible = true;
          this.draftPolygon.isSaved = true;
        }
        this.initSphereOnClick(hit.pickedPoint);
      } else {
        this.store.dispatch(addPointToPolygon({ id: this.editedPolygon, point: hit.pickedPoint }));
      }
    }
    return false;
  }

  private initSphereOnClick(pickedPoint: Vector3) {
    this.root = this.root || new AbstractMesh(NEW_POLYGON_NAME, this.sceneService.scene);
    const sphere = this.polygonUtilsService.createSphere(pickedPoint, this.root, true);
    this.pointsMeshes.push(sphere);
    if (this.draftPolygon) {
      this.draftPolygon.data.squareArea = Polygon.calculateSquareArea(
        this.pointsMeshes.filter((mesh) => !!mesh) as Mesh[],
      );
    }
    if (pickedPoint) {
      this.draftPolygon?.data.points?.push({
        x: pickedPoint.x,
        y: pickedPoint.y,
        z: pickedPoint.z,
      });
    }
    this.createTmpLayer();
  }

  private createTmpLayer() {
    if (this.pointsMeshes.length > 2) {
      this.store.dispatch(addTmpLayer({ layer: this.draftPolygon as LayerUI }));
      for (let i = 0; i < this.pointsMeshes.length; i++) {
        this.pointsMeshes[i]?.dispose();
        this.pointsMeshes[i] = null;
      }
      this.editedPolygon = this.newPolygonId;
    }
  }

  private clearTmpData() {
    this.draftPolygon = null;
    this.editedPolygon = null;
    this.pointsMeshes = [];
  }

  private resetPoints() {
    for (let i = 0; i < this.pointsMeshes.length; i++) {
      this.pointsMeshes[i]?.dispose();
      this.pointsMeshes[i] = null;
    }
    this.clearTmpData();
  }

  private finishCreatingPolygon(editMode?: boolean) {
    if (
      this.sceneService.scene &&
      !editMode &&
      !!this.draftPolygon &&
      this.pointsMeshes.length > 2
    ) {
      this.store.dispatch(saveTmpLayer({ layer: this.draftPolygon }));
      this.clearTmpData();
    } else {
      this.draftPolygon = new Polygon();
    }
  }
}
