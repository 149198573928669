import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNumeric',
})
export class IsNumericPipe implements PipeTransform {
  transform(value: unknown): boolean {
    return typeof value === 'number';
  }
}
