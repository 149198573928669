import { SKYFISH_DOCS } from '@App/app/configs/app.config';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ReportProblemDialogComponent } from '../../components/report-problem-dialog/report-problem-dialog/report-problem-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
})
export class FooterComponent {
  constructor(private dialogService: NbDialogService) {}
  DOCS = SKYFISH_DOCS;
  showReportForm() {
    this.dialogService.open(ReportProblemDialogComponent);
  }
}
