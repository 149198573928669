import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { ISceneLoaderAsyncResult, Mesh } from 'babylonjs';
import { GLTFFileLoader, GLTFLoaderState } from 'babylonjs-loaders';

export const clearLoaderResult = (result: ISceneLoaderAsyncResult) => {
  result.animationGroups.forEach((group) => group.dispose());
  result.meshes.forEach((mesh) => mesh.dispose(false, true));
  result.particleSystems.forEach((system) => system.dispose(true));
  result.skeletons.forEach((skeleton) => skeleton.dispose());
  result.transformNodes.forEach((node) => node.dispose(false, true));
  result.geometries.forEach((geometry) => geometry.dispose());
  result.lights.forEach((light) => light.dispose(false, true));
};

export const abortLoaderRequests = (loaders: GLTFFileLoader[]) => {
  for (const loader of loaders) {
    for (const httpInnerRequest of (loader as any)._requests) {
      httpInnerRequest.abort();
    }
    loader.dispose();
  }
};

export const clearLoaders = (loaders: GLTFFileLoader[]) => {
  let loading = false;

  for (let i = 0; i < loaders.length; i++) {
    if (loaders[i].loaderState === GLTFLoaderState.LOADING) {
      loading = true;
    }
    loaders[i].dispose();
    delete loaders[i];
  }

  return loading;
};

export const getVertexPositions = (pointsMeshes: Mesh[]) => {
  return pointsMeshes.reduce((acc: Coordinates3[], cur) => {
    acc.push({
      x: cur.absolutePosition.x,
      y: cur.absolutePosition.y,
      z: cur.absolutePosition.z,
    });
    return acc;
  }, []);
};
