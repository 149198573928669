<div class="progress_bar" [nbTooltip]="getProcessInformation()">
  <div
    class="progress_bar__segment"
    *ngFor="let segment of segments; let i = index"
    [ngClass]="{ '--large': segment.isLarge() }"
  >
    <div class="progress_bar__container">
      <div class="progress_bar__header" *ngIf="segment.showHeader()">
        {{ segment.name }}
        <span class="progress_bar__header__time">{{ getElapsedTime() }}</span>
      </div>
      <div class="progress_bar__bar" [ngClass]="{ '--no-check': !segment.showCheck }">
        <span *ngIf="!started" class="progress_bar__value --contrast">NOT STARTED</span>
        <div
          class="progress_bar__fill"
          [ngClass]="{
            '--failed': isFailed(segment),
            '--success': isSuccess(segment),
            '--starting': isStarting(segment, i),
            '--canceled': isCanceled(segment),
            '--paused': isPaused(segment),
            '--interrupted': isInterrupted(segment)
          }"
          [ngStyle]="{
            width: segment.getPercentagesWidth(),
            'border-bottom-right-radius': getBorderRadius(segment),
            'style.border-top-right-radius': getBorderRadius(segment)
          }"
        >
          <span
            *ngIf="showValue(segment) && !isCanceled(segment) && !isPaused(segment)"
            class="progress_bar__value"
            data-testId="segment-value"
            >{{ getValue(segment) }}</span
          >
          <span *ngIf="isInitializing(segment, i)" class="progress_bar__value --contrast"
            >Initializing...</span
          >
          <span *ngIf="isCanceled(segment)" class="progress_bar__value">Canceled</span>
          <span *ngIf="isPaused(segment)" class="progress_bar__value">Paused</span>
        </div>
      </div>
    </div>
    <div
      *ngIf="!isLastSegment(segment)"
      class="progress_bar__point"
      [ngClass]="{
        '--failed': isPointFailed(segment),
        '--success': isPointSuccess(segment),
        '--warning': isPointWarning(segment),
        '--canceled': isCanceled(segment),
        '--paused': isPaused(segment)
      }"
    >
      <nb-icon class="progress_bar__icon" [icon]="getPointIcon(segment)"></nb-icon>
    </div>
  </div>
</div>
