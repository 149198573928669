import { Injectable } from '@angular/core';
import { ArcRotateCamera, Matrix, Vector3 } from 'babylonjs';
import { modelMeshPredicate } from '../../../helpers/layers-helpers';
import { RegionOfInterestService } from '../../region-of-interest-service/region-of-interest.service';
import { SceneService } from '../../scene-service/scene.service';
import { OrthoProjectionService } from '../orthoprojection-service/orthoprojection.service';

@Injectable({
  providedIn: 'root',
})
export class CameraTargetService {
  constructor(
    private sceneService: SceneService,
    private regionOfInterestService: RegionOfInterestService,
    private orthoprojectionService: OrthoProjectionService,
  ) {}

  onClickChangeCameraTarget(camera: ArcRotateCamera) {
    if (this.sceneService.scene) {
      const ray = this.sceneService.scene.createPickingRay(
        this.sceneService.scene.pointerX,
        this.sceneService.scene.pointerY,
        Matrix.Identity(),
        null,
      );

      const hit = this.sceneService.scene.pickWithRay(ray, modelMeshPredicate);
      const tmpRadius = camera.radius;

      if (hit?.pickedPoint) {
        camera.target = hit.pickedPoint;
        camera.radius = tmpRadius;
      }
    }
  }

  moveCameraTargetToTheFront(distance: number) {
    const cam = this.sceneService.scene.activeCamera as ArcRotateCamera;
    const direction = cam.position.subtract(cam.target).normalizeToNew();
    cam.target = cam.target.clone().subtract(direction.scale(distance));
  }

  onChangeCameraTargetToROI(camera: ArcRotateCamera) {
    const roi$ = this.regionOfInterestService.regionOfInterest$;

    if (roi$) {
      roi$.subscribe((region) => {
        const center = region?.getCenter();
        if (center) {
          camera.target = center;
        }
        if (ArcRotateCamera.ORTHOGRAPHIC_CAMERA) {
          this.orthoprojectionService.resetOrthograpichCamera(camera);
        }
      });
    }
  }

  resetCamera(camera: ArcRotateCamera) {
    camera.target = Vector3.Zero();
  }
}
