import { DEFAULT_TILE_LOAD } from '@App/app/configs/babylon.config';
import { CONTEXT_LOST, FAILED } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { LAYER_EVENTS } from '@App/app/entities/layer/enums/layer-types.enum';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { CustomScene } from '@App/app/entities/viewer/custom-scene.model';
import { TilesInfo } from '@App/app/entities/viewer/tiles-info.model';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { environment } from '@App/environments/environment';
import { Injectable, isDevMode } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { ArcRotateCamera, Camera, Engine } from 'babylonjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AnimateService } from '../../animate-service/animate.service';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { CameraTargetService } from '../../camera-services/camera-target-service/camera-target.service';
import { CamerasService } from '../../camera-services/cameras-service/cameras.service';
import { AxisCoordsToolService } from '../../coords-tool-services/axis-coords-tool-service/axis-coords-tool.service';
import { GeoCoordsToolService } from '../../coords-tool-services/geo-coords-tool-service/geo-coords-tool.service';
import { GenerateWorldAxisService } from '../../generate-world-axis-service/generate-world-axis.service';
import { LayerEventsService } from '../../layer-services/layer-events-service/layer-events.service';
import { MaterialService } from '../../material-service/material.service';
import { SceneService } from '../../scene-service/scene.service';

@Injectable({ providedIn: 'root' })
export class EngineUtilsService {
  private _lostContext$ = new BehaviorSubject(false);
  lostContext$ = this._lostContext$.asObservable();

  constructor(
    private _materialService: MaterialService,
    private _layerEventsService: LayerEventsService,
    private _toastrService: NbToastrService,
    private _sceneService: SceneService,
    private _generateWorldAxisService: GenerateWorldAxisService,
    private _camerasService: CamerasService,
    private _babylonNodesService: BabylonNodesService,
    private _axisCoordsToolService: AxisCoordsToolService,
    private _geoCoordsToolService: GeoCoordsToolService,
    private _cameraTargetService: CameraTargetService,
    private _animateService: AnimateService,
    private _broadcastService: BroadcastService,
  ) {}

  cleanEngineServices(): void {
    this._materialService.destroyMaterials();
    this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
  }

  initEngineAndCamera(canvas: HTMLCanvasElement, distanceLimit: boolean) {
    const engine = new Engine(canvas, true, undefined, true);
    engine.onContextLostObservable.add(() => {
      this._lostContext$.next(true);
      this._toastrService.show(CONTEXT_LOST, FAILED, {
        ...WARNING_TOASTR_CONFIG,
      });
    });
    this._sceneService.scene?.dispose();
    this._sceneService.scene = this._sceneService.createScene(engine);
    const camera = this._camerasService.initiateCameras(canvas, distanceLimit);
    this._materialService.initMaterials(this._sceneService.scene);
    this.setAxisVisibility(true);
    this._setupCypress(camera);
    return { engine, camera };
  }

  resetCanvasForEngine(engine: Engine | null, canvas: HTMLCanvasElement) {
    if (engine) {
      this._replaceEngineCanvas(engine, canvas);
      this._sceneService.resetAdvancedTexture();
    }
  }

  setAxisVisibility(value: boolean) {
    if (isDevMode()) {
      if (value) {
        this._generateWorldAxisService.showWorldAxis(15, this._sceneService.scene);
      } else {
        this._generateWorldAxisService.destroyAxis();
      }
    }
  }

  destroyScene() {
    if (this._sceneService.scene) {
      this._babylonNodesService.disposeCameraMesh();
      this._sceneService.scene.__advancedTexture?.dispose();
      this._sceneService.scene.__advancedTexture = null;
      this._sceneService.scene.dispose();
      (this._sceneService.scene as CustomScene | null) = null;
      this.cleanEngineServices();
    }
  }

  switchCamera(
    engine: Engine | null,
    canvas: HTMLCanvasElement | null,
    camera: ArcRotateCamera | null,
  ) {
    if (camera?.mode === Camera.ORTHOGRAPHIC_CAMERA) {
      this._camerasService.switchToPerspectiveCamera(camera);
      this._sceneService.scene.__orthoprojectionCustomGizmoScale = 1;
    } else if (camera?.mode === Camera.PERSPECTIVE_CAMERA && engine && canvas) {
      this._camerasService.initOrthoprojection(canvas, engine, camera);
    }
  }

  onSceneClick() {
    this._axisCoordsToolService.onClickAction();
    this._geoCoordsToolService.onClickAction();
    this._layerEventsService.onSingleTapLayerEvents();
  }

  onCursorMove() {
    this._axisCoordsToolService.onMoveAction();
    this._geoCoordsToolService.onMoveAction();
    this._layerEventsService.onMouseMoveLayerEvents();
  }

  setCameraSpeed(camera: ArcRotateCamera | null, value: number) {
    if (camera) {
      camera.wheelPrecision = 101 - value;
    }
  }

  handleMovingArcRotateCameraTarget(camera: ArcRotateCamera | null) {
    if (camera) {
      this._cameraTargetService.onClickChangeCameraTarget(camera);
    }
  }

  switchCameraToRoi(camera: ArcRotateCamera | null) {
    if (camera) {
      this._cameraTargetService.onChangeCameraTargetToROI(camera);
    }
  }

  runEngineAnimation(engine: Engine | null) {
    if (engine) {
      this._animateService.animate(engine, this._sceneService.scene);
    }
  }

  getTilesInfo(tilesNumber: number): TilesInfo {
    return { tilesNumber, defaultTileLoad: DEFAULT_TILE_LOAD.DEFAULT_TILE_LOAD };
  }

  clearPreviousScene(engine: Engine | null, canvas: HTMLCanvasElement | null) {
    if (this._sceneService.scene && canvas) {
      engine?.unRegisterView(canvas);
      this._layerEventsService.activeLayerEvent.next(LAYER_EVENTS.NULL);
    }
  }

  startModelLoadingIfProd() {
    if (environment.production) {
      this._broadcastService.broadcast(EVENT_TYPE.LOAD_MODEL, null);
    }
  }

  showDebugLayerSync(onLoadDebugLayer: () => void) {
    if (isDevMode()) {
      Promise.resolve().then(() => {
        onLoadDebugLayer();
        this._sceneService.toggleDebugLayer();
      });
    }
  }

  getTilesRangeByDev(tilesRange: number[]) {
    return environment.production ? undefined : tilesRange;
  }

  private _setupCypress(camera: ArcRotateCamera): void {
    if (window.Cypress) {
      window.babylonScene = this._sceneService.scene;
      window.babylonCamera = camera;
    }
  }

  private _replaceEngineCanvas(engine: Engine, canvas: HTMLCanvasElement) {
    engine.registerView(canvas);
    this._sceneService.scene.detachControl();
    engine.inputElement = canvas;
    this._sceneService.scene.attachControl();
  }
}
