<div class="owner" *ngIf="isOwner" (click)="$event.stopPropagation()">
  <nb-icon
    nbTooltip="You're owner of this process - cannot unsubscribe"
    class="owner__icon"
    status="success"
    icon="bell"
  ></nb-icon>
</div>
<ng-container *ngxPermissionsOnly="permissions.CAN_SUBSCRIBE_TO_NOTIFICATIONS; else elseBlock">
  <button
    nbButton
    *ngIf="!isOwner"
    (click)="$event.stopPropagation(); handleSubscribeChange(!isSubscriber)"
    [nbTooltip]="isSubscriber ? 'Unsubscribe from notifications' : 'Subscribe to notifications'"
    ghost
  >
    <nb-icon
      [status]="isSubscriber ? 'success' : 'basic'"
      [icon]="isSubscriber ? 'bell' : 'bell-off'"
    ></nb-icon>
  </button>
</ng-container>

<button
  *ngxPermissionsOnly="permissions.CAN_ARCHIVE_PROCESSING"
  nbButton
  (click)="$event.stopPropagation(); archiveHandler()"
  [nbTooltip]="process.isArchived ? 'Unarchive process' : 'Archive process'"
  ghost
  class="action"
>
  <svg-icon [src]="process.isArchived ? unarchiveIcon : archiveIcon"></svg-icon>
</button>

<button
  *ngxPermissionsOnly="permissions.CAN_DELETE_PROCESSING"
  nbButton
  data-testId="delete-process"
  (click)="$event.stopPropagation(); deleteHandler()"
  [nbTooltip]="'Delete process'"
  ghost
  class="action"
>
  <div>
    <nb-icon icon="trash-2-outline"></nb-icon>
  </div>
</button>

<ng-template #elseBlock>
  <button
    nbButton
    (click)="$event.stopPropagation()"
    [nbTooltip]="'You are not authorized to subscribe'"
    ghost
  >
    <nb-icon status="basic" icon="bell-off"></nb-icon>
  </button>
</ng-template>
