import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { ViewerLayerService } from '../../../../engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { hideLayer, showLayer, showLayers } from '../actions/layers.actions';
import { selectAllLayers } from '../selectors/layers.selectors';
import { hideLayers, updateLayers } from './../actions/layers.actions';

@Injectable()
export class VisibilityLayerEffects {
  constructor(
    private actions: Actions,
    private viewerLayerService: ViewerLayerService,
    private store: Store,
  ) {}

  showLayer = createEffect(
    () => {
      return this.actions.pipe(
        ofType(showLayer),
        withLatestFrom(this.store.select(selectAllLayers)),
        tap(([{ id }, layers]) => {
          const layer = layers.find((item) => item.id === id) as LayerUI;
          this.viewerLayerService.showLayer(layer);
        }),
      );
    },
    { dispatch: false },
  );

  showLayers = createEffect(() => {
    return this.actions.pipe(
      ofType(showLayers),
      withLatestFrom(this.store.select(selectAllLayers)),
      map(
        ([{ ids }, layers]) => {
          const layersToUpdate = layers.filter((item) => ids.includes(item.id));
          this.viewerLayerService.showLayers(layersToUpdate);
          const updates = layersToUpdate.map((layer) => {
            return {
              id: layer.id,
              changes: { isVisible: true },
            };
          });
          return updateLayers({ updates });
        },
        catchError(() => EMPTY),
      ),
    );
  });

  hideLayer = createEffect(
    () => {
      return this.actions.pipe(
        ofType(hideLayer),
        tap((payload) => {
          this.viewerLayerService.hideLayer(payload.id);
        }),
      );
    },
    { dispatch: false },
  );

  hideLayers = createEffect(() => {
    return this.actions.pipe(
      ofType(hideLayers),
      withLatestFrom(this.store.select(selectAllLayers)),
      map(
        ([{ ids }, layers]) => {
          const layersToUpdate = layers.filter((item) => ids.includes(item.id));
          this.viewerLayerService.hideLayers(layersToUpdate);
          const updates = layersToUpdate.map((layer) => {
            return {
              id: layer.id,
              changes: { isVisible: false },
            };
          });
          return updateLayers({ updates });
        },
        catchError(() => EMPTY),
      ),
    );
  });
}
