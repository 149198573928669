import { SKETCH_CIRCLE_MESH_NAME } from '@App/app/configs/babylon.config';
import { SketchCircle } from '@App/app/entities/layer/sketch-tools/tools/sketch-circle.model';
import { Injectable } from '@angular/core';
import { LinesMesh, Vector3 } from 'babylonjs';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';

@Injectable()
export class SketchCircleUtilsService {
  private deltaTheta = 0.1;
  private circleNumber = 70; //tweaked value - it's just enough to make a circle

  constructor(private babylonNodesService: BabylonNodesService) {}

  /**
   *
   * @param radius
   * @param instance if there's a line instance provided this method returns updated instance.
   * In other way it's going to create new line
   * @param visible .
   * Creates new circle or updates existing ones.
   */
  updateCircle(radius: number = 1, instance?: LinesMesh, visible?: boolean) {
    let theta = 0;
    const points = new Array(this.circleNumber).fill(null).map((acc) => {
      const e = new Vector3(radius * Math.cos(theta), 0, radius * Math.sin(theta));
      theta += this.deltaTheta;
      return e;
    });

    const lines = instance
      ? this.babylonNodesService.createLine(points, SKETCH_CIRCLE_MESH_NAME, null, {
          instance,
        })
      : this.babylonNodesService.createLine(points, SKETCH_CIRCLE_MESH_NAME, null, {
          updatable: true,
        });

    lines.renderingGroupId = 2;
    lines.alwaysSelectAsActiveMesh = true;
    lines.isPickable = false;

    if (visible) {
      lines.isVisible = visible;
    }

    return lines;
  }

  /**
   * @param sketchCircle
   * Makes sketchCircle babylon meshes to stay together in their local position.
   */
  recomputeRootSpace(sketchCircle: SketchCircle) {
    this._addOptionalPositions(sketchCircle.root?.position, sketchCircle.centerMesh?.position);
    this._subtractOptionalPositions(
      sketchCircle.centerMesh?.position,
      sketchCircle.centerMesh?.position,
    );
    this._subtractOptionalPositions(sketchCircle.edgeMesh?.position, sketchCircle.root?.position);
  }

  private _addOptionalPositions(pos1?: Vector3, pos2?: Vector3) {
    if (pos1 && pos2) {
      pos1.addInPlace(pos2);
    }
  }

  private _subtractOptionalPositions(pos1?: Vector3, pos2?: Vector3) {
    if (pos1 && pos2) {
      pos1.subtractInPlace(pos2);
    }
  }
}
