<div class="camera_snap">
  <div class="camera_snap__nadir-button">
    <div (click)="onNadirButton()" class="arrow-up select-none"></div>
    <button (click)="onNadirButton()">NADIR</button>
  </div>
  <div class="camera_snap__wrapper">
    <canvas id="canvas" width="100" height="100"></canvas>
    <span class="camera_snap__direction-text" [id]="dataDonutMenuE">E</span>
    <span class="camera_snap__direction-text" [id]="dataDonutMenuS">S</span>
    <span class="camera_snap__direction-text" [id]="dataDonutMenuW">W</span>
    <span class="camera_snap__direction-text" [id]="dataDonutMenuN">N</span>
  </div>
</div>
