import {
  DRAFT_SKETCH_RECTANGLE_TAG_NAME,
  DRAG_MESH_MATERIAL_NAME,
  SKETCH_LINE_BOX_NAME,
  SKETCH_RECTANGLE_BOX_NAME,
} from '@App/app/configs/babylon.config';
import { SketchPlane } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { SketchRectangle } from '@App/app/entities/layer/sketch-tools/tools/sketch-rectangle.model';
import { Injectable } from '@angular/core';
import { Mesh, Plane, TransformNode, Vector3 } from 'babylonjs';
import { SketchLineMeshUtilsService } from '../shared/sketch-line-mesh-utils.service';
import { SketchesUtilsService } from '../shared/sketches-utils.service';

@Injectable()
export class SketchRectangleUtilsService {
  constructor(
    private _sketchLineMeshUtilsService: SketchLineMeshUtilsService,
    private _sketchesUtilsService: SketchesUtilsService,
  ) {}

  updateRectangleVertices(sketchRectangle: SketchRectangle, plane: SketchPlane | null) {
    sketchRectangle.pointsMeshes.forEach((pointMesh, index) => {
      if (pointMesh.material?.name === DRAG_MESH_MATERIAL_NAME) {
        const meshIndexes = this._getMeshIndexesByBaseIndex(index);
        if (meshIndexes) {
          const { indexBehind, indexAhead, indexOpposite } = meshIndexes;
          const [
            perpendicularPlaneBehind,
            perpendicularPlaneAhead,
          ] = this._calculatePerpendicularPlanes(
            sketchRectangle.pointsMeshes,
            indexBehind,
            indexAhead,
            indexOpposite,
            plane,
          );
          sketchRectangle.pointsMeshes[indexBehind].position = pointMesh.position.projectOnPlane(
            perpendicularPlaneBehind,
            pointMesh.position.add(perpendicularPlaneBehind.normal),
          );
          sketchRectangle.pointsMeshes[indexAhead].position = pointMesh.position.projectOnPlane(
            perpendicularPlaneAhead,
            pointMesh.position.add(perpendicularPlaneAhead.normal),
          );
          sketchRectangle.linesMesh = this._sketchLineMeshUtilsService.updateLine(sketchRectangle);
        }
      }
    });
  }

  updateRectangleByPoint(draftSketch: SketchRectangle, point: Vector3, plane: SketchPlane) {
    const perpendicularSketchPlane = new Plane(1, 0, 0, 0).copyFromPoints(
      draftSketch.pointsMeshes[0].position,
      draftSketch.pointsMeshes[0].position.add(plane.normal || Vector3.Zero()),
      draftSketch.pointsMeshes[1].position,
    );
    const distance = perpendicularSketchPlane.signedDistanceTo(point);
    draftSketch.pointsMeshes[2].position = draftSketch.pointsMeshes[1].position
      .clone()
      .add(perpendicularSketchPlane.normal.scale(distance));
    draftSketch.pointsMeshes[3].position = draftSketch.pointsMeshes[0].position
      .clone()
      .add(perpendicularSketchPlane.normal.scale(distance));
  }

  closeRectangleByPoint(
    draftSketch: SketchRectangle | null,
    finalPoint: Vector3,
    root: TransformNode | null,
    boxId: number,
  ) {
    const createPointMeshes = () => {
      for (let i = 0; i < 4; i++) {
        if (root) {
          draftSketch?.pointsMeshes.push(
            this._sketchesUtilsService.createMesh(
              i === 0 ? finalPoint : draftSketch.pointsMeshes[0].position,
              root,
              `${SKETCH_LINE_BOX_NAME}${++boxId}`,
              true,
            ),
          );
        }
      }
    };

    if (draftSketch?.linesMesh) {
      draftSketch?.linesMesh?.dispose();
      draftSketch.linesMesh = null;
    }
    createPointMeshes();
    if (draftSketch) {
      draftSketch.linesMesh = this._sketchLineMeshUtilsService.createLine(draftSketch);
      draftSketch.closed = true;
    }
    return boxId;
  }

  finishRectangle(draftSketch: SketchRectangle, root: TransformNode | null) {
    draftSketch.pointsMeshes[draftSketch.pointsMeshes.length - 1].dispose();
    delete draftSketch.pointsMeshes[draftSketch.pointsMeshes.length - 1];
    draftSketch?.pointsMeshes.pop();
    draftSketch.linesMesh = this._sketchLineMeshUtilsService.finishLine(draftSketch);
    draftSketch.linesMesh.parent = root;
  }

  removeDraftSketch(
    sketch: SketchRectangle | null,
    sketches: SketchRectangle[],
    root: TransformNode | null,
  ): [SketchRectangle | null, SketchRectangle[], TransformNode | null] {
    if (sketches?.length) {
      sketches.forEach((draftSketchRectangle) =>
        this._sketchesUtilsService.deleteDraftSketch(draftSketchRectangle),
      );
      this._sketchesUtilsService.disposeDraftSketchMeshesByTag(DRAFT_SKETCH_RECTANGLE_TAG_NAME);
    }

    if (sketch) {
      this._sketchesUtilsService.deleteDraftSketch(sketch);
      sketch = null;
    }
    sketches = [];

    if (root) {
      root.dispose();
      root = null;
    }
    return [sketch, sketches, root];
  }

  createPointMeshes(sketch: SketchRectangle, boxId: number, root: TransformNode): [Mesh[], number] {
    const meshes =
      sketch.data.vertexPositions?.map((vertexPos) => {
        const pos = new Vector3(vertexPos.x, vertexPos.y, vertexPos.z);
        const name = `${SKETCH_RECTANGLE_BOX_NAME}${++boxId}`;
        return this._sketchesUtilsService.createMesh(pos, root, name, true);
      }) || [];
    return [meshes, boxId];
  }

  private _getMeshIndexesByBaseIndex(baseIndex: number) {
    const wallIndexesByBaseOne: {
      [key: number]: { behind: number; ahead: number; opposite: number };
    } = {
      0: { behind: 3, ahead: 1, opposite: 2 },
      1: { behind: 0, ahead: 2, opposite: 3 },
      2: { behind: 1, ahead: 3, opposite: 0 },
      3: { behind: 2, ahead: 0, opposite: 1 },
    };
    const { behind, ahead, opposite } = wallIndexesByBaseOne[baseIndex];
    return { indexBehind: behind, indexAhead: ahead, indexOpposite: opposite };
  }

  private _calculatePerpendicularPlanes(
    meshes: Mesh[],
    indexBehind: number,
    indexAhead: number,
    indexOpposite: number,
    plane: SketchPlane | null,
  ) {
    const perpendicularPlaneBehind = new Plane(1, 0, 0, 0).copyFromPoints(
      meshes[indexBehind].position,
      meshes[indexBehind].position.add(plane?.normal || Vector3.Zero()),
      meshes[indexOpposite].position,
    );
    const perpendicularPlaneAhead = new Plane(1, 0, 0, 0).copyFromPoints(
      meshes[indexAhead].position,
      meshes[indexAhead].position.add(plane?.normal || Vector3.Zero()),
      meshes[indexOpposite].position,
    );
    return [perpendicularPlaneBehind, perpendicularPlaneAhead];
  }
}
