import {
  AUTOMAPPING_IMPORTABLE_LAYER,
  LAYER_TYPES,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { SketchPlaneData } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Vector3 } from 'babylonjs';

export const addLayers = createAction(
  '[Layer] Add layers',
  props<{
    layers: (LayerUI | LayerUiExpandable)[];
    showToast?: boolean;
  }>(),
);

export const addLayersSuccess = createAction(
  '[Layer] Add layers success',
  props<{ layers: (LayerUI | LayerUiExpandable)[]; showToast?: boolean }>(),
);

export const addLayersLocal = createAction(
  '[Layer] Add layers local',
  props<{ layers: (LayerUI | LayerUiExpandable)[] }>(),
);

export const removeAllLayersLocal = createAction('[Layer] Remove all layers local');

export const saveTmpLayer = createAction(
  '[Layer] Save tmp layer',
  props<{ layer: LayerUI | LayerUiExpandable; commit?: boolean }>(),
);

export const addLayer = createAction(
  '[Layer] Add layer',
  props<{
    layer: LayerUI | LayerUiExpandable;
    tmpId?: number;
    commit?: boolean;
  }>(),
);

export const addFolderLayer = createAction(
  '[Layer] Add folder layer',
  props<{ layer: LayerUI | LayerUiExpandable }>(),
);

export const addFolderLayerSuccess = createAction(
  '[Layer] Add folder layer success',
  props<{ layer: LayerUI | LayerUiExpandable }>(),
);

export const addLayerSuccess = createAction(
  '[Layer] Add layer success',
  props<{ layer: LayerUI | LayerUiExpandable; tmpLayerId?: number }>(),
);

export const addTmpLayer = createAction(
  '[Layer] Add tmp layer',
  props<{ layer: LayerUI | LayerUiExpandable }>(),
);

export const importLayers = createAction(
  '[Layer] Import layers success',
  props<{ layers: AUTOMAPPING_IMPORTABLE_LAYER[] }>(),
);

export const loadAllLayers = createAction(
  '[Layers] Load all layers',
  props<{ renderId: number }>(),
);

export const loadAllLayersSuccess = createAction(
  '[Layer] Load all layers success',
  props<{ layers: (LayerUI | LayerUiExpandable)[] }>(),
);

export const updateLayer = createAction(
  '[Layer] Update layer',
  props<{ update: Update<LayerUI | LayerUiExpandable> }>(),
);

export const updateLayers = createAction(
  '[Layer] Update layers',
  props<{ updates: Update<LayerUI | LayerUiExpandable>[] }>(),
);

export const changeLayerProp = createAction(
  '[Layer] Change layer prop',
  props<{
    id: number;
    prop: { key: keyof (LayerUI | LayerUiExpandable); value: string | number };
  }>(),
);

export const changeLayerPropSuccess = createAction(
  '[Layer] Change layer prop success',
  props<{ layer: LayerUI | LayerUiExpandable; key: keyof (LayerUI | LayerUiExpandable) }>(),
);

export const changeLayersProp = createAction(
  '[Layer] Change layers prop',
  props<{
    ids: number[];
    prop: { key: keyof (LayerUI | LayerUiExpandable); value: string | number };
  }>(),
);

export const changeLayersPropSuccess = createAction(
  '[Layer] Change layers prop success',
  props<{ layers: (LayerUI | LayerUiExpandable)[]; key: keyof (LayerUI | LayerUiExpandable) }>(),
);

export const showLayer = createAction('[Layer] Show layer', props<{ id: number }>());

export const hideLayer = createAction('[Layer] Hide layer', props<{ id: number }>());

export const showLayers = createAction('[Layer] Show layers', props<{ ids: number[] }>());

export const hideLayers = createAction('[Layer] Hide layers', props<{ ids: number[] }>());

export const editLayer = createAction(
  '[Layer] Edit layer',
  props<{
    id: number;
    editedData?: SketchPlaneData;
    props?: { key: keyof (LayerUI | LayerUiExpandable); value: string | number }[];
  }>(),
);

export const editLayerSuccess = createAction(
  '[Layer] Edit layer success',
  props<{ layer: LayerUI | LayerUiExpandable }>(),
);

export const editLayers = createAction(
  '[Layer] Edit layers',
  props<{
    layers: {
      id: number;
      editedData?: SketchPlaneData;
      props?: { key: keyof (LayerUI | LayerUiExpandable); value: string | number }[];
    }[];
  }>(),
);

export const editLayersSuccess = createAction(
  '[Layer] Edit layers success',
  props<{ layers: (LayerUI | LayerUiExpandable)[] }>(),
);

export const deleteLayers = createAction(
  '[Layer] Delete layers',
  props<{ ids: number[]; isForce: boolean }>(),
);

export const deleteLayersLocal = createAction(
  '[Layer] Delete layers local',
  props<{ ids: number[] }>(),
);

export const deleteLayer = createAction(
  '[Layer] Delete layer',
  props<{ id: number; _type: LAYER_TYPES; isForce?: boolean; isHoist?: boolean }>(),
);

export const deleteLayerSuccess = createAction(
  '[Layer] Delete layer success',
  props<{ id: number; isFolder?: boolean }>(),
);

export const deleteLayerFailed = createAction(
  '[Layer] Delete layers failed',
  props<{ id: number }>(),
);

export const deleteLayersSuccess = createAction(
  '[Layer] Delete layers success',
  props<{ ids: number[] }>(),
);

export const addPointToPolygon = createAction(
  '[Layer] Add point to polygon',
  props<{ id: number; point: Vector3 }>(),
);
