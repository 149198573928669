import { Injectable, NgZone } from '@angular/core';
import { Constants, EdgesRenderer } from 'babylonjs';
import { Engine } from 'babylonjs/Engines/engine';
import { Scene } from 'babylonjs/scene';
import { CUBOID_OBJECT_NAME, POLYGON_MESH_BUILDER_NAME } from 'src/app/configs/babylon.config';

interface IEdgesRendererEx extends EdgesRenderer {
  _render(): void;
}

//@ts-ignore
// eslint-disable-next-line @typescript-eslint/unbound-method
EdgesRenderer.prototype._render = EdgesRenderer.prototype.render;
EdgesRenderer.prototype.render = function (this: IEdgesRendererEx) {
  if (
    this._source.name.includes(CUBOID_OBJECT_NAME) ||
    this._source.name.includes(POLYGON_MESH_BUILDER_NAME)
  ) {
    this._source.getScene().getEngine().setDepthFunction(Constants.ALWAYS);
  }

  this._render();
  this._source.getScene().getEngine().setDepthFunction(Constants.LEQUAL);
};

@Injectable({
  providedIn: 'root',
})
export class AnimateService {
  domContentLoaded: any;
  resize: any;

  constructor(private ngZone: NgZone) {}

  animate(engine: Engine, scene: Scene): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      const canvas = document.getElementById('renderCanvas') as HTMLCanvasElement;

      const rendererLoopCallback = () => {
        if (canvas && canvas.width !== canvas.clientWidth) {
          engine.resize();
        }
        scene.render();
      };

      if (document.readyState !== 'loading') {
        engine.runRenderLoop(rendererLoopCallback);
      } else {
        this.domContentLoaded = () => engine.runRenderLoop(rendererLoopCallback);
        window.addEventListener('DOMContentLoaded', this.domContentLoaded);
      }

      this.resize = () => engine.resize();
      window.addEventListener('resize', this.resize);
    });
  }

  destroyListeners() {
    window.removeEventListener('DOMContentLoaded', this.domContentLoaded);
    window.removeEventListener('resize', this.resize);
    this.domContentLoaded = null;
    this.resize = null;
  }
}
