import { NbComponentStatus, NbGlobalPosition } from '@nebular/theme';

const createSuccessConfig = (message: string) => ({
  message,
  title: 'Success',
  preventDuplicates: true,
});

const createWarningConfig = (message: string) => ({
  message,
  title: 'Warning',
  preventDuplicates: true,
});

export const FAILED_TOASTR_CONFIG = {
  status: 'danger' as NbComponentStatus,
  position: 'bottom-right' as NbGlobalPosition,
  icon: 'alert-circle',
  preventDuplicates: true,
};

export const SUCCESS_TOASTR_CONFIG = {
  status: 'success' as NbComponentStatus,
  position: 'bottom-right' as NbGlobalPosition,
  destroyByClick: true,
  preventDuplicates: true,
};

export const WARNING_TOASTR_CONFIG = {
  status: 'warning' as NbComponentStatus,
  position: 'bottom-right' as NbGlobalPosition,
  destroyByClick: true,
  preventDuplicates: true,
};

export const ADD_MEASUREMENT_CONFIG = createSuccessConfig('Added new measurement');

export const EDIT_MEASUREMENT_CONFIG = createSuccessConfig('Measurement edited');

export const DELETE_MEASUREMENT_CONFIG = createSuccessConfig('Measurement deleted');

export const ADD_POLYGON_CONFIG = createSuccessConfig('Added new polygon');

export const EDIT_POLYGON_CONFIG = createSuccessConfig('Polygon edited');

export const DELETE_POLYGON_CONFIG = createSuccessConfig('Polygon deleted');

export const ADD_DROP_LINE_CONFIG = createSuccessConfig('Added new drop line');

export const EDIT_DROP_LINE_CONFIG = createSuccessConfig('Drop line edited');

export const DELETE_DROP_LINE_CONFIG = createSuccessConfig('Drop line deleted');

export const ADD_FOLDER_CONFIG = createSuccessConfig('Added new folder');

export const EDIT_FOLDER_CONFIG = createSuccessConfig('Folder edited');

export const DELETE_FOLDER_CONFIG = createSuccessConfig('Folder deleted');

export const ADD_SKETCH_PLANE_CONFIG = createSuccessConfig('Added new plane');

export const EDIT_SKETCH_PLANE_CONFIG = createSuccessConfig('Plane edited');

export const DELETE_SKETCH_PLANE_CONFIG = createSuccessConfig('Plane deleted');

export const ADD_SKETCH_LINE_CONFIG = createSuccessConfig('Added new line');

export const ADD_SKETCH_LINES_CONFIG = createSuccessConfig('Added new lines');

export const EDIT_SKETCH_LINE_CONFIG = createSuccessConfig('Line edited');

export const DELETE_SKETCH_LINE_CONFIG = createSuccessConfig('Line deleted');

export const ADD_SKETCH_CIRCLE_CONFIG = createSuccessConfig('Added new circle');

export const ADD_SKETCH_CIRCLES_CONFIG = createSuccessConfig('Added new circles');

export const EDIT_SKETCH_CIRCLE_CONFIG = createSuccessConfig('Circle edited');

export const DELETE_SKETCH_CIRCLE_CONFIG = createSuccessConfig('Circle deleted');

export const ADD_MODEL_SUCCESS = createSuccessConfig('Model added');

export const ADD_MODEL_FAILED = createWarningConfig('Model addition failed');

export const DELETE_MODEL = createSuccessConfig('Model deleted');

export const EDIT_MODEL = createSuccessConfig('Model edited');

export const EDIT_MODEL_FAILED = createWarningConfig('Model edition failed');

export const INCLUDES_BASE_PLANE = createWarningConfig(
  "You can't delete the folder with base plane",
);

export const ADD_SETTING_SUCCESS = createSuccessConfig('Setting added');

export const EDIT_MODE_SETTING = createSuccessConfig('Mode setting edited');

export const ADD_PRESET_SUCCESS = createSuccessConfig('Preset added');

export const EDIT_PRESET_SUCCESS = createSuccessConfig('Preset edited');

export const INFINITE_SUCCESS_TOASTR_CONFIG = { ...SUCCESS_TOASTR_CONFIG, duration: 0 };
