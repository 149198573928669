import { Component, OnInit } from '@angular/core';
import { ATLog, ProdLog } from '@App/app/entities/files/files.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxPermissionsService } from 'ngx-permissions';
import { takeWhile } from 'rxjs/operators';
import { ProcessingService } from '../../processing.service';
import { BaseTabComponent } from './base-tab.component';

@UntilDestroy()
@Component({
  template: '',
})
export abstract class ReviewTabComponent extends BaseTabComponent implements OnInit {
  protected abstract retryPermission: string;
  protected abstract acceptPermission: string;
  protected abstract continueStep: ProcessingSteps;
  canRetry: boolean;
  canAccept: boolean;
  log: ATLog | ProdLog | null;

  constructor(
    processingService: ProcessingService,
    private permissionsService: NgxPermissionsService,
    protected modelsProcessingService: ModelsProcessingService,
  ) {
    super(processingService);
    this.onContinue = this.onContinue.bind(this);
  }

  protected abstract getLogFromProcess(process: BuildProcess): ATLog | ProdLog | null;

  ngOnInit() {
    this.canRetry = !!this.permissionsService.getPermission(this.retryPermission);
    this.canAccept = !!this.permissionsService.getPermission(this.acceptPermission);

    this.modelsProcessingService.areFilesLoaded
      .pipe(
        untilDestroyed(this),
        takeWhile(() => !this.log),
      )
      .subscribe(() => {
        const process = this.modelsProcessingService.getCurrentBuildProcess();
        this.log = process ? this.getLogFromProcess(process) : null;
      });
  }

  onContinue() {
    this.redirectToStep(this.continueStep);
  }
}
