import { CustomLODService } from '@App/app/engine/services/custom-lod-service/custom-lod.service';
import { CustomLodCacheService } from '@App/app/engine/services/custom-lod-service/services/custom-lod-cache-service/custom-lod-cache.service';
import { SketchLineMeshUtilsService } from '@App/app/engine/services/layer-services/sketches-services/shared/sketch-line-mesh-utils.service';
import { SketchesUtilsService } from '@App/app/engine/services/layer-services/sketches-services/shared/sketches-utils.service';
import { SketchCircleActionsService } from '@App/app/engine/services/layer-services/sketches-services/sketch-circle/sketch-circle-actions.service';
import { SketchCircleUtilsService } from '@App/app/engine/services/layer-services/sketches-services/sketch-circle/sketch-circle-utils.service';
import { SketchCircleService } from '@App/app/engine/services/layer-services/sketches-services/sketch-circle/sketch-circle.service';
import { SketchLineUtilsService } from '@App/app/engine/services/layer-services/sketches-services/sketch-line/sketch-line-utils.service';
import { SketchLineService } from '@App/app/engine/services/layer-services/sketches-services/sketch-line/sketch-line.service';
import { SketchPlaneGizmoService } from '@App/app/engine/services/layer-services/sketches-services/sketch-plane-gizmo.service';
import { SketchPlaneUtilsService } from '@App/app/engine/services/layer-services/sketches-services/sketch-plane-utils.service';
import { SketchPlaneService } from '@App/app/engine/services/layer-services/sketches-services/sketch-plane.service';
import { SketchRectangleInitializerService } from '@App/app/engine/services/layer-services/sketches-services/sketch-rectangle/sketch-rectangle-initializer.service';
import { SketchRectangleUtilsService } from '@App/app/engine/services/layer-services/sketches-services/sketch-rectangle/sketch-rectangle-utils.service';
import { SketchRectangleService } from '@App/app/engine/services/layer-services/sketches-services/sketch-rectangle/sketch-rectangle.service';
import { LoadModelService } from '@App/app/engine/services/load-model-service/load-model.service';
import { LoadModelUtilsService } from '@App/app/engine/services/load-model-service/services/load-model-utils-service/load-model-utils.service';
import { RegionOfInterestHttpService } from '@App/app/engine/services/region-of-interest-http-service/region-of-interest-http.service';
import { limitsReducer } from '@App/app/engine/store/reducers/limits.reducer';
import { ViewerGcpLegendComponent } from '@App/app/shared/components/viewer-gcp-legend/viewer-gcp-legend/viewer-gcp-legend.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { ResizableModule } from 'angular-resizable-element';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Ng5SliderModule } from 'ng5-slider';
import { AutofocusFixModule } from 'ngx-autofocus-fix';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { effects } from '../../app.imports';
import { EngineComponent } from '../../engine/engine.component';
import { NebularModule } from '../../modules';
import { SharedModule } from '../../shared/shared.module';
import { presetsReducer } from '../admin-panel/settings/presets-details/store/reducers/presets.reducer';
import { CameraControlsComponent } from './camera-controls/camera-controls.component';
import { CameraSnapComponent } from './camera-snap/camera-snap.component';
import { ColorPickerPresetsComponent } from './color-picker/color-picker-presets/color-picker-presets.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { CustomColorPresetComponent } from './color-picker/custom-color-preset/custom-color-preset.component';
import { CoordsToolComponent } from './coords-tool/coords-tool.component';
import { CreateAngleIronDetailsComponent } from './create-angle-iron-details/create-angle-iron-details.component';
import { CreateCuboidDetailsComponent } from './create-cuboid-details/create-cuboid-details.component';
import { CreateFolderComponent } from './create-folder/create-folder.component';
import { CreateLayerButtonComponent } from './create-layer-button/create-layer-button.component';
import { CreateLayersBarComponent } from './create-layers-bar/create-layers-bar.component';
import { CreateRectTubeDetailsComponent } from './create-rect-tube-details/create-rect-tube-details.component';
import { CreateTubeDetailsComponent } from './create-tube-details/create-tube-details.component';
import { FolderDetailsComponent } from './folder-details/folder-details.component';
import { HintsComponent } from './hints/hints.component';
import { LayerDetailsModule } from './layer-details/layer-details.module';
import { LayersBackButtonComponent } from './layers-back-button/layers-back-button.component';
import { LayersDetailsComponent } from './layers-details/layers-details.component';
import { LayersListModule } from './layers-list/layers-list.module';
import { LayersCsvExportBarComponent } from './layers/layers-csv-export-bar/layers-csv-export-bar.component';
import { LayersDxfExportBarComponent } from './layers/layers-dxf-export-bar/layers-dxf-export-bar.component';
import { LayersComponent } from './layers/layers.component';
import { ManualToolLayerDetailsComponent } from './manual-tool-details/manual-tool-details.component';
import { PhotosCarouselImageComponent } from './photos-carousel/photos-carousel-image/photos-carousel-image.component';
import { PhotosCarouselComponent } from './photos-carousel/photos-carousel.component';
import { ProcessingGcpMapComponent } from './processing/processing-gcp-map/processing-gcp-map.component';
import { PhotoGridFilterPipe } from './processing/processing-photos-grid/pipes/photo-grid-filter-pipe/photo-grid-filter.pipe';
import { SortLabelPipe } from './processing/processing-photos-grid/pipes/sort-label-pipe/sort-label.pipe';
import { ImgRectOverviewSrcPipe } from './processing/processing-photos-grid/processing-photo-dialog/pipes/img-rect-overview-src/img-rect-overview-src.pipe';
import { ProcessingPhotoDialogComponent } from './processing/processing-photos-grid/processing-photo-dialog/processing-photo-dialog.component';
import { TargetPhotoDialogComponent } from './processing/processing-photos-grid/processing-photo-dialog/target-photo-dialog/target-photo-dialog.component';
import { ProcessingPhotosGridComponent } from './processing/processing-photos-grid/processing-photos-grid.component';
import { ProcessingSidebarComponent } from './processing/processing-sidebar/processing-sidebar.component';
import { ControlPointTableComponent } from './processing/shared/control-point-table/control-point-table.component';
import { LogsDialogComponent } from './processing/shared/logs-dialog/logs-dialog.component';
import { ProcessingSettingsComponent } from './processing/shared/processing-settings/processing-settings.component';
import { TabActionsComponent } from './processing/shared/tab-actions/tab-actions.component';
import { AtReviewTabComponent } from './processing/tabs/at-review-tab/at-review-tab.component';
import { AccuracyInputComponent } from './processing/tabs/at-setup-tab/at-setup-control-points/accuracy-input/accuracy-input.component';
import { AtSetupControlPointsComponent } from './processing/tabs/at-setup-tab/at-setup-control-points/at-setup-control-points.component';
import { ControlPointsInputComponent } from './processing/tabs/at-setup-tab/at-setup-control-points/control-points-input/control-points-input.component';
import { ControlPointsListComponent } from './processing/tabs/at-setup-tab/at-setup-control-points/control-points-list/control-points-list.component';
import { MeasurementListComponent } from './processing/tabs/at-setup-tab/at-setup-control-points/measurements-list/measurement-list.component';
import { SrsDropdownComponent } from './processing/tabs/at-setup-tab/at-setup-control-points/srs-dropdown/srs-dropdown.component';
import { AtSetupTabComponent } from './processing/tabs/at-setup-tab/at-setup-tab.component';
import { PpSetupTabComponent } from './processing/tabs/pp-setup-tab/pp-setup-tab.component';
import { ProdReviewTabComponent } from './processing/tabs/prod-review-tab/prod-review-tab.component';
import { ProdSetupTabComponent } from './processing/tabs/prod-setup-tab/prod-setup-tab.component';
import { AutomappingService } from './services/automapping/automapping.service';
import { LayersHttpActionsService } from './services/layers-http-actions-service/layers-http-actions.service';
import { LayersUtilsService } from './services/layers-utils-service/layers-utils.service';
import { LayersViewerUtilsService } from './services/layers-viewer-utils-service/layers-viewer-utils.service';
import { LayersService } from './services/layers/layers.service';
import { ObjExportService } from './services/obj-export/obj-export.service';
import { layersReducer } from './store/reducers/layers.reducer';
import { TutorialVideoPopupComponent } from './tutorials-video/tutorial-video-popup/tutorial-video-popup.component';
import { ViewerComponent } from './viewer.component';

@NgModule({
  declarations: [
    AtReviewTabComponent,
    AtSetupTabComponent,
    PpSetupTabComponent,
    ProcessingSettingsComponent,
    AtSetupControlPointsComponent,
    ControlPointsListComponent,
    ControlPointTableComponent,
    MeasurementListComponent,
    SrsDropdownComponent,
    CameraControlsComponent,
    CameraSnapComponent,
    CoordsToolComponent,
    CreateFolderComponent,
    CreateLayersBarComponent,
    EngineComponent,
    HintsComponent,
    LayersComponent,
    LayersDetailsComponent,
    ProcessingSidebarComponent,
    ProcessingPhotosGridComponent,
    PhotosCarouselComponent,
    ProdReviewTabComponent,
    ProdSetupTabComponent,
    ViewerComponent,
    CreateTubeDetailsComponent,
    CreateCuboidDetailsComponent,
    CreateRectTubeDetailsComponent,
    ManualToolLayerDetailsComponent,
    TabActionsComponent,
    LogsDialogComponent,
    LayersBackButtonComponent,
    CreateAngleIronDetailsComponent,
    ColorPickerComponent,
    ColorPickerPresetsComponent,
    CustomColorPresetComponent,
    PhotosCarouselImageComponent,
    TutorialVideoPopupComponent,
    AccuracyInputComponent,
    ControlPointsInputComponent,
    PhotoGridFilterPipe,
    ProcessingGcpMapComponent,
    SortLabelPipe,
    ProcessingPhotoDialogComponent,
    TargetPhotoDialogComponent,
    ImgRectOverviewSrcPipe,
    ViewerGcpLegendComponent,
    FolderDetailsComponent,
    LayersDxfExportBarComponent,
    LayersCsvExportBarComponent,
    CreateLayerButtonComponent,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    AutofocusFixModule,
    ClipboardModule,
    CommonModule,
    DragDropModule,
    EffectsModule.forFeature(effects),
    ResizableModule,
    NebularModule,
    Ng5SliderModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('layers', layersReducer),
    StoreModule.forFeature('limits', limitsReducer),
    StoreModule.forFeature('processing-presets', presetsReducer),
    NgxSpinnerModule,
    DigitOnlyModule,
    ColorPickerModule,
    LayerDetailsModule,
    LayersListModule,
  ],
  providers: [
    SketchRectangleInitializerService,
    SketchRectangleUtilsService,
    SketchRectangleService,
    SketchLineService,
    SketchLineUtilsService,
    SketchCircleActionsService,
    SketchCircleUtilsService,
    SketchCircleService,
    SketchLineMeshUtilsService,
    SketchesUtilsService,
    SketchPlaneGizmoService,
    SketchPlaneUtilsService,
    SketchPlaneService,
    LayersService,
    LayersUtilsService,
    LayersViewerUtilsService,
    LayersHttpActionsService,
    RegionOfInterestHttpService,
    CustomLODService,
    CustomLodCacheService,
    LoadModelService,
    LoadModelUtilsService,
    ObjExportService,
    AutomappingService,
  ],
})
export class ViewerModule {}
