<div [ngClass]="isViewerMode ? 'photo_zoom_legend photo_zoom_legend__viewer' : 'photo_zoom_legend'">
  <div class="photo_zoom_legend__left_btn">
    <img [src]="leftMouseButtonIcon" />
    <p>{{ leftMouseButtonActionLabel }}</p>
  </div>
  <div class="photo_zoom_legend__move">
    <img [src]="isViewerMode ? scrollClickIcon : rightMouseButtonIcon" />
    <p>Move</p>
  </div>
  <div class="photo_zoom_legend__zoom">
    <img [src]="scrollIcon" />
    <p>Zoom</p>
  </div>
  <div *ngIf="isViewerMode" class="photo_zoom_legend__space_btn">
    <p class="space_btn">Space</p>
    <p class="plus_icon">+</p>
    <img [src]="leftMouseButtonIcon" />
    <p>Target</p>
  </div>
  <ng-container *ngIf="roi$ | async">
    <div *ngIf="isViewerMode" class="photo_zoom_legend__space_btn">
      <p class="space_btn">Shift</p>
      <p class="plus_icon">+</p>
      <img [src]="leftMouseButtonIcon" />
      <p>Move</p>
    </div>
  </ng-container>
</div>
