<div class="site_header">
  <ng-container>
    <div class="site_header__title">
      <a class="site_header__back" routerLink="{{ backUrl }}" *ngIf="backUrl; else buttonBlock">
        <nb-icon icon="arrow-ios-back" data-testId="header-back-btn"></nb-icon>
      </a>
      <ng-template #buttonBlock>
        <button
          *ngIf="backButton"
          onclick="history.back()"
          class="site_header__back"
          style="background: none; padding: 0; border: none;"
        >
          <nb-icon icon="arrow-ios-back"></nb-icon>
        </button>
      </ng-template>
      <span class="site_header__name">
        {{ label }}
      </span>
      <ng-content select="[info]"></ng-content>
    </div>
    <ng-content select="[forwardArrow]"></ng-content>
    <ng-content select="[renderSelect]"></ng-content>
    <ng-content select="[menu]"></ng-content>
  </ng-container>
</div>
