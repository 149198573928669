import { User } from '@App/app/entities/users/user';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/users.actions';

export const usersAdapter = createEntityAdapter<User>();

export interface State extends EntityState<User> {
  totalCount: number;
}

const defaultUsers: State = {
  ids: [],
  entities: {},
  totalCount: 0,
};

export const initialState: State = usersAdapter.getInitialState(defaultUsers);

export const usersReducer = createReducer(
  initialState,
  on(actions.loadAllUsersSuccess, (state, { users, totalCount }) => {
    return usersAdapter.setAll(users, { ...state, totalCount });
  }),
  on(actions.loadUserSuccess, (state, { user }) => {
    return usersAdapter.upsertOne(user, state);
  }),
  on(actions.enableUserSuccess, (state, { id }) => {
    return usersAdapter.updateOne({ id, changes: { isDisabled: false } }, state);
  }),
  on(actions.disableUserSuccess, (state, { id }) => {
    return usersAdapter.updateOne({ id, changes: { isDisabled: true } }, state);
  }),
  on(actions.removeAllUsersLocal, (state) => {
    return usersAdapter.removeAll(state);
  }),
);

export const getTotalCount = (state: State) => state.totalCount;
