import { MARKER_SYMBOL, SHADOW_SYMBOL } from '@App/app/configs/map.config';
import { DataType, Model } from '@App/app/entities/models/model.model';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as maptalks from 'maptalks';
import { filter } from 'rxjs/operators';
import {
  selectCurrentModel,
  selectCurrentModelLocation,
} from '../../../store/models/selectors/models.selectors';

@UntilDestroy()
@Component({
  selector: 'app-model-map',
  templateUrl: './model-map.component.html',
  styleUrls: ['./model-map.component.scss'],
})
export class ModelMapComponent implements OnInit {
  private model: Model<DataType>;
  private map: maptalks.Map | null = null;
  private config: maptalks.MapOptions;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectCurrentModel)
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((model: Model<DataType>) => {
        this.setModel(model);
      });

    this.store
      .select(selectCurrentModelLocation)
      .pipe(
        untilDestroyed(this),
        filter((loc) => !!(loc?.latitude && loc?.longitude)),
      )
      .subscribe(({ latitude, longitude }: GeoCoords) => {
        this.setModelsMarker(latitude, longitude);
      });
  }

  private setModel(model: Model<DataType>) {
    this.model = { ...model };
    this.setDefaultConfig();
    this.initMap();
  }

  private setDefaultConfig() {
    const loc = this.model.location;
    this.config = {
      center: [loc?.longitude || -80.0, loc?.latitude || 0.0],
      zoom: loc ? 5 : 2,
      maxZoom: 16,
      baseLayer: new maptalks.TileLayer('base', {
        urlTemplate: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        subdomains: ['a', 'b', 'c'],
      }),
      layers: [new maptalks.VectorLayer('main', [])],
    };
  }

  private initMap(): void {
    this.map?.remove();
    this.map = new maptalks.Map('overview-map', { ...this.config });
    (this.map as any).scrollWheelZoom._wheelZoomRate = 0.001;
  }

  private setModelsMarker(lat: number, lon: number): void {
    if (this.map) {
      const marker = new maptalks.Marker([lon, lat], { symbol: MARKER_SYMBOL });
      const shadow = new maptalks.Marker([lon, lat], { symbol: SHADOW_SYMBOL });
      const layer = this.map.getLayer('main') as maptalks.VectorLayer;
      layer.addGeometry([shadow, marker]);
    }
  }
}
