export const ENTER_AT_SETTINGS_QUESTION = 'Do you want to go to the aerotriangulation settings?';

export const WRONG_METADATA_INFO = `
  The list contains files with potentially wrong coordinates data.
  Files without coordinates metadata {noCoordsCount}/{totalCount}.
  Files with potentially wrong coordinates metadata: {wrongCoordsCount}/{totalCount}.`;

export const UNFINISHED_PROCESS_INFO = `
  There is unfinished upload for this model. Please, choose previous images set and
  click Start. Images that are already uploaded won't be uploaded again. 
  Please note that any redundant files included with your set will be deleted. 
  If you don't want to continue with previous upload click Cancel.
`;
