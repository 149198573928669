<nb-card class="login-card" [nbSpinner]="loading" nbSpinnerStatus="primary">
  <nb-card-body>
    <form
      class="login-form"
      [formGroup]="passwordResetForm"
      (ngSubmit)="onSubmit(passwordResetForm)"
    >
      <app-input-email
        [error]="!!error"
        controlName="email"
        [formGroup]="passwordResetForm"
      ></app-input-email>
      <input
        type="text"
        nbInput
        fullWidth
        placeholder="Code"
        name="code"
        formControlName="code"
        required
        [status]="error ? 'danger' : ''"
      />
      <app-input-password
        [error]="!!error"
        controlName="password"
        placeholder="New password"
        [formGroup]="passwordResetForm"
      ></app-input-password>
      <div class="login-error" *ngIf="error">
        {{ error }}
      </div>
      <div
        *ngIf="
          passwordResetForm.controls.password.touched &&
          passwordResetForm.controls.password.errors?.pattern
        "
      >
        <app-wrong-password-error></app-wrong-password-error>
      </div>
      <button
        type="submit"
        nbButton
        status="primary"
        [disabled]="!passwordResetForm.valid || loading"
      >
        Change password
      </button>
    </form>
    <a [routerLink]="['/password-recovery']">Back to the first step</a>
  </nb-card-body>
</nb-card>

<ng-template #dialog let-ref="dialogRef">
  <nb-card class="recovery-dialog">
    <nb-card-header>Password recovery</nb-card-header>
    <nb-card-body>Your password has been changed. You can now login with new data.</nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" (click)="finishPasswordReset(ref)">Login</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
