<div *ngIf="selectedAnnotation" class="editor" #editor data-testId="editor">
  <nb-card>
    <nb-card-header class="editor__header">
      <span>{{ editingForm ? '' : selectedAnnotation.title }}</span>
      <nb-action
        class="editor__header__close"
        nbTooltip="Close"
        icon="close-outline"
        (click)="onCancel()"
      ></nb-action>
    </nb-card-header>
    <ng-container *ngIf="editingActive; else details">
      <!--Editing Form-->
      <nb-card-body>
        <form *ngIf="editingForm" [formGroup]="editingForm" class="editor__form">
          <input
            nbInput
            data-testId="title-input"
            formControlName="title"
            class="editor__form__input"
            type="text"
            placeholder="Title"
            max="15"
            required
          />
          <textarea
            nbInput
            data-testId="description-input"
            formControlName="description"
            class="editor__form__input"
            placeholder="Description"
          ></textarea>
          <nb-select
            formControlName="priority"
            class="editor__form__input"
            [selected]="this.editingForm.controls.priority"
            data-testId="priority-input"
          >
            <nb-select-label>
              Priority: {{ this.editingForm.controls.priority.value.toUpperCase() }}
            </nb-select-label>
            <nb-option value="low">Low</nb-option>
            <nb-option value="normal">Normal</nb-option>
            <nb-option value="high">High</nb-option>
          </nb-select>
        </form>
      </nb-card-body>
      <nb-card-footer class="editor__actions">
        <div>
          <button
            *ngIf="isSelectedAnnotationSaved()"
            data-testId="delete-btn"
            nbButton
            ghost
            (click)="onDelete()"
          >
            <nb-icon icon="trash-2-outline" status="danger"></nb-icon>
          </button>
        </div>
        <div>
          <button nbButton (click)="onCancel()">Cancel</button>
          <button nbButton (click)="onSave()" status="primary" data-testId="save-btn">Save</button>
        </div>
      </nb-card-footer>
    </ng-container>
  </nb-card>
</div>

<ng-template #details>
  <!--Details Displaying-->
  <nb-card-body class="editor__details__body">
    <span *ngIf="selectedAnnotation?.description?.length; else noContent">{{
      selectedAnnotation?.description
    }}</span>
    <ng-template #noContent>
      <i class="editor__details__body__no_content">No content</i>
    </ng-template>
  </nb-card-body>
  <nb-card-footer class="editor__details__footer">
    <div>
      <span> Priority: </span>
      <span>
        {{ selectedAnnotation?.priority | titlecase }}
      </span>
    </div>
  </nb-card-footer>
</ng-template>

<ng-container *ngIf="visible">
  <div
    *ngFor="let annotation of annotations"
    class="label"
    data-testId="label"
    [ngClass]="getAnnotationLabelClass(annotation)"
    [ngStyle]="getAnnotationLabelStyle(annotation)"
  >
    {{ annotation.title }}
  </div>
</ng-container>
