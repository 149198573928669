import { Environment } from '@App/app/entities/shared/environment.model';

export const environment: Environment = {
  name: 'prod',
  production: true,
  loginUrl: 'https://mnxk13xhz1.execute-api.us-east-2.amazonaws.com/v1',
  mapboxAccessToken:
    'pk.eyJ1IjoicGlrbG9wMTIzIiwiYSI6ImNsM24weHl3ejBhMmQzZW85ZDJqdXNsbnIifQ._DNMUINR_Gd6iXaKG92Orw',
  sonyEnv: {
    enabled: false,
    url: 'https://d27abzfibl908.cloudfront.net',
    bucket: 'dxc-exqa-storage-upload',
  },
};
