import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RegionOfInterestHttpService } from '../../services/region-of-interest-http-service/region-of-interest-http.service';
import { addRoiLimit, addRoiLimitFailure, addRoiLimitSuccess } from '../actions/limits.actions';

@Injectable()
export class AddLimitEffects {
  constructor(
    private actions: Actions,
    private regionOfInterestHttpService: RegionOfInterestHttpService,
  ) {}

  addLimit = createEffect(() => {
    return this.actions.pipe(
      ofType(addRoiLimit),
      mergeMap(({ limit }) => {
        return this.regionOfInterestHttpService.postRoiLimit(limit).pipe(
          map((roi) => {
            return addRoiLimitSuccess({ limit: roi });
          }),
          catchError(() => of(addRoiLimitFailure())),
        );
      }),
    );
  });
}
