<nb-card class="doc_acceptance">
  <nb-card-header class="doc_acceptance__header">Review and sign the agreements</nb-card-header>
  <nb-card-body>
    <nb-accordion>
      <nb-accordion-item
        *ngFor="let item of DOCS | keyvalue: asIsOrder; let i = index"
        class="doc_acceptance__file"
        [collapsed]="!!i"
        data-testId="docs-file-row"
      >
        <nb-accordion-item-header>
          <nb-icon class="doc_acceptance__file__icon" icon="file-outline"></nb-icon>
          <span>{{ item.key }}</span>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <pdf-viewer
            [src]="item.value"
            [render-text]="true"
            [original-size]="true"
            [fit-to-page]="false"
            class="doc_acceptance__file__preview"
          ></pdf-viewer>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-card-body>
  <nb-card-footer class="doc_acceptance__footer">
    <button nbButton status="info" (click)="onAcceptClick()">Accept</button>
  </nb-card-footer>
</nb-card>
