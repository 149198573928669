import { BoundingBoxGizmo, Mesh } from 'babylonjs';
import { BaseDxfExportableViewerLayer } from './base-dxf-exportable-viewer-layer.model';
import { ANGLE_IRON_PART, LAYER_TYPES } from './enums/layer-types.enum';
import { Layer } from './layer.model';
import { Coordinates3, Coordinates4 } from './measurements/coordinates';
import { NameTag } from './name-tag.model';

export class AngleIron implements Layer<AngleIronData> {
  id: number;
  name: string;
  createdAt: string;
  editedAt: string;
  data: AngleIronData = {};
  parentId: number;
  type = LAYER_TYPES.ANGLE_IRON;
  isVisible = false;
  isSaved = true;
  description: string;
}
export interface AngleIronData {
  absoluteRotationQuaternion?: Coordinates4;
  boxes?: [AngleIronBox, AngleIronBox];
  length?: number;
}

export interface ViewerAngleIronLayer extends AngleIron, BaseDxfExportableViewerLayer {
  mesh?: Mesh;
  gui?: NameTag;
  gizmoManager?: BoundingBoxGizmo;
  data: AngleIronData;
}

export interface AngleIronPartsArrays<T> {
  [ANGLE_IRON_PART.SIDE_1_OUTER]: T[];
  [ANGLE_IRON_PART.SIDE_1_INNER]: T[];
  [ANGLE_IRON_PART.SIDE_2_OUTER]: T[];
  [ANGLE_IRON_PART.SIDE_2_INNER]: T[];
}

export interface AngleIronPartsValues<T> {
  [ANGLE_IRON_PART.SIDE_1_OUTER]: T;
  [ANGLE_IRON_PART.SIDE_1_INNER]: T;
  [ANGLE_IRON_PART.SIDE_2_OUTER]: T;
  [ANGLE_IRON_PART.SIDE_2_INNER]: T;
}

export interface AngleIronBox {
  min?: Coordinates3;
  max?: Coordinates3;
}

export const angleIronMesh = {
  defaultName: 'angleIron',
};
