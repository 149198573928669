/* eslint-disable max-lines */
import { BabylonNodesService } from '@App/app/engine/services/babylon-nodes-service/babylon-nodes.service';
import {
  AbstractMesh,
  Color3,
  Color4,
  Mesh,
  PointerDragBehavior,
  StandardMaterial,
  Vector3,
} from 'babylonjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { ELECTRIC_BLUE_COLOR } from '../../configs/babylon.config';
import { Coordinates3 } from '../layer/measurements/coordinates';
import { BoundingVectors } from '../viewer/bounding-vectors.model';
import { CustomScene } from '../viewer/custom-scene.model';

export class RegionOfInterest {
  private _dimensions$ = new BehaviorSubject<{ length: number; height: number; width: number }>({
    length: 20,
    height: 20,
    width: 20,
  });
  dimensions$ = this._dimensions$.asObservable();
  root: AbstractMesh | null;
  sides: Mesh[];
  size: number;
  minSize: number;
  private oldPos: Coordinates3 = { x: 0, y: 0, z: 0 };
  private oldPosMinus: Coordinates3 = { x: 0, y: 0, z: 0 };
  maxSizeX: number;
  maxSizeY: number;
  maxSizeZ: number;
  mat: StandardMaterial;

  constructor(
    size: number,
    scene: CustomScene,
    babylonNodesService: BabylonNodesService,
    maxSizeX: number,
    maxSizeY: number,
    maxSizeZ: number,
    shiftKeyPressed$: Observable<boolean>,
    minSize: number = 10,
  ) {
    const checkIfShiftIsPressed = () => {
      let isPressed = false;
      shiftKeyPressed$.subscribe((value) => {
        isPressed = value;
      });
      return isPressed;
    };
    this.size = size;
    this.minSize = minSize;
    this.maxSizeX = maxSizeX;
    this.maxSizeY = maxSizeY;
    this.maxSizeZ = maxSizeZ;
    this.root = new AbstractMesh(RoiNames.root, scene);
    this.mat = new StandardMaterial(RoiNames.mat, scene);
    this.mat.diffuseColor = Color3.FromHexString(ELECTRIC_BLUE_COLOR);
    this.mat.alpha = 0.6;
    this.sides = new Array<Mesh | null>(6).fill(null).map((_, i) => {
      const side = babylonNodesService.createPlane(
        `${RoiNames.side}${i}`,
        {
          size,
          updatable: true,
        },
        this.mat,
      );

      side.parent = this.root;
      side.enableEdgesRendering();
      side.edgesWidth = 25.0;
      side.edgesColor = Color4.FromHexString(`${ELECTRIC_BLUE_COLOR}FF`);
      return side;
    });

    const vectorX = new Vector3(1, 0, 0);
    const vectorY = new Vector3(0, 1, 0);
    const vectorZ = new Vector3(0, 0, 1);

    const pointerDragBehavior0 = new PointerDragBehavior({
      dragAxis: vectorX.clone(),
    });
    pointerDragBehavior0.useObjectOrientationForDragging = false;

    pointerDragBehavior0.validateDrag = (targetPosition) => {
      if (checkIfShiftIsPressed()) {
        return !(targetPosition.x > this.sides[Sides.xMax].position.x - this.minSize);
      }
      return !(
        targetPosition.x > this.sides[Sides.xMax].position.x - this.minSize ||
        maxSizeX < this.sides[Sides.xMax].position.x - this.sides[Sides.xMin].position.x
      );
    };

    const pointerDragBehavior1 = new PointerDragBehavior({
      dragAxis: vectorZ.clone(),
    });
    pointerDragBehavior1.useObjectOrientationForDragging = false;
    pointerDragBehavior1.validateDrag = (targetPosition) => {
      if (checkIfShiftIsPressed()) {
        return !(targetPosition.z < this.sides[Sides.zMin].position.z + this.minSize);
      }
      return !(
        targetPosition.z < this.sides[Sides.zMin].position.z + this.minSize ||
        maxSizeZ < this.sides[Sides.zMax].position.z - this.sides[Sides.zMin].position.z
      );
    };

    const pointerDragBehavior2 = new PointerDragBehavior({
      dragAxis: vectorX.clone(),
    });
    pointerDragBehavior2.useObjectOrientationForDragging = false;
    pointerDragBehavior2.validateDrag = (targetPosition) => {
      if (checkIfShiftIsPressed()) {
        return !(targetPosition.x < this.sides[Sides.xMin].position.x + this.minSize);
      }
      return !(
        targetPosition.x < this.sides[Sides.xMin].position.x + this.minSize ||
        maxSizeX < this.sides[Sides.xMax].position.x - this.sides[Sides.xMin].position.x
      );
    };

    const pointerDragBehavior3 = new PointerDragBehavior({
      dragAxis: vectorY.clone(),
    });
    pointerDragBehavior3.useObjectOrientationForDragging = false;
    pointerDragBehavior3.validateDrag = (targetPosition) => {
      if (checkIfShiftIsPressed()) {
        return !(targetPosition.y < this.sides[Sides.yMin].position.y + this.minSize);
      }
      return !(
        targetPosition.y < this.sides[Sides.yMin].position.y + this.minSize ||
        maxSizeY < this.sides[Sides.yMax].position.y - this.sides[Sides.yMin].position.y
      );
    };

    const pointerDragBehavior4 = new PointerDragBehavior({
      dragAxis: vectorY.clone(),
    });
    pointerDragBehavior4.useObjectOrientationForDragging = false;
    pointerDragBehavior4.validateDrag = (targetPosition) => {
      if (checkIfShiftIsPressed()) {
        return !(targetPosition.y > this.sides[Sides.yMax].position.y - this.minSize);
      }
      return !(
        targetPosition.y > this.sides[Sides.yMax].position.y - this.minSize ||
        maxSizeY < this.sides[Sides.yMax].position.y - this.sides[Sides.yMin].position.y
      );
    };

    const pointerDragBehavior5 = new PointerDragBehavior({
      dragAxis: vectorZ.clone(),
    });
    pointerDragBehavior5.useObjectOrientationForDragging = false;
    pointerDragBehavior5.validateDrag = (targetPosition) => {
      if (checkIfShiftIsPressed()) {
        return !(targetPosition.z > this.sides[Sides.zMax].position.z - this.minSize);
      }
      return !(
        targetPosition.z > this.sides[Sides.zMax].position.z - this.minSize ||
        maxSizeZ < this.sides[Sides.zMax].position.z - this.sides[Sides.zMin].position.z
      );
    };

    this.sides[Sides.xMax].position = new Vector3(size / 2, 0, 0); // +x
    this.oldPos.x = this.sides[Sides.xMax].position.x;
    this.sides[Sides.xMax].rotation = new Vector3(0, -Math.PI / 2, 0);
    this.sides[Sides.xMax].addBehavior(pointerDragBehavior2);

    this.sides[Sides.yMax].position = new Vector3(0, size / 2, 0); // +y
    this.oldPos.y = this.sides[Sides.yMax].position.y;
    this.sides[Sides.yMax].rotation = new Vector3(Math.PI / 2, 0, 0);
    this.sides[Sides.yMax].addBehavior(pointerDragBehavior3);

    this.sides[Sides.zMax].position = new Vector3(0, 0, size / 2); // +z
    this.oldPos.z = this.sides[Sides.zMax].position.z;
    this.sides[Sides.zMax].rotation = new Vector3(0, Math.PI, 0);
    this.sides[Sides.zMax].addBehavior(pointerDragBehavior1);

    this.sides[Sides.xMin].position = new Vector3(-size / 2, 0, 0); // -x
    this.oldPosMinus.x = this.sides[Sides.xMin].position.x;
    this.sides[Sides.xMin].rotation = new Vector3(0, Math.PI / 2, 0);
    this.sides[Sides.xMin].addBehavior(pointerDragBehavior0);

    this.sides[Sides.yMin].position = new Vector3(0, -size / 2, 0); // -y
    this.oldPosMinus.y = this.sides[Sides.yMin].position.y;
    this.sides[Sides.yMin].rotation = new Vector3(-Math.PI / 2, 0, 0);
    this.sides[Sides.yMin].addBehavior(pointerDragBehavior4);

    this.sides[Sides.zMin].position = new Vector3(0, 0, -size / 2); // -z
    this.oldPosMinus.z = this.sides[Sides.zMin].position.z;
    this.sides[Sides.zMin].rotation = new Vector3(0, 0, 0);
    this.sides[Sides.zMin].addBehavior(pointerDragBehavior5);

    // -x
    pointerDragBehavior0.onDragObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxBackwards(Sides.xMin, Sides.xMax, Axis.x);
        this.adjustSides(Sides.xMin, Sides.xMax, Axis.x);
        this.oldPosMinus.x = this.sides[Sides.xMin].position.x;
      } else {
        this.adjustSides(Sides.xMin, Sides.xMax, Axis.x);
        this.oldPosMinus.x = this.sides[Sides.xMin].position.x;
        this.updateDimensions();
      }
    });
    pointerDragBehavior0.onDragEndObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxBackwards(Sides.xMin, Sides.xMax, Axis.x);
      }
      if (this.sides[Sides.xMax].position.x - this.sides[Sides.xMin].position.x > this.maxSizeX) {
        this.sides[Sides.xMin].position.x +=
          this.sides[Sides.xMax].position.x - this.sides[Sides.xMin].position.x - this.maxSizeX;
      }
      this.adjustSides(Sides.xMin, Sides.xMax, Axis.x);
      this.oldPos.x = this.sides[Sides.xMax].position.x;
      this.oldPosMinus.x = this.sides[Sides.xMin].position.x;
      this.updateDimensions();
    });

    // +z
    pointerDragBehavior1.onDragObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxForward(Sides.zMax, Sides.zMin, Axis.z);
        this.adjustSides(Sides.zMax, Sides.zMin, Axis.z);
        this.oldPos.z = this.sides[Sides.zMax].position.z;
      } else {
        this.adjustSides(Sides.zMax, Sides.zMin, Axis.z);
        this.oldPos.z = this.sides[Sides.zMax].position.z;
        this.updateDimensions();
      }
    });
    pointerDragBehavior1.onDragEndObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxForward(Sides.zMax, Sides.zMin, Axis.z);
      }
      if (this.sides[Sides.zMax].position.z - this.sides[Sides.zMin].position.z > this.maxSizeZ) {
        this.sides[Sides.zMax].position.z -=
          this.sides[Sides.zMax].position.z - this.sides[Sides.zMin].position.z - this.maxSizeZ;
      }
      this.adjustSides(Sides.zMax, Sides.zMin, Axis.z);
      this.oldPosMinus.z = this.sides[Sides.zMin].position.z;
      this.oldPos.z = this.sides[Sides.zMax].position.z;
      this.updateDimensions();
    });

    // +x
    pointerDragBehavior2.onDragObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxForward(Sides.xMax, Sides.xMin, Axis.x);
        this.oldPos.x = this.sides[Sides.xMax].position.x;
      } else {
        this.adjustSides(Sides.xMax, Sides.xMin, Axis.x);
        this.oldPos.x = this.sides[Sides.xMax].position.x;
        this.updateDimensions();
      }
    });
    pointerDragBehavior2.onDragEndObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxForward(Sides.xMax, Sides.xMin, Axis.x);
      }
      if (this.sides[Sides.xMax].position.x - this.sides[Sides.xMin].position.x > this.maxSizeX) {
        this.sides[Sides.xMax].position.x -=
          this.sides[Sides.xMax].position.x - this.sides[Sides.xMin].position.x - this.maxSizeX;
      }
      this.adjustSides(Sides.xMax, Sides.xMin, Axis.x);
      this.oldPosMinus.x = this.sides[Sides.xMin].position.x;
      this.oldPos.x = this.sides[Sides.xMax].position.x;
      this.updateDimensions();
    });

    // +y
    pointerDragBehavior3.onDragObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxForward(Sides.yMax, Sides.yMin, Axis.y);
        this.oldPos.y = this.sides[Sides.yMax].position.y;
      } else {
        this.adjustSides(Sides.yMax, Sides.yMin, Axis.y);
        this.oldPos.y = this.sides[Sides.yMax].position.y;
        this.updateDimensions();
      }
    });
    pointerDragBehavior3.onDragEndObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxForward(Sides.yMax, Sides.yMin, Axis.y);
      }
      if (this.sides[Sides.yMax].position.y - this.sides[Sides.yMin].position.y > this.maxSizeY) {
        this.sides[Sides.yMax].position.y -=
          this.sides[Sides.yMax].position.y - this.sides[Sides.yMin].position.y - this.maxSizeY;
      }
      this.adjustSides(Sides.yMax, Sides.yMin, Axis.y);
      this.oldPosMinus.y = this.sides[Sides.yMin].position.y;
      this.oldPos.y = this.sides[Sides.yMax].position.y;
      this.updateDimensions();
    });
    // -y
    pointerDragBehavior4.onDragObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxBackwards(Sides.yMin, Sides.yMax, Axis.y);
        this.oldPosMinus.y = this.sides[Sides.yMin].position.y;
      } else {
        this.adjustSides(Sides.yMin, Sides.yMax, Axis.y);
        this.oldPosMinus.y = this.sides[Sides.yMin].position.y;
        this.updateDimensions();
      }
    });
    pointerDragBehavior4.onDragEndObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxBackwards(Sides.yMin, Sides.yMax, Axis.y);
      }
      if (this.sides[Sides.yMax].position.y - this.sides[Sides.yMin].position.y > this.maxSizeY) {
        this.sides[Sides.yMin].position.y +=
          this.sides[Sides.yMax].position.y - this.sides[Sides.yMin].position.y - this.maxSizeY;
      }
      this.adjustSides(Sides.yMin, Sides.yMax, Axis.y);
      this.oldPos.y = this.sides[Sides.yMax].position.y;
      this.oldPosMinus.y = this.sides[Sides.yMin].position.y;
      this.updateDimensions();
    });

    // -z
    pointerDragBehavior5.onDragObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxBackwards(Sides.zMin, Sides.zMax, Axis.z);
        this.oldPosMinus.z = this.sides[Sides.zMin].position.z;
      } else {
        this.adjustSides(Sides.zMin, Sides.zMax, Axis.z);
        this.oldPosMinus.z = this.sides[Sides.zMin].position.z;
        this.updateDimensions();
      }
    });
    pointerDragBehavior5.onDragEndObservable.add(() => {
      if (checkIfShiftIsPressed()) {
        this.moveBoxBackwards(Sides.zMin, Sides.zMax, Axis.z);
      }
      if (this.sides[Sides.zMax].position.z - this.sides[Sides.zMin].position.z > this.maxSizeZ) {
        this.sides[Sides.zMin].position.z +=
          this.sides[Sides.zMax].position.z - this.sides[Sides.zMin].position.z - this.maxSizeZ;
      }
      this.adjustSides(Sides.zMin, Sides.zMax, Axis.z);
      this.oldPos.z = this.sides[Sides.zMax].position.z;
      this.oldPosMinus.z = this.sides[Sides.zMin].position.z;
      this.updateDimensions();
    });
  }

  setSidesToBoundingVectors(vectors: BoundingVectors) {
    this.sides.forEach((side, index) => {
      const axis = Sides[index].charAt(0) as keyof Vector3;

      const orientation = Sides[index].slice(1).toLowerCase() as keyof BoundingVectors;
      (side.position as any)[axis] = vectors[orientation][axis];
      this.adjustSides(index, index > 2 ? index - 3 : index + 3, Axis[axis as Axis]);

      this.oldPos.y = vectors.max.y;
      this.oldPosMinus.y = vectors.min.y;
      this.oldPos.x = vectors.max.x;
      this.oldPosMinus.x = vectors.min.x;
      this.oldPos.z = vectors.max.z;
      this.oldPosMinus.z = vectors.min.z;
      this.updateDimensions();
    });
  }

  private adjustSides(dragged: Sides, counter: Sides, axis: Axis) {
    this.sides.forEach((side, index) => {
      if (index !== dragged && index !== counter) {
        this.setSideScaling(side, index, dragged, counter, axis);
        const pos1 = side.position.clone();
        pos1[axis] = this.sides[dragged].position[axis];
        const pos2 = side.position.clone();
        pos2[axis] = this.sides[counter].position[axis];
        side.position = Vector3.Center(pos1, pos2);
      }
    });
  }

  private movingBox(dragged: Sides, counter: Sides, axis: Axis, forward: boolean) {
    let positionUpdated = false;
    let diff = 0;
    this.sides.forEach((side, index) => {
      if (index !== dragged && index !== counter) {
        const pos1 = side.position.clone();
        pos1[axis] = this.sides[dragged].position[axis];
        const pos2 = side.position.clone();
        pos2[axis] = this.sides[counter].position[axis];

        if (forward) {
          diff = pos1[axis] - this.oldPos[axis];
        } else {
          diff = pos1[axis] - this.oldPosMinus[axis];
        }

        if (!positionUpdated) {
          positionUpdated = true;
          this.sides[counter].position[axis] += diff;
        }
        side.position = Vector3.Center(pos1, pos2);
        this.adjustSides(dragged, counter, axis);
      }
    });
  }

  private moveBoxForward(dragged: Sides, counter: Sides, axis: Axis) {
    this.movingBox(dragged, counter, axis, true);
  }

  private moveBoxBackwards(dragged: Sides, counter: Sides, axis: Axis) {
    this.movingBox(dragged, counter, axis, false);
  }

  private setSideScaling(side: Mesh, index: number, dragged: Sides, counter: Sides, axis: Axis) {
    const diff = this.axisDistance(
      this.sides[dragged].position[axis],
      this.sides[counter].position[axis],
    );
    const ratio = Math.abs(diff) / this.size;
    if (axis === Axis.x) {
      side.scaling = new Vector3(ratio, side.scaling.y, side.scaling.z);
    } else if (axis === Axis.y) {
      side.scaling = new Vector3(side.scaling.x, ratio, side.scaling.z);
    } else if (axis === Axis.z) {
      side.scaling = this.calculateSideScaling(index, ratio, side);
    }
  }

  private calculateSideScaling(index: number, ratio: number, side: Mesh): Vector3 {
    return index === Sides.xMin || index === Sides.xMax
      ? new Vector3(ratio, side.scaling.y, side.scaling.z)
      : new Vector3(side.scaling.x, ratio, side.scaling.z);
  }

  private updateDimensions() {
    const newDimensions = {
      length: this.oldPos.x - this.oldPosMinus.x,
      height: this.oldPos.y - this.oldPosMinus.y,
      width: this.oldPos.z - this.oldPosMinus.z,
    };
    this._dimensions$.next(newDimensions);
  }

  private axisDistance(point1: number, point2: number) {
    return point1 > point2 ? point1 - point2 : point2 - point1;
  }

  public dispose() {
    for (const sideElement of this.sides) {
      sideElement.dispose();
    }
    this.sides = [];
    this.root?.dispose();
    this.root = null;
  }

  getCenter() {
    return new Vector3(
      (this.oldPosMinus.x + this.oldPos.x) / 2,
      (this.oldPosMinus.y + this.oldPos.y) / 2,
      (this.oldPosMinus.z + this.oldPos.z) / 2,
    );
  }
}

export enum RoiNames {
  root = 'RegionOfInterest',
  side = 'RegionOfInterestSide',
  mat = 'RegionOfInterestMaterial',
}

export enum Axis {
  x = 'x',
  y = 'y',
  z = 'z',
}

export enum Sides {
  xMax,
  yMax,
  zMax,
  xMin,
  yMin,
  zMin,
}
