import { BuildProcessTypeId } from './build-process-status.model';
import { BuildProcess } from './build-process.model';
import { ProcessingSteps } from './processing-steps.model';

export interface BuildProcessStepsList {
  upload: UploadStep;
  at: AerotriangulationStep;
  reconstruct: ReconstructionStep;
  prod: ProductionStep;
  pp: PostProductionStep;
}

export abstract class BuildProcessStep {
  abstract type: BuildProcessTypeId;

  constructor(protected process: BuildProcess) {}

  isCurrent() {
    return this.process.buildProcessTypeId === this.type;
  }

  isNotAlready() {
    return this.process.buildProcessTypeId > this.type;
  }

  isNotYet() {
    return this.process.buildProcessTypeId < this.type;
  }

  abstract getAvailableStep(): ProcessingSteps | 0;

  abstract getActiveStep(): ProcessingSteps | 0;
}

export class UploadStep extends BuildProcessStep {
  type = BuildProcessTypeId.Upload;

  allowAccessToProcess() {
    const isUpload = this.isCurrent();
    const proc = this.process;
    return !(isUpload && (!proc.isFinished() || proc.runAerotriangulationAutomatically));
  }

  getAvailableStep() {
    return ProcessingSteps.AT_SETUP;
  }

  getActiveStep() {
    return ProcessingSteps.AT_SETUP;
  }
}

export class AerotriangulationStep extends BuildProcessStep {
  type = BuildProcessTypeId.AT;

  isStarted() {
    return this.process.buildProcessTypeId > 0;
  }

  isCancelled() {
    return this.process.isFinished() && !this.process.aerotriangulationAccepted;
  }

  isWaiting() {
    return (
      !this.process.runAerotriangulationAutomatically &&
      this.process.steps.upload.isCurrent() &&
      this.process.isFinished()
    );
  }

  getAvailableStep() {
    return this.process.isFinished()
      ? ProcessingSteps.PROD_SETUP
      : this.process.isFailed()
      ? ProcessingSteps.AT_REVIEW
      : ProcessingSteps.AT_SETUP;
  }

  getActiveStep() {
    return this.process.isFinished() || this.process.isFailed()
      ? ProcessingSteps.AT_REVIEW
      : ProcessingSteps.AT_SETUP;
  }
}

export class ReconstructionStep extends BuildProcessStep {
  type = BuildProcessTypeId.Reconstruction;

  getAvailableStep() {
    return 0;
  }

  getActiveStep() {
    return 0;
  }
}

export class ProductionStep extends BuildProcessStep {
  type = BuildProcessTypeId.Prod;

  isStarted() {
    return (
      this.process.isFinished() &&
      this.process.steps.at.isCurrent() &&
      this.process.aerotriangulationAccepted
    );
  }

  isCanceled() {
    return this.process.isFinished() && !this.process.productionAccepted;
  }

  getAvailableStep() {
    return this.process.isFinished()
      ? ProcessingSteps.PP_SETUP
      : this.process.isFailed()
      ? ProcessingSteps.PROD_REVIEW
      : ProcessingSteps.PROD_SETUP;
  }

  getActiveStep() {
    return this.process.isFinished() || this.process.isFailed()
      ? ProcessingSteps.PROD_REVIEW
      : ProcessingSteps.PROD_SETUP;
  }
}

export class PostProductionStep extends BuildProcessStep {
  type = BuildProcessTypeId.PP;

  isStarted() {
    return (
      this.process.isFinished() &&
      this.process.steps.prod.isCurrent() &&
      this.process.productionAccepted
    );
  }

  getAvailableStep() {
    return ProcessingSteps.PP_SETUP;
  }

  getActiveStep() {
    return ProcessingSteps.PP_SETUP;
  }
}
