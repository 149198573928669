<div class="layer_details__subtitle geolocation">Geolocation:</div>

<div class="layer_details__row left">
  <div class="layer_details__label">lat:</div>
  <div class="layer_details__value" *ngIf="baseGeoPosition; else noGeolocationTpl">
    {{ calculateGeoCords(position).latitude }}
  </div>
</div>
<div class="layer_details__row left">
  <div class="layer_details__label">lng:</div>
  <div class="layer_details__value" *ngIf="baseGeoPosition; else noGeolocationTpl">
    {{ calculateGeoCords(position).longitude }}
  </div>
</div>
<button
  *ngIf="baseGeoPosition"
  type="button"
  class="layer_details__action layer_details__action--edit layer_details__copy select-none"
  (click)="copyCoordinates(position)"
>
  Copy
</button>
<div *ngIf="!baseGeoPosition" class="layer_details__break"></div>

<ng-template #noGeolocationTpl>
  <div class="layer_details__value">
    <span>N/A</span>
    <nb-icon
      icon="info"
      class="layer_details__info_icon"
      nbTooltip="Specify location in model details to make geolocation available"
      nbTooltipStatus="primary"
    ></nb-icon>
  </div>
</ng-template>
