import { Component, ViewEncapsulation } from '@angular/core';
import { User } from '@App/app/entities/users/user';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent {
  user: User;

  constructor() {}
}
