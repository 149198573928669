import { Injectable } from '@angular/core';
import { ApiService } from '@App/app/shared/api.service';
import { NgHttpCachingHeaders } from 'ng-http-caching';

@Injectable({
  providedIn: 'root',
})
export class RendersHttpService extends ApiService {
  getRenders(modelId: number) {
    return this.http.get(`${this.api}/models/${modelId}/renders`);
  }

  getExtendedRender(modelId: number, renderId: number) {
    return this.http.get(`${this.api}/models/${modelId}/renders/${renderId}`, {
      headers: {
        [NgHttpCachingHeaders.ALLOW_CACHE]: '1',
      },
    });
  }

  getAnnotationsReport(modelId: number, renderId: number) {
    return this.http.get(`${this.api}/models/${modelId}/renders/${renderId}/annotationsReport`);
  }
}
