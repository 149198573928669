<app-manual-tool-layer-details
  *ngIf="activeLayerEvent === LAYER_EVENTS.ADD_CUBOID && isDetailsVisible"
  [samples]="cuboidSamples"
  [partName]="CUBOID_PART_NAME"
  [sampleHighlighted]="cuboidHighlightedSample"
  [generateAvailable]="isGenerateAvailable"
  [activePart]="cuboidActivePart"
  (setPartActive)="setCuboidActivePart($event)"
  (highlightSample)="toggleHighlightCuboidSample($event)"
  (deleteSample)="onDeleteCuboidSample($event)"
  (generate)="onGenerate()"
  (cancel)="onCancel()"
>
</app-manual-tool-layer-details>
