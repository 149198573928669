<div
  mwlResizable
  class="layers_container"
  [@sidebarAnimation]="(isBarVisible$ | async) ? 'visible' : 'hidden'"
  [style.width]="(barWidth$ | async) + 'px'"
  [validateResize]="barValidator"
  [resizeSnapGrid]="{ left: 20, right: 20 }"
  (resizing)="onResize($event)"
>
  <div class="layers">
    <div class="layers__header select-none">
      <div class="layers__header_title">Layers</div>

      <div class="layers__header_total">total: {{ layersCount$ | async }}</div>

      <div class="layers__header_actions">
        <ng-container *ngxPermissionsOnly="permissions.CAN_IMPORT_AUTOMAPPING">
          <button
            *ngIf="automappingPath"
            nbButton
            status="primary"
            size="tiny"
            nbTooltip="Import autogenerated mappings"
            nbTooltipStatus="primary"
            nbTooltipPlacement="bottom"
            class="layers__button layers__button__header"
            (click)="openAutomappingDialog()"
            [disabled]="automappingLoading$ | async"
            [nbSpinner]="(automappingLoading$ | async) ?? false"
            data-testId="import-automapping-button"
          >
            <nb-icon icon="download-outline"></nb-icon>
            Import
          </button>
        </ng-container>

        <button
          class="layers__button layers__button__header"
          nbTooltip="Export data"
          nbTooltipStatus="primary"
          nbTooltipPlacement="bottom"
          size="tiny"
          nbButton
          status="primary"
          data-testId="export-mappings-button"
          type="button"
          [nbContextMenu]="exportOptions"
          [nbContextMenuTag]="exportsContextTag"
        >
          <nb-icon icon="more-vertical-outline"></nb-icon>
          <span>Exports</span>
        </button>

        <button
          class="layers__header_action layers__import_automapping"
          nbTooltip="Pick coordinates"
          nbTooltipStatus="primary"
          nbTooltipPlacement="bottom"
          (click)="isGeoCoordsToolActive ? toggleGeoCoordsTool() : toggleAxisCoordsTool()"
        >
          <svg-icon
            [src]="coordsIcon"
            class="layers__header_svg"
            [ngClass]="{ 'layers__header_svg--active': isGeoCoordsToolActive }"
          ></svg-icon>
        </button>
        <div
          *ngIf="!(isLocationSpecified$ | async)"
          class="layers__header_action"
          nbTooltip="Specify location in model details to use geolocation picker"
          nbTooltipStatus="primary"
        >
          <svg-icon
            [src]="markerIcon"
            class="layers__header_svg layers__header_svg--disabled"
          ></svg-icon>
        </div>
      </div>
    </div>
    <div class="layers__body">
      <ng-container *ngIf="!(isCreateFolderActive$ | async) && !(isFolderDetailsActive$ | async)">
        <app-layers-list *ngIf="!(isDetailsViewActive$ | async)"></app-layers-list>
        <app-layers-details
          [unitsSystem]="(unitsSystem$ | async) ?? UnitsSystem.Imperial"
          *ngIf="isDetailsViewActive$ | async"
        ></app-layers-details>
      </ng-container>

      <app-create-folder *ngIf="isCreateFolderActive$ | async"></app-create-folder>

      <app-folder-details
        *ngIf="isFolderDetailsActive$ | async as folder"
        [folder]="folder"
      ></app-folder-details>

      <div
        *ngIf="(isDetailsViewActive$ | async) || (isFolderDetailsActive$ | async)"
        class="layers__body__exports"
      >
        <app-layers-dxf-export-bar></app-layers-dxf-export-bar>
        <app-layers-csv-export-bar></app-layers-csv-export-bar>
      </div>

      <app-create-layers-bar *ngIf="!(isCreateFolderActive$ | async)"></app-create-layers-bar>
    </div>
    <div class="layers__coords" *ngIf="isCoordsToolActive || isGeoCoordsToolActive">
      <app-coords-tool></app-coords-tool>
    </div>
  </div>
  <div>
    <div
      class="resizable_bar"
      mwlResizeHandle
      [resizeEdges]="(isBarVisible$ | async) ? { right: true } : {}"
    ></div>
    <button
      class="resizable_bar__dragger"
      [ngClass]="{ barInvisibleArrow: !(isBarVisible$ | async) }"
      (click)="barToggle()"
    >
      <nb-icon
        [icon]="(isBarVisible$ | async) ? 'arrow-ios-back-outline' : 'arrow-ios-forward-outline'"
      ></nb-icon>
    </button>
  </div>
</div>
<div class="layers__coords" *ngIf="isCoordsToolActive || isGeoCoordsToolActive">
  <app-coords-tool></app-coords-tool>
</div>
