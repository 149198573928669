import { createAction, props } from '@ngrx/store';
import { SonyCodeSet } from '../../../models/sony/sony-code-set.model';

export const login = createAction('[Sony Auth] Login');

export const generateCodeChallenge = createAction('[Sony Auth] Generate code challenge');

export const getLoginUrl = createAction(
  '[Sony Auth] Get login url',
  props<{ codeSet: SonyCodeSet }>(),
);

export const getLoginUrlSuccess = createAction(
  '[Sony Auth] Get login url success',
  props<{ loginUrl: string }>(),
);

export const waitForUserToLogin = createAction('[Sony Auth] Wait for user to login');

export const fetchUserLoginCodeSuccess = createAction(
  '[Sony Auth] Fetch user login code success',
  props<{ codeSet: SonyCodeSet }>(),
);

export const loadTokens = createAction(
  '[Sony Auth] Load tokens',
  props<{ codeSet: SonyCodeSet }>(),
);

export const loadTokensSuccess = createAction(
  '[Sony Auth] Load tokens success',
  props<{ refreshToken: string; accessToken: string }>(),
);

export const resetSonyAuthData = createAction('[Sony Auth] Reset auth data');
