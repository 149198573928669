import { Tube } from '@App/app/entities/layer/tube.model';
import { Vector3 } from 'babylonjs';
import { transformPointsToViewer } from 'src/app/shared/utils/utils';

export const getEdges = (tube: Tube) => {
  const { edges } = tube.data;
  return edges ? edges.map((edge) => new Vector3(edge.x, edge.y, edge.z)) : [];
};

export const getAndAdjustEdges = (tube: Tube) => {
  const edges = getEdges(tube);
  transformPointsToViewer(edges);
  return edges as [Vector3, Vector3];
};
