import { AxisCoordsToolService } from '@App/app/engine/services/coords-tool-services/axis-coords-tool-service/axis-coords-tool.service';
import { GeoCoordsToolService } from '@App/app/engine/services/coords-tool-services/geo-coords-tool-service/geo-coords-tool.service';
import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { LayerService } from '@App/app/engine/services/layer-services/layer-service/layer.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { FolderData } from '@App/app/entities/layer/folder.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NbMenuItem, NbMenuService, NbTrigger } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ResizeEvent } from 'angular-resizable-element';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, Subscription } from 'rxjs';
import { delay, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { RESPONSIVE_CONFIG } from 'src/app/configs/app.config';
import { RendersService } from '../../models/services/renders-service/renders.service';
import { selectCurrentModelLocationIsSpecified } from '../../models/store/models/selectors/models.selectors';
import { CreateLayersBarComponent } from '../create-layers-bar/create-layers-bar.component';
import { AutomappingService } from '../services/automapping/automapping.service';
import { CsvExportService } from '../services/csv-export/csv-export.service';
import { DxfExportService } from '../services/dxf-export/dxf-export.service';
import { LayersBarService } from '../services/layers-bar.service';
import { LayersService } from '../services/layers/layers.service';
import { ObjExportService } from '../services/obj-export/obj-export.service';
import { ThicknessFormService } from '../services/thickness-utils/thickness-form.service';
import { UnitsService } from '../services/utils/units.service';
import { selectAllLayers } from '../store/selectors/layers.selectors';
import { ExportableItemData } from './models/exportable-item-data.model';
import { getExportOptions } from './utils/export-options.utils';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LayersBarService],
  animations: [
    trigger('sidebarAnimation', [
      state('visible', style({ marginLeft: 0 })),
      state(
        'hidden',
        style({
          marginLeft: -(RESPONSIVE_CONFIG.MIN_SIDEBAR_WIDTH - 10),
          width: RESPONSIVE_CONFIG.MIN_SIDEBAR_WIDTH,
        }),
      ),
      transition('visible <=> hidden', animate('0.3s')),
    ]),
  ],
})
export class LayersComponent implements OnInit, OnDestroy {
  @ViewChild(CreateLayersBarComponent) private createLayersBarComponent?: CreateLayersBarComponent;
  isBarVisible$: Observable<boolean>;
  barWidth$: Observable<number>;
  isFolderDetailsActive$: Observable<LayerUI | null>;
  isLocationSpecified$: Observable<boolean>;
  isCreateFolderActive$: Observable<boolean>;
  isDetailsViewActive$: Observable<boolean>;
  automappingLoading$: Observable<boolean>;
  unitsSystem$: Observable<UnitsSystem>;
  isCoordsToolActive = false;
  isGeoCoordsToolActive = false;
  subscriptions: Subscription[] = [];
  layersCount$: Observable<number>;
  automappingPath: string | null;
  UnitsSystem = UnitsSystem;
  coordsIcon = '../../../assets/svg/icons/sf_portal-ui_icon_axis.svg';
  markerIcon = '../../../assets/svg/icons/sf_portal-ui_icon_tools_coords.svg';
  permissions = UserPermissions;
  exportOptions: NbMenuItem[];
  exportsContextTag = 'layers-exports-context-menu';
  NbTrigger = NbTrigger;
  barValidator = this._layersBarService.validate;

  constructor(
    private _axisCoordsToolService: AxisCoordsToolService,
    private _geoCoordsToolService: GeoCoordsToolService,
    private _layersService: LayersService,
    private _layerService: LayerService,
    private _engineService: CustomerEngineService,
    private _unitsService: UnitsService,
    private _automappingService: AutomappingService,
    private _csvExportService: CsvExportService,
    private _thicknessFormService: ThicknessFormService,
    private _layersBarService: LayersBarService,
    private _rendersService: RendersService,
    private _viewerLayerService: ViewerLayerService,
    private _nbMenuService: NbMenuService,
    private _permissionsService: NgxPermissionsService,
    private _objExportService: ObjExportService,
    private _dxfExportService: DxfExportService,
    private store: Store,
  ) {
    this.isLocationSpecified$ = this.store.select(selectCurrentModelLocationIsSpecified);
    this.isBarVisible$ = this._layersBarService.visible$;
    this.barWidth$ = this._layersBarService.width$;
    this.isFolderDetailsActive$ = this._layersService.editingFolder$;
    this.isCreateFolderActive$ = this._layersService.isCreatingFolderActive$;
    this.automappingLoading$ = this._automappingService.loading$;
    // eslint-disable-next-line ngrx/avoid-mapping-selectors
    this.layersCount$ = this.store.select(selectAllLayers).pipe(map((layers) => layers.length));
    this.isDetailsViewActive$ = this._layersService.detailsViewLayers$.pipe(
      map((layers) => !!layers.length),
    );
    this.unitsSystem$ = this._unitsService.isImperialActive$.pipe(
      map((value: boolean) => (value ? UnitsSystem.Imperial : UnitsSystem.Metric)),
    );
  }

  ngOnInit(): void {
    this.subscriptions = [
      this._axisCoordsToolService.axisCoordsToolActive.subscribe((value) => {
        this.isCoordsToolActive = value;
        this._engineService.activeCursorMove(value, true);
      }),
      this._geoCoordsToolService.geoCoordsToolActive$.subscribe((value) => {
        this.isGeoCoordsToolActive = value;
        this._engineService.activeCursorMove(value, true);
      }),
      this._rendersService.currentRender$.subscribe((render) => {
        this.automappingPath = render?.automapping?.downloadURL || null;
        this.exportOptions = getExportOptions(
          render,
          (permission) => !!this._permissionsService.hasPermission(permission),
          () => this._objExportService.export(),
          () => this._csvExportService.export(this._getRootFolder()),
          () => this._dxfExportService.exportAllLayersToDXF(),
        );
      }),
      this._engineService.target$
        .pipe(
          switchMap((_id) =>
            this._layersService.layers$.pipe(map((layers) => layers.find(({ id }) => id === _id))),
          ),
          filter(Boolean),
          first(),
          tap((layer: LayerUI) => this._layersService.setLayerVisibility(layer, true)),
          delay(100),
        )
        .subscribe((layer) => this._viewerLayerService.setCameraOnLayer(layer)),
      this._nbMenuService
        .onItemClick()
        .pipe(filter(({ tag }) => tag === this.exportsContextTag))
        .subscribe(({ item }) => (item.data as ExportableItemData).click()),
    ];
  }

  onResize(event: ResizeEvent) {
    this._layersBarService.onResize(event);
  }

  barToggle() {
    this._layersBarService.toggle();
    this._layersBarService.visible$.pipe(first(), filter(Boolean)).subscribe(() => {
      this.createLayersBarComponent?.clear();
      this.createLayersBarComponent?.hidePopover();
      this._geoCoordsToolService.setCoordsToolActive(false);
      this._axisCoordsToolService.setCoordsToolActive(false);
    });
  }

  ngOnDestroy() {
    this._thicknessFormService.removeAllForms();
    this._layersService.editedLayers = [];
    this._layersService.destroyLayersUI();
  }

  toggleAxisCoordsTool() {
    this._geoCoordsToolService.setCoordsToolActive(false);
    this._axisCoordsToolService.setCoordsToolActive(!this.isCoordsToolActive);
  }

  toggleGeoCoordsTool() {
    this._axisCoordsToolService.setCoordsToolActive(false);
    this._geoCoordsToolService.setCoordsToolActive(!this.isGeoCoordsToolActive);
  }

  openAutomappingDialog() {
    if (this.automappingPath) {
      this._automappingService.openDialog(this.automappingPath);
    }
  }

  private _getRootFolder(): LayerUiExpandable {
    const { value } = this._layerService.layers;
    return value.find((layer) => (layer.data as FolderData).isRoot) as LayerUiExpandable;
  }
}
