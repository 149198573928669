import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/layers.actions';

export const layersAdapter = createEntityAdapter<LayerUI | LayerUiExpandable>();
export type State = EntityState<LayerUI | LayerUiExpandable>;

const defaultLayer: State = {
  ids: [],
  entities: {},
};
export const initialState: State = layersAdapter.getInitialState(defaultLayer);

export const layersReducer = createReducer(
  initialState,
  on(actions.addLayerSuccess, (state, { layer }) => {
    return layersAdapter.addOne(layer, state);
  }),
  on(actions.addFolderLayerSuccess, (state, { layer }) => {
    return layersAdapter.addOne(layer, state);
  }),
  on(actions.addLayersSuccess, (state, { layers }) => {
    return layersAdapter.addMany(layers, state);
  }),
  on(actions.addLayersLocal, (state, { layers }) => {
    return layersAdapter.addMany(layers, state);
  }),
  on(actions.removeAllLayersLocal, (state) => {
    return layersAdapter.removeAll(state);
  }),
  on(actions.loadAllLayersSuccess, (state, { layers }) => {
    return layersAdapter.setAll(layers, state);
  }),
  on(actions.updateLayer, (state, { update }) => {
    return layersAdapter.updateOne(update, state);
  }),
  on(actions.updateLayers, (state, { updates }) => {
    return layersAdapter.updateMany(updates, state);
  }),
  on(actions.showLayer, (state, { id }) => {
    return layersAdapter.updateOne({ id, changes: { isVisible: true } }, state);
  }),
  on(actions.hideLayer, (state, { id }) => {
    return layersAdapter.updateOne({ id, changes: { isVisible: false } }, state);
  }),
  on(actions.deleteLayerSuccess, (state, { id }) => {
    return layersAdapter.removeOne(id, state);
  }),
  on(actions.deleteLayersSuccess, (state, { ids }) => {
    return layersAdapter.removeMany(ids, state);
  }),
  on(actions.deleteLayersLocal, (state, { ids }) => {
    return layersAdapter.removeMany(ids, state);
  }),
);
