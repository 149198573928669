import { RectType, getDimensions } from '@App/app/engine/helpers/rectangle-tools-helpers';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rectDimensions',
  pure: false,
})
export class RectDimensionsPipe implements PipeTransform {
  transform(layer: RectType) {
    return getDimensions(layer);
  }
}
