<div [nbSpinner]="gcpFileLoading" class="at_tab" [ngClass]="{ 'full-size': gcpFileLoading }">
  <ng-container *ngIf="!gcpFileLoading">
    <div class="at_tab__nav" data-testId="at-setup-tab">
      <nav class="sidebar__nav sidebar__nav--subnav">
        <div
          class="sidebar__nav_item sidebar__nav_item"
          [ngClass]="{ 'sidebar__nav_item--active': activeTab === atSetupTabs.SETTINGS }"
          (click)="setActiveTab(atSetupTabs.SETTINGS)"
        >
          Settings
        </div>
        <div
          class="sidebar__nav_item sidebar__nav_item"
          [ngClass]="{
            'sidebar__nav_item--active': activeTab === atSetupTabs.CONTROL_POINTS,
            'sidebar__nav_item--disabled': !areGCPSettingsAvailable() || isModelLoading,
            'sidebar__nav_item--no-permission': !hasConfigGcpPermission()
          }"
          (click)="setActiveTab(atSetupTabs.CONTROL_POINTS)"
          data-testId="control-points-tab"
        >
          Control points
        </div>
      </nav>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
      <div class="at_tab__content">
        <app-processing-settings
          *ngIf="activeTab === atSetupTabs.SETTINGS"
          (resetSettings)="restoreDefault()"
          [form]="form"
          [settings]="getSettings()"
        >
          <div class="sidebar__slider">
            <div
              [nbTooltip]="atQualityDescription"
              nbTooltipPlacement="right"
              class="sidebar__label"
            >
              AT quality
            </div>
            <ng5-slider
              [options]="options"
              ngDefaultControl
              formControlName="photoSamplingRate"
            ></ng5-slider>
          </div>
          <div class="gcp__error">
            <span *ngIf="!areGCPSettingsValid()" class="gcp__error__text"
              >Ground control points settings are invalid</span
            >
          </div>
        </app-processing-settings>
        <app-at-setup-control-points
          *ngIf="activeTab === atSetupTabs.CONTROL_POINTS"
          [controlPoints]="getControlPoints()"
        ></app-at-setup-control-points>
      </div>
      <div class="at_tab__footer">
        <app-tab-actions
          [ATContinueEnabled]="true"
          [nextAction]="onSubmit"
          [requireLoadedModelForNextAction]="false"
        ></app-tab-actions>
      </div>
    </form>
  </ng-container>
</div>
