import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByName',
})
export class FilterByNamePipe implements PipeTransform {
  transform<T extends { name: string }>(items: T[], filterText: string): T[] {
    if (!items) {
      return [];
    }
    if (!filterText) {
      return items;
    }
    filterText = filterText.toLocaleLowerCase();

    return items.filter((it) => it.name.toLocaleLowerCase().includes(filterText));
  }
}
