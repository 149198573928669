import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LayersService } from '../services/layers/layers.service';

@Component({
  selector: 'app-folder-details',
  templateUrl: './folder-details.component.html',
  styleUrls: ['./folder-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FolderDetailsComponent implements OnInit {
  @Input() folder: LayerUiExpandable | LayerUI;
  LAYER_TYPES = LAYER_TYPES;
  isChangeNameActive = false;
  isDeleteActive = false;
  nameForm: FormGroup<{ name: FormControl<string | null> }>;
  permissions = UserPermissions;

  constructor(private _layersService: LayersService, private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.nameForm = this._fb.group({
      name: this._fb.control('', [Validators.required, Validators.minLength(1)]),
    });
  }

  onBackClick(): void {
    this._layersService.setEditingFolder(null);
  }

  onDeleteClick(): void {
    this.isDeleteActive = true;
  }

  onDeleteCancel(): void {
    this.isDeleteActive = false;
  }

  onDeleteConfirm(): void {
    this._layersService.deleteLayer(this.folder);
    this.isDeleteActive = false;
  }

  onChangeName(name: string) {
    this._layersService.changeLayerName(name, this.folder);
  }
}
