import { modelMeshPredicate } from '@App/app/engine/helpers/layers-helpers';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Injectable } from '@angular/core';
import { Mesh, Plane, Ray, Vector3 } from 'babylonjs';
import { SceneService } from '../../../scene-service/scene.service';
import { UtilsService } from '../../../utils-service/utils.service';

@Injectable({
  providedIn: 'root',
})
export class RectangleToolsEdgeDetectService {
  constructor(private sceneService: SceneService, private utilsService: UtilsService) {}

  detectEdgesAndRecalculateHeight(
    mesh: Mesh,
    startPoints: Mesh[],
    type: LAYER_TYPES.CUBOID | LAYER_TYPES.RECT_TUBE,
  ): [Vector3, number] {
    const topBottomEdges = this.getEdgesPoints(mesh);
    const centerEdges: [Vector3, Vector3, Vector3, Vector3] = [
      Vector3.Center(topBottomEdges.top[0], topBottomEdges.bottom[3]),
      Vector3.Center(topBottomEdges.top[1], topBottomEdges.bottom[2]),
      Vector3.Center(topBottomEdges.top[2], topBottomEdges.bottom[1]),
      Vector3.Center(topBottomEdges.top[3], topBottomEdges.bottom[0]),
    ];
    const distancesFromCenterEdgesToModel = this.getLengths(centerEdges);

    const distanceToBottomPoint = this.calculateStartPoint(topBottomEdges.bottom, startPoints);
    const normalBottom = centerEdges[0].subtract(topBottomEdges.bottom[3]).scale(0.02).normalize();
    const bottomStartEdges: [Vector3, Vector3, Vector3, Vector3] = [
      topBottomEdges.bottom[3].subtract(normalBottom.negate().scale(distanceToBottomPoint)),
      topBottomEdges.bottom[2].subtract(normalBottom.negate().scale(distanceToBottomPoint)),
      topBottomEdges.bottom[1].subtract(normalBottom.negate().scale(distanceToBottomPoint)),
      topBottomEdges.bottom[0].subtract(normalBottom.negate().scale(distanceToBottomPoint)),
    ];

    const distanceToTopPoint = this.calculateStartPoint(topBottomEdges.top, startPoints);
    const normalTop = centerEdges[0].subtract(topBottomEdges.top[0]).scale(0.02).normalize();
    const topStartEdges: [Vector3, Vector3, Vector3, Vector3] = [
      topBottomEdges.top[3].subtract(normalTop.negate().scale(distanceToTopPoint)),
      topBottomEdges.top[2].subtract(normalTop.negate().scale(distanceToTopPoint)),
      topBottomEdges.top[1].subtract(normalTop.negate().scale(distanceToTopPoint)),
      topBottomEdges.top[0].subtract(normalTop.negate().scale(distanceToTopPoint)),
    ];

    const thresholds = [0.01, 0.005, 0.01];
    let top = topStartEdges;
    let bottom = bottomStartEdges;
    thresholds.forEach((threshold, i) => {
      const evenIndex = !(i % 2);
      top = this.edgeDetect(
        top,
        evenIndex ? normalTop : normalTop.negate(),
        threshold,
        !evenIndex,
        distancesFromCenterEdgesToModel,
        type,
      );
      bottom = this.edgeDetect(
        bottom,
        evenIndex ? normalBottom : normalBottom.negate(),
        threshold,
        !evenIndex,
        distancesFromCenterEdgesToModel,
        type,
      );
    });

    const bottomCenter = Vector3.Center(bottom[0], bottom[2]);
    const topCenter = Vector3.Center(top[0], top[2]);
    const center = Vector3.Center(bottomCenter, topCenter);
    const height = Vector3.Distance(bottomCenter, topCenter);

    return [center, height];
  }

  private calculateStartPoint(edgePoints: Vector3[], startPoints: Mesh[]): number {
    const edgePlain = Plane.FromPoints(edgePoints[0], edgePoints[1], edgePoints[2]);
    return Math.min(...startPoints.map((mesh) => edgePlain.signedDistanceTo(mesh.position)));
  }

  private getLengths(points: [Vector3, Vector3, Vector3, Vector3]) {
    const innerPoints = this.calculateInnerPoints(points, 0.2);
    return innerPoints.map((point, index) => {
      const ray = Ray.CreateNewFromTo(points[index], point);
      const hit = this.sceneService.scene.pickWithRay(ray, modelMeshPredicate);
      return hit?.pickedMesh && hit.pickedPoint
        ? Vector3.Distance(points[index], hit.pickedPoint)
        : 0.02;
    });
  }

  private edgeDetect(
    startPoints: [Vector3, Vector3, Vector3, Vector3],
    normal: Vector3,
    threshold: number,
    next: boolean,
    innerLength: number[],
    type: LAYER_TYPES.CUBOID | LAYER_TYPES.RECT_TUBE,
  ): [Vector3, Vector3, Vector3, Vector3] {
    let count = 0;
    const innerPoints = this.calculateInnerPoints(startPoints, innerLength, type);
    const outerPoints = this.calculateInnerPoints(
      startPoints,
      type === LAYER_TYPES.CUBOID ? -0.04 : -0.08,
    );
    startPoints.forEach((_, index) => {
      if (this.checkIntersect(outerPoints[index], innerPoints[index])) {
        count++;
      }
    });

    if (next ? count <= 3 : count >= 3) {
      return this.edgeDetect(
        [
          startPoints[0].subtract(normal.clone().scale(threshold)),
          startPoints[1].subtract(normal.clone().scale(threshold)),
          startPoints[2].subtract(normal.clone().scale(threshold)),
          startPoints[3].subtract(normal.clone().scale(threshold)),
        ],
        normal,
        threshold,
        next,
        innerLength,
        type,
      );
    } else {
      return startPoints;
    }
  }

  private calculateInnerPoints(
    points: Vector3[],
    length: number | number[],
    type?: LAYER_TYPES.CUBOID | LAYER_TYPES.RECT_TUBE,
  ) {
    const modifier = type && type === LAYER_TYPES.RECT_TUBE ? 0.01 : 0.02;
    return points.map((point, index) => {
      return this.calculateInnerPoint(
        point,
        points[index + 1] || points[0],
        points[index - 1] || points[points.length - 1],
        Array.isArray(length) ? length[index] + modifier : length,
      );
    });
  }

  private calculateInnerPoint(
    point: Vector3,
    adjacentPointA: Vector3,
    adjacentPointB: Vector3,
    thickness: number,
  ) {
    const distance = Vector3.Distance(point, adjacentPointA);
    let normal = point.clone().subtract(adjacentPointB.clone()).normalize();
    const tmpPoint = point.subtract(normal.clone().scale(distance));
    const tmpCenter = Vector3.Center(adjacentPointA, tmpPoint);
    normal = point.subtract(tmpCenter).normalize();
    return point.subtract(normal.scale(thickness));
  }

  private getEdgesPoints(mesh: Mesh) {
    mesh.bakeCurrentTransformIntoVertices(true);
    const vertices = this.utilsService.getVectorVertices(mesh);
    const result = {
      bottom: [vertices[20], vertices[21], vertices[22], vertices[23]],
      top: [vertices[16], vertices[17], vertices[18], vertices[19]],
    };
    mesh.dispose();
    return result;
  }

  private checkIntersect(from: Vector3, to: Vector3) {
    const ray = Ray.CreateNewFromTo(from, to);
    const hit = this.sceneService.scene.pickWithRay(ray, modelMeshPredicate);
    return !!(hit?.pickedMesh && hit.pickedPoint);
  }
}
