import { Quaternion, Vector3 } from 'babylonjs';

export const median = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2);
  const nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const transformPointsToViewer = (points: Vector3[]) => {
  const q = Quaternion.FromEulerAngles(-Math.PI / 2, 0, 0);
  points.forEach((vector) => {
    vector.multiplyInPlace(new Vector3(-1, 1, 1));
    vector.rotateByQuaternionToRef(q, vector);
  });
  return points;
};

export const getMemoryHeapUsage = () => {
  const mem = (window.performance as any).memory;
  return mem.totalJSHeapSize / mem.jsHeapSizeLimit;
};
