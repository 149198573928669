<div class="layer layers__item layers__folder layer__visible_on_hover" [ngClass]="classes">
  <div class="layer__highlight"></div>
  <div *ngIf="showExpandableArrow" data-testId="expand-btn" (click)="onExpand($event)">
    <div class="layer__expand" *ngIf="!folder.open">
      <nb-icon class="layer__toggle" icon="arrow-right"></nb-icon>
    </div>
    <div class="layer__expand" *ngIf="folder.open">
      <nb-icon class="layer__toggle" icon="arrow-down"></nb-icon>
    </div>
  </div>
  <div class="layer__type">
    <nb-icon class="layer__icon" icon="folder"></nb-icon>
  </div>
  <div class="layer__name select-none">{{ folder.name }}</div>
  <div class="layer__edit" data-testId="edit-btn" (click)="onEditClick($event)">
    <nb-icon
      nbTooltip="Show details"
      class="layer__icon layer__invisible"
      icon="info-outline"
    ></nb-icon>
  </div>
  <ng-container *ngIf="!layerPath.length">
    <div
      *ngIf="isFolderVisible"
      data-testId="all-gui-toggle-btn"
      [ngClass]="isAnyGuiVisible ? 'layer__select_gui--active' : 'layer__select_gui'"
      (click)="onAllGuiToggle($event)"
    >
      <nb-icon
        [nbTooltip]="isAnyGuiVisible ? 'Hide tags' : 'Show tags'"
        class="layer__icon"
        icon="pricetags-outline"
      ></nb-icon>
    </div>
    <div class="layer__select" data-testId="select-btn" (click)="onSelect($event)">
      <nb-icon
        [nbTooltip]="isAnyGuiVisible ? 'Hide layers' : 'Show layers'"
        class="layer__icon"
        icon="eye"
      ></nb-icon>
    </div>
  </ng-container>
  <app-layer-path [path]="layerPath" (clickPath)="onClickLayerPath()"></app-layer-path>
</div>
