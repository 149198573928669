import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Pipe, PipeTransform } from '@angular/core';
import { LayersListService } from '../../../services/layers-list-service/layers-list.service';

@Pipe({
  name: 'layerPath',
})
export class LayerPathPipe implements PipeTransform {
  constructor(private _layersListService: LayersListService) {}

  transform(layer: LayerUI) {
    return this._layersListService.getLayerPath(layer);
  }
}
