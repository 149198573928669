<div class="content">
  <app-async-action-dispatched-info
    *ngIf="downloadMessage$ | async as message"
    [message]="message"
    (closeInfo)="onDownloadMessageClose()"
  ></app-async-action-dispatched-info>

  <app-grid
    [entities]="filteredPhotos"
    [loading]="(loading$ | async) ?? false"
    noEntitiesPlaceholder="No photos to show"
    [headerContentTpl]="headerContentTpl"
    [cellContentTpl]="cellContentTpl"
    [footerContentTpl]="footerContentTpl"
  ></app-grid>
</div>

<ng-template #headerContentTpl>
  <form [formGroup]="filtersForm">
    <div class="photos__card__inputs-fields">
      <ng-container *ngxPermissionsOnly="permissions.CAN_FILTER_PHOTOS_BY_TARGET">
        <input
          nbInput
          fullWidth="true"
          class="photos__card__keywords"
          placeholder="Search photos by name & annotation"
          formControlName="keywords"
          data-testId="search-photos-by-target"
        />
      </ng-container>
      <nb-select
        placeholder="Select Annotation Priority..."
        class="photos__card__select"
        formControlName="priority"
      >
        <nb-option>None</nb-option>
        <nb-option value="low">Low</nb-option>
        <nb-option value="normal">Normal</nb-option>
        <nb-option value="high">High</nb-option>
      </nb-select>
    </div>
    <nb-checkbox
      status="info"
      class="photos__card__checkbox"
      formControlName="showOnlyWithAnnotations"
      >Show only with annotations</nb-checkbox
    >
  </form>
</ng-template>

<ng-template #cellContentTpl let-entity="entity">
  <app-model-photo-card
    [photo]="entity"
    [isSelected]="entity | isIncludedIn: selectedPhotos"
    (photoClick)="onPhotoClick($event)"
    (enlargePhoto)="enlargePhoto($event)"
  ></app-model-photo-card>
</ng-template>

<ng-template #footerContentTpl>
  <div class="photos__footer__actions">
    <div
      *ngxPermissionsOnly="permissions.CAN_GENERATE_ANNOTATION_REPORT"
      nbTooltip="No photos with annotations"
      [nbTooltipTrigger]="
        !(allPhotos | annotationsReportAvailable) ? NbTrigger.HOVER : NbTrigger.NOOP
      "
    >
      <button
        nbButton
        status="info"
        (click)="onAnnotationsDownloadClick()"
        [disabled]="!filteredPhotos.length || !(allPhotos | annotationsReportAvailable)"
        data-testId="generate-annotations-report-button"
      >
        Generate Annotations Report
      </button>
    </div>
    <button
      *ngxPermissionsOnly="permissions.CAN_DOWNLOAD_PHOTO_BUNDLE"
      nbButton
      status="info"
      (click)="onDownloadClick()"
      [disabled]="!selectedPhotos.length"
      data-testId="download-photos-button"
    >
      Download Photos
    </button>
    <button
      class="photos__footer__actions__download_btn"
      nbButton
      (click)="unselectAllPhotos()"
      [disabled]="!selectedPhotos.length"
      data-testId="unselect-all-photos-button"
    >
      Unselect All Photos
    </button>
  </div>
  <div class="photos__footer__counter">
    {{ selectedPhotos.length }}
    <span class="photos__footer__desc" *ngIf="totalPhotosSize"
      >({{ totalPhotosSize | memorySizeUnit }})</span
    >
    / {{ maxSelectNumber }} <span class="photos__footer__desc">max</span> / {{ allPhotos.length }}
    <span class="photos__footer__desc">total</span>
  </div>
</ng-template>
