import { CamerasService } from '@App/app/engine/services/camera-services/cameras-service/cameras.service';
import { SketchesUtilsService } from '@App/app/engine/services/layer-services/sketches-services/shared/sketches-utils.service';
import { SketchCircleService } from '@App/app/engine/services/layer-services/sketches-services/sketch-circle/sketch-circle.service';
import { SketchLineService } from '@App/app/engine/services/layer-services/sketches-services/sketch-line/sketch-line.service';
import { SketchRectangleService } from '@App/app/engine/services/layer-services/sketches-services/sketch-rectangle/sketch-rectangle.service';
import { ISketchPlaneBroadcast } from '@App/app/entities/layer/sketch-tools/sketch-plane-broadcast-info.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { CameraPosAndDir } from '@App/app/entities/viewer/camera-pos-and-dir.model';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { ArcRotateCamera, Engine } from 'babylonjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerEngineSketchUtilsService {
  constructor(
    private _camerasService: CamerasService,
    private _sketchesUtilsService: SketchesUtilsService,
    private _broadcastService: BroadcastService,
    private _sketchLineService: SketchLineService,
    private _sketchCircleService: SketchCircleService,
    private _sketchRectangleService: SketchRectangleService,
  ) {}

  onPlaneEdit(event: ISketchPlaneBroadcast) {
    // resets current sketch plane on save or cancel click
    if (event.resetSketchesCurrentPlane) {
      this._resetCurrentPlane();
    }
    // removes draft sketches on cancel click
    if (event.removeSketchDrafts) {
      this._removeDraftSketches();
    }
  }

  targetCameraToSketchPlane(
    id: number,
    camera: ArcRotateCamera | null,
    tmpCameraPosition: CameraPosAndDir,
    canvas: HTMLCanvasElement | null,
    engine: Engine | null,
  ) {
    if (camera) {
      tmpCameraPosition.position.copyFrom(camera.position);
      tmpCameraPosition.target.copyFrom(camera.target);
      if (engine && canvas) {
        this._camerasService.setCameraOnSketchPlane(canvas, engine, camera, id);
      }
    }
    this._broadcastService.broadcast(EVENT_TYPE.CAMERA_MODE_BUTTON_STATUS, true);
  }

  finishSketchTool(camera: ArcRotateCamera | null, tmpCameraPosition: CameraPosAndDir) {
    if (camera) {
      this._camerasService.switchToPerspectiveCamera(camera);
    }
    this._sketchesUtilsService.finishNewSketch(camera, tmpCameraPosition);
  }

  private _resetCurrentPlane() {
    this._sketchLineService.resetCurrentSketchPlane();
    this._sketchCircleService.resetCurrentSketchPlane();
  }

  private _removeDraftSketches() {
    this._sketchLineService.removeDraftSketch();
    this._sketchCircleService.removeDraftSketch();
    this._sketchRectangleService.removeDraftSketch();
  }
}
