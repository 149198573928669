import { IPresetModel } from '@App/app/entities/processing/processing-preset-settings.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ProcessingPresetsHttpService } from '@App/app/pages/processing/services/processing-presets-http-service.service/processing-presets-http.service';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ProcessingPresetsService {
  constructor(private _processingPresetsHttpService: ProcessingPresetsHttpService) {}

  getBuildPresets() {
    return this._processingPresetsHttpService.getBuildProcessPresets().pipe(
      untilDestroyed(this),
      map((presets: IPresetModel[]) => {
        const stepMapping: { [step: string]: ProcessingSteps } = {
          ['aerotriangulation']: ProcessingSteps.AT_SETUP,
          ['post-processing']: ProcessingSteps.PP_SETUP,
          ['production']: ProcessingSteps.PROD_SETUP,
        };
        return presets.map((preset) => ({
          ...preset,
          step: stepMapping[preset.step],
        })) as IPresetModel[];
      }),
    );
  }
}
