import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ModelsProcessingHttpService } from '../../../../processing/services/models-processing-http-service/models-processing-http.service';
import { RendersService } from '../../../services/renders-service/renders.service';
import {
  updateCurrentRenderFiles,
  updateCurrentRenderFilesSuccess,
} from '../actions/renders.actions';

@Injectable()
export class UpdateRendersEffects {
  constructor(
    private actions: Actions,
    private modelsProcessingHttpService: ModelsProcessingHttpService,
    private rendersService: RendersService,
  ) {}

  updateCurrentRenderFiles = createEffect(() => {
    return this.actions.pipe(
      ofType(updateCurrentRenderFiles),
      mergeMap(({ files }) => {
        const renderId = this.rendersService.getCurrentRenderId();
        if (!renderId) {
          return EMPTY;
        }
        return this.modelsProcessingHttpService.putBuildProcessFiles(renderId, files).pipe(
          map(
            (resp: any) => updateCurrentRenderFilesSuccess({ files: resp }),
            catchError((error) => of(error)),
          ),
        );
      }),
    );
  });

  updateCurrentRenderFilesSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(updateCurrentRenderFilesSuccess),
        tap(({ files: newFiles }) => {
          const currentRender = this.rendersService.getCurrentRender();
          if (!currentRender) {
            return;
          }
          const allFiles = [...currentRender.files];
          for (const file of newFiles) {
            const index = allFiles.findIndex((f) => f.id === file.id);
            if (index !== -1) {
              allFiles[index] = file;
            }
          }
          const newCurrentRender = new ExtendedRender({
            ...currentRender,
            files: allFiles,
          });
          this.rendersService.setCurrentRender(newCurrentRender);
        }),
      );
    },
    { dispatch: false },
  );
}
