import { TUBE_PART, TUBE_PART_STRICT } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { TubePartsArrays, TubePartsValues } from '@App/app/entities/layer/tube.model';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { AbstractMesh, Vector3 } from 'babylonjs';
import { Subject } from 'rxjs';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { median } from 'src/app/shared/utils/utils';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { UtilsService } from '../../utils-service/utils.service';
import { TubeUtilsService } from './tube-utils.service';

@Injectable({
  providedIn: 'root',
})
export class CreateTubeService {
  activePart: TUBE_PART = TUBE_PART.NONE;
  highlightedSample: { part: TUBE_PART; i: number } = { part: TUBE_PART.NONE, i: -1 };
  samples: TubePartsArrays<AbstractMesh> = { 1: [], 2: [], 3: [], 4: [] };

  private centers: TubePartsArrays<Vector3> = { 1: [], 2: [], 3: [], 4: [] };
  private medianCenter: TubePartsValues<Vector3 | null> = { 1: null, 2: null, 3: null, 4: null };
  private medianRadius: TubePartsValues<number | null> = { 1: null, 2: null, 3: null, 4: null };
  private radiuses: TubePartsArrays<number> = { 1: [], 2: [], 3: [], 4: [] };
  private _tubeDataChange$ = new Subject<void>();
  tubeDataChange$ = this._tubeDataChange$.asObservable();

  constructor(
    private nodeService: BabylonNodesService,
    private store: Store,
    private toastrService: NbToastrService,
    private tubeUtilsService: TubeUtilsService,
    private utilsService: UtilsService,
  ) {}

  onClickAction() {
    const hit = this.utilsService.pickRay();
    if (hit?.pickedPoint && hit.pickedMesh) {
      this.createSamplePoint(hit.pickedPoint.clone());
      this._tubeDataChange$.next();
    }
  }

  createSamplePoint(position: Vector3) {
    if (this.activePart !== TUBE_PART.NONE) {
      const sphere = this.nodeService.createSphere(position);
      this.samples[this.activePart].push(sphere);
    }
    if (
      (this.activePart === TUBE_PART.ENDPOINT_1_OUTER ||
        this.activePart === TUBE_PART.ENDPOINT_2_OUTER) &&
      this.samples[this.activePart].length > 2
    ) {
      this.calculateCirclesData();
    }
  }

  deleteSamplePoint(part: TUBE_PART_STRICT, i: number) {
    this.samples[part][i].dispose();
    delete this.samples[part][i];
    this.samples[part] = this.samples[part].filter((_, j) => j !== i);
    if (this.highlightedSample.part === part && this.highlightedSample.i === i) {
      this.highlightedSample = { part: TUBE_PART.NONE, i: -1 };
    }
    this._tubeDataChange$.next();
  }

  calculateCirclesData() {
    this.clearActiveStepCirclesData();
    if (this.activePart !== TUBE_PART.NONE) {
      const positions = this.samples[this.activePart].map((mesh) => mesh.position.clone());
      const combinations = this.utilsService.getTriangleCombinations(positions);
      combinations.forEach((vectors) => {
        const [center, radius] = this.utilsService.getCircleData(vectors);
        if (this.activePart !== TUBE_PART.NONE) {
          this.centers[this.activePart].push(center);
          this.radiuses[this.activePart].push(radius);
        }
      });
      this.medianCenter[this.activePart] = this.utilsService.medianVector(
        this.centers[this.activePart],
      );
      this.medianRadius[this.activePart] =
        Math.floor(median(this.radiuses[this.activePart]) * 100) / 100;
    }
  }

  // eslint-disable-next-line complexity
  createTube() {
    if (this.areOuterMedianCentersProvided()) {
      try {
        const medianCenter1Outer = this.medianCenter[TUBE_PART.ENDPOINT_1_OUTER];
        const medianCenter2Outer = this.medianCenter[TUBE_PART.ENDPOINT_2_OUTER];
        if (medianCenter1Outer && medianCenter2Outer) {
          const { edges, radiusOuter, radiusInner } = this.tubeUtilsService.calculateTubeData(
            [medianCenter1Outer, medianCenter2Outer],
            [
              this.samples[TUBE_PART.ENDPOINT_1_INNER].map((sample) => sample.position.clone()),
              this.samples[TUBE_PART.ENDPOINT_2_INNER].map((sample) => sample.position.clone()),
            ],
          );
          if (!edges.some((edge) => !edge)) {
            const tube = this.tubeUtilsService.createTube(edges, radiusOuter, radiusInner);
            this.store.dispatch(addLayer({ layer: tube as LayerUI, commit: false }));
          }
          this.clear();
        }
      } catch (e) {
        this.utilsService.catchToolCreationError(e);
        this.clear();
      }
    }
  }

  clearActiveStepCirclesData() {
    if (this.activePart !== TUBE_PART.NONE) {
      if (this.centers[this.activePart]?.length) {
        this.centers[this.activePart] = [];
      }
      if (this.radiuses[this.activePart]?.length) {
        this.radiuses[this.activePart] = [];
      }
    }
  }

  clear() {
    this.activePart = TUBE_PART.NONE;
    this.resetSamples();
    this.centers = { 1: [], 2: [], 3: [], 4: [] };
    this.highlightedSample = { part: TUBE_PART.NONE, i: -1 };
    this.medianCenter = { 1: null, 2: null, 3: null, 4: null };
    this.medianRadius = { 1: null, 2: null, 3: null, 4: null };
    this.radiuses = { 1: [], 2: [], 3: [], 4: [] };
    this._tubeDataChange$.next();
  }

  highlightSample(part: TUBE_PART_STRICT, i: number) {
    if (this.highlightedSample.part !== TUBE_PART.NONE && this.highlightedSample.i > -1) {
      this.tubeUtilsService.changeSampleMaterial(
        this.samples[this.highlightedSample.part][this.highlightedSample.i],
        true,
      );
    }
    if (this.highlightedSample.part === part && this.highlightedSample.i === i) {
      this.highlightedSample = { part: TUBE_PART.NONE, i: -1 };
    } else {
      this.tubeUtilsService.changeSampleMaterial(this.samples[part][i], false);
      this.highlightedSample = { part, i };
    }
    this._tubeDataChange$.next();
  }

  setActivePart(part: TUBE_PART_STRICT) {
    this.activePart = this.activePart === part ? TUBE_PART.NONE : part;
    this._tubeDataChange$.next();
  }

  private areOuterMedianCentersProvided() {
    return (
      this.medianCenter[TUBE_PART.ENDPOINT_1_OUTER] && this.medianCenter[TUBE_PART.ENDPOINT_2_OUTER]
    );
  }

  private resetSamples() {
    if (this.samples) {
      for (const part in this.samples) {
        if (
          this.samples[(part as any) as TUBE_PART_STRICT] &&
          this.samples[(part as any) as TUBE_PART_STRICT].length
        ) {
          this.removeSamplesOfPart((part as any) as TUBE_PART_STRICT);
        }
      }
      this.samples = { 1: [], 2: [], 3: [], 4: [] };
    }
  }

  private removeSamplesOfPart(part: TUBE_PART_STRICT) {
    for (let i = 0; i < this.samples[part].length; i++) {
      this.samples[part][i].dispose();
      delete this.samples[part][i];
    }
  }
}
