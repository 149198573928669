import { CUSTOM_ICONS_PACK } from '@App/app/configs/app.config';
import { LayerService } from '@App/app/engine/services/layer-services/layer-service/layer.service';
import { Folder } from '@App/app/entities/layer/folder.model';
import { LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NbTrigger } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { CsvExportService } from '../../services/csv-export/csv-export.service';
import { LayersService } from '../../services/layers/layers.service';

@UntilDestroy()
@Component({
  selector: 'app-layers-csv-export-bar',
  templateUrl: './layers-csv-export-bar.component.html',
  styleUrls: ['./layers-csv-export-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayersCsvExportBarComponent implements OnInit {
  private _folder: Folder | null;
  hasChild = false;
  NbTrigger = NbTrigger;
  CUSTOM_ICONS_PACK = CUSTOM_ICONS_PACK;

  constructor(
    private _layerService: LayerService,
    private _layersService: LayersService,
    private _csvExportService: CsvExportService,
  ) {}

  ngOnInit() {
    merge(this._layersService.editingFolder$.pipe(filter(Boolean)))
      .pipe(untilDestroyed(this))
      .subscribe((folder: Folder) => {
        this._folder = folder;
      });

    this._layerService.layers.pipe(first()).subscribe((layers) => {
      this.hasChild = layers.some((layer) => layer.parentId === this._folder?.id);
    });
  }

  onExportToCsv() {
    if (this._folder) {
      this._csvExportService.export(this._folder as LayerUiExpandable);
    }
  }
}
