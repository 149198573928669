<div *ngIf="tooltip; else noTooltip" [nbTooltip]="tooltip">
  <ng-container *ngTemplateOutlet="badge"></ng-container>
</div>

<ng-template #noTooltip>
  <ng-container *ngTemplateOutlet="badge"></ng-container>
</ng-template>

<ng-template #badge>
  <nb-badge
    class="model-type"
    [text]="text"
    [ngStyle]="{ 'background-color': bgColor }"
    *ngIf="typeName"
  ></nb-badge>
</ng-template>
