import { Page } from '@App/app/entities/shared/page.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subject } from 'rxjs';
import { PageInfo } from '../../../../entities/shared/page-info.model';
import { updatePageOffset } from '../../../viewer/store/color-presets-store/actions/ui-settings.actions';

@Injectable({
  providedIn: 'root',
})
export abstract class SitesService<T> {
  loading = new BehaviorSubject<boolean>(false);
  selectedEntities = new BehaviorSubject<T[]>([]);
  showArchived$ = new BehaviorSubject<boolean>(false);
  private _clickOutsideFilterPopover$ = new Subject<void>();
  clickOutsideFilterPopover$ = this._clickOutsideFilterPopover$.asObservable();
  private _page$ = new BehaviorSubject<Page>({
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  });
  page$ = this._page$.asObservable();

  constructor(protected store: Store, private spinner: NgxSpinnerService) {
    this.initItemsList();
  }

  setSelectedEntities(entities: T[]) {
    this.selectedEntities.next(entities);
  }

  hideSpinner() {
    this.spinner.hide();
  }

  changeArchiveSelect(val: boolean) {
    this.showArchived$.next(val);
    this._page$.next({ ...this._page$.value, pageNumber: 1 });
  }

  setPage(pageInfo: PageInfo, updateOffset = false) {
    this._page$.next({
      ...this._page$.value,
      size: pageInfo.pageSize,
      totalElements: pageInfo.count,
      pageNumber: updateOffset ? pageInfo.offset + 1 : this._page$.value.pageNumber,
    });
  }

  setPageNumber(pageNumber: number) {
    this._page$.next({ ...this._page$.value, pageNumber });
  }

  setPageOffset(value: number) {
    this._page$.next({
      ...this._page$.value,
      size: value,
      pageNumber: 1,
    });
    this.store.dispatch(updatePageOffset({ pageOffset: value }));
  }

  clickOutsideFilterPopover() {
    this._clickOutsideFilterPopover$.next();
  }

  abstract archiveMultiple(entities: T[]): void;

  abstract deleteMultiple(entities: T[]): void;

  private initItemsList() {
    this.showArchived$.next(false);
    this._page$.next({ ...this._page$.value, pageNumber: 1, size: 8 });
  }
}
