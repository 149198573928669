import { UnitsSystem } from '@App/app/entities/shared/units.model';
import configureMeasurements from 'convert-units';
import area from 'convert-units/lib/esm/definitions/area';
import length from 'convert-units/lib/esm/definitions/length';
import volume from 'convert-units/lib/esm/definitions/volume';

const convert = configureMeasurements({ length, area, volume });

/* eslint-disable complexity */
export const toImperialLength = (metric: number, forceUnit?: string) => {
  const result = convert(metric)
    .from('m')
    .to(forceUnit ? forceUnit : 'ft');
  const precision = getPrecision(result);
  return forceUnit
    ? Number(result.toFixed(3))
    : `${Number(
        (
          Math.round((result < 1 ? convert(result).from('ft').to('in') : result) * precision) /
          precision
        ).toFixed(3),
      )} ${result < 1 ? 'in' : 'ft'}`;
};

export const toImperialArea = (metric: number) => {
  const result = convert(metric).from('m2').to('ft2');
  const precision = getPrecision(result);
  return `${
    Math.round((result < 1 ? convert(result).from('ft2').to('in2') : result) * precision) /
    precision
  } ${result < 1 ? 'sq in' : 'sq ft'}`;
};

export const toImperialVolume = (metric: number) => {
  const result = convert(metric).from('m3').to('ft3');
  const precision = getPrecision(result);
  return `${
    Math.round((result < 1 ? convert(result).from('ft3').to('in3') : result) * precision) /
    precision
  } ${result < 1 ? 'in³' : 'ft³'}`;
};

export const toMetricLength = (imperial: number, precision = 1, fromUnit = 'ft', toUnit = 'm') => {
  return (
    Math.round(convert(imperial).from(fromUnit).to(toUnit) * 100 * precision) / (100 * precision)
  );
};

export const toImperialDimensions = (
  dimensions: {
    width: number;
    height: number;
    depth: number;
  },
  unit = 'in',
) => {
  return {
    width: Number((toImperialLength(dimensions.width, unit) as number).toFixed(2)),
    height: Number((toImperialLength(dimensions.height, unit) as number).toFixed(2)),
    depth: Number((toImperialLength(dimensions.depth, unit) as number).toFixed(2)),
  };
};

const getPrecision = (num: number) => {
  return num < 1 ? 10000 : 100;
};

export const toImperialIfNeeded = (value: number, outputUnit: UnitsSystem) => {
  let newValue = outputUnit === UnitsSystem.Metric ? value : Number(toImperialLength(value, 'in'));
  newValue = Number(newValue.toFixed(3));
  return newValue > 0 ? newValue : 0.01;
};
