import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-point-table',
  templateUrl: './control-point-table.component.html',
  styleUrls: ['./control-point-table.component.scss'],
})
export class ControlPointTableComponent implements OnInit {
  @Input() title: string;
  constructor() {}

  ngOnInit() {}
}
