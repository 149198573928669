/* eslint-disable max-lines */
import { MAX_SELECTABLE_PHOTOS_NUMBER } from '@App/app/configs/photos.config';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Photo } from '@App/app/entities/files/files.model';
import { PhotosFiltersForm } from '@App/app/entities/forms/photos-filters-form.model';
import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { ModelPhotoCardZoomComponent } from '@App/app/pages/models/model-details/model-photos/model-photo-card/model-photo-card-zoom/model-photo-card-zoom.component';
import { createSelectedPhotosZip } from '@App/app/shared/store/photos/actions/photos.actions';
import { selectPhotoByName } from '@App/app/shared/store/photos/selectors/photos.selectors';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService, NbTrigger } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, filter, first, map, switchMap } from 'rxjs/operators';
import { RendersService } from '../../services/renders-service/renders.service';
import { ModelPhotosService } from './services/model-photos-service/model-photos.service';

@UntilDestroy()
@Component({
  selector: 'app-model-photos',
  templateUrl: './model-photos.component.html',
  styleUrls: ['./model-photos.component.scss'],
})
export class ModelPhotosComponent implements OnInit {
  private _lastSelectedPhoto: Photo | null = null;
  allPhotos: Photo[] = [];
  filteredPhotos: Photo[] = [];
  selectedPhotos: Photo[] = [];
  totalPhotosSize = 0;
  maxSelectNumber = MAX_SELECTABLE_PHOTOS_NUMBER;
  NbTrigger = NbTrigger;
  loading$: Observable<boolean>;
  downloadMessage$: Observable<string | null>;
  filtersForm = this.fb.group<PhotosFiltersForm>({
    keywords: '',
    priority: null,
    showOnlyWithAnnotations: false,
  });
  permissions = UserPermissions;

  constructor(
    private fb: FormBuilder,
    private dialogService: NbDialogService,
    private modelPhotoService: ModelPhotosService,
    private rendersService: RendersService,
    private store: Store,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.loading$ = this.modelPhotoService.loadingPhotos$;
    this.modelPhotoService.setDownloadMessage(null);

    this.route.queryParams // open target photo if defined
      .pipe(
        untilDestroyed(this),
        map((params) => params.target),
        filter((target?: string) => !!target),
        switchMap((target: string) =>
          this.store.select(selectPhotoByName(target)).pipe(
            filter((photo) => !!photo),
            first(),
          ),
        ),
      )
      .subscribe((photo: Photo) => this.enlargePhoto(photo));

    this.rendersService.currentRender$
      .pipe(
        untilDestroyed(this),
        filter<ExtendedRender>(Boolean),
        debounceTime(100),
        map((currentRender) => [...currentRender.photos]),
      )
      .subscribe((photos: Photo[]) => {
        this.allPhotos = this.modelPhotoService.sortPhotosByIndex(photos);
        this.filteredPhotos = [...this.allPhotos];
        this.modelPhotoService.setLoading(false);
      });

    this.downloadMessage$ = this.modelPhotoService.downloadMessage$;
    this.filtersForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this._filterPhotos());
    this.modelPhotoService.startListeningRangeSelection();
  }

  onDownloadMessageClose() {
    this.modelPhotoService.setDownloadMessage(null);
  }

  unselectAllPhotos() {
    this.selectedPhotos = [];
    this._lastSelectedPhoto = null;
    this.totalPhotosSize = 0;
  }

  onDownloadClick() {
    const folderName = this.modelPhotoService.getFolderNameFromPhoto(this.allPhotos[0]);
    if (folderName) {
      const photos = [...this.selectedPhotos];
      this.store.dispatch(createSelectedPhotosZip({ folderName, photos }));
    }
  }

  onAnnotationsDownloadClick() {
    this.modelPhotoService.downloadAnnotationsReport();
  }

  enlargePhoto(photo: Photo) {
    this.dialogService.open(ModelPhotoCardZoomComponent, { context: { photo } });
  }

  onPhotoClick(photo: Photo) {
    if (this.modelPhotoService.isRangeSelectionActive && this._lastSelectedPhoto) {
      this._selectRange(photo);
    } else if (this.selectedPhotos.includes(photo)) {
      this._unselectPhoto(photo);
    } else {
      this._selectPhoto(photo);
    }
  }

  private _filterPhotos() {
    this.filteredPhotos = this.modelPhotoService.filterPhotos(this.allPhotos, this.filtersForm);
  }

  private _selectRange(photo: Photo) {
    const { _lastSelectedPhoto, allPhotos, selectedPhotos } = this;
    this.selectedPhotos = this.modelPhotoService.selectRange(photo, _lastSelectedPhoto, allPhotos, [
      ...selectedPhotos,
    ]);
    this.totalPhotosSize = this.modelPhotoService.recalculateTotalSizeByPhotos(this.selectedPhotos);
  }

  private _unselectPhoto(photo: Photo) {
    const photoItem = this.selectedPhotos.find((p) => p.id === photo.id);
    if (photoItem) {
      const index = this.selectedPhotos.indexOf(photoItem);
      this.selectedPhotos.splice(index, 1);
      this.totalPhotosSize -= photo.data.size ? photo.data.size : 0;
    }
  }

  private _selectPhoto(photo: Photo) {
    if (this.selectedPhotos.length < this.maxSelectNumber) {
      this._lastSelectedPhoto = photo;
      this.selectedPhotos.push(photo);
      this.totalPhotosSize += photo.data.size ? photo.data.size : 0;
    } else {
      this.modelPhotoService.showMaxPhotosNumberReachedMessage();
    }
  }
}
