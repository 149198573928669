import { getAngleIronCenterPoint } from '@App/app/engine/helpers/rectangle-tools-helpers';
import { AngleIron } from '@App/app/entities/layer/angle-iron.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'angleIronCenter',
  pure: false,
})
export class AngleIronCenterPipe implements PipeTransform {
  transform(layer: AngleIron) {
    return getAngleIronCenterPoint(layer);
  }
}
