import { Injectable } from '@angular/core';
import { CustomScene } from '@App/app/entities/viewer/custom-scene.model';
import { Color3, StandardMaterial, Texture } from 'babylonjs';
import { GridMaterial } from 'babylonjs-materials';
import {
  DARK_BLUE_MATERIAL_NAME,
  DRAG_MESH_COLOR,
  DRAG_MESH_MATERIAL_NAME,
  ELECTRIC_BLUE_COLOR3,
  ELECTRIC_BLUE_MATERIAL_NAME,
  FILLING_PLANE_MATERIAL_NAME,
  GREEN_LIGHT_MATERIAL_NAME,
  KNOT_MATERIAL_NAME,
  RED_LIGHT_MATERIAL_NAME,
  RED_MATERIAL_NAME,
  SKETCH_PLANE_GRID_MAT_NAME,
  SLICE_TOOL_CONFIG,
  YELLOW_LIGHT_MATERIAL_NAME,
} from 'src/app/configs/babylon.config';

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  bottomFillingPlaneMaterial: StandardMaterial;
  bottomGridMaterial: GridMaterial;
  darkBlueMaterial: StandardMaterial;
  dragMeshMaterial: StandardMaterial;
  electricBlueMaterial: StandardMaterial;
  fillingPlaneMaterial: StandardMaterial;
  redMaterial: StandardMaterial;
  sketchGridMaterial: GridMaterial;
  topFillingPlaneMaterial: StandardMaterial;
  topGridMaterial: GridMaterial;
  decalMaterial: StandardMaterial;
  greenLightMaterial: StandardMaterial;
  yellowLightMaterial: StandardMaterial;
  redLightMaterial: StandardMaterial;

  initMaterials(scene: CustomScene) {
    this.redMaterial = this.createStandardMaterial(
      RED_MATERIAL_NAME,
      new Color3(1, 0, 0),
      scene,
      false,
    );
    this.redLightMaterial = this.createStandardMaterial(
      RED_LIGHT_MATERIAL_NAME,
      new Color3(1, 0.5, 0.5),
      scene,
      false,
    );
    this.greenLightMaterial = this.createStandardMaterial(
      GREEN_LIGHT_MATERIAL_NAME,
      new Color3(0.7, 1, 0),
      scene,
      false,
    );
    this.yellowLightMaterial = this.createStandardMaterial(
      YELLOW_LIGHT_MATERIAL_NAME,
      new Color3(0.9, 1, 0.1),
      scene,
      false,
    );

    this.electricBlueMaterial = this.createStandardMaterial(
      ELECTRIC_BLUE_MATERIAL_NAME,
      ELECTRIC_BLUE_COLOR3,
      scene,
      false,
    );

    this.darkBlueMaterial = this.createStandardMaterial(
      DARK_BLUE_MATERIAL_NAME,
      new Color3(0.215, 0.252, 32.241),
      scene,
    );

    this.dragMeshMaterial = this.createStandardMaterial(
      DRAG_MESH_MATERIAL_NAME,
      Color3.FromHexString(DRAG_MESH_COLOR),
      scene,
      false,
    );

    this.topFillingPlaneMaterial = this.createStandardMaterial(
      SLICE_TOOL_CONFIG.TOP_SLICE.MATERIAL_NAME,
      SLICE_TOOL_CONFIG.TOP_SLICE.MAIN_COLOR,
      scene,
      false,
      SLICE_TOOL_CONFIG.MAIN_COLOR_OPACITY,
    );

    this.bottomFillingPlaneMaterial = this.createStandardMaterial(
      SLICE_TOOL_CONFIG.BOTTOM_SLICE.MATERIAL_NAME,
      SLICE_TOOL_CONFIG.BOTTOM_SLICE.MAIN_COLOR,
      scene,
      false,
      SLICE_TOOL_CONFIG.MAIN_COLOR_OPACITY,
    );

    this.fillingPlaneMaterial = this.createStandardMaterial(
      FILLING_PLANE_MATERIAL_NAME,
      ELECTRIC_BLUE_COLOR3,
      scene,
      false,
      SLICE_TOOL_CONFIG.MAIN_COLOR_OPACITY,
    );

    this.topGridMaterial = this.createGridMaterial(
      KNOT_MATERIAL_NAME,
      SLICE_TOOL_CONFIG.TOP_SLICE.LINE_COLOR,
      scene,
      SLICE_TOOL_CONFIG.TOP_SLICE.MAIN_COLOR,
    );
    this.bottomGridMaterial = this.createGridMaterial(
      KNOT_MATERIAL_NAME,
      SLICE_TOOL_CONFIG.BOTTOM_SLICE.LINE_COLOR,
      scene,
      SLICE_TOOL_CONFIG.BOTTOM_SLICE.MAIN_COLOR,
    );
    this.sketchGridMaterial = this.createGridMaterial(
      SKETCH_PLANE_GRID_MAT_NAME,
      ELECTRIC_BLUE_COLOR3,
      scene,
      ELECTRIC_BLUE_COLOR3,
    );
    this.decalMaterial = this.createDecalMaterial(scene);
  }

  destroyMaterials() {
    const allMaterials = [
      this.redMaterial,
      this.electricBlueMaterial,
      this.darkBlueMaterial,
      this.dragMeshMaterial,
      this.topFillingPlaneMaterial,
      this.bottomFillingPlaneMaterial,
      this.fillingPlaneMaterial,
      this.topGridMaterial,
      this.bottomGridMaterial,
      this.sketchGridMaterial,
      this.decalMaterial,
    ];
    for (let i = 0; i < allMaterials.length; i++) {
      if (allMaterials[i]) {
        allMaterials[i].dispose();
        delete allMaterials[i];
      }
    }
    for (const key of Object.keys(this)) {
      (this as any)[key] = null;
    }
  }

  private createStandardMaterial(
    name: string,
    color: Color3,
    scene: CustomScene,
    backFaceCulling: boolean = true,
    alpha: number = 1,
  ): StandardMaterial {
    const material = new StandardMaterial(name, scene);
    material.diffuseColor = color;
    material.backFaceCulling = backFaceCulling;
    material.alpha = alpha;
    return material;
  }

  private createDecalMaterial(scene: CustomScene) {
    const decalMaterial = new StandardMaterial('decalMat', scene);
    decalMaterial.diffuseTexture = new Texture(
      '../../../assets/svg/icons/sf_portal-ui_icon_viewfinder.svg',
      scene,
    );
    decalMaterial.diffuseTexture.hasAlpha = true;
    return decalMaterial;
  }

  private createGridMaterial(
    name: string,
    lineColor: Color3,
    scene: CustomScene,
    mainColor: Color3,
  ): GridMaterial {
    const gridMaterial = new GridMaterial(name, scene);
    gridMaterial.gridRatio = SLICE_TOOL_CONFIG.GRID_RATIO;
    gridMaterial.majorUnitFrequency = SLICE_TOOL_CONFIG.MAJOR_UNIT_FREQUENCY;
    gridMaterial.minorUnitVisibility = SLICE_TOOL_CONFIG.MINOR_UNIT_VISIBILITY;
    gridMaterial.opacity = SLICE_TOOL_CONFIG.GRID_OPACITY;
    gridMaterial.lineColor = lineColor;
    gridMaterial.mainColor = mainColor;
    gridMaterial.backFaceCulling = false;
    return gridMaterial;
  }
}
