import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { LayersByFolders } from '@App/app/entities/layer/layers-by-folders.model';
import { LayersChildren } from '@App/app/entities/layer/layers-children.model';
import { ConfirmationDialogComponent } from '@App/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ElementRef, Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LayersService } from '../../../services/layers/layers.service';
import { updateLayers } from '../../../store/actions/layers.actions';
import { LayersListUtilsService } from '../layers-list-utils-service/layers-list-utils.service';

@UntilDestroy({ arrayName: '_activeSubscriptions' })
@Injectable()
export class LayersListService {
  private _activeSubscriptions: Subscription[] = [];
  private _defaultFolderId: number;
  visibleLayers: LayerUI[] = [];
  selectedLayers: LayerUI[] = [];
  layers: LayerUI[] = [];
  folders: (LayerUI | LayerUiExpandable)[] = [];
  layersByFolders: LayersByFolders = {};
  children$ = new BehaviorSubject<LayersChildren>({});
  rootFolder$: Observable<LayerUiExpandable>;
  isAtLeastOneSelectedLayerVisible$: Observable<boolean>;

  constructor(
    private _layersService: LayersService,
    private _dialogService: NbDialogService,
    private _layersListUtilsService: LayersListUtilsService,
    private store: Store,
  ) {
    this._activeSubscriptions.push(
      this._layersService.defaultFolderId$.subscribe((id) => {
        this._defaultFolderId = id;
      }),
      this._layersService.visibleLayers$.subscribe((layers) => {
        this.visibleLayers = [...layers];
      }),
      this._layersService.selectedLayers$.subscribe((layers) => {
        this.selectedLayers = [...layers];
      }),
      this._layersService.layers$.subscribe((val) => {
        this.layers = [...val];
        const folders = this.folders as LayerUiExpandable[];
        const groupedLayers = this._layersListUtilsService.groupLayersByFolders([...val], folders);
        this._layersListUtilsService.sortGroupedLayers(groupedLayers);
        this.layersByFolders = groupedLayers;
      }),
      this._layersService.folders$.subscribe((val) => {
        val = [...val.map((folder) => ({ ...folder }))];
        this.folders = val.sort((a, b) => a.name.localeCompare(b.name));
        for (const folder of val) {
          if (!this.layersByFolders[folder.id]) {
            this.layersByFolders[folder.id] = [];
          }
        }
      }),
      combineLatest([this._layersService.layers$, this._layersService.folders$]).subscribe(() => {
        const children = this._layersListUtilsService.populateChildrenForEachFolder(
          this.folders,
          this.children$.value,
          this.layersByFolders,
        );
        this.children$.next(children);
      }),
    );

    this.rootFolder$ = this._layersListUtilsService.rootFolder$;

    this.isAtLeastOneSelectedLayerVisible$ = this._layersService.selectedLayers$.pipe(
      map((layers) => {
        const visibleLayerIds = this.visibleLayers.map(({ id }) => id);
        return layers.some((layer) => visibleLayerIds.includes(layer.id));
      }),
    );
  }

  getAllItems() {
    return [...this.folders, ...this.layers];
  }

  filterLayersByKeywords(keywords: string) {
    if (!keywords.length) {
      this.folders.forEach((folder) => (folder.open = false));
      return [];
    } else {
      return this.getAllItems().filter(
        (layer) =>
          (layer.name.toLowerCase().includes(keywords.toLowerCase()) &&
            layer.id !== this._defaultFolderId) ||
          layer.type.toLowerCase().includes(keywords.toLowerCase()),
      );
    }
  }

  getLayerPath(layer: LayerUI | LayerUiExpandable) {
    return this._layersListUtilsService.getLayerPath(layer);
  }

  isExpandableType(layer: LayerUI | LayerUiExpandable) {
    return this._layersListUtilsService.isExpandableType(layer);
  }

  isFolder(layer: LayerUI | LayerUiExpandable) {
    return this._layersListUtilsService.isFolder(layer);
  }

  openParentsAlongToLayer(layer: LayerUI | LayerUiExpandable) {
    const parents = this._layersService.getLayerParents(layer);
    const updates = this._layersListUtilsService.getFoldersOpenRawData(parents);
    this.store.dispatch(updateLayers({ updates }));
  }

  showInfoToast(info: string) {
    this._layersListUtilsService.showToast(info);
  }

  highlightLayerHTMLElement(layer: HTMLElement) {
    this._layersListUtilsService.highlightLayerHTMLElement(layer);
  }

  findLayerElementByRefAndId(ref: ElementRef<HTMLElement>, id: number) {
    return this._layersListUtilsService.findLayerElementByRefAndId(ref, id);
  }

  deleteLayers(layers: LayerUI[]) {
    if (layers.length) {
      this._dialogService
        .open(ConfirmationDialogComponent, {
          context: {
            title: `Are you sure you want to delete ${layers.length} selected layers?`,
            status: 'danger',
          },
        })
        .onClose.pipe(filter(Boolean))
        .subscribe(() => {
          const deletableLayers = layers.filter((layer) => layer.type !== LAYER_TYPES.BASE_PLANE);
          this._layersService.deleteLayers(deletableLayers);
        });
    } else {
      this.showInfoToast('Please select at least one layer.');
    }
  }

  setFolderVisibility(folder: LayerUiExpandable, value: boolean) {
    const layers = this._setFolderVisibility(folder, value, this.visibleLayers);
    this._layersService.setVisibleLayers(layers);
  }

  setFolderGuiVisibility(folder: LayerUiExpandable, value: boolean) {
    const visibleIds = this.visibleLayers.map((layer) => layer.id);
    this.children$.value[folder.id].forEach((child) => {
      if (this.isFolder(child)) {
        this.setFolderGuiVisibility(child as LayerUiExpandable, value);
      } else {
        if (visibleIds.includes(child.id)) {
          this._layersService.setLayerGuiVisibility(child.id, value);
        }
      }
    });
  }

  moveToNewParent(parentId: number) {
    this._layersListUtilsService.moveToNewParent(parentId, this.folders, this.getAllItems());
  }

  private _setFolderVisibility(
    folder: LayerUiExpandable,
    value: boolean,
    visibleLayers: LayerUI[],
  ) {
    this.children$.value[folder.id].forEach((child) => {
      if (this.isFolder(child)) {
        this._setFolderVisibility(child as LayerUiExpandable, value, visibleLayers);
      } else {
        this._layersService.setLayerVisibility(child, value);
        if (value) {
          visibleLayers.push(child);
        } else {
          const index = visibleLayers.findIndex(({ id }) => child.id === id);
          visibleLayers.splice(index, 1);
          [...visibleLayers]
            .filter((l) => l.parentId === child.id)
            .forEach((l) => {
              const i = visibleLayers.findIndex(({ id }) => l.id === id);
              visibleLayers.splice(i, 1);
            });
        }
      }
    });
    return visibleLayers;
  }
}
