import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PresetsHttpService } from '../../services/presets-http/presets-http.service';
import { editPreset, editPresetFailure, editPresetSuccess } from '../actions/presets.actions';

@Injectable()
export class EditPresetEffects {
  constructor(private actions: Actions, private presetsHttpService: PresetsHttpService) {}

  editPreset = createEffect(() => {
    return this.actions.pipe(
      ofType(editPreset),
      mergeMap(({ id, preset }) => {
        return this.presetsHttpService.putPreset(id, preset).pipe(
          map(() => {
            return editPresetSuccess({ id, preset });
          }),
          catchError(() => of(editPresetFailure())),
        );
      }),
    );
  });
}
