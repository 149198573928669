import { BASIC_TYPES } from '@App/app/configs/models.config';
import { ModelsTableFilterForm } from '@App/app/entities/forms/models-table-filter-form.model';
import { AdvancedSearchModel } from '@App/app/entities/shared/advanced-search.model';
import { User } from '@App/app/entities/users/user';
import { ModelsHttpService } from '@App/app/pages/models/services/models-http-service/models-http.service';
import { BaseFilterComponent } from '@App/app/pages/sites/shared/base-filter/base-filter.component';
import { ModelsSitesService } from '@App/app/pages/sites/shared/site-services/models-sites.service';
import { UsersHttpService } from '@App/app/pages/users/users-http.service';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ModelsTableFiltersService } from './models-table-filters.service';

@UntilDestroy()
@Component({
  selector: 'app-models-table-filters',
  templateUrl: './models-table-filters.component.html',
  styleUrls: ['../../shared/base-filter/base-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ModelsTableFiltersComponent
  extends BaseFilterComponent<
    AdvancedSearchModel,
    ModelsTableFilterForm,
    ModelsTableFiltersService,
    ModelsSitesService
  >
  implements OnInit {
  pilots: User[];
  basicModelTypes = BASIC_TYPES;
  loadingPilots = true;
  loadingModelTypes = true;

  constructor(
    filterService: ModelsTableFiltersService,
    siteService: ModelsSitesService,
    cdr: ChangeDetectorRef,
    private modelsHttpService: ModelsHttpService,
    private usersHttpService: UsersHttpService,
  ) {
    super(cdr, siteService, filterService);
  }

  ngOnInit() {
    this.initAdvancedSearch();
  }

  isFormTouched() {
    return super.isFormTouched() || this.isSomeFieldSelected(this.filterForm.value.type);
  }

  initAdvancedSearch() {
    this.loadingPilots = true;
    this.loadingModelTypes = true;

    forkJoin([this.usersHttpService.getAllUsers()])
      .pipe(
        take(1),
        finalize(() => {
          this.loadingModelTypes = false;
          this.loadingPilots = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe(([users]: [User[]]) => {
        this.basicModelTypes.forEach((type) => {
          const typeFormGroup = this.filterForm.controls['type'] as UntypedFormGroup;
          typeFormGroup.addControl(type.abbr + '', new UntypedFormControl(false));
        });
        this.pilots = users;
      });
  }

  resetFilters() {
    if (this.previousSearch) {
      this.filterForm.reset({
        ...this.previousSearch,
      });
    } else {
      const { query, type } = this.filterForm.value;
      for (const item in type) {
        if (type.hasOwnProperty(item)) {
          type[item] = false;
        }
      }
      this.filterForm.reset({
        query,
        type,
        dateFlown: null,
        dateProcessed: null,
        pilot: null,
      });
    }
  }
}
