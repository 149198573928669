import { IPresetModel } from '@App/app/entities/processing/processing-preset-settings.model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/presets.actions';

export const presetsAdapter = createEntityAdapter<IPresetModel>();

export interface State extends EntityState<IPresetModel> {
  currentPreset: IPresetModel | null;
}

const defaultPreset: State = {
  ids: [],
  entities: {},
  currentPreset: null,
};

export const initialState: State = presetsAdapter.getInitialState(defaultPreset);

export const presetsReducer = createReducer(
  initialState,
  on(actions.loadPresetsSuccess, (state, { presets }) => {
    return presetsAdapter.setAll(presets, { ...state });
  }),
  on(actions.addPresetSuccess, (state, { preset }) => {
    return presetsAdapter.addOne(preset, { ...state });
  }),
  on(actions.deletePresetSuccess, (state, { id }) => {
    return presetsAdapter.removeOne(id, state);
  }),
  on(actions.editPresetSuccess, (state, { preset }) => {
    return presetsAdapter.setOne(preset, { ...state });
  }),
);

export const getCurrentPreset = (state: State) => state.currentPreset;
