import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  private _isImperialActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isImperialActive$ = this._isImperialActive$.asObservable();

  constructor() {}

  getImperialUnitsActive() {
    return this._isImperialActive$.value;
  }

  setImperialUnitsActive(value: boolean) {
    this._isImperialActive$.next(value);
  }
}
