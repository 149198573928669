import { Injectable } from '@angular/core';
import { Annotation } from '@App/app/entities/files/annotation.model';
import { Coordinates2 } from '@App/app/entities/layer/measurements/coordinates';
import { AnnotationPriorities } from '@App/app/entities/shared/annotation-priorities.enum';
import { Annotorious } from '@recogito/annotorious';
import Draggable from 'draggable';

@Injectable({
  providedIn: 'root',
})
export class Annotations2dService {
  saveButton: HTMLButtonElement | null = null;
  cancelButton: HTMLButtonElement | null = null;
  deleteButton: HTMLButtonElement | null = null;

  constructor() {}

  createAnnotorious(image: HTMLImageElement) {
    return new Annotorious({
      image,
      allowEmpty: true,
      readOnly: true,
    });
  }

  createAnnotation(
    min: Coordinates2,
    max: Coordinates2,
    title: string = '',
    content: string = '',
    priority = AnnotationPriorities.LOW,
    localId?: string,
  ) {
    const annotation = new Annotation();
    annotation.update(min, max, title, content, priority);
    if (localId) {
      annotation.localId = localId;
    }
    return annotation;
  }

  bindAnnotoriousControls(annotoriousEditor: Element | null, isFormView: boolean) {
    const innerEditor = annotoriousEditor?.querySelector('.r6o-editor-inner');
    if (innerEditor) {
      if (isFormView) {
        this.saveButton = innerEditor.querySelector(
          '.r6o-btn:not(.outline):not(.delete-annotation)',
        );
        this.cancelButton = innerEditor.querySelector('.r6o-btn.outline');
        this.deleteButton = innerEditor.querySelector('.r6o-btn.delete-annotation');
      } else {
        this.cancelButton = innerEditor.querySelector('.r6o-btn');
      }
    }
  }

  toggleVisibility(wrapper: HTMLElement, value: boolean) {
    const layer = wrapper.querySelector('.a9s-annotationlayer');
    (layer as HTMLElement | null)?.style.setProperty('display', value ? 'block' : 'none');
  }

  setEditorStyles(annotoriousEditor: HTMLElement, editor: HTMLDivElement, popup: HTMLElement) {
    const { top, left } = annotoriousEditor.style;
    editor.style.top = top;
    editor.style.left = left;
    editor.style.display = 'block';
    this.initEditorDragging(editor, popup);
  }

  private initEditorDragging(editor: HTMLDivElement, popup: HTMLElement) {
    const { documentElement } = document;
    const [docW, docH] = [documentElement.offsetWidth, documentElement.offsetHeight];
    const [popupW, popupH] = [popup.offsetWidth, popup.offsetHeight];
    const [wMargin, hMargin] = [(docW - popupW) / 2, (docH - popupH) / 2];

    const validatePosition = (element: HTMLDivElement) => {
      const { offsetHeight, offsetWidth, offsetLeft, offsetTop } = element;
      if (offsetLeft < -wMargin) {
        element.style.setProperty('left', -wMargin + 'px');
      }
      if (offsetLeft + offsetWidth > popupW + wMargin) {
        element.style.setProperty('left', Number(popupW + wMargin - offsetWidth) + 'px');
      }
      const padding = 40;
      if (offsetTop < -hMargin - padding) {
        element.style.setProperty('top', Number(-hMargin - padding) + 'px');
      }
      if (offsetTop + offsetHeight > popupH + hMargin - padding) {
        element.style.setProperty('top', Number(popupH + hMargin - padding - offsetHeight) + 'px');
      }
    };

    new Draggable(editor, {
      limit: -(document.documentElement.offsetWidth - popup.offsetWidth) / 2,
      onDrag: validatePosition,
      filterTarget: (element: HTMLElement) => {
        return !element.classList.contains('editor__form__input');
      },
    });

    validatePosition(editor);
  }
}
