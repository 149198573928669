import { getVertexPositions } from '@App/app/engine/utils/babylon-utils/babylon.utils';
import { GizmoManager, UtilityLayerRenderer, Vector3 } from 'babylonjs';
import { Mesh } from 'babylonjs/Meshes/mesh';
import { User } from '../../users/user';
import { CustomScene } from '../../viewer/custom-scene.model';
import { LAYER_TYPES } from '../enums/layer-types.enum';
import { Layer } from '../layer.model';
import { Coordinates3 } from '../measurements/coordinates';
import { NameTag } from '../name-tag.model';

export class SketchPlane implements Layer<SketchPlaneData> {
  name: string;
  isVisible: boolean;
  isSaved = true;
  type = LAYER_TYPES.SKETCH_PLANES;
  parentId: number;
  id: number;
  data: SketchPlaneData = {};
  createdAt: string;
  editedAt: string;
  description: string;

  open?: boolean; // treat it as a Folder

  // babylon
  normal?: Vector3;
  pointsMeshes?: Mesh[] = [];
  triangleMesh?: Mesh | null;
  plane?: {
    gridPlane: Mesh;
    fillingPlane: Mesh;
  };

  gizmoManager?: GizmoManager | null;
  user?: User;
  gui?: NameTag;

  constructor(scene?: CustomScene) {
    if (scene) {
      this.gizmoManager = new GizmoManager(scene, 1, new UtilityLayerRenderer(scene));
    }
    this.name = 'sketch plane';
  }

  static removeAllNodes(sketchPlane: SketchPlane) {
    this.removePoints(sketchPlane);
    sketchPlane.pointsMeshes = [];
    sketchPlane.gizmoManager?.dispose();
    sketchPlane.gizmoManager = null;
    sketchPlane.triangleMesh?.dispose();
    sketchPlane.triangleMesh = null;
    this.removePlane(sketchPlane);
  }

  static createSketchPlaneForHttpRequest(sketchPlane: SketchPlane) {
    const tmpSketchPlane = new SketchPlane();
    if (sketchPlane.id) {
      tmpSketchPlane.id = sketchPlane.id;
    }
    tmpSketchPlane.name = sketchPlane.name;
    tmpSketchPlane.parentId = sketchPlane.parentId;
    tmpSketchPlane.isVisible = sketchPlane.isVisible ?? true;
    tmpSketchPlane.data = {
      norm: sketchPlane.normal
        ? {
            x: sketchPlane.normal.x,
            y: sketchPlane.normal.y,
            z: sketchPlane.normal.z,
          }
        : { ...(sketchPlane.data.norm as Coordinates3) },
      vertexPositions: sketchPlane.pointsMeshes?.length
        ? getVertexPositions(sketchPlane.pointsMeshes)
        : sketchPlane.data.vertexPositions,
    };

    return tmpSketchPlane;
  }

  static removePoints(sketchPlane: SketchPlane) {
    if (sketchPlane.pointsMeshes) {
      for (const mesh of sketchPlane.pointsMeshes) {
        mesh.dispose();
      }
      sketchPlane.pointsMeshes = [];
    }
  }

  static removePlane(sketchPlane: SketchPlane) {
    if (sketchPlane.plane?.fillingPlane && sketchPlane.plane?.gridPlane) {
      for (const prop in sketchPlane.plane) {
        if (sketchPlane.plane.hasOwnProperty(prop)) {
          (sketchPlane.plane as any)[prop]?.dispose();
          (sketchPlane.plane as any)[prop] = null;
        }
      }
    }
  }
}

export type sketchPlanePredicate = (mesh: Mesh) => boolean;

export interface SketchPlaneData {
  norm?: Coordinates3;
  vertexPositions?: Coordinates3[];
}

export interface ViewerSketchPlaneLayer extends SketchPlane {
  pointsMeshes: Mesh[];
  triangleMesh: Mesh;
  normal: Vector3;
  plane: {
    gridPlane: Mesh;
    fillingPlane: Mesh;
  };
  gizmoManager: GizmoManager;
  data: SketchPlaneData;
}
