import { BuildProcess } from '@App/app/entities/processing/build-process.model';

export const getBuildProcessUsers = (buildProcess: BuildProcess) => {
  const { owner, subscribers } = buildProcess.getUsers();
  return [owner, subscribers];
};

export const sortByProgress = (_: unknown, __: unknown, a: BuildProcess, b: BuildProcess) => {
  return a.compareByProgress(b);
};
