<nb-card size="tiny" class="thumbnail" [nbSpinner]="!(isLoaded$ | async)">
  <ng-container *ngIf="isLoaded$ | async">
    <nb-card-body>
      <img
        [src]="thumbnail?.downloadURL || placeholder"
        alt="thumbnail"
        class="thumbnail__image"
        [ngClass]="{ '--placeholder': !thumbnail }"
      />
    </nb-card-body>
    <nb-card-footer>
      <input
        *ngxPermissionsOnly="permissions.CAN_EDIT_THUMBNAIL"
        type="file"
        accept="image/*"
        id="thumbnailInput"
        style="display: none;"
        data-testId="thumbnail-change-input"
        (change)="onChange($event)"
      />
      <button nbButton>
        <label for="thumbnailInput" nbButton size="medium" class="thumbnail__edit_btn">
          <nb-icon icon="edit-outline"></nb-icon>
        </label>
      </button>
      <ng-container *ngxPermissionsOnly="permissions.CAN_DELETE_THUMBNAIL">
        <button
          nbButton
          status="danger"
          (click)="onDelete()"
          *ngIf="thumbnail"
          data-testId="delete-btn"
          class="thumbnail__delete_btn"
        >
          <nb-icon icon="trash-outline"></nb-icon>
        </button>
      </ng-container>
    </nb-card-footer>
  </ng-container>
</nb-card>
