import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ViewerLayerService } from '../../../../engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { removeAllLayersLocal } from '../actions/layers.actions';

@Injectable()
export class RemoveAllLayersLocal {
  constructor(private viewerLayerService: ViewerLayerService, private actions: Actions) {}

  removeAllLayersLocal = createEffect(
    () => {
      return this.actions.pipe(
        ofType(removeAllLayersLocal),
        tap(() => this.viewerLayerService.deleteAllLocal()),
      );
    },
    { dispatch: false },
  );
}
