import { AuthService } from '@App/app/auth/auth.service';
import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Model } from '@App/app/entities/models/model.model';
import { Render } from '@App/app/entities/processing/render.model';
import { ModelsService } from '@App/app/pages/models/services/models-service/models.service';
import { ThumbnailService } from '@App/app/shared/services/thumbnail/thumbnail.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, Params, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { EMPTY, Subscription, merge } from 'rxjs';
import { filter, first, map, pairwise, startWith } from 'rxjs/operators';
import { ModelsProcessingService } from '../../processing/services/models-processing-service/models-processing.service';
import { RendersService } from '../services/renders-service/renders.service';
import { removeCurrentModelLocal } from '../store/models/actions/models.actions';
import { selectCurrentModel } from '../store/models/selectors/models.selectors';

@UntilDestroy({ arrayName: 'activeSubscriptions' })
@Component({
  selector: 'app-model-details',
  templateUrl: './model-details.component.html',
  styleUrls: ['./model-details.component.scss'],
})
export class ModelDetailsComponent implements OnInit, OnDestroy {
  private activeSubscriptions: Subscription[] = [];
  companyId: number;
  modelId: number | null = null;
  renderId: number | null = null;
  model: Model | null = null;
  permissions = UserPermissions;
  selectedRender: Render | null;
  nbLayoutActive = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private modelsService: ModelsService,
    private rendersService: RendersService,
    private cdr: ChangeDetectorRef,
    private engineService: CustomerEngineService,
    private modelsProcessingService: ModelsProcessingService,
    private store: Store,
    /**
     * do not remove it since it's needed for detect if model has loaded
     * in some sub details page (e.g. in photos). This is why it has to
     * be injected over here
     */
    _: ThumbnailService,
  ) {}

  ngOnInit(): void {
    this.activeSubscriptions.push(
      merge(
        /**
         * When user loads the app from overview, renderId change does never emit
         * value, this is why firstChild is attached over here.
         */
        this.route.params,
        this.route.firstChild?.params || EMPTY,
        this.router.events.pipe(
          filter((e) => e instanceof ChildActivationEnd),
          map((e: ChildActivationEnd) => e.snapshot.params),
        ),
      )
        .pipe(
          startWith(({} as unknown) as Params),
          pairwise(),
          filter(([prev, curr]) => {
            const { id: currId, renderId: currRenderId } = curr;
            const { id: prevId, renderId: prevRenderId } = prev;
            return currId !== prevId || String(currRenderId) !== String(prevRenderId);
          }),
          map(([_, curr]) => curr),
        )
        .subscribe((params) => {
          if (params.renderId) {
            this.onRenderUrlParameterChange(params.renderId);
          }

          if (params.id && !this.model) {
            this.onModelUrlParameterChange(params.id);
          }
        }),
      this.authService.currentEndpoint$.pipe(first()).subscribe((endpoint: Endpoint) => {
        this.companyId = endpoint.id;
      }),
      this.rendersService.currentRenderId$.subscribe((id) => {
        this.renderId = id;
      }),
      this.rendersService.renders$.pipe(filter<Render[]>(Boolean)).subscribe(() => {
        this.loadCurrentRender();
      }),
      this.rendersService.currentRender$.subscribe((render) => {
        const renders = this.rendersService.getRenders();
        this.selectedRender = renders?.find((r) => r.id === render?.id) || null;
      }),
      this.store.select(selectCurrentModel).subscribe((model) => {
        this.model = model ? { ...model } : null;
      }),
    );
  }

  ngOnDestroy() {
    this.engineService.destroyViewer();
    this.modelsProcessingService.setCurrentBuildProcess(null);
    this.store.dispatch(removeCurrentModelLocal());
    this.rendersService.resetAll();
    window.stop();
  }

  onRenderChange(render: Render) {
    if (this.renderId && render.id !== +this.renderId) {
      this.rendersService.setCurrentRenderId(render.id);
      this.rendersService.setCurrentRender(null);
      const urlParts = this.router.url.split('/');
      const tab = urlParts[urlParts.length - 1];
      this.router.navigate([this.companyId, 'models', this.modelId, 'renders', render.id, tab]);
    }
  }

  isOverviewActive() {
    const urlParts = this.router.url.split('/');
    return !isNaN(+urlParts[urlParts.length - 1]);
  }

  getBackUrl() {
    const modelsPath = `/${this.companyId}/models`;
    return this.isOverviewActive() ? modelsPath : `${modelsPath}/${this.modelId}`;
  }

  getRenders() {
    return this.selectedRender ? this.rendersService.getRenders() || [] : [];
  }

  private loadCurrentRender() {
    if (this.modelId && this.renderId) {
      this.rendersService.loadCurrentRender(this.modelId, this.renderId);
    }
  }

  private reloadViewer() {
    if (this.model) {
      const tmpModel = { ...this.model };
      this.model = null;
      this.cdr.detectChanges();
      this.model = tmpModel;
      this.cdr.detectChanges();
    }
  }

  private onModelUrlParameterChange(newId: number) {
    this.modelId = newId;
    this.modelsService.loadModel(this.modelId);
    if (!this.rendersService.getRenders()) {
      this.rendersService.loadAllRenders(this.modelId);
    }
  }

  private onRenderUrlParameterChange(newId: number) {
    this.rendersService.setCurrentRender(null);
    this.rendersService.setCurrentRenderId(newId);
    this.reloadViewer();
    this.loadCurrentRender();
  }
}
