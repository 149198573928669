<div class="site_header" [nbSpinner]="loading">
  <ng-container *ngIf="buildProcess && !loading">
    <div class="site_header__title">
      <a class="site_header__back" (click)="goBack()">
        <nb-icon icon="arrow-ios-back"></nb-icon>
      </a>
      <span class="site_header__name">
        {{ buildProcess.model.name }}
      </span>
      <span class="site_header__info">{{ buildProcess.model.id }}</span>
    </div>
  </ng-container>
</div>
<router-outlet></router-outlet>
