import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { selectUser } from '@App/app/auth/store/auth/selectors/auth.selectors';
import { FAILED } from '@App/app/configs/toastr-events.config';
import { FAILED_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { NGXLogger } from 'ngx-logger';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { versions } from 'src/environments/versions';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private errorsToIgnore = ['ResizeObserver loop limit exceeded'];

  constructor(
    private injector: Injector,
    private toastrService: NbToastrService,
    private logger: NGXLogger,
  ) {
    if (environment.production) {
      Sentry.init({
        environment: environment.name,
        release: versions.version,
        dsn: 'https://5054a51b463b41a6b1c46ce5729cf04a@o538395.ingest.sentry.io/5656407',
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: [
              'http://skyportal-dev.s3-website.us-east-2.amazonaws.com/',
              'http://skyportal-staging.s3-website.us-east-2.amazonaws.com/',
              'https://portal.skyfish.ai',
            ],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        allowUrls: ['skyportal-dev', 'skyportal-staging', 'portal.skyfish'],
        tracesSampleRate: 1.0,
        ignoreErrors: this.errorsToIgnore,
      });
      this.injector
        .get(Store)
        .select(selectUser)
        .pipe(take(1))
        .subscribe((user) => {
          if (user) {
            Sentry.setUser({ email: user.email, username: `${user.firstName} ${user.lastName}` });
          }
        });
    }
  }

  handleError(error: any) {
    if (this.errorsToIgnore.includes(error.message)) {
      return;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        this.logger.error('An error occurred:', error.error.message);
      } else {
        this.logger.error(
          `Backend returned code ${error.status}, ` + `body was: ${error.error.message}`,
        );
      }
    } else {
      this.logger.error(error);
    }
    this.toastrService.show('A problem occurred, please try again later.', FAILED, {
      ...FAILED_TOASTR_CONFIG,
    });
    this.notifySentry(error);
  }

  private notifySentry(error: Error) {
    if (this.shouldNotifySentry(error)) {
      Sentry.captureException(error);
    }
  }

  private shouldNotifySentry(error: Error): boolean {
    const hostname = document.location.hostname;
    if (hostname === 'localhost' || hostname === '127.0.0.1') {
      return false;
    }
    if (!environment.production) {
      return false;
    }
    if (this.hasIgnoredStatus(error)) {
      return false;
    }
    return true;
  }

  private hasIgnoredStatus(error: Error) {
    return (error as any).status === 404
      ? false
      : error instanceof HttpErrorResponse && error.status >= 400 && error.status <= 499;
  }
}
