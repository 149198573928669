<div class="sidebar__content">
  <div class="sidebar__desc">
    Please review the production results and proceed to upload the model for customers or retry PROD
    step with different settings
  </div>
</div>
<app-tab-actions
  [backAction]="onCancel"
  backActionName="Retry"
  [nextAction]="onContinue"
  [nextActionPermission]="canAccept"
  [backActionPermission]="canRetry"
  [log]="log"
></app-tab-actions>
