<div class="layer_details__subtitle">Dimensions:</div>
<div class="layer_details__row left">
  <div class="layer_details__label">Length:</div>
  <div class="layer_details__value">
    <app-layer-details-optional-measurement-field
      [value]="(layer | angleIronDimensions)?.length"
      [unitsSystem]="unitsSystem"
      type="length"
    ></app-layer-details-optional-measurement-field>
  </div>
</div>
<div class="layer_details__row left">
  <div class="layer_details__label">Leg 1:</div>
  <div class="layer_details__value">
    <app-layer-details-optional-measurement-field
      [value]="(layer | angleIronDimensions)?.leg1"
      [unitsSystem]="unitsSystem"
      type="length"
    ></app-layer-details-optional-measurement-field>
  </div>
</div>
<div class="layer_details__row left">
  <div class="layer_details__label">Leg 2:</div>
  <div class="layer_details__value">
    <app-layer-details-optional-measurement-field
      [value]="(layer | angleIronDimensions)?.thickness"
      [unitsSystem]="unitsSystem"
      type="length"
    ></app-layer-details-optional-measurement-field>
  </div>
</div>
<app-layer-details-thickness-field
  [layer]="layer"
  [value]="(layer | angleIronDimensions)?.thickness"
  [unitsSystem]="unitsSystem"
  class="layer_details__row left"
></app-layer-details-thickness-field>
<button
  type="button"
  class="layer_details__action layer_details__action--edit layer_details__copy select-none"
  (click)="copyDimensions()"
>
  Copy
</button>
<app-layer-details-position [position]="layer | angleIronCenter"> </app-layer-details-position>
<app-layer-details-geolocation [position]="layer | angleIronCenter"></app-layer-details-geolocation>
