import { THUMBNAIL_PLACEHOLDER } from '@App/app/configs/app.config';
import { Model } from '@App/app/entities/models/model.model';
import { selectThumbnailByModelId } from '@App/app/pages/models/store/models/selectors/model-files.selectors';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Pipe({
  name: 'thumbnail',
  pure: false,
})
export class ThumbnailPipe implements PipeTransform {
  private _modelId: string;
  private _value = THUMBNAIL_PLACEHOLDER;

  constructor(private store: Store, private _cdr: ChangeDetectorRef) {}

  transform({ id: modelId }: Model): string | null {
    if (modelId !== this._modelId) {
      this._setModelId(modelId);
    }

    return this._value;
  }

  private _setModelId(modelId: string) {
    this._modelId = modelId;

    this.store
      .select(selectThumbnailByModelId(+this._modelId))
      .pipe(
        untilDestroyed(this),
        // eslint-disable-next-line ngrx/avoid-mapping-selectors
        map((thumbnail) => thumbnail?.downloadURL),
      )
      .subscribe((url: string) => {
        this._value = url || THUMBNAIL_PLACEHOLDER;
        this._cdr.markForCheck();
      });
  }
}
