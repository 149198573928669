import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@App/app/auth/auth.service';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModelsListGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permission = route.data['permission'];
    const redirectPermissions = route.data['redirectPermissions'];
    if (this.permissionsService.getPermission(permission)) {
      return true;
    } else {
      for (const perm of redirectPermissions) {
        if (this.permissionsService.getPermission(perm)) {
          this.redirect(perm);
          break;
        }
      }
    }
    return false;
  }

  redirect(redirectPermission: string) {
    this.authService.currentEndpoint$.pipe(take(1)).subscribe((endpoint: Endpoint) => {
      const companyId = endpoint.id;
      const redirectPaths = {
        [UserPermissions.CAN_ACCESS_MODEL_LIST]: `${companyId}/models`,
        [UserPermissions.CAN_ACCESS_PROCESSING_LIST]: `${companyId}/processing`,
        [UserPermissions.CAN_UPLOAD_PHOTOS]: `${companyId}/uploader`,
      };
      if (redirectPaths[redirectPermission as keyof typeof redirectPaths]) {
        this.router.navigate([redirectPaths[redirectPermission as keyof typeof redirectPaths]]);
      } else {
        this.authService.onLogout();
      }
    });
  }
}
