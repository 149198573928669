import { ModelPhotosService } from '@App/app/pages/models/model-details/model-photos/services/model-photos-service/model-photos.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PhotosHttpService } from '../../../photos-http.service';
import { loadAllPhotos, loadAllPhotosSuccess } from '../actions/photos.actions';

@Injectable()
export class LoadPhotosEffects {
  constructor(
    private actions: Actions,
    private photosHttpService: PhotosHttpService,
    private modelPhotosService: ModelPhotosService,
  ) {}

  loadAllPhotos = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAllPhotos),
      mergeMap(({ modelId }) => {
        this.modelPhotosService.setLoading(true);
        return this.photosHttpService.getAllPhotos(modelId).pipe(
          map((photos) => {
            this.modelPhotosService.setLoading(false);
            return loadAllPhotosSuccess({ photos });
          }),
          catchError(() => {
            this.modelPhotosService.setLoading(false);
            return EMPTY;
          }),
        );
      }),
    );
  });
}
