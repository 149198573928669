import { ControlPointsForm } from '@App/app/entities/forms/at-setup-tab-form.model';
import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ControlPointMeasurement,
  RawControlPointMeasurement,
} from '../../../models/control-point-measurement.model.';
import { ControlPoint } from '../../../models/control-point.model';
import { Srs } from '../../srs-dropdown/models/srs.model';

@Injectable()
export class AtSetupControlPointsService {
  private _selectedMeasurements$ = new BehaviorSubject<ControlPointMeasurement[]>([]);
  selectedMeasurements$ = this._selectedMeasurements$.asObservable();
  private _activeControlPointName$ = new BehaviorSubject<string | null>(null);
  activeControlPointName$ = this._activeControlPointName$.asObservable();
  form: ControlPointsForm;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      accuracy: this.fb.control<string | null>(null, { validators: [Validators.required] }),
      srs: this.fb.control<Srs | null>(null, { validators: [Validators.required] }),
      controlPoints: this.fb.control<ControlPoint[]>([], {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        validators: [this._enoughMeasurementsForEachControlPoint],
      }),
      useImageGPSMetadata: this.fb.control<boolean | null>(true, {
        validators: [Validators.required],
      }),
    });
  }

  setControlPoints(controlPoints: ControlPoint[]) {
    this.form.patchValue({ controlPoints });
  }

  getControlPoints() {
    return this.form.controls.controlPoints.value || [];
  }

  addControlPoint(controlPoint: RawControlPointMeasurement) {
    if (this._activeControlPointName$.value) {
      this._selectedMeasurements$.next([
        ...this._filterMeasurements(controlPoint.imageId),
        { ...controlPoint, controlPointName: this._activeControlPointName$.value },
      ]);
    }
  }

  deleteControlPointByImageId(id: number) {
    this._selectedMeasurements$.next(this._filterMeasurements(id));
  }

  setActiveControlPointName(name: string | null) {
    this._activeControlPointName$.next(name);
  }

  createHttpControlPoints(controlPoints: ControlPoint[]) {
    const getControlPointMeasurements = (
      selectedPoints: ControlPointMeasurement[],
      controlPointName: string,
    ): RawControlPointMeasurement[] => {
      return selectedPoints
        .filter((selectedPoint) => selectedPoint.controlPointName === controlPointName)
        .map((selectedPoint) => {
          return {
            imageName: selectedPoint.imageName,
            imageId: selectedPoint.imageId,
            pos: {
              x: selectedPoint.pos.x,
              y: selectedPoint.pos.y,
            },
          };
        });
    };
    const result: ControlPoint[] = [];
    this.selectedMeasurements$.pipe(take(1)).subscribe((selectedPoints) => {
      controlPoints.forEach((controlPoint) => {
        result.push({
          ...controlPoint,
          measurements: getControlPointMeasurements(selectedPoints, controlPoint.name),
        });
      });
    });
    return result;
  }

  checkAllControlPointsAreValid() {
    const controlPoints = this.getControlPoints();
    return this._getValidControlPoints(controlPoints).length === controlPoints.length;
  }

  private _filterMeasurements(imageId: number) {
    return this._selectedMeasurements$.value.filter(
      (point) =>
        point.imageId !== imageId || point.controlPointName !== this._activeControlPointName$.value,
    );
  }

  private _enoughMeasurementsForEachControlPoint = (control: AbstractControl<ControlPoint[]>) => {
    const validControlPoints = this._getValidControlPoints(control.value);
    return validControlPoints.length < 3 ? { notEnoughMeasurements: true } : null;
  };

  private _getValidControlPoints(controlPoints: ControlPoint[]) {
    const measurementCPNames = this._selectedMeasurements$.value.map((m) => m.controlPointName);
    return controlPoints.filter(({ name }) => {
      const count = measurementCPNames.filter((n) => n === name).length;
      return count >= 3;
    });
  }
}
