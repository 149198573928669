import { ERRORS_RESPOND_ARRAY } from '@App/app/configs/app.config';
import { MODELS_DELETE_SUCCESS, SUCCESS } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { IErrorIdentification } from '@App/app/entities/shared/error-identificator.model';
import { ModelsSitesService } from '@App/app/pages/sites/shared/site-services/models-sites.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, delay, finalize, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ModelsHttpService } from '../../../services/models-http-service/models-http.service';
import { ModelsService } from '../../../services/models-service/models.service';
import { deleteModels, deleteModelsFailed, deleteModelsSuccess } from '../actions/models.actions';
import { selectAllModels } from '../selectors/models.selectors';

@Injectable()
export class DeleteModelsEffects {
  constructor(
    private actions: Actions,
    private sitesService: ModelsSitesService,
    private modelsHttpService: ModelsHttpService,
    private toastrService: NbToastrService,
    private modelsService: ModelsService,
    private store: Store,
  ) {}

  deleteModels = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteModels),
      mergeMap(({ ids, force }) => {
        const result = this.store.select(selectAllModels).pipe(
          first(),
          switchMap(async (models) => {
            const batch: IErrorIdentification[] = [];
            for (const id of ids) {
              const errorModels = await this.modelsHttpService
                .deleteModel(id, force)
                .pipe(
                  delay(500),
                  catchError((error) => {
                    return of(error);
                  }),
                  map(
                    (error: HttpErrorResponse | null): IErrorIdentification => {
                      return {
                        id,
                        error: error instanceof HttpErrorResponse ? error : null,
                        name: models.find((model) => +model.id === id)?.name || '',
                      };
                    },
                  ),
                )
                .toPromise();
              batch.push(errorModels);
            }
            // eslint-disable-next-line ngrx/no-multiple-actions-in-effects
            return batch;
          }),
        );
        return result
          .pipe(
            map((modelError: IErrorIdentification[]) => {
              this.sitesService.setSelectedEntities([]);
              if (modelError.some((error) => !!error.error)) {
                // models with errors
                const deletableModels = modelError.filter(
                  (error) =>
                    error.error?.error.message ===
                    "There are some existing build processes for this model. If you are sure about deleting all of them along with the model, please pass additional query param 'force=true'",
                );
                const indelibleModelMessages = ERRORS_RESPOND_ARRAY;
                const indelibleModels = modelError.filter((error) =>
                  indelibleModelMessages.includes(error.error?.error.message),
                );
                const deletedModels = modelError.filter((error) => error.error === null);
                return deleteModelsFailed({
                  deletableModels,
                  indelibleModels,
                  deletedModels,
                });
              } else {
                // models without any errors
                return deleteModelsSuccess({ ids });
              }
            }),
          )
          .pipe(
            finalize(() => {
              this.sitesService.hideSpinner();
            }),
          );
      }),
    );
  });

  deleteModelsSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(deleteModelsSuccess),
        tap(({}) => {
          this.sitesService.setSelectedEntities([]);

          this.toastrService.show(MODELS_DELETE_SUCCESS, SUCCESS, {
            ...SUCCESS_TOASTR_CONFIG,
          });
        }),
      );
    },
    { dispatch: false },
  );

  deleteModelsFailed = createEffect(
    () => {
      return this.actions.pipe(
        ofType(deleteModelsFailed),
        map(({ deletableModels, indelibleModels, deletedModels }) => {
          this.modelsService.openDeleteModelsConfirmationDialog(
            deletableModels,
            indelibleModels,
            deletedModels,
          );
        }),
      );
    },
    { dispatch: false },
  );
}
