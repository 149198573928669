import { AuthService } from '@App/app/auth/auth.service';
import { User } from '@App/app/entities/users/user';
import { ChangeDetectionStrategy, Component, Renderer2, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { BaseSiteComponent } from '../shared/base-site/base-site.component';
import { UsersSitesService } from '../shared/site-services/users-sites.service';

@Component({
  selector: 'app-users-site',
  templateUrl: './users-site.component.html',
  styleUrls: ['../shared/base-site/base-site.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersSiteComponent extends BaseSiteComponent<User, UsersSitesService> {
  constructor(
    authService: AuthService,
    renderer: Renderer2,
    permissionsService: NgxPermissionsService,
    store: Store,
    sitesService: UsersSitesService,
  ) {
    super(authService, renderer, permissionsService, store, sitesService);
  }
}
