import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { ControlPointMeasurement } from '../../models/control-point-measurement.model.';
import { ControlPoint } from '../../models/control-point.model';
import { AtSetupControlPointsService } from '../services/at-setup-control-points-service/at-setup-control-points.service';

@UntilDestroy()
@Component({
  selector: 'app-control-points-list',
  templateUrl: './control-points-list.component.html',
  styleUrls: ['./control-points-list.component.scss'],
})
export class ControlPointsListComponent {
  @Input() controlPoints: ControlPoint[];
  @Output() activePoint: EventEmitter<string> = new EventEmitter();
  selectedPointName: string;
  measurements: ControlPointMeasurement[] = [];

  constructor(private atSetupControlPointsService: AtSetupControlPointsService) {
    this.atSetupControlPointsService.selectedMeasurements$
      .pipe(untilDestroyed(this))
      .subscribe((measurements) => {
        this.measurements = measurements;
      });

    this.atSetupControlPointsService.activeControlPointName$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((name: string) => {
        this.selectedPointName = name;
      });
  }

  getNumOfMeasurements(controlPointName: string): number {
    return this.measurements.filter(
      (measurement) => measurement.controlPointName === controlPointName,
    ).length;
  }

  selectPoint(name: string) {
    this.selectedPointName = name;
    this.activePoint.emit(name);
  }
}
