import { Injectable, QueryList, ViewChildren } from '@angular/core';
import { TOOLS_ORDER } from '@App/app/configs/layers-bar.config';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { LAYER_NAMES } from '@App/app/entities/layer/enums/layer-names.enum';
import { LAYER_EVENTS } from '@App/app/entities/layer/enums/layer-types.enum';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { NbPopoverDirective } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateLayerBarService } from '../create-layers-bar/create-layer-bar.service';
import { ActiveLayerToolService } from '../services/active-layer-tool/active-layer-tool.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CreateLayerButtonService {
  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  private toolsOrder = TOOLS_ORDER;
  isEditMode: boolean;
  activeTool: LAYER_NAMES = LAYER_NAMES.NULL;
  actualPopoverIndex: number;
  slicePlanesFlag = true;
  activeLayerMode = LAYER_NAMES.BOUNDING_BOX;

  buttonFunctions: { [key: string]: () => void } = {
    [LAYER_NAMES.MEASUREMENT]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_MEASUREMENT, LAYER_NAMES.MEASUREMENT),
    [LAYER_NAMES.AREA_POLYGON]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_POLYGON, LAYER_NAMES.AREA_POLYGON),
    [LAYER_NAMES.DROP_LINE]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_DROP_LINE, LAYER_NAMES.DROP_LINE),
    [LAYER_NAMES.SLICE_PLANE]: () => this.toggleShowSlicePlanes(),
    [LAYER_NAMES.COORDINATES_LAYER]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_COORDINATES_LAYER, LAYER_NAMES.COORDINATES_LAYER),
    [LAYER_NAMES.SKETCH_PLANE]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_SKETCH_PLANE, LAYER_NAMES.SKETCH_PLANE),
    [LAYER_NAMES.TUBE_PICKER]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_TUBE_PICKER, LAYER_NAMES.TUBE_PICKER),
    [LAYER_NAMES.TUBE]: () => this.toggleActiveLayer(LAYER_EVENTS.ADD_TUBE, LAYER_NAMES.TUBE),
    [LAYER_NAMES.BOUNDING_BOX]: () => {
      if (this.activeLayerMode === LAYER_NAMES.BOUNDING_BOX) {
        this.toggleActiveLayer(LAYER_EVENTS.ADD_BOUNDING_BOX, LAYER_NAMES.BOUNDING_BOX);
      } else {
        this.initCuboidPicker();
      }
      this.switchLayerMode();
    },
    [LAYER_NAMES.CUBOID]: () => this.toggleActiveLayer(LAYER_EVENTS.ADD_CUBOID, LAYER_NAMES.CUBOID),
    [LAYER_NAMES.ANGLE_IRON]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_ANGLE_IRON, LAYER_NAMES.ANGLE_IRON),
    [LAYER_NAMES.RECT_TUBE]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_RECT_TUBE, LAYER_NAMES.RECT_TUBE),
    [LAYER_NAMES.ANNOTATION]: () =>
      this.toggleActiveLayer(LAYER_EVENTS.ADD_ANNOTATION, LAYER_NAMES.ANNOTATION),
  };

  constructor(
    private createLayerBarService: CreateLayerBarService,
    private broadcastService: BroadcastService,
    private activeLayerToolService: ActiveLayerToolService,
    private layerEventsService: LayerEventsService,
  ) {}

  toggleActiveLayer(event: LAYER_EVENTS, name: LAYER_NAMES) {
    this.createLayerBarService.toggleActiveLayer(event, name, this.activeTool);
    if (this.isEditMode === true) {
      this.createLayerBarService.toggleActiveLayer(event, name, this.activeTool);
    }
    this.actualPopoverIndex = this.toolsOrder.findIndex((n) => n === name);
  }

  toggleShowSlicePlanes() {
    this.toggleActiveLayer(LAYER_EVENTS.ADD_SLICE_PLANE, LAYER_NAMES.SLICE_PLANE);
    this.broadcastService.broadcast(EVENT_TYPE.SHOW_SLICE_PLANES, this.slicePlanesFlag);
    this.slicePlanesFlag = !this.slicePlanesFlag;
  }

  initCuboidPicker() {
    this.broadcastService.broadcast(EVENT_TYPE.INIT_CUBOID_PICKER, null);
    this.activeLayerToolService.setActiveTool(LAYER_NAMES.BOUNDING_BOX);
    this.generateManualTool();
  }

  generateManualTool() {
    this.createLayerBarService.generateManualTool();
    this.createLayerBarService.isEditMode$.pipe(untilDestroyed(this)).subscribe((editMode) => {
      this.isEditMode = editMode;
    });
  }

  switchLayerMode() {
    this.activeLayerMode =
      this.activeLayerMode === LAYER_NAMES.BOUNDING_BOX
        ? LAYER_NAMES.CUBOID_PICKER
        : LAYER_NAMES.BOUNDING_BOX;
  }
}
