import { RegionOfInterestService } from '@App/app/engine/services/region-of-interest-service/region-of-interest.service';
import { ATSetupTabForm } from '@App/app/entities/forms/at-setup-tab-form.model';
import { PpSetupForm } from '@App/app/entities/forms/pp-setup-tab-form.model';
import { ProductionForm } from '@App/app/entities/forms/production-form.model';
import { PostProcessingSettings } from '@App/app/entities/processing/build-process-status.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { IPresetModel } from '@App/app/entities/processing/processing-preset-settings.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import {
  startAerotriangulation,
  startPostProcessing,
  startProduction,
} from '@App/app/pages/processing/store/processes/actions/processes.actions';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ProcessingService } from '../processing.service';
import { AtSetupControlPointsService } from '../tabs/at-setup-tab/at-setup-control-points/services/at-setup-control-points-service/at-setup-control-points.service';
import { ProcessingPresetsService } from '../tabs/at-setup-tab/services/processing-presets/processing-presets.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-processing-sidebar',
  templateUrl: './processing-sidebar.component.html',
  styleUrls: ['./processing-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProcessingSidebarComponent implements OnInit, OnDestroy {
  companyId: number | null;
  ProcessingSteps = ProcessingSteps;
  activeStep$: Observable<ProcessingSteps | 0>;
  availableSteps: ProcessingSteps | 0;
  currentBuildProcess: BuildProcess;
  isATWaiting: boolean;

  constructor(
    private authService: AuthService,
    private dialogService: NbDialogService,
    private processingService: ProcessingService,
    private modelsProcessingService: ModelsProcessingService,
    private atSetupControlPointsService: AtSetupControlPointsService,
    private store: Store,
    private _processingPresetsService: ProcessingPresetsService,
    private regionOfInterestService: RegionOfInterestService,
  ) {}

  ngOnInit(): void {
    this.authService.currentEndpoint$.pipe(untilDestroyed(this)).subscribe((endpoint) => {
      this.companyId = endpoint?.id || null;
    });
    this.activeStep$ = this.processingService.activeStep$;
    this.modelsProcessingService.currentBuildProcess
      .pipe(untilDestroyed(this), filter<BuildProcess>(Boolean), take(1))
      .subscribe((process) => {
        this.currentBuildProcess = process;
        this.processingService.init(process);
        this.regionOfInterestService.initProcess(process);
        this.availableSteps = this.processingService.getAvailableSteps();
        this.isATWaiting = process.steps.at.isWaiting();
      });

    this._processingPresetsService.getBuildPresets().subscribe((presets: IPresetModel[]) => {
      this.processingService.buildProcessPresets = presets;
    });
  }

  ngOnDestroy() {
    this.processingService.clear();
    this.modelsProcessingService.clearCurrentBuildProcess();
  }

  atHasFailed() {
    return this.availableSteps === 1 && this.currentBuildProcess.isFailed();
  }

  onNavClick(step: ProcessingSteps) {
    if (step <= this.availableSteps || (step === ProcessingSteps.AT_REVIEW && this.atHasFailed())) {
      this.processingService.setActiveStep(step);
    }
  }

  onStartProd(productionForm: ProductionForm['value']) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Start production?',
          details: 'Are you sure you want to start production process?',
        },
      })
      .onClose.pipe(filter((value) => value && this.currentBuildProcess))
      .subscribe(() => {
        const reconstructionSettings = { ...productionForm };
        delete reconstructionSettings.withObj;
        delete reconstructionSettings.withPointCloud;
        delete reconstructionSettings.withOrthoDSM;

        this.store.dispatch(
          startProduction({
            process: new BuildProcess({
              ...this.currentBuildProcess.toHttp(),
              reconstructionSettings: JSON.stringify(reconstructionSettings),
              aerotriangulationAccepted: true,
              buildProcessTypeId: 2,
              buildProcessStatusId: 4,
              productionAccepted: false,
              postProcessed: false,
              withObj: productionForm.withObj,
              withPointCloud: productionForm.withPointCloud,
              withOrthoDsm: productionForm.withOrthoDSM,
            }),
          }),
        );
      });
  }

  onStartAT(aerotriangulationSettings: ATSetupTabForm['value']) {
    let details = 'Are you sure you want to start aerotriangulation process?';

    if (
      aerotriangulationSettings.useGCP &&
      !this.atSetupControlPointsService.checkAllControlPointsAreValid()
    ) {
      details = 'There are some control points with not enough measurements amount. ' + details;
    }

    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Start aerotriangulation?',
          details,
        },
      })
      .onClose.pipe(filter((value) => value && this.currentBuildProcess))
      .subscribe(() => {
        this.store.dispatch(
          startAerotriangulation({
            process: new BuildProcess({
              ...this.currentBuildProcess.toHttp(),
              aerotriangulationSettings: JSON.stringify(aerotriangulationSettings),
              buildProcessTypeId: 1,
              buildProcessStatusId: 4,
              aerotriangulationAccepted: false,
              productionAccepted: false,
              postProcessed: false,
              runAerotriangulationAutomatically: true,
            }),
          }),
        );
      });
  }

  onStartPP(postProcessingSettings: PpSetupForm['value']) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title: 'Start post-processing?',
          details: 'Are you sure you want to start post-processing?',
        },
      })
      .onClose.pipe(filter((value) => value && this.currentBuildProcess))
      .subscribe(() => {
        this.store.dispatch(
          startPostProcessing({
            process: new BuildProcess({
              ...this.currentBuildProcess.toHttp(),
              postProcessingSettings: postProcessingSettings as PostProcessingSettings,
              buildProcessTypeId: 4,
              buildProcessStatusId: 4,
              productionAccepted: true,
              postProcessed: false,
            }),
          }),
        );
      });
  }
}
