import { BUILDER_TYPES } from '@App/app/entities/babylon/builder-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { CameraPosAndDir } from '@App/app/entities/viewer/camera-pos-and-dir.model';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import {
  AbstractMesh,
  ArcRotateCamera,
  Camera,
  Mesh,
  StandardMaterial,
  Tags,
  TransformNode,
  Vector3,
} from 'babylonjs';
import {
  LOD_BOXES_CONTAINER_NAME,
  LOD_BOX_SIZE_NAME,
  LOD_MESHES_CONFIG,
  SKETCH_MESHES_CUSTOM_SCALE,
  SKETCH_POINTER_NAME,
} from 'src/app/configs/babylon.config';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../../material-service/material.service';
import { SceneService } from '../../../scene-service/scene.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';

@Injectable()
export class SketchesUtilsService {
  pointer: Mesh;

  constructor(
    private _materialService: MaterialService,
    private _sceneService: SceneService,
    private _babylonNodesService: BabylonNodesService,
    private _broadcastService: BroadcastService,
    private _viewerLayerService: ViewerLayerService,
  ) {}

  createMesh(
    position: Vector3,
    root: TransformNode,
    name: string,
    visible: boolean = true,
    type: BUILDER_TYPES = BUILDER_TYPES.box,
  ): Mesh {
    const mesh =
      type === BUILDER_TYPES.box
        ? this._babylonNodesService.createBox(
            position.clone(),
            { size: LOD_MESHES_CONFIG.boxSizeStart },
            null,
            name,
          )
        : this._babylonNodesService.createBox(
            position.clone(),
            { size: LOD_MESHES_CONFIG.sizeStart },
            null,
            name,
          );

    mesh.material = this._materialService.electricBlueMaterial;
    mesh.renderingGroupId = 1;
    mesh.parent = root;
    mesh.isVisible = visible;
    mesh.isPickable = true;

    if (this._sceneService.scene.cameras[0].mode === Camera.ORTHOGRAPHIC_CAMERA) {
      mesh.scaling.x = this._sceneService.scene.__orthoprojectionCustomLODScale;
      mesh.scaling.y = this._sceneService.scene.__orthoprojectionCustomLODScale;
      mesh.scaling.z = this._sceneService.scene.__orthoprojectionCustomLODScale;
    }

    Tags.AddTagsTo(mesh, SKETCH_MESHES_CUSTOM_SCALE);
    this._initLODMeshes(mesh, this._materialService.electricBlueMaterial);
    return mesh;
  }

  setStandardMaterial(mesh: AbstractMesh) {
    mesh.material = this._materialService.electricBlueMaterial;
    mesh.getChildMeshes().forEach((childMesh) => {
      childMesh.material = this._materialService.electricBlueMaterial;
    });
  }

  changeMaterial(mesh: AbstractMesh, material: StandardMaterial) {
    mesh.material = material;
    mesh.getChildMeshes().forEach((childMesh) => {
      childMesh.material = material;
    });
  }

  addPointerEventListener(plane: { gridPlane: Mesh; fillingPlane: Mesh }): void {
    if (!this.pointer) {
      this.pointer = this._initializePointer();
    }
    const onPointerMoveSet = () => {
      this._onPointerMove(plane);
    };
    const canvas = document.querySelector('#renderCanvas');
    canvas?.removeEventListener('pointermove', onPointerMoveSet);
    canvas?.addEventListener('pointermove', onPointerMoveSet);
  }

  finishNewSketch(camera: ArcRotateCamera | null, tmpCamPos: CameraPosAndDir) {
    this._sceneService.scene.__orthoprojectionCustomLODScale = 1;
    this._sceneService.scene.__orthoprojectionCustomGizmoScale = 1;
    if (this._sceneService.scene.getMeshesByTags(SKETCH_MESHES_CUSTOM_SCALE)?.length) {
      this._sceneService.scene.getMeshesByTags(SKETCH_MESHES_CUSTOM_SCALE).forEach((box) => {
        box.scaling = new Vector3(1, 1, 1);
      });
    }
    this._broadcastService.broadcast(EVENT_TYPE.REMOVE_ACTIVE_LAYER_BUTTON, null);
    const { x, y, z } = tmpCamPos.position;
    camera?.setPosition(new Vector3(x, y, z));
    camera?.target.copyFrom(tmpCamPos.target);
    tmpCamPos.position = new Vector3();
    tmpCamPos.target = new Vector3();
    this._broadcastService.broadcast(EVENT_TYPE.CAMERA_MODE_BUTTON_STATUS, false);
  }

  deleteDraftSketch(sketch: LayerUI | null) {
    if (sketch) {
      this._viewerLayerService.initViewerLayer(sketch, true);
      this._viewerLayerService.deleteLayer(sketch.id);
    }
  }

  disposeDraftSketchNodesByTag(tag: string): void {
    const meshes: (TransformNode | null)[] = this._sceneService.scene.getTransformNodesByTags(tag);
    for (let i = 0; i < meshes.length; i++) {
      meshes[i]?.dispose();
      meshes[i] = null;
    }
  }

  disposeDraftSketchMeshesByTag(tag: string): void {
    const meshes: (Mesh | null)[] = this._sceneService.scene.getMeshesByTags(tag);
    for (let i = 0; i < meshes.length; i++) {
      meshes[i]?.dispose();
      meshes[i] = null;
    }
  }

  private _initLODMeshes(mesh: Mesh, material: StandardMaterial): void {
    const lodSpheres = new AbstractMesh(LOD_BOXES_CONTAINER_NAME, this._sceneService.scene);
    const arrayLODSpheres = this._createLODMeshes(material, lodSpheres);
    mesh.addChild(lodSpheres);
    arrayLODSpheres.forEach((sphere, index) => {
      mesh.addLODLevel(Math.pow(index, LOD_MESHES_CONFIG.LOD_POWER), sphere);
    });
  }

  private _createLODMeshes(material: StandardMaterial, parentMesh: AbstractMesh): Mesh[] {
    const sizeUnit = LOD_MESHES_CONFIG.sizeEnd / LOD_MESHES_CONFIG.boxesAmount;
    const { sizeStart: sizeStart, boxesAmount } = LOD_MESHES_CONFIG;
    return [...Array(boxesAmount)].map((_, i) => {
      const size = +(sizeStart + sizeUnit * i).toFixed(2);
      const mesh = this._babylonNodesService.createSphere(
        null,
        `${LOD_BOX_SIZE_NAME}${size}`,
        material,
        { diameter: size * 3 },
      );
      mesh.renderingGroupId = 2;
      mesh.parent = parentMesh;
      mesh.isPickable = false;
      return mesh;
    });
  }

  private _onPointerMove(plane: { gridPlane: Mesh; fillingPlane: Mesh }) {
    const pickInfo = this._sceneService.scene.pick(
      this._sceneService.scene.pointerX,
      this._sceneService.scene.pointerY,
      (mesh) => mesh.name === plane.fillingPlane.name || mesh.name === plane.gridPlane.name,
      false,
    );
    if (pickInfo?.hit && pickInfo.pickedPoint) {
      this.pointer.position = pickInfo.pickedPoint;
    }
  }

  private _initializePointer(): Mesh {
    const box = this._babylonNodesService.createBox(null, { size: 0.1 }, null, SKETCH_POINTER_NAME);
    box.isPickable = false;
    box.isVisible = false;
    return box;
  }
}
