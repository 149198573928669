import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { FAILED } from '../configs/toastr-events.config';
import { FAILED_TOASTR_CONFIG } from '../configs/toastr-messages.config';
import { Photo } from '../entities/files/files.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadPhotoService {
  constructor(private toastrService: NbToastrService) {}

  downloadPhoto(photo: Photo) {
    return fetch(photo.downloadURL)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = photo.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
      .catch(() => {
        this.toastrService.show('A problem occurred, please try again later.', FAILED, {
          ...FAILED_TOASTR_CONFIG,
        });
      });
  }
}
