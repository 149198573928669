import { DropLine } from '@App/app/entities/layer/drop-line.model';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Vector3 } from 'babylonjs';
import { addLayer } from '../../../../pages/viewer/store/actions/layers.actions';
import { UtilsService } from '../../utils-service/utils.service';
import { BasePlaneService } from '../base-plane-services/base-plane.service';

@Injectable({
  providedIn: 'root',
})
export class CreateDropLineService {
  constructor(
    private basePlaneService: BasePlaneService,
    private store: Store,
    private utilsService: UtilsService,
    private broadcastService: BroadcastService,
  ) {}

  onClickAction() {
    const hit = this.utilsService.pickRay();
    if (hit?.pickedMesh && hit.pickedPoint) {
      this.createDropLine(hit.pickedPoint.clone());
    }
  }

  createDropLine(position: Vector3) {
    const dropLine = new DropLine();
    const basePos = this.basePlaneService.getBasePlanesPosition();
    const absPos = new Vector3(position.x, basePos.y, position.z);

    dropLine.name = `drop line`;
    dropLine.isVisible = true;
    dropLine.isSaved = true;
    dropLine.data.position = { x: position.x, y: position.y, z: position.z };
    dropLine.data.length = this.utilsService.getLengthBetweenPoints(position, absPos);

    this.store.dispatch(addLayer({ layer: dropLine as LayerUI, commit: false }));
    this.broadcastService.broadcast(EVENT_TYPE.INIT_DROP_LINE, false);
  }
}
