import { User } from '@App/app/entities/users/user';
import { Injectable } from '@angular/core';
import { SitesService } from './sites.service';

@Injectable({
  providedIn: 'root',
})
export class UsersSitesService extends SitesService<User> {
  archiveMultiple(_: User[]): void {}

  deleteMultiple(_: User[]): void {}
}
