import { Pipe, PipeTransform } from '@angular/core';
import states from 'states-us';

@Pipe({
  name: 'modelStateAbbr',
})
export class ModelStateAbbrPipe implements PipeTransform {
  transform(name: string | null): any {
    if (name === null) {
      return '';
    }
    const state = states.find((item) => item.name === name) || null;
    return state?.abbreviation || name;
  }
}
