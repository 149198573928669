import { Component, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import { ControlPointMeasurement } from '../../models/control-point-measurement.model.';
import { ControlPoint } from '../../models/control-point.model';
import { AtSetupControlPointsService } from '../services/at-setup-control-points-service/at-setup-control-points.service';

@UntilDestroy()
@Component({
  selector: 'app-control-points-input',
  templateUrl: './control-points-input.component.html',
  styleUrls: ['./control-points-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlPointsInputComponent),
      multi: true,
    },
  ],
})
export class ControlPointsInputComponent implements OnDestroy, ControlValueAccessor {
  value: ControlPoint[];
  filteredMeasurements: ControlPointMeasurement[] = [];
  selectedControlPointName: Observable<string | null>;
  onChange: (controlPoints: ControlPoint[]) => void;

  constructor(private atSetupControlPointsService: AtSetupControlPointsService) {
    const controlPointsMeasurements = this.atSetupControlPointsService.selectedMeasurements$;
    this.selectedControlPointName = this.atSetupControlPointsService.activeControlPointName$;

    combineLatest([controlPointsMeasurements, this.selectedControlPointName])
      .pipe(untilDestroyed(this))
      .subscribe(([measurements, controlPointName]) => {
        this.filteredMeasurements = measurements.filter(
          (point) => point.controlPointName === controlPointName,
        );

        this.onChange?.(this.value);
      });
  }

  ngOnDestroy() {
    this.atSetupControlPointsService.setActiveControlPointName(null);
  }

  setActivePoint(name: string) {
    this.atSetupControlPointsService.setActiveControlPointName(name);
  }

  isControlPointsSettingValid() {
    return !(
      this._getForm().controls.controlPoints.touched &&
      !this._getForm().controls.controlPoints.valid
    );
  }

  deleteMeasurement(measurement: ControlPointMeasurement) {
    this.atSetupControlPointsService.deleteControlPointByImageId(measurement.imageId);
  }

  writeValue(value: ControlPoint[]): void {
    this.value = value;
  }

  registerOnChange(fn: (controlPoints: ControlPoint[]) => void) {
    this.onChange = fn;
  }

  registerOnTouched(_: () => void) {}

  private _getForm() {
    return this.atSetupControlPointsService.form;
  }
}
