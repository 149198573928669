/* eslint-disable max-len */
export enum ProdSetupGroupNames {
  PRODUCTION_SETTINGS = 'Production Settings',
  PRODUCTION_FORMATS = 'Production Formats',
}

export enum ProdSettingsDescription {
  COLOR_EQUALIZATION_MODE = 'Color equalization mode - While similar to the color equalization setting in AT setup, this option affects the texture reproduction. Blockwise is available if the blockwise color equalization mode was set during aerotriangulation setup. . None option disables color equalization. Standard option is the default advanced ContextCapture automatic color equalization the is performed on each tile independently. Standard including thermal option applies the standard color correction on the thermal images also. Blockwise option is a more advanced color equalization algorithm that is able deal with greater radiometric differences between images. Blockwise option including thermal will apply the blockwise color correction on the thermal photos also.',
  GEOMETRY_PRECISION_MODE = 'Geometry precision mode - During AT, a sparse cloud is calculated. During Reconstruction, the point cloud is refined and densified by calculating additional tie points between existing points. This setting sets the allowable tolerance between features in the input images to match them. Ultra precision sets a very low tolerance for the maximum number of pixels the reprojection error between corresponding features can be in order to be considered valid. It appears that this isn’t just a filter or a threshold but rather, changing this settings can cause the reconstruction algorithms to: 1) Calculate more or less tie points during densification 2) Increase the number of iterations performed to refine the position and orientation of the cameras and 3D points in order to minimize the reprojection errors to be within tolerance 3) Use more complex feature matching and descriptor matching algorithms.',
  HOLE_FILLING_MODE = 'Hole filling mode - Holes can appear in the mesh due to missing or insufficient data during reconstruction. Small Holes option targets small holes and avoids over-filling and altering the geometry of larger holes, which could lead to inaccuracies in the new mesh. All Holes option is more aggressive and targets all holes but may create aberrant geometry to fill holes with.',
  PAIR_SELECTION_MODE = 'Pair selection mode - Similar to the block type option in AT, this option helps the reconstruction make some assumptions about the data when matching pairs. Additional tie points are calculated during the reconstruction to densify the point cloud. Changing this setting optimizes the algorithms for the corresponding approach.',
  UNTEXTURED_REGIONS_REPRESENTATION = 'Untextured regions representation - Choose how untextured regions of the 3D reconstruction are handled.',
}
