import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { LayersService } from '@App/app/pages/viewer/services/layers/layers.service';
import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
  name: 'isLayerSelected',
  pure: false,
})
export class IsLayerSelectedPipe implements PipeTransform {
  private _layers: LayerUI[] = [];

  constructor(private _layersService: LayersService) {
    this._layersService.selectedLayers$.pipe(untilDestroyed(this)).subscribe((layers) => {
      this._layers = layers;
    });
  }

  transform(layer: LayerUI) {
    return this._layers.some((l) => l?.id === layer.id && l?.type === layer.type);
  }
}
