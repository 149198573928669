import { NO_LAYERS_SELECTED } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import {
  LAYER_EXPANDABLE_TYPES,
  LAYER_TYPES,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { Folder, FolderData } from '@App/app/entities/layer/folder.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { LayersByFolders } from '@App/app/entities/layer/layers-by-folders.model';
import { ElementRef, Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { LayersService } from '../../../services/layers/layers.service';
import { updateLayers } from '../../../store/actions/layers.actions';
import { getLayerParents } from '../../../utils/layers.utils';

@Injectable()
export class LayersListUtilsService {
  rootFolder$: Observable<LayerUiExpandable>;

  constructor(
    private _layersService: LayersService,
    private _toastrService: NbToastrService,
    private store: Store,
  ) {
    this.rootFolder$ = this._layersService.folders$.pipe(
      map((val) => val.find((folder) => (folder.data as FolderData).isRoot) as LayerUiExpandable),
    );
  }

  groupLayersByFolders(layers: LayerUI[], folders: LayerUiExpandable[]): LayersByFolders {
    const initialFolders: { [key: number]: LayerUI[] } = folders.reduce(
      (res, folder) => ({ ...res, [folder.id]: [] }),
      {},
    );

    return layers.reduce((acc, cur) => {
      if (!!acc[cur.parentId]) {
        acc[cur.parentId].push(cur);
      } else {
        acc[cur.parentId] = [cur];
      }
      return acc;
    }, initialFolders);
  }

  getLayerPath(layer: LayerUI | LayerUiExpandable) {
    const parents = this._layersService.getLayerParents(layer);
    return parents.length
      ? parents
          .reverse()
          .map((parent) => parent.name)
          .join('/')
      : 'root';
  }

  isFolder(layer: LayerUI | LayerUiExpandable) {
    return layer.type === LAYER_TYPES.FOLDERS;
  }

  isExpandableType(layer: LayerUI | LayerUiExpandable) {
    return Object.values(LAYER_EXPANDABLE_TYPES).some(
      (type) => ((type as unknown) as LAYER_TYPES) === layer.type,
    );
  }

  checkNewFolderIsAvailable(layer: LayerUI, destination: Folder, destinationParents: Folder[]) {
    return (
      this.isFolder(destination) &&
      layer.parentId !== destination.id &&
      layer.id !== destination.id &&
      !destinationParents.find((parent) => parent.id === layer.id)
    );
  }

  getFoldersOpenRawData(folders: Folder[]) {
    return folders.map((f) => ({ id: f.id, changes: { open: true } }));
  }

  showToast(info: string) {
    this._toastrService.show(info, NO_LAYERS_SELECTED, WARNING_TOASTR_CONFIG);
  }

  highlightLayerHTMLElement(layer: HTMLElement) {
    setTimeout(() => {
      layer.classList.add('--highlighted');
      layer.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => layer.classList.remove('--highlighted'), 750);
    }, 10);
  }

  findLayerElementByRefAndId(ref: ElementRef<HTMLElement>, id: number) {
    const layerElements = [...ref.nativeElement.querySelectorAll('.layer')];
    return layerElements.find((element) => element.id === `layer${id}`) as HTMLElement;
  }

  populateChildrenForEachFolder(
    folders: LayerUI[],
    children: { [id: number]: LayerUI[] },
    layersByFolders: { [id: number]: LayerUI[] },
  ) {
    for (const folder of folders) {
      const { isRoot } = folder.data as FolderData;
      const lookUp = isRoot ? [folder.id, null] : [folder.id];
      const subFolders = folders.filter((f) => lookUp.includes(f.parentId) && f.id !== folder.id);
      const subLayers = layersByFolders[folder.id];
      children[folder.id] = subFolders.concat(subLayers);
    }
    return children;
  }

  sortGroupedLayers(newLayers: LayersByFolders) {
    for (const folder in newLayers) {
      if (newLayers.hasOwnProperty(folder)) {
        newLayers[folder].sort((a, b) => a.name.localeCompare(b.name));
      }
    }
  }

  moveToNewParent(parentId: number, folders: LayerUI[], allItems: LayerUI[]) {
    this._layersService.selectedLayers$.pipe(first()).subscribe((selectedLayers) => {
      this._layersService.setLoading(true);
      const dst = folders.find((l) => l.id === parentId) as Folder;
      const destinationParents = getLayerParents(dst, folders);
      const idsToChange = selectedLayers
        .map((layer) => allItems.find((l) => layer.id === l.id))
        .filter(
          (layer) => !!layer && this.checkNewFolderIsAvailable(layer, dst, destinationParents),
        )
        .map((layer: LayerUI | LayerUiExpandable) => layer.id);
      if (idsToChange.length) {
        this._layersService.changeLayersFolder(idsToChange, parentId);
      } else {
        this._layersService.setLoading(false);
      }
      this.rootFolder$
        .pipe(first())
        .pipe(filter(({ id }) => id !== dst.id))
        .subscribe(() => {
          const foldersToUpdate = [dst, ...destinationParents];
          const updates = this.getFoldersOpenRawData(foldersToUpdate);
          this.store.dispatch(updateLayers({ updates }));
        });
    });
  }
}
