import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@App/app/auth/auth.service';
import { ApiService } from '@App/app/shared/api.service';
import { StartUploadDTO } from '../../models/start-upload-dto.model';
import { StartUploadResponse } from '../../models/start-upload-response.model';
import { UploadPendingStatusResponse } from '../../models/upload-pending-status-response.model';

@Injectable({ providedIn: 'root' })
export class UploaderHttpService extends ApiService {
  constructor(authService: AuthService, http: HttpClient) {
    super(authService, http);
  }

  getModels() {
    return this.http.get(`${this.api}/models`);
  }

  startUpload(dto: StartUploadDTO) {
    return this.http.post<StartUploadResponse>(`${this.api}/buildProcess/startUpload`, dto);
  }

  pauseUpload(buildProcessId: number) {
    return this.http.put<boolean>(`${this.api}/buildProcess/pauseStage/${buildProcessId}`, null);
  }

  cancelUpload(buildProcessId: number) {
    return this.http.put<boolean>(`${this.api}/buildProcess/cancelStage/${buildProcessId}`, null);
  }

  getPendingUploadStatus(id: number) {
    return this.http.get<UploadPendingStatusResponse>(
      `${this.api}/buildProcess/getPendingUploadForTower/${id}`,
    );
  }
}
