import { getVertexPositions } from '@App/app/engine/utils/babylon-utils/babylon.utils';
import { LAYER_NAMES } from '../../enums/layer-names.enum';
import { LAYER_TYPES } from '../../enums/layer-types.enum';
import { httpLayer } from '../../layer.model';
import { Coordinates3 } from '../../measurements/coordinates';
import { SketchBaseTool } from './sketch-base-tool.model';

export class SketchLine extends SketchBaseTool<SketchLineData> {
  name = LAYER_NAMES.SKETCH_LINE;
  type = LAYER_TYPES.SKETCH_LINES;
  data: SketchLineData;

  static createBodyForHttpRequest(sketchLine: SketchLine): httpLayer {
    return {
      name: sketchLine.name,
      isVisible: sketchLine.isVisible || false,
      type: sketchLine.type,
      parentId: sketchLine.parentId,
      data: {
        type: LAYER_TYPES.SKETCH_LINES,
        closed: sketchLine.data.closed,
        vertexPositions: sketchLine.pointsMeshes
          ? getVertexPositions(sketchLine.pointsMeshes)
          : sketchLine.data.vertexPositions,
      },
    };
  }

  static getInitialData(): SketchLineData {
    return {
      type: LAYER_TYPES.SKETCH_LINES,
      closed: false,
      vertexPositions: null,
    };
  }
}

export interface SketchLineData {
  closed: boolean;
  type: string;
  vertexPositions: Coordinates3[] | null;
}
