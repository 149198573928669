import { FilterableListForm } from '@App/app/entities/forms/filterable-list-form.model';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbCalendarRange } from '@nebular/theme';
import { FilterableListField } from './models/filterable-list-field.model';

@Component({
  selector: 'app-filterable-list',
  templateUrl: './filterable-list.component.html',
  styleUrls: ['./filterable-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterableListComponent<T extends { id: number | string }> {
  @Input() entities: T[] | null = null;
  @Input() selectedEntity: T | null = null;
  @Input() nameField: FilterableListField<T> | null = null;
  @Input() dateField: FilterableListField<T> | null = null;
  @Input() noEntitiesPlaceholder: string;
  @Output() changeEntity = new EventEmitter<T>();
  filterForm: FormGroup<FilterableListForm>;

  constructor(private fb: FormBuilder, private ref: ElementRef) {
    this.filterForm = this.fb.group<FilterableListForm>({
      name: new FormControl(null, { updateOn: 'change' }),
      dateRange: new FormControl(null, { updateOn: 'change' }),
    });
  }

  getLabel(entity: T) {
    const labelParts: string[] = [];
    if (this.nameField) {
      labelParts.push(this.getEntityName(entity));
    }
    if (this.dateField) {
      labelParts.push(this.getEntityDate(entity).toLocaleString());
    }
    return labelParts.join(', ');
  }

  getFilteredEntities() {
    let entities = [...(this.entities || [])];
    entities = this.filterByName(entities);
    entities = this.filterByDate(entities);
    return entities;
  }

  onEntityChange(entity: T) {
    if (entity.id !== this.selectedEntity?.id) {
      this.changeEntity.emit(entity);
    }
  }

  getEntityName(entity: T) {
    return (entity[this.nameField?.key as keyof T] as unknown) as string;
  }

  getEntityDate(entity: T) {
    return (entity[this.dateField?.key as keyof T] as unknown) as Date;
  }

  resetName() {
    this.filterForm.controls.name.reset();
    this.scrollToSelectedEntity();
  }

  resetDateRange() {
    this.filterForm.controls.dateRange.reset();
    this.scrollToSelectedEntity();
  }

  private filterByName(entities: T[]) {
    const { name } = this.filterForm.controls;
    return this.nameField && name.value
      ? entities.filter((entity) =>
          this.getEntityName(entity)
            .toLowerCase()
            .includes((name.value as string).toLowerCase()),
        )
      : entities;
  }

  private filterByDate(entities: T[]) {
    const { dateRange } = this.filterForm.controls;
    return this.dateField && dateRange.value
      ? entities.filter((entity) => {
          const entityDate = new Date(this.getEntityDate(entity));
          entityDate.setHours(0, 0, 0, 0);
          const { start, end } = dateRange.value as NbCalendarRange<Date>;
          const isValidWithStartDate = entityDate.getTime() >= start.getTime();
          const isValidWithEndDate = end ? entityDate.getTime() <= end.getTime() : true;
          return isValidWithStartDate && isValidWithEndDate;
        })
      : entities;
  }

  private scrollToSelectedEntity() {
    setTimeout(() => {
      const selected: HTMLElement | undefined = this.ref.nativeElement.querySelector('.--selected');
      selected?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }
}
