import { GizmoManager, LinesMesh, Mesh } from 'babylonjs';
import { LAYER_TYPES } from './enums/layer-types.enum';
import { Layer } from './layer.model';
import { Coordinates3 } from './measurements/coordinates';
import { NameTag } from './name-tag.model';

export class DropLine implements Layer<DropLineData> {
  id: number;
  name: string;
  createdAt: string;
  editedAt: string;
  data: DropLineData = {};
  parentId?: number;
  type = LAYER_TYPES.DROP_LINES;
  isVisible = false;
  isSaved = true;
  description: string;
}
export interface DropLineData {
  length?: number;
  position?: Coordinates3;
}

export interface ViewerDropLineLayer extends DropLine {
  sphere?: Mesh;
  line?: LinesMesh;
  gui?: NameTag;
  gizmoManager?: GizmoManager;
  data: DropLineData;
}
