import { NameTag } from '@App/app/entities/layer/name-tag.model';
import { Injectable } from '@angular/core';
import { AbstractMesh, Plane, PointerDragBehavior, Tools, Vector3 } from 'babylonjs';
import { Control, Rectangle, TextBlock } from 'babylonjs-gui';
import { BABYLON_COLORS as BC, SLICE_TOOL_CONFIG } from 'src/app/configs/babylon.config';
import { SceneService } from '../../scene-service/scene.service';
import { GuiUtilsService } from '../gui-utils-service/gui-utils.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateGUIService {
  constructor(private guiUtilsService: GuiUtilsService, private sceneService: SceneService) {}

  createHorizontalTopSliceTool(posY: number) {
    const clipPlane = new Plane(0, 1, 0, 0);
    const fillingPlane = this.guiUtilsService.createFillingColorPlane(
      clipPlane,
      SLICE_TOOL_CONFIG.TOP_SLICE.MAIN_COLOR,
    );
    const gridPlane = this.guiUtilsService.createSliceGridPlane(
      SLICE_TOOL_CONFIG.TOP_SLICE.NAME,
      SLICE_TOOL_CONFIG.TOP_SLICE.MAIN_COLOR,
    );
    const pointerDragBehavior = new PointerDragBehavior({
      dragAxis: new Vector3(0, 0, 1),
    });

    this.sceneService.scene.clipPlane2 = new Plane(0, 1, 0, -gridPlane.position.y);

    gridPlane.position.y = posY;
    fillingPlane.parent = gridPlane;
    gridPlane.onAfterWorldMatrixUpdateObservable.add((e) => {
      this.sceneService.scene.clipPlane2 = new Plane(0, 1, 0, -e.position.y);
    });
    gridPlane.addBehavior(pointerDragBehavior);

    return { fillingTopPlane: fillingPlane, gridTopPlane: gridPlane };
  }

  createHorizontalBottomSliceTool(posY: number) {
    const clipPlane = new Plane(0, 1, 0, 0);
    const fillingPlane = this.guiUtilsService.createFillingColorPlane(
      clipPlane,
      SLICE_TOOL_CONFIG.BOTTOM_SLICE.MAIN_COLOR,
    );
    const gridPlane = this.guiUtilsService.createSliceGridPlane(
      SLICE_TOOL_CONFIG.BOTTOM_SLICE.NAME,
      SLICE_TOOL_CONFIG.BOTTOM_SLICE.MAIN_COLOR,
    );
    const pointerDragBehavior = new PointerDragBehavior({
      dragAxis: new Vector3(0, 0, 1),
    });
    gridPlane.position.y = posY;

    this.sceneService.scene.clipPlane3 = new Plane(0, 1, 0, gridPlane.position.y);

    fillingPlane.parent = gridPlane;
    gridPlane.onAfterWorldMatrixUpdateObservable.add((e) => {
      this.sceneService.scene.clipPlane3 = new Plane(0, -1, 0, e.position.y);
    });
    gridPlane.addBehavior(pointerDragBehavior);

    return { fillingBottomPlane: fillingPlane, gridBottomPlane: gridPlane };
  }

  createNameTag(name: string, mesh: AbstractMesh, visible: boolean, layerId?: number): NameTag {
    // background
    const bg = new Rectangle(`${name}-bg`);
    bg.adaptWidthToChildren = true;
    bg.height = '25px';
    bg.color = BC.BLACK;
    bg.background = BC.BLACK;
    bg.clipChildren = false;
    bg.clipContent = false;
    this.sceneService.scene.__advancedTexture?.addControl(bg);
    bg.linkWithMesh(mesh);
    bg.isVisible = visible;
    bg.linkOffsetY = -30;
    bg.metadata = { layerId };

    // bottom triangle
    const arrow = new Rectangle(`${name}-arrow`);
    arrow.color = BC.BLACK;
    arrow.rotation = Tools.ToRadians(45);
    arrow.background = BC.BLACK;
    bg.addControl(arrow);
    arrow.isVisible = visible;
    arrow.width = '17.73px';
    arrow.height = '17.73px';
    arrow.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    arrow.top = '8.865px';
    arrow.metadata = { layerId };

    // text
    const text = new TextBlock(`${name}-text`, name);
    text.width = '100%';
    text.height = '100%';
    text.paddingLeftInPixels = 10;
    text.paddingRightInPixels = 10;
    text.color = BC.WHITE;
    text.resizeToFit = true;
    text.isVisible = visible;
    text.fontSize = 12;
    text.fontFamily = 'Roboto';
    text.metadata = { layerId };
    text.onPointerEnterObservable.add(() => (this.sceneService.scene.defaultCursor = 'pointer'));
    text.onPointerOutObservable.add(() => (this.sceneService.scene.defaultCursor = 'default'));

    bg.addControl(text);
    return { bg, arrow, text };
  }

  createLengthTag(mesh: AbstractMesh, value: number, visible: boolean) {
    const lengthText = new TextBlock('Length Tag');
    lengthText.text = `${value}m`;
    lengthText.isVisible = visible;
    this.textBlockSettings(lengthText, mesh);
    return lengthText;
  }

  createSquareAreaTag(mesh: AbstractMesh, value: number, visible: boolean) {
    const squareAreaText = new TextBlock('Square Area Tag');
    squareAreaText.text = `${value}m²`;
    squareAreaText.isVisible = visible;
    this.textBlockSettings(squareAreaText, mesh);

    return squareAreaText;
  }

  textBlockSettings(text: TextBlock, mesh: AbstractMesh) {
    text.color = 'white';
    text.fontFamily = 'Roboto';
    text.fontSize = 14;
    text.linkOffsetY = -25;
    this.sceneService.scene.__advancedTexture?.addControl(text);
    text.linkWithMesh(mesh);
  }
}
