<nb-card *ngIf="showTopBar" status="primary" class="top-card user-nav">
  <nb-card-body>
    <a
      nbButton
      routerLinkActive
      [routerLinkActiveOptions]="{ exact: true }"
      #rla1="routerLinkActive"
      [routerLink]="['/', companyId, 'user', 'profile']"
      [appearance]="rla1.isActive ? 'filled' : 'outline'"
      status="primary"
    >
      Profile
    </a>
    <a
      nbButton
      routerLinkActive
      [routerLinkActiveOptions]="{ exact: true }"
      #rla2="routerLinkActive"
      [routerLink]="['/', companyId, 'user', 'preferences']"
      [appearance]="rla2.isActive ? 'filled' : 'outline'"
      status="primary"
    >
      Preferences
    </a>
  </nb-card-body>
</nb-card>
<router-outlet></router-outlet>
