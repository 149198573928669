import { ApiService } from '@App/app/shared/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProcessingPresetsHttpService extends ApiService {
  getBuildProcessPresets() {
    return this.http.get(`${this.api}/processing-presets`);
  }
}
