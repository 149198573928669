import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isLayerDeletable',
})
export class IsLayerDeletablePipe implements PipeTransform {
  transform(layer: LayerUI) {
    return layer.type !== LAYER_TYPES.BASE_PLANE;
  }
}
