import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.scss'],
})
export class InputEmailComponent implements OnInit {
  @Input() controlName: string;
  @Input() error: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() prefixIcon: string | null = null;

  constructor() {}

  ngOnInit(): void {}
}
