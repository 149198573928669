import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { RoiLimit } from '../../models/roi-limits.model';
import * as actions from '../actions/limits.actions';

export const limitsAdapter = createEntityAdapter<RoiLimit>();

export interface State extends EntityState<RoiLimit> {
  currentLimit: RoiLimit | null;
}

const defaultRoiLimit: State = {
  ids: [],
  entities: {},
  currentLimit: null,
};

export const initialState: State = limitsAdapter.getInitialState(defaultRoiLimit);

export const limitsReducer = createReducer(
  initialState,
  on(actions.loadRoiLimitsSuccess, (state, { limits }) => {
    return limitsAdapter.setAll(limits, { ...state });
  }),
  on(actions.deleteRoiLimitSuccess, (state, { id }) => {
    return limitsAdapter.removeOne(id, state);
  }),
  on(actions.addRoiLimitSuccess, (state, { limit }) => {
    return limitsAdapter.addOne(limit, { ...state });
  }),
  on(actions.updateRoiLimit, (state, { limit }) => {
    return limitsAdapter.setOne(limit, { ...state });
  }),
);

export const getCurrentLimit = (state: State) => state.currentLimit;
