/* eslint-disable max-lines */
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { UserTableFilterForm } from '@App/app/entities/forms/users-table-filter-form.model';
import { NgxTableEvent } from '@App/app/entities/shared/ngx-table-event.model';
import { Page } from '@App/app/entities/shared/page.model';
import { User } from '@App/app/entities/users/user';
import { updateByQuery } from '@App/app/pages/sites/shared/utils';
import { BaseTableComponent } from '@App/app/shared/components/base-table/base-table.component';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { DatatableComponent, TableColumnProp } from '@swimlane/ngx-datatable';
import { NgxPermissionsService } from 'ngx-permissions';
import { first, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AdvancedSearchUsers } from '../../../../entities/shared/advanced-search.model';
import { RoleName } from '../../../../entities/users/user';
import { loadAllUsers } from '../../../users/store/actions/users.actions';
import { selectAllUsers, selectUsers } from '../../../users/store/selectors/users.selectors';
import { UsersSitesService } from '../../shared/site-services/users-sites.service';
import { UsersTableFiltersService } from '../users-table-filters/users-table-filters.service';
import getColumns from './helpers/users-columns';
import { UsersService } from './services/users.service';

@UntilDestroy()
@Component({
  selector: 'app-users',
  templateUrl: './users-table.component.html',
  styleUrls: [
    './users-table.component.scss',
    '../../../../shared/components/base-table/base-table.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UsersTableComponent
  extends BaseTableComponent<
    User,
    UserTableFilterForm,
    UsersSitesService,
    AdvancedSearchUsers,
    UsersTableFiltersService
  >
  implements OnInit {
  @ViewChild('rolesTpl', { static: true }) rolesTpl: TemplateRef<any>;
  @ViewChild('actionsTpl', { static: true }) actionsTpl: TemplateRef<any>;
  Role = RoleName;
  page: Page = {
    size: 8,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };

  constructor(
    sitesService: UsersSitesService,
    authService: AuthService,
    store: Store,
    router: Router,
    userFiltersService: UsersTableFiltersService,
    cdr: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    private usersService: UsersService,
  ) {
    super(authService, router, store, cdr, sitesService, userFiltersService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initUsers();
    if (this.sitesService) {
      this.store
        .select(selectAllUsers)
        .pipe(untilDestroyed(this), withLatestFrom(this.sitesService.showArchived$))
        .subscribe(([users, isArchived]) => {
          if (users.length) {
            this.setEntities(users.filter((user) => user.isDisabled === isArchived));
          }
        });
    }

    this.sitesService?.showArchived$.pipe(untilDestroyed(this)).subscribe((showArchived) => {
      this.store
        .select(selectUsers(showArchived))
        .pipe(first())
        .subscribe((users) => this.setEntities(users));
    });
  }

  editUser(user: User) {
    this.usersService.redirectEditUser(user.id);
  }

  disableUserHandler(user: User) {
    if (user.isDisabled) {
      this.usersService.enableUser(user.id);
      return;
    }
    this.usersService.disableUser(user.id);
  }

  onSelectRow(_: NgxTableEvent<User>) {}

  private initUsers() {
    this.loading = true;
    this.store.dispatch(loadAllUsers());
  }

  private getPageInfo(table: DatatableComponent) {
    return {
      count: this.rows.length,
      pageSize: table.pageSize,
      limit: table.limit as number,
      offset: table.offset,
    };
  }

  protected initColumns(): void {
    const columns = getColumns(this.rolesTpl, this.actionsTpl);
    const [commonColumns, editColumn] = [columns.slice(0, -1), columns[columns.length - 1]];
    const canEditUser = !!this.permissionsService.getPermission(UserPermissions.CAN_EDIT_USER);
    this.columns = canEditUser ? [...commonColumns, editColumn] : [...commonColumns];
  }

  protected onEndpointChange(_: Endpoint): void {
    this.store.dispatch(loadAllUsers());
  }

  protected updateFilters(advancedSearch: AdvancedSearchUsers) {
    let temp = [...this.temp] as User[];
    temp = updateByQuery(
      temp,
      advancedSearch.query,
      this.columns.map((column) => column.prop as TableColumnProp),
    ) as User[];
    if (this.filtersService) {
      temp = this.filtersService.filterRoleType(temp, advancedSearch.role);
    }
    this.rows = temp;
    if (this.table) {
      this.table.offset = 0;
      this.setPage(this.getPageInfo(this.table));
    }
    this.loading = false;
  }

  protected setEntities(users: User[]) {
    const currentUserId = this.authService.getUser().id;
    const filteredUsers = users.filter((user) => user.id !== currentUserId);
    this.temp = [...filteredUsers];
    this.rows = filteredUsers;
    if (this.table) {
      this.filtersService?.$advancedSearch.pipe(take(1)).subscribe((advancedSearch) => {
        this.updateFilters(advancedSearch);
      });
      this.setPage(this.getPageInfo(this.table));
    }
  }
}
