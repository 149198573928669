import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { PageRangeInfo } from '@App/app/entities/shared/page-info.model';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteLayoutComponent {
  @Input() filtersTpl: TemplateRef<any>;
  @Input() leftControlsTpl: TemplateRef<any>;
  @Input() rightControlsTpl: TemplateRef<any>;
  @Input() contentTpl: TemplateRef<any>;
  @Input() companyId: number | null;
  @Input() pageRangeInfo: PageRangeInfo | null = null;
  @Input() adjustContentHeight = false;
  nbLayoutActive = false;
  permissions = UserPermissions;

  constructor(private permissionsService: NgxPermissionsService) {}

  hasPermission(permission: UserPermissions) {
    return !!this.permissionsService.getPermission(permission);
  }
}
