import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PresetsHttpService } from '../../services/presets-http/presets-http.service';
import { deletePreset, deletePresetFailure, deletePresetSuccess } from '../actions/presets.actions';

@Injectable()
export class DeletePresetEffects {
  constructor(private actions: Actions, private presetsHttpService: PresetsHttpService) {}

  deletePreset = createEffect(() => {
    return this.actions.pipe(
      ofType(deletePreset),
      mergeMap(({ id }) => {
        return this.presetsHttpService.deletePreset(id).pipe(
          map(() => {
            return deletePresetSuccess({ id });
          }),
          catchError(() => of(deletePresetFailure())),
        );
      }),
    );
  });
}
