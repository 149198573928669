import { MODEL_TYPES } from '../entities/models/model-types.enum';

export const ROI_LIMITS_BY_TYPE: {
  [key in typeof MODEL_TYPES[keyof typeof MODEL_TYPES]]: [number, number, number];
} = {
  [MODEL_TYPES.CELL_TOWER]: [300, 300, 300],
  [MODEL_TYPES.BRIDGE]: [300, 300, 300],
  [MODEL_TYPES.ROADWAY]: [300, 300, 300],
  [MODEL_TYPES.PIPELINE]: [300, 300, 300],
  [MODEL_TYPES.BUILDING]: [300, 300, 300],
  [MODEL_TYPES.ATCT]: [300, 300, 300],
  [MODEL_TYPES.POWER_LINE]: [300, 300, 300],
  [MODEL_TYPES.GENERIC]: [300, 300, 300],
};
