export enum EVENT_TYPE {
  // general
  LOAD_MODEL = 'LOAD_MODEL',
  MODEL_MAX_HEIGHT = 'MODEL_MAX_HEIGHT',
  CHECK_PROGRESS = 'CHECK_PROGRESS',
  SET_MARKER = 'SET_MARKER',
  RESET_CAMERA = 'RESET_CAMERA',
  CHANGE_CAMERA = 'CHANGE_CAMERA',
  CAMERA_SNAP = 'CAMERA_SNAP',
  CAMERA_MODE_BUTTON_STATUS = 'CAMERA_MODE_BUTTON_STATUS',

  // layer tools
  TAKE_BASE_PLANE = 'TAKE_BASE_PLANE',
  TAKE_SKETCH_PLANE = 'TAKE_SKETCH_PLANE',
  FINISH_SKETCH_PLANE = 'FINISH_SKETCH_PLANE',
  SHOW_SLICE_PLANES = 'SHOW_SLICE_PLANES',
  DISABLED_SLICE_PLANES = 'DISABLED_SLICE_PLANES',
  REMOVE_ACTIVE_LAYER_BUTTON = 'REMOVE_ACTIVE_LAYER_BUTTON',

  // layer tools details
  INIT_CUBOID_PICKER = 'INIT_CUBOID_PICKER',
  INIT_TUBE_PICKER = 'INIT_TUBE_PICKER',
  INIT_DROP_LINE = 'INIT_DROP_LINE',
  INIT_COORDINATES = 'INIT_COORDINATES',
  INIT_MEASUREMENT = 'INIT_MEASUREMENT',
  INIT_AREA_POLYGON = 'INIT_AREA_POLYGON',

  // sketch tools
  SAVE_SKETCH = 'SAVE_SKETCH',
  ACTIVATE_SAVE_BUTTON = 'ACTIVATE_SAVE_BUTTON',
  TAKE_SKETCH_LINE = 'TAKE_SKETCH_LINE',
  FINISH_SKETCH_LINES = 'FINISH_SKETCH_LINES',
  TAKE_SKETCH_CIRCLE = 'TAKE_SKETCH_CIRCLE',
  TAKE_SKETCH_RECTANGLE = 'TAKE_SKETCH_RECTANGLE',
  ADD_SKETCH = 'ADD_SKETCH',
  REFRESH_CUSTOM_LOD = 'REFRESH_CUSTOM_LOD',
  EDIT_SKETCHES = 'EDIT_SKETCHES',
  SKETCH_PLANE_TO_UPDATE = 'SKETCH_PLANE_TO_UPDATE',
  MESH_RESTORE_DEFAULT = 'MESH_RESTORE_DEFAULT',

  // edit mode
  EDIT_MODE_CHANGED = 'EDIT_MODE_CHANGED',

  CHANGE_BOUNDING_BOX_BUTTON_STATUS = 'CHANGE_BOUNDING_BOX_BUTTON_STATUS',
  INIT_ANNOTATION = 'INIT_ANNOTATION',

  // layers updates
  UPDATE_BASE_PLANE = 'UPDATE_BASE_PLANE',
  UPDATE_FOLDERS = 'UPDATE_FOLDERS',

  // key pressed
  SPACE_PRESSED = 'SPACE_PRESSED',
}
