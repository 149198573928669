import { AuthData } from '@App/app/entities/auth/auth-data.model';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { createReducer, on } from '@ngrx/store';
import {
  ACCESS_TOKEN,
  CURRENT_ENDPOINT,
  ENDPOINTS_DATA,
  ID_TOKEN,
  REFRESH_TOKEN,
  USER_DATA,
} from 'src/app/configs/auth.config';
import { AuthUser } from '../../../../entities/users/user';
import * as actions from '../actions/auth.actions';

export const initialState: AuthData = {
  user: JSON.parse(localStorage.getItem(USER_DATA) as string) as AuthUser,
  endpoints: JSON.parse(localStorage.getItem(ENDPOINTS_DATA) as string) as Endpoint[],
  currentEndpoint: JSON.parse(localStorage.getItem(CURRENT_ENDPOINT) as string) as Endpoint,
  tokens: {
    idToken: localStorage.getItem(ID_TOKEN) as string,
    accessToken: localStorage.getItem(ACCESS_TOKEN) as string,
    refreshToken: localStorage.getItem(REFRESH_TOKEN) as string,
  },
};

export const authReducer = createReducer(
  initialState,
  on(
    actions.loadAuthDataSuccess,
    (_, { authData }): AuthData => {
      return { ...authData };
    },
  ),
  on(
    actions.removeAuthDataSuccess,
    (_): AuthData => {
      return { ...initialState };
    },
  ),
  on(
    actions.changeCurrentEndpointSuccess,
    (state, { endpoint }): AuthData => {
      return { ...state, currentEndpoint: endpoint };
    },
  ),
  on(
    actions.refreshTokensSuccess,
    (state, { tokens }): AuthData => {
      return {
        ...state,
        tokens: {
          idToken: tokens.idToken,
          accessToken: tokens.accessToken,
          refreshToken: state.tokens.refreshToken,
        },
      };
    },
  ),
  on(
    actions.updateUserUiSettingsLocally,
    (state, { uiSettings }): AuthData => {
      const user = { ...state.user, uiSettings };
      return { ...state, user };
    },
  ),
);

export const getAuthData = (state: AuthData) => state;

export const getUser = (state: AuthData) => state.user;

export const getTokens = (state: AuthData) => state.tokens;

export const getIdToken = (state: AuthData) => state.tokens.idToken;

export const getEndpoints = (state: AuthData) => state.endpoints;

export const getCurrentEndpoint = (state: AuthData) => state.currentEndpoint;

export const getUiSettings = (state: AuthData) => state.user?.uiSettings;

export const checkHasDocsAccepted = (state: AuthData) =>
  state.user?.uiSettings?.hasDocsAccepted || false;
