import { Options, SortableEvent } from 'sortablejs';

export const getOptions = (
  onStart: (event: SortableEvent) => void,
  onEnd: (event: SortableEvent) => void,
): Options => ({
  delay: 20,
  sort: false,
  filter: '.layers__static',
  forceFallback: true,
  onStart,
  onEnd,
});
