<app-site-layout
  [filtersTpl]="filtersTpl"
  [leftControlsTpl]="leftControlsTpl"
  [rightControlsTpl]="rightControlsTpl"
  [contentTpl]="contentTpl"
  [companyId]="companyId"
  [pageRangeInfo]="page | asPageRangeInfo"
></app-site-layout>

<ng-template #filtersTpl>
  <app-users-table-filters class="filters_component"></app-users-table-filters>
</ng-template>

<ng-template #leftControlsTpl>
  <ng-container *ngxPermissionsOnly="permissions.CAN_ADD_USER">
    <button
      nbButton
      size="tiny"
      status="primary"
      [routerLink]="['/', companyId, 'add-user']"
      class="sites-nav__button"
    >
      <nb-icon icon="person-add-outline"></nb-icon>
      Add User
    </button>
  </ng-container>
</ng-template>

<ng-template #rightControlsTpl>
  <div *ngIf="hasPermission(permissions.CAN_ACCESS_USER_LIST)" class="sites-nav__mode">
    <nb-select [selected]="showArchived" size="tiny" (selectedChange)="changeArchiveSelect($event)">
      <nb-option [value]="true">Disabled</nb-option>
      <nb-option [value]="false">Active</nb-option>
    </nb-select>
  </div>
</ng-template>

<ng-template #contentTpl>
  <app-users></app-users>
</ng-template>
