import { GizmoManager, Mesh } from 'babylonjs';
import { AnnotationPriorities } from '../shared/annotation-priorities.enum';
import { LAYER_TYPES } from './enums/layer-types.enum';
import { Layer } from './layer.model';
import { Coordinates3, Coordinates4 } from './measurements/coordinates';
import { NameTag } from './name-tag.model';

export class Annotation implements Layer<AnnotationData> {
  id: number;
  name: string = LAYER_TYPES.ANNOTATION;
  createdAt: string;
  editedAt: string;
  data: AnnotationData = {};
  parentId: number;
  type = LAYER_TYPES.ANNOTATION;
  isVisible = false;
  isSaved = true;
  description: string;
}
export interface AnnotationData {
  absoluteRotationQuaternion?: Coordinates4;
  min?: Coordinates3;
  max?: Coordinates3;
  priority?: AnnotationPriorities;
}

export interface ViewerAnnotationLayer extends Annotation {
  mesh: Mesh;
  gui?: NameTag;
  gizmoManager?: GizmoManager;
  data: AnnotationData;
}
