import { SketchPlane } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { Injectable } from '@angular/core';
import { FloatArray, Mesh, Plane, TransformNode, Vector3, VertexData } from 'babylonjs';
import {
  FILLING_COLOR_SKETCH_PLANE_NAME,
  GRID_SKETCH_PLANE_NAME,
  SKETCH_PLANES_SIZE,
  SKETCH_POLYGON_NAME,
} from 'src/app/configs/babylon.config';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../material-service/material.service';
import { SceneService } from '../../scene-service/scene.service';

@Injectable()
export class SketchPlaneUtilsService {
  constructor(
    private babylonNodesService: BabylonNodesService,
    private materialService: MaterialService,
    private sceneService: SceneService,
  ) {}

  private createFillingPlane(id: number | string): Mesh {
    const fillingPlane = this.babylonNodesService.createPlane(
      `${FILLING_COLOR_SKETCH_PLANE_NAME}${id}`,
      { size: SKETCH_PLANES_SIZE },
      this.materialService.fillingPlaneMaterial,
    );

    fillingPlane.isPickable = false;
    return fillingPlane;
  }

  private createGridPlane(id: number | string): Mesh {
    const plane = this.babylonNodesService.createPlane(
      `${GRID_SKETCH_PLANE_NAME}${id}`,
      { size: SKETCH_PLANES_SIZE },
      this.materialService.sketchGridMaterial,
    );

    plane.isVisible = true;
    plane.isPickable = false;
    return plane;
  }

  moveSketchPlane(sketchPlane: SketchPlane, root: TransformNode): SketchPlane {
    if (sketchPlane.triangleMesh) {
      sketchPlane.triangleMesh.dispose();
      sketchPlane.triangleMesh = null;
    }
    const { mesh, normal } = this.initSketchPolygon(
      sketchPlane.pointsMeshes || [],
      root,
      true,
      true,
    );
    sketchPlane.triangleMesh = mesh;
    sketchPlane.normal = normal;
    return sketchPlane;
  }

  initSketchPolygon(
    meshes: Mesh[],
    root: TransformNode,
    visible: boolean,
    isLoadedFromDB?: boolean,
  ): { mesh: Mesh; normal: Vector3 } {
    const sketchPolygonMesh = new Mesh(SKETCH_POLYGON_NAME, this.sceneService.scene);
    const indices = [0, 1, 2];
    const normals: FloatArray = [];
    const sum = [];
    const vertexData = new VertexData();

    const positions = meshes.reduce((acc, mesh) => {
      return [
        ...acc,
        isLoadedFromDB ? mesh.position.x : mesh.absolutePosition.x,
        isLoadedFromDB ? mesh.position.y : mesh.absolutePosition.y,
        isLoadedFromDB ? mesh.position.z : mesh.absolutePosition.z,
      ];
    }, []);
    VertexData.ComputeNormals(positions, indices, normals);

    vertexData.indices = indices;
    vertexData.positions = positions;
    vertexData.normals = normals;
    vertexData.applyToMesh(sketchPolygonMesh);

    for (let i = 0; i < vertexData.normals.length / 3; i++) {
      sum.push(vertexData.normals[i] + vertexData.normals[i + 3]);
    }

    const normal = new Plane(sum[0], sum[1], sum[2], 0).normal;

    sketchPolygonMesh.material = this.materialService.electricBlueMaterial;
    sketchPolygonMesh.visibility = 0.7;
    sketchPolygonMesh.alphaIndex = 2;
    sketchPolygonMesh.isVisible = visible;
    sketchPolygonMesh.parent = root;

    return { mesh: sketchPolygonMesh, normal };
  }

  recomputeRootSpace(sketchPlane: SketchPlane, root: TransformNode, visible: boolean = true) {
    const { pointsMeshes } = sketchPlane;
    const { position } = root;
    const mid = new Vector3(0, 0, 0);

    pointsMeshes?.forEach((e) => {
      mid.x += e.position.x;
      mid.y += e.position.y;
      mid.z += e.position.z;
    });

    if (pointsMeshes?.length) {
      mid.x /= pointsMeshes.length;
      mid.y /= pointsMeshes.length;
      mid.z /= pointsMeshes.length;
    }

    position.x += mid.x;
    position.y += mid.y;
    position.z += mid.z;

    pointsMeshes?.forEach((e) => {
      e.position.x -= mid.x;
      e.position.y -= mid.y;
      e.position.z -= mid.z;
    });

    const { normal, triangleMesh } = this.moveSketchPlane(sketchPlane, root);
    if (triangleMesh) {
      triangleMesh.isVisible = visible;
    }

    for (const prop in sketchPlane.plane) {
      if (sketchPlane.plane.hasOwnProperty(prop)) {
        (sketchPlane.plane as any)[prop].setDirection(normal);
      }
    }
  }

  createPlane(
    id: number | string,
  ): {
    fillingPlane: Mesh;
    gridPlane: Mesh;
  } {
    return {
      fillingPlane: this.createFillingPlane(id),
      gridPlane: this.createGridPlane(id),
    };
  }
}
