import { SKETCH_PLANE_NAME, SKETCH_PLANE_SPHERE_NAME } from '@App/app/configs/babylon.config';
import { BUILDER_TYPES } from '@App/app/entities/babylon/builder-types.enum';
import { SAVE_SKETCH_TYPE } from '@App/app/entities/layer/sketch-tools/save-sketch-types.enum';
import {
  SketchPlane,
  ViewerSketchPlaneLayer,
} from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractMesh, PickingInfo, TransformNode, Vector3 } from 'babylonjs';
import { addLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { GizmoManagerService } from '../../gizmo-manager/gizmo-manager.service';
import { SceneService } from '../../scene-service/scene.service';
import { UtilsService } from '../../utils-service/utils.service';
import { SketchesUtilsService } from './shared/sketches-utils.service';
import { SketchPlaneGizmoService } from './sketch-plane-gizmo.service';
import { SketchPlaneUtilsService } from './sketch-plane-utils.service';

@Injectable()
export class SketchPlaneService extends GizmoManagerService {
  private draftSketchPlane: SketchPlane | null;
  private root: TransformNode | null;
  private sphereId = 0;

  constructor(
    private sketchPlaneGizmoService: SketchPlaneGizmoService,
    private sketchPlaneUtilsService: SketchPlaneUtilsService,
    private sketchesUtilsService: SketchesUtilsService,
    private store: Store,
    private utilsService: UtilsService,
    broadcastService: BroadcastService,
    sceneService: SceneService,
  ) {
    super(sceneService, broadcastService);

    this.broadcastService.on(EVENT_TYPE.TAKE_SKETCH_PLANE).subscribe((val: boolean) => {
      if (
        val &&
        this.draftSketchPlane?.pointsMeshes &&
        this.draftSketchPlane?.pointsMeshes?.length > 2
      ) {
        this.initLocalSketchPlane();
      } else {
        this.removeDraftSketchPlane();
      }
      this.sphereId = 0;
    });

    this.broadcastService.on(SAVE_SKETCH_TYPE.PLANE).subscribe(() => this.saveDraftSketchPlane());
  }

  onClickAction() {
    if (this.draftSketchPlane?.pointsMeshes?.length === 3) {
      return;
    }

    const hit = this.utilsService.pickRay();

    if (hit && hit.pickedPoint) {
      if (!this.root) {
        this.root = new AbstractMesh(`newSketchPlane`, this.sceneService.scene);
        this.draftSketchPlane = new SketchPlane(this.sceneService.scene);
        this.sphereId = 0;
      }

      this.initSphereOnClick(hit);
    }
  }

  showSketchPlane(sketchPlane: SketchPlane): ViewerSketchPlaneLayer {
    const newSketchPlane = sketchPlane as ViewerSketchPlaneLayer;
    this.sceneService.scene.getNodeByName(`${SKETCH_PLANE_NAME}${newSketchPlane.id}`)?.dispose();
    const root = new AbstractMesh(
      `${SKETCH_PLANE_NAME}${newSketchPlane.id}`,
      this.sceneService.scene,
    );
    root.id = `${newSketchPlane.id}`;
    this.sphereId = 0;

    if (newSketchPlane.data.vertexPositions) {
      newSketchPlane.pointsMeshes = newSketchPlane.data.vertexPositions.map((vertexPos) => {
        return this.createSphere(
          new Vector3(vertexPos.x, vertexPos.y, vertexPos.z),
          root,
          true,
          true,
        );
      });
    }
    const { mesh, normal } = this.sketchPlaneUtilsService.initSketchPolygon(
      newSketchPlane.pointsMeshes,
      root,
      true,
      true,
    );
    newSketchPlane.triangleMesh = mesh;
    newSketchPlane.normal = normal;
    newSketchPlane.plane = this.initPlane(newSketchPlane as SketchPlane, true, root);
    newSketchPlane.gizmoManager = this.createGizmoManager();
    this.sketchPlaneUtilsService.recomputeRootSpace(newSketchPlane as SketchPlane, root, true);
    this.setGizmoManager(newSketchPlane.id, newSketchPlane.gizmoManager);
    return newSketchPlane;
  }

  removeDraftSketchPlane(): void {
    if (this.draftSketchPlane) {
      SketchPlane.removeAllNodes(this.draftSketchPlane);
      if (this.root) {
        this.root.dispose();
      }
      this.draftSketchPlane = null;
      this.root = null;
    }
  }

  private saveDraftSketchPlane() {
    if (this.draftSketchPlane) {
      const sketchPlaneToSend = SketchPlane.createSketchPlaneForHttpRequest(this.draftSketchPlane);
      this.removeDraftSketchPlane();
      this.store.dispatch(addLayer({ layer: sketchPlaneToSend }));
    }
  }

  private initLocalSketchPlane() {
    if (this.draftSketchPlane?.pointsMeshes && this.root) {
      const { mesh, normal } = this.sketchPlaneUtilsService.initSketchPolygon(
        this.draftSketchPlane.pointsMeshes,
        this.root,
        true,
      );

      this.draftSketchPlane.triangleMesh = mesh;
      this.draftSketchPlane.normal = normal;
      this.draftSketchPlane.plane = this.initPlane(this.draftSketchPlane, true, this.root);
      this.sketchPlaneGizmoService.initGizmo(this.draftSketchPlane, this.root);
      this.draftSketchPlane.gizmoManager?.attachToMesh(this.draftSketchPlane.triangleMesh);
      this.broadcastService.broadcast(EVENT_TYPE.ACTIVATE_SAVE_BUTTON, SAVE_SKETCH_TYPE.PLANE);
    }
  }

  private initPlane({ normal, id }: SketchPlane, visible: boolean, root: TransformNode) {
    const plane = this.sketchPlaneUtilsService.createPlane(id);

    for (const prop in plane) {
      if (plane.hasOwnProperty(prop)) {
        (plane as any)[prop].setDirection(normal);
        (plane as any)[prop].parent = root;
        (plane as any)[prop].isVisible = visible;
      }
    }

    return plane;
  }

  private initSphereOnClick(hit: PickingInfo) {
    if (hit.pickedPoint && this.draftSketchPlane?.pointsMeshes && this.root) {
      const pointMesh = this.createSphere(hit.pickedPoint, this.root, true, false);
      this.draftSketchPlane.pointsMeshes = [...this.draftSketchPlane.pointsMeshes, pointMesh];
      if (this.draftSketchPlane.pointsMeshes.length === 3) {
        this.broadcastService.broadcast(EVENT_TYPE.FINISH_SKETCH_PLANE, true);
      }
    }
  }

  private createSphere(
    position: Vector3,
    root: TransformNode,
    visible: boolean,
    isLoadedFromDB: boolean,
  ) {
    const sphere = this.sketchesUtilsService.createMesh(
      position,
      root,
      `${SKETCH_PLANE_SPHERE_NAME}${++this.sphereId}`,
      visible,
      BUILDER_TYPES.sphere,
    );

    if (isLoadedFromDB) {
      sphere.position.x = position.x - root.position.x;
      sphere.position.y = position.y - root.position.y;
      sphere.position.z = position.z - root.position.z;
    }

    return sphere;
  }
}
