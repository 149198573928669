<div
  *ngIf="activePhotoId && activePhotoIndex !== 0"
  class="carousel_arrow carousel_arrow--left"
  data-testId="prev-photo"
  (click)="prev()"
>
  <nb-icon icon="arrow-ios-back-outline"></nb-icon>
</div>

<div *ngIf="!photos.length" class="photos_info">
  Click on the model to show photos containing the selected point
</div>

<nb-list #carousel class="photos_carousel" data-testId="carousel">
  <nb-list-item *ngFor="let photo of photos">
    <app-photos-carousel-image
      class="photos_carousel__image"
      [id]="photo.id"
      [photo]="photo"
      [activePhotoId]="activePhotoId"
      [downloadingPhotoIds]="downloadingPhotoIds"
      (selectPhoto)="selectPhoto($event)"
      (download)="download($event)"
      (closePhoto)="closePhoto($event)"
      (openPhoto)="openPhoto($event)"
      data-testId="carousel-photo"
    ></app-photos-carousel-image>
  </nb-list-item>
</nb-list>

<div
  *ngIf="activePhotoId && activePhotoIndex !== photos.length - 1"
  class="carousel_arrow carousel_arrow--right"
  data-testId="next-photo"
  (click)="next()"
>
  <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
</div>
