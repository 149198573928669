import { ModelSiteHeaderModule } from '@App/app/shared/components/model-site-header/model-site-header.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NebularModule } from '../../modules';
import { SharedModule } from '../../shared/shared.module';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { UserFormComponent } from './user-form/user-form.component';

@NgModule({
  declarations: [AddUserComponent, UserFormComponent, EditUserComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgxDatatableModule,
    NebularModule,
    ModelSiteHeaderModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
  ],
})
export class UsersModule {}
