<nb-icon class="search__icon" icon="search-outline"></nb-icon>
<input
  data-testId="search-input"
  class="search"
  type="text"
  [value]="value || ''"
  [placeholder]="placeholder"
  (change)="onChangeEvent($event)"
  (keyup)="onKeyUpEvent($event)"
/>

<button
  *ngIf="showClearButton"
  class="search__filter --clear-button"
  nbButton
  size="small"
  status="info"
  type="button"
  (click)="onResetFilters()"
  nbTooltip="Clear all filters"
>
  <nb-icon icon="close-outline"></nb-icon>
</button>

<button
  class="search__filter"
  nbButton
  size="small"
  status="info"
  (click)="togglePopover()"
  type="button"
  [ngClass]="{ '--with-clear-button': showClearButton }"
>
  <nb-icon icon="funnel-outline"></nb-icon>
  Filter
</button>
