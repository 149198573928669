import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { passwordRegExpPattern } from 'src/app/shared/password-pattern';
import { AuthBaseComponent } from '../auth-base-component';
import { AuthService } from '../auth.service';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-confirm-password-recovery',
  templateUrl: './confirm-password-recovery.component.html',
  styleUrls: ['./confirm-password-recovery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmPasswordRecoveryComponent extends AuthBaseComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: TemplateRef<any>;

  passwordResetForm = this.fb.group({
    email: this.fb.control('', { validators: [Validators.required] }),
    password: this.fb.control('', {
      validators: [Validators.required, Validators.pattern(passwordRegExpPattern)],
    }),
    code: this.fb.control('', { validators: [Validators.required] }),
  });
  error: string;
  loading = false;
  formValueSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private dialogService: NbDialogService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.onChanges(this.passwordResetForm);
  }

  onSubmit(form: FormGroup) {
    this.loading = true;
    this.authService
      .confirmResetPassword({
        email: form.value.email,
        confirmationCode: form.value.code,
        newPassword: form.value.password,
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.dialogService.open(this.dialog, { closeOnBackdropClick: false });
          this.loading = false;
        },
        (error) => {
          this.error = error.error.message;
          this.loading = false;
        },
      );
  }

  finishPasswordReset(ref: NbDialogRef<any>) {
    ref.close();
    this.router.navigate(['/login']);
  }
}
