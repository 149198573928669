/* eslint-disable max-lines */
import { BezierCurveEase, Color3, Color4, Mesh, Plane, Vector3 } from 'babylonjs';
import * as Meshes from '../entities/babylon/nodes';

export const MAIN_LIGHT_NAME = 'mainLight';

// (0 0 -1) points the north
export const NORTH_VECTOR = new Vector3(0, 0, -1);
export const HORIZON_VECTOR = new Vector3(1, 0, 0);

export const ELECTRIC_BLUE_COLOR = '#56CCFF';
export const ELECTRIC_BLUE_COLOR3 = Color3.FromHexString(ELECTRIC_BLUE_COLOR);
export const BASE_MATERIAL_NAME = 'baseMaterial';
export const ELECTRIC_BLUE_MATERIAL_NAME = 'electricBlueMaterial';
export const RED_MATERIAL_NAME = 'redMaterial';
export const GREEN_LIGHT_MATERIAL_NAME = 'greenLightMaterial';
export const RED_LIGHT_MATERIAL_NAME = 'redLightMaterial';
export const YELLOW_LIGHT_MATERIAL_NAME = 'yellowLightMaterial';
export const DARK_BLUE_MATERIAL_NAME = 'darkBlueMaterial';
export const DRAG_MESH_MATERIAL_NAME = 'dragMeshMaterial';
export const BLACK_COLOR = '#000000';
export const WHITE_COLOR = '#ffffff';
export const DRAG_MESH_COLOR = '#ff4f00';
export const GREY_COLOR = '#aaa8ab';

// model
export const MODEL_NAME = 'model';
export const MODEL_MESH_NAME = 'modelMesh';

export const MODEL_AT_NAME = 'modelAT';

// babylon gui
export const FILLING_COLOR_PLANE_NAME = 'fillingColorPlane';
export const FILLING_PLANE_MATERIAL_NAME = 'fillingPlaneMaterial';
export const KNOT_MATERIAL_NAME = 'knotMaterial';

// basePlane config
export const BASE_PLANE_NAME = 'basePlane';
export const DIRECTIONAL_LIGHT_NAME = 'directionalLight';

// measurements config
export const MEASUREMENT_NAME = 'measurement';
export const NEW_MEASUREMENT_NAME = 'newMeasurement';
export const MIDDLE_SPHERE_NAME = 'middleSphere';

// cuboidObject config
export const CUBOID_OBJECT_NAME = 'cuboid';
export const CUBOID_PICKER_NAME = 'cuboidPicker';

// annotation config
export const ANNOTATION_NAME = 'annotation';

// rectTubeObject config
export const RECT_TUBE_OBJECT_NAME = 'rectTube';

// polygon config
export const POLYGON_MESH_BUILDER_NAME = 'polygonMeshBuilder';
export const CENTER_SPHERES_CONTAINER_NAME = 'Center Spheres';
export const PICKER_SPHERES_CONTAINER_NAME = 'Picker Spheres';
export const POLYGON_PICKER_SPHERE_NAME = 'pickerSphere';
export const PICKER_SPHERE_MATERIAL_NAME = 'pickerSphereMaterial';
export const NEW_POLYGON_NAME = 'newPolygon';
export const POLYGON_NAME = 'Polygon';
/**
 *   centerSphereName used for creating
 *   description in the polygon center
 */
export const CENTER_SPHERE_NAME = 'centerSphere';

// sketchPlanes config
export const SKETCH_PLANE_NAME = 'sketchPlane';
export const SKETCH_POLYGON_NAME = 'sketchPolygon';
export const SKETCH_PLANE_SPHERE_NAME = 'sketchPlaneSphere';
export const SKETCH_POLYGON_SPHERE_MAT_NAME = 'sketchPolygonSphereMat';
export const SKETCH_POLYGON_MAT_NAME = 'sketchPolygonMat';
export const SKETCH_PLANE_GRID_MAT_NAME = 'sketchPlaneGridMat';
export const FILLING_PLANE_MAT_NAME = 'fillingPlaneMat';
export const FILLING_COLOR_SKETCH_PLANE_NAME = 'fillingColorSketchPlane';
export const GRID_SKETCH_PLANE_NAME = 'gridSketchPlane';
export const SKETCH_PLANES_SIZE = 40;

// common sketch configs
export const LOD_BOX_SIZE_NAME = 'lodMeshSize_';
export const LOD_BOXES_CONTAINER_NAME = 'lodMeshes';
export const SKETCH_MESHES_CUSTOM_SCALE = 'sketchMeshesCustomScale';
export const UPWARD_VECTOR = new Vector3(0, 1, 0);
export const DOWNWARD_VECTOR = new Vector3(0, -1, 0);
export const SKETCH_MESHES_MATERIAL_NAME = 'sketchMeshesMat';
export const SKETCH_DRAG_MESHES_MATERIAL_NAME = 'sketchDragMeshesMat';
export const SKETCH_POINTER_NAME = 'sketchPointer';

// sketchLines config
export const DRAFT_SKETCH_LINE_TAG_NAME = 'draftSketchLineTag';
export const SKETCH_LINE_BOX_TAG_NAME = 'sketchLineBoxTagName';
export const NEW_SKETCH_LINE_NAME = 'newSketchLine';
export const SKETCH_LINE_NAME = 'sketchLine';
export const SKETCH_LINE_BOX_NAME = 'sketchLineBox';
export const SKETCH_LINE_MESH_NAME = 'lineMesh';
export const SKETCH_LINE_POINTER_NAME = 'sketchLinePointer';

// sketchRectangle config
export const DRAFT_SKETCH_RECTANGLE_TAG_NAME = 'draftSketchLineTag';
export const NEW_SKETCH_RECTANGLE_NAME = 'newSketchRectangle';
export const SKETCH_RECTANGLE_NAME = 'sketchRectangle';
export const SKETCH_RECTANGLE_BOX_NAME = 'sketchRectangleBox';
export const SKETCH_RECTANGLE_POINTER_NAME = 'sketchRectanglePointer';

// sketchCircle config
export const NEW_SKETCH_CIRCLE_NAME = 'newSketchCircle';
export const DRAFT_SKETCH_CIRCLE_TAG_NAME = 'draftSketchCircleTag';
export const SKETCH_CIRCLE_MESH_NAME = 'circleMesh';
export const SKETCH_CIRCLE_CENTER_MESH_NAME = 'centerMesh';
export const SKETCH_CIRCLE_EDGE_MESH_NAME = 'edgeMesh';
export const SKETCH_CIRCLE_MESHES_TAG_NAME = 'sketchCircleMeshesTagName';
export const VECTOR_LENGTH = 99; // picking/finding the plane meters range

export const CAMERA_MESH_NAME = 'cameraPointMesh';
export const DECAL_NAME = 'decal';

// TODO: Get rid of UPPER_BETA_LIMIT magic numbers
export const ARC_ROTATE_CAMERA_CONFIG = {
  INERTIA: 0.9,
  WHEEL_PRECISION: 40,
  USE_BOUNCING_BEHAVIOR: false,
  ANGULAR_SENSIBILITY_X: 1300,
  ANGULAR_SENSIBILITY_Y: 1300,
  LOWER_BETA_LIMIT: 0,
  UPPER_BETA_LIMIT: Math.PI,
  LOWER_RADIUS_LIMIT: 0.1,
  UPPER_RADIUS_LIMIT: 1000,
  INERTIAL_PANNING_X: 1,
  INERTIAL_PANNING_Y: 1,
};

export const ArcRotateCameraAnimationConfig = {
  SPEED: 65,
  ADDITIONAL_HEIGHT: 20,
  FRAME_COUNT: 50,
  EASE: new BezierCurveEase(0.59, 0.58, 0.68, 0.69),
  LATERAL_LEFT: {
    name: 'DataDonutMenuW',
    camEndPos: new Vector3(-85, 15, 0),
    targetEndPos: new Vector3(5, 15, 0),
  },
  LATERAL_RIGHT: {
    name: 'DataDonutMenuE',
    camEndPos: new Vector3(85, 15, 0),
    targetEndPos: new Vector3(-5, 15, 0),
  },
  BACK: {
    name: 'DataDonutMenuN',
    camEndPos: new Vector3(0, 15, 85),
    targetEndPos: new Vector3(0, 15, -5),
  },
  FRONT: {
    name: 'DataDonutMenuS',
    camEndPos: new Vector3(0, 15, -85),
    targetEndPos: new Vector3(5, 15, 5),
  },
  TOP: {
    name: 'Nadir',
    camEndPos: new Vector3(0, 85, 0),
    targetEndPos: new Vector3(0, -5, 0),
  },
};

export const CAMERAS_CONFIG = {
  perspectiveMinZ: 0.001, // determines how much camera can zoom to object/mesh. It's gonna ba 0.1 if lower than 0
  orthoGraphicMinZ: 20,
  perspectiveGizmoScaleRatio: 1,
  orthoGraphicGizmoScaleRatio: 0.3,
};

export const ORTHOPROJECTION_CONFIG = {
  scrollSpeed: 0.51, // determines how many units(meters) move per one scroll
  LODScale: {
    min: 1,
    max: 0.15,
  },
  gizmoScale: {
    min: 0.8,
    max: 0.005,
  },
  startingYPosition: 30,
  maxZoom: 20,
  //I was tweaking values till get proper result:
  // to zoom as close as possible to the plane, but not over zoom it
  camStartingPosition: 13,
};

export const ORTHOGRAPHIC_CAMERA_SPECS = {
  bottom: -12.5,
  left: -32,
  right: 32,
  top: 12.5,
};

export const SLIDER_OPTIONS = {
  MAX_VALUE: 100,
  MIN_VALUE: 1,
  DEFAULT_SPEED_LEVEL: 25,
  REDUCING_SPEED_LEVEL: 65,
};

export const VIEWFINDER_SLIDER_OPTIONS = {
  MIN_VALUE: 1,
  MAX_VALUE: 5,
};

export const SLICE_TOOL_CONFIG = {
  CLIP_PLANE_NORMAL: new Plane(0, 1, 0, 0).normal,
  GRID_RATIO: 4,
  MAJOR_UNIT_FREQUENCY: 8,
  MINOR_UNIT_VISIBILITY: 0.9,
  GRID_OPACITY: 0.99,
  MAIN_COLOR_OPACITY: 0.6,

  TOP_SLICE: {
    NAME: 'TopSlice',
    MATERIAL_NAME: 'TopSliceMat',
    MAIN_COLOR: new Color3(0.55, 0.96, 0.55),
    LINE_COLOR: new Color3(0.55, 0.96, 0.55),
    POSITION_Y: 40,
  },
  BOTTOM_SLICE: {
    NAME: 'BottomSlice',
    MATERIAL_NAME: 'BottomSliceMat',
    MAIN_COLOR: new Color3(0.96, 0.55, 0.55),
    LINE_COLOR: new Color3(0.96, 0.55, 0.55),
    POSITION_Y: -3,
  },
};

export const BOTTOM_SLICE_TOOL_CONFIG = {
  NAME: 'BottomSlice',
};

// How many tiles will be loaded on default (without changing input)
export const DEFAULT_TILE_LOAD = {
  DEFAULT_TILE_LOAD: [0, 1],
};

export const ENGINE_BACKGROUND = {
  color: new Color4(0.99, 0.99, 0.99, 1),
};

export const MESH: {
  sphere: Meshes.Sphere;
  tube: Meshes.Tube;
  box: Meshes.Box;
  line: Meshes.Line;
  plane: Meshes.Plane;
  cylinder: Meshes.Cylinder;
} = {
  sphere: {
    options: {
      diameter: 0.001,
      segments: 4,
    },
    renderGroupId: 3,
    lodName: 'lodSize',
    lodContainerName: 'lodSpheres',
    defaultName: 'picker_sphere',
  },
  tube: {
    options: {
      sideOrientation: Mesh.DOUBLESIDE,
      updatable: true,
    },
    defaultName: 'tube',
    dragMeshName: 'tube_draggable',
    outerMeshName: 'tube_outer',
    innerMeshName: 'tube_inner',
  },
  box: {
    options: {
      updatable: true,
    },
    defaultName: 'picker_box',
  },
  line: {
    options: {
      updatable: false,
      instance: undefined,
    },
    defaultName: 'lineMesh',
  },
  plane: {
    options: {
      size: 1,
    },
    defaultName: 'planeMesh',
  },
  cylinder: {
    options: {
      diameterTop: 1,
      diameterBottom: 1,
      height: 2,
      tessellation: 24,
    },
    defaultName: 'cylinderMesh',
  },
};

export const MEASUREMENT = {
  lineColor: BABYLON.Color3.FromHexString('#56CCFF'),
  activeLineColor: BABYLON.Color3.FromHexString('#56CCFF'),
  lineRenderGroupId: 2,

  spheresMaterial: BABYLON.Color3.FromHexString('#56CCFF'),
  spheresOptions: {
    diameter: 0.001,
    segments: 4,
  },
  sphereRenderGroupId: 3,

  imageUrl: '../assets/img/red-ball.png',
  imageWidth: '5px',
  imageHeight: '5px',
};

const SPHERE_CONFIG = {
  color: BABYLON.Color3.FromHexString('#56CCFF'),
  sphereOptions: {
    diameter: 0.01,
    segments: 4,
  },
  sphereRenderGroupId: 3,
  lodName: 'lodSize',
  lodContainerName: 'lodSpheres',
};

export const DROP_LINE = {
  ...SPHERE_CONFIG,
  lineRenderGroupId: 2,
  name: 'dropLineSphere',
  lineName: 'dropLineLine',
};

export const CUBOID_MAPPING = {
  name: 'cuboid',
};

export const COORDINATES_LAYER = {
  ...SPHERE_CONFIG,
  name: 'coordinatesLayerSphere',
};

export const MEASUREMENT_PRECISION = {
  precision: 0.1,
};

export const SCENE_CONFIG = {
  editMode: true,
};

export const MEASUREMENT_SPHERES_NAMES = {
  MIDDLE_SPHERE_NAME: 'middle_sphere',
  PICKER_SPHERE_NAME: 'picker_sphere',
  LOD_SPHERE_NAME: 'sphere_lod_',
  LOD_SPHERES_NAME: 'lod_spheres',
  LINE_NAME: 'measurement_line',
};

export const LOD_SPHERES_CONFIG = {
  diameterStart: 0.01,
  diameterEnd: 0.1,
  spheresAmount: 12,
  segments: 10,
  LOD_POWER: 1.1,
};

export const LOD_MESHES_CONFIG = {
  boxSizeStart: 0.3,
  sizeStart: 0.01,
  sizeEnd: 0.05,
  boxesAmount: 12,
  LOD_POWER: 1.1,
};

export const CAMERA_MESH_VERTEX = [
  [0.05, -0.05, 0.05],
  [-0.05, -0.05, 0.05],
  [-0.05, 0.05, 0.05],
  [0.05, 0.05, 0.05],
  [0.25, -0.25, -0.25],
  [-0.25, -0.25, -0.25],
  [-0.25, 0.25, -0.25],
  [0.25, 0.25, -0.25],
];

export const CAMERA_MESH_FACE = [
  [0, 1, 2, 3],
  [4, 7, 6, 5],
  [1, 5, 6, 2],
  [0, 3, 7, 4],
  [0, 4, 5, 1],
  [3, 2, 6, 7],
];

export const BABYLON_COLORS = {
  BLACK: 'Black',
  WHITE: 'White',
};
