import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICompany } from '../entities/admin-panel/company.model';
import { AuthResponse } from '../entities/auth/auth-response.model';
import { LoginData } from '../entities/auth/login-data.model';
import { ResetPassword } from '../entities/auth/reset-password.model';
import { RefreshedTokens } from '../entities/auth/tokens.model';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private http: HttpClient) {}

  login(url: string, data: LoginData): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(url, data);
  }

  refreshTokens(endpointPath: string, refreshToken: string): Observable<RefreshedTokens> {
    return this.http.post<RefreshedTokens>(`${endpointPath}/auth/refreshToken`, {
      refreshToken,
    });
  }

  logout(endpointPath: string, accessToken: string) {
    return this.http.post<string>(`${endpointPath}/auth/logout`, {
      accessToken,
    });
  }

  resetPassword(url: string, email: string) {
    return this.http.post<any>(url, { email });
  }

  changePassword(
    endpointPath: string,
    accessToken: string,
    oldPassword: string,
    newPassword: string,
  ) {
    return this.http.post<any>(`${endpointPath}/auth/changePassword`, {
      accessToken,
      oldPassword,
      newPassword,
    });
  }

  confirmResetPassword(url: string, resetPasswordData: ResetPassword) {
    return this.http.post<any>(url, resetPasswordData);
  }

  getCompanies(url: string) {
    return this.http.get<ICompany[]>(`${url}/companies`);
  }
}
