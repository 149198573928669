import { Photo } from '@App/app/entities/files/files.model';
import { Injectable } from '@angular/core';
import { IAlbum } from 'ngx-lightbox';
import { ControlPointMeasurement } from '../../../tabs/at-setup-tab/models/control-point-measurement.model.';

@Injectable({
  providedIn: 'root',
})
export class ProcessingPhotosGridService {
  calculateMarkerPosition(activePoint: ControlPointMeasurement) {
    const lightboxWidth = (document.getElementById('outerContainer') as HTMLElement).offsetWidth;
    const lightboxHeight =
      (document.getElementById('outerContainer') as HTMLElement).offsetHeight || 1;
    const imgHTML = document.getElementById('image') as HTMLImageElement;
    const originalImgWidth = imgHTML.naturalWidth;
    const originalImgHeight = imgHTML.naturalHeight;
    return [
      (lightboxWidth * activePoint.pos.x) / originalImgWidth,
      (lightboxHeight * activePoint.pos.y) / originalImgHeight,
    ];
  }

  calculateClickedPosFromEventAndContainer(event: MouseEvent, container: HTMLElement) {
    const { offsetLeft, offsetTop, offsetHeight: outerContainerOffsetHeight } = container;
    const {
      naturalWidth,
      naturalHeight,
      offsetWidth,
      offsetHeight,
    } = event.target as HTMLImageElement;
    const widthScale = (offsetWidth * 100) / naturalWidth;
    const heightScale = (offsetHeight * 100) / naturalHeight;
    const x = event.pageX - offsetLeft;
    const y = event.pageY - offsetTop - (window.innerHeight - outerContainerOffsetHeight) / 2;
    const controlPointX = (x * 100) / widthScale;
    const controlPointY = (y * 100) / heightScale;
    return [controlPointX, controlPointY];
  }

  getMarkerStyleByItsPosition(x: number, y: number) {
    return `
      position: absolute;
      display: block;
      font-size: 130px;
      width: 20px;
      height: 20px;
      content: '';
      z-index: 1;
      border: solid 4px rgb(245, 104, 17);
      border-radius: 100%;
      left: ${x - 12}px;
      top: ${y - 12}px;
    `;
  }

  getLightboxAlbums(photos: Photo[]): IAlbum[] {
    return photos
      .map((photo) => ({
        src: photo.downloadURL,
        thumb: photo.data.thumbnailURL,
        caption: photo.name,
      }))
      .filter(
        ({ src, thumb, caption }) =>
          src !== undefined && thumb !== undefined && caption !== undefined,
      );
  }
}
