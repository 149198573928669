export enum ProgressSegmentStatus {
  'IN PROGRESS' = 1,
  'PAUSED',
  'FAILED',
  'FINISHED',
  'CANCELED',
  'WARNING',
}

export enum ProgressSegmentStatusIcon {
  'IN PROGRESS' = '',
  'PAUSED' = 'pause-circle-outline',
  'FAILED' = 'alert-triangle-outline',
  'FINISHED' = 'checkmark',
  'WARNING' = 'question-mark',
  'CANCELED' = 'alert-triangle-outline',
}
