import { LAYER_NAMES } from '../entities/layer/enums/layer-names.enum';
import { EVENT_TYPE } from '../entities/shared/event-types.enum';

export const TOOLS_ORDER = [
  LAYER_NAMES.MEASUREMENT,
  LAYER_NAMES.AREA_POLYGON,
  LAYER_NAMES.DROP_LINE,
  LAYER_NAMES.SLICE_PLANE,
  LAYER_NAMES.SKETCH_PLANE,
  LAYER_NAMES.COORDINATES_LAYER,
  LAYER_NAMES.TUBE_PICKER,
  LAYER_NAMES.TUBE,
  LAYER_NAMES.CUBOID_PICKER,
  LAYER_NAMES.CUBOID,
  LAYER_NAMES.ANGLE_IRON,
  LAYER_NAMES.RECT_TUBE,
  LAYER_NAMES.ANNOTATION,
];

export const EDIT_INIT_TOOLS = [
  EVENT_TYPE.INIT_ANNOTATION,
  EVENT_TYPE.INIT_TUBE_PICKER,
  EVENT_TYPE.INIT_DROP_LINE,
  EVENT_TYPE.INIT_COORDINATES,
  EVENT_TYPE.INIT_MEASUREMENT,
];
