import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NbDialogModule, NbToastrModule } from '@nebular/theme';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgHttpCachingModule } from 'ng-http-caching';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AutofocusFixModule } from 'ngx-autofocus-fix';
import { ColorPickerModule } from 'ngx-color-picker';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompanySelectionComponent } from './auth/company-selection/company-selection.component';
import { ConfirmPasswordRecoveryComponent } from './auth/confirm-password-recovery/confirm-password-recovery.component';
import { DocumentsAcceptanceComponent } from './auth/documents-acceptance/documents-acceptance.component';
import { LoginComponent } from './auth/login/login.component';
import { PasswordRecoveryComponent } from './auth/password-recovery/password-recovery.component';
import { CompanySelectionRowComponent } from './auth/shared/company-selection-row/company-selection-row.component';
import { InputEmailComponent } from './auth/shared/input-email/input-email.component';
import { InputPasswordComponent } from './auth/shared/input-password/input-password.component';
import { HTTP_CACHING_CONFIG } from './configs/app.config';
import { NebularModule } from './modules';
import { ModelsModule } from './pages/models/models.module';
import { ProcessingModule } from './pages/processing/processing.module';
import { SitesModule } from './pages/sites/sites.module';
import { ChangePasswordComponent } from './pages/user/profile/change-password/change-password.component';
import { ProfileInfoComponent } from './pages/user/profile/profile-info/profile-info.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { UserComponent } from './pages/user/user.component';
import { UsersModule } from './pages/users/users.module';
import { ViewerModule } from './pages/viewer/viewer.module';
import { ModelSiteHeaderModule } from './shared/components/model-site-header/model-site-header.module';
import { GlobalErrorHandler } from './shared/interceptors/global-error-handler';
import { HttpErrorInterceptor } from './shared/interceptors/http-error-interceptor.interceptor';
import { SonyHttpErrorInterceptor } from './shared/interceptors/sony-http-error.interceptor';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { FilterByNamePipe } from './shared/pipes/filter-by-name.pipe';
import { NetworkService } from './shared/services/network/network.service';
import { SharedModule } from './shared/shared.module';
import { metaReducers, rootReducer } from './store/index';
import { CompanySelectionDropDownComponent } from './auth/company-selection-drop-down/company-selection-drop-down.component';

@NgModule({
  declarations: [
    AppComponent,
    CompanySelectionComponent,
    ConfirmPasswordRecoveryComponent,
    DocumentsAcceptanceComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    PasswordRecoveryComponent,
    ProfileComponent,
    UserComponent,
    InputEmailComponent,
    InputPasswordComponent,
    ChangePasswordComponent,
    ProfileInfoComponent,
    CompanySelectionRowComponent,
    FilterByNamePipe,
    CompanySelectionDropDownComponent,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    AutofocusFixModule.forRoot(),
    BrowserAnimationsModule,
    NgHttpCachingModule.forRoot(HTTP_CACHING_CONFIG),
    BrowserModule,
    EffectsModule.forRoot([]),
    FormsModule,
    HttpClientModule,
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NebularModule,
    NgxDatatableModule,
    PdfViewerModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forRoot(rootReducer, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    NgxPermissionsModule.forRoot(),
    ModelsModule,
    ProcessingModule,
    ModelSiteHeaderModule,
    ViewerModule,
    UsersModule,
    SitesModule,
    NgxSpinnerModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
    ColorPickerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SonyHttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private _injector: Injector) {
    this._injector.get(NetworkService);
  }
}
