import { NebularModule } from '@App/app/modules';
import { SharedModule } from '@App/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LayerDetailsDescriptionComponent } from './layer-details-description/layer-details-description.component';
import { LayerDetailsGeolocationComponent } from './layer-details-geolocation/layer-details-geolocation.component';
import { LayerDetailsOptionalMeasurementFieldComponent } from './layer-details-optional-measurement-field/layer-details-optional-measurement-field.component';
import { LayerDetailsPositionComponent } from './layer-details-position/layer-details-position.component';
import { AngleIronPropertiesComponent } from './layer-details-properties/components/angle-iron-properties/angle-iron-properties.component';
import { AngleIronCenterPipe } from './layer-details-properties/components/angle-iron-properties/pipes/angle-iron-center/angle-iron-center.pipe';
import { AngleIronDimensionsPipe } from './layer-details-properties/components/angle-iron-properties/pipes/angle-iron-dimensions/angle-iron-dimensions.pipe';
import { AnnotationPropertiesComponent } from './layer-details-properties/components/annotation-properties/annotation-properties.component';
import { AreaPolygonPropertiesComponent } from './layer-details-properties/components/area-polygon-properties/area-polygon-properties.component';
import { BasePlanePropertiesComponent } from './layer-details-properties/components/base-plane-properties/base-plane-properties.component';
import { CoordinatePropertiesComponent } from './layer-details-properties/components/coordinate-properties/coordinate-properties.component';
import { CuboidPropertiesComponent } from './layer-details-properties/components/cuboid-properties/cuboid-properties.component';
import { DropLinePropertiesComponent } from './layer-details-properties/components/drop-line-properties/drop-line-properties.component';
import { MeasurementPropertiesComponent } from './layer-details-properties/components/measurement-properties/measurement-properties.component';
import { RectCenterPipe } from './layer-details-properties/components/rect-layer-properties/pipes/rect-center/rect-center.pipe';
import { RectDimensionsPipe } from './layer-details-properties/components/rect-layer-properties/pipes/rect-dimensions/rect-dimensions.pipe';
import { RectTubePropertiesComponent } from './layer-details-properties/components/rect-tube-properties/rect-tube-properties.component';
import { IsLayerDeleteActivePipe } from './layer-details-properties/components/sketch-plane-properties/pipes/is-layer-delete-active/is-layer-delete-active.pipe';
import { SketchPlanePropertiesComponent } from './layer-details-properties/components/sketch-plane-properties/sketch-plane-properties.component';
import { TubePropertiesComponent } from './layer-details-properties/components/tube-properties/tube-properties.component';
import { LayerDetailsPropertiesComponent } from './layer-details-properties/layer-details-properties.component';
import { LayerDetailsRecalculatePropsComponent } from './layer-details-recalculate-props/layer-details-recalculate-props.component';
import { IsRecalculateActivePipe } from './layer-details-recalculate-props/pipes/is-recalculate-active/is-recalculate-active.pipe';
import { LayerDetailsThicknessFieldComponent } from './layer-details-thickness-field/layer-details-thickness-field.component';
import { LayerDetailsComponent } from './layer-details.component';
import { LayerThicknessInputComponent } from './layer-thickness-input/layer-thickness-input.component';
import { IsLayerBeingEditedPipe } from './pipes/is-layer-being-edited/is-layer-being-edited.pipe';
import { IsLayerDeletablePipe } from './pipes/is-layer-deletable/is-layer-deletable.pipe';
import { IsLayerRectTypePipe } from './pipes/is-layer-rect-type/is-layer-rect-type.pipe';
import { LayerDetailsService } from './services/layer-details-service/layer-details.service';
import { LayerDetailsUtilsService } from './services/layer-details-utils-service/layer-details-utils.service';

@NgModule({
  declarations: [
    LayerDetailsComponent,
    LayerDetailsPropertiesComponent,
    AngleIronPropertiesComponent,
    DropLinePropertiesComponent,
    MeasurementPropertiesComponent,
    AreaPolygonPropertiesComponent,
    SketchPlanePropertiesComponent,
    CoordinatePropertiesComponent,
    BasePlanePropertiesComponent,
    TubePropertiesComponent,
    CuboidPropertiesComponent,
    AnnotationPropertiesComponent,
    RectTubePropertiesComponent,
    LayerDetailsDescriptionComponent,
    IsLayerBeingEditedPipe,
    LayerDetailsOptionalMeasurementFieldComponent,
    LayerDetailsThicknessFieldComponent,
    RectDimensionsPipe,
    RectCenterPipe,
    AngleIronDimensionsPipe,
    AngleIronCenterPipe,
    IsLayerDeleteActivePipe,
    IsLayerDeletablePipe,
    LayerDetailsRecalculatePropsComponent,
    IsRecalculateActivePipe,
    IsLayerRectTypePipe,
    LayerThicknessInputComponent,
    LayerDetailsPositionComponent,
    LayerDetailsGeolocationComponent,
  ],
  imports: [CommonModule, NebularModule, SharedModule, ReactiveFormsModule],
  exports: [LayerDetailsComponent],
  providers: [LayerDetailsService, LayerDetailsUtilsService],
})
export class LayerDetailsModule {}
