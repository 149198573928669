/* eslint-disable ngrx/avoid-dispatching-multiple-actions-sequentially */
import {
  FAILED,
  REMOVE_XLIF_FAIL,
  REMOVE_XLIF_SUCCESSFULLY,
  SUCCESS,
} from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AddFilesProcessStatus } from '../../../models/add-files-process-status.model';
import { CsvTypes } from '../../../models/csv-types.enum';
import { FileMetadataInfo } from '../../../models/uploaded-image-metadata.model';
import { UploaderStorageService } from '../../../services/uploader-storage-service/uploader-storage.service';
import {
  removeFile,
  setControlPointsFileName,
  setCsvFiles,
  setFiles,
  setFlatFileName,
  setXlifData,
} from '../../../store/files/actions/files.actions';
import {
  selectCsvFiles,
  selectFiles,
  selectFlatFileName,
  selectGCPFileName,
  selectXlifData,
} from '../../../store/files/selectors/files.selectors';
import { AddCsvFileEvent } from '../models/add-csv-file-event.model';
import { XlifService } from './xlif.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UploaderFilesActionsService {
  private flatFileName: string | null;
  private controlPointsFileName: string | null;
  private files: File[];
  private csvFiles: File[];
  private xlifData: FileMetadataInfo[];

  constructor(
    private xlifService: XlifService,
    private toastrService: NbToastrService,
    private store: Store,
    private uploaderStorageService: UploaderStorageService,
  ) {
    this.store
      .select(selectFiles)
      .pipe(untilDestroyed(this))
      .subscribe((files) => (this.files = files));

    this.store
      .select(selectFlatFileName)
      .pipe(untilDestroyed(this))
      .subscribe((name) => (this.flatFileName = name));

    this.store
      .select(selectGCPFileName)
      .pipe(untilDestroyed(this))
      .subscribe((name) => (this.controlPointsFileName = name));

    this.store
      .select(selectCsvFiles)
      .pipe(untilDestroyed(this))
      .subscribe((files) => (this.csvFiles = files));

    this.store
      .select(selectXlifData)
      .pipe(untilDestroyed(this))
      .subscribe((data) => (this.xlifData = data));
  }

  // eslint-disable-next-line complexity
  setCsvFileType(name: string, type: CsvTypes) {
    if (this.flatFileName === name && type !== CsvTypes.FLAT_FILE) {
      this.store.dispatch(setFlatFileName({ name: null }));
    }

    if (this.controlPointsFileName === name && type !== CsvTypes.CONTROL_POINT) {
      this.store.dispatch(setControlPointsFileName({ name: null }));
    }

    if (type === CsvTypes.CONTROL_POINT) {
      this.store.dispatch(setControlPointsFileName({ name: null }));
      this.store.dispatch(setControlPointsFileName({ name }));
    }

    if (type === CsvTypes.FLAT_FILE && this.flatFileName !== name) {
      this.store.dispatch(setFlatFileName({ name: null }));
      this.store.dispatch(setFlatFileName({ name }));
    }
  }

  async deleteCsvFile(name: string) {
    switch (name) {
      case this.flatFileName:
        this.store.dispatch(setFlatFileName({ name: null }));
        break;
      case this.controlPointsFileName:
        this.store.dispatch(setControlPointsFileName({ name: null }));
        break;
    }
    const csvFiles = this.csvFiles.filter((f) => f.name !== name);
    this.store.dispatch(setCsvFiles({ csvFiles }));
  }

  async addFiles(files: File[]) {
    const { newFiles, imgFiles, csvFiles } = this._groupFiles(files);
    this.store.dispatch(setCsvFiles({ csvFiles }));

    if (newFiles.length) {
      this.uploaderStorageService.addFilesProcessStatus$.next(
        AddFilesProcessStatus.VALIDATING_DATA,
      );
      let xlifData: FileMetadataInfo[] = [...this.xlifData];
      xlifData.push(...(await this.xlifService.getXlifDataFromFiles(imgFiles)));
      files = [...this.files, ...imgFiles];
      xlifData = xlifData.filter((data) => files.find((file) => file.name === data.name));
      this.store.dispatch(setFiles({ files, xlifData }));
      this.uploaderStorageService.addFilesProcessStatus$.next(null);
    }
  }

  async addCsvFile(event: AddCsvFileEvent) {
    const { file, type } = event;
    await this.addFiles([file]);
    this.setCsvFileType(file.name, type);
  }

  deleteFileMetadata(name: string) {
    this.uploaderStorageService.removingMetadata$.next(true);
    const fileToChange = this.files.find((f) => f.name === name);
    this.xlifService
      .removeXlif(fileToChange)
      .then(async (file) => {
        const index = this.files.findIndex((f) => f.name === file.name);
        this.files[index] = file;
        const photosXlifDataIndex = this.xlifData.findIndex((item) => item.name === name);
        const xlifData = [...this.xlifData];
        xlifData[photosXlifDataIndex] = new FileMetadataInfo(name);
        this.store.dispatch(setXlifData({ xlifData }));
        this.uploaderStorageService.updateWrongMetadataInfo();
        this.toastrService.show(REMOVE_XLIF_SUCCESSFULLY, SUCCESS, {
          ...SUCCESS_TOASTR_CONFIG,
        });
      })
      .catch(() => {
        this.toastrService.show(REMOVE_XLIF_FAIL, FAILED, {
          ...WARNING_TOASTR_CONFIG,
        });
      })
      .finally(() => {
        this.uploaderStorageService.removingMetadata$.next(false);
      });
  }

  removeOne(name: string) {
    this.store.dispatch(removeFile({ name }));
    this.uploaderStorageService.updateWrongMetadataInfo();
  }

  private _groupFiles(files: File[]) {
    const newFiles = files.filter((file) => !this.files.some((item) => item.name === file.name));
    const imgFiles = newFiles.filter((file) => file.type.includes('image/'));
    const csvFiles = this._getNewCsvFiles(files);
    return { newFiles, imgFiles, csvFiles };
  }

  private _getNewCsvFiles(files: File[]) {
    const prevCsvFileNames = this.csvFiles.map((file) => file.name);
    return [
      ...this.csvFiles,
      ...files
        .filter((file) => file.type === 'text/csv')
        .filter((file) => !prevCsvFileNames.includes(file.name)),
    ];
  }
}
