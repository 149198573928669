<div class="layers_csv_export_bar">
  <div
    class="layers_csv_export_bar__button_wrapper"
    nbTooltip="Folder is empty"
    [nbTooltipTrigger]="hasChild ? NbTrigger.NOOP : NbTrigger.HOVER"
  >
    <button
      nbButton
      status="info"
      size="tiny"
      class="layers_csv_export_bar__button"
      [disabled]="!hasChild"
      (click)="!hasChild ? null : onExportToCsv()"
    >
      <nb-icon icon="cuboid" [pack]="CUSTOM_ICONS_PACK"></nb-icon>
      <div data-testId="csv-export-btn">Export CSV</div>
    </button>
  </div>
</div>
