import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoiLimits from '../reducers/limits.reducer';

export const getModelsState = createFeatureSelector<fromRoiLimits.State>('limits');

const { selectAll } = fromRoiLimits.limitsAdapter.getSelectors(getModelsState);

export const selectAllRoiLimits = selectAll;

export const selectRoiLimitByID = (id: number) =>
  createSelector(selectAll, (entities) => entities.find((entity) => entity.id === id));

export const selectRoiLimitByType = (type: string) =>
  createSelector(selectAll, (entities) => entities.find((entity) => entity.modelType === type));
