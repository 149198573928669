import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@App/app/shared/api.service';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ControlPointsHttpService extends ApiService {
  constructor(authService: AuthService, http: HttpClient, private store: Store) {
    super(authService, http);
  }

  getControlPointsFromUrl(url: string) {
    return this.http.get(url, {
      responseType: 'text',
      headers: {
        notLogOutOn403: 'true',
      },
    });
  }
}
