<div class="select_preset">
  <label>Choose preset</label>
  <form [formGroup]="selectPresetForm">
    <nb-select
      aria-label="Choose preset"
      placeholder="Select preset"
      status="control"
      size="tiny"
      formControlName="selectedPreset"
      class="select_preset__select"
      optionsListClass="presets-list-settings"
    >
      <nb-option
        *ngFor="let color of colorList"
        [value]="color.name"
        class="select_preset__select__option"
      >
        <div data-testId="preset" class="select_preset__select__option__inputs_preview_presets">
          <input
            class="select_preset__select__option__inputs_preview_presets__preview_input"
            type="color"
            [value]="color.backgroundColor"
          />
          <input
            class="select_preset__select__option__inputs_preview_presets__preview_input"
            type="color"
            [value]="color.viewFinderColor"
          />
        </div>
        {{ color.name }}
        <ng-container *ngxPermissionsOnly="permissions.CAN_DELETE_COLOR_PRESET">
          <nb-icon
            *ngIf="!color.default"
            class="select_preset__select__option__delete"
            (click)="onDeletePreset(color.name)"
            icon="trash-2-outline"
          ></nb-icon>
        </ng-container>
      </nb-option>
    </nb-select>
  </form>
</div>
