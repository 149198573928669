import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-async-action-dispatched-info',
  templateUrl: './async-action-dispatched-info.component.html',
  styleUrls: ['./async-action-dispatched-info.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsyncActionDispatchedInfoComponent {
  @Input() message: string;
  @Input() inline = true;
  @Input() theme: 'light' | 'dark' = 'light';
  @Output() closeInfo = new EventEmitter<void>();

  onClose() {
    this.closeInfo.emit();
  }
}
