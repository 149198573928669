import { User } from '@App/app/entities/users/user';
import { createAction, props } from '@ngrx/store';
import { AddUserForm, EditUserFormExt, HttpEditRoles } from '../../../../entities/users/user';

export const loadAllUsers = createAction('[Users] Load all users');

export const loadAllUsersSuccess = createAction(
  '[Users] Load all users success',
  props<{ users: User[]; totalCount: number }>(),
);

export const loadUser = createAction('[Users] Load user', props<{ id: number }>());

export const loadUserSuccess = createAction('[Users] Load user success', props<{ user: User }>());

export const addNewUser = createAction('[Users] Add new user', props<{ user: AddUserForm }>());

export const assignRoles = createAction('[Users] Assign roles', props<{ roles: HttpEditRoles }>());

export const addNewUserSuccess = createAction('[Users] Add new user success');

export const addNewUserFailed = createAction('[Users] Add new user failed');

export const editUser = createAction('[Users] Edit user', props<{ user: EditUserFormExt }>());

export const editUserSuccess = createAction('[Users] Edit user success');

export const editUserFailed = createAction('[Users] Edit user failed');

export const disableUser = createAction('[Users] Disable user', props<{ userId: number }>());

export const disableUserSuccess = createAction(
  '[Users] Disable user success',
  props<{ id: number }>(),
);

export const disableUserFailed = createAction('[Users] Disable user failed');

export const enableUser = createAction('[Users] Enable user', props<{ userId: number }>());

export const enableUserSuccess = createAction(
  '[Users] Enable user success',
  props<{ id: number }>(),
);

export const enableUserFailed = createAction('[Users] Enable user failed');

export const removeAllUsersLocal = createAction('[Users] Remove all users local');
