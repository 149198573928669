/* eslint-disable complexity */
import { NO_MODEL_TO_SHOW } from '@App/app/configs/models.config';
import * as utils from '@App/app/engine/utils/engine-utils/engine.utils';
import { ATMesh } from '@App/app/entities/files/files.model';
import { ModelType } from '@App/app/entities/processing/build-process-status.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { ProcessingService } from '@App/app/pages/viewer/processing/processing.service';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { changeElementStyle } from '@App/app/shared/utils/html.utils';
import { environment } from '@App/environments/environment';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subscription, from, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  mergeMap,
  switchMap,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import { CameraTargetService } from '../../camera-services/camera-target-service/camera-target.service';
import { CamerasService } from '../../camera-services/cameras-service/cameras.service';
import { CustomLODService } from '../../custom-lod-service/custom-lod.service';
import { LoadModelService } from '../../load-model-service/load-model.service';
import { SceneService } from '../../scene-service/scene.service';
import { BaseEngineService } from '../base-engine-service/base-engine.service';
import { EngineUtilsService } from '../engine-utils-service/engine-utils.service';

@Injectable({ providedIn: 'root' })
export class ProcessingEngineService extends BaseEngineService {
  private _areFilesLoaded = false;
  private _typeChangeSubscription: Subscription;
  private _activeStepSubscription: Subscription;

  constructor(
    private _processingService: ProcessingService,
    private _modelsProcessingService: ModelsProcessingService,
    private cameraTargetService: CameraTargetService,
    broadcastService: BroadcastService,
    camService: CamerasService,
    loadModelService: LoadModelService,
    sceneService: SceneService,
    utilsService: EngineUtilsService,
    lodService: CustomLODService,
  ) {
    super(broadcastService, camService, loadModelService, sceneService, utilsService, lodService);
  }

  isReadyToLoadModel = () => this._areFilesLoaded;

  getEntity(): ExtendedRender | null {
    return this._modelsProcessingService.getCurrentBuildProcess();
  }

  protected initSubscribers() {
    return [
      this.broadcastService.on(EVENT_TYPE.LOAD_MODEL).subscribe(() => {
        this._typeChangeSubscription?.unsubscribe();
        this._typeChangeSubscription = this._processingService.modelToLoad$
          .pipe(distinctUntilChanged())
          .subscribe((type) => {
            this.loadingModelSubscription?.unsubscribe();
            this._loadingError$.next(null);
            this._loadingText$.next(null);
            this._modelIsNotAvailable$.next(null);
            this.loadModelService.abortAllLoaderRequests();
            this.loadModel(type);
          });
      }),
      this._modelsProcessingService.areFilesLoaded.subscribe((value) => {
        this._areFilesLoaded = value;
        if (environment.production) {
          this.loadModel(this._processingService.getModelToLoad());
        }
      }),
      this.isModelLoaded$
        .pipe(filter(Boolean))
        .pipe(switchMapTo(this._processingService.modelToLoad$))
        .subscribe((type) => {
          if (type === ModelType.AT) {
            const isModelAvailable = type === ModelType.AT ? !!this._getATMeshFile() : true;
            this._modelIsNotAvailable$.next(isModelAvailable ? null : NO_MODEL_TO_SHOW);
          } else if (
            type === ModelType.Prod &&
            this._modelIsNotAvailable$.value === 'No model to show.'
          ) {
            this.sceneService.scene.meshes = [];
          }
        }),
    ];
  }

  importModel(type?: ModelType): Observable<void> {
    if (this._processingService.buildProcess && this._areFilesLoaded) {
      this.customLODService.setFreezed(true);
      return from(this._modelsProcessingService.waitForLogsToBeLoaded()).pipe(
        mergeMap(() => this._loadModelByType(type || this._processingService.getModelToLoad())),
      );
    }
    return EMPTY;
  }

  switchCameraToRoi() {
    this.utilsService.switchCameraToRoi(this.camera);
  }

  resetCameraToDefault() {
    if (this.camera) this.cameraTargetService.resetCamera(this.camera);
  }

  initEngineSubscriptions(canvas: HTMLCanvasElement, _: number) {
    return [
      this._modelsProcessingService.areFilesLoaded.subscribe(() => {
        const buildProcess = this.getEntity() as BuildProcess;
        if (buildProcess && !buildProcess.steps.upload.isCurrent() && !this.sceneService.scene) {
          this.startScene(canvas, false);
        }
      }),
    ];
  }

  protected setDebugLayerStyles = () => {
    changeElementStyle('viewer__sidebar_layers', 'left', '300px');
  };

  private _getATMeshFile(): ATMesh | null {
    return this.getEntity()?.atMesh || null;
  }

  private _loadModelByType(type: ModelType): Observable<void> {
    switch (type) {
      case ModelType.AT:
        return this._loadATModel();
      case ModelType.Prod:
        return this._loadProdModel();
      default:
        this.setModelLoad$(false, true);
        return of();
    }
  }

  private _loadATModel(): Observable<void> {
    this._loadingText$.next('Loading Aerotriangulation Model');
    const atMesh = this._getATMeshFile();
    if (!this.camera) return EMPTY;
    return from(this.loadModelService.loadATModel(atMesh, this.camera)).pipe(
      take(1),
      finalize(() => this.loadModelService.clearContainerByType(ModelType.AT)),
      tap(() => {
        this._activeStepSubscription?.unsubscribe();
        this._activeStepSubscription = this._processingService.activeStep$
          .pipe(filter((step) => step === ProcessingSteps.PROD_SETUP))
          .subscribe(() => this._processingService.initRegionOfInterest());
        this.loadModelService.isATModelLoaded = true;
      }),
    );
  }

  private _loadProdModel(): Observable<void> {
    this._loadingText$.next('Loading Production Model');
    return of(null).pipe(
      switchMap(() => {
        if (!this.camera) {
          const { buildProcess: process } = this._processingService;
          const isUploadOrAT = process?.steps.upload.isCurrent() || process?.steps.at.isCurrent();
          const isLoaded = !!(isUploadOrAT && !process?.isFailed());
          this.setModelLoad$(false, isLoaded);
          return EMPTY;
        }
        const process = this.getEntity();
        const tilesList = utils.getTilesListFromRender(process);
        const simplifiedTileLevels = utils.getSimplifiedTilesLevelsListFromRender(process);
        const tiles = utils.getTilesToLoad(tilesList, simplifiedTileLevels);
        const range = this.utilsService.getTilesRangeByDev(this.tilesRange);
        return from(this.loadModelService.loadProdModel(tiles, this.camera, false, range)).pipe(
          take(1),
          finalize(() => this.loadModelService.clearContainerByType(ModelType.Prod)),
          tap(() => {
            this._registerOrUnfreezeLOD(tilesList, simplifiedTileLevels);
            this.loadModelService.isProdModelLoaded = true;
          }),
        );
      }),
    );
  }

  private _registerOrUnfreezeLOD(tiles: string[], simplifiedTileLevels: string[][]) {
    if (!this.customLODService.isRegistered()) {
      const ctr = this.loadModelService.getContainerByType(ModelType.Prod);
      this.registerLODIfNeeded(tiles, simplifiedTileLevels, ctr || undefined);
    }
    this.customLODService.setFreezed(false);
  }
}
