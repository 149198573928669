import {
  MARKER_SYMBOL,
  MODEL_LABEL_BOX_STYLE,
  MODEL_LABEL_SYMBOL,
} from '@App/app/configs/map.config';
import { DEFAULT_POSITION } from '@App/app/configs/photos-map.config';
import { Model } from '@App/app/entities/models/model.model';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getBounds, getCenter } from 'geolib';
import { Coordinate, Label, Map, Marker, Polygon, VectorLayer, ui } from 'maptalks';
import { ThumbnailPipe } from '../../../models-table/pipes/thumbnail/thumbnail.pipe';
import { ModelsContentService } from '../../../services/models-content-service/models-content.service';
import { createModelContext } from '../../utils/model-context.utils';

@UntilDestroy()
@Injectable()
export class ModelsMapService {
  private _companyId: number | null;

  constructor(
    private _modelsContentService: ModelsContentService,
    private _thumbnailPipe: ThumbnailPipe,
    private _router: Router,
  ) {
    this._modelsContentService.companyId$.pipe(untilDestroyed(this)).subscribe((companyId) => {
      this._companyId = companyId;
    });
  }

  createMultipleInfoWindow(markers: Marker[], map: Map, coordinate: Coordinate) {
    const content = this._getDialogContent(markers);
    const infoWindow = new ui.InfoWindow({ autoCloseOn: 'click', content });
    infoWindow.addTo(map).show(coordinate);
    return infoWindow;
  }

  zoomToInfoWindow(infoWindow: ui.InfoWindow, map: Map) {
    const center = this._getInfoWindowCenter(infoWindow, map);
    map.panTo(center);
  }

  getMarkersByCoordinate(map: Map, coordinate: Coordinate) {
    const layer = map.getLayer('main') as VectorLayer;
    const result = layer.identify(coordinate);
    return result as { center: Coordinate; children: Marker[] } | Marker[];
  }

  modelToMarker(model: Model) {
    const lat = model.location?.latitude as number;
    const long = model.location?.longitude as number;
    const marker = new Marker([long, lat], { symbol: MARKER_SYMBOL });
    marker.setProperties({ model });
    marker.setInfoWindow({
      title: model.name,
      content: this._getDialogContent([marker]),
      autoCloseOn: 'click',
      single: true,
      custom: true,
      dy: -5,
    });

    this._addMarkerEventListeners(marker);
    return marker;
  }

  getMapCenter(models: Model[]): [number, number] {
    const coords: GeoCoords[] = models.map((model) => ({
      latitude: model.location?.latitude as number,
      longitude: model.location?.longitude as number,
    }));

    const center = getCenter(coords);
    return center ? [center.longitude, center.latitude] : DEFAULT_POSITION;
  }

  adjustMapViewToModels(models: Model[], map: Map) {
    const coords = models.map((model) => model.location as GeoCoords);
    const { minLat, maxLat, minLng, maxLng } = getBounds(coords);
    const polygon = new Polygon([
      [minLng, minLat],
      [minLng, maxLat],
      [maxLng, maxLat],
      [maxLng, minLat],
    ]);
    map.fitExtent(polygon.getExtent(), 0.5);
  }

  redrawMarkers(map: Map) {
    try {
      map.getRenderer().setToRedraw();
      map.getLayer('main').getRenderer().draw();
    } catch (_) {
      /**
       * since this callback is only a workaround for maptalks bug
       * that doesn't render markers correctly on mousemove, sometimes
       * those renders above throw some errors, but everything works fine.
       * That's why we catch them here, but there's nothing to worry about.
       */
    }
  }

  private _addMarkerEventListeners(marker: Marker) {
    marker.on('mouseenter', (event) => this._setCanvasCursor(event, 'pointer'));
    marker.on('mouseout', (event) => this._setCanvasCursor(event, 'default'));

    let label: Label | null = null;
    marker.on('mouseenter', () => {
      const layer = marker.getMap().getLayer('labels');
      const { name } = (marker.getProperties() as { model: Model }).model;
      label = new Label(name, marker.getCoordinates(), {
        boxStyle: MODEL_LABEL_BOX_STYLE,
        textSymbol: MODEL_LABEL_SYMBOL,
      });
      label.addTo(layer);
    });
    marker.on('mouseout', () => label?.remove());
  }

  private _setCanvasCursor(event: { domEvent: MouseEvent }, value: string) {
    const e: MouseEvent = event.domEvent;
    (e.target as HTMLCanvasElement).style.cursor = value;
  }

  private _getInfoWindowCenter(infoWindow: ui.InfoWindow, map: Map) {
    const coordinate = infoWindow._coordinate;
    const point = map.coordinateToContainerPoint(coordinate);
    point.y -= infoWindow.getSize().height / 2;
    return map.containerPointToCoordinate(point);
  }

  private _getDialogContent(markers: Marker[]) {
    const elements = markers.map((marker) => {
      const properties = marker.getProperties() as { model: Model };
      const { model: pickedModel } = properties;
      const thumbnailUrl = this._thumbnailPipe.transform(pickedModel) as string;
      const onClick = () =>
        this._router.navigate(['/', this._companyId, 'models', pickedModel?.id]);
      return createModelContext(pickedModel, onClick, thumbnailUrl);
    });
    const container = document.createElement('div');
    container.classList.add('div-context-style-container');
    elements.forEach((element) => container.appendChild(element));
    container.addEventListener('wheel', (e) => {
      container.scrollTop += e.deltaY;
    });
    return container;
  }
}
