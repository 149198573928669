<div class="create_folder">
  <div class="create_folder__header">
    <div class="create_folder__title">
      <div class="create_folder__type">
        <nb-icon class="create_folder__icon" icon="folder-outline"></nb-icon>
      </div>
      <div class="create_folder__name">Create new folder</div>
    </div>
  </div>
  <div class="create_folder__body">
    <div class="create_folder__row">
      <div class="create_folder__label">Name</div>
      <div class="create_folder__value">
        <input
          type="text"
          data-testId="name-input"
          class="create_folder__input"
          [formControl]="name"
          #nameInput
          autofocus
        />
      </div>
    </div>
    <div class="create_folder__error" *ngIf="name.invalid && name.dirty">Name cannot be empty</div>
  </div>
  <div class="create_folder__actions">
    <button
      type="button"
      class="create_folder__action create_folder__action--cancel"
      data-testId="cancel-btn"
      (click)="onCancel()"
    >
      Cancel
    </button>
    <button
      type="button"
      nbButton
      status="info"
      size="tiny"
      class="create_folder__save"
      (click)="onConfirm()"
      data-testId="confirm-btn"
    >
      Confirm
    </button>
  </div>
</div>
