import { IPresetModel } from '@App/app/entities/processing/processing-preset-settings.model';
import { ApiService } from '@App/app/shared/api.service';
import { environment } from '@App/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PresetsHttpService extends ApiService {
  private _loginUrl = environment.loginUrl;

  getPresets(): Observable<IPresetModel[]> {
    return this.http.get<IPresetModel[]>(`${this._loginUrl}/processing-presets`);
  }

  postPreset(form: IPresetModel): Observable<IPresetModel> {
    return this.http.post<IPresetModel>(`${this._loginUrl}/processing-presets`, form);
  }

  putPreset(id: number, form: IPresetModel): Observable<IPresetModel> {
    return this.http.put<IPresetModel>(`${this._loginUrl}/processing-presets/${id}`, form);
  }

  deletePreset(id: number): Observable<IPresetModel> {
    return this.http.delete<IPresetModel>(`${this._loginUrl}/processing-presets/${id}`);
  }
}
