import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ViewerLayerService } from '../../../../engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { addPointToPolygon } from './../actions/layers.actions';

@Injectable()
export class AddPointToPolygonEffects {
  constructor(private viewerLayerService: ViewerLayerService, private actions: Actions) {}

  addPointToPolygon = createEffect(
    () => {
      return this.actions.pipe(
        ofType(addPointToPolygon),
        tap(({ id, point }) => {
          this.viewerLayerService.addPointToPolygon(id, point);
        }),
      );
    },
    { dispatch: false },
  );
}
