import { MODEL_TYPES } from '../entities/models/model-types.enum';
import { ModelType } from '../entities/models/model.model';

export const TILES_ARE_BEING_PROCESSED = 'The model cannot be processed.';

export const NO_MODEL_TO_SHOW = 'No model to show.';

export const MODEL_LOADING_ERROR = 'Something went wrong when loading model.';

export const THUMBNAIL_SHAPE = [400, 400];

export const BASIC_TYPES: ModelType[] = [
  { name: 'Cell Tower', abbr: 'cell-tower' },
  { name: 'Air Traffic Control Tower', abbr: 'atct' },
  { name: 'Power Line', abbr: 'power-line' },
  { name: 'Bridge', abbr: 'bridge' },
  { name: 'Roadway', abbr: 'roadway' },
  { name: 'Pipeline', abbr: 'pipeline' },
  { name: 'Building', abbr: 'building' },
  { name: 'Generic', abbr: 'generic' },
];

export const CELL_TOWER_MODEL_TYPES: ModelType[] = [
  { name: 'Monopole', abbr: 'monopole' },
  { name: 'Self-Support', abbr: 'self-support' },
  { name: 'Guyed', abbr: 'guyed' },
];

export const BRIDGE_MODEL_TYPES: ModelType[] = [
  { name: 'Small span', abbr: 'small-span' },
  { name: 'Medium span', abbr: 'medium-span' },
  { name: 'Long span', abbr: 'long-span' },
];

export const POWER_LINE_MODEL_TYPES: ModelType[] = [
  { name: 'High Voltage', abbr: 'high-voltage' },
  { name: 'Medium Voltage', abbr: 'medium-voltage' },
  { name: 'Low Voltage', abbr: 'low-voltage' },
];

export const ATCT_MODEL_TYPES: ModelType[] = [
  { name: 'Visual flight rules tower', abbr: 'vfr-tower' },
  { name: 'Not register air traffic tower', abbr: 'nra-tower' },
  { name: 'Register air traffic tower', abbr: 'ra-tower' },
];

export const ROAD_WAY_MODEL_TYPES: ModelType[] = [
  { name: 'Highway', abbr: 'highway' },
  { name: 'Primary', abbr: 'primary' },
  { name: 'Secondary', abbr: 'secondary' },
  { name: 'Tertiary', abbr: 'tertiary' },
  { name: 'Residential', abbr: 'residential' },
];

export const BUILDING_MODEL_TYPES: ModelType[] = [
  { name: 'Residential', abbr: 'residential' },
  { name: 'Commercial', abbr: 'commercial' },
  { name: 'Industrial', abbr: 'industrial' },
  { name: 'Public', abbr: 'public' },
];

export const PIPELINE_MODEL_TYPES: ModelType[] = [
  { name: 'Oil', abbr: 'oil' },
  { name: 'Gas', abbr: 'gas' },
  { name: 'Water', abbr: 'water' },
];

export const GENERIC_MODEL_TYPES: ModelType[] = [{ name: 'Generic', abbr: 'generic' }];

export const MODEL_TYPES_BADGE_COLORS = [
  {
    text: MODEL_TYPES.CELL_TOWER,
    bgColor: '#1c5422',
  },
  {
    text: MODEL_TYPES.BUILDING,
    bgColor: '#ab5561',
  },
  {
    text: MODEL_TYPES.BRIDGE,
    bgColor: '#4a66a6',
  },
  {
    text: MODEL_TYPES.ROADWAY,
    bgColor: '#7e500f',
  },
  {
    text: MODEL_TYPES.ATCT,
    bgColor: '#29417c',
  },
  {
    text: MODEL_TYPES.POWER_LINE,
    bgColor: '#5e701c',
  },
  {
    text: MODEL_TYPES.PIPELINE,
    bgColor: '#ab592a',
  },
  {
    text: MODEL_TYPES.GENERIC,
    bgColor: '#764794',
  },
  {
    text: 'non-type',
    bgColor: '#575a58',
  },
];
