import { ProcessingEngineService } from '@App/app/engine/services/engine-services/processing-engine-service/processing-engine.service';
import { RegionOfInterestService } from '@App/app/engine/services/region-of-interest-service/region-of-interest.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { ProductionForm } from '@App/app/entities/forms/production-form.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ModelsProcessingService } from '@App/app/pages/processing/services/models-processing-service/models-processing.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Quaternion, Vector3 } from 'babylonjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProcessingService } from '../../processing.service';
import { prodSettings } from '../../shared/models/prod-settings';
import { Srs } from '../at-setup-tab/at-setup-control-points/srs-dropdown/models/srs.model';
import { SetupTabComponent } from '../base-tabs/setup-tab.component';
@UntilDestroy()
@Component({
  selector: 'app-prod-setup-tab',
  templateUrl: './prod-setup-tab.component.html',
  styleUrls: ['./prod-setup-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProdSetupTabComponent extends SetupTabComponent<ProductionForm>
  implements OnInit, OnDestroy {
  static step = ProcessingSteps.PROD_SETUP;
  static settings = prodSettings;
  protected cancelStep = ProcessingSteps.AT_REVIEW;
  private initialValues: ProductionForm['value'];
  canAccept: boolean;
  isFormatError = false;

  constructor(
    fb: NonNullableFormBuilder,
    processingService: ProcessingService,
    private permissionsService: NgxPermissionsService,
    private regionOfInterestService: RegionOfInterestService,
    private processingEngineService: ProcessingEngineService,
    store: Store,
    _modelsProcessingService: ModelsProcessingService,
  ) {
    super(fb, processingService, store, _modelsProcessingService);
    this.canAccept = !!this.permissionsService.getPermission(UserPermissions.CAN_ACCEPT_AT);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initialValues = this.form.value;
    this.processingService.initRegionOfInterest();
    this.isFailed =
      !!this.processingService.buildProcess?.isFailed() &&
      this.processingService.buildProcess.steps.prod.isCurrent();
  }

  ngOnDestroy(): void {
    this.processingService.destroyRegionOfInterest();
  }

  getSettings() {
    return prodSettings;
  }

  onSubmit(form: ProductionForm) {
    const formValue = { ...form.value };
    if (!formValue.withObj && !formValue.withPointCloud && !formValue.withOrthoDSM) {
      this.isFormatError = true;
      return;
    }
    this.isFormatError = false;

    const q = Quaternion.FromEulerAngles(Math.PI / 2, 0, 0);
    const rootPosition = this.regionOfInterestService.getRootPosition();
    const max = this.processingService.boundingVectors.max.clone();
    const min = this.processingService.boundingVectors.min.clone();
    [max, min].forEach((vector) => {
      if (rootPosition) {
        vector.subtractInPlace(rootPosition);
      }
      vector.rotateByQuaternionToRef(q, vector);
      vector.multiplyInPlace(new Vector3(-1, 1, 1));
    });

    formValue.boundingBox = {
      xMax: Math.max(max.x, min.x),
      yMax: Math.max(max.y, min.y),
      zMax: Math.max(max.z, min.z),
      xMin: Math.min(max.x, min.x),
      yMin: Math.min(max.y, min.y),
      zMin: Math.min(max.z, min.z),
    };
    this.startNext.emit(formValue);
  }

  onRestoreDefault() {
    this.regionOfInterestService.setDefaultBoundingBox();
    this.processingEngineService.resetCameraToDefault();
    this.form.reset(this.initialValues);
  }

  protected createForm(): ProductionForm {
    return this.fb.group({
      settingsPreset: this.fb.control(0, { validators: [Validators.required] }),
      colorEqualizationMode: this.fb.control('blockwise', { validators: [Validators.required] }),
      withObj: this.fb.control(false, { validators: [Validators.required] }),
      withPointCloud: this.fb.control(false, { validators: [Validators.required] }),
      withOrthoDSM: this.fb.control(false, { validators: [Validators.required] }),
      geometryPrecisionMode: this.fb.control('extra', { validators: [Validators.required] }),
      geometrySimplificationMode: this.fb.control('standard', {
        validators: [Validators.required],
      }),
      holeFillingMode: this.fb.control('smallHoles', { validators: [Validators.required] }),
      pairSelectionMode: this.fb.control('generic', { validators: [Validators.required] }),
      textureSource: this.fb.control('photoFirst', { validators: [Validators.required] }),
      untexturedRegionsRepresentation: this.fb.control('inpaintingCompletion', {
        validators: [Validators.required],
      }),
      photosUsedForGeometry: this.fb.control('excludeThermal', {
        validators: [Validators.required],
      }),
      targetMemoryUse: this.fb.control(20, { validators: [Validators.required] }),
      tileSize: this.fb.control(200, { validators: [Validators.required] }),
      textureEnabled: this.fb.control(true, { validators: [Validators.required] }),
      textureCompressionQuality: this.fb.control(80, { validators: [Validators.required] }),
      orthoDsmSrs: this.fb.control<Srs | null>(null, { validators: [Validators.required] }),
      boundingBox: this.fb.group({
        xMax: this.fb.control(this.processingService.boundingVectors.max.x, {
          validators: [Validators.required],
        }),
        yMax: this.fb.control(this.processingService.boundingVectors.max.y, {
          validators: [Validators.required],
        }),
        zMax: this.fb.control(this.processingService.boundingVectors.max.z, {
          validators: [Validators.required],
        }),
        xMin: this.fb.control(this.processingService.boundingVectors.min.x, {
          validators: [Validators.required],
        }),
        yMin: this.fb.control(this.processingService.boundingVectors.min.y, {
          validators: [Validators.required],
        }),
        zMin: this.fb.control(this.processingService.boundingVectors.min.z, {
          validators: [Validators.required],
        }),
      }),
    });
  }
}
