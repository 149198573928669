import { GCPPoint } from '@App/app/entities/files/gcp-point.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhotosUtilsService } from '../photos-utils/photos-utils.service';
import { GcpHttpService } from './gcp-points.http.service';

@Injectable({
  providedIn: 'root',
})
export class GcpPointsService {
  constructor(
    private _gcpPointsHttpService: GcpHttpService,
    private _photosUtilsService: PhotosUtilsService,
  ) {}

  downloadGcpPoints(gcpPointsPath: string): Observable<GCPPoint[]> {
    return this._gcpPointsHttpService.download(gcpPointsPath).pipe(
      map((gcpPoints) => {
        return gcpPoints.map((gcpPoint) => {
          return {
            ...gcpPoint,
            position: {
              x: -gcpPoint.position.x - this._photosUtilsService.photosModifier.xDiff,
              y: gcpPoint.position.z - this._photosUtilsService.photosModifier.yDiff,
              z: -gcpPoint.position.y - this._photosUtilsService.photosModifier.zDiff,
            },
          };
        });
      }),
    );
  }
}
