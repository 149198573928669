import { CalculatedData } from '@App/app/entities/layer/calculated-data';
import { Cuboid } from '@App/app/entities/layer/cuboid.model';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { RectTube } from '@App/app/entities/layer/rect-tube.model';
import { Injectable } from '@angular/core';
import {
  AbstractMesh,
  Color3,
  Mesh,
  Plane,
  Ray,
  RayHelper,
  Vector3,
  VertexBuffer,
} from 'babylonjs';
import { MODEL_NAME } from 'src/app/configs/babylon.config';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../../material-service/material.service';
import { SceneService } from '../../../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export class RectangleToolsUtilsService {
  constructor(
    private materialService: MaterialService,
    private sceneService: SceneService,
    private nodeService: BabylonNodesService,
  ) {}

  changeSampleMaterial(sample: AbstractMesh | null, defaultMaterial: boolean) {
    if (sample) {
      sample.material = defaultMaterial
        ? this.materialService.electricBlueMaterial
        : this.materialService.redMaterial;
      sample.getChildMeshes().forEach((el) => {
        el.material = defaultMaterial
          ? this.materialService.electricBlueMaterial
          : this.materialService.redMaterial;
      });
    }
  }

  pickRay(from: Vector3, to: Vector3, showRay = false, showMesh = false) {
    const ray = new Ray(from, to);
    if (showRay) {
      RayHelper.CreateAndShow(ray, this.sceneService.scene, new Color3(0, 1, 0.25));
    }
    const hit = this.sceneService.scene.pickWithRay(ray, (mesh) => mesh.name.includes(MODEL_NAME));
    if (hit?.pickedMesh && hit.pickedPoint) {
      if (showMesh) {
        this.nodeService.createSphere(hit.pickedPoint);
      }
      return hit.pickedPoint;
    }
  }

  createPlane(points: Mesh[], facedTo?: Mesh[]) {
    const plane = new Plane(1, 0, 0, 0);
    plane.copyFromPoints(points[0].position, points[1].position, points[2].position);
    if (facedTo) {
      if (
        facedTo &&
        !plane.isFrontFacingTo(facedTo[0].position.subtract(points[0].position).normalize(), 0)
      ) {
        plane.normal = plane.normal.negate();
      }
    }
    return plane;
  }

  createPoint(position: Vector3) {
    return this.nodeService.createSphere(position);
  }

  calculateEdges(box: Mesh): [Coordinates3, Coordinates3] {
    box.bakeCurrentTransformIntoVertices(true);
    const vertices = this.extractVertices(box);

    const centerA = Vector3.Center(vertices[16], vertices[18]);
    const centerB = Vector3.Center(vertices[20], vertices[22]);
    return [
      { x: centerA.x, y: centerA.y, z: centerA.z },
      { x: centerB.x, y: centerB.y, z: centerB.z },
    ];
  }

  extractVertices(box: Mesh) {
    const data = box.getVerticesData(VertexBuffer.PositionKind);
    const vertices: Vector3[] = [];
    if (data) {
      for (let i = 0; i < data.length; i += 3) {
        vertices.push(new Vector3(data[i], data[i + 1], data[i + 2]));
      }
    }
    return vertices;
  }

  setLayerMetricProps(
    layer: Cuboid | RectTube,
    calculatedData: CalculatedData,
    azimuth: number,
    verticalTilt: number,
  ) {
    layer.data.volume = calculatedData.volume;
    layer.data.min = calculatedData.min;
    layer.data.max = calculatedData.max;
    layer.data.onHeightDistance = calculatedData.onHeightDistance;
    layer.data.towerCenterDistance = calculatedData.towerCenterDistance;
    layer.data.azimuth = azimuth;
    layer.data.verticalTilt = verticalTilt;
    layer.data.worldDirection = calculatedData.worldDirection;
  }

  setRotationQuaternion(layer: Cuboid | RectTube, box: Mesh) {
    if (box.rotationQuaternion) {
      layer.data.absoluteRotationQuaternion = {
        x: box.rotationQuaternion.x,
        y: box.rotationQuaternion.y,
        z: box.rotationQuaternion.z,
        w: box.rotationQuaternion.w,
      };
    }
  }

  setLayerMappingProps(layer: Cuboid | RectTube, box: Mesh, thickness?: number) {
    this.setRotationQuaternion(layer, box);
    if (layer.type === LAYER_TYPES.CUBOID) {
      (layer as Cuboid).data.color = '#fff';
    }
    if (layer.type === LAYER_TYPES.RECT_TUBE) {
      (layer as RectTube).data.thickness = thickness;
      (layer as RectTube).data.edges = this.calculateEdges(box);
    }
  }
}
