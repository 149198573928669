import { Render } from '@App/app/entities/processing/render.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RendersHttpService } from '../../../services/renders-http-service/renders-http.service';
import { RendersService } from '../../../services/renders-service/renders.service';
import { loadAllRenders, loadAllRendersSuccess } from '../actions/renders.actions';

@Injectable()
export class LoadRendersEffects {
  constructor(
    private actions: Actions,
    private rendersHttpService: RendersHttpService,
    private rendersService: RendersService,
  ) {}

  loadAllRenders = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAllRenders),
      mergeMap(({ modelId }) => {
        this.rendersService.setRenders(null);
        return this.rendersHttpService.getRenders(modelId).pipe(
          map(
            (resp: Render[]) => {
              return loadAllRendersSuccess({
                renders: resp.map((obj) => new Render(obj)),
              });
            },
            catchError((error) => of(error)),
          ),
        );
      }),
    );
  });
}
