import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export abstract class AuthBaseComponent {
  error: string | null;
  formValueSubscription: Subscription;

  onChanges(form: UntypedFormGroup): void {
    this.formValueSubscription = form.valueChanges.subscribe((val) => {
      this.error = null;
    });
  }
}
