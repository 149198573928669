import { ROI_LIMITS_BY_TYPE } from '@App/app/configs/processing.config';
import { Model } from '@App/app/entities/models/model.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { RegionOfInterest } from '@App/app/entities/processing/region-of-interest.model';
import { BoundingVectors } from '@App/app/entities/viewer/bounding-vectors.model';
import { loadAllModels } from '@App/app/pages/models/store/models/actions/models.actions';
import { selectModelById } from '@App/app/pages/models/store/models/selectors/models.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Quaternion, Vector3 } from 'babylonjs';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { MODEL_AT_NAME } from 'src/app/configs/babylon.config';
import { RoiLimit } from '../../models/roi-limits.model';
import { selectRoiLimitByType } from '../../store/selectors/limits.selectors';
import { BabylonNodesService } from '../babylon-nodes-service/babylon-nodes.service';
import { SceneService } from '../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export class RegionOfInterestService {
  private _regionOfInterest$ = new BehaviorSubject<RegionOfInterest | null>(null);
  regionOfInterest$ = this._regionOfInterest$.asObservable();
  private modelTypeExist = false;
  isShiftPressed$ = merge(fromEvent(window, 'keyup'), fromEvent(window, 'keydown')).pipe(
    filter((event: KeyboardEvent) => event.key === 'Shift'),
    shareReplay(),
    map((event: KeyboardEvent) => event.type === 'keydown'),
  );
  private size = 20;
  boundingVectors: BehaviorSubject<BoundingVectors> = new BehaviorSubject({
    min: new Vector3(0, 0, 0),
    max: new Vector3(0, 0, 0),
  });
  buildProcess: BuildProcess | null;
  constructor(
    private sceneService: SceneService,
    private babylonNodesService: BabylonNodesService,
    private store: Store,
  ) {}

  initProcess(process: BuildProcess) {
    this.buildProcess = process;
    this.loadAllModels();
    this.modelTypeExist = false;
  }

  initRegionOfInterest(vectors?: BoundingVectors | null) {
    this.store
      .select(selectModelById(Number(this.buildProcess?.modelId)))
      .pipe(first(), filter<Model>(Boolean))
      .subscribe((model) => {
        this.store
          .select(selectRoiLimitByType(model.modelType))
          .pipe(
            first(),
            filter<RoiLimit>(Boolean),
            switchMap((type) => {
              return this.store.select(selectRoiLimitByType(type.modelType)).pipe(
                first(),
                filter<RoiLimit>(Boolean),
                tap((limit) => {
                  this.createRegionOfInterest(limit.length, limit.height, limit.width);
                  this.modelTypeExist = true;
                }),
              );
            }),
          )
          .subscribe();
        if (this.modelTypeExist === false) {
          const params = ROI_LIMITS_BY_TYPE[model?.modelType];
          this.createRegionOfInterest(...params);
        }
      });

    this.regionOfInterest$?.subscribe((roi) => {
      roi?.sides.forEach((side) => {
        side.onAfterWorldMatrixUpdateObservable.add(() => {
          const min = new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
          const max = new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);

          roi.sides.forEach((child) => {
            const boundingBox = child.getBoundingInfo().boundingBox;
            const minBox = boundingBox.minimumWorld;
            const maxBox = boundingBox.maximumWorld;

            Vector3.CheckExtends(minBox, min, max);
            Vector3.CheckExtends(maxBox, min, max);
          });
          this.boundingVectors.next({ min, max });
        });
      });
    });
    const rootPos = this.getRootPosition();
    if (rootPos) {
      if (vectors) {
        const { min, max } = vectors;
        const q = Quaternion.FromEulerAngles(-Math.PI / 2, 0, 0);
        [min, max].forEach((vector) => {
          vector.multiplyInPlace(new Vector3(-1, 1, 1));
          vector.rotateByQuaternionToRef(q, vector);
          vector.addInPlace(rootPos);
        });
        this._regionOfInterest$.getValue()?.setSidesToBoundingVectors({
          min: new Vector3(Math.min(max.x, min.x), Math.min(max.y, min.y), Math.min(max.z, min.z)),
          max: new Vector3(Math.max(max.x, min.x), Math.max(max.y, min.y), Math.max(max.z, min.z)),
        });
      } else {
        this.setDefaultBoundingBox();
      }
    }
  }

  createRegionOfInterest(maxSizeX: number, maxSizeY: number, maxSizeZ: number) {
    this._regionOfInterest$.next(
      new RegionOfInterest(
        this.size,
        this.sceneService.scene,
        this.babylonNodesService,
        maxSizeX,
        maxSizeY,
        maxSizeZ,
        this.isShiftPressed$,
      ),
    );
  }

  destroyRegionOfInterest() {
    this._regionOfInterest$?.getValue()?.dispose();
    this._regionOfInterest$.next(null);
  }

  setDefaultBoundingBox() {
    const transformNode = this.sceneService.scene.getTransformNodeByName(MODEL_AT_NAME);
    if (transformNode) {
      const rootVectors = transformNode.getHierarchyBoundingVectors();
      const yRootDiff = (rootVectors.max.y - rootVectors.min.y) / 2;
      this._regionOfInterest$.getValue()?.setSidesToBoundingVectors({
        min: new Vector3(-(this.size / 2), -(this.size / 2) + yRootDiff, -(this.size / 2)),
        max: new Vector3(this.size / 2, this.size / 2 + yRootDiff, this.size / 2),
      });
    }
  }

  loadAllModels() {
    this.store.dispatch(loadAllModels());
  }

  getRootPosition() {
    return this.sceneService.scene?.getTransformNodeByName(MODEL_AT_NAME)?.position.clone();
  }
}
