<div class="layer_details__header">
  <div class="layer_details__title">
    <div class="layer_details__type" *ngIf="type">
      <nb-icon
        *ngIf="type === LAYER_TYPES.FOLDERS"
        class="layer_details__icon"
        icon="folder"
      ></nb-icon>
      <nb-icon
        class="layer_details__icon"
        icon="compass-outline"
        *ngIf="type === LAYER_TYPES.BASE_PLANE"
      ></nb-icon>
      <nb-icon
        class="layer_details__icon"
        icon="expand-outline"
        *ngIf="type === LAYER_TYPES.MEASUREMENTS"
      ></nb-icon>
      <nb-icon
        class="layer_details__icon"
        icon="square-outline"
        *ngIf="type === LAYER_TYPES.POLYGONS"
      ></nb-icon>
      <nb-icon
        class="layer_details__icon"
        icon="corner-right-down"
        *ngIf="type === LAYER_TYPES.DROP_LINES"
      ></nb-icon>
      <nb-icon
        class="layer_details__icon"
        icon="corner-right-down"
        *ngIf="type === LAYER_TYPES.SKETCH_PLANES"
      ></nb-icon>
      <svg-icon *ngIf="type === LAYER_TYPES.TUBES" [src]="tubeIcon" svgClass="layer__svg-icon">
      </svg-icon>
      <svg-icon
        *ngIf="type === LAYER_TYPES.ANGLE_IRON"
        [src]="angleIronIcon"
        svgClass="layer__svg-icon"
      >
      </svg-icon>
      <svg-icon
        *ngIf="type === LAYER_TYPES.CUBOID"
        [src]="rectangularIcon"
        svgClass="layer__svg-icon"
      >
      </svg-icon>
      <svg-icon
        *ngIf="type === LAYER_TYPES.RECT_TUBE"
        [src]="rectTubeIcon"
        svgClass="layer__svg-icon rect_tube"
      >
      </svg-icon>
      <svg-icon
        *ngIf="type === LAYER_TYPES.COORDINATES_LAYER"
        [src]="coordsIcon"
        svgClass="layer__svg-icon coord layer_details__icon"
      >
      </svg-icon>
    </div>
    <div class="layer_details__name" *ngIf="!isChangeNameActive">
      {{ name }}
    </div>
    <ng-container>
      <div class="layer_details__name --edit" *ngIf="isChangeNameActive" [formGroup]="editForm">
        <input
          type="text"
          class="layer_details__input"
          data-testId="edit-name-input"
          formControlName="name"
          #nameInput
          autofocus
        />
      </div>
      <div *ngIf="type === LAYER_TYPES.FOLDERS">
        <div
          class="layer_details__icons"
          *ngIf="!isChangeNameActive && !isEditModeOnInitMappingTool"
        >
          <div
            class="layer_details__edit"
            data-testId="edit-name-btn"
            (click)="onChangeNameClick()"
            nbTooltip="Change name"
            nbTooltipStatus="primary"
            nbTooltipPlacement="bottom"
          >
            <nb-icon class="layer_details__icon" icon="edit-2-outline"></nb-icon>
          </div>
        </div>
        <div class="layer_details__icons" *ngIf="isChangeNameActive">
          <div
            class="layer_details__cancel"
            (click)="onChangeNameCancel()"
            nbTooltip="Cancel"
            nbTooltipStatus="primary"
            nbTooltipPlacement="bottom"
            data-testId="edit-name-cancel-btn"
          >
            <nb-icon class="layer_details__icon" icon="close"></nb-icon>
          </div>
          <div
            class="layer_details__confirm"
            (click)="onChangeNameConfirm()"
            data-testId="edit-name-confirm-btn"
            nbTooltip="Save changes"
            nbTooltipStatus="primary"
            nbTooltipPlacement="bottom"
          >
            <nb-icon class="layer_details__icon" icon="checkmark"></nb-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="layer_details__error select-none" *ngIf="isChangeNameActive && isEmpty()">
    Name cannot be empty
  </div>
  <div *ngIf="!isEditModeOnInitMappingTool" class="layer_details__created">
    Created {{ createdAt | date }}
  </div>
</div>
