<nb-form-field [formGroup]="formGroup" class="input-password">
  <nb-icon *ngIf="prefixIcon" nbPrefix [icon]="prefixIcon"></nb-icon>
  <input
    type="password"
    nbInput
    fullWidth
    [placeholder]="placeholder"
    name="password"
    [formControlName]="controlName"
    required
    minlength="6"
    [status]="error ? 'danger' : ''"
  />
</nb-form-field>
