/* eslint-disable max-lines */
import { AuthService } from '@App/app/auth/auth.service';
import { Endpoint } from '@App/app/entities/auth/endpoint.model';
import { ModelsTableFilterForm } from '@App/app/entities/forms/models-table-filter-form.model';
import { Model } from '@App/app/entities/models/model.model';
import { AdvancedSearchModel } from '@App/app/entities/shared/advanced-search.model';
import { NgxTableEvent } from '@App/app/entities/shared/ngx-table-event.model';
import { PageRangeInfo } from '@App/app/entities/shared/page-info.model';
import { ModelsTableFiltersService } from '@App/app/pages/sites/models-site/models-table-filters/models-table-filters.service';
import { BaseTableComponent } from '@App/app/shared/components/base-table/base-table.component';
import { MainDatatableComponent } from '@App/app/shared/components/main-datatable/main-datatable.component';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { ModelsService } from '../../../models/services/models-service/models.service';
import { AsPageRangeInfoPipe } from '../../shared/pipes/as-page-range-info/as-page-range-info.pipe';
import { ModelsSitesService } from '../../shared/site-services/models-sites.service';
import { ModelsView } from '../models/models-view.model';
import { ModelsContentService } from '../services/models-content-service/models-content.service';
import getColumns from './helpers/models-table-columns';

@UntilDestroy()
@Component({
  selector: 'app-models-table',
  templateUrl: './models-table.component.html',
  styleUrls: [
    './models-table.component.scss',
    '../../../../shared/components/base-table/base-table.component.scss',
  ],
})
export class ModelsTableComponent
  extends BaseTableComponent<
    Model,
    ModelsTableFilterForm,
    ModelsSitesService,
    AdvancedSearchModel,
    ModelsTableFiltersService
  >
  implements OnInit, OnDestroy, ModelsView {
  @ViewChild(MainDatatableComponent) datatable: MainDatatableComponent<Model>;
  @ViewChild('typeTpl', { static: true }) typeTpl: TemplateRef<any>;
  @ViewChild('editedTpl', { static: true }) editedTpl: TemplateRef<any>;
  @ViewChild('stateTpl', { static: true }) stateTpl: TemplateRef<any>;
  @ViewChild('nameTpl', { static: true }) nameTpl: TemplateRef<any>;
  @ViewChild('zipTpl', { static: true }) zipTpl: TemplateRef<any>;
  @ViewChild('actionsTpl', { static: true }) actionsTpl: TemplateRef<any>;
  @ViewChild('renderTpl', { static: true }) renderTpl: TemplateRef<any>;
  @ViewChild('createdAtTpl', { static: true }) createdAtTpl: TemplateRef<any>;
  @ViewChild('selectTpl', { static: true }) selectTpl: TemplateRef<any>;
  @ViewChild('selectAllTpl', { static: true }) selectAllTpl: TemplateRef<any>;
  loading$: Observable<boolean>;
  showStatisticsButton$: Observable<boolean>;
  showTable = true;

  constructor(
    sitesService: ModelsSitesService,
    authService: AuthService,
    router: Router,
    store: Store,
    modelsTableFiltersService: ModelsTableFiltersService,
    cdr: ChangeDetectorRef,
    private _modelsService: ModelsService,
    private _modelsContentService: ModelsContentService,
    private _asPageRangeInfoPipe: AsPageRangeInfoPipe,
  ) {
    super(authService, router, store, cdr, sitesService, modelsTableFiltersService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sitesService?.selectedEntities.pipe(untilDestroyed(this)).subscribe((models) => {
      this.selectedRows = [...models];
    });

    this._modelsContentService.filteredModels$.pipe(untilDestroyed(this)).subscribe((models) => {
      this.setEntities(models);
      this.loading = false;
    });

    this.sitesService?.displayingMode$.pipe(untilDestroyed(this), skip(1)).subscribe(() => {
      /**
       * Workaround
       *
       * sometimes when switching between models' views
       * the datatable doesn't show up correctly (columns' widths are crashed).
       * Seems like below's approach fixes this issue
       */
      this.showTable = false;
      this.cdr.detectChanges();
      this.showTable = true;
      this.cdr.detectChanges();
      this.datatable.table.columnMode = 'flex';
    });

    this.loading$ = this._modelsContentService.loading$;
    this.showStatisticsButton$ = this._modelsContentService.showStatisticsButton$;
  }

  ngOnDestroy(): void {
    this.sitesService?.setSelectedEntities([]);
  }

  deleteHandler(event: Event, model: Model) {
    event.stopPropagation();
    this._modelsService.deleteSingleModelHandler(model);
  }

  archiveHandler(event: Event, model: Model) {
    event.stopPropagation();
    this.sitesService?.loading.next(true);
    this._modelsService.archiveModel(model);
  }

  onActivate(event: NgxTableEvent<Model>) {
    if (event.type === 'click' && !event.column.checkboxable) {
      event.cellElement?.blur();
      this._modelsContentService.companyId$.pipe(take(1)).subscribe((companyId) => {
        this.router.navigate([`${companyId}/models`, event.row.id]);
      });
    }
  }

  onSelectRow({ selected }: NgxTableEvent<Model>) {
    if (selected) {
      this.selectedRows = selected;
      this.sitesService?.setSelectedEntities(this.selectedRows);
    }
  }

  getPageRangeInfo(): PageRangeInfo | null {
    return this._asPageRangeInfoPipe.transform(this.page);
  }

  protected initColumns(): void {
    this.columns = getColumns(
      this.selectAllTpl,
      this.selectTpl,
      this.nameTpl,
      this.typeTpl,
      this.stateTpl,
      this.editedTpl,
      this.createdAtTpl,
      this.renderTpl,
      this.actionsTpl,
    );
    if (this.filtersService) {
      this.filtersService.modelColumns = this.columns;
    }
  }

  protected setEntities(entities: Model[]) {
    this.entities = [...entities];
    this.rows = [...this.entities];

    if (this.table) {
      this.table.offset = 0;
    }

    if (this.table?.limit) {
      this.setPage({
        count: this.rows?.length,
        pageSize: this.table.pageSize,
        limit: this.table.limit,
        offset: this.table.offset,
      });
    }
  }

  protected updateFilters(_: AdvancedSearchModel) {}

  protected onEndpointChange(_: Endpoint): void {}
}
