/* eslint-disable complexity */
import { selectUiSettings } from '@App/app/auth/store/auth/selectors/auth.selectors';
import { GREY_COLOR, WHITE_COLOR } from '@App/app/configs/babylon.config';
import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { ViewerCameraPointsService } from '@App/app/engine/services/viewer-camera-point-service/viewer-camera-point.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { SelectPresetForm } from '@App/app/entities/forms/custom-preset-form.model';
import { UiSettings } from '@App/app/entities/users/user';
import { IPreset } from '@App/app/entities/viewer/custom-preset.model';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Color4 } from 'babylonjs';
import { filter, map } from 'rxjs/operators';
import {
  deletePreset,
  loadAllPresets,
  updateCurrentBGColor,
  updateCurrentVFColor,
} from '../../store/color-presets-store/actions/ui-settings.actions';
import { selectAllPresets } from '../../store/color-presets-store/selectors/ui-settings.selectors';
@UntilDestroy()
@Component({
  selector: 'app-color-picker-presets',
  templateUrl: './color-picker-presets.component.html',
  styleUrls: ['./color-picker-presets.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ColorPickerPresetsComponent implements OnInit {
  @Output() setBackgroundPreset: EventEmitter<string> = new EventEmitter();
  @Output() setViewfinderPreset: EventEmitter<string> = new EventEmitter();
  colorList: IPreset[];
  selectPresetForm: FormGroup<SelectPresetForm>;
  permissions = UserPermissions;

  constructor(
    private sceneService: SceneService,
    private viewerCameraPointsService: ViewerCameraPointsService,
    private store: Store,
    private fb: FormBuilder,
    private ref: ElementRef,
  ) {}

  ngOnInit() {
    this.store
      .select(selectUiSettings)
      .pipe(filter<UiSettings>(Boolean))
      .pipe(untilDestroyed(this))
      .subscribe((uiSettings) => {
        this.sceneService.sceneBackgroundColor = Color4.FromHexString(
          uiSettings.currentPreset?.backgroundColor || GREY_COLOR,
        );
        this.viewerCameraPointsService.photoMeshColor = Color4.FromHexString(
          uiSettings.currentPreset?.viewFinderColor || WHITE_COLOR,
        );
      });
    this.selectPresetForm = this.fb.group<SelectPresetForm>({
      selectedPreset: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
    this.store.dispatch(loadAllPresets());

    this.store
      .select(selectAllPresets)
      .pipe(filter<IPreset[]>(Boolean))
      .subscribe((items) => {
        this.colorList = [...items];
      });

    this.selectPresetForm.controls.selectedPreset.valueChanges
      .pipe(
        map((val) => this.colorList.find((item) => val === item.name)),
        filter<IPreset>(Boolean),
      )
      .subscribe((preset) => {
        this.sceneService.changeSceneColor(
          Color4.FromHexString(preset.backgroundColor || GREY_COLOR),
        );
        this.viewerCameraPointsService.photoMeshColor = Color4.FromHexString(
          preset.viewFinderColor || WHITE_COLOR,
        );
        this.viewerCameraPointsService.cameraMeshColor.next(preset.viewFinderColor || WHITE_COLOR);
        this.setPresetBackgroundCol(preset.backgroundColor || GREY_COLOR);
        this.setPresetViewfinderCol(preset.viewFinderColor || WHITE_COLOR);
      });

    this.overrideNebularOptionStylesOnInit();
  }

  onDeletePreset(name: string) {
    this.selectPresetForm.reset();
    this.store.dispatch(deletePreset({ name }));
    this.ngOnInit();
  }

  private setPresetBackgroundCol(value: string) {
    this.setBackgroundPreset.emit(value);
    setTimeout(() => {
      this.store.dispatch(updateCurrentBGColor({ backgroundColor: value }));
    });
  }

  private setPresetViewfinderCol(value: string) {
    this.setViewfinderPreset.emit(value);
    setTimeout(() => {
      this.store.dispatch(updateCurrentVFColor({ viewFinderColor: value }));
    }, 500);
  }

  private overrideNebularOptionStylesOnInit() {
    setTimeout(() => {
      this.ref.nativeElement
        .querySelector('.select_preset .placeholder')
        ?.style.setProperty('min-width', 'unset', 'important');
    });
  }
}
