import { getAngleIronDimensions } from '@App/app/engine/helpers/rectangle-tools-helpers';
import { AngleIron } from '@App/app/entities/layer/angle-iron.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'angleIronDimensions',
  pure: false,
})
export class AngleIronDimensionsPipe implements PipeTransform {
  transform(layer: AngleIron) {
    const { boxes } = layer.data;
    return boxes?.length ? getAngleIronDimensions(boxes) : null;
  }
}
