import { LAYER_NAMES } from '../entities/layer/enums/layer-names.enum';
import { LayerButton } from '../entities/viewer/layer-button.model';
import { TOOL_TUTORIALS } from './hints.config';

const angleIronIcon = '../../../assets/svg/icons/sf_portal-ui_icon_angle_iron.svg';
const coordsIcon = '../../../assets/svg/icons/sf_portal-ui_icon_axis.svg';
const planeIcon = '../../../assets/svg/icons/sf_portal-ui_icon_layers_plane.svg#Layer_1';
const rectangularTubeIcon = '../../../assets/svg/icons/sf_portal-ui_icon_layers_rect_tube.svg';
const annotationIcon = '../../../assets/svg/icons/sf_portal-ui_icon_layers_annotation.svg';
const tubePickerIcon = '../../../assets/svg/icons/tube-picker.svg';
const tubeIcon = '../../../assets/svg/icons/tube.svg';
const cuboidIcon = '../../../assets/svg/icons/cuboid.svg';
const cuboidPickerIcon = '../../../assets/svg/icons/cuboid-picker.svg';
export const CIRCLE_ICON = '../../../assets/svg/icons/sf_portal-ui_icon_tools_circle.svg#Layer_1';
export const LINE_ICON = '../../../assets/svg/icons/sf_portal-ui_icon_tools_line.svg#Layer_1';
export const RECTANGLE_ICON = '../../../assets/svg/icons/sf_portal-ui_icon_tools_rectangle.svg';

export const BUTTON_LAYERS_CONFIG: LayerButton[] = [
  {
    description: TOOL_TUTORIALS.ADD_MEASUREMENT.description,
    icon: 'expand',
    dataTestId: 'measurement_btn',
    toolName: LAYER_NAMES.MEASUREMENT,
    iconType: 'nb',
  },
  {
    description: TOOL_TUTORIALS.ADD_POLYGON.description,
    icon: 'square',
    dataTestId: 'polygon_btn',
    toolName: LAYER_NAMES.AREA_POLYGON,
    iconType: 'nb',
  },
  {
    description: TOOL_TUTORIALS.ADD_DROP_LINE.description,
    icon: 'corner-right-down',
    dataTestId: 'drop_line_btn',
    toolName: LAYER_NAMES.DROP_LINE,
    iconType: 'nb',
  },
  {
    description: TOOL_TUTORIALS.ADD_SLICE_PLANE.description,
    icon: 'minus-outline',
    dataTestId: 'slice_plane_btn',
    toolName: LAYER_NAMES.SLICE_PLANE,
    iconType: 'nb',
  },
  {
    description: TOOL_TUTORIALS.ADD_COORDINATES_LAYER.description,
    icon: coordsIcon,
    dataTestId: 'coordinates_btn',
    toolName: LAYER_NAMES.COORDINATES_LAYER,
    iconType: 'svg',
  },
  {
    description: TOOL_TUTORIALS.ADD_SKETCH_PLANE.description,
    icon: planeIcon,
    dataTestId: 'create_plane',
    toolName: LAYER_NAMES.SKETCH_PLANE,
    iconType: 'svg',
  },
  {
    description: TOOL_TUTORIALS.ADD_TUBE_PICKER.description,
    icon: tubePickerIcon,
    dataTestId: 'tube_picker_btn',
    toolName: LAYER_NAMES.TUBE_PICKER,
    iconType: 'svg',
  },
  {
    description: TOOL_TUTORIALS.ADD_TUBE.description,
    icon: tubeIcon,
    dataTestId: 'tube_btn',
    toolName: LAYER_NAMES.TUBE,
    iconType: 'svg',
  },
  {
    description: TOOL_TUTORIALS.ADD_BOUNDING_BOX.description,
    icon: cuboidPickerIcon,
    dataTestId: 'bounding_box_btn',
    toolName: LAYER_NAMES.BOUNDING_BOX,
    iconType: 'svg',
  },
  {
    description: TOOL_TUTORIALS.ADD_CUBOID.description,
    icon: cuboidIcon,
    dataTestId: 'cuboid_btn',
    toolName: LAYER_NAMES.CUBOID,
    iconType: 'svg',
  },
  {
    description: TOOL_TUTORIALS.ADD_ANGLE_IRON.description,
    icon: angleIronIcon,
    dataTestId: 'angle-iron_btn',
    toolName: LAYER_NAMES.ANGLE_IRON,
    iconType: 'svg',
  },
  {
    description: TOOL_TUTORIALS.ADD_RECT_TUBE.description,
    icon: rectangularTubeIcon,
    dataTestId: 'rectangle_btn',
    toolName: LAYER_NAMES.RECT_TUBE,
    iconType: 'svg',
  },
  {
    description: TOOL_TUTORIALS.ADD_ANNOTATION.description,
    icon: annotationIcon,
    dataTestId: 'annotation',
    toolName: LAYER_NAMES.ANNOTATION,
    iconType: 'svg',
  },
];
