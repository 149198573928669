<nb-card status="basic" data-testId="overlay" class="photo-card" [nbSpinner]="downloading">
  <nb-card-body data-testId="photo-card" (click)="onClick(photo)">
    <div class="photo-card__checkmark" *ngIf="isSelected" data-testId="selection-mark">
      <nb-icon icon="checkmark-outline" status="success"></nb-icon>
    </div>
    <div
      class="photo-card__annotations-badge"
      *ngIf="photo | photoAnnotationsNumber as annotationsNumber"
    >
      <nb-icon icon="pricetags-outline"></nb-icon>
      <span>{{ annotationsNumber }}</span>
    </div>
    <img
      [ngClass]="{ '--dark': isSelected, '--dark-2': downloading }"
      [src]="photo | photoThumbnail"
      alt="site photo"
    />
    <div class="photo-card__label">
      <p>{{ photo.name | shortenName: 18 }}</p>
      <div>
        <nb-icon
          class="photo-card__icon"
          nbTooltip="Enlarge photo"
          data-testId="enlarge-photo"
          (click)="onEnlargePhoto($event, photo)"
          icon="image-outline"
        ></nb-icon>
        <nb-icon
          *ngxPermissionsOnly="permissions.CAN_DOWNLOAD_PHOTO"
          class="photo-card__icon"
          nbTooltip="Download"
          data-testId="download-photo"
          (click)="onDownload($event, photo)"
          icon="download-outline"
        ></nb-icon>
      </div>
    </div>
  </nb-card-body>
</nb-card>
