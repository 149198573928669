import { ActionReducer, ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';
import * as authActions from '../../app/auth/store/auth/actions/auth.actions';
import { authReducer } from '../auth/store/auth/reducers/auth.reducer';
import { modelFilesReducer } from '../pages/models/store/models/reducers/model-files.reducer';
import { modelsReducer } from '../pages/models/store/models/reducers/models.reducer';
import { rendersReducer } from '../pages/models/store/renders/reducers/renders.reducer';
import { processesReducer } from '../pages/processing/store/processes/reducers/processes.reducer';
import { filesReducer } from '../pages/uploader/store/files/reducers/files.reducer';
import { sonyAuthReducer } from '../pages/uploader/store/sony-auth/reducers/sony-auth.reducer';
import { sonyMissionsReducer } from '../pages/uploader/store/sony-missions/reducers/sony-missions.reducer';
import { usersReducer } from '../pages/users/store/reducers/users.reducer';
import { presetReducer } from '../pages/viewer/store/color-presets-store/reducers/ui-settings.reducers';
import { layersReducer } from '../pages/viewer/store/reducers/layers.reducer';
import { photosReducer } from '../shared/store/photos/reducer/photos.reducer';

export const rootReducer: ActionReducerMap<any> = {
  layers: layersReducer,
  photos: photosReducer,
  models: modelsReducer,
  modelFiles: modelFilesReducer,
  processes: processesReducer,
  renders: rendersReducer,
  auth: authReducer,
  users: usersReducer,
  files: filesReducer,
  presets: presetReducer,
  sonyAuth: sonyAuthReducer,
  sonyMissions: sonyMissionsReducer,
};

export const logout = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state, action) => {
    if (action !== null && action.type === authActions.removeAuthDataSuccess.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
};

export const metaReducers: MetaReducer[] = [logout];
