/* eslint-disable max-lines */

const totalAreaInput = () => {
  return {
    title: 'Total area',
    formControlName: 'totalArea',
    inputType: 'number',
  };
};

export const dataTypeParameters = {
  ATCT: {
    subTypeName: 'atct',
    params: [
      {
        title: 'Total height',
        formControlName: 'totalHeight',
        inputType: 'number',
      },
      {
        title: 'Shaft height',
        formControlName: 'shaftHeight',
        inputType: 'number',
      },
      totalAreaInput(),
      {
        title: 'Cab area',
        formControlName: 'cabArea',
        inputType: 'number',
      },
      {
        title: 'Base area',
        formControlName: 'baseArea',
        inputType: 'number',
      },
    ],
    types: {
      VRF_TOWER: 'vfr-tower',
      NRA_TOWER: 'nra-tower',
      RA_TOWER: 'ra-tower',
    },
  },

  CELL_TOWER: {
    subTypeName: 'cell-tower',
    params: [
      {
        title: 'Top steel',
        formControlName: 'topSteel',
        inputType: 'number',
      },
      {
        title: 'Lightning rod',
        formControlName: 'lightningRod',
        inputType: 'number',
      },
      {
        title: 'Highest Appurtenance',
        formControlName: 'highestAppurtenance',
        inputType: 'number',
      },
      {
        title: 'Rad Centers',
        formControlName: 'radCenters',
        isRadCenterInput: true,
      },
    ],
    types: {
      MONOPOLE: 'monopole',
      SELF_SUPPORT: 'self-support',
      GUYED: 'guyed',
    },
  },

  POWER_LINE: {
    subTypeName: 'power-line',
    params: [
      {
        title: 'Height',
        formControlName: 'height',
        inputType: 'number',
      },
      {
        title: 'Line Length',
        formControlName: 'lineLength',
        inputType: 'number',
      },
      totalAreaInput(),
    ],
    types: {
      HIGH_VOLTAGE: 'high-voltage',
      MEDIUM_VOLTAGE: 'medium-voltage',
      LOW_VOLTAGE: 'low-voltage',
    },
  },
  BRIDGE: {
    subTypeName: 'bridge',
    params: [
      {
        title: 'Length',
        formControlName: 'length',
        inputType: 'number',
      },
      {
        title: 'Width',
        formControlName: 'width',
        inputType: 'number',
      },
      totalAreaInput(),
      {
        title: 'Paved area',
        formControlName: 'pavedArea',
        inputType: 'number',
      },
      {
        title: 'Parapets length',
        formControlName: 'parapetsLength',
        inputType: 'number',
      },
      {
        title: 'Edge Beam length',
        formControlName: 'edgeBeamLength',
        inputType: 'number',
      },
      {
        title: 'Bearings',
        formControlName: 'bearings',
        inputType: 'number',
      },
    ],
    types: {
      SMALL_SPAN: 'small-span',
      MEDIUM_SPAN: 'medium-span',
      LONG_SPAN: 'long-span',
    },
  },
  ROADWAY: {
    subTypeName: 'roadway',
    params: [
      {
        title: 'Length',
        formControlName: 'length',
        inputType: 'number',
      },
      {
        title: 'Total width',
        formControlName: 'totalWidth',
        inputType: 'number',
      },
      {
        title: 'Paved width',
        formControlName: 'pavedWidth',
        inputType: 'number',
      },
      {
        title: 'Traffic lines',
        formControlName: 'trafficLines',
        inputType: 'number',
      },
      {
        title: 'Lights distance',
        formControlName: 'lightsDistance',
        inputType: 'number',
      },
    ],
    types: {
      HIGHWAY: 'highway',
      PRIMARY: 'primary',
      SECONDARY: 'secondary',
      TERTIARY: 'tertiary',
      RESIDENTIAL: 'residential',
    },
  },

  PIPELINE: {
    subTypeName: 'pipeline',
    params: [
      {
        title: 'Total length',
        formControlName: 'totalLength',
        inputType: 'number',
      },
      {
        title: 'Section length',
        formControlName: 'sectionLength',
        inputType: 'number',
      },
      {
        title: 'Diameter',
        formControlName: 'diameter',
        inputType: 'number',
      },
      {
        title: 'Wall thickness',
        formControlName: 'wallThickness',
        inputType: 'number',
      },
      {
        title: 'Steel grade',
        formControlName: 'steelGrade',
        inputType: 'number',
      },
      {
        title: 'Pressure',
        formControlName: 'pressure',
        inputType: 'number',
      },
    ],
    types: {
      OIL: 'oil',
      GAS: 'gas',
      WATER: 'water',
    },
  },
  BUILDING: {
    subTypeName: 'building',
    params: [
      {
        title: 'Total height',
        formControlName: 'totalHeight',
        inputType: 'number',
      },
      {
        title: 'Total floors',
        formControlName: 'totalFloors',
        inputType: 'number',
      },
      totalAreaInput(),
    ],
    types: {
      RESIDENTIAL: 'residential',
      COMMERCIAL: 'commercial',
      INDUSTRIAL: 'industrial',
      PUBLIC: 'public',
    },
  },

  GENERIC: {
    subTypeName: 'generic',
    params: [
      totalAreaInput(),
      {
        title: 'Length',
        formControlName: 'length',
        inputType: 'number',
      },
      {
        title: 'Width',
        formControlName: 'width',
        inputType: 'number',
      },
      {
        title: 'Height',
        formControlName: 'height',
        inputType: 'number',
      },
    ],
    types: {
      GENERIC: 'generic',
    },
  },
};
