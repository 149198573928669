import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { ProdLog } from '@App/app/entities/files/files.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReviewTabComponent } from '../base-tabs/review-tab.component';

@UntilDestroy()
@Component({
  selector: 'app-prod-review-tab',
  templateUrl: './prod-review-tab.component.html',
  styleUrls: ['./prod-review-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProdReviewTabComponent extends ReviewTabComponent implements OnInit {
  protected retryPermission = UserPermissions.CAN_RETRY_PRODUCTION;
  protected acceptPermission = UserPermissions.CAN_ACCEPT_PRODUCTION;
  protected cancelStep = ProcessingSteps.PROD_SETUP;
  protected continueStep = ProcessingSteps.PP_SETUP;
  log: ProdLog;

  protected getLogFromProcess(process: BuildProcess) {
    return process.prodLog;
  }
}
