import { VIEWER_LOD_CONFIG } from '@App/app/configs/viewer.config';
import { LoadModelService } from '@App/app/engine/services/load-model-service/load-model.service';
import { clearLoaderResult } from '@App/app/engine/utils/babylon-utils/babylon.utils';
import { Injectable } from '@angular/core';
import { AssetContainer, Mesh } from 'babylonjs';
import { Subject } from 'rxjs';
import { LODTileInfo } from '../../models/lod-tile-info.model';
import { Tile } from '../../models/tile.model';

@Injectable()
export class CustomLodCacheService {
  private _tiles: Tile[] = [];
  private _maxCacheSizeInMB = VIEWER_LOD_CONFIG.CACHE_LIMIT;
  private _totalCacheSize$ = new Subject<number>();
  totalCacheSize$ = this._totalCacheSize$.asObservable();

  constructor(private _loadModelService: LoadModelService) {}

  initializeFromLODTileInfos(infos: LODTileInfo[], assetContainer: AssetContainer | null) {
    for (const info of infos) {
      this.addTile({
        url: info.getCurrentDisplayingTile(),
        meshes: info.getMeshes(),
        sizeInMB: 0,
      });
    }
    this.recalculate(assetContainer);
  }

  addTile(tile: Tile) {
    this._tiles.push(tile);
    this._totalCacheSize$.next(this._recalculateTotalCacheSize());
  }

  findByUrl(url: string): Tile | undefined {
    return this._tiles.find((t) => t.url === url);
  }

  recalculate(assetContainer: AssetContainer | null) {
    for (const cachedTile of [...this._tiles]) {
      const isBeingUsed = this._isTileBeingUsed(cachedTile);
      if (isBeingUsed) {
        continue;
      }

      if (this._recalculateTotalCacheSize() > this._maxCacheSizeInMB) {
        this._removeTileFromAssetContainerIfNeeded(cachedTile, assetContainer);
        this._disposeMeshesFromLoadedModel(cachedTile);
        this._removeTile(cachedTile);
      }

      if (this._recalculateTotalCacheSize() <= this._maxCacheSizeInMB) {
        break;
      }
    }
  }

  cacheMesh(mesh: Mesh) {
    this._setMeshAsCached(mesh, true);
  }

  uncacheMesh(mesh: Mesh) {
    this._setMeshAsCached(mesh, false);
  }

  clear() {
    this._tiles = [];
  }

  private _setMeshAsCached(mesh: Mesh, isCached: boolean) {
    mesh.isVisible = !isCached;
    /**
     * this name updating is not needed, but it's
     * easier to identify the meshes in Babylon's
     * debug layer
     */
    mesh.name = isCached ? `${mesh.name}--cached` : mesh.name.replace('--cached', '');
  }

  private _recalculateTotalCacheSize() {
    return this._tiles.reduce((total, tile) => total + tile.sizeInMB, 0);
  }

  private _isTileBeingUsed(tile: Tile) {
    return tile.meshes.some((m) => m.isVisible);
  }

  private _removeTileFromAssetContainerIfNeeded(tile: Tile, assetContainer: AssetContainer | null) {
    if (!assetContainer) {
      return;
    }
    for (const mesh of tile.meshes) {
      const index = assetContainer.meshes.indexOf(mesh);
      if (index === -1) continue;
      assetContainer.meshes.splice(index, 1);
    }
  }

  private _disposeMeshesFromLoadedModel(tile: Tile) {
    const index = this._loadModelService.model.findIndex((i) =>
      tile.meshes.some((m) => i.meshes.includes(m)),
    );
    if (index === -1) {
      return;
    }
    const res = this._loadModelService.model.splice(index, 1)[0];
    clearLoaderResult(res);
  }

  private _removeTile(tile: Tile) {
    this._tiles.splice(this._tiles.indexOf(tile), 1);
    this._totalCacheSize$.next(this._recalculateTotalCacheSize());
  }
}
