<div
  class="layer layers__item --expandable"
  [ngClass]="classes"
  (click)="onClick($event)"
  [id]="'layer' + layer.id"
  data-testId="layer-expandable"
>
  <div class="layer__highlight"></div>
  <ng-container *ngIf="showExpandableArrow">
    <div
      class="layer__expand"
      *ngIf="!layer.open && !layerPath.length"
      data-testId="layer-expandable-expand-icon"
      (click)="onExpandLayer($event)"
    >
      <nb-icon class="layer__toggle" icon="arrow-right"></nb-icon>
    </div>
    <div
      class="layer__expand"
      *ngIf="layer.open"
      data-testId="layer-expandable-expand-icon"
      (click)="onExpandLayer($event)"
    >
      <nb-icon class="layer__toggle" icon="arrow-down"></nb-icon>
    </div>
  </ng-container>
  <div class="layer__type">
    <svg-icon
      *ngIf="layer.type === layerTypes.SKETCH_PLANES"
      [src]="planeIcon"
      svgClass="layer__svg-icon"
    ></svg-icon>
  </div>
  <div class="layer__name select-none">{{ layer.name }}</div>
  <ng-container *ngIf="!layerPath.length">
    <div
      *ngIf="layer.isVisible"
      class="layer__show"
      data-testId="set-camera-btn"
      (click)="onSetCameraTarget($event)"
    >
      <nb-icon class="layer__icon" icon="video"></nb-icon>
    </div>
    <div
      class="layer__details"
      (click)="onEditClick($event)"
      data-testId="layer-expandable-edit-icon"
    >
      <nb-icon class="layer__icon" icon="info-outline"></nb-icon>
    </div>
  </ng-container>
  <div class="layer__select" (click)="onSelect($event)" data-testId="layer-expandable-show-icon">
    <nb-icon icon="eye" class="layer__icon"></nb-icon>
  </div>
  <app-layer-path [path]="layerPath" (clickPath)="onClickLayerPath()"></app-layer-path>
</div>
<ng-container *ngIf="layer.open">
  <div class="layers__empty layers__static" *ngIf="!childLayers.length">Empty</div>
  <ng-container *ngFor="let sketchTool of childLayers">
    <app-layer
      [layer]="sketchTool"
      [classes]="{
        '--visible': sketchTool.isVisible,
        '--with-left-border': true
      }"
      [style.padding-left]="'20px'"
      (toggleLayerVisibility)="onChildSelect(sketchTool)"
      data-testId="child-layer"
    ></app-layer>
  </ng-container>
</ng-container>
