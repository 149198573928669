export interface DateRange {
  start: Date;
  end: Date;
}

export class BaseAdvancedSearch {
  query: string | null;
}

export class AdvancedSearchModel extends BaseAdvancedSearch {
  dateFlown: DateRange | null;
  dateProcessed: DateRange | null;
  pilot: number | null;
  type: {
    [name: string]: boolean | null;
  };
}

export class AdvancedSearchProcessing extends BaseAdvancedSearch {
  query: string;
  dateStart: DateRange;
  dateEnd: DateRange;
  subscription: {
    [name: string]: boolean;
  };
  type: {
    [name: string]: boolean;
  };
  status: {
    [name: string]: boolean;
  };
}

export class AdvancedSearchUsers extends BaseAdvancedSearch {
  role: {
    [name: string]: boolean | null;
  };
}
