<app-manual-tool-layer-details
  *ngIf="activeLayerEvent === LAYER_EVENTS.ADD_RECT_TUBE && isDetailsVisible"
  [samples]="rectTubeSamples"
  [partName]="RECT_TUBE_PART_NAME"
  [sampleHighlighted]="rectTubeHighlightedSample"
  [generateAvailable]="isGenerateAvailable"
  [activePart]="rectTubeActivePart"
  (setPartActive)="setRectTubeActivePart($event)"
  (highlightSample)="toggleHighlightRectTubeSample($event)"
  (deleteSample)="onDeleteRectTubeSample($event)"
  (generate)="onGenerate()"
  (cancel)="onCancel()"
>
</app-manual-tool-layer-details>
