import { EmptyOption } from '@App/app/configs/processing-settings-preset.config';
import { IProcessingSettings } from '@App/app/entities/processing/processing-preset-settings.model';
import { ProcessingsPresetName } from '../../tabs/at-setup-tab/models/processing-preset.enum';
import {
  ProdSettingsDescription,
  ProdSetupGroupNames,
} from '../../tabs/at-setup-tab/models/prod-setup-tab.enum';

export const prodSettings = [
  {
    groupName: ProcessingsPresetName.SETTINGS_PRESET,
    settings: [
      {
        title: 'Select preset',
        formControlName: 'settingsPreset',
        inputType: 'select',
        options: [EmptyOption],
      },
    ],
  } as IProcessingSettings,
  {
    groupName: ProdSetupGroupNames.PRODUCTION_FORMATS,
    settings: [
      {
        title: 'Mesh',
        formControlName: 'withObj',
        inputType: 'checkbox',
        description: 'A portable 3D format (OBJ)',
      },
      {
        title: 'Point Cloud',
        formControlName: 'withPointCloud',
        inputType: 'checkbox',
        description: 'Public file format (LAS) for interchange of 3-dimensional point cloud data.',
      },
      {
        title: 'OrthoDSM',
        formControlName: 'withOrthoDSM',
        inputType: 'checkbox',
        description: 'Raster format interoperable with 2D GIS.',
      },
    ],
  },
  {
    groupName: ProdSetupGroupNames.PRODUCTION_SETTINGS,
    settings: [
      {
        title: 'Color equalization mode',
        formControlName: 'colorEqualizationMode',
        settingsDescription: ProdSettingsDescription.COLOR_EQUALIZATION_MODE,
        inputType: 'select',
        options: [
          { title: 'None', value: 'none' },
          { title: 'Standard', value: 'standard' },
          { title: 'Standard incl. thermal', value: 'standardIncludingThermal' },
          { title: 'Blockwise', value: 'blockwise' },
          { title: 'Blockwise incl. thermal', value: 'blockwiseIncludingThermal' },
        ],
      },
      {
        title: 'Geometry precision mode',
        formControlName: 'geometryPrecisionMode',
        settingsDescription: ProdSettingsDescription.GEOMETRY_PRECISION_MODE,
        inputType: 'select',
        options: [
          { title: 'Extra', value: 'extra' },
          { title: 'High', value: 'high' },
          { title: 'Medium', value: 'medium' },
          { title: 'Ultra', value: 'ultra' },
        ],
      },
      {
        title: 'Hole filling mode',
        formControlName: 'holeFillingMode',
        settingsDescription: ProdSettingsDescription.HOLE_FILLING_MODE,
        inputType: 'select',
        options: [
          { title: 'All holes', value: 'allHoles' },
          { title: 'Small holes', value: 'smallHoles' },
        ],
      },
      {
        title: 'Pair selection mode',
        formControlName: 'pairSelectionMode',
        settingsDescription: ProdSettingsDescription.PAIR_SELECTION_MODE,
        inputType: 'select',
        options: [
          { title: 'ROI', value: 'ROI' },
          { title: 'Structured aerial dataset', value: 'forStructuredAerialDataset' },
          { title: 'Generic', value: 'generic' },
        ],
      },
      {
        title: 'Untextured regions representation',
        formControlName: 'untexturedRegionsRepresentation',
        settingsDescription: ProdSettingsDescription.UNTEXTURED_REGIONS_REPRESENTATION,
        inputType: 'select',
        options: [
          { title: 'Inpainting completion', value: 'inpaintingCompletion' },
          { title: 'Uniform color', value: 'uniformColor' },
        ],
      },
    ],
  },
];
