import { TemplateRef } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';

export default (
  selectAllTpl: TemplateRef<any>,
  selectTpl: TemplateRef<any>,
  nameTpl: TemplateRef<any>,
  typeTpl: TemplateRef<any>,
  stateTpl: TemplateRef<any>,
  editedTpl: TemplateRef<any>,
  createdAtTpl: TemplateRef<any>,
  renderTpl: TemplateRef<any>,
  actionsTpl: TemplateRef<any>,
): TableColumn[] => [
  {
    sortable: false,
    resizeable: false,
    headerTemplate: selectAllTpl,
    flexGrow: 0.5,
    cellTemplate: selectTpl,
  },
  {
    prop: 'id',
    name: 'ID',
    cellClass: 'clickable',
    flexGrow: 0.5,
  },
  {
    prop: 'name',
    name: 'Name',
    cellTemplate: nameTpl,
    cellClass: 'clickable',
    flexGrow: 1.5,
  },
  {
    prop: 'modelType',
    name: 'Type',
    cellTemplate: typeTpl,
    cellClass: 'clickable',
    flexGrow: 1,
  },
  {
    prop: 'address.state',
    name: 'Address',
    cellTemplate: stateTpl,
    cellClass: 'clickable',
    flexGrow: 2,
  },
  {
    prop: 'editedAt',
    name: 'Last edited',
    cellTemplate: editedTpl,
    sortable: false,
    cellClass: 'clickable',
    flexGrow: 1,
  },
  {
    prop: 'createdAt',
    name: 'Created',
    cellTemplate: createdAtTpl,
    cellClass: 'clickable',
    flexGrow: 1,
  },
  {
    prop: 'renderCount',
    name: 'Renders',
    cellTemplate: renderTpl,
    cellClass: 'clickable',
    flexGrow: 0.6,
  },
  {
    name: '',
    cellTemplate: actionsTpl,
    flexGrow: 1,
    cellClass: 'action',
    sortable: false,
  },
];
