import { LayerDetailsEditForm } from '@App/app/entities/forms/layer-details-edit-form.model';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layer-details-properties',
  templateUrl: './layer-details-properties.component.html',
})
export class LayerDetailsPropertiesComponent {
  @Input() layer: LayerUI;
  @Input() editForm: LayerDetailsEditForm;
  LAYER_TYPES = LAYER_TYPES;
}
