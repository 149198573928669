<div class="engine-wrapper">
  <canvas
    #rendererCanvas
    id="renderCanvas"
    [ngClass]="{ 'pointer-active': activeLayerEvent || (tiePointsModeActive$ | async) }"
  ></canvas>

  <div *ngIf="lostContext$ | async" class="disabling_overlay --with-backdrop">
    Lost context, please reload the page
  </div>

  <ng-container *ngIf="allowToShowModelPlaceholder$ | async">
    <div *ngIf="modelIsNotAvailable" class="disabling_overlay --sidebar-open">
      {{ modelIsNotAvailable }}
    </div>
  </ng-container>

  <ngx-spinner
    bdColor="rgba(33, 33, 33, 0.8)"
    [zIndex]="10"
    size="large"
    color="#fff"
    type="ball-clip-rotate"
    data-testId="loader"
    [fullScreen]="false"
  >
    <div style="text-align: center;" [ngStyle]="{ 'margin-top': loadingText ? '5.2rem' : null }">
      <p style="color: #fff;" data-testId="loader-progress">{{ loadedPercent }}%</p>
      <p *ngIf="loadingText" style="color: #fff; display: block; margin-top: 3rem;">
        {{ loadingText }}
      </p>
    </div>
  </ngx-spinner>

  <ngx-spinner
    name="gcp-grid"
    bdColor="rgba(33, 33, 33, 0.8)"
    [zIndex]="10"
    size="large"
    color="#fff"
    type="ball-clip-rotate"
    data-testId="loader"
    [fullScreen]="false"
  ></ngx-spinner>
</div>

<ng-template #loadingError let-ref="dialogRef" let-context>
  <nb-card class="loading_error_dialog">
    <nb-card-body>{{ context?.loadingErrorMessage || 'There is no model to show.' }}</nb-card-body>
    <nb-card-footer>
      <button nbButton size="tiny" status="info" (click)="prevPage()">BACK</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
