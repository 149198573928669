import { Injectable } from '@angular/core';
import { TOOL_TUTORIALS } from '@App/app/configs/hints.config';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HintsService {
  private _hintsActive$ = new BehaviorSubject<boolean>(false);
  public hintsActive$ = this._hintsActive$.asObservable();

  private _currentHint$ = new BehaviorSubject<string | null>(null);
  public currentHint$ = this._currentHint$.asObservable();

  constructor(private layerEventsService: LayerEventsService) {
    this.layerEventsService.activeLayerEvent.subscribe((evt) => {
      this._currentHint$.next(evt in TOOL_TUTORIALS ? (TOOL_TUTORIALS as any)[evt] : null);
    });
  }

  toggleHints() {
    this._hintsActive$.next(!this._hintsActive$.value);
  }
}
