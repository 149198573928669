<nb-card class="recovery-card">
  <nb-card-body>
    <form
      class="recovery-form"
      [formGroup]="passwordResetForm"
      (ngSubmit)="onSubmit(passwordResetForm)"
    >
      <app-input-email
        [error]="!!error"
        controlName="email"
        [formGroup]="passwordResetForm"
      ></app-input-email>
      <div class="recovery-error" *ngIf="error">
        {{ error }}
      </div>
      <button
        type="submit"
        nbButton
        status="primary"
        [disabled]="!passwordResetForm.valid || loading"
      >
        Reset password
      </button>
    </form>
    <a [routerLink]="['/login']">Back to login page</a>
  </nb-card-body>
</nb-card>

<ng-template #dialog let-ref="dialogRef">
  <nb-card class="recovery-dialog">
    <nb-card-header>Password recovery</nb-card-header>
    <nb-card-body
      >You will receive a message with a confirmation code to the e-mail address provided. In the
      next step you will be asked to enter it to set a new password for your account.</nb-card-body
    >
    <nb-card-footer>
      <button nbButton status="primary" (click)="nextStep(ref)">Next step</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
