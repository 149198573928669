import {
  DRAFT_SKETCH_RECTANGLE_TAG_NAME,
  SKETCH_RECTANGLE_NAME,
} from '@App/app/configs/babylon.config';
import { getVertexPositions } from '@App/app/engine/utils/babylon-utils/babylon.utils';
import { httpLayer } from '@App/app/entities/layer/layer.model';
import { SketchRectangle } from '@App/app/entities/layer/sketch-tools/tools/sketch-rectangle.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { AbstractMesh, ActionManager, PickingInfo, TransformNode, Vector3 } from 'babylonjs';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../../material-service/material.service';
import { SceneService } from '../../../scene-service/scene.service';
import { UtilsService } from '../../../utils-service/utils.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';
import { SketchLineMeshUtilsService } from '../shared/sketch-line-mesh-utils.service';
import { SketchToolService } from '../shared/sketch-tool.service';
import { SketchesUtilsService } from '../shared/sketches-utils.service';
import { SketchRectangleInitializerService } from './sketch-rectangle-initializer.service';
import { SketchRectangleUtilsService } from './sketch-rectangle-utils.service';

@Injectable()
export class SketchRectangleService extends SketchToolService<SketchRectangle> {
  private _boxId = 0;
  private _draftRoot: TransformNode | null;
  handleTakingEvent = EVENT_TYPE.TAKE_SKETCH_RECTANGLE;

  constructor(
    private _sketchLineMeshUtilsService: SketchLineMeshUtilsService,
    private _sketchRectangleUtilsService: SketchRectangleUtilsService,
    private _sketchRectangleInitializer: SketchRectangleInitializerService,
    sketchesUtilsService: SketchesUtilsService,
    broadcastService: BroadcastService,
    utilsService: UtilsService,
    materialService: MaterialService,
    babylonNodesService: BabylonNodesService,
    sceneService: SceneService,
    viewerLayerService: ViewerLayerService,
  ) {
    super(
      broadcastService,
      materialService,
      sketchesUtilsService,
      utilsService,
      babylonNodesService,
      sceneService,
      viewerLayerService,
    );
  }

  setMeshesDragBehavior(rect: SketchRectangle) {
    if (this.currentSketchPlane?.normal) {
      const updateRectangleVertices = () => {
        this._sketchRectangleUtilsService.updateRectangleVertices(rect, this.currentSketchPlane);
      };
      const dragBehavior = this.setDragBehavior(this.currentSketchPlane.normal);
      dragBehavior.onDragObservable.add(() => updateRectangleVertices());
      dragBehavior.onDragEndObservable.add(() => {
        updateRectangleVertices();
        rect.data.vertexPositions = getVertexPositions(rect.pointsMeshes);
      });
      rect.pointsMeshes.forEach((pointMesh) => {
        pointMesh.actionManager = new ActionManager(this.sceneService.scene);
        const { dragMeshMaterial } = this.materialService;
        const pick = this.onPickAction(dragBehavior, dragMeshMaterial, this.draftSketches);
        pointMesh.actionManager.registerAction(pick);
      });
    }
  }

  getDraftSketches(): SketchRectangle[] {
    const result: httpLayer[] = [];
    if (this.draftSketches?.length) {
      this.draftSketches.forEach((sketchRectangle) => {
        result.push(SketchRectangle.createBodyForHttpRequest(sketchRectangle));
      });
      this.sketchesUtilsService.disposeDraftSketchMeshesByTag(DRAFT_SKETCH_RECTANGLE_TAG_NAME);
      this.draftSketches = [];
      this.currentSketchPlane = null;
      this.removeDraftSketch();
    }
    return result as SketchRectangle[];
  }

  removeDraftSketch() {
    const [sketch, sketches, root] = [this.draftSketch, this.draftSketches, this._draftRoot];
    const result = this._sketchRectangleUtilsService.removeDraftSketch(sketch, sketches, root);
    [this.draftSketch, this.draftSketches, this._draftRoot] = result;
  }

  removeUnfinishedDraftSketch() {
    if (this.draftSketch) {
      this.sketchesUtilsService.deleteDraftSketch(this.draftSketch);
      this.draftSketch = null;
    }
  }

  finishSketch(): void {
    if (this.draftSketch?.pointsMeshes && this.draftSketch.pointsMeshes.length >= 4) {
      const hit = this.utilsService.pickRay(this.predicate);
      if (hit) {
        this._sketchRectangleUtilsService.finishRectangle(this.draftSketch, this._draftRoot);
        this.removePointerMeshEventListener();
        this.draftSketches.push({ ...this.draftSketch });
        this.setMeshesDragBehavior(this.draftSketch);
        this.draftSketch = null;
      }
    }
  }

  handleTakingSketch(): void {
    this.removeUnfinishedDraftSketch();
  }

  // eslint-disable-next-line complexity
  handleMouseMove = () => {
    const hit = this.utilsService.pickRay(this.predicate);
    if (hit?.pickedPoint && this.draftSketch?.pointsMeshes) {
      this.pointer.position = hit.pickedPoint;
      if (this.draftSketch.pointsMeshes.length >= 1) {
        const [sketch, pointer] = [this.draftSketch, this.pointer];
        this.draftSketch.linesMesh = this._sketchLineMeshUtilsService.updateLine(sketch, pointer);
      }
      if (this.draftSketch?.pointsMeshes.length > 2 && this.currentSketchPlane?.normal) {
        const [rect, plane, point] = [this.draftSketch, this.currentSketchPlane, hit.pickedPoint];
        this._sketchRectangleUtilsService.updateRectangleByPoint(rect, point, plane);
      }
    }
  };

  handleClickAction(hit: PickingInfo) {
    if (!this.draftSketch) {
      const result = this._sketchRectangleInitializer.initDraftSketchRectangleByHit(
        hit,
        this.draftSketch,
        this.currentSketchPlane,
        this._draftRoot,
        this._boxId,
        this.pointer,
        () => this.addPointerMeshEventListener(),
      );
      [this.draftSketch, this._draftRoot, this._boxId] = result;
    } else if (!this.draftSketch?.closed) {
      if (hit.pickedPoint) {
        this._closeRectangleByPoint(hit.pickedPoint);
      }
    } else {
      this.finishSketch();
    }
  }

  onClickAction(): boolean {
    if (!this.currentSketchPlane) {
      return false;
    }
    if (!this.pointer) {
      this.pointer = this.initPointer();
    }
    const hit = this.utilsService.pickRay(this.predicate);
    if (hit?.pickedPoint) {
      this.handleClickAction(hit);
    }
    return true;
  }

  showSketch(rect: SketchRectangle): SketchRectangle {
    this.sceneService.scene.getNodeByName(`${SKETCH_RECTANGLE_NAME}${rect.id}`)?.dispose();
    const rootName = `${SKETCH_RECTANGLE_NAME}${rect.id}`;
    const root = new AbstractMesh(rootName, this.sceneService.scene);
    root.id = `${rect.id}`;
    this._boxId = 0;
    if (rect.data.vertexPositions) {
      const [meshes, boxId] = this._sketchRectangleUtilsService.createPointMeshes(rect, 0, root);
      [rect.pointsMeshes, this._boxId] = [meshes, boxId];
    }
    rect.linesMesh = this._sketchLineMeshUtilsService.finishLine(rect);
    rect.linesMesh.parent = root;
    return rect;
  }

  private _closeRectangleByPoint(point: Vector3) {
    const [rect, root, boxId] = [this.draftSketch, this._draftRoot, this._boxId];
    this._boxId = this._sketchRectangleUtilsService.closeRectangleByPoint(rect, point, root, boxId);
    this.broadcastService.broadcast(EVENT_TYPE.REFRESH_CUSTOM_LOD, null);
  }
}
