import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbComponentStatus, NbDialogRef } from '@nebular/theme';
import { ConfirmationDialogService } from '../../services/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent implements OnInit {
  details: string;
  options: string[];
  selectedOption: string;
  title: string;
  status: NbComponentStatus = 'primary';
  useInnerHTML = false;
  showConfirmButton = true;
  shouldCancelUpload = false;

  keyword: string;
  keywordMode = false;
  keywordIsValid = false;

  constructor(
    protected dialogRef: NbDialogRef<ConfirmationDialogComponent>,
    private confirmationDialog: ConfirmationDialogService,
  ) {}

  ngOnInit(): void {
    if (this.options?.length) {
      this.selectedOption = this.options[0];
    }
  }

  onChangeInput(event: Event) {
    const { value } = event.target as HTMLInputElement;
    this.keywordIsValid = value === this.keyword;
  }

  onConfirm() {
    if (this.keywordMode && !this.keywordIsValid) {
      return;
    }
    if (this.options) {
      this.dialogRef.close(
        this.options.map((option) => option.localeCompare(this.selectedOption) === 0),
      );
    } else {
      this.dialogRef.close(true);
    }
    if (this.shouldCancelUpload) {
      this.confirmationDialog.cancelUpload();
    }
  }

  onCancel() {
    if (this.options) {
      this.dialogRef.close(this.options.map(() => false));
    } else {
      this.dialogRef.close(false);
    }
  }
}
