import { Component, ViewEncapsulation } from '@angular/core';
import { AtSetupControlPointsService } from '../services/at-setup-control-points-service/at-setup-control-points.service';

@Component({
  selector: 'app-accuracy-input',
  templateUrl: './accuracy-input.component.html',
  styleUrls: ['./accuracy-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AccuracyInputComponent {
  constructor(private atSetupControlPointsService: AtSetupControlPointsService) {}

  getForm() {
    return this.atSetupControlPointsService.form;
  }

  isValid() {
    return !(this.getForm().controls.accuracy.touched && !this.getForm().controls.accuracy.valid);
  }
}
