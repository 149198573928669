import { ApiService } from '@App/app/shared/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExportsHttpService extends ApiService {
  postDxfExport(ids: number[]) {
    return this.http.post<boolean>(`${this.api}/export-dxf`, ids);
  }

  postObjExport(buildProcessId: number) {
    return this.http.post<boolean>(`${this.api}/export-obj`, { buildProcessId });
  }
}
