import { Injectable } from '@angular/core';
import { VERSION_TYPES } from '../entities/shared/version-types.enum';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private API = '';
  private APP = '';

  constructor() {}

  setVersion(env: VERSION_TYPES, value: string) {
    this[env] = value;
  }

  getVersion(env: VERSION_TYPES) {
    return this[env];
  }

  checkVersion(env: VERSION_TYPES, currentVersion: string) {
    const storedVersion = this.getVersion(env);
    if (storedVersion && storedVersion !== currentVersion) {
      console.error(`Skyportal API versions don't match: ${storedVersion} and ${currentVersion}.`);
    }
    this.setVersion(env, currentVersion);
    return true;
  }
}
