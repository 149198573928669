import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { AnalyticsService } from '@App/app/pages/sites/models-site/services/analytics-service/analytics.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-download-analytics-button',
  templateUrl: './download-analytics-button.component.html',
  styleUrls: ['./download-analytics-button.component.scss'],
})
export class DownloadAnalyticsButtonComponent implements OnInit {
  @Output() exportAnalytics = new EventEmitter<void>();
  permissions = UserPermissions;
  constructor(private _analyticsService: AnalyticsService) {}

  ngOnInit() {}

  downloadAnalytics() {
    this._analyticsService.export();
  }
}
