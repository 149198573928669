import { Mesh } from 'babylonjs';
import { User } from '../users/user';
import { LAYER_TYPES } from './enums/layer-types.enum';
import { Layer } from './layer.model';
import { Coordinates3 } from './measurements/coordinates';
import { NameTag } from './name-tag.model';

export class BasePlane implements Layer<BasePlaneData> {
  type = LAYER_TYPES.BASE_PLANE;
  renderId: number;
  parentId: number;
  data: BasePlaneData = {};
  id: number;
  createdAt: string;
  editedAt: string;
  user: User;
  gui?: NameTag;
  name: string;
  mesh?: Mesh;
  isVisible = false;
  isSaved = true;
  description: string;

  static createBackupBasePlane(basePlane: BasePlane) {
    return {
      ...basePlane,
      data: {
        diameter: basePlane.data.diameter,
        position: { ...basePlane.data.position },
      },
    };
  }

  static createBasePlaneForRequest(basePlane: BasePlane) {
    return {
      id: basePlane.id,
      name: basePlane.name,
      isVisible: basePlane.isVisible,
      type: basePlane.type,
      parentId: basePlane.parentId.toString(),
      data: {
        diameter: basePlane.data.diameter,
        position: basePlane.data.position,
      },
    };
  }

  static setVisibility(basePlane: BasePlane, value: boolean) {
    basePlane.isVisible = value;
    if (basePlane.mesh) {
      basePlane.mesh.isVisible = value;
    }
  }
}

export interface BasePlaneData {
  diameter?: number;
  position?: Coordinates3;
}
