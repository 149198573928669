import { getVertexPositions } from '@App/app/engine/utils/babylon-utils/babylon.utils';
import { LAYER_NAMES } from '../../enums/layer-names.enum';
import { LAYER_TYPES } from '../../enums/layer-types.enum';
import { httpLayer } from '../../layer.model';
import { Coordinates3 } from '../../measurements/coordinates';
import { SketchBaseTool } from './sketch-base-tool.model';

export class SketchRectangle extends SketchBaseTool<SketchRectangleData> {
  name = LAYER_NAMES.SKETCH_RECTANGLE;
  type = LAYER_TYPES.SKETCH_RECTANGLE;
  data: SketchRectangleData;
  closed: boolean;

  static createBodyForHttpRequest(sketchRectangle: SketchRectangle): httpLayer {
    return {
      name: sketchRectangle.name,
      isVisible: sketchRectangle.isVisible || false,
      type: sketchRectangle.type,
      parentId: sketchRectangle.parentId,
      data: {
        type: LAYER_TYPES.SKETCH_RECTANGLE,
        vertexPositions: sketchRectangle.pointsMeshes
          ? getVertexPositions(sketchRectangle.pointsMeshes)
          : sketchRectangle.data.vertexPositions,
      },
    };
  }

  static getInitialData(): SketchRectangleData {
    return {
      type: LAYER_TYPES.SKETCH_RECTANGLE,
      vertexPositions: null,
    };
  }
}

export interface SketchRectangleData {
  type: string;
  vertexPositions: Coordinates3[] | null;
}
