<div class="create_layer" *ngIf="isModelLoaded && layersLoaded">
  <div class="create_layer__wrapper">
    <div class="create_layer__header">
      <div class="create_layer__title select-none" *ngIf="!(isPlaneEditing$ | async)">Tools</div>
      <div class="create_layer__title select-none" *ngIf="isPlaneEditing$ | async">
        Create sketch
      </div>
      <div
        *ngIf="!(hintsActive$ | async) && (currentHint$ | async)"
        (click)="toggleVideo()"
        class="create_layer__hints select-none"
        nbTooltip="Show tutorial"
      >
        <nb-icon icon="question-mark-circle-outline"></nb-icon>
      </div>
    </div>
    <div
      class="create_layer__body create_layer__body--col2"
      *ngIf="!isBasePlaneCreated && !(isPlaneEditing$ | async)"
    >
      <button
        nbTooltip="Base Plane"
        nbTooltipPlacement="top"
        nbTooltipStatus="primary"
        [disabled]="!isModelLoaded"
        type="button"
        class="create_layer__button"
        (click)="toggleTakeBasePlane()"
        [class.create_layer__button--hovered]="isModelLoaded"
      >
        <nb-icon class="create_layer__type" icon="compass-outline"></nb-icon>
      </button>
    </div>
    <ng-container *ngxPermissionsOnly="permissions.CAN_ADD_LAYER; else notAuthorized">
      <div
        class="create_layer__body create_layer__body--col2"
        *ngIf="isBasePlaneCreated && !(isPlaneEditing$ | async)"
      >
        <button
          [nbPopover]="isEditMode ? EDIT_MODE : 'New folder'"
          nbTooltipPlacement="top"
          nbPopoverTrigger="hint"
          [disabled]="!isModelLoaded || isEditMode"
          type="button"
          data-testId="create-folder-btn"
          [class]="
            isEditMode
              ? 'create_layer__button create_layer__button--disabled'
              : 'create_layer__button'
          "
          (click)="createNewFolder()"
          [class.create_layer__button--hovered]="isModelLoaded"
        >
          <nb-icon class="create_layer__type" icon="folder-add-outline"></nb-icon>
        </button>
        <ng-container *ngFor="let btn of BUTTON_LAYERS">
          <app-create-layer-button
            [icon]="btn.icon"
            [description]="btn.description"
            [isEditMode]="isEditMode"
            [isModelLoaded]="isModelLoaded"
            [func]="btn.func"
            [dataTestCypressId]="btn.dataTestId"
            [dataTestId]="btn.dataTestId"
            [activeTool]="activeTool"
            [toolName]="btn.toolName"
            [iconType]="btn.iconType"
          ></app-create-layer-button>
        </ng-container>
      </div>
    </ng-container>
    <div class="create_layer__body create_layer__body--col2" *ngIf="isPlaneEditing$ | async">
      <button
        nbTooltip="Line"
        nbTooltipPlacement="top"
        nbTooltipStatus="primary"
        [disabled]="!isModelLoaded"
        type="button"
        [class]="
          activeTool === LAYER_NAMES.SKETCH_LINE
            ? 'create_layer__button create_layer__button--active'
            : 'create_layer__button'
        "
        [class.create_layer__button--hovered]="isModelLoaded"
        (click)="toggleActiveLayer(LAYER_EVENTS.ADD_SKETCH_LINE, LAYER_NAMES.SKETCH_LINE)"
      >
        <svg-icon [src]="lineIcon" svgClass="create_layer__svg"></svg-icon>
      </button>

      <button
        nbTooltip="Circle"
        nbTooltipPlacement="top"
        nbTooltipStatus="primary"
        [disabled]="!isModelLoaded"
        type="button"
        [class]="
          activeTool === LAYER_NAMES.SKETCH_CIRCLE
            ? 'create_layer__button create_layer__button--active'
            : 'create_layer__button'
        "
        [class.create_layer__button--hovered]="isModelLoaded"
        (click)="toggleActiveLayer(LAYER_EVENTS.ADD_SKETCH_CIRCLE, LAYER_NAMES.SKETCH_CIRCLE)"
      >
        <svg-icon [src]="circleIcon" svgClass="create_layer__svg"></svg-icon>
      </button>
      <button
        nbTooltip="Rectangle"
        nbTooltipPlacement="top"
        nbTooltipStatus="primary"
        [disabled]="!isModelLoaded"
        type="button"
        [class]="
          activeTool === LAYER_NAMES.SKETCH_RECTANGLE
            ? 'create_layer__button create_layer__button--active'
            : 'create_layer__button'
        "
        [class.create_layer__button--hovered]="isModelLoaded"
        (click)="toggleActiveLayer(LAYER_EVENTS.ADD_SKETCH_RECTANGLE, LAYER_NAMES.SKETCH_RECTANGLE)"
      >
        <svg-icon
          [src]="rectangleIcon"
          svgClass="create_layer__svg"
          style="width: 1.45rem;"
        ></svg-icon>
      </button>
    </div>

    <div class="create_layer__actions" *ngIf="!buttonsDisabled">
      <button
        class="create_layer__cancel_button"
        nbButton
        size="tiny"
        status="info"
        (click)="onCancelClick()"
      >
        cancel
      </button>
      <button
        class="create_layer__save_button"
        nbButton
        size="tiny"
        status="info"
        (click)="onSaveClick()"
      >
        save
      </button>
    </div>
  </div>
  <div *ngIf="(hintsActive$ | async) && (currentHint$ | async)" class="create_layer__hints_wrapper">
    <app-hints></app-hints>
  </div>
  <app-create-tube-details
    *ngIf="activeTool === LAYER_NAMES.TUBE"
    class="create_layer__details"
    [ngClass]="{
      'create_layer__details--hints_active': (hintsActive$ | async) && (currentHint$ | async)
    }"
    (generate)="generateManualTool()"
    (cancel)="clear()"
  ></app-create-tube-details>
  <app-create-cuboid-details
    *ngIf="activeTool === LAYER_NAMES.CUBOID"
    class="create_layer__details"
    [ngClass]="{
      'create_layer__details--hints_active': (hintsActive$ | async) && (currentHint$ | async)
    }"
    (generate)="generateManualTool()"
    (cancel)="clear()"
  ></app-create-cuboid-details>
  <app-create-angle-iron-details
    *ngIf="activeTool === LAYER_NAMES.ANGLE_IRON"
    class="create_layer__details"
    [ngClass]="{
      'create_layer__details--hints_active': (hintsActive$ | async) && (currentHint$ | async)
    }"
    (generate)="generateManualTool()"
    (cancel)="clear()"
  ></app-create-angle-iron-details>
  <app-create-rect-tube-details
    *ngIf="activeTool === LAYER_NAMES.RECT_TUBE"
    class="create_layer__details"
    [ngClass]="{
      'create_layer__details--hints_active': (hintsActive$ | async) && (currentHint$ | async)
    }"
    (generate)="generateManualTool()"
    (cancel)="clear()"
  ></app-create-rect-tube-details>
</div>

<ng-template #notAuthorized>
  <div class="create_layer__body create_layer__body--col12">
    You are not authorized to create layers.
  </div>
</ng-template>
