import { AngleIron } from '@App/app/entities/layer/angle-iron.model';
import { UnitsService } from '@App/app/pages/viewer/services/utils/units.service';
import { ClipboardService } from '@App/app/shared/services/clipboard/clipboard.service';
import { Component } from '@angular/core';
import { LayerDetailsService } from '../../../services/layer-details-service/layer-details.service';
import { BaseLayerPropertiesComponent } from '../base-layer-properties/base-layer-properties.component';

@Component({
  selector: 'app-angle-iron-properties',
  templateUrl: './angle-iron-properties.component.html',
})
export class AngleIronPropertiesComponent extends BaseLayerPropertiesComponent<AngleIron> {
  constructor(
    private _clipboardService: ClipboardService,
    unitsService: UnitsService,
    layerDetailsService: LayerDetailsService,
  ) {
    super(unitsService, layerDetailsService);
  }

  copyDimensions() {
    this._clipboardService.copyAngleIronDimensions(this.layer, this.unitsSystem);
  }
}
