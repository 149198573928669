import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ThicknessFormService } from '../../services/thickness-utils/thickness-form.service';
import { UnitsService } from '../../services/utils/units.service';

@UntilDestroy()
@Component({
  selector: 'app-layer-thickness-input',
  templateUrl: './layer-thickness-input.component.html',
  styleUrls: ['./layer-thickness-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayerThicknessInputComponent implements OnInit {
  @Input() field = 'thickness';
  @Input() layer: LayerUI;
  private unitsSystem: UnitsSystem;

  constructor(
    private thicknessFormService: ThicknessFormService,
    private unitsService: UnitsService,
  ) {
    this.unitsService.isImperialActive$.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
      this.unitsSystem = value ? UnitsSystem.Imperial : UnitsSystem.Metric;
    });
  }

  ngOnInit(): void {}

  get form() {
    return this.thicknessFormService.forms[this.layer.id];
  }

  get step() {
    return this.isImperialUnits() ? 0.01 : 0.001;
  }

  get min() {
    return this.validatorsData?.minValue as number;
  }

  get max() {
    return this.validatorsData?.maxValue as number;
  }

  get errors() {
    return this.form.get(this.field)?.errors;
  }

  get unitsString() {
    return this.isImperialUnits() ? 'in' : 'm';
  }

  private get validatorsData() {
    return this.thicknessFormService.validatorsData[this.layer.id].find(
      (data) => data.fieldName === this.field,
    );
  }

  onFocusOut() {
    if (this.errors) {
      const { controls } = this.thicknessFormService.forms[this.layer.id];
      for (const field in controls) {
        if (controls.hasOwnProperty(field)) {
          this.updateFieldValue(field);
        }
      }
    }
  }

  private updateFieldValue(field: string) {
    const control = this.thicknessFormService.forms[this.layer.id].get(field);
    if (control?.errors) {
      const { min, max } = control.errors;
      const newValue = Number((min ? min.min : max.max).toFixed(3));
      control.setValue(newValue);
    }
  }

  private isImperialUnits() {
    return this.unitsSystem === UnitsSystem.Imperial;
  }
}
