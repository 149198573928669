import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SonyTokenResponse } from '@App/app/pages/uploader/models/sony/sony-token-response.model';
import { SonyHttpService } from '@App/app/pages/uploader/services/sony-http-service/sony-http.service';
import { loadTokensSuccess } from '@App/app/pages/uploader/store/sony-auth/actions/sony-auth.actions';
import { selectTokens } from '@App/app/pages/uploader/store/sony-auth/selectors/sony-auth.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class SonyHttpErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<SonyTokenResponse | null>(null);
  private refreshToken: string | null;
  private accessToken: string | null;

  constructor(private store: Store, private sonyHttpService: SonyHttpService) {
    this.store.select(selectTokens).subscribe((tokens) => {
      this.accessToken = tokens.accessToken;
      this.refreshToken = tokens.refreshToken;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.get('sonyRequest')) {
      return next.handle(request);
    }

    request = this.addToken(request, this.accessToken || '');
    return next.handle(request).pipe(catchError((error) => this.handleError(error, request, next)));
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
    if (error.status === 401) {
      this.handle401Error(request, next);
    }
    return throwError(error);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.sonyHttpService.refreshToken(this.refreshToken || '').pipe(
        switchMap((tokens) => {
          this.store.dispatch(
            loadTokensSuccess({
              refreshToken: tokens.body.refresh_token,
              accessToken: tokens.body.access_token,
            }),
          );
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens);
          return next.handle(this.addToken(request, tokens.body.access_token));
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((tokens) => tokens != null),
        take(1),
        switchMap((tokens) => {
          if (tokens) {
            return next.handle(this.addToken(request, tokens.body.access_token));
          } else {
            return EMPTY;
          }
        }),
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
