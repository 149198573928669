import { Component, Input } from '@angular/core';
import { ControlPoint } from '../models/control-point.model';
import { AtSetupControlPointsService } from './services/at-setup-control-points-service/at-setup-control-points.service';

@Component({
  selector: 'app-at-setup-control-points',
  templateUrl: './at-setup-control-points.component.html',
  styleUrls: ['./at-setup-control-points.component.scss'],
})
export class AtSetupControlPointsComponent {
  @Input() controlPoints: ControlPoint[];

  constructor(private atSetupControlPointsService: AtSetupControlPointsService) {}

  getForm() {
    return this.atSetupControlPointsService.form;
  }
}
