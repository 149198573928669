import { PageRangeInfo } from '@App/app/entities/shared/page-info.model';
import { Page } from '@App/app/entities/shared/page.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asPageRangeInfo',
})
export class AsPageRangeInfoPipe implements PipeTransform {
  transform(page: Page): PageRangeInfo {
    let [from, to]: (number | null)[] = [null, null];

    if (page.size * page.pageNumber <= page.totalElements) {
      from = page.size * page.pageNumber - page.size + 1;
      to = page.size * page.pageNumber;
    } else if (page.totalElements) {
      from = page.size * page.pageNumber - page.size + 1;
      to = page.totalElements;
    }

    const total = page.totalElements;
    return { from, to, total };
  }
}
