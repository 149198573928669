import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RegionOfInterestHttpService } from '../../services/region-of-interest-http-service/region-of-interest-http.service';
import {
  updateRoiLimit,
  updateRoiLimitFailure,
  updateRoiLimitSuccess,
} from '../actions/limits.actions';

@Injectable()
export class EditLimitEffects {
  constructor(
    private actions: Actions,
    private regionOfInterestHttpService: RegionOfInterestHttpService,
  ) {}

  editLimit = createEffect(() => {
    return this.actions.pipe(
      ofType(updateRoiLimit),
      mergeMap(({ id, limit }) => {
        return this.regionOfInterestHttpService.putRoiLimit(id, limit).pipe(
          map(() => {
            return updateRoiLimitSuccess({ id, limit });
          }),
          catchError(() => of(updateRoiLimitFailure())),
        );
      }),
    );
  });
}
