import { AngleIron, AngleIronBox } from '@App/app/entities/layer/angle-iron.model';
import { Annotation } from '@App/app/entities/layer/annotation.model';
import { Cuboid } from '@App/app/entities/layer/cuboid.model';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { RectTube } from '@App/app/entities/layer/rect-tube.model';
import { BoundingBox, Vector3 } from 'babylonjs';
import { transformPointsToViewer } from 'src/app/shared/utils/utils';

export type RectType = Cuboid | RectTube | Annotation;

export const minMax = (rectTypeLayer: RectType) => {
  const { min: minimum, max: maximum } = rectTypeLayer.data;
  const min = new Vector3(minimum?.x, minimum?.y, minimum?.z);
  const max = new Vector3(maximum?.x, maximum?.y, maximum?.z);
  return { min, max };
};

export const getDimensions = (cuboid: RectType) => {
  const { min, max } = minMax(cuboid);
  const boundingBox = new BoundingBox(min, max);
  return {
    width: Math.abs(+(boundingBox.extendSize.x * 2).toFixed(5)),
    height: Math.abs(+(boundingBox.extendSize.y * 2).toFixed(5)),
    depth: Math.abs(+(boundingBox.extendSize.z * 2).toFixed(5)),
  };
};

export const getAngleIronDimensions = (boxes: [AngleIronBox, AngleIronBox]) => {
  const boundingBoxes = getBoundingBoxes(boxes);
  const angleIronBoxes = boxes;
  const angleIronBox = angleIronBoxes[0];
  const length = +(boundingBoxes[0].extendSize.z * 2).toFixed(5);
  const leg1 = +(boundingBoxes[0].extendSize.x * 2).toFixed(5);
  const leg2 = +(boundingBoxes[1].extendSize.y * 2).toFixed(5);
  const thickness = +(boundingBoxes[0].extendSize.y * 2).toFixed(5);
  const innerWall = angleIronBox.min?.y || 0;
  const outerWall = angleIronBox.max?.y || 0;
  return { length, leg1, leg2, thickness, innerWall, outerWall };
};

export const getAngleIronCenterPoint = (angleIron: AngleIron) => {
  const boxes = getBoundingBoxes(angleIron.data.boxes as [AngleIronBox, AngleIronBox]);
  const center = Vector3.Center(boxes[0].centerWorld, boxes[1].centerWorld);
  return {
    x: center.x,
    y: center.y,
    z: center.z,
  };
};

export const getCenterPoint = (cuboid: RectType) => {
  const { min, max } = minMax(cuboid);
  const boundingBox = new BoundingBox(min, max);
  const center = boundingBox.centerWorld;
  return {
    x: center.x,
    y: center.y,
    z: center.z,
  };
};

export const getCenter = (points: Vector3[]): Vector3 => {
  const min = points[0].clone();
  const max = points[0].clone();
  points.forEach((point) => {
    min.minimizeInPlace(point);
    max.maximizeInPlace(point);
  });
  return Vector3.Center(min, max);
};

export const getBoundingBoxes = (
  boxes: {
    min?: Coordinates3;
    max?: Coordinates3;
  }[],
) => {
  return boxes.map((box) => {
    const min = new Vector3(box.min?.x, box.min?.y, box.min?.z);
    const max = new Vector3(box.max?.x, box.max?.y, box.max?.z);
    return new BoundingBox(min, max);
  });
};

export const getAndAdjustMinMax = (cuboid: Cuboid) => {
  const { min, max } = minMax(cuboid);
  transformPointsToViewer([min, max]);
  return [min, max];
};

export const importedCuboidsCenter = (cuboids: Cuboid[]) => {
  const minMaxLayers = cuboids.map((cuboid) => getAndAdjustMinMax(cuboid)) as [Vector3, Vector3][];
  const minLayers = minMaxLayers.map((layer) => layer[0]);
  const maxLayers = minMaxLayers.map((layer) => layer[1]);
  const min = new Vector3(
    Math.min(...minLayers.map((layer) => layer.x)),
    Math.min(...minLayers.map((layer) => layer.y)),
    Math.min(...minLayers.map((layer) => layer.z)),
  );
  const max = new Vector3(
    Math.min(...maxLayers.map((layer) => layer.x)),
    Math.min(...maxLayers.map((layer) => layer.y)),
    Math.min(...maxLayers.map((layer) => layer.z)),
  );
  return Vector3.Center(min, max);
};
