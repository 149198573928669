import { Photo } from '@App/app/entities/files/files.model';
import { Pipe, PipeTransform } from '@angular/core';
import { getDistance } from 'geolib';
import { AtSetupControlPointsService } from '../../../tabs/at-setup-tab/at-setup-control-points/services/at-setup-control-points-service/at-setup-control-points.service';
import { FiltersForm } from '../../models/filters.form.model';
import { SortingOptions } from '../../models/sorting-options.model';

@Pipe({
  name: 'photoGridFilter',
  pure: false,
})
export class PhotoGridFilterPipe implements PipeTransform {
  constructor(private _atSetupControlPointsService: AtSetupControlPointsService) {}

  transform(
    photos: Photo[],
    state: {
      form: FiltersForm;
      activeControlPointName: string | null;
    },
  ): Photo[] {
    photos = [...photos];
    const keywords = state.form.controls.keywords.value;
    const sortBy = state.form.controls.sortBy.value;

    if (keywords) {
      photos = this._filterByKeywords(photos, keywords);
    }

    if (sortBy) {
      photos =
        sortBy === SortingOptions.Metadata.distanceToGCP
          ? this._sortByDistance(state.activeControlPointName as string, photos)
          : this._sortByName(photos, sortBy);
    }

    return photos;
  }

  private _filterByKeywords(photos: Photo[], keywords: string) {
    return photos.filter((photo) => photo.name.toLowerCase().includes(keywords.toLowerCase()));
  }

  private _sortByName(photos: Photo[], sortByValue: string) {
    const sortDir = sortByValue === SortingOptions.Name.ASC ? 1 : -1;
    return photos.sort((a, b) => a.name.localeCompare(b.name) * sortDir);
  }

  private _sortByDistance(activeControlPointName: string, photos: Photo[]): Photo[] {
    const controlPoints = this._atSetupControlPointsService.getControlPoints();
    const activeControlPoint = controlPoints.find((cp) => cp.name === activeControlPointName);

    if (!activeControlPoint) {
      return photos;
    }

    const { lat, long: lon } = activeControlPoint;
    const from = { lat, lon };

    const photosWithDistances = photos.map((photo) => {
      const { gpsPosition } = photo.data;
      const to = gpsPosition ? { lat: gpsPosition.latitude, lon: gpsPosition.longitude } : null;
      const distance = to ? getDistance(from, to) : Number.POSITIVE_INFINITY;
      return { photo, distance };
    });

    const sortedPhotosWithDistances = photosWithDistances.sort((a, b) => a.distance - b.distance);
    return sortedPhotosWithDistances.map(({ photo }) => photo);
  }
}
