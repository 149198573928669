import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FileMetadataInfo } from '../../../models/uploaded-image-metadata.model';
import * as actions from '../actions/files.actions';

export const filesAdapter = createEntityAdapter<File>({
  selectId: (file) => file.name,
});

export interface State extends EntityState<File> {
  csvFiles: File[];
  flatFileName: string | null;
  controlPointsFileName: string | null;
  xlifData: FileMetadataInfo[];
}

const defaultState: State = {
  ids: [],
  entities: {},
  csvFiles: [],
  flatFileName: null,
  controlPointsFileName: null,
  xlifData: [],
};

export const initialState: State = filesAdapter.getInitialState(defaultState);

export const filesReducer = createReducer(
  initialState,
  on(actions.setFiles, (state, { files }) => filesAdapter.setAll(files, state)),
  on(actions.removeFile, (state, { name }): State => filesAdapter.removeOne(name, state)),
  on(actions.removeAll, (): State => defaultState),
  on(
    actions.setXlifDataLocally,
    actions.setXlifData,
    (state, { xlifData }): State => ({ ...state, xlifData }),
  ),
  on(actions.setCsvFiles, (state, { csvFiles }): State => ({ ...state, csvFiles })),
  on(actions.setFlatFileName, (state, { name }): State => ({ ...state, flatFileName: name })),
  on(
    actions.setControlPointsFileName,
    (state, { name }): State => ({ ...state, controlPointsFileName: name }),
  ),
);
