import { DEFAULT_PRESETS } from '@App/app/configs/viewer.config';
import { IPreset } from '@App/app/entities/viewer/custom-preset.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/ui-settings.actions';

export const presetsAdapter = createEntityAdapter<IPreset>();
export type State = EntityState<IPreset>;

const defaultPresets: State = {
  ids: [],
  entities: {},
};

export const initialState: State = presetsAdapter.getInitialState(defaultPresets);

export const presetReducer = createReducer(
  initialState,
  on(actions.loadAllPresetsSuccess, (state, { presets }) => {
    return presetsAdapter.setAll(
      presets.map((preset, index) => ({
        ...preset,
        id: DEFAULT_PRESETS.length + index,
      })),
      state,
    );
  }),
);
