import { ERRORS_RESPOND_PROCESSES_ARRAY } from '@App/app/configs/app.config';
import { FAILED, PROCESSES_DELETE_SUCCESS, SUCCESS } from '@App/app/configs/toastr-events.config';
import {
  SUCCESS_TOASTR_CONFIG,
  WARNING_TOASTR_CONFIG,
} from '@App/app/configs/toastr-messages.config';
import { IErrorIdentification } from '@App/app/entities/shared/error-identificator.model';
import { ProcessesSitesService } from '@App/app/pages/sites/shared/site-services/processes-sites.service';
import { ConfirmationDialogComponent } from '@App/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {} from 'cypress/types/bluebird';
import { EMPTY, of } from 'rxjs';
import { catchError, delay, finalize, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ModelsProcessingHttpService } from '../../../services/models-processing-http-service/models-processing-http.service';
import { ModelsProcessingService } from '../../../services/models-processing-service/models-processing.service';
import {
  deleteProcess,
  deleteProcesses,
  deleteProcessesFailed,
  deleteProcessesSuccess,
  removeProcessLocal,
} from '../actions/processes.actions';
import { selectAllProcesses } from '../selectors/processes.selectors';

@Injectable()
export class DeleteProcessEffects {
  constructor(
    private actions: Actions,
    private modelsProcessingHttpService: ModelsProcessingHttpService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private store: Store,
    private sitesService: ProcessesSitesService,
    private modelsProcessingService: ModelsProcessingService,
  ) {}

  deleteProcess = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteProcess),
      mergeMap(({ processId, force }) => {
        return this.modelsProcessingHttpService.deleteBuildProcess(processId, force).pipe(
          map(() => {
            return removeProcessLocal({ id: processId });
          }),
          catchError((error: HttpErrorResponse) => {
            const msg = error.error.message as string;
            if (
              msg ===
              "This process is already accepted and cannot be deleted unless additional query param 'force=true' is passed."
            ) {
              this.dialogService
                .open(ConfirmationDialogComponent, {
                  context: {
                    title: 'This process is already accepted. Do you want to delete it anyway?',
                    status: 'danger',
                  },
                })
                .onClose.subscribe((value) => {
                  if (value) {
                    // eslint-disable-next-line ngrx/no-dispatch-in-effects
                    this.store.dispatch(deleteProcess({ processId, force: true }));
                  }
                });
            } else {
              this.toastrService.show(msg, FAILED, WARNING_TOASTR_CONFIG);
            }
            return EMPTY;
          }),
        );
      }),
    );
  });

  deleteProcesses = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteProcesses),
      mergeMap(({ processesId, force }) => {
        const result = this.store.select(selectAllProcesses).pipe(
          first(),
          switchMap(async (processes) => {
            const batch: IErrorIdentification[] = [];
            for (const processId of processesId) {
              const errorProcess = await this.modelsProcessingHttpService
                .deleteBuildProcess(processId, force)
                .pipe(
                  delay(500),
                  catchError((error) => {
                    return of(error);
                  }),
                  map(
                    (error: HttpErrorResponse | null | number): IErrorIdentification => {
                      return {
                        id: processId,
                        error:
                          typeof error === 'number' ? null : (error as HttpErrorResponse | null),
                        name:
                          processes.find((process) => process.id === processId)?.model.name || '',
                      };
                    },
                  ),
                )
                .toPromise();
              batch.push(errorProcess);
            }
            // eslint-disable-next-line ngrx/no-multiple-actions-in-effects
            return batch;
          }),
        );
        return result
          .pipe(
            map((processError: IErrorIdentification[]) => {
              this.sitesService.setSelectedEntities([]);
              if (processError.some((error) => !!error.error)) {
                // processes with errors
                const deletableProcesses = processError.filter(
                  (error) =>
                    error.error?.error.message ===
                    "This process is already accepted and cannot be deleted unless additional query param 'force=true' is passed.",
                );
                const indelibleProcessesMessages = ERRORS_RESPOND_PROCESSES_ARRAY;
                const indelibleProcesses = processError.filter((error) =>
                  indelibleProcessesMessages.includes(error.error?.error.message),
                );
                const deletedProcesses = processError.filter((error) => error.error === null);
                return deleteProcessesFailed({
                  deletableProcesses,
                  indelibleProcesses,
                  deletedProcesses,
                });
              } else {
                // processes without any errors
                return deleteProcessesSuccess({ processesId });
              }
            }),
          )
          .pipe(
            finalize(() => {
              this.sitesService.hideSpinner();
            }),
          );
      }),
    );
  });

  deleteProcessesSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(deleteProcessesSuccess),
        tap(({}) => {
          this.sitesService.setSelectedEntities([]);

          this.toastrService.show(PROCESSES_DELETE_SUCCESS, SUCCESS, {
            ...SUCCESS_TOASTR_CONFIG,
          });
        }),
      );
    },
    { dispatch: false },
  );

  deleteProcessesFailed = createEffect(
    () => {
      return this.actions.pipe(
        ofType(deleteProcessesFailed),
        map(({ deletableProcesses, indelibleProcesses, deletedProcesses }) => {
          this.modelsProcessingService.openDeleteProcessesConfirmationDialog(
            deletableProcesses,
            indelibleProcesses,
            deletedProcesses,
          );
        }),
      );
    },
    { dispatch: false },
  );
}
