<nb-card class="image_cropper" [nbSpinner]="!loaded" data-testId="container">
  <nb-card-body class="image_cropper__image">
    <image-cropper
      *ngIf="compressed"
      [imageFile]="image"
      format="png"
      (imageCropped)="onCrop($event)"
      (cropperReady)="onLoad()"
      data-testId="cropper"
    ></image-cropper>
  </nb-card-body>
  <nb-card-footer class="image_cropper__footer">
    <button nbButton (click)="onCancel()" data-testId="cancel-btn">Cancel</button>
    <button
      nbButton
      status="info"
      [disabled]="!croppedBlob || !loaded"
      (click)="onConfirm()"
      data-testId="confirm-btn"
    >
      Confirm
    </button>
  </nb-card-footer>
</nb-card>
