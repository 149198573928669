import { EmptyOption } from '@App/app/configs/processing-settings-preset.config';
import { RegionOfInterestService } from '@App/app/engine/services/region-of-interest-service/region-of-interest.service';
import { MODEL_TYPES } from '@App/app/entities/models/model-types.enum';
import { ModelType } from '@App/app/entities/processing/build-process-status.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import {
  IOptions,
  IPresetModel,
  IProcessingSettings,
} from '@App/app/entities/processing/processing-preset-settings.model';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { BoundingVectors } from '@App/app/entities/viewer/bounding-vectors.model';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Vector3 } from 'babylonjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProcessingsPresetName } from './tabs/at-setup-tab/models/processing-preset.enum';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ProcessingService {
  boundingVectors: BoundingVectors = {
    min: new Vector3(0, 0, 0),
    max: new Vector3(0, 0, 0),
  };
  buildProcess: BuildProcess | null;

  private availableSteps: ProcessingSteps | 0 = 0;
  private boundingVectorsSub: Subscription;
  private regionOfInterestInitialized = false;
  private _activeStep$ = new BehaviorSubject<ProcessingSteps | 0>(0);
  activeStep$ = this._activeStep$.asObservable();
  private _modelToLoad$ = new BehaviorSubject<ModelType>(ModelType.None);
  modelToLoad$ = this._modelToLoad$.asObservable();
  buildProcessPresets: IPresetModel[];

  constructor(private regionOfInterestService: RegionOfInterestService) {}

  init(process: BuildProcess) {
    this.buildProcess = process;
    this.availableSteps = process.getAvailableStep();
    this.setActiveStep(process.getActiveStep());
  }

  clear() {
    this.buildProcess = null;
    this.setActiveStep(0);
    this.availableSteps = 0;
    this._modelToLoad$.next(ModelType.None);
  }

  getAvailableSteps(): ProcessingSteps | 0 {
    return this.availableSteps;
  }

  setActiveStep(step: ProcessingSteps | 0) {
    const modelType = this.getModelTypeByStep(step);
    if (modelType) {
      this._modelToLoad$.next(modelType);
    }
    this._activeStep$.next(step);
  }

  initRegionOfInterest() {
    if (this.regionOfInterestInitialized) {
      this.destroyRegionOfInterest();
    }
    if (this.buildProcess) {
      const settings = JSON.parse(this.buildProcess.reconstructionSettings)?.boundingBox;
      const box = settings
        ? {
            max: new Vector3(settings.xMax, settings.yMax, settings.zMax),
            min: new Vector3(settings.xMin, settings.yMin, settings.zMin),
          }
        : null;
      this.regionOfInterestService.initRegionOfInterest(box);
      this.boundingVectorsSub = this.regionOfInterestService.boundingVectors.subscribe(
        (vectors) => {
          if (this.validateVectors(vectors)) {
            this.boundingVectors.min = vectors.min.clone();
            this.boundingVectors.max = vectors.max.clone();
          } else {
            this.regionOfInterestService.setDefaultBoundingBox();
          }
        },
      );
      this.regionOfInterestInitialized = true;
    }
  }

  destroyRegionOfInterest() {
    this.regionOfInterestService.destroyRegionOfInterest();
    this.boundingVectorsSub.unsubscribe();
    this.regionOfInterestInitialized = false;
  }

  getModelToLoad() {
    return this._modelToLoad$.value;
  }

  getPresetsByType(
    modelType: MODEL_TYPES,
    processStep: ProcessingSteps,
    settings: IProcessingSettings[],
  ) {
    const presets = this.buildProcessPresets
      .filter((preset) => preset.modelType === modelType)
      .filter((p) => p.step === processStep);
    this.setSelectOptions(presets, settings);
    return presets;
  }

  setSelectOptions(presets: IPresetModel[], settings: IProcessingSettings[]) {
    const presetIndex = settings.findIndex(
      (item) => item.groupName === ProcessingsPresetName.SETTINGS_PRESET,
    );
    const presetSettings: IOptions[] = presets.map((preset) => {
      return {
        title: preset.name,
        value: preset.id,
        description: preset.description,
      };
    });
    settings[presetIndex].settings[0].options = [EmptyOption, ...presetSettings];
  }

  private validateVectors(vectors: BoundingVectors): boolean {
    const data = [
      vectors.min.x,
      vectors.min.y,
      vectors.min.z,
      vectors.max.x,
      vectors.max.y,
      vectors.max.x,
    ];
    return !data.find((val) => typeof val !== 'number');
  }

  private getModelTypeByStep(step: ProcessingSteps | 0) {
    return this.availableSteps <= ProcessingSteps.AT_SETUP
      ? ModelType.None
      : this.getModelTypeByStepIfAModelCanBeLoaded(step);
  }

  private getModelTypeByStepIfAModelCanBeLoaded(step: ProcessingSteps | 0) {
    const isFurtherThanProdReview = step >= ProcessingSteps.PROD_REVIEW;
    const currentType = this._modelToLoad$.value;
    if (isFurtherThanProdReview && currentType !== ModelType.Prod) {
      return ModelType.Prod;
    }
    if (!isFurtherThanProdReview && currentType !== ModelType.AT) {
      return ModelType.AT;
    }
  }
}
