import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layer-details-optional-measurement-field',
  templateUrl: './layer-details-optional-measurement-field.component.html',
})
export class LayerDetailsOptionalMeasurementFieldComponent {
  @Input() value?: number;
  @Input() type: keyof typeof this.OPTIONS;
  @Input() unitsSystem: UnitsSystem;
  OPTIONS = { length: 'length', area: 'area' };
}
