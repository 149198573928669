import {
  DRAFT_SKETCH_RECTANGLE_TAG_NAME,
  NEW_SKETCH_RECTANGLE_NAME,
  SKETCH_RECTANGLE_BOX_NAME,
} from '@App/app/configs/babylon.config';
import { SketchPlane } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { SketchRectangle } from '@App/app/entities/layer/sketch-tools/tools/sketch-rectangle.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { AbstractMesh, Mesh, PickingInfo, Tags, TransformNode } from 'babylonjs';
import { v4 as uuidv4 } from 'uuid';
import { SceneService } from '../../../scene-service/scene.service';
import { SketchLineMeshUtilsService } from '../shared/sketch-line-mesh-utils.service';
import { SketchesUtilsService } from '../shared/sketches-utils.service';

@Injectable()
export class SketchRectangleInitializerService {
  constructor(
    private _broadcastService: BroadcastService,
    private _sketchesUtilsService: SketchesUtilsService,
    private _sketchLineMeshUtilsService: SketchLineMeshUtilsService,
    private _sceneService: SceneService,
  ) {}

  initDraftSketchRectangleByHit(
    hit: PickingInfo,
    draftSketch: SketchRectangle | null,
    plane: SketchPlane | null,
    root: TransformNode | null,
    boxId: number,
    pointer: Mesh,
    addPointerMeshEventListener: () => void,
  ): [SketchRectangle | null, TransformNode | null, number] {
    draftSketch = this._createFirstLine(draftSketch, plane);
    [draftSketch, root, boxId] = this._createLines(
      draftSketch,
      plane,
      root,
      boxId,
      addPointerMeshEventListener,
    );
    Tags.AddTagsTo(root, `${DRAFT_SKETCH_RECTANGLE_TAG_NAME}`);
    let boxMesh: Mesh | undefined;
    [boxMesh, boxId] = this._initSphereOnClick(hit, root, boxId);
    this._broadcastService.broadcast(EVENT_TYPE.REFRESH_CUSTOM_LOD, null);
    if (boxMesh && draftSketch) {
      draftSketch.pointsMeshes.push(boxMesh);
      this._createLinesMesh(boxMesh, draftSketch, pointer, root);
    }
    return [draftSketch, root, boxId];
  }

  private _initSphereOnClick(
    hit: PickingInfo,
    root: TransformNode | null,
    boxId: number,
  ): [Mesh | undefined, number] {
    if (hit.pickedPoint && root) {
      const sphereName = `${SKETCH_RECTANGLE_BOX_NAME}${++boxId}`;
      const mesh = this._sketchesUtilsService.createMesh(hit.pickedPoint, root, sphereName, true);
      return [mesh, boxId];
    }
    return [undefined, boxId];
  }

  private _createLinesMesh(
    boxMesh: Mesh,
    draftSketch: SketchRectangle | null,
    pointer: Mesh,
    root: TransformNode | null,
  ) {
    pointer.position.copyFrom(boxMesh.position);
    if (draftSketch) {
      draftSketch.linesMesh = this._sketchLineMeshUtilsService.createLine(draftSketch, pointer);
      draftSketch.linesMesh.parent = root;
    }
  }

  private _createFirstLine(draftSketch: SketchRectangle | null, plane: SketchPlane | null) {
    if (!draftSketch && plane) {
      const [planeId, uuid] = [plane.id, (uuidv4() as any) as number];
      return new SketchRectangle(planeId, uuid);
    }
    return draftSketch;
  }

  private _createLines(
    draftSketch: SketchRectangle | null,
    plane: SketchPlane | null,
    root: TransformNode | null,
    boxId: number,
    addPointerMeshEventListener: () => void,
  ): [SketchRectangle | null, TransformNode | null, number] {
    if (draftSketch && plane) {
      const uuid = (uuidv4() as any) as number;
      draftSketch = new SketchRectangle(plane.id, uuid);
      const newRootName = `${NEW_SKETCH_RECTANGLE_NAME}${uuid}`;
      root = new AbstractMesh(newRootName, this._sceneService.scene);
      boxId = 0;
    }
    if (draftSketch && draftSketch.pointsMeshes.length < 1) {
      addPointerMeshEventListener();
    }
    return [draftSketch, root, boxId];
  }
}
