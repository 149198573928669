import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@App/app/auth/auth.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';

import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import {
  archiveProcess,
  changeProcessSubscription,
  deleteProcess,
} from '@App/app/pages/processing/store/processes/actions/processes.actions';
import { ProcessesSitesService } from '@App/app/pages/sites/shared/site-services/processes-sites.service';
import { ConfirmationDialogComponent } from '@App/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { slugify } from '@App/app/shared/utils/utils';
import { NbDialogService } from '@nebular/theme';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-model-processing-actions',
  templateUrl: './model-processing-actions.component.html',
  styleUrls: ['./model-processing-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModelProcessingActionsComponent implements OnInit, OnChanges {
  @Input() process: BuildProcess;

  isOwner = false;
  isSubscriber = false;
  userId: string | number | null;
  permissions = UserPermissions;

  archiveIcon = '../../../../assets/svg/icons/sf_portal-ui_icon_archive.svg';
  unarchiveIcon = '../../../../assets/svg/icons/sf_portal-ui_icon_unarchive.svg';

  constructor(
    private authService: AuthService,
    private sitesService: ProcessesSitesService,
    private store: Store,
    private dialogService: NbDialogService,
  ) {}

  ngOnInit(): void {
    this.setUserProperties();
  }

  ngOnChanges() {
    this.setUserProperties();
  }

  deleteHandler() {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        context: {
          title:
            'Are you sure you want to delete this process? All data related to it will be lost.',
          status: 'danger',
          keywordMode: true,
          keyword: `${this.process.id}/${slugify(this.process.model.name)}`,
        },
      })
      .onClose.subscribe((value) => {
        if (value) {
          this.store.dispatch(deleteProcess({ processId: this.process.id, force: false }));
        }
      });
  }

  archiveHandler() {
    this.sitesService.loading.next(true);
    const isArchived = !this.process.isArchived;
    this.store.dispatch(archiveProcess({ process: this.process, isArchived }));
  }

  handleSubscribeChange(subscribe: boolean) {
    this.sitesService.loading.next(true);
    this.store.dispatch(changeProcessSubscription({ process: this.process, subscribe }));
    document.querySelectorAll('button').forEach((button) => {
      (button as HTMLButtonElement).blur();
    });
  }

  private setUserProperties() {
    this.userId = this.authService.getUser().id || null;
    const users = JSON.parse(this.process.users);
    this.isOwner = users?.owner === this.userId;
    this.isSubscriber = users?.subscribers?.includes(this.userId);
  }
}
