import { Injectable } from '@angular/core';
import { Photo } from '@App/app/entities/files/files.model';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { RPYAngles } from '@App/app/entities/shared/parameters.model';
import { Angle, Matrix, Quaternion, Vector3 } from 'babylonjs';

@Injectable({
  providedIn: 'root',
})
export class PhotosUtilsService {
  photosModifier: {
    xDiff: number;
    yDiff: number;
    zDiff: number;
  } = {
    xDiff: 0,
    yDiff: 0,
    zDiff: 0,
  };

  constructor() {}

  position(position: Coordinates3) {
    return new Vector3(
      -position.x - this.photosModifier.xDiff,
      position.z - this.photosModifier.yDiff,
      -position.y - this.photosModifier.zDiff,
    );
  }

  quaternion(rotation: RPYAngles) {
    return Quaternion.FromEulerAngles(
      Angle.FromDegrees(rotation.pitch).radians(),
      Angle.FromDegrees(rotation.yaw).radians(),
      Angle.FromDegrees(rotation.roll).radians(),
    );
  }

  normal(quaternion: Quaternion) {
    const rotMat = Matrix.Identity();
    Matrix.FromQuaternionToRef(quaternion, rotMat);
    return Vector3.TransformNormal(new Vector3(0, 0, 1), rotMat);
  }

  checkPhotosUseGPSMetadata(photos: Photo[]) {
    return photos.some((photo) => photo.data.gpsPosition);
  }
}
