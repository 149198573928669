import { Model } from '@App/app/entities/models/model.model';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { User } from '@App/app/entities/users/user';
import { UsersHttpService } from '@App/app/pages/users/users-http.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { decimalToSexagesimal } from 'geolib';
import { dataTypeParameters } from '../../model-form/models-types-forms/model-type-params';

@Component({
  selector: 'app-model-overview-details',
  templateUrl: './model-overview-details.component.html',
  styleUrls: ['./model-overview-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelOverviewDetailsComponent implements OnInit {
  @Input() model: Model;
  isGeoLocationInDecimal = false;
  siteDataUnitsSystem = UnitsSystem.Imperial;
  structureDataUnitsSystem = UnitsSystem.Imperial;
  unitsSystem = UnitsSystem;
  processTime: number | null = null;
  manager: User | undefined;
  pilot: User | undefined;

  constructor(private usersHttpService: UsersHttpService, private cdr: ChangeDetectorRef) {
    this.toggleGeoLocationFormat = this.toggleGeoLocationFormat.bind(this);
    this.toggleSiteDataFormat = this.toggleSiteDataFormat.bind(this);
  }
  ngOnInit(): void {
    this.usersHttpService.getAllUsers().subscribe((users) => {
      if (this.model.manager) {
        this.manager = users.find((user) => user.id === this.model.manager.id);
      }
      if (this.model.pilot) {
        this.pilot = users.find((user) => user.id === this.model.pilot.id);
      }
      this.cdr.detectChanges();
    });
  }

  getTypeParams() {
    return Object.entries(dataTypeParameters).find((type) => {
      if (type[1].subTypeName === this.model.modelType) {
        return type[1];
      }
    });
  }

  decimalToDegrees(decimal: number) {
    return decimalToSexagesimal(decimal);
  }

  toggleGeoLocationFormat() {
    this.isGeoLocationInDecimal = !this.isGeoLocationInDecimal;
  }

  toggleSiteDataFormat() {
    this.siteDataUnitsSystem =
      this.siteDataUnitsSystem === UnitsSystem.Imperial ? UnitsSystem.Metric : UnitsSystem.Imperial;
  }
}
