import { createFeatureSelector, createSelector } from '@ngrx/store';
import { usersAdapter, State } from '../reducers/users.reducer';
import * as fromUsers from '../reducers/users.reducer';

export const getUsersState = createFeatureSelector<State>('users');

const { selectAll } = usersAdapter.getSelectors(getUsersState);

export const selectAllUsers = selectAll;

export const selectUsersState = createFeatureSelector<fromUsers.State>('users');

export const selectUsersTotalCount = createSelector(selectUsersState, fromUsers.getTotalCount);

export const selectUserById = (id: number) =>
  createSelector(selectAll, (entities) => entities.filter((entity) => entity.id === id)[0]);

export const selectUsers = (disabled: boolean) =>
  createSelector(selectAll, (entities) =>
    entities.filter((entity) => entity.isDisabled === disabled),
  );
