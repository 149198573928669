import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Folder } from '@App/app/entities/layer/folder.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import {
  loadAllLayers,
  removeAllLayersLocal,
} from 'src/app/pages/viewer/store/actions/layers.actions';
import { selectAllLayers } from 'src/app/pages/viewer/store/selectors/layers.selectors';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { SliceToolService } from '../slice-tool-services/slice-tool.service';

@Injectable({
  providedIn: 'root',
})
export class LayerService {
  layers = new BehaviorSubject<(LayerUI | LayerUiExpandable)[]>([]);

  constructor(
    private _broadcastService: BroadcastService,
    private _sliceToolService: SliceToolService,
    private store: Store,
  ) {
    this._broadcastService.on(EVENT_TYPE.UPDATE_BASE_PLANE).subscribe((basePlanes: LayerUI[]) => {
      this.updateLayers(basePlanes, LAYER_TYPES.BASE_PLANE);
    });

    this._broadcastService.on(EVENT_TYPE.UPDATE_FOLDERS).subscribe((folders: Folder[]) => {
      this.layers.next(this.layers.value);
    });

    this.store.select(selectAllLayers).subscribe((layers) => {
      this.layers.next([...layers]);
    });
  }

  initLayers(renderId: number) {
    this._sliceToolService.initSlicePlanes();
    this.layers.next([]);
    this.store.dispatch(loadAllLayers({ renderId }));
  }

  destroyLayers() {
    this.store.dispatch(removeAllLayersLocal());
    this.layers.next([]);
  }

  addBatchLayers(layers: (LayerUI | LayerUiExpandable)[]) {
    this.layers.next([...layers, ...this.layers.value]);
  }

  private updateLayers(layers: (LayerUI | LayerUiExpandable)[], type: LAYER_TYPES) {
    const result = this.layers.value.filter((layer) => layer.type !== type);
    this.layers.next([...layers, ...result]);
  }
}
