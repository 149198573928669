import { createAction, props } from '@ngrx/store';
import { SonyMission } from '../../../models/sony/sony-mission.model';

export const loadSonyMissions = createAction('[Sony Missions] Load Sony missions');

export const loadSonyMissionsSuccess = createAction(
  '[Sony Missions] Load Sony missions success',
  props<{ missions: SonyMission[] }>(),
);

export const removeSonyMissionsLocally = createAction(
  '[Sony Missions] Remove Sony missions locally',
);
