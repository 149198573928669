import { CoordsTool } from '@App/app/entities/viewer/coords-tool.model';
import { ClipboardService } from '@App/app/shared/services/clipboard/clipboard.service';
import { Injectable } from '@angular/core';
import { Vector3 } from 'babylonjs';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from '../../utils-service/utils.service';
import { CoordsToolService } from '../coords-tool-service/coords-tool.service';

@Injectable({
  providedIn: 'root',
})
export class AxisCoordsToolService extends CoordsToolService implements CoordsTool {
  protected _coordsToolActive$ = new BehaviorSubject<boolean>(false);
  axisCoordsToolActive = this._coordsToolActive$.asObservable();
  protected _currentCoords$ = new BehaviorSubject<Vector3>(Vector3.Zero());
  axisCurrentCoords$ = this._currentCoords$.asObservable();

  constructor(private _clipboardService: ClipboardService, private utilsService: UtilsService) {
    super();
  }

  updateCoords() {
    const hit = this.utilsService.pickRay();
    if (!hit?.pickedPoint) {
      this._currentCoords$.next(Vector3.Zero());
    } else {
      this._currentCoords$.next(hit.pickedPoint.clone());
    }
  }

  saveCoords() {
    this.setCoordsToolActive(false);
    this.updateCoords();
    this._clipboardService.copyCoords(this._currentCoords$.value);
  }
}
