<div *ngIf="!!path && !!path.length" class="layer__path">
  <span class="layer__path--text">
    {{ path }}
  </span>
  <span (click)="onClickPath($event)" data-testId="path-click-btn">
    <nb-icon
      icon="arrowhead-right-outline"
      class="layer__path--arrow"
      nbTooltip="See in folder"
      nbTooltipStatus="primary"
    ></nb-icon>
  </span>
</div>
