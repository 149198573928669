<div class="create_details">
  <div class="create_details__header">
    <div class="create_details__title">Creation details</div>
  </div>
  <div class="create_details__body">
    <div class="create_details__table">
      <div
        class="create_details__column"
        *ngFor="let part of samples | keyvalue"
        [ngClass]="{ 'create_details__column--active': activePart === +part.key }"
      >
        <div class="create_details__column_header" (click)="setActivePart(+part.key)">
          {{ partName[+part.key] }}
        </div>
        <div class="create_details__rows">
          <div class="create_details__row" *ngFor="let sample of part.value; index as i">
            <div class="create_details__label">
              x: {{ sample.position.x | number }}, y: {{ sample.position.y | number }}, z:
              {{ sample.position.z | number }}
            </div>
            <div class="create_details__row_actions">
              <div
                class="create_details__row_action"
                [ngClass]="{
                  'create_details__row_action--active':
                    sampleHighlighted.part === +part.key && sampleHighlighted.i === i
                }"
                (click)="toggleHighlightSample(+part.key, i)"
              >
                <nb-icon icon="pin-outline"></nb-icon>
              </div>
              <div class="create_details__row_action" (click)="onDeleteSample(+part.key, i)">
                <nb-icon icon="close"></nb-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="create_details__actions">
      <button
        (click)="onGenerate()"
        [disabled]="!generateAvailable"
        nbButton
        status="info"
        size="tiny"
        class="create_details__action"
      >
        Generate
      </button>
      <button
        (click)="onCancel()"
        nbButton
        status="basic"
        size="tiny"
        ghost
        class="create_details__action"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
