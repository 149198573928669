import { VIEWER_LOD_CONFIG } from '@App/app/configs/viewer.config';
import { TileInfo, TileLevel, TilesSetLevel } from '@App/app/entities/viewer/tiles-info.model';
import { ArcRotateCamera, Frustum, Scene } from 'babylonjs';
import { ProgressInfo } from '../models/progress.model';

export const getBaseTileAndLevelsForInfo = (
  tileInfo: TileInfo,
  baseTiles: string[],
  tileSetsLevels: TilesSetLevel[],
) => {
  const { tileName: name } = tileInfo;
  const baseTile = _findTileInArray(baseTiles, name);
  const levels: TileLevel[] = tileSetsLevels.map((lev) => ({
    tile: _findTileInArray(lev.tiles, name),
    dist: lev.minDistance,
  }));
  return { baseTile, levels };
};

export const getTileAndHashFromFileName = (fileName: string) => {
  const paramsStr = fileName.split('?')[1];
  const urlParams = new URLSearchParams(paramsStr);
  const params = Object.fromEntries(urlParams);
  const tile = params.tile as string;
  const hash = +(params.hash as string);
  return { tile, hash };
};

export const getCameraFrustum = (camera: ArcRotateCamera) => {
  const planes = camera.getTransformationMatrix();
  return Frustum.GetPlanes(planes);
};

export const clearPickedMeshFromScene = (scene: Scene) => {
  const manager = scene?._inputManager as any;
  if (manager?._previousPickResult) {
    manager._previousPickResult.pickedMesh = null;
  }
};

export const populateNewProgressInfos = (infos: ProgressInfo[], progresses: (number | null)[]) => {
  _updateExistingProgressInfos(infos, progresses);
  _assignNewProcessInfos(infos, progresses);
  return infos;
};

export const tilesArraysToLODLevels = (tiles: string[][]) => {
  return tiles.map((level, i) => ({
    tiles: level,
    minDistance:
      VIEWER_LOD_CONFIG.HQ_DISTANCE_THRESHOLD + i * VIEWER_LOD_CONFIG.NEXT_LEVELS_THRESHOLD,
  }));
};

const _updateExistingProgressInfos = (infos: ProgressInfo[], progresses: (number | null)[]) => {
  for (const prog of infos) {
    prog.value = progresses[prog.index] ?? 1;
  }
};

const _assignNewProcessInfos = (infos: ProgressInfo[], progresses: (number | null)[]) => {
  for (const [index, prog] of progresses.entries()) {
    if (prog !== null && !infos.some((p) => p.index === index)) {
      infos.push({ index, value: prog });
    }
  }
};

const _findTileInArray = (tiles: string[], name: string): string => {
  const tile = tiles.find((t) => {
    const index = t.lastIndexOf(name);
    if (index === -1) {
      return false;
    }
    const nextChar = t[index + name.length];
    return isNaN(+nextChar);
  });
  if (!tile) {
    throw new Error(`The tile ${name} was not found in the array: ${tiles}`);
  }
  return tile;
};
