import { Coordinates2 } from '@App/app/entities/layer/measurements/coordinates';

export const getDimensionsFromImage = (img: HTMLImageElement) => {
  const L = +img.style.left.replace('px', '');
  const T = +img.style.top.replace('px', '');
  const W = +img.style.width.replace('px', '');
  const H = +img.style.height.replace('px', '');
  return [L, T, W, H];
};

export const percentagesToPixels = (
  { x: minXPct, y: minYPct }: Coordinates2,
  { x: maxXPct, y: maxYPct }: Coordinates2,
  totalWidthPx: number,
  totalHeightPx: number,
): {
  min: Coordinates2;
  max: Coordinates2;
} => {
  return {
    min: { x: minXPct * totalWidthPx, y: minYPct * totalHeightPx },
    max: { x: maxXPct * totalWidthPx, y: maxYPct * totalHeightPx },
  };
};

export const translatePxDimensionsToPercents = (
  img: HTMLImageElement,
  min: Coordinates2,
  max: Coordinates2,
): Coordinates2[] => {
  const [L, T, W, H] = getDimensionsFromImage(img);
  max = { x: min.x + max.x, y: min.y + max.y };
  const pointsInPx = [min, max].map((p) => [-L + p.x, -T + p.y]);
  return pointsInPx.map((p) => ({ x: p[0] / W, y: p[1] / H }));
};

export const calculateRelativeToAbsoluteCoordsByImage = (
  { x, y }: Coordinates2,
  image: HTMLImageElement,
) => {
  const { offsetHeight, offsetWidth, naturalHeight, naturalWidth } = image;
  const [xRatio, yRatio] = [x / offsetWidth, y / offsetHeight];
  return [xRatio * naturalWidth, yRatio * naturalHeight];
};

export const calculateAbsoluteToRelativeCoordsByImage = (
  { x, y }: Coordinates2,
  image: HTMLImageElement,
) => {
  const { naturalHeight, naturalWidth } = image;
  const [xRatio, yRatio] = [x / naturalWidth, y / naturalHeight];
  const [L, T, W, H] = getDimensionsFromImage(image);
  return [xRatio * W + L, yRatio * H + T];
};

export const resizeImageInWrapper = (
  image: HTMLImageElement,
  wrapper: HTMLDivElement,
  isLargePhotoDisplaying = false,
) => {
  let newWidth = 960;
  let newHeight = 640;

  if (isLargePhotoDisplaying) {
    // full screen
    const { offsetHeight } = document.documentElement;
    const { naturalHeight, naturalWidth } = image;
    const ratio = (offsetHeight / naturalHeight) * 0.9;
    newWidth = naturalWidth * ratio;
    newHeight = naturalHeight * ratio;
  }

  for (const cls of [
    '.photo_card_zoom__photo',
    '.photo_card_zoom',
    '.photo_card_zoom__photo div:first-child',
  ]) {
    const container = document.querySelector(cls) as HTMLElement | null;
    container?.style.setProperty('width', `${newWidth}px`);
    container?.style.setProperty('height', `${newHeight}px`);
  }
  image.style.setProperty('width', `${newWidth}px`);
  image.style.setProperty('height', `${newHeight}px`);
  wrapper.style.setProperty('width', `${newWidth}px`);
  wrapper.style.setProperty('height', `${newHeight}px`);
};
