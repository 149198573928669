import { RectType } from '@App/app/engine/helpers/rectangle-tools-helpers';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { LayersService } from '@App/app/pages/viewer/services/layers/layers.service';
import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
  name: 'isRecalculateActive',
  pure: false,
})
export class IsRecalculateActivePipe implements PipeTransform {
  private _recalculatedLayers: LayerUI[] = [];

  constructor(private _layersService: LayersService) {
    this._layersService.recalculatedLayers$.pipe(untilDestroyed(this)).subscribe((layers) => {
      this._recalculatedLayers = layers;
    });
  }

  transform(layer: RectType): boolean {
    return this._recalculatedLayers.includes(layer);
  }
}
