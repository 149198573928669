import { MODELS_MAP_CLUSTER_SYMBOL, SUPPORTED_BASE_MAPS } from '@App/app/configs/photos-map.config';
import { Model } from '@App/app/entities/models/model.model';
import { PageRangeInfo } from '@App/app/entities/shared/page-info.model';
import { ModelsSitesService } from '@App/app/pages/sites/shared/site-services/models-sites.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coordinate, Map, TileLayer, VectorLayer, ui } from 'maptalks';
import { ClusterLayer } from 'maptalks.markercluster';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ModelsDisplayingModes } from '../../shared/models/models-displaying-modes.model';
import { ModelsView } from '../models/models-view.model';
import { ModelsContentService } from '../services/models-content-service/models-content.service';
import { ModelsMapService } from './services/models-map-service/models-map.service';
import { validateMapLocation } from './validators/models-validator';

@UntilDestroy()
@Component({
  selector: 'app-models-map',
  templateUrl: './models-map.component.html',
  styleUrls: ['./models-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ModelsMapService],
})
export class ModelsMapComponent implements OnInit, OnDestroy, ModelsView {
  private _map: Map | null;
  private _container = 'map';
  private _models: Model[] = [];
  private _multipleInfoWindow: ui.InfoWindow | null;
  defaultBaseMapIndex = 0;
  baseMaps = SUPPORTED_BASE_MAPS;
  mapUrl: string = this.baseMaps[0].urlTemplate;
  showStatisticsButton$: Observable<boolean>;
  targetIcon = '../../../../../../../assets/svg/target_icon.svg';

  constructor(
    private _modelsSiteService: ModelsSitesService,
    private _modelsContentService: ModelsContentService,
    private _modelsMapService: ModelsMapService,
  ) {}

  ngOnInit() {
    this._modelsContentService.filteredModels$.pipe(untilDestroyed(this)).subscribe((models) => {
      this._models = models;
      this._showModelsOnMap(models);
    });

    this._modelsSiteService.displayingMode$
      .pipe(untilDestroyed(this), delay(10))
      .pipe(map((value) => value === ModelsDisplayingModes.MAP))
      .subscribe((isMapActive: boolean) => {
        if (isMapActive) {
          this._resetMap();
        } else {
          this._removeMap();
        }
      });

    this.showStatisticsButton$ = this._modelsContentService.showStatisticsButton$;
  }

  ngOnDestroy(): void {
    this._removeMap();
  }

  onResetMap() {
    this._resetMap();
  }

  onBaseMapChange(index: number) {
    this.mapUrl = this.baseMaps[index].urlTemplate;
    this._resetMap();
  }

  getPageRangeInfo(): PageRangeInfo | null {
    return null;
  }

  private _initMap(mapUrl: string) {
    this._map = new Map(this._container, {
      center: this._modelsMapService.getMapCenter(this._models),
      zoom: 2.5,
      minZoom: 2,
      baseLayer: new TileLayer('base', {
        urlTemplate: mapUrl,
        subdomains: ['a', 'b', 'c', 'd'],
        repeatWorld: false,
      }),
      layers: [
        new ClusterLayer('main', [], {
          symbol: MODELS_MAP_CLUSTER_SYMBOL,
          animation: false,
          enableInfoWindow: true,
        }),
        new VectorLayer('labels'),
      ],
    });

    this._map.on('moving', () => this._forceRerendering());
    this._map.on('click', (e) => this._onMapClick(e));
  }

  private _removeMap() {
    this._map?.remove();
  }

  private _showModelsOnMap(models: Model[]) {
    const validModels = validateMapLocation(models);
    const markers = validModels.map((model) => this._modelsMapService.modelToMarker(model));

    const mainLayer = this._map?.getLayer('main') as VectorLayer | null;
    mainLayer?.removeGeometry(mainLayer.getGeometries());
    mainLayer?.addGeometry(markers);

    if (models.length && this._map) {
      this._modelsMapService.adjustMapViewToModels(validModels, this._map);
    }
  }

  private _onMapClick({ coordinate }: { coordinate: Coordinate }) {
    this._multipleInfoWindow?.remove();
    this._multipleInfoWindow = null;

    const _map = this._map as Map;
    const result = this._modelsMapService.getMarkersByCoordinate(_map, coordinate);

    if (!Array.isArray(result) && result.children.length) {
      this._multipleInfoWindow = this._modelsMapService.createMultipleInfoWindow(
        result.children,
        _map,
        result.center,
      );
      this._modelsMapService.zoomToInfoWindow(this._multipleInfoWindow, _map);
    }
  }

  private _resetMap() {
    this._removeMap();
    this._initMap(this.mapUrl);
    this._showModelsOnMap(this._models);
  }

  private _forceRerendering() {
    this._modelsMapService.redrawMarkers(this._map as Map);
  }
}
