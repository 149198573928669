import { Component } from '@angular/core';
import { ProcessingSteps } from '@App/app/entities/processing/processing-steps.model';
import { ProcessingService } from '../../processing.service';

@Component({
  template: '',
})
export abstract class BaseTabComponent {
  protected cancelStep: ProcessingSteps | null;
  public isFailed = false;

  constructor(protected processingService: ProcessingService) {
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    this.redirectToStep(this.cancelStep as ProcessingSteps);
  }

  protected redirectToStep(step: ProcessingSteps) {
    this.processingService.setActiveStep(step);
  }
}
