<div [formGroup]="form" class="sidebar__content">
  <ng-container *ngFor="let setting of settings">
    <div class="sidebar__label sidebar__label--group label">
      {{ setting.groupName }}
    </div>
    <div
      *ngFor="let setting of setting.settings"
      class="sidebar__input_row sidebar__input_row--settings"
    >
      <ng-container *ngxPermissionsOnly="setting.permissions">
        <div
          class="sidebar__label sidebar__label--settings"
          [nbTooltip]="setting.settingsDescription"
          [nbTooltipTrigger]="setting.settingsDescription ? NbTrigger.HINT : NbTrigger.NOOP"
          nbTooltipPlacement="top"
        >
          {{ setting.title }}
          <div *ngIf="setting.description" class="sidebar__checkbox__desc">
            {{ setting.description }}
          </div>
        </div>
        <nb-select
          *ngIf="setting.inputType === 'select'"
          class="sidebar__option"
          status="control"
          size="tiny"
          [formControlName]="setting.formControlName"
        >
          <nb-option
            *ngFor="let option of setting.options"
            class="sidebar__option__select_opt"
            [value]="option.value"
            [nbTooltip]="option.description"
            [nbTooltipTrigger]="option.description ? NbTrigger.HINT : NbTrigger.NOOP"
            nbTooltipPlacement="right"
            >{{ option.title }}</nb-option
          >
        </nb-select>
        <div *ngIf="setting.inputType === 'checkbox'">
          <div class="sidebar__input_row sidebar__input_row--checkbox">
            <nb-checkbox
              class="checkbox"
              [formControlName]="setting.formControlName"
              [nbTooltipDisabled]="!setting.tooltipText"
              [nbTooltip]="setting.tooltipText"
            ></nb-checkbox>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="prodTab">
    <div class="sidebar__label sidebar__label--group label">OrthoDSM Settings</div>
    <app-srs-dropdown formControlName="orthoDsmSrs"></app-srs-dropdown>

    <div class="sidebar__label sidebar__label--group label">Region Of Interest</div>
    <div class="sidebar__desc">
      Expand or shrink the blue box to specify excerpt of 3D scene that will be used in production
      step to render the model.
      <span (click)="toggleROIVideo()" class="hints" nbTooltip="Show tutorial">
        <nb-icon icon="question-mark-circle-outline"></nb-icon>
      </span>
    </div>
    <ng-container *ngIf="roi$ | async as roi">
      <ng-container *ngIf="roi.dimensions$ | async as dimensions">
        <div
          class="sidebar__desc sidebar__label--limit"
          [ngClass]="dimensions.length >= roi.maxSizeX - 0.5 ? 'end-limit' : ''"
        >
          <span>Length:</span>{{ dimensions.length | lengthUnit: unitSystem }} /
          {{ +roi.maxSizeX | lengthUnit: unitSystem }}
        </div>
        <div
          class="sidebar__desc sidebar__label--limit"
          [ngClass]="dimensions.height >= roi.maxSizeY - 0.5 ? 'end-limit' : ''"
        >
          <span>Heigth:</span>{{ dimensions.height | lengthUnit: unitSystem }} /
          {{ +roi.maxSizeY | lengthUnit: unitSystem }}
        </div>
        <div
          class="sidebar__desc sidebar__label--limit"
          [ngClass]="dimensions.width >= roi.maxSizeZ - 0.5 ? 'end-limit' : ''"
        >
          <span>Width:</span>{{ dimensions.width | lengthUnit: unitSystem }} /
          {{ +roi.maxSizeZ | lengthUnit: unitSystem }}
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-content></ng-content>
  <button class="sidebar__action restore_default" type="button" (click)="restoreDefault()">
    Restore default
  </button>
</div>

<span #unit class="unit">m</span>
