import { AbstractControl, Validators } from '@angular/forms';
import { phone } from 'phone';

export class FormValidators {
  static noOnlySpaces(control: AbstractControl) {
    return Validators.pattern(/\S/)(control);
  }
  static phoneValidator(control: AbstractControl) {
    const phoneNumber = control.value as string;
    const parsedPhoneNumber = phone(phoneNumber, { country: 'USA' });
    return parsedPhoneNumber.isValid ? null : { isInvalid: true };
  }
}
