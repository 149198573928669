<div
  class="content"
  [ngClass]="adjustContentHeight ? '--adjust-height' : null"
  data-testId="content"
>
  <nb-card status="primary" class="top-card sites-top">
    <nb-card-header>
      <ng-container *ngTemplateOutlet="filtersTpl"></ng-container>
    </nb-card-header>

    <nb-card-body>
      <ng-container *ngxPermissionsOnly="permissions.CAN_ACCESS_MODEL_LIST">
        <ng-container
          *ngTemplateOutlet="
            navigationButton;
            context: { postfix: 'models', iconLabel: 'cube-outline', label: 'Models' }
          "
        ></ng-container>
      </ng-container>
      <ng-container *ngxPermissionsOnly="permissions.CAN_ACCESS_PROCESSING_LIST">
        <ng-container
          *ngTemplateOutlet="
            navigationButton;
            context: { postfix: 'processing', iconLabel: 'loader-outline', label: 'Processing' }
          "
        ></ng-container>
      </ng-container>
      <ng-container *ngxPermissionsOnly="permissions.CAN_ACCESS_USER_LIST">
        <ng-container
          *ngTemplateOutlet="
            navigationButton;
            context: { postfix: 'users', iconLabel: 'people-outline', label: 'Users' }
          "
        ></ng-container>
      </ng-container>
    </nb-card-body>
  </nb-card>

  <div class="content-card sites-nav">
    <div class="sites-nav__item sites-nav__actions">
      <ng-container *ngTemplateOutlet="leftControlsTpl"></ng-container>
    </div>

    <div
      *ngIf="pageRangeInfo"
      class="sites-nav__item sites-nav__result sites-nav__result--models"
      data-testId="page-count-label"
    >
      <span class="sites-nav__current" *ngIf="pageRangeInfo.from && pageRangeInfo.to">
        {{ pageRangeInfo.from }} - {{ pageRangeInfo.to }}
      </span>
      <span class="sites-nav__total">
        {{ pageRangeInfo.total ? 'of' : '' }} {{ pageRangeInfo.total }} results</span
      >
    </div>

    <div class="sites-nav__item sites-nav__modes sites-nav__selected">
      <ng-container *ngTemplateOutlet="rightControlsTpl"></ng-container>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>

<nb-layout *ngIf="nbLayoutActive"> </nb-layout>

<ng-template #navigationButton let-postfix="postfix" let-iconLabel="iconLabel" let-label="label">
  <a
    [routerLink]="['/', companyId, postfix]"
    routerLinkActive
    [routerLinkActiveOptions]="{ exact: false }"
    nbButton
    #rla="routerLinkActive"
    [appearance]="rla.isActive ? 'filled' : 'ghost'"
    size="small"
    status="primary"
    ><nb-icon icon="{{ iconLabel }}"></nb-icon> {{ label }}</a
  >
</ng-template>
