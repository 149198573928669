import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { ListNode } from '@App/app/entities/layer/list-node.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesOnlyFolders',
})
export class IncludesOnlyFoldersPipe implements PipeTransform {
  transform(node: ListNode) {
    return node.children.every((child) => child.layer.type === LAYER_TYPES.FOLDERS);
  }
}
