import { Component } from '@angular/core';

@Component({
  selector: 'app-viewer-gcp-legend',
  templateUrl: './viewer-gcp-legend.component.html',
  styleUrls: ['./viewer-gcp-legend.component.scss'],
})
export class ViewerGcpLegendComponent {
  sphereIcon = '../../../../assets/svg/icons/sphere.svg';
}
