import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelSiteHeaderComponent } from './model-site-header.component';
import { NebularModule } from '@App/app/modules';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [ModelSiteHeaderComponent],
  imports: [RouterModule, NebularModule, CommonModule, NgxPermissionsModule],
  exports: [ModelSiteHeaderComponent],
})
export class ModelSiteHeaderModule {}
