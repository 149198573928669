<div #container class="container">
  <div class="empty"></div>
  <div class="line__vertical"></div>
  <div class="empty"></div>
  <div class="line__horizontal"></div>
  <div #viewfinder class="viewfinder">
    <div class="viewfinder__dot"></div>
  </div>
  <div class="line__horizontal"></div>
  <div class="empty"></div>
  <div class="line__vertical"></div>
  <div class="empty"></div>
</div>
