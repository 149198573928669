import { httpLayer } from '@App/app/entities/layer/layer.model';
import { SketchLine } from '@App/app/entities/layer/sketch-tools/tools/sketch-line.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { Injectable } from '@angular/core';
import { AbstractMesh, ActionManager, Mesh, PickingInfo, Tags, TransformNode } from 'babylonjs';
import {
  DRAFT_SKETCH_LINE_TAG_NAME,
  NEW_SKETCH_LINE_NAME,
  SKETCH_LINE_NAME,
} from 'src/app/configs/babylon.config';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { v4 as uuidv4 } from 'uuid';
import { BabylonNodesService as NodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { MaterialService } from '../../../material-service/material.service';
import { SceneService } from '../../../scene-service/scene.service';
import { UtilsService } from '../../../utils-service/utils.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';
import { SketchToolService } from '../shared/sketch-tool.service';
import { SketchesUtilsService } from '../shared/sketches-utils.service';
import { SketchLineUtilsService } from './sketch-line-utils.service';

@Injectable()
export class SketchLineService extends SketchToolService<SketchLine> {
  private _draftRoot: TransformNode | null;
  private _boxId = 0; // helpers
  handleTakingEvent = EVENT_TYPE.TAKE_SKETCH_LINE;

  constructor(
    private _utilsService: SketchLineUtilsService,
    broadcastService: BroadcastService,
    materialService: MaterialService,
    sketchesUtilsService: SketchesUtilsService,
    utilsService: UtilsService,
    babylonNodesService: NodesService,
    sceneService: SceneService,
    viewerLayerService: ViewerLayerService,
  ) {
    super(
      broadcastService,
      materialService,
      sketchesUtilsService,
      utilsService,
      babylonNodesService,
      sceneService,
      viewerLayerService,
    );
  }

  setMeshesDragBehavior(sketchLine: SketchLine) {
    if (this.currentSketchPlane?.normal) {
      const drag = this.setDragBehavior(this.currentSketchPlane.normal);
      drag.onDragObservable.add(() => {
        this._utilsService.updateSketchLinesMesh(sketchLine);
      });
      drag.onDragEndObservable.add(() => {
        this._utilsService.updateVertexPositions(sketchLine, this.draftSketches);
      });
      sketchLine.pointsMeshes.forEach((pointMesh) => {
        pointMesh.actionManager = new ActionManager(this.sceneService.scene);
        pointMesh.actionManager.registerAction(
          this.onPickAction(drag, this.materialService.dragMeshMaterial, this.draftSketches),
        );
      });
    }
  }

  getDraftSketches(): SketchLine[] {
    let result: httpLayer[] = [];
    if (this.draftSketches && this.draftSketches.length) {
      result = this.draftSketches.map((line) => SketchLine.createBodyForHttpRequest(line));
      this.sketchesUtilsService.disposeDraftSketchNodesByTag(DRAFT_SKETCH_LINE_TAG_NAME);
      this.draftSketches = [];
      this.currentSketchPlane = null;
      this.removeDraftSketch();
    }
    return result as SketchLine[];
  }

  removeDraftSketch() {
    const [line, lines, root] = [this.draftSketch, this.draftSketches, this._draftRoot];
    const res = this._utilsService.removeDraftSketch(line, lines, root);
    [this.draftSketch, this.draftSketches, this._draftRoot] = res;
  }

  removeUnfinishedDraftSketch() {
    if (this.draftSketch && !this.draftSketch.data.closed) {
      this.sketchesUtilsService.deleteDraftSketch(this.draftSketch);
      this.draftSketch = null;
    }
  }

  showSketch(sketchLine: SketchLine): SketchLine {
    this.sceneService.scene.getNodeByName(`${SKETCH_LINE_NAME}${sketchLine.id}`)?.dispose();
    const root = new AbstractMesh(`${SKETCH_LINE_NAME}${sketchLine.id}`, this.sceneService.scene);
    root.id = `${sketchLine.id}`;
    this._boxId = this._utilsService.vertexToMeshes(sketchLine, root, 0);
    sketchLine.linesMesh = this._utilsService.finishLine(sketchLine);
    sketchLine.linesMesh.parent = root;
    return sketchLine;
  }

  finishSketch(): void {
    if (this.draftSketch) {
      const [line, lines, root] = [this.draftSketch, this.draftSketches, this._draftRoot];
      this._utilsService.finishSketch(line, lines, root);
      this.removePointerMeshEventListener();
      this.setMeshesDragBehavior(this.draftSketch);
    }
  }

  handleMouseMove = () => {
    const hit = this.utilsService.pickRay(this.predicate);
    if (hit?.pickedPoint) {
      this.pointer.position = hit.pickedPoint;
      if (this.draftSketch && this.draftSketch.pointsMeshes.length >= 1) {
        const [line, pointer] = [this.draftSketch, this.pointer];
        this.draftSketch.linesMesh = this._utilsService.updateLine(line, pointer);
      }
    }
  };

  handleTakingSketch(): void {
    if (this.draftSketch && !this.draftSketch.data.closed) {
      this.sketchesUtilsService.deleteDraftSketch(this.draftSketch);
      this.draftSketch = null;
    }
  }

  handleClickAction(hit: PickingInfo) {
    const firstPointClicked = hit.pickedMesh?.name === this.draftSketch?.pointsMeshes[0]?.name;
    if (firstPointClicked && !this.draftSketch?.data.closed) {
      return this.finishSketch();
    }
    this._createDraftRoot();
    this._createFirstLine();
    this._createLines();
    Tags.AddTagsTo(this._draftRoot, `${DRAFT_SKETCH_LINE_TAG_NAME}`);
    const box = this._initSphereOnClick(hit);
    this.broadcastService.broadcast(EVENT_TYPE.REFRESH_CUSTOM_LOD, null);
    if (box) {
      this.draftSketch?.pointsMeshes.push(box);
      this._utilsService.createLinesMesh(box, this.draftSketch, this.pointer, this._draftRoot);
    }
  }

  onClickAction(): boolean {
    if (!this.currentSketchPlane) {
      return false;
    }
    // eslint-disable-next-line prettier/prettier
    if (!this.pointer) this.pointer = this.initPointer();
    const predicate = (mesh: Mesh) =>
      mesh.name === this.draftSketch?.pointsMeshes[0]?.name ||
      mesh.name === this.currentSketchPlane?.plane?.fillingPlane.name;
    const hit = this.utilsService.pickRay(predicate);
    if (hit?.pickedPoint) {
      this.handleClickAction(hit);
    }
    return true;
  }

  private _initSphereOnClick(hit: PickingInfo) {
    if (hit.pickedPoint && this._draftRoot) {
      return this._utilsService.initSphere(hit.pickedPoint, this._draftRoot, ++this._boxId);
    }
  }

  private _createDraftRoot() {
    if (!this._draftRoot) {
      this._draftRoot = new AbstractMesh(`${NEW_SKETCH_LINE_NAME}`, this.sceneService.scene);
      this._boxId = 0;
    }
  }

  private _createFirstLine() {
    if (!this.draftSketch && this.currentSketchPlane) {
      const { id } = this.currentSketchPlane;
      this.draftSketch = new SketchLine(id, (uuidv4() as any) as number);
    }
  }

  private _createLines() {
    if (this.draftSketch) {
      if (this.currentSketchPlane && this.draftSketch.data.closed) {
        const uuid = (uuidv4() as any) as number;
        this.draftSketch = new SketchLine(this.currentSketchPlane.id, uuid);
        this._draftRoot = new AbstractMesh(
          `${NEW_SKETCH_LINE_NAME}${uuid}`,
          this.sceneService.scene,
        );
        this._boxId = 0;
      }
      if (this.draftSketch.pointsMeshes.length < 1) {
        this.addPointerMeshEventListener();
      }
    }
  }
}
