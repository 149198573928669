import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { Vector3 } from 'babylonjs';
import { BehaviorSubject } from 'rxjs';

export class CoordsToolService {
  protected _coordsToolActive$ = new BehaviorSubject<boolean>(false);
  protected _currentCoords$ = new BehaviorSubject<Vector3 | GeoCoords | null>(null);

  constructor() {}

  onMoveAction() {
    if (this._coordsToolActive$.value) {
      this.updateCoords();
    }
  }

  onClickAction() {
    if (this._coordsToolActive$.value) {
      this.saveCoords();
    }
  }

  setCoordsToolActive(value: boolean) {
    this._coordsToolActive$.next(value);
  }

  updateCoords() {}

  saveCoords() {}
}
