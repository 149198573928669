<div class="content-card models_grid">
  <app-grid
    class="models_grid__grid"
    [entities]="models"
    [loading]="(loading$ | async) ?? true"
    noEntitiesPlaceholder="No model to show"
    [cellContentTpl]="cellContentTpl"
    [footerContentTpl]="footerContentTpl"
    (loadNext)="onLoadNext($event)"
    [threshold]="20"
  ></app-grid>
</div>

<ng-template #cellContentTpl let-model="entity">
  <nb-card
    (click)="onClick(model)"
    status="basic"
    class="models_grid__card --clickable"
    data-testId="model-cell"
  >
    <nb-card-body>
      <app-model-type-badge
        [modelType]="model.modelType"
        tooltip="Model type"
        class="models_grid__card__icon --model_type"
      ></app-model-type-badge>
      <nb-badge
        class="models_grid__card__icon --renders_count"
        [text]="model.renderCount"
        nbTooltip="Renders count"
        nbTooltipPlacement="top"
        status="info"
      ></nb-badge>
      <img
        *ngIf="model | thumbnail as thumb"
        class="models_grid__card__image"
        [src]="thumb"
        [ngClass]="thumb === THUMBNAIL_PLACEHOLDER ? '--placeholder' : ''"
        alt="model thumbnail"
      />
      <div class="models_grid__card__label">
        <div class="models_grid__card__label__row">
          <p class="models_grid__card__label__row__model_name">{{ model.name }}</p>
        </div>
        <div class="models_grid__card__label__row">
          <span class="models_grid__card__label__row__address">{{
            (model | modelAddress) || '-'
          }}</span>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #footerContentTpl>
  <div *ngIf="showStatisticsButton$ | async" class="models_grid__download_analytics_btn_wrapper">
    <app-download-analytics-button></app-download-analytics-button>
  </div>
</ng-template>
