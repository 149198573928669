import { NameTag } from '@App/app/entities/layer/name-tag.model';
import { AssetContainer } from 'babylonjs';

export class AssetContainerWithGui extends AssetContainer {
  GUIs: NameTag[] = [];

  addAllToScene() {
    super.addAllToScene();
    this.showGui();
  }

  removeAllFromScene() {
    super.removeAllFromScene();
    this._hideGcpGUI();
  }

  private showGui() {
    this.GUIs.forEach((gui) => {
      const { arrow, bg, text } = gui;
      if (arrow && bg && text) {
        arrow.isVisible = true;
        bg.isVisible = true;
        text.isVisible = true;
      }
    });
  }

  private _hideGcpGUI() {
    this.GUIs.forEach((gui) => {
      const { arrow, bg, text } = gui;
      if (arrow && bg && text) {
        arrow.isVisible = false;
        bg.isVisible = false;
        text.isVisible = false;
      }
    });
  }
}
