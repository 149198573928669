import { GizmoManager } from 'babylonjs';
import { BaseDxfExportableViewerLayer } from './base-dxf-exportable-viewer-layer.model';
import { AUTOMAPPING_IMPORTABLE_LAYER, CUBOID_PART, LAYER_TYPES } from './enums/layer-types.enum';
import { Layer } from './layer.model';
import { Coordinates3, Coordinates4 } from './measurements/coordinates';

export class Cuboid implements Layer<CuboidData> {
  id: number;
  name: string = LAYER_TYPES.CUBOID;
  createdAt: string;
  editedAt: string;
  data: CuboidData = {};
  parentId: number;
  type = LAYER_TYPES.CUBOID;
  isVisible = false;
  isSaved = true;
  description: string;

  static move(layer: AUTOMAPPING_IMPORTABLE_LAYER, shiftValue: [number, number]) {
    const cuboid = layer as Cuboid;
    if (cuboid.data.min && cuboid.data.max) {
      cuboid.data.min.x -= shiftValue[0];
      cuboid.data.min.y -= shiftValue[1];
      cuboid.data.max.x -= shiftValue[0];
      cuboid.data.max.y -= shiftValue[1];
    }
  }
}
export interface CuboidData {
  absoluteRotationQuaternion?: Coordinates4;
  min?: Coordinates3;
  max?: Coordinates3;
  color?: string;
  volume?: number;
  onHeightDistance?: number;
  towerCenterDistance?: number;
  azimuth?: number;
  verticalTilt?: number;
  worldDirection?: number;
}

export interface ViewerCuboidLayer extends Cuboid, BaseDxfExportableViewerLayer {
  gizmoManager?: GizmoManager;
  data: CuboidData;
}

export interface CuboidPartsArrays<T> {
  [CUBOID_PART.FRONT_BACK]: T[];
  [CUBOID_PART.TOP_BOTTOM]: T[];
  [CUBOID_PART.LEFT_RIGHT]: T[];
}
