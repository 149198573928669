import { Photo } from '@App/app/entities/files/files.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'annotationsReportAvailable',
})
export class AnnotationsReportAvailablePipe implements PipeTransform {
  transform(photos: Photo[]): boolean {
    return photos.some((photo) => photo.data.annotations.length);
  }
}
