import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/processes.actions';

export const processesAdapter = createEntityAdapter<BuildProcess>();

export type State = EntityState<BuildProcess>;

const defaultProcesses: State = {
  ids: [],
  entities: {},
};

export const initialState: State = processesAdapter.getInitialState(defaultProcesses);

export const processesReducer = createReducer(
  initialState,
  on(actions.loadAllProcessesSuccess, (state, { processes }) => {
    return processesAdapter.setAll(processes, state);
  }),
  on(actions.removeAllProcessesLocal, (state) => {
    return processesAdapter.removeAll(state);
  }),
  on(actions.updateProcessSuccessLocal, (state, { process }) => {
    return processesAdapter.setOne(process, state);
  }),
  on(actions.archiveProcessSuccess, (state, { process }) => {
    return processesAdapter.setOne(process, state);
  }),
  on(actions.removeProcessLocal, (state, { id }) => {
    return processesAdapter.removeOne(id, state);
  }),
  on(actions.deleteProcessesSuccess, (state, { processesId }) => {
    return processesAdapter.removeMany(processesId, state);
  }),
  on(actions.deleteProcessesFailed, (state, { deletedProcesses }) => {
    const ids = deletedProcesses.map((deletedProcess) => deletedProcess.id);
    return processesAdapter.removeMany(ids, state);
  }),
);
