<app-model-site-header
  [label]="'Add User'"
  [backUrl]="'/' + companyId + '/users'"
></app-model-site-header>
<app-user-form
  [requestSended]="requestSended$ | async"
  [parentForm]="form"
  (submitted)="onSubmit($event)"
>
  <h1>Add User</h1>
  <ng-container [formGroup]="form">
    <div class="model-form__section">
      <label for="password" class="model-form__label">Password</label>
      <nb-form-field>
        <input
          nbInput
          id="password"
          [type]="showPassword ? 'text' : 'password'"
          placeholder="Password"
          formControlName="password"
        />
        <button nbSuffix nbButton ghost type="button" (click)="toggleShowPassword()">
          <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"></nb-icon>
        </button>
      </nb-form-field>
      <div
        class="text text--error"
        *ngIf="form.controls.password.touched && form.controls.password.errors?.required"
      >
        Password is required
      </div>
      <ng-container
        *ngIf="form.controls.password.touched && form.controls.password.errors?.pattern"
      >
        <app-wrong-password-error></app-wrong-password-error>
      </ng-container>
    </div>
    <div class="model-form__section">
      <label for="confirmPassword" class="model-form__label">Confirm password</label>
      <nb-form-field>
        <input
          nbInput
          id="confirmPassword"
          [type]="showConfirmPassword ? 'text' : 'password'"
          placeholder="Confirm password"
          formControlName="confirmPassword"
        />
        <button nbSuffix nbButton ghost type="button" (click)="toggleShowConfirmPassword()">
          <nb-icon [icon]="showConfirmPassword ? 'eye-outline' : 'eye-off-2-outline'"></nb-icon>
        </button>
      </nb-form-field>
      <div
        class="text text--error"
        *ngIf="
          form.controls.confirmPassword.errors?.required && form.controls.confirmPassword.touched
        "
      >
        Password confirmation is required
      </div>
      <div
        class="text text--error"
        *ngIf="
          form.controls.confirmPassword.errors?.mustMatch && form.controls.confirmPassword.touched
        "
      >
        Password and confirm password must be match.
      </div>
    </div>
  </ng-container>
</app-user-form>
