import { Injectable } from '@angular/core';
import { MaterialService } from '@App/app/engine/services/material-service/material.service';
import { UtilsService } from '@App/app/engine/services/utils-service/utils.service';
import { TiePointMeasurement } from '@App/app/entities/files/tie-point-model';
import { PhotoDimensions } from '@App/app/entities/shared/parameters.model';
import { Mesh, MeshBuilder, Vector3 } from 'babylonjs';
import { BehaviorSubject } from 'rxjs';
import { DECAL_NAME } from 'src/app/configs/babylon.config';

@Injectable({
  providedIn: 'root',
})
export class PhotoMarkerService {
  photoMarker?: Mesh;

  private _markerActive$ = new BehaviorSubject<boolean>(true);
  markerActive$ = this._markerActive$.asObservable();

  constructor(private utilsService: UtilsService, private materialService: MaterialService) {
    this.markerActive$.subscribe((active) => {
      if (this.photoMarker) {
        this.photoMarker.visibility = active ? 1 : 0;
      }
    });
  }

  markerPosition(photo: Mesh, photoDimension: PhotoDimensions, measurement: TiePointMeasurement) {
    const vertices = this.utilsService.getRawVertices(photo);
    const width = Math.abs(vertices[0][0]) + Math.abs(vertices[2][0]);
    const height = Math.abs(vertices[0][1]) + Math.abs(vertices[2][1]);
    const pointPositionX = (width * +measurement.x) / photoDimension.width;
    const pointPositionY = (height * +measurement.y) / photoDimension.height;
    const worldMatrix = photo.getWorldMatrix();
    const positionInLocalMatrix = new Vector3(
      vertices[0][0] + pointPositionX,
      vertices[2][1] - pointPositionY,
      0,
    );
    return Vector3.TransformCoordinates(positionInLocalMatrix, worldMatrix);
  }

  createMarker(photo: Mesh, position: Vector3, normal: Vector3) {
    this.disposeMarker();
    const decalSize = new Vector3(0.2, 0.2, 0.2);
    this.photoMarker = MeshBuilder.CreateDecal(DECAL_NAME, photo, {
      position,
      normal,
      size: decalSize,
    });
    this.photoMarker.renderingGroupId = 3;
    this.photoMarker.material = this.materialService.decalMaterial;
    this.photoMarker.visibility = this._markerActive$.value ? 1 : 0;
  }

  toggleMarkerVisibility() {
    this._markerActive$.next(!this._markerActive$.value);
  }

  disposeMarker() {
    if (this.photoMarker) {
      this.photoMarker.material?.dispose();
      this.photoMarker.material = null;
      this.photoMarker.dispose(true, true);
      delete this.photoMarker;
    }
  }
}
