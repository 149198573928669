<div class="photos_grid" [@mapOverlap]="mapOverlap ? 'overlap' : 'notOverlap'">
  <div class="photos_grid__header">
    <div class="photos_grid__filters" [formGroup]="form">
      <input
        nbInput
        placeholder="Search photo"
        formControlName="keywords"
        data-testId="photos-grid-keywords-input"
        class="photos_grid__filters__control"
      />
      <nb-select
        placeholder="Sort images"
        formControlName="sortBy"
        data-testId="photos-grid-sortBy-input"
        class="photos_grid__filters__control"
      >
        <nb-select-label *ngIf="form.controls.sortBy.value">{{
          form.controls.sortBy.value | sortLabel
        }}</nb-select-label>
        <nb-option-group *ngFor="let group of SortingOptions | keyvalue" [title]="group.key">
          <nb-option
            *ngFor="let option of group.value | keyvalue"
            class="photos_grid__filters__control__select_opt"
            [value]="option.value"
            [disabled]="group.value === SortingOptions.Metadata && !activeControlPointName"
            >{{ option.value }}</nb-option
          >
        </nb-option-group>
      </nb-select>
      <button
        nbButton
        (click)="resetFilters()"
        nbTooltip="Reset filters"
        data-testId="photos-grid-clear-filters-btn"
        class="photos_grid__filters__control"
      >
        <nb-icon icon="close-outline"></nb-icon>
      </button>
    </div>
    <div>
      <button
        nbButton
        ghost
        (click)="toggleMapOverlapping()"
        [nbTooltip]="mapOverlap ? 'Show map' : 'Hide map'"
        size="tiny"
        class="photos_grid__header__overlap_toggler"
      >
        <nb-icon
          [icon]="mapOverlap ? 'arrow-ios-downward-outline' : 'arrow-ios-upward-outline'"
        ></nb-icon>
      </button>
    </div>
  </div>

  <ng-container
    *ngIf="(photos | photoGridFilter: { form, activeControlPointName }).length; else noPhotoInfo"
  >
    <div
      [ngClass]="
        mapOverlap ? 'photos_grid__inner' : 'photos_grid__inner photos_grid__inner__hidden'
      "
    >
      <nb-card
        *ngFor="let photo of photos | photoGridFilter: { form, activeControlPointName }"
        status="basic"
        class="photo-card"
        data-testId="photos-grid-photo-card"
        [attr.data-name]="photo.name"
        [nbTooltip]="photo.name"
      >
        <nb-card-body (click)="onClick(photo)">
          <div *ngIf="isMarked(photo.name)" class="photo-card__desc">
            <nb-icon icon="checkmark-outline"></nb-icon>
          </div>
          <img [src]="photo | photoThumbnail" loading="lazy" alt="site photo" />
        </nb-card-body>
      </nb-card>
    </div>
  </ng-container>

  <ng-template #noPhotoInfo>
    <div class="photos_grid__no_photo_info">
      <span>No photos to show</span>
    </div>
  </ng-template>
</div>
