<nb-card class="confirmation_dialog">
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body *ngIf="details || options">
    <div
      *ngIf="useInnerHTML; else rawText"
      [innerHTML]="details"
      class="confirmation_dialog__details"
    ></div>
    <ng-template #rawText class="confirmation_dialog__details">{{ details }}</ng-template>
    <nb-radio-group *ngIf="options" [(value)]="selectedOption" class="confirmation_dialog__options">
      <nb-radio *ngFor="let option of options; index as i" [value]="option" [checked]="i === 0">
        {{ option }}
      </nb-radio>
    </nb-radio-group>
  </nb-card-body>
  <nb-card-body *ngIf="keywordMode">
    <div>
      For confirm please type: <b>{{ keyword }}</b>
      <div>
        <input
          nbInput
          type="text"
          class="confirmation_dialog__keyword_input"
          (input)="onChangeInput($event)"
        />
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="confirmation_dialog__buttons">
    <button nbButton class="confirmation_dialog__button" status="basic" (click)="onCancel()">
      Cancel
    </button>
    <button
      *ngIf="showConfirmButton"
      nbButton
      class="confirmation_dialog__button"
      [disabled]="keywordMode && !keywordIsValid"
      [status]="status"
      (click)="onConfirm()"
    >
      Confirm
    </button>
  </nb-card-footer>
</nb-card>
