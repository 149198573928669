import { AuthUser } from '@App/app/entities/users/user';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, switchMapTo, tap } from 'rxjs/operators';
import { selectHasDocsAccepted, selectUser } from '../../store/auth/selectors/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class HasDocsAcceptedGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store, private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._checkUserHasDocsAccepted();
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._checkUserHasDocsAccepted();
  }

  private _checkUserHasDocsAccepted() {
    return this.store.select(selectUser).pipe(
      filter<AuthUser>(Boolean),
      switchMapTo(this.store.select(selectHasDocsAccepted)),
      first(),
      tap((hasAccepted) => {
        if (!hasAccepted) {
          this._router.navigate(['documents-acceptance']);
        }
      }),
    );
  }
}
