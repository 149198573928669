import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { LAYER_EVENTS, LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import {
  deleteLayersLocal,
  hideLayer,
  hideLayers,
  showLayer,
  showLayers,
} from '@App/app/pages/viewer/store/actions/layers.actions';
import {
  selectAllLayers,
  selectAllNestedChildLayers,
  selectFolders,
} from '@App/app/pages/viewer/store/selectors/layers.selectors';
import {
  getChildFoldersAsFlatList,
  getLayersByParentId,
} from '@App/app/pages/viewer/utils/layers.utils';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first, take, withLatestFrom } from 'rxjs/operators';
import { ThicknessFormService } from '../thickness-utils/thickness-form.service';
import { UnitsService } from '../utils/units.service';

@UntilDestroy()
@Injectable()
export class LayersViewerUtilsService {
  constructor(
    private store: Store,
    private _viewerLayerService: ViewerLayerService,
    private _layerEventsService: LayerEventsService,
    private _thicknessFormService: ThicknessFormService,
    private _engineService: CustomerEngineService,
    private _unitsService: UnitsService,
    private _broadcastService: BroadcastService,
  ) {
    this._broadcastService
      .on(EVENT_TYPE.MESH_RESTORE_DEFAULT)
      .pipe(untilDestroyed(this), withLatestFrom(this.store.select(selectAllLayers)))
      .subscribe(([parentId, layers]: [number, LayerUI[]]) => {
        const childLayers = getLayersByParentId(layers, parentId);
        const childLayersIds = childLayers.map((l) => l.id);
        this._viewerLayerService.restoreAllSketchToolsMeshesDefault(childLayersIds);
      });

    this._unitsService.isImperialActive$.pipe(untilDestroyed(this)).subscribe((active) => {
      this._viewerLayerService.toggleImperialGui(active);
    });
  }

  createBackupLayer(layer: LayerUI) {
    this._viewerLayerService.createBackupLayer(layer);
  }

  loadBackupLayer(id: number) {
    this._viewerLayerService.loadBackupLayer(id);
  }

  setLayerVisibility(layer: LayerUI, value: boolean, allLayers: LayerUI[]) {
    if (layer.type === LAYER_TYPES.SKETCH_PLANES) {
      const childLayersIds = allLayers
        .filter((item) => item.parentId === layer.id)
        .map((i) => i.id);
      const ids = [layer.id, ...childLayersIds];
      this.store.dispatch(value ? showLayers({ ids }) : hideLayers({ ids }));
    } else {
      this.store.dispatch(value ? showLayer({ id: layer.id }) : hideLayer({ id: layer.id }));
    }
  }

  setLayerGuiVisibility(id: number, value: boolean) {
    if (value) {
      this._viewerLayerService.showLayerGui(id);
    } else {
      this._viewerLayerService.hideLayerGui(id);
    }
  }

  setLayerEditMode(
    layer: LayerUI,
    value: boolean,
    allLayers: LayerUI[],
    editedLayers: LayerUI[],
    pushToEditedLayers: boolean,
  ) {
    if (value && pushToEditedLayers) {
      editedLayers.push(layer);
    }
    if (layer.type === LAYER_TYPES.POLYGONS) {
      this._layerEventsService.activeLayerEvent.next(
        value ? LAYER_EVENTS.EDIT_POLYGON : LAYER_EVENTS.NULL,
      );
    }
    const childLayers = allLayers.filter((l) => l.parentId === layer.id);
    childLayers.forEach((item) => {
      this._viewerLayerService.setLayerEditMode(item.id, value);
    });

    this._thicknessFormService.initForm(layer);
    this._engineService.activeCursorMove(value, false);
    this._viewerLayerService.setLayerEditMode(layer.id, value);
  }

  deleteNestedFromViewer(ids: number[]) {
    this.store
      .select(selectFolders)
      .pipe(first())
      .subscribe((folders) => {
        for (const id of ids) {
          this._viewerLayerService.deleteLayer(id);
          const childFolders = getChildFoldersAsFlatList(id, folders);
          const childFolderIds = childFolders.map((f) => f.id);
          this.store
            .select(selectAllNestedChildLayers([id, ...childFolderIds]))
            .pipe(take(1))
            .subscribe((layers) => {
              layers.forEach((l) => {
                if (l.type === LAYER_TYPES.SKETCH_PLANES) {
                  this.deleteNestedFromViewer([l.id]);
                } else {
                  this._viewerLayerService.deleteLayer(l.id);
                }
              });
              const allIds = [id, ...layers.map((l) => l.id), ...childFolderIds];
              this.store.dispatch(deleteLayersLocal({ ids: allIds }));
            });
        }
      });
  }
}
