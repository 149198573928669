import { selectUiSettings } from '@App/app/auth/store/auth/selectors/auth.selectors';
import { VIEWER_LOD_CONFIG } from '@App/app/configs/viewer.config';
import { CustomLODService } from '@App/app/engine/services/custom-lod-service/custom-lod.service';
import { BaseEngineService } from '@App/app/engine/services/engine-services/base-engine-service/base-engine.service';
import { ProcessingEngineService } from '@App/app/engine/services/engine-services/processing-engine-service/processing-engine.service';
import { RegionOfInterestService } from '@App/app/engine/services/region-of-interest-service/region-of-interest.service';
import { SceneService } from '@App/app/engine/services/scene-service/scene.service';
import { ViewerCameraPointsService } from '@App/app/engine/services/viewer-camera-point-service/viewer-camera-point.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { RegionOfInterest } from '@App/app/entities/processing/region-of-interest.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { UiSettings } from '@App/app/entities/users/user';
import { TilesInfo } from '@App/app/entities/viewer/tiles-info.model';
import { getMemoryHeapUsage } from '@App/app/shared/utils/utils';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Camera, Color4 } from 'babylonjs';
import { ChangeContext, Options } from 'ng5-slider';
import { Observable, interval, merge } from 'rxjs';
import { filter, first, map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import {
  GREY_COLOR,
  SLIDER_OPTIONS,
  VIEWFINDER_SLIDER_OPTIONS,
  WHITE_COLOR,
} from 'src/app/configs/babylon.config';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { environment } from 'src/environments/environment';
import { CustomColorPresetComponent } from '../color-picker/custom-color-preset/custom-color-preset.component';
import { UnitsService } from '../services/utils/units.service';
import {
  updateCurrentBGColor,
  updateCurrentVFColor,
} from '../store/color-presets-store/actions/ui-settings.actions';

@UntilDestroy()
@Component({
  selector: 'app-camera-controls',
  templateUrl: './camera-controls.component.html',
  styleUrls: ['./camera-controls.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CameraControlsComponent implements OnInit {
  @Input() engineService: BaseEngineService;
  @Input() photoMode: boolean;
  backgroundSceneColor: string;
  viewfinderColor: string;
  sizeMesh = 1;
  tilesInfo: TilesInfo;
  tilesRange: number[];
  editMode: boolean;
  imperialUnits: boolean;
  isProd = environment.production;
  isModelLoaded = false;
  isChangeCameraButtonEnabled$: Observable<boolean>;
  cameraSpeed = SLIDER_OPTIONS.DEFAULT_SPEED_LEVEL;
  cameraOptions: Options = {
    floor: SLIDER_OPTIONS.MIN_VALUE,
    ceil: SLIDER_OPTIONS.MAX_VALUE,
  };
  viewfinderOptions: Options = {
    floor: VIEWFINDER_SLIDER_OPTIONS.MIN_VALUE,
    ceil: VIEWFINDER_SLIDER_OPTIONS.MAX_VALUE,
  };
  showCameraControlsTab$: Observable<boolean>;
  permissions = UserPermissions;
  roi$: Observable<RegionOfInterest | null>;
  heapSizeReport$: Observable<number>;
  totalCacheSize$: Observable<{ total: number; percentage: number }>;
  MAX_CACHE_SIZE = VIEWER_LOD_CONFIG.CACHE_LIMIT;
  isLODRegistered$: Observable<boolean>;
  shouldShowOnlyHQModel: boolean;

  constructor(
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private unitsService: UnitsService,
    private sceneService: SceneService,
    private viewerCameraPointsService: ViewerCameraPointsService,
    private dialogService: NbDialogService,
    private customLODService: CustomLODService,
    private store: Store,
    private processingEngineService: ProcessingEngineService,
    private regionOfInterestService: RegionOfInterestService,
  ) {
    this.store
      .select(selectUiSettings)
      .pipe(first())
      .pipe(untilDestroyed(this), filter<UiSettings>(Boolean))
      .subscribe((uiSettings) => {
        this.sceneService.sceneBackgroundColor = Color4.FromHexString(
          uiSettings.currentPreset?.backgroundColor || GREY_COLOR,
        );
        this.viewerCameraPointsService.photoMeshColor = Color4.FromHexString(
          uiSettings.currentPreset?.viewFinderColor || WHITE_COLOR,
        );
        this.backgroundSceneColor = uiSettings.currentPreset?.backgroundColor || GREY_COLOR;
        this.viewfinderColor = uiSettings.currentPreset?.viewFinderColor || WHITE_COLOR;
      });
    this.isChangeCameraButtonEnabled$ = broadcastService
      .on<boolean>(EVENT_TYPE.CAMERA_MODE_BUTTON_STATUS)
      .pipe(startWith(false));

    merge([broadcastService.on(EVENT_TYPE.LOAD_MODEL), this.authService.currentEndpoint$])
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.isModelLoaded = false));
  }

  ngOnInit(): void {
    this.tilesInfo = this.engineService.getTilesInfo();
    this.tilesRange = [...this.tilesInfo.defaultTileLoad];
    this.editMode = this.engineService.isEditMode();
    this.imperialUnits = this.unitsService.getImperialUnitsActive();
    this.showCameraControlsTab$ = this.engineService.isCameraControlsTabOpen$;
    this.roi$ = this.regionOfInterestService.regionOfInterest$;
    this.heapSizeReport$ = interval(1000).pipe(map(() => +(getMemoryHeapUsage() * 100).toFixed(2)));
    this.totalCacheSize$ = this.customLODService.totalCacheSize$.pipe(
      map((total) => +total?.toFixed(2) ?? 0),
      map((total) => ({ total, percentage: +((total / this.MAX_CACHE_SIZE) * 100).toFixed(2) })),
    );
    this.isLODRegistered$ = this.customLODService.registered$;
    this.shouldShowOnlyHQModel = this.engineService.isHQModelOnly;
  }

  toggleCameraControls(value: boolean) {
    this.engineService.setIsCameraControlsTabOpen(value);
  }

  onCameraSpeedChange({ value }: ChangeContext) {
    this.engineService.setCameraSpeed(value);
  }

  onSliceRangeChange(event: Event, position: number) {
    const value = (event.target as HTMLInputElement).valueAsNumber;
    this.tilesRange[position] = value;
    this.engineService.setTilesRange(this.tilesRange);
  }

  onUnitsChange(value: boolean) {
    this.unitsService.setImperialUnitsActive(value);
    this.imperialUnits = value;
  }

  onShowOnlyHQModelChange(value: boolean) {
    this.engineService.toggleOnlyHQModel(value);
    this.shouldShowOnlyHQModel = value;
  }

  onCameraChange() {
    this.processingEngineService.switchCameraToRoi();
  }

  setBackgroundPreset(value: string) {
    this.backgroundSceneColor = value;
  }

  setViewfinderPreset(value: string) {
    this.viewfinderColor = value;
  }

  onLoadModel() {
    this.broadcastService.broadcast(EVENT_TYPE.LOAD_MODEL, null);
    this.isModelLoaded = true;
  }

  changeCameraMode() {
    this.broadcastService.broadcast(
      EVENT_TYPE.CHANGE_CAMERA,
      this.engineService.camera?.mode === Camera.PERSPECTIVE_CAMERA
        ? Camera.ORTHOGRAPHIC_CAMERA
        : Camera.PERSPECTIVE_CAMERA,
    );
  }

  isReadyToLoadModel() {
    return this.engineService.isReadyToLoadModel() || false;
  }

  changeColorMesh(value: string) {
    this.viewfinderColor = value;
    this.viewerCameraPointsService.cameraMeshColor.next(value);
    this.store.dispatch(updateCurrentVFColor({ viewFinderColor: value }));
  }

  changeViewfinderSize(value: number) {
    this.sizeMesh = value;
    this.viewerCameraPointsService.cameraMeshEdgesWidth.next(value);
  }

  acceptColor(value: string) {
    this.backgroundSceneColor = value;
    this.sceneService.changeSceneColor(Color4.FromHexString(value));
    this.store.dispatch(updateCurrentBGColor({ backgroundColor: value }));
  }

  openCustomPresetPopup() {
    this.dialogService.open(CustomColorPresetComponent);
  }
}
