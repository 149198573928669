import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { RectTubeService } from '@App/app/engine/services/layer-services/rectangle-tools-services/rect-tube-services/rect-tube.service';
import {
  LAYER_EVENTS,
  RECT_TUBE_PART,
  RECT_TUBE_PART_NAME,
  RECT_TUBE_STRICT,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { RectTubePartsArrays } from '@App/app/entities/layer/rect-tube.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractMesh } from 'babylonjs';

@UntilDestroy()
@Component({
  selector: 'app-create-rect-tube-details',
  templateUrl: './create-rect-tube-details.component.html',
  styleUrls: ['./create-rect-tube-details.component.scss'],
})
export class CreateRectTubeDetailsComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() generate = new EventEmitter();

  LAYER_EVENTS = LAYER_EVENTS;
  RECT_TUBE_PART_NAME = RECT_TUBE_PART_NAME;
  activeLayerEvent: LAYER_EVENTS;
  rectTubeActivePart: RECT_TUBE_PART;
  rectTubeHighlightedSample: { part: RECT_TUBE_PART | null; i: number };
  rectTubeSamples: RectTubePartsArrays<AbstractMesh>;
  isGenerateAvailable: boolean;
  isDetailsVisible = true;

  constructor(
    private layerEventsService: LayerEventsService,
    private rectTubeService: RectTubeService,
  ) {}

  ngOnInit(): void {
    this.activeLayerEvent = this.layerEventsService.activeLayerEvent.value;
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_RECT_TUBE) {
      this.rectTubeSamples = this.rectTubeService.samples;
      this.rectTubeService.dataChange$.pipe(untilDestroyed(this)).subscribe(() => {
        if (this.rectTubeService.activePart) {
          this.rectTubeActivePart = this.rectTubeService.activePart;
        }
        this.rectTubeHighlightedSample = this.rectTubeService.highlightedSample;
        this.rectTubeSamples = this.rectTubeService.samples;
        this.isGenerateAvailable =
          this.rectTubeSamples[RECT_TUBE_PART.SURFACE].length > 2 &&
          this.rectTubeSamples[RECT_TUBE_PART.ADJACENT_SURFACE].length > 2 &&
          this.rectTubeSamples[RECT_TUBE_PART.INNER].length > 0;
      });
      this.rectTubeService.setActivePart(RECT_TUBE_PART.SURFACE);
    }
  }

  toggleHighlightRectTubeSample({ part, i }: { part: RECT_TUBE_STRICT; i: number }) {
    this.rectTubeService.highlightSample(part, i);
  }

  onDeleteRectTubeSample({ part, i }: { part: RECT_TUBE_STRICT; i: number }) {
    this.rectTubeService.deleteSample(part, i);
  }

  onGenerate() {
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_RECT_TUBE) {
      this.rectTubeService.generateRectTube();
      this.rectTubeService.setActivePart(RECT_TUBE_PART.NONE);
      this.generate.emit();
      this.isDetailsVisible = false;
    }
  }

  onSave() {
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_RECT_TUBE) {
      this.cancel.emit();
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  isRectTubeSampleHighlighted(part: RECT_TUBE_PART, i: number) {
    return this.rectTubeHighlightedSample.part === part && this.rectTubeHighlightedSample.i === i;
  }

  setRectTubeActivePart({ part }: { part: RECT_TUBE_STRICT }) {
    this.rectTubeService.setActivePart(part);
  }
}
