import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PresetsHttpService } from '../../services/presets-http/presets-http.service';
import { addPreset, addPresetFailure, addPresetSuccess } from '../actions/presets.actions';

@Injectable()
export class AddPresetEffects {
  constructor(private actions: Actions, private presetHttpService: PresetsHttpService) {}

  addPreset = createEffect(() => {
    return this.actions.pipe(
      ofType(addPreset),
      mergeMap(({ preset }) => {
        return this.presetHttpService.postPreset(preset).pipe(
          map((newPreset) => {
            return addPresetSuccess({ preset: newPreset });
          }),
          catchError(() => of(addPresetFailure())),
        );
      }),
    );
  });
}
