import { ViewerMeasurementLayer } from '@App/app/entities/layer/measurements/measurement.model';
import { BroadcastService } from '@App/app/shared/broadcast.service';
import { Injectable } from '@angular/core';
import { Mesh, Vector3 } from 'babylonjs';
import { ELECTRIC_BLUE_COLOR3, MEASUREMENT_SPHERES_NAMES } from 'src/app/configs/babylon.config';
import { LayerGizmoManager } from '../../../viewer-layers/gizmo-manager';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { SceneService } from '../../scene-service/scene.service';
import { UtilsService } from '../../utils-service/utils.service';

@Injectable({
  providedIn: 'root',
})
export class MeasurementsGizmosService extends LayerGizmoManager {
  constructor(
    private babylonNodesService: BabylonNodesService,
    sceneService: SceneService,
    utilsService: UtilsService,
    broadcastService: BroadcastService,
  ) {
    super(utilsService, sceneService, broadcastService);
  }

  onDragHandler(measurement: ViewerMeasurementLayer) {
    const middleMesh = measurement.meshes?.find(
      (mesh) => mesh.id === MEASUREMENT_SPHERES_NAMES.MIDDLE_SPHERE_NAME,
    );

    const points =
      measurement.meshes?.reduce((acc, mesh) => {
        if (mesh.id === MEASUREMENT_SPHERES_NAMES.PICKER_SPHERE_NAME) {
          acc.push(mesh.position);
        }
        return acc;
      }, [] as Vector3[]) || [];

    const middlePoint = this.utilsService.findMiddlePointOfTwoMeshes(points[0], points[1]);
    measurement.data.length = this.utilsService.getLengthBetweenPoints(points[0], points[1]);
    if (measurement.gui?.text) {
      measurement.gui.text.text = `${measurement.data.length}m`;
    }

    if (middleMesh) {
      middleMesh.position = middlePoint;
    }
    measurement.linesMesh = this.babylonNodesService.createLine(
      [points[0], points[1]],
      MEASUREMENT_SPHERES_NAMES.LINE_NAME,
      ELECTRIC_BLUE_COLOR3,
      { updatable: true, instance: measurement.linesMesh },
    );

    measurement.data.meshesPoints = measurement.meshes?.map((mesh: Mesh) => {
      return { x: mesh.position.x, y: mesh.position.y, z: mesh.position.z };
    });
  }
}
