<app-control-point-table title="Control Points">
  <nb-list data-testId="control-points-list">
    <nb-list-item
      *ngFor="let point of controlPoints"
      [ngClass]="{ 'list-item--selected': point.name === selectedPointName }"
      class="list-item control-point-list-item"
      (click)="selectPoint(point.name)"
    >
      <div class="list-item__wrapper">
        <div class="list-item__subwrapper">
          <span class="list-item__name">{{ point.name }}</span>
          <div
            class="list-item__progress"
            [ngClass]="[
              getNumOfMeasurements(point.name) === 1 ? 'list-item__progress--danger' : '',
              getNumOfMeasurements(point.name) === 2 ? 'list-item__progress--warning' : '',
              getNumOfMeasurements(point.name) > 2 ? 'list-item__progress--success' : ''
            ]"
          >
            <span
              data-testId="badge-one"
              [ngClass]="{ '--inactive': getNumOfMeasurements(point.name) < 1 }"
            ></span>
            <span
              data-testId="badge-two"
              [ngClass]="{ '--inactive': getNumOfMeasurements(point.name) < 2 }"
            ></span>
            <span
              data-testId="badge-three"
              [ngClass]="{ '--inactive': getNumOfMeasurements(point.name) < 3 }"
            ></span>
          </div>
        </div>
        <span class="list-item__coords">
          <span class="list-item__coords__item">Lat: {{ point.lat.toFixed(6) }}</span>
          <span class="list-item__coords__item">Long: {{ point.long.toFixed(6) }}</span>
          <span class="list-item__coords__item">Alt: {{ point.alt.toFixed(2) }}</span>
        </span>
      </div>
    </nb-list-item>
  </nb-list>
</app-control-point-table>
