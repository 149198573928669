import { ApiService } from '@App/app/shared/api.service';
import { environment } from '@App/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoiLimit } from '../../models/roi-limits.model';

@Injectable()
export class RegionOfInterestHttpService extends ApiService {
  private _loginUrl = environment.loginUrl;

  getRoiLimits(): Observable<RoiLimit[]> {
    return this.http.get<RoiLimit[]>(`${this._loginUrl}/roi-limits`);
  }

  postRoiLimit(form: RoiLimit): Observable<RoiLimit> {
    return this.http.post<RoiLimit>(`${this._loginUrl}/roi-limits`, form);
  }

  deleteRoiLimit(id: number): Observable<RoiLimit> {
    return this.http.delete<RoiLimit>(`${this._loginUrl}/roi-limits/${id}`);
  }

  putRoiLimit(id: number, form: RoiLimit): Observable<RoiLimit> {
    return this.http.put<RoiLimit>(`${this._loginUrl}/roi-limits/${id}`, form);
  }
}
