import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
})
export class InputPasswordComponent implements OnInit {
  @Input() controlName: string;
  @Input() error: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() placeholder: string;
  @Input() prefixIcon: string | null = null;

  constructor() {}

  ngOnInit(): void {}
}
