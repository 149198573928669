<input
  [(colorPicker)]="color"
  [style.background]="color"
  cpOutputFormat="hex"
  [cpAddColorButton]="false"
  (colorPickerChange)="acceptColor($event)"
  [cpOKButton]="true"
  cpOKButtonText="Close"
  cpOKButtonClass="okButton"
/>
