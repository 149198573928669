import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-layer-path',
  templateUrl: './layer-path.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LayerPathComponent implements OnInit {
  @Input() path: string;
  @Output() clickPath: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClickPath(event: MouseEvent) {
    event.stopPropagation();
    this.clickPath.emit(true);
  }
}
