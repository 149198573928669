import { Injectable } from '@angular/core';
import { REPORT_FILE_NAME } from '@App/app/configs/app.config';
import * as rectTools from '@App/app/engine/helpers/rectangle-tools-helpers';
import { LayerService } from '@App/app/engine/services/layer-services/layer-service/layer.service';
import { Cuboid, CuboidData } from '@App/app/entities/layer/cuboid.model';
import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import downloadCsv from 'download-csv';
import { toImperialDimensions } from 'src/app/shared/utils/units-converter';

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  headers = [
    'Name',
    'Height (in.)',
    'Width (in.)',
    'Depth (in.)',
    'Azimuth (deg.)',
    'Down tilt (deg.)',
  ];

  data: string[][] = [];

  constructor(private layerService: LayerService) {}

  export(folder: LayerUiExpandable) {
    this.toCsvData(folder);
    downloadCsv(this.data, [], REPORT_FILE_NAME);
    this.data = [];
  }

  private toCsvData(folder: LayerUiExpandable) {
    this.storeChildren(folder, -1, true);
  }

  private storeChildren(folder: LayerUiExpandable, nestingLevel: number, isRoot = false) {
    const children = this.layerService.layers.value.filter((layer) => layer.parentId === folder.id);
    const subFolders = children.filter((child) => child.type === LAYER_TYPES.FOLDERS);
    const cuboids = children.filter((child) => child.type === LAYER_TYPES.CUBOID);
    if (!isRoot) {
      this.pushWithNestingLevel(folder, nestingLevel);
    }
    if (!subFolders.length) {
      this.storeCuboids(cuboids as Cuboid[], nestingLevel + 1);
    } else {
      for (const subFolder of subFolders) {
        this.storeChildren(subFolder as LayerUiExpandable, nestingLevel + 1);
      }
      this.storeCuboids(cuboids as Cuboid[], nestingLevel + 1);
    }
  }

  private storeCuboids(cuboids: Cuboid[], nestingLevel: number) {
    if (cuboids.length) {
      this.data.push(Array(nestingLevel).fill('').concat(this.headers));
    }
    for (const cuboid of cuboids) {
      this.pushWithNestingLevel(cuboid, nestingLevel, true);
    }
  }

  private pushWithNestingLevel(layer: LayerUI, nestingLevel: number, isCuboid = false) {
    if (isCuboid) {
      const dimensions = toImperialDimensions(rectTools.getDimensions(layer as rectTools.RectType));
      this.data.push(
        Array(nestingLevel)
          .fill('')
          .concat([
            layer.name,
            dimensions.height,
            dimensions.width,
            dimensions.depth,
            (layer.data as CuboidData).azimuth,
            (layer.data as CuboidData).verticalTilt,
          ]),
      );
    } else {
      this.data.push(Array(nestingLevel).fill('').concat([layer.name]));
    }
  }
}
