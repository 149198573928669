<div
  *ngIf="!isEditModeOnInitMappingTool()"
  class="layers__back"
  (click)="onBackClick()"
  data-testId="back-button"
>
  <div class="layers__icon_wrapper">
    <nb-icon class="layers__icon" icon="arrow-back"></nb-icon>
  </div>
  <div class="layers__title select-none">Back to Layers</div>
</div>
