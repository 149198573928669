import { Pipe, PipeTransform } from '@angular/core';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { toImperialArea } from '../utils/units-converter';

@Pipe({
  name: 'areaUnit',
})
export class AreaUnitPipe implements PipeTransform {
  transform(value: number, system: UnitsSystem): string {
    return system === UnitsSystem.Imperial
      ? `${toImperialArea(value)}`
      : system === UnitsSystem.Metric
      ? `${value} m²`
      : '-';
  }
}
