import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-model-site-header',
  templateUrl: './model-site-header.component.html',
  styleUrls: ['./model-site-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModelSiteHeaderComponent implements OnInit {
  @Input() backUrl: string | null;
  @Input() backButton = true;
  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}
}
