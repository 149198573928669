import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { Folder } from '@App/app/entities/layer/folder.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { ListNode } from '@App/app/entities/layer/list-node.model';
import { SelectChildEvent } from '@App/app/entities/layer/select-child-event.model';
import {
  ChangeDetectionStrategy,
  Component,
  Host,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { SortableEvent } from 'sortablejs';
import { LayersListComponent } from '../layers-list.component';
import { LayersListService } from '../services/layers-list-service/layers-list.service';
import { getOptions } from './layers-sortable.options';

@UntilDestroy()
@Component({
  selector: 'app-layers-sortable',
  templateUrl: './layers-sortable.component.html',
  styleUrls: ['./layers-sortable.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayersSortableComponent implements OnInit, OnChanges {
  @Input() rootLayer: LayerUiExpandable;
  root: ListNode | null = null;
  hoveredLayer: LayerUI | null = null;
  currentFolderId: number | null = null;
  openingTimeout: NodeJS.Timeout | null = null;
  LAYER_TYPES = LAYER_TYPES;
  options = getOptions(
    (e) => this._onDrag(e),
    (e) => this._onDrop(e),
  );
  isDragging$: Observable<boolean>;
  keywords$: Observable<string>;
  filteredLayers$: Observable<LayerUI[]>;

  constructor(
    @Host() private _parent: LayersListComponent,
    private _layersListService: LayersListService,
  ) {
    this.isDragging$ = this._parent.dragging$;
    this.keywords$ = this._parent.keywords$;
    this.filteredLayers$ = this._parent.filteredLayers$;
  }

  ngOnInit() {
    this.root = this._convertToNodes(this.rootLayer);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rootLayer) {
      this.root = this._convertToNodes(this.rootLayer);
    }
  }

  trackById(_: number, node: ListNode) {
    return node.layer.id;
  }

  updateCurrentFolderByLayer(layer: LayerUI | LayerUiExpandable | undefined) {
    if (this._parent.dragging$.value && layer) {
      this._setCurrentFolderId(layer.type === LAYER_TYPES.FOLDERS ? layer.id : layer.parentId);
    }
  }

  onFolderSelect(folder: LayerUiExpandable) {
    this._parent.onFolderSelect(folder);
  }

  onAllGuiFolderToggle(folder: LayerUiExpandable, value: boolean) {
    this._parent.onAllGuiFolderToggle({ folder: folder as Folder, value });
  }

  onFolderClick(folder: LayerUI) {
    this._parent.onFolderClick(folder);
  }

  onLayerClick(layer: LayerUI) {
    this._parent.onLayerClick(layer);
  }

  onClickLayerPath(layer: LayerUI) {
    this._parent.onClickLayerPath(layer);
  }

  onShowDetails(layer: LayerUI) {
    this._parent.onShowDetails(layer);
  }

  onChildLayerSelect(event: SelectChildEvent) {
    this._parent.onChildLayerSelect(event);
  }

  onExpandableLayerClick(layer: LayerUiExpandable) {
    this._parent.onExpandableLayerClick(layer);
  }

  onToggleLayerVisibility(layer: LayerUI) {
    this._parent.onToggleLayerVisibility(layer);
  }

  onToggleLayerGuiVisibility(layer: LayerUI) {
    this._parent.onToggleLayerGuiVisibility(layer);
  }

  private _onDrag(event: SortableEvent) {
    this._setCurrentFolderId(Number(event.from.dataset.folderId));
    this._parent.onDragStart(event);
  }

  private _onDrop(event: SortableEvent) {
    if (this.currentFolderId) {
      this._parent.onDrop(event, this.currentFolderId);
      this._setCurrentFolderId(null);
    }
  }

  private _convertToNodes(layer: LayerUI | LayerUiExpandable) {
    const node = { layer, children: [] } as ListNode;
    const children = this._layersListService.children$.value[layer.id] || [];
    children.forEach((child) => node.children.push(this._convertToNodes(child)));
    return node;
  }

  private _setCurrentFolderId(id: number | null) {
    this.currentFolderId = id;
    if (this.openingTimeout) {
      clearTimeout(this.openingTimeout);
    }
    this.openingTimeout = setTimeout(() => {
      if (this.currentFolderId === id && id) {
        const folder = this._layersListService.folders.find((f) => f.id === id);
        if (folder) {
          folder.open = true;
        }
      }
    }, 1500);
  }
}
