import { SimplifiedTile } from '@App/app/entities/files/files.model';
import { ExtendedRender } from '@App/app/entities/processing/render.model';
import { TilesAreBeingProcessedError } from '../../exceptions/tiles-are-being-processed.exception';

export const getTilesToLoad = (tiles: string[], simplifiedTileLevels: string[][]) => {
  const simplifiedTileLowestLevel = simplifiedTileLevels[simplifiedTileLevels.length - 1];
  const tilesToLoad = simplifiedTileLowestLevel?.length ? simplifiedTileLowestLevel : tiles;
  if (!tilesToLoad.length) throw new TilesAreBeingProcessedError();
  return tilesToLoad;
};

export const getTilesListFromRender = (render: ExtendedRender | null): string[] => {
  const tiles = render?.tiles || [];
  const getDownloadUrl = (tile: SimplifiedTile) => tile.downloadURL;
  return tiles.filter(getDownloadUrl).map(getDownloadUrl);
};

export const getSimplifiedTilesLevelsListFromRender = (
  render: ExtendedRender | null,
): string[][] => {
  const levels = render?.getSimplifiedTilesLevelsAsFlatList() || [];
  const getDownloadUrl = (tile: SimplifiedTile) => tile.downloadURL;
  return levels.map((level) => level.filter(getDownloadUrl).map(getDownloadUrl));
};
