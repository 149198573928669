import { UserTableFilterForm } from '@App/app/entities/forms/users-table-filter-form.model';
import { AdvancedFiltersService } from '@App/app/entities/shared/advanced-filters-service.model';
import { AdvancedSearchUsers } from '@App/app/entities/shared/advanced-search.model';
import { Roles, User } from '@App/app/entities/users/user';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { isAtLeastOneValueTrue } from '../../shared/utils';

@Injectable({
  providedIn: 'root',
})
export class UsersTableFiltersService
  implements AdvancedFiltersService<AdvancedSearchUsers, UserTableFilterForm> {
  private _advanceSearch = new BehaviorSubject<AdvancedSearchUsers>(new AdvancedSearchUsers());
  $advancedSearch = this._advanceSearch.asObservable();
  filterForm: UserTableFilterForm = this.fb.group({
    query: this.fb.control(''),
    role: this.fb.group<{ [name: string]: boolean }>({}),
  });

  constructor(private fb: FormBuilder) {}

  advancedSearch(values: AdvancedSearchUsers) {
    this._advanceSearch.next(values);
  }

  filterRoleType(users: User[], role: { [name: string]: boolean | null }) {
    if (role && isAtLeastOneValueTrue(role)) {
      return users.filter((user) =>
        user.roles.some(({ name }: { name: keyof typeof Roles }) => role[Roles[name]]),
      );
    }
    return users;
  }
}
