<div class="reset-nb-layout">
  <!--
      Added nb-layout and nb-layout-column here to
      make nb-select work, that's kind of workaround
      for a nebular bug
  -->
  <nb-layout-column>
    <nb-card class="content-card model-form">
      <nb-card-header><ng-content select="h1"></ng-content> </nb-card-header>
      <nb-card-body>
        <form (ngSubmit)="onSubmit()" [formGroup]="parentForm">
          <div class="model-form__section">
            <label for="firstName" class="model-form__label">First name</label>
            <input
              id="firstName"
              nbInput
              type="text"
              placeholder="First name"
              formControlName="firstName"
            />
            <div class="text text--error" *ngIf="required('firstName')">First name is required</div>
          </div>
          <div class="model-form__section">
            <label for="lastName" class="model-form__label">Last name</label>
            <input
              id="lastName"
              nbInput
              type="text"
              placeholder="Last name"
              formControlName="lastName"
            />
            <div class="text text--error" *ngIf="required('lastName')">Last name is required</div>
          </div>
          <div class="model-form__section">
            <label for="email" class="model-form__label">Email</label>
            <input
              nbInput
              id="email"
              type="email"
              placeholder="Email"
              formControlName="email"
              [attr.disabled]="editUser"
            />
            <div class="text text--error" *ngIf="required('email')">Email is required</div>
          </div>
          <div class="model-form__section">
            <label for="phoneNumber" class="model-form__label">Phone number</label>
            <input
              id="phoneNumber"
              nbInput
              type="text"
              placeholder="Phone number"
              formControlName="phoneNumber"
            />
            <div
              class="text text--error"
              *ngIf="
                parentForm.get('phoneNumber')?.touched &&
                parentForm.get('phoneNumber')?.hasError('isInvalid')
              "
            >
              Invalid phone number. Valid format (817) xxx-xxxx
            </div>
          </div>
          <ng-container *ngxPermissionsOnly="permissions.CAN_ADD_ROLE">
            <div class="model-form__section">
              <label class="model-form__label">Roles</label>
              <nb-select multiple placeholder="Choose roles" formControlName="roleIds">
                <nb-option *ngFor="let role of allRoles" [value]="role.id">{{
                  role.name
                }}</nb-option>
              </nb-select>
            </div>
          </ng-container>
          <ng-content></ng-content>
          <div class="model-form__button-container">
            <button
              [disabled]="!parentForm.valid || requestSended"
              type="submit"
              nbButton
              status="primary"
            >
              Submit
            </button>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</div>
