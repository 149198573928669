import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { LayerDetailsService } from '@App/app/pages/viewer/layer-details/services/layer-details-service/layer-details.service';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Pipe({
  name: 'isLayerDeleteActive',
  pure: false,
})
export class IsLayerDeleteActivePipe implements PipeTransform {
  private _value: boolean;
  private _layer: LayerUI;

  constructor(private _layerDetailsService: LayerDetailsService, private _cdr: ChangeDetectorRef) {
    this._layerDetailsService.activeDeletes$
      .pipe(
        untilDestroyed(this),
        filter(() => !!this._layer),
      )
      .subscribe((activeDeletes) => {
        this._value = activeDeletes.some(
          (item) => item.id === this._layer.id && item.type === this._layer.type,
        );
        this._cdr.markForCheck();
      });
  }

  transform(layer: LayerUI): boolean {
    if (layer !== this._layer) {
      this._layer = layer;
    }

    return this._value;
  }
}
