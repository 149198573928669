import { CustomerEngineService } from '@App/app/engine/services/engine-services/customer-engine-service/customer-engine.service';
import { ViewerLayerService } from '@App/app/engine/services/layer-services/viewer-layer-service/viewer-layer.service';
import { UserPermissions } from '@App/app/entities/auth/permissions.enum';
import { Folder } from '@App/app/entities/layer/folder.model';
import { LayerUI, LayerUiExpandable } from '@App/app/entities/layer/layer-ui.model';
import { LayersChildren } from '@App/app/entities/layer/layers-children.model';
import { SelectChildEvent } from '@App/app/entities/layer/select-child-event.model';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortableEvent } from 'sortablejs';
import { LayersService } from '../services/layers/layers.service';
import { IsGuiLayerVisiblePipe } from './layers-sortable/pipes/is-gui-layer-visible/is-gui-layer-visible.pipe';
import { LayersListActionsService } from './services/layers-list-actions-service/layers-list-actions.service';
import { LayersListService } from './services/layers-list-service/layers-list.service';

@UntilDestroy()
@Component({
  selector: 'app-layers-list',
  templateUrl: './layers-list.component.html',
  styleUrls: ['./layers-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayersListComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('layersList', { read: ElementRef }) private listRef: ElementRef<HTMLElement>;
  filteredLayers$ = new BehaviorSubject<LayerUI[]>([]);
  dragging$ = new BehaviorSubject(false);
  layersLoading$: Observable<boolean>;
  permissions = UserPermissions;
  keywords$ = new BehaviorSubject('');
  isAtLeastOneSelectedLayerVisible$: Observable<boolean>;
  isAnyGuiLayerVisible$: Observable<boolean>;
  isAnyLayerVisible$: Observable<boolean>;
  isAnyLayerSelected$: Observable<boolean>;
  rootFolder$: Observable<LayerUiExpandable>;
  children$: Observable<LayersChildren>;
  selectedLayers$: Observable<LayerUI[]>;
  layersLoaded$: Observable<boolean>;
  isBasePlaneCreated$: Observable<boolean>;

  constructor(
    private _isGuiLayerVisiblePipe: IsGuiLayerVisiblePipe,
    private _layersService: LayersService,
    private _viewerLayerService: ViewerLayerService,
    private _cdr: ChangeDetectorRef,
    private _layersListService: LayersListService,
    private _layersListActionsService: LayersListActionsService,
    private _engineService: CustomerEngineService,
  ) {}

  ngOnInit(): void {
    this.layersLoading$ = this._layersService.loading$;
    this.isAtLeastOneSelectedLayerVisible$ = this._layersListService.isAtLeastOneSelectedLayerVisible$;
    this.isAnyLayerVisible$ = this._layersService.visibleLayers$.pipe(map((val) => !!val.length));
    this.isAnyGuiLayerVisible$ = this._layersService.visibleLayers$.pipe(
      map((val) => val.some((layer) => this._isGuiLayerVisiblePipe.transform(layer))),
    );
    this.isAnyLayerSelected$ = this._layersService.selectedLayers$.pipe(
      map((val) => val.some((layer) => !this._layersListService.isFolder(layer))),
    );
    this.children$ = this._layersListService.children$;
    this.rootFolder$ = this._layersListService.rootFolder$;
    this.selectedLayers$ = this._layersService.selectedLayers$;
    this.layersLoaded$ = this._viewerLayerService.layersLoaded$;
    this.isBasePlaneCreated$ = this._layersService.isBasePlaneCreated$;
  }

  ngAfterViewInit(): void {
    this._layersService.initLayersUI();
    this._engineService.clickedTagLayerId$
      .pipe(untilDestroyed(this))
      .subscribe((id) => this._showAndHighlightLayerInList(id));
  }

  ngAfterViewChecked() {
    this._cdr.detectChanges();
  }

  clearKeyword() {
    this._setKeywords('');
  }

  onDragStart(event: SortableEvent) {
    const id = Number(event.item.dataset.id);
    this._layersListActionsService.setLayerAsDraggedById(id);
    this.dragging$.next(true);
  }

  onDrop(event: SortableEvent, dstId: number) {
    const oriId = Number(event.from.dataset.folderId);
    this._layersListActionsService.setLayerAsDroppedByOriginAndDestinationIds(oriId, dstId);
    this.dragging$.next(false);
  }

  onChangeKeyword(event: Event) {
    const value = (event.target as any).value as string;
    this._setKeywords(value);
    this.filteredLayers$.next(this._layersListService.filterLayersByKeywords(value));
  }

  onClickLayerPath(layer: LayerUI | LayerUiExpandable) {
    this.clearKeyword();
    this._showAndHighlightLayerInList(layer.id);
    this._layersListActionsService.setLayerAsSelected(layer);
  }

  onLayerClick(layer: LayerUI) {
    if (!this.keywords$.value.length) {
      this._layersListActionsService.toggleLayerSelection(layer);
    }
  }

  onShowDetails(layer: LayerUI) {
    this._layersListActionsService.showLayerDetails(layer);
  }

  onFolderClick(folder: LayerUI | LayerUiExpandable) {
    this._layersListActionsService.toggleLayerOpen(folder);
  }

  onExpandableLayerClick(layer: LayerUiExpandable) {
    this._layersListActionsService.toggleLayerOpen(layer);
  }

  onToggleLayerVisibility(layer: LayerUI) {
    this._layersListActionsService.toggleLayerVisibility(layer);
  }

  onToggleLayerGuiVisibility(layer: LayerUI) {
    this._layersListActionsService.toggleLayerGuiVisibility(layer);
  }

  onToggleShowLayersClick() {
    this._layersListActionsService.toggleShowAllLayers();
  }

  onAllGuiFolderToggle(data: { folder: Folder; value: boolean }) {
    this._layersListActionsService.toggleAllGuiInFolder(data);
  }

  onAllLayersToggleVisibility() {
    this._layersListActionsService.toggleAllLayersVisibility();
  }

  onAllGuiLayersToggleVisibility() {
    this._layersListActionsService.toggleAllLayerGuiVisibility();
  }

  onFolderSelect(folder: LayerUiExpandable) {
    this._layersListActionsService.toggleFolderVisibility(folder);
  }

  onChildLayerSelect({ parent, layer }: SelectChildEvent) {
    this._layersListActionsService.toggleLayerInFolderVisibility(layer, parent);
  }

  onAnalyzeClick() {
    this._layersListActionsService.showBatchLayerDetails();
  }

  onSelectedDeleteClick() {
    this._layersListActionsService.deleteSelectedLayers();
  }

  private _showAndHighlightLayerInList(id: number) {
    const layer = this._layersListService.getAllItems().find((e) => e.id === id);
    if (layer) {
      const folder = this._layersListService.folders.find((e) => e.id === layer.parentId);
      if (folder && !folder.open) {
        this._layersListActionsService.toggleLayerOpen(folder);
        this._cdr.detectChanges();
      }
      const childLayer = this._layersListService.findLayerElementByRefAndId(this.listRef, id);
      if (childLayer) {
        this._layersListService.openParentsAlongToLayer(layer);
        this._layersListService.highlightLayerHTMLElement(childLayer);
      }
    }
  }

  private _setKeywords(value: string) {
    this.keywords$.next(value);
    this._layersService.setSelectedLayers([]);
    if (this._layersListService.visibleLayers.length) {
      this.onAllLayersToggleVisibility();
    }
  }
}
