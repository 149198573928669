<div class="layer_details__row">
  <div class="layer_details__label">Square area</div>
  <div class="layer_details__value">
    <app-layer-details-optional-measurement-field
      [value]="layer.data.squareArea"
      [unitsSystem]="unitsSystem"
      type="area"
    ></app-layer-details-optional-measurement-field>
  </div>
</div>
