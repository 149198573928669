import { httpLayer } from '@App/app/entities/layer/layer.model';
import { selectRootFolder } from '@App/app/pages/viewer/store/selectors/layers.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayerUtilsService {
  constructor(private store: Store) {}

  createRequestBody(layer: httpLayer): httpLayer {
    const res: httpLayer = {
      id: layer.id,
      name: layer.name,
      isVisible: layer.isVisible,
      type: layer.type,
      data: layer.data,
    };

    if ('description' in layer) {
      res.description = layer.description;
    }
    if (!('isRoot' in res.data && res.data.isRoot)) {
      res.parentId = layer.parentId || this._getDefaultFolderId();
    }
    return res;
  }

  private _getDefaultFolderId() {
    let rootFolderId = -1;

    this.store
      .select(selectRootFolder())
      .pipe(take(1))
      .subscribe((layer) => {
        if (layer?.id) {
          rootFolderId = layer.id;
        }
      });

    return rootFolderId;
  }
}
