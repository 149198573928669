<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary">
  <nb-card-header><h3>Report problem</h3></nb-card-header>
  <nb-card-body class="custom-preset-container" [formGroup]="reportForm">
    <div class="model-form__group">
      <div class="model-form__section">
        <div data-testid="category_select" class="model-form__label">Category</div>
        <nb-select
          fullWidth
          placeholder="Select category"
          formControlName="category"
          (selectedChange)="onCategoryChange($event)"
        >
          <nb-option *ngFor="let category of categories" [value]="category.value">{{
            category.name
          }}</nb-option>
        </nb-select>
      </div>
    </div>
    <div class="model-form__group">
      <div class="model-form__section">
        <div class="model-form__label">Subcategory</div>
        <nb-select
          [disabled]="!selectedSubcategories"
          fullWidth
          placeholder="Select subcategory"
          formControlName="subcategory"
        >
          <nb-option
            data-testid="subcategory_option"
            *ngFor="let subcategory of selectedSubcategories"
            [value]="subcategory.value"
            >{{ subcategory.name }}</nb-option
          >
        </nb-select>
      </div>
    </div>
    <div class="model-form__group">
      <div class="model-form__section">
        <div class="model-form__label">Title</div>
        <input
          data-testid="title_input"
          nbInput
          fullWidth
          type="text"
          placeholder="Title"
          formControlName="title"
        />
      </div>
    </div>
    <div class="model-form__group">
      <div class="model-form__section">
        <div class="model-form__label">Description</div>
        <textarea
          data-testid="description_input"
          nbInput
          fullWidth
          placeholder="Description"
          formControlName="description"
          rows="6"
        ></textarea>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="report_footer">
    <button nbButton class="report_footer__cancel_btn" (click)="closeReportForm()">Cancel</button>
    <button nbButton status="info" (click)="confirmReportForm()" [disabled]="!reportForm.valid">
      Confirm
    </button>
  </nb-card-footer>
</nb-card>
