<app-site-layout
  [filtersTpl]="filtersTpl"
  [leftControlsTpl]="leftControlsTpl"
  [rightControlsTpl]="rightControlsTpl"
  [contentTpl]="contentTpl"
  [companyId]="companyId"
  [pageRangeInfo]="page | asPageRangeInfo"
></app-site-layout>

<ng-template #filtersTpl>
  <app-processing-table-filters class="filters_component"></app-processing-table-filters>
</ng-template>

<ng-template #leftControlsTpl>
  <ng-container *ngIf="hasPermission(permissions.CAN_ADD_MODEL)">
    <button
      nbButton
      size="tiny"
      status="primary"
      [routerLink]="['/', companyId, 'models', 'add']"
      class="sites-nav__button"
    >
      <nb-icon icon="plus-square-outline"></nb-icon>
      Add model
    </button>
  </ng-container>
  <ng-container *ngIf="hasPermission(permissions.CAN_UPLOAD_PHOTOS)">
    <button
      nbButton
      size="tiny"
      status="primary"
      class="sites-nav__button"
      [routerLink]="['/', companyId, 'uploader', 'selection']"
    >
      <nb-icon icon="camera-outline"></nb-icon>
      Upload photos
    </button>
  </ng-container>
</ng-template>

<ng-template #rightControlsTpl>
  <app-actions-buttons
    (archive)="archive()"
    (delete)="delete()"
    [disabled]="!selectedEntities.length"
    [archiveButtonLabel]="showArchived ? 'Unarchive processes' : 'Archive processes'"
    deleteButtonLabel="Delete processes"
  ></app-actions-buttons>
  <div *ngIf="hasPermission(permissions.CAN_ACCESS_ARCHIVED_PROCESSINGS)" class="sites-nav__mode">
    <nb-select [selected]="showArchived" size="tiny" (selectedChange)="changeArchiveSelect($event)">
      <nb-option [value]="true">Archived</nb-option>
      <nb-option [value]="false">Active</nb-option>
    </nb-select>
  </div>
  <button nbButton size="tiny" status="primary" class="sites-nav__refresh" (click)="onRefresh()">
    <nb-icon icon="refresh-outline"></nb-icon>
    Refresh
  </button>
</ng-template>

<ng-template #contentTpl>
  <app-processing-table></app-processing-table>
</ng-template>
