/* eslint-disable max-lines */
import { Cuboid, CuboidPartsArrays } from '@App/app/entities/layer/cuboid.model';
import { LAYER_NAMES } from '@App/app/entities/layer/enums/layer-names.enum';
import {
  CUBOID_PART,
  CUBOID_PART_STRICT,
  LAYER_TYPES,
  RECT_TUBE_PART,
  RECT_TUBE_STRICT,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { RectTube, RectTubePartsArrays } from '@App/app/entities/layer/rect-tube.model';
import { SideData } from '@App/app/entities/layer/side-data.model';
import { CreationToolError } from '@App/app/shared/exceptions/creation-tool-error';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Axis, Matrix, Mesh, Plane, Quaternion, Ray, Tools, Vector3 } from 'babylonjs';
import { Subject } from 'rxjs';
import {
  CUBOID_OBJECT_NAME,
  MODEL_NAME,
  RECT_TUBE_OBJECT_NAME,
} from 'src/app/configs/babylon.config';
import { addLayer, editLayer } from 'src/app/pages/viewer/store/actions/layers.actions';
import { getCenter } from '../../../../helpers/rectangle-tools-helpers';
import { BabylonNodesService } from '../../../babylon-nodes-service/babylon-nodes.service';
import { SceneService } from '../../../scene-service/scene.service';
import { UtilsService } from '../../../utils-service/utils.service';
import { BasePlaneService } from '../../base-plane-services/base-plane.service';
import { ViewerLayerService } from '../../viewer-layer-service/viewer-layer.service';
import { RectangleToolsEdgeDetectService } from './rectangle-tools-edge-detect.service';
import { RectangleToolsUtilsService } from './rectangle-tools-utls.service';
import { VolumeUtilsService } from './volume-utils.service';

@Injectable({
  providedIn: 'root',
})
export abstract class RectangleToolsService {
  samples: CuboidPartsArrays<Mesh | null> | RectTubePartsArrays<Mesh | null>;
  activePart: CUBOID_PART | RECT_TUBE_PART;
  highlightedSample: {
    part: CUBOID_PART | RECT_TUBE_PART;
    i: number;
  };

  private originalSurfaceData: SideData;
  private adjacentSurfaceData: SideData;
  private topBottomSurfaceData: SideData;

  protected _dataChange$ = new Subject<void>();
  dataChange$ = this._dataChange$.asObservable();

  constructor(
    private _basePlaneService: BasePlaneService,
    private _rectangleToolsUtilsService: RectangleToolsUtilsService,
    private _sceneService: SceneService,
    // eslint-disable-next-line ngrx/use-consistent-global-store-name
    private _store: Store,
    private _utilsService: UtilsService,
    private _viewerLayerService: ViewerLayerService,
    private _volumeUtilsService: VolumeUtilsService,
    private _babylonNodesService: BabylonNodesService,
    private _rectangleToolsEdgeDetectService: RectangleToolsEdgeDetectService,
  ) {}

  onClickAction() {
    if (this.activePart) {
      const hit = this._utilsService.pickRay();
      if (hit && hit.pickedPoint) {
        this.createSamplePoint(hit.pickedPoint.clone());
        this._dataChange$.next();
      }
    }
  }

  save(
    type: LAYER_TYPES.CUBOID | LAYER_TYPES.RECT_TUBE,
    topPlaneNormal: Vector3,
    surfacePlaneNormal: Vector3,
    center: Vector3,
    width: number,
    depth: number,
    height: number,
    sampleMeshes: Mesh[],
    thickness?: number,
  ) {
    const options = {
      height,
      depth,
      width,
    };

    const tmpBox = this._babylonNodesService.createBox(
      center,
      options,
      Quaternion.FromRotationMatrix(Matrix.LookDirectionLH(topPlaneNormal, surfacePlaneNormal)),
      'box',
    );
    tmpBox.rotate(Axis.X, Tools.ToRadians(90));
    const [
      updatedCenter,
      updatedHeight,
    ] = this._rectangleToolsEdgeDetectService.detectEdgesAndRecalculateHeight(
      tmpBox,
      sampleMeshes,
      type,
    );
    options.height = updatedHeight;
    tmpBox.dispose();
    const box = this._babylonNodesService.createBox(
      updatedCenter,
      options,
      Quaternion.FromRotationMatrix(Matrix.LookDirectionLH(topPlaneNormal, surfacePlaneNormal)),
      'box',
    );
    box.rotate(Axis.X, Tools.ToRadians(90));
    box.visibility = 0;
    const calculatedData = this._volumeUtilsService.getCalculatedData(box);
    const layer = type === LAYER_TYPES.CUBOID ? new Cuboid() : new RectTube();
    layer.name = type === LAYER_TYPES.CUBOID ? LAYER_NAMES.CUBOID : LAYER_NAMES.RECT_TUBE;
    layer.isVisible = true;
    layer.isSaved = true;
    layer.data.volume = calculatedData.volume;
    this._rectangleToolsUtilsService.setRotationQuaternion(layer, box);
    layer.data.min = calculatedData.min;
    layer.data.max = calculatedData.max;
    layer.data.onHeightDistance = calculatedData.onHeightDistance;
    layer.data.towerCenterDistance = calculatedData.towerCenterDistance;
    const [azimuth, verticalTilt] = this.calculateAzimuthAndVerticalTilt(box);
    layer.data.azimuth = azimuth;
    layer.data.verticalTilt = verticalTilt;
    layer.data.worldDirection = calculatedData.worldDirection;
    if (layer.type === LAYER_TYPES.CUBOID) {
      (layer as Cuboid).data.color = '#fff';
    }
    if (type === LAYER_TYPES.RECT_TUBE) {
      (layer as RectTube).data.thickness = thickness;
      (layer as RectTube).data.edges = this._rectangleToolsUtilsService.calculateEdges(box);
    }
    box.dispose();
    this._store.dispatch(addLayer({ layer: layer as LayerUI, commit: false }));
  }

  // eslint-disable-next-line complexity
  calculateSidesData(
    originalSurfacePoints: Mesh[] = [],
    adjacentSurfacePoints: Mesh[] = [],
    topBottomSurfacePoints: Mesh[] = [],
  ): [number, number, number, Vector3, Vector3, Vector3] {
    let topPoint: Vector3;
    let sidePoint: Vector3;
    const originalSurfacePlane = this._rectangleToolsUtilsService.createPlane(
      originalSurfacePoints,
      topBottomSurfacePoints.length ? topBottomSurfacePoints : adjacentSurfacePoints,
    );
    const originalSurfaceOppositePoints = this.getOppositesPoints(
      originalSurfacePoints,
      originalSurfacePlane.normal.clone(),
    );
    const originalSurfaceDistance = Vector3.Distance(
      getCenter(originalSurfaceOppositePoints),
      getCenter([...originalSurfacePoints.map((point) => point.position)]),
    );
    let centerPosition = getCenter([
      ...originalSurfacePoints.map((point) => point.position),
      ...originalSurfaceOppositePoints,
    ]);
    let adjacentSurfacePlane = new Plane(1, 0, 0, 0);
    let topPlane = new Plane(1, 0, 0, 0);
    if (topBottomSurfacePoints.length > 2) {
      topPlane = this._rectangleToolsUtilsService.createPlane(topBottomSurfacePoints);
      topPoint = centerPosition.projectOnPlane(topPlane, centerPosition.add(topPlane.normal));
      this.originalSurfaceData = this.calculateSideData(
        originalSurfacePlane.normal.clone(),
        centerPosition,
      );
      if (this.originalSurfaceData.pointA && this.originalSurfaceData.pointB) {
        adjacentSurfacePlane.copyFromPoints(
          topPoint,
          this.originalSurfaceData.pointB,
          this.originalSurfaceData.pointA,
        );
        this.adjacentSurfaceData = this.calculateSideData(
          adjacentSurfacePlane.normal,
          centerPosition,
        );
        if (this.adjacentSurfaceData.pointA && this.adjacentSurfaceData.pointB) {
          topPlane.copyFromPoints(
            this.adjacentSurfaceData.pointA,
            this.adjacentSurfaceData.pointB,
            this.originalSurfaceData.pointA,
          );
          centerPosition = Vector3.Center(
            this.adjacentSurfaceData.pointA,
            this.adjacentSurfaceData.pointB,
          );
          this.topBottomSurfaceData = this.calculateSideData(topPlane.normal, centerPosition);
          if (this.topBottomSurfaceData.pointA && this.topBottomSurfaceData.pointB) {
            centerPosition = Vector3.Center(
              this.topBottomSurfaceData.pointA,
              this.topBottomSurfaceData.pointB,
            );
          }
        }
      }
    } else if (adjacentSurfacePoints.length > 2) {
      adjacentSurfacePlane = this._rectangleToolsUtilsService.createPlane(adjacentSurfacePoints);
      sidePoint = centerPosition.projectOnPlane(
        adjacentSurfacePlane,
        centerPosition.add(adjacentSurfacePlane.normal),
      );
      this.originalSurfaceData = this.calculateSideData(
        originalSurfacePlane.normal.clone(),
        centerPosition,
      );
      if (this.originalSurfaceData.pointA && this.originalSurfaceData.pointB) {
        topPlane.copyFromPoints(
          sidePoint,
          this.originalSurfaceData.pointB,
          this.originalSurfaceData.pointA,
        );
        this.topBottomSurfaceData = this.calculateSideData(topPlane.normal, centerPosition);
        if (this.topBottomSurfaceData.pointA && this.topBottomSurfaceData.pointB) {
          adjacentSurfacePlane.copyFromPoints(
            this.topBottomSurfaceData.pointA,
            this.topBottomSurfaceData.pointB,
            this.originalSurfaceData.pointA,
          );
          centerPosition = Vector3.Center(
            this.topBottomSurfaceData.pointA,
            this.topBottomSurfaceData.pointB,
          );
          this.adjacentSurfaceData = this.calculateSideData(
            adjacentSurfacePlane.normal,
            centerPosition,
          );
          if (this.adjacentSurfaceData.pointA && this.adjacentSurfaceData.pointB) {
            centerPosition = Vector3.Center(
              this.adjacentSurfaceData.pointA,
              this.adjacentSurfaceData.pointB,
            );
          }
        }
      }
    }
    const originalSurfaceSubPoints = this.calculateSubPoints(
      5,
      originalSurfacePlane.normal.clone(),
      this.originalSurfaceData.length / 2,
      centerPosition,
    );
    this.adjacentSurfaceData = this.findLongestDistance(
      originalSurfaceSubPoints,
      adjacentSurfacePlane.normal,
    );

    const adjacentSurfaceSubPoints = this.calculateSubPoints(
      10,
      adjacentSurfacePlane.normal,
      this.adjacentSurfaceData.length / 2,
      centerPosition,
    );
    this.originalSurfaceData = this.findLongestDistance(
      adjacentSurfaceSubPoints,
      originalSurfacePlane.normal.clone(),
      true,
      originalSurfacePlane,
      originalSurfaceDistance,
    );
    return [
      this.originalSurfaceData.length,
      this.adjacentSurfaceData.length,
      this.topBottomSurfaceData.length,
      topPlane.normal,
      originalSurfacePlane.normal,
      centerPosition,
    ];
  }

  highlightSamplePoint(part: CUBOID_PART_STRICT | RECT_TUBE_STRICT, i: number) {
    if (this.highlightedSample.part && this.highlightedSample.i > -1) {
      this._rectangleToolsUtilsService.changeSampleMaterial(
        this.samples[this.highlightedSample.part][this.highlightedSample.i],
        true,
      );
    }
    if (this.highlightedSample.part === part && this.highlightedSample.i === i) {
      this.highlightedSample = { part: CUBOID_PART.NONE, i: -1 };
    } else {
      this._rectangleToolsUtilsService.changeSampleMaterial(this.samples[part][i], false);
      this.highlightedSample = { part, i };
    }
  }

  deleteSamplePoint(part: CUBOID_PART_STRICT | RECT_TUBE_STRICT, i: number) {
    this.samples[part][i]?.dispose();
    this.samples[part][i] = null;
    this.samples[part] = this.samples[part].filter((_, j) => j !== i);
    if (this.highlightedSample.part === part && this.highlightedSample.i === i) {
      this.highlightedSample = { part: CUBOID_PART.NONE, i: -1 };
    }
  }

  clearSamplesPoints() {
    this.activePart = CUBOID_PART.NONE;
    if (this.samples) {
      for (const part in this.samples) {
        if (
          this.samples[(part as any) as CUBOID_PART_STRICT | RECT_TUBE_STRICT] &&
          this.samples[(part as any) as CUBOID_PART_STRICT | RECT_TUBE_STRICT].length
        ) {
          this.removeSamplesOfPart((part as any) as CUBOID_PART_STRICT | RECT_TUBE_STRICT);
        }
      }
      this.samples = { 1: [], 2: [], 3: [] };
    }
    this.highlightedSample = { part: CUBOID_PART.NONE, i: -1 };
  }

  findAzimuthAndVerticalTilt(type: LAYER_TYPES.RECT_TUBE | LAYER_TYPES.CUBOID) {
    const objectName = this.rectangleObjectName(type);
    const predicate = (mesh: Mesh) => {
      if (mesh.name.includes(objectName)) {
        return true;
      }
      return false;
    };
    const hit = this._utilsService.pickRay(predicate);
    if (hit !== null && hit.pickedMesh !== null) {
      const meshId = hit.pickedMesh.id;
      if (meshId.includes(objectName)) {
        const id = +meshId.replace(/[^0-9]/g, '');
        const normal = hit.getNormal(true);
        if (normal) {
          const azimuth = this._volumeUtilsService.calculateAzimuth(normal);
          const verticalTilt = this._volumeUtilsService.calculateVerticalTilt(normal);
          this._viewerLayerService.setTmpData(id, { azimuth, verticalTilt });
          this._store.dispatch(editLayer({ id }));
        }
      }
    }
  }

  calculateAzimuthAndVerticalTilt(
    mesh: Mesh,
    modelCenter: Vector3 = this._basePlaneService.getBasePlanesPosition(),
  ) {
    mesh.bakeCurrentTransformIntoVertices(true);
    const vertices = this._utilsService.getVectorVertices(mesh);
    const frontAndBackPlanes = this.findPlanesTwoLargestSides(vertices);
    const planeToCalculate =
      Vector3.Distance(
        modelCenter
          .clone()
          .projectOnPlane(
            frontAndBackPlanes[0],
            modelCenter.clone().add(frontAndBackPlanes[0].normal),
          ),
        modelCenter,
      ) >
      Vector3.Distance(
        modelCenter
          .clone()
          .projectOnPlane(
            frontAndBackPlanes[1],
            modelCenter.clone().add(frontAndBackPlanes[1].normal),
          ),
        modelCenter,
      )
        ? frontAndBackPlanes[0]
        : frontAndBackPlanes[1];
    const targetCenter = modelCenter
      .clone()
      .projectOnPlane(planeToCalculate, modelCenter.clone().add(planeToCalculate.normal));
    const normal =
      Vector3.Distance(targetCenter.add(planeToCalculate.normal), modelCenter) >
      Vector3.Distance(targetCenter.subtract(planeToCalculate.normal), modelCenter)
        ? planeToCalculate.normal
        : planeToCalculate.normal.negate();
    const azimuth = this._volumeUtilsService.calculateAzimuth(normal);
    const verticalTilt = this._volumeUtilsService.calculateVerticalTilt(normal);
    return [azimuth, verticalTilt];
  }

  createSamplePoint(position: Vector3) {
    const sphere = this._rectangleToolsUtilsService.createPoint(position);
    if (this.activePart) {
      this.samples[this.activePart].push(sphere);
    }
  }

  private rectangleObjectName(type: LAYER_TYPES.RECT_TUBE | LAYER_TYPES.CUBOID) {
    return type === LAYER_TYPES.RECT_TUBE ? RECT_TUBE_OBJECT_NAME : CUBOID_OBJECT_NAME;
  }

  private getOppositesPoints(points: Mesh[], normal: Vector3) {
    const result: Vector3[] = [];
    points.forEach((point) => {
      const distance = Vector3.Distance(point.position, point.position.add(normal));
      const ray = Ray.CreateNewFromTo(
        point.position.subtract(normal.clone().scale(distance / 50)),
        point.position.subtract(normal.clone().scale(distance * 20)),
      );
      const hit = this._sceneService.scene.pickWithRay(ray, (mesh) =>
        mesh.name.includes(MODEL_NAME),
      );
      if (hit?.pickedMesh && hit.pickedPoint) {
        result.push(hit.pickedPoint);
      }
    });
    return result;
  }

  private calculateSideData(
    normal: Vector3,
    centerPosition: Vector3,
  ): {
    pointA: Vector3;
    pointB: Vector3;
    length: number;
  } {
    const PointA = this._rectangleToolsUtilsService.pickRay(
      centerPosition,
      centerPosition.clone().subtract(centerPosition.add(normal)).normalize().negate(),
    );
    const PointB = this._rectangleToolsUtilsService.pickRay(
      centerPosition,
      centerPosition.clone().subtract(centerPosition.add(normal)).normalize(),
    );
    if (!PointA || !PointB) {
      throw new CreationToolError('Something went wrong. Try again.');
    }
    return {
      pointA: PointA,
      pointB: PointB,
      length: Vector3.Distance(PointB, PointA),
    };
  }

  private calculateSubPoints(
    num: number,
    originalVector: Vector3,
    distance: number,
    originalMesh: Vector3,
  ): Vector3[] {
    const result: Vector3[] = [];
    const startPoint = originalMesh.subtract(originalVector.scale(distance / 2));
    for (let i = 0; i < num; i++) {
      const vector = originalVector.normalize().scale((distance / num) * i);
      const point = startPoint.add(vector);
      result.push(point);
    }
    return result;
  }

  private findLongestDistance(
    subPoints: Vector3[],
    vector: Vector3,
    frontBack = false,
    originalSurfacePlane?: Plane,
    originalSurfaceDistance?: number,
  ) {
    let result: SideData = { length: 0 };
    for (const point of subPoints) {
      const points = this.calculateOppositePoints(
        point,
        vector,
        frontBack,
        originalSurfacePlane,
        originalSurfaceDistance,
      );
      if (points[0] && points[1]) {
        const distance = Vector3.Distance(points[0], points[1]);
        if (distance > result.length) {
          result = {
            pointA: points[0],
            pointB: points[1],
            length: distance,
          };
        }
      }
    }
    return result;
  }

  private calculateOppositePoints(
    originPoint: Vector3,
    direction: Vector3,
    frontBack: boolean,
    originalSurfacePlane?: Plane,
    originalSurfaceDistance?: number,
  ): [Vector3 | undefined, Vector3 | undefined] {
    const pointA =
      frontBack && originalSurfacePlane && originalSurfaceDistance
        ? originPoint
            .clone()
            .subtract(originalSurfacePlane.normal.scale(originalSurfaceDistance / 2).negate())
        : this._rectangleToolsUtilsService.pickRay(
            originPoint,
            originPoint.clone().subtract(originPoint.add(direction)).normalize().negate(),
          );

    const pointB = this._rectangleToolsUtilsService.pickRay(
      originPoint,
      originPoint.subtract(originPoint.add(direction)).normalize(),
    );

    return [pointA, pointB];
  }

  private findPlanesTwoLargestSides(vertices: Vector3[]): [Plane, Plane] {
    const meshSidesVertices: [Vector3, Vector3, Vector3][] = [];
    for (let i = 0; i < 24; i += 4) {
      meshSidesVertices.push([vertices[i], vertices[i + 1], vertices[i + 2]]);
    }
    meshSidesVertices
      .sort((a, b) => {
        return (
          Vector3.Distance(a[0], a[1]) +
          Vector3.Distance(a[1], a[2]) -
          (Vector3.Distance(b[0], b[1]) + Vector3.Distance(b[1], b[2]))
        );
      })
      .reverse();
    return [Plane.FromPoints(...meshSidesVertices[0]), Plane.FromPoints(...meshSidesVertices[1])];
  }

  private removeSamplesOfPart(part: CUBOID_PART_STRICT | RECT_TUBE_STRICT) {
    for (let i = 0; i < this.samples[part].length; i++) {
      this.samples[part][i]?.dispose();
      this.samples[part][i] = null;
    }
  }
}
