/* eslint-disable max-len */
export enum AtSetupTabs {
  SETTINGS,
  CONTROL_POINTS,
}

export enum AtSetupGroupNames {
  GROUND_CONTROL_POINTS = 'Ground Control Points',
  GENERAL_SETTINGS = 'General Settings',
  ESTIMATION_POLICE = 'Estimation Policy',
  ADDITIONAL_DATA = 'Additional Data',
}

export enum AtSettingsDescription {
  KEY_POINTS_DENSITY = 'Key points density - Affects the number of generated key points. Key points are identified per image. High may cause the key point detection algorithm to “search” across a broader range of features in the image. More key points in each image may increase the amount of image matches and key point matches (tie points) found throughout the image set. Changing this setting may affect the amount of time required for processing.',
  PAIR_SELECTION_MODE = 'Pair selection mode - Modifies the selection criteria and algorithms used when selecting image pairs for matching. This setting can have a measurable impact on AT results and processing time. Exhaustive option searches across all possible pairs and is advised in cases where the overlap between photos is limited. Loop option uses only neighbor pairs within the given distance in a loop. The photo insertion order must correspond to the sequence order. Sequence option uses only neighbor pairs within the given distance in a sequence of photos. The photo insertion order must correspond to the sequence order. Default option uses several selection criteria, among which is similarity between images. Similar images option estimates relevant pairs by key point similarity.',
  COMPONENT_CONSTRUCTION_MODE = 'Component construction mode - Modifies the component construction algorithm. Sometimes the AT cannot connect all the images due to a too large baseline or scale difference between some images, resulting in a main component and disconnected components/images. Using a multi-pass method may increase component construction chances but is not guaranteed. Bentley advises using Multi Pass only if the One Pass mode fails to include a large proportion of images in the main component.',
  BLOCKWISE_COLOR_EQUALIZATION = 'Blockwise color equalization - Calculates and applies an automatic color equalization for all input images to ensure consistency of appearance between all images. To use blockwise color equalization setting during production, the color equalization setting must be set to Blockwise in AT settings.',
  TIE_POINTS = 'Tie points - User sets the Tie Points policy. Because Skyportal does not allow users to compute subsequent ATs using the results of the first AT, the only valid option is Compute. Unless we have an AT already, computed or imported, the Adjust and Keep options do nothing. This option has no relation to the model type. It may be worthwhile to hide this setting from the user in the interface unless we add the ability to perform a second AT or use a point cloud as an input.',
  ROTATION = 'Rotation - Rotation refers to the camera`s orientation. Most likely, the user will be working with geotagged images. If the input photo metadata has orientation information and the user has a very high level of confidence in its accuracy, Keep can be used to have the AT use the input estimate as is. If the user has a lower confidence in the accuracy, Adjust can be used to estimate the rotation parameters by adjusting the input estimate. If the user has little or no confidence in the accuracy, or there is not any rotation information in the metadata, Compute can be used to estimate the rotation parameters without using any input estimate.',
  CENTER = 'Center - Center refers to the camera`s location. Most likely, the user will be working with geotagged images, but not always. These prior positions are known up to some level of precision. If the input photo metadata has GPS position information and the user has a very high level of confidence in its accuracy, Keep can be used to have the AT use the input estimate as is. If the user has a lower confidence in the accuracy, Adjust can be used to estimate the position by adjusting the input estimate. If the user has little or no confidence in the accuracy, or there is not any position information in the metadata, Compute can be used to estimate the position without using any input estimate.',
  OPTICAL_ESTIMATION_MODE = 'Optical properties estimation mode - Bentley recommends using the One Pass mode first. They say the Multi Pass mode is useful when AT fails because the initial parameters are far from real values. Bentley says choosing Multi Pass adds significantly more computation time.',
  FOCAL_LENGTH = 'Focal length - Adjust the policy for estimating the camera`s focal point.',
  PRINCIPAL_POINT = 'Principal point - Adjust the policy for estimating the camera`s principal point. The principal point is the point on the image plane onto which the perspective center is projected. This is the image`s mathematical center, however due to small manufacturing variances the center of the mounted lens and the center of the image sensor may not be exactly aligned.',
  RADIAL_DISTORTION = 'Radial distortion - Adjust the policy for estimating the camera`s radial distortion. Radial distortion is the symmetric distortion caused by the lens due to imperfections in curvature when the lens was ground.',
  TANGENTIAL_DISTORTION = 'Tangential distortion - Adjust the policy for estimating the camera`s tangential distortion. Tangential distortion is often the result of de-centered lens assemblies.',
  ASPECT_RATIO = 'Aspect Ratio - Adjust the policy for estimating the camera`s aspect ratio.',
  SKEW = 'Skew - Adjust the policy for estimating the camera`s skew. The pixels in a CCD sensor may not be perfectly square, resulting in a small distortion in the X or Y directions.',
  BLOCK_TYPE = 'Block type - Defining a block type allows the aerotriangulation to make some assumptions about the data to aid in the estimation of camera poses and the matching of key points across images. General option provides the most flexibility and is advised for most image datasets. Nadir option makes assumptions about the block using the constraint that all images are nadir perspective. Because of the constraints this puts on the assumptions that can be made, Exhaustive Pair Selection Mode may become feasible, even on a large dataset. Aerial option assumes that the image dataset contains vertical and oblique views acquired with a regular sweep of the area in parallel lines, and sensors have fixed lever arm angles. Orbit option assumes that an orbital flight pattern was performed around a thin vertical subject of interest in the foreground.',
  AT_QUALITY = 'AT quality - The AT quality slider adjusts the image downsampling scale. Downsampled images lose resolution compared to the original counterpart. Downsampling will reduce the amount of time it takes to process the dataset. Downsampling may be beneficial for images where there is noise or excessive detail, as it can be hard for the algorithms to detect distinct features. The feature detection algorithm is essentially “overwhelmed” and may struggle to “focus” on meaningful features. Additionally, regions around key points may contain similar or repetitive patterns, leading to ambiguous descriptors. The ambiguity can result in incorrect or unreliable matches between key points during the matching process. Furthermore, in images with excessive detail, key points may be sensitive to minor viewpoint changes due to the intricate patterns and structures. This sensitivity reduces the algorithms’ robustness and may result in fewer successful matches across different views of the scene.',
}
