import { SUCCESS, UPLOAD_CANCELLED } from '@App/app/configs/toastr-events.config';
import { SUCCESS_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { UploadApiService } from '@App/app/pages/uploader/components/default-uploader/services/upload-api.service';
import { UploaderStorageService } from '@App/app/pages/uploader/services/uploader-storage-service/uploader-storage.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeUntil } from 'rxjs/operators';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
  constructor(
    private uploadApiService: UploadApiService,
    private uploaderStorageService: UploaderStorageService,
    private toastrService: NbToastrService,
  ) {}

  cancelUpload() {
    const { buildProcessId } = this.uploaderStorageService;
    if (buildProcessId) {
      this.uploadApiService
        .cancelStage(buildProcessId)
        .pipe(takeUntil(this.uploaderStorageService.cancelUploadSubject), untilDestroyed(this))
        .subscribe(() => this.onCanceled());
    }
  }

  private onCanceled() {
    this.uploaderStorageService.localUnsubscribe();
    this.uploaderStorageService.updateLogs('Upload cancelled');
    this.uploaderStorageService.uploadStarted$.next(false);
    this.uploaderStorageService.resetPending();
    this.toastrService.show(UPLOAD_CANCELLED, SUCCESS, { ...SUCCESS_TOASTR_CONFIG });
  }
}
