import { MODEL_TYPES_BADGE_COLORS } from '@App/app/configs/models.config';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-model-type-badge',
  templateUrl: './model-type-badge.component.html',
  styleUrls: ['./model-type-badge.component.scss'],
})
export class ModelTypeBadgeComponent implements OnInit, OnChanges {
  @Input() modelType: string;
  @Input() tooltip?: string;
  typeName: string;
  text: string;
  bgColor: string;

  constructor() {}

  ngOnInit(): void {
    if (this.modelType) {
      this.setBadge();
    }
  }

  ngOnChanges(): void {
    if (this.modelType) {
      this.setBadge();
    }
  }

  private setBadge() {
    this.typeName = this.modelType.toLowerCase();
    const budgeType = MODEL_TYPES_BADGE_COLORS.find((type) => type.text === this.typeName);
    if (budgeType) {
      this.bgColor = budgeType.bgColor;
      this.text = budgeType.text;
    } else {
      this.bgColor = MODEL_TYPES_BADGE_COLORS[7].bgColor;
      this.text = MODEL_TYPES_BADGE_COLORS[7].text;
    }
  }
}
