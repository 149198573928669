import { ANALYTICS_FILE_NAME } from '@App/app/configs/app.config';
import { NO_DATA_TO_EXPORT, WARNING } from '@App/app/configs/toastr-events.config';
import { WARNING_TOASTR_CONFIG } from '@App/app/configs/toastr-messages.config';
import { Model } from '@App/app/entities/models/model.model';
import { Analytics } from '@App/app/entities/processing/analytics.model';
import { BuildProcess } from '@App/app/entities/processing/build-process.model';
import { CSVAnalyticsRow } from '@App/app/entities/processing/csv-analytics.model';
import { ModelsHttpService } from '@App/app/pages/models/services/models-http-service/models-http.service';
import { ModelsProcessingHttpService } from '@App/app/pages/processing/services/models-processing-http-service/models-processing-http.service';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import downloadCsv from 'download-csv';
import { map, switchMap } from 'rxjs/operators';
import { CostsService } from '../../../../../shared/services/costs/costs.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private headers = [
    'ID',
    'Name',
    'Render ID',
    'AT Start',
    'AT Finish',
    'PROD Start',
    'PROD Finish',
    'PP Start',
    'PP Finish',
    'Total Images Size (B)',
    'Total Model Size (B)',
    'Total pixels',
    'AT Cost ($)',
    'PROD Cost ($)',
    'PP Cost ($)',
    'Total Render Cost ($)',
  ];

  constructor(
    private modelsHttpService: ModelsHttpService,
    private modelsProcessingHttpService: ModelsProcessingHttpService,
    private toastrService: NbToastrService,
    private costsService: CostsService,
  ) {}

  export() {
    this.modelsHttpService
      .getModels()
      .pipe(
        switchMap((models: Model[]) => {
          return this.modelsProcessingHttpService
            .getBuildProcesses()
            .pipe(map((processes: BuildProcess[]) => [models, processes]));
        }),
        map(([models, processes]: [Model[], BuildProcess[]]) => {
          return models
            .filter((model) => !model.isArchived)
            .map((model) => this.toCSVRow(model, processes))
            .filter((row) => row.renders.length)
            .map((row) => this.toArray(row))
            .flat();
        }),
      )
      .subscribe((rows) => {
        if (rows.length) {
          this.download([this.headers, ...(rows as string[][])]);
        } else {
          this.toastrService.show(NO_DATA_TO_EXPORT, WARNING, {
            ...WARNING_TOASTR_CONFIG,
          });
        }
      });
  }

  private download(rows: string[][]) {
    return downloadCsv(rows, this.headers, ANALYTICS_FILE_NAME);
  }

  private toCSVRow(model: Model, allProcesses: BuildProcess[]) {
    const processes = allProcesses.filter(
      (process) => +process.modelId === +model.id && process.analytics && process.postProcessed,
    );
    return {
      modelId: model.id,
      modelName: model.name,
      // eslint-disable-next-line complexity
      renders: processes.map((process) => {
        const analytics: Analytics = JSON.parse(process.analytics as string);
        const { atCost, prodCost, ppCost, totalCost } = this.costsService.calculateCosts(
          analytics as Analytics,
        );
        return {
          renderId: `${process.id}`,
          atStartedAt: analytics.aerotriangulation?.startedAt || '-',
          atFinishedAt: analytics.aerotriangulation?.finishedAt || '-',
          prodStartedAt: analytics.production?.startedAt || '-',
          prodFinishedAt: analytics.production?.finishedAt || '-',
          ppStartedAt: analytics.postProduction?.startedAt || '-',
          ppFinishedAt: analytics.postProduction?.finishedAt || '-',
          imageSizeTotal: analytics.imageSizeTotal || '-',
          modelSizeTotal: analytics.modelSizeTotal || '-',
          pixelsTotal: analytics.pixelsTotal || '-',
          atCost: atCost?.toFixed(2) || '-',
          prodCost: prodCost?.toFixed(2) || '-',
          ppCost: ppCost?.toFixed(2) || '-',
          totalRenderCost: totalCost?.toFixed(2) || '-',
        };
      }),
    } as CSVAnalyticsRow;
  }

  private toArray(row: CSVAnalyticsRow) {
    const renderRows = row.renders.map((render) => [
      '',
      '',
      render.renderId,
      render.atStartedAt,
      render.atFinishedAt,
      render.prodStartedAt,
      render.prodFinishedAt,
      render.ppStartedAt,
      render.ppFinishedAt,
      render.imageSizeTotal,
      render.modelSizeTotal,
      render.pixelsTotal,
      render.atCost,
      render.prodCost,
      render.ppCost,
      render.totalRenderCost,
    ]);
    return [[row.modelId, row.modelName], ...renderRows, []];
  }
}
