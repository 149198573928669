import { TOOL_TUTORIALS } from '@App/app/configs/hints.config';
import { LAYER_EVENTS } from '@App/app/entities/layer/enums/layer-types.enum';
import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tutorial-video-popup',
  templateUrl: './tutorial-video-popup.component.html',
  styleUrls: ['./tutorial-video-popup.component.scss'],
})
export class TutorialVideoPopupComponent implements OnInit {
  toolEvent: LAYER_EVENTS;
  path: string;

  constructor(private dialogRef: NbDialogRef<TutorialVideoPopupComponent>) {}

  ngOnInit() {
    if (this.toolEvent in TOOL_TUTORIALS) {
      this.path = TOOL_TUTORIALS[this.toolEvent].videoPath;
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
