/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import {
  AngleIron,
  AngleIronBox,
  angleIronMesh,
  ViewerAngleIronLayer,
} from '@App/app/entities/layer/angle-iron.model';
import { createGui } from '@App/app/entities/layer/create-gui.model';
import { Coordinates4 } from '@App/app/entities/layer/measurements/coordinates';
import { CreationToolError } from '@App/app/shared/exceptions/creation-tool-error';
import {
  AbstractMesh,
  BoundingBox,
  Color4,
  CSG,
  Matrix,
  Mesh,
  Plane,
  Quaternion,
  Ray,
  Vector3,
} from 'babylonjs';
import { ELECTRIC_BLUE_COLOR } from 'src/app/configs/babylon.config';
import { modelMeshPredicate } from '../../../helpers/layers-helpers';
import { BabylonNodesService } from '../../babylon-nodes-service/babylon-nodes.service';
import { GenerateGUIService } from '../../gui-services/generate-gui-service/generate-gui.service';
import { MaterialService } from '../../material-service/material.service';
import { SceneService } from '../../scene-service/scene.service';

@Injectable({
  providedIn: 'root',
})
export class AngleIronUtilsService implements createGui {
  constructor(
    public generateGUIService: GenerateGUIService,
    private babylonNodesService: BabylonNodesService,
    private materialService: MaterialService,
    private sceneService: SceneService,
  ) {}

  calculateAngleIronData(samples: [AbstractMesh, AbstractMesh], planes: [Plane, Plane]) {
    const centers = samples.map((sample, i) =>
      this.findCenterPoint(sample.position, planes[i]),
    ) as [Vector3, Vector3];
    const normals = this.getNormals(centers, planes);
    const cross = Vector3.Cross(normals[0], normals[1]);
    const orthoPlanes: [Plane, Plane] = [
      planes[0],
      Plane.FromPositionAndNormal(
        centers[1].projectOnPlane(planes[1], centers[1].add(planes[1].normal)),
        Vector3.Cross(normals[0], cross.negate()),
      ),
    ];
    const orthoNormals = this.getNormals(centers, orthoPlanes);
    const lengthEdges = this.findLengthEdges(centers, orthoPlanes, cross);
    const length = Vector3.Distance(lengthEdges[0], lengthEdges[1]);
    if (length <= 0) {
      throw new CreationToolError('Length edges not valid');
    }
    const edgePlane = Plane.FromPositionAndNormal(lengthEdges[0], cross.negate());
    const projected = this.findProjectedEdges(centers, orthoPlanes);
    const quaternion = Quaternion.FromRotationMatrix(
      Matrix.LookDirectionLH(cross, orthoNormals[0].negate()),
    );
    const thickness = this.findThickness(centers, projected, orthoNormals, orthoPlanes, cross);

    const boxes = centers.map((center, i) => {
      const ray = new Ray(center, normals[Math.abs(i - 1)], 100);
      const pick = this.sceneService.scene.pickWithRay(ray, modelMeshPredicate)?.pickedPoint;
      if (!pick) {
        throw new CreationToolError('Mesh edges not find');
      }
      const projectedPick = this.doubleProjectOnPlanes(pick, [
        orthoPlanes[i],
        Plane.FromPositionAndNormal(projected[i], cross),
      ]);
      const thicknessEdge = projected[Math.abs(i - 1)].add(normals[i].scale(thickness));
      const { min, max } = this.minMaxVectors([projectedPick, thicknessEdge]);
      const position = Vector3.Center(min, max);
      const projectedPosition = position.projectOnPlane(edgePlane, position.add(cross));
      const centeredPosition = projectedPosition.subtract(cross.scale(length / 2));

      const box = this.babylonNodesService.createBox(centeredPosition, {
        width: i === 1 ? thickness : Vector3.Distance(projected[0], projectedPick),
        height: i === 0 ? thickness : Vector3.Distance(projected[1], projectedPick),
        depth: length,
      });
      const boxData = box.getHierarchyBoundingVectors();
      box.dispose();
      return boxData;
    });

    return {
      boxes,
      quaternion,
      length,
    };
  }

  changeSampleMaterial(sample: AbstractMesh, defaultMaterial: boolean) {
    sample.material = defaultMaterial
      ? this.materialService.electricBlueMaterial
      : this.materialService.redMaterial;
  }

  showAngleIron(
    angleIron: AngleIron,
    boxes: [AngleIronBox, AngleIronBox],
    absoluteRotationQuaternion: Coordinates4,
  ) {
    const newAngleIron = angleIron as ViewerAngleIronLayer;
    const meshes = this.createBoxes(boxes, absoluteRotationQuaternion);
    newAngleIron.mesh = CSG.FromMesh(meshes[0])
      .union(CSG.FromMesh(meshes[1]))
      .toMesh(
        angleIronMesh.defaultName,
        this.materialService.electricBlueMaterial,
        this.sceneService.scene,
        false,
      );
    newAngleIron.mesh.material = this.materialService.electricBlueMaterial;
    newAngleIron.mesh.renderingGroupId = 0;
    newAngleIron.mesh.visibility = 0.6;
    newAngleIron.mesh.enableEdgesRendering();
    newAngleIron.mesh.edgesWidth = 0.1;
    newAngleIron.mesh.edgesColor = Color4.FromHexString(`${ELECTRIC_BLUE_COLOR}FF`);
    for (let i = 0; i < meshes.length; i++) {
      meshes[i].dispose();
      delete meshes[i];
    }
    newAngleIron.gui = this.createGui(newAngleIron);
    return newAngleIron;
  }

  createGui(layer: ViewerAngleIronLayer) {
    if (layer.mesh) {
      return this.generateGUIService.createNameTag(layer.name, layer.mesh, true, layer.id);
    }
  }

  createBoxes(boxes: [AngleIronBox, AngleIronBox], quaternion: Coordinates4): [Mesh, Mesh] {
    const rotation = new Quaternion(quaternion.x, quaternion.y, quaternion.z, quaternion.w);
    return boxes.map((box) => {
      if (box.min && box.max) {
        const min = new Vector3(box.min.x, box.min.y, box.min.z);
        const max = new Vector3(box.max.x, box.max.y, box.max.z);
        const boundingBox = new BoundingBox(min, max);
        const mesh = this.babylonNodesService.createBox(
          boundingBox.centerWorld,
          {
            width: boundingBox.extendSize.x * 2,
            height: boundingBox.extendSize.y * 2,
            depth: boundingBox.extendSize.z * 2,
          },
          rotation,
        );
        mesh.visibility = 0.6;
        return mesh;
      }
    }) as [Mesh, Mesh];
  }

  private findCenterPoint(sample: Vector3, plane: Plane) {
    const projected = sample.projectOnPlane(plane, sample.add(plane.normal));
    return Vector3.Center(projected, sample);
  }

  private findThickness(
    points: [Vector3, Vector3],
    projected: [Vector3, Vector3],
    normals: [Vector3, Vector3],
    planes: [Plane, Plane],
    cross: Vector3,
  ) {
    const edges = points.map((point, i) => {
      const ray = new Ray(point, normals[i], 100);
      const pick = this.sceneService.scene.pickWithRay(ray, modelMeshPredicate)?.pickedPoint;
      if (!pick) {
        throw new CreationToolError('Mesh edges not find');
      }
      return this.doubleProjectOnPlanes(pick, [
        planes[Math.abs(i - 1)],
        Plane.FromPositionAndNormal(projected[Math.abs(i - 1)], cross),
      ]);
    });
    return (
      (Vector3.Distance(edges[0], projected[1]) + Vector3.Distance(edges[1], projected[0])) /
      edges.length
    );
  }

  private getNormals(centers: Vector3[], planes: Plane[]): [Vector3, Vector3] {
    const normalInner = centers[0].subtract(centers[1]).normalizeToNew();
    const normal = planes[0].isFrontFacingTo(normalInner, 0)
      ? planes[0].normal
      : planes[0].normal.negate();
    const otherNormal = planes[1].isFrontFacingTo(normalInner, 0)
      ? planes[1].normal.negate()
      : planes[1].normal;
    return [normal, otherNormal];
  }

  private findLengthEdges(
    points: [Vector3, Vector3],
    planes: [Plane, Plane],
    cross: Vector3,
  ): [Vector3, Vector3] {
    const edges = points.map((point, i) => {
      const center = this.findCenterPoint(point, planes[Math.abs(i - 1)]);
      const rays = [new Ray(center, cross, 100), new Ray(center, cross.negate(), 100)];
      const picks = this.pickWithRays(rays);
      return [...picks];
    }) as [[Vector3, Vector3], [Vector3, Vector3]];
    return [Vector3.Center(edges[0][0], edges[1][0]), Vector3.Center(edges[0][1], edges[1][1])];
  }

  private findProjectedEdges(
    centers: [Vector3, Vector3],
    planes: [Plane, Plane],
  ): [Vector3, Vector3] {
    const projected = centers.map((center) => [
      this.doubleProjectOnPlanes(center, [planes[0], planes[1]]),
      this.doubleProjectOnPlanes(center, [planes[1], planes[0]]),
    ]);
    return [
      Vector3.Center(projected[0][0], projected[1][0]),
      Vector3.Center(projected[0][1], projected[1][1]),
    ];
  }

  private doubleProjectOnPlanes(point: Vector3, planes: [Plane, Plane]) {
    const tmp = point.projectOnPlane(planes[0], point.add(planes[0].normal));
    return tmp.projectOnPlane(planes[1], tmp.add(planes[1].normal));
  }

  private pickWithRays(rays: Ray[]) {
    return rays.map((ray) => {
      return this.sceneService.scene.pickWithRay(ray, modelMeshPredicate)?.pickedPoint;
    });
  }

  private minMaxVectors(picks: Vector3[]) {
    const min = picks[0].clone();
    const max = picks[0].clone();
    picks.forEach((pick) => {
      min.minimizeInPlace(pick);
      max.maximizeInPlace(pick);
    });
    return { min, max };
  }
}
