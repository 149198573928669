<ng-container [ngSwitch]="layer.type">
  <app-angle-iron-properties
    *ngSwitchCase="LAYER_TYPES.ANGLE_IRON"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-angle-iron-properties>
  <app-measurement-properties
    *ngSwitchCase="LAYER_TYPES.MEASUREMENTS"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-measurement-properties>
  <app-annotation-properties
    *ngSwitchCase="LAYER_TYPES.ANNOTATION"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-annotation-properties>
  <app-area-polygon-properties
    *ngSwitchCase="LAYER_TYPES.POLYGONS"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-area-polygon-properties>
  <app-coordinate-properties
    *ngSwitchCase="LAYER_TYPES.COORDINATES_LAYER"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-coordinate-properties>
  <app-tube-properties
    *ngSwitchCase="LAYER_TYPES.TUBES"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-tube-properties>
  <app-sketch-plane-properties
    *ngSwitchCase="LAYER_TYPES.SKETCH_PLANES"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-sketch-plane-properties>
  <app-cuboid-properties
    *ngSwitchCase="LAYER_TYPES.CUBOID"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-cuboid-properties>
  <app-drop-line-properties
    *ngSwitchCase="LAYER_TYPES.DROP_LINES"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-drop-line-properties>
  <app-rect-tube-properties
    *ngSwitchCase="LAYER_TYPES.RECT_TUBE"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-rect-tube-properties>
  <app-base-plane-properties
    *ngSwitchCase="LAYER_TYPES.BASE_PLANE"
    [layer]="layer | asType"
    [editForm]="editForm"
  ></app-base-plane-properties>
</ng-container>
