<div [formGroup]="form">
  <input
    class="layer_details__input thickness_input"
    [formControlName]="field"
    type="number"
    [step]="step"
    [min]="min"
    [max]="max"
    required
    (focusout)="onFocusOut()"
    data-testId="thickness-input"
  />
  <span>{{ unitsString }}</span>
  <div class="layer_details__input__error" *ngIf="errors?.min">The value is too low</div>
  <div class="layer_details__input__error" *ngIf="errors?.max">The value is too great</div>
</div>
