import {
  ATLog,
  ATMesh,
  Automapping,
  BaseFile,
  FlatFile,
  GCPFile,
  GCPList,
  OrthoDSM,
  Photo,
  PointCloud,
  ProdLog,
  SimplifiedTile,
  TiePoints,
  Tile,
} from '../files/files.model';
import { Analytics } from './analytics.model';

export class Render {
  id: number;
  createdAt: string;
  imageDimensionsWidth: number | null;
  imageDimensionsHeight: number | null;
  analytics?: string;

  constructor(props: Partial<Render>) {
    Object.assign(this, props);
  }

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get analyticsObject() {
    return this.analytics ? (JSON.parse(this.analytics) as Analytics) : null;
  }
}

export class ExtendedRender extends Render {
  files: BaseFile[] = [];

  constructor(props: Partial<ExtendedRender>) {
    const propFiles = props.files || [];
    delete props.files;
    super(props);
    this.files = BaseFile.fromHttpFiles(propFiles);
  }

  get tiles() {
    return this.files.filter((file) => file instanceof Tile) as Tile[];
  }

  get simplifiedTiles() {
    return this.files.filter((file) => file instanceof SimplifiedTile) as SimplifiedTile[];
  }

  get photos(): Photo[] {
    return this.files.filter((file) => file instanceof Photo) as Photo[];
  }

  get automapping() {
    return this.files.find((file) => file instanceof Automapping) as Automapping | null;
  }

  get gcp() {
    return this.files.find((file) => file instanceof GCPFile) as GCPFile | null;
  }

  get flatFile() {
    return this.files.find((file) => file instanceof FlatFile) as FlatFile | null;
  }

  get atLog() {
    return this.files.find((file) => file instanceof ATLog) as ATLog | null;
  }

  get prodLog() {
    return this.files.find((file) => file instanceof ProdLog) as ProdLog | null;
  }

  get tiePoints() {
    return this.files.find((file) => file instanceof TiePoints) as TiePoints | null;
  }

  get atMesh() {
    return this.files.find((file) => file instanceof ATMesh) as ATMesh | null;
  }

  get gcpList() {
    return this.files.find((file) => file instanceof GCPList) as GCPList | null;
  }

  get pointCloud() {
    return this.files.find((file) => file instanceof PointCloud) as PointCloud | null;
  }

  get orthoDSM() {
    return this.files.find((file) => file instanceof OrthoDSM) as OrthoDSM | null;
  }

  getSimplifiedTilesLevelsAsFlatList() {
    const simplifiedTilesByLevels = this.simplifiedTiles.reduce<{
      [level: number]: SimplifiedTile[];
    }>((acc, tile) => {
      const level = tile.data.level || -1;
      acc[level] = acc[level] || [];
      acc[level].push(tile);
      return acc;
    }, {});

    if (-1 in simplifiedTilesByLevels) {
      // depreciated format
      return [simplifiedTilesByLevels[-1]];
    }

    const levels = Object.keys(simplifiedTilesByLevels)
      .sort()
      .map((key) => simplifiedTilesByLevels[+key]);

    return levels;
  }
}
