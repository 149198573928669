import { ICategory } from '@App/app/entities/models/report-problem.model';
import { ApiService } from '@App/app/shared/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReportProblemHttpService extends ApiService {
  postReportProblem(reportForm: Partial<ICategory>) {
    return this.http.post<ICategory>(`${this.api}/problem-reports`, reportForm);
  }
}
