import { CUSTOM_ICONS_PACK } from '@App/app/configs/app.config';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NbTrigger } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DxfExportService } from '../../services/dxf-export/dxf-export.service';
import { LayersService } from '../../services/layers/layers.service';
import { selectAllChildrenByLayerIds } from '../../store/selectors/layers.selectors';
import { isLayerDXFExportable } from '../../utils/layers.utils';

@UntilDestroy()
@Component({
  selector: 'app-layers-dxf-export-bar',
  templateUrl: './layers-dxf-export-bar.component.html',
  styleUrls: ['./layers-dxf-export-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayersDxfExportBarComponent implements OnInit {
  exportableChildLayers: LayerUI[] = [];
  nbTrigger = NbTrigger;
  CUSTOM_ICONS_PACK = CUSTOM_ICONS_PACK;

  constructor(
    private _layersService: LayersService,
    private _dxfExportService: DxfExportService,
    private store: Store,
  ) {}

  ngOnInit() {
    merge(
      this._layersService.detailsViewLayers$,
      this._layersService.editingFolder$.pipe(
        filter(Boolean),
        map((folder) => [folder]),
      ),
    )
      .pipe(
        untilDestroyed(this),
        switchMap((layers: LayerUI[]) =>
          this.store
            .select(selectAllChildrenByLayerIds(layers.map(({ id }) => id)))
            // eslint-disable-next-line ngrx/avoid-mapping-selectors
            .pipe(map((children) => [...layers, ...children])),
        ),
      )
      .subscribe((layers) => {
        this.exportableChildLayers = layers.filter((layer) => isLayerDXFExportable(layer));
      });
  }

  onExportDXFClick() {
    return this._dxfExportService.exportLayersToDXF(this.exportableChildLayers);
  }
}
