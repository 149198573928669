import { BuildProcessStatusId, BuildProcessTypeId } from './build-process-status.model';
import { BuildProcess } from './build-process.model';
import { ProgressSegmentStatus, ProgressSegmentStatusIcon } from './process-segment-status.model';

export abstract class ProgressSegment {
  abstract name: string;
  abstract size: number;
  status: number;
  type: number;
  progress: number;
  showCheck = true;

  constructor(private process: BuildProcess) {
    this.status = this.getStatus(process);
    this.type = process.buildProcessTypeId;
    this.progress = +this.getProgress(process);
  }

  protected abstract getStatus(process: BuildProcess): BuildProcessStatusId;

  protected abstract getProgress(process: BuildProcess): number;

  getStatusIcon() {
    const status = ProgressSegmentStatus[this.status];
    const warningStatus =
      this.isCanceled() &&
      !(this instanceof UploadProgressSegment) &&
      !this.process.steps.at.isWaiting();
    if (warningStatus) {
      return ProgressSegmentStatusIcon['WARNING'];
    }

    return ProgressSegmentStatusIcon[status as keyof typeof ProgressSegmentStatusIcon];
  }

  isLarge() {
    return this.size > 1;
  }

  showHeader() {
    return this.isStarting() && this.progress;
  }

  isPaused() {
    return this.status === BuildProcessStatusId.Paused;
  }

  isInterrupted() {
    return this.status === BuildProcessStatusId.Started && this.type === BuildProcessTypeId.Upload;
  }

  isFailed() {
    return this.status === BuildProcessStatusId.Failed;
  }

  isFinished() {
    return this.status === BuildProcessStatusId.Finished;
  }

  isSuccess() {
    return this.isFinished() || this.status === BuildProcessStatusId.Canceled;
  }

  hasEmptyProgress() {
    return this.progress === 0;
  }

  hasFullProgress() {
    return this.progress === 100;
  }

  getPercentagesWidth() {
    return this.isStarting() ? `${this.progress}%` : '100%';
  }

  isStarting() {
    return this.status === BuildProcessStatusId.Started;
  }

  isCanceled() {
    return this.status === BuildProcessStatusId.Canceled;
  }
}

export class UploadProgressSegment extends ProgressSegment {
  name = BuildProcessTypeId[1];
  size = 1;

  protected getStatus(process: BuildProcess) {
    return process.steps.upload.isCurrent()
      ? process.buildProcessStatusId
      : BuildProcessStatusId.Finished;
  }

  protected getProgress(process: BuildProcess) {
    return process.steps.upload.isCurrent() ? process.progress : 100;
  }
}

export class ATProgressSegment extends ProgressSegment {
  name = BuildProcessTypeId[2];
  size = 1;

  protected getStatus(process: BuildProcess) {
    return process.steps.at.isCurrent()
      ? process.steps.at.isCancelled()
        ? BuildProcessStatusId.Canceled
        : process.buildProcessStatusId
      : process.steps.at.isNotYet()
      ? BuildProcessStatusId.Started
      : BuildProcessStatusId.Finished;
  }

  protected getProgress(process: BuildProcess) {
    return process.steps.at.isCurrent() ? process.progress : process.steps.at.isNotYet() ? 0 : 100;
  }
}

export class ProdProgressSegment extends ProgressSegment {
  name = BuildProcessTypeId[4];
  size = 2;

  protected getStatus(process: BuildProcess) {
    return process.steps.prod.isCurrent()
      ? process.steps.prod.isCanceled()
        ? BuildProcessStatusId.Canceled
        : process.buildProcessStatusId
      : process.steps.prod.isNotYet()
      ? BuildProcessStatusId.Started
      : BuildProcessStatusId.Finished;
  }

  protected getProgress(process: BuildProcess) {
    return process.steps.prod.isCurrent()
      ? process.progress
      : process.steps.prod.isNotYet()
      ? 0
      : 100;
  }
}

export class PPProgressSegment extends ProgressSegment {
  name = BuildProcessTypeId[5];
  size = 1;
  showCheck = false;

  protected getStatus(process: BuildProcess) {
    return process.steps.pp.isCurrent()
      ? process.buildProcessStatusId
      : BuildProcessStatusId.Started;
  }

  protected getProgress(process: BuildProcess) {
    return process.steps.pp.isNotYet() ? 0 : process.progress;
  }
}
