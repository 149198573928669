import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { CreateTubeService } from '@App/app/engine/services/layer-services/tube-services/create-tube.service';
import {
  LAYER_EVENTS,
  TUBE_PART,
  TUBE_PART_NAME,
  TUBE_PART_STRICT,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { TubePartsArrays } from '@App/app/entities/layer/tube.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractMesh } from 'babylonjs';

@UntilDestroy()
@Component({
  selector: 'app-create-tube-details',
  templateUrl: './create-tube-details.component.html',
  styleUrls: ['./create-tube-details.component.scss'],
})
export class CreateTubeDetailsComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() generate = new EventEmitter();

  LAYER_EVENTS = LAYER_EVENTS;
  TUBE_PART_NAME = TUBE_PART_NAME;
  activeLayerEvent: LAYER_EVENTS;
  tubeActivePart: TUBE_PART;
  tubeHighlightedSample: { part: TUBE_PART; i: number };
  tubeSamples: TubePartsArrays<AbstractMesh>;
  isGenerateAvailable: boolean;
  isDetailsVisible = true;

  constructor(
    private createTubeService: CreateTubeService,
    private layerEventsService: LayerEventsService,
  ) {}

  ngOnInit(): void {
    this.activeLayerEvent = this.layerEventsService.activeLayerEvent.value;
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_TUBE) {
      this.createTubeService.tubeDataChange$.pipe(untilDestroyed(this)).subscribe(() => {
        this.tubeActivePart = this.createTubeService.activePart;
        this.tubeHighlightedSample = this.createTubeService.highlightedSample;
        this.tubeSamples = this.createTubeService.samples;
        this.isGenerateAvailable =
          this.tubeSamples[TUBE_PART.ENDPOINT_1_OUTER].length > 2 &&
          this.tubeSamples[TUBE_PART.ENDPOINT_2_OUTER].length > 2;
      });
      this.createTubeService.setActivePart(TUBE_PART.ENDPOINT_1_OUTER);
    }
  }

  toggleHighlightTubeSample({ part, i }: { part: TUBE_PART_STRICT; i: number }) {
    this.createTubeService.highlightSample(part, i);
  }

  onDeleteTubeSample({ part, i }: { part: TUBE_PART_STRICT; i: number }) {
    this.createTubeService.deleteSamplePoint(part, i);
  }

  onGenerate() {
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_TUBE) {
      this.createTubeService.createTube();
      this.generate.emit();
      this.isDetailsVisible = false;
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  isTubeSampleHighlighted(part: TUBE_PART, i: number) {
    return this.tubeHighlightedSample.part === part && this.tubeHighlightedSample.i === i;
  }

  setTubeActivePart({ part }: { part: TUBE_PART_STRICT }) {
    this.createTubeService.setActivePart(part);
  }
}
