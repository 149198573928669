import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-layout',
  templateUrl: './filter-layout.component.html',
  styleUrls: ['./filter-layout.component.scss', '../base-filter/base-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FilterLayoutComponent {
  @Input() filterForm: FormGroup;
  @Input() loading: boolean;
  @Input() textInputValue: string;
  @Input() textInputPlaceholder: string;
  @Input() showClearButton: boolean;
  @Input() isFilterPopoverActive: boolean;
  @Input() contentTpl: TemplateRef<any>;
  @Output() submitHandler = new EventEmitter<FormGroup>();
  @Output() toggleFilterPopover = new EventEmitter<void>();
  @Output() queryChange = new EventEmitter<string>();
  @Output() clearFilters = new EventEmitter<void>();

  onSubmit(form: FormGroup) {
    this.submitHandler.emit(form);
  }

  onToggleFilterPopover() {
    this.toggleFilterPopover.emit();
  }

  onQueryChange(value: string) {
    this.queryChange.emit(value);
  }

  onClearFilters() {
    this.clearFilters.emit();
  }
}
