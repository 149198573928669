import { Photo } from '@App/app/entities/files/files.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'photoAnnotationsNumber',
})
export class PhotoAnnotationsNumberPipe implements PipeTransform {
  transform(photo: Photo): number {
    return photo.data.annotations?.length || 0;
  }
}
