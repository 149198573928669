import { LAYER_TYPES } from '@App/app/entities/layer/enums/layer-types.enum';
import { LayerUI } from '@App/app/entities/layer/layer-ui.model';
import { EVENT_TYPE } from '@App/app/entities/shared/event-types.enum';
import { UnitsSystem } from '@App/app/entities/shared/units.model';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NbTrigger } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from 'src/app/shared/broadcast.service';
import { LayerDetailsService } from '../layer-details/services/layer-details-service/layer-details.service';
import { ActiveLayerToolService } from '../services/active-layer-tool/active-layer-tool.service';
import { LayersService } from '../services/layers/layers.service';

@UntilDestroy()
@Component({
  selector: 'app-layers-details',
  templateUrl: './layers-details.component.html',
  styleUrls: ['./layers-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayersDetailsComponent implements OnInit {
  @Input() unitsSystem: UnitsSystem;
  layers: LayerUI[];
  loadingLayers: LayerUI[];
  nbTrigger = NbTrigger;

  constructor(
    private _layersService: LayersService,
    private _layerDetailsService: LayerDetailsService,
    private _activeLayerToolService: ActiveLayerToolService,
    private _broadcastService: BroadcastService,
  ) {}

  ngOnInit(): void {
    this._layersService.detailsViewLayers$.pipe(untilDestroyed(this)).subscribe((layers) => {
      this.layers = [...layers];
    });
    this._layersService.loadingLayers$.pipe(untilDestroyed(this)).subscribe((layers) => {
      this.loadingLayers = [...layers];
    });
  }

  onBackClick() {
    this.layers.forEach((layer) => {
      if (this._layersService.editedLayers.includes(layer)) {
        this._layersService.setLayerEditMode(layer, false);
        this._layersService.loadBackupLayer(layer.id);
        if (layer.type === LAYER_TYPES.SKETCH_PLANES) {
          this._broadcastService.broadcast(EVENT_TYPE.EDIT_SKETCHES, {
            sketchPlane: layer,
            isAddPlaneActive: false,
            resetSketchesCurrentPlane: true,
            removeSketchDrafts: true,
          });
        }
      }
    });
    this._activeLayerToolService.activateEditMode(false);
    this._layersService.setVisibleLayers([...this.layers]);
    this._layersService.setDetailsViewLayers([]);
    this._layersService.editedLayers = [];
    this._layerDetailsService.deleteCanceled();
  }
}
