<div
  class="internet_connection_status"
  [ngClass]="(isInternetAvailable$ | async) ? '--online' : '--offline'"
  data-testId="internet-connection-status"
>
  <nb-icon [icon]="(isInternetAvailable$ | async) ? 'wifi-outline' : 'wifi-off-outline'"></nb-icon>
  <span>{{
    (isInternetAvailable$ | async) ? INTERNET_CONNECTION_ESTABLISHED : INTERNET_CONNECTION_LOST
  }}</span>
</div>
