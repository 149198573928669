import { LayerEventsService } from '@App/app/engine/services/layer-services/layer-events-service/layer-events.service';
import { CuboidPartsArrays } from '@App/app/entities/layer/cuboid.model';
import {
  CUBOID_PART,
  CUBOID_PART_NAME,
  CUBOID_PART_STRICT,
  LAYER_EVENTS,
} from '@App/app/entities/layer/enums/layer-types.enum';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractMesh } from 'babylonjs';
import { CuboidService } from '../../../engine/services/layer-services/rectangle-tools-services/cuboid-services/cuboid.service';

@UntilDestroy()
@Component({
  selector: 'app-create-cuboid-details',
  templateUrl: './create-cuboid-details.component.html',
  styleUrls: ['./create-cuboid-details.component.scss'],
})
export class CreateCuboidDetailsComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() generate = new EventEmitter();

  LAYER_EVENTS = LAYER_EVENTS;
  CUBOID_PART_NAME = CUBOID_PART_NAME;
  activeLayerEvent: LAYER_EVENTS;
  cuboidActivePart: CUBOID_PART | null;
  cuboidHighlightedSample: { part: CUBOID_PART | null; i: number };
  cuboidSamples: CuboidPartsArrays<AbstractMesh>;
  isGenerateAvailable: boolean;
  isDetailsVisible = true;

  constructor(
    private layerEventsService: LayerEventsService,
    private cuboidService: CuboidService,
  ) {}

  ngOnInit(): void {
    this.activeLayerEvent = this.layerEventsService.activeLayerEvent.value;
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_CUBOID) {
      this.cuboidSamples = this.cuboidService.samples;
      this.cuboidService.dataChange$.pipe(untilDestroyed(this)).subscribe(() => {
        this.cuboidActivePart = this.cuboidService.activePart;
        this.cuboidHighlightedSample = this.cuboidService.highlightedSample;
        this.cuboidSamples = this.cuboidService.samples;
        this.isGenerateAvailable =
          (this.cuboidSamples[CUBOID_PART.FRONT_BACK].length > 3 &&
            this.cuboidSamples[CUBOID_PART.TOP_BOTTOM].length > 2) ||
          (this.cuboidSamples[CUBOID_PART.FRONT_BACK].length > 3 &&
            this.cuboidSamples[CUBOID_PART.LEFT_RIGHT].length > 2);
      });
      this.cuboidService.setActivePart(CUBOID_PART.FRONT_BACK);
    }
  }

  toggleHighlightCuboidSample({ part, i }: { part: CUBOID_PART_STRICT; i: number }) {
    this.cuboidService.highlightSample(part, i);
  }

  onDeleteCuboidSample({ part, i }: { part: CUBOID_PART_STRICT; i: number }) {
    this.cuboidService.deleteSample(part, i);
  }

  onGenerate() {
    if (this.activeLayerEvent === LAYER_EVENTS.ADD_CUBOID) {
      this.cuboidService.generateCuboidBoundingBox();
      this.cuboidService.setActivePart(CUBOID_PART.NONE);
      this.generate.emit();
      this.isDetailsVisible = false;
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  isTubeSampleHighlighted(part: CUBOID_PART, i: number) {
    return this.cuboidHighlightedSample.part === part && this.cuboidHighlightedSample.i === i;
  }

  setCuboidActivePart({ part }: { part: CUBOID_PART_STRICT }) {
    this.cuboidService.setActivePart(part);
  }
}
