<form [formGroup]="form" (ngSubmit)="onSubmit(form)" data-testId="prod-form">
  <app-processing-settings
    (resetSettings)="onRestoreDefault()"
    [form]="form"
    [settings]="getSettings()"
    [prodTab]="true"
  >
  </app-processing-settings>
  <div class="format-error">
    <span *ngIf="isFormatError" class="format-error__text"
      >At least one production format must be selected</span
    >
  </div>
  <app-tab-actions
    [backAction]="onCancel"
    [isSubmit]="true"
    [nextActionPermission]="canAccept"
  ></app-tab-actions>
</form>
