import { Duration } from '../shared/duration.model';
import { BuildProcessStatusId, PostProcessingSettings } from './build-process-status.model';
import * as Steps from './build-process-step.model';
import { ExtendedRender } from './render.model';

export class BuildProcess extends ExtendedRender implements Duration {
  heartbeatAt: string | null;
  failedAt: string;
  canceledAt: string;
  aerotriangulationSettings: string;
  imagesBucketAddress: string;
  postProcessingSettings: PostProcessingSettings;
  resourceBaseURL: string;
  workerInstanceId: string;
  aerotriangulationAccepted: boolean;
  buildProcessStatusId: number;
  buildProcessTypeId: number;
  runAerotriangulationAutomatically: boolean;
  currentProcessingActivity: string;
  failMessage: string;
  isArchived: boolean;
  postProcessed: boolean;
  productionAccepted: boolean;
  progress: number;
  reconstructionSettings: string;
  model: {
    id: number;
    name: string;
  };
  modelId: number;
  users: string;
  startedAt: string;
  finishedAt: string;
  steps: Steps.BuildProcessStepsList;
  withObj: boolean;
  withPointCloud: boolean;
  withOrthoDsm: boolean;

  constructor(props: Partial<BuildProcess>) {
    super(props);
    this.progress = +this.progress;

    this.steps = {
      upload: new Steps.UploadStep(this),
      at: new Steps.AerotriangulationStep(this),
      reconstruct: new Steps.ReconstructionStep(this),
      prod: new Steps.ProductionStep(this),
      pp: new Steps.PostProductionStep(this),
    };
  }

  isStarted() {
    return this.buildProcessStatusId === BuildProcessStatusId.Started;
  }

  isFinished() {
    return this.buildProcessStatusId === BuildProcessStatusId.Finished;
  }

  isFailed() {
    return this.buildProcessStatusId === BuildProcessStatusId.Failed;
  }

  isCancelled() {
    return this.buildProcessStatusId === BuildProcessStatusId.Canceled;
  }

  isAccessible() {
    return !this.isInProgress() && !this.isStarting() && this.steps.upload.allowAccessToProcess();
  }

  getAvailableStep() {
    return this.getCurrentStep()?.getAvailableStep() || 0;
  }

  getActiveStep() {
    return this.getCurrentStep()?.getActiveStep() || 0;
  }

  getCurrentStep(): Steps.BuildProcessStep | undefined {
    return Object.values(this.steps).find((step: Steps.BuildProcessStep) => step.isCurrent());
  }

  getUsers() {
    const result = JSON.parse(this.users) || {};
    const owner = result.owner || undefined;
    const subscribers = result.subscribers || [];
    return { owner, subscribers };
  }

  compareByProgress(another: BuildProcess) {
    return (
      this.buildProcessTypeId - another.buildProcessTypeId ||
      this.buildProcessStatusId - another.buildProcessStatusId ||
      this.progress - another.progress
    );
  }

  toHttp() {
    const props: Partial<BuildProcess> = { ...this };
    delete props.steps;
    delete props.files;
    return props;
  }

  private noStepIsAvailable() {
    return this.steps.at.isNotYet();
  }

  private isInProgress() {
    const progressIsNotFull = this.progress !== 100;
    return this.isStarted() && progressIsNotFull;
  }

  private isStarting() {
    return this.steps.prod.isStarted() || this.steps.pp.isStarted();
  }
}
