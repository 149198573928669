import { Annotation } from '@App/app/entities/layer/annotation.model';
import { Cuboid } from '@App/app/entities/layer/cuboid.model';
import { Coordinates3 } from '@App/app/entities/layer/measurements/coordinates';
import { SketchPlane } from '@App/app/entities/layer/sketch-tools/sketch-plane.model';
import { GeoCoords } from '@App/app/entities/shared/geo-coords';
import { CustomScene } from '@App/app/entities/viewer/custom-scene.model';
import {
  AbstractMesh,
  Material,
  Mesh,
  MeshBuilder,
  Quaternion,
  StandardMaterial,
  Vector2,
  Vector3,
} from 'babylonjs';
import { computeDestinationPoint, getRhumbLineBearing } from 'geolib';
import { LOD_SPHERES_CONFIG, MODEL_MESH_NAME } from 'src/app/configs/babylon.config';

export const copySketchPlane = (layer: SketchPlane): SketchPlane => {
  return {
    ...layer,
    data: {
      ...layer.data,
    },
  };
};

export const checkMeshesIntersections = (
  originMesh: AbstractMesh,
  otherMeshes: AbstractMesh[],
): boolean => {
  return otherMeshes.some((mesh) => originMesh.intersectsMesh(mesh));
};

export const getPicketPointGeoCoords = (
  hitPoints: Vector3 | Coordinates3,
  basePlanePos: Vector3,
  baseModelPosition: GeoCoords,
): GeoCoords => {
  // z axis points south that's why latitude is -z axis and longitude is -x axis
  const bearing = getRhumbLineBearing(
    { latitude: -basePlanePos.z, longitude: -basePlanePos.x },
    { latitude: -hitPoints.z, longitude: -hitPoints.x },
  );
  const baseVector2 = new Vector2(basePlanePos.x, basePlanePos.z);
  const targetVector2 = new Vector2(hitPoints.x, hitPoints.z);
  const distance = Vector2.Distance(baseVector2, targetVector2);
  const result = computeDestinationPoint({ ...baseModelPosition }, distance, bearing);
  return {
    latitude: +result.latitude.toFixed(8),
    longitude: +result.longitude.toFixed(8),
  };
};

export const initLODSpheres = (
  mesh: Mesh,
  scene: CustomScene,
  material: StandardMaterial | Material,
  lodContainerName: string,
  lodName: string,
) => {
  const lodSpheres = new AbstractMesh(lodContainerName, scene);
  mesh.addChild(lodSpheres);

  const arrayLODSpheres = createLODSpheres(lodSpheres, lodName, material, scene);

  arrayLODSpheres.forEach((sphere, index) => {
    mesh.addLODLevel(Math.pow(index + 1, LOD_SPHERES_CONFIG.LOD_POWER), sphere);
  });
};

export const modelMeshPredicate = (mesh: Mesh) => mesh.name.includes(MODEL_MESH_NAME);

const createLODSpheres = (
  parentMesh: AbstractMesh,
  name: string,
  material: Material,
  scene: CustomScene,
) => {
  const { diameterStart, diameterEnd, spheresAmount } = LOD_SPHERES_CONFIG;
  const diameterUnit = (diameterEnd - diameterStart) / spheresAmount;
  const tmpArray = Array(spheresAmount).fill(null);

  return tmpArray.map((_, i) => {
    let diameter = diameterStart + diameterUnit * i;
    diameter = +diameter.toFixed(2);

    const sphere = MeshBuilder.CreateSphere(
      `${name}${diameter}`,
      {
        diameter,
        segments: LOD_SPHERES_CONFIG.segments,
      },
      scene,
    );

    sphere.material = material;
    sphere.renderingGroupId = 2;
    sphere.parent = parentMesh;

    return sphere;
  });
};

export const rotationQuaternion = (layer: Cuboid | Annotation) => {
  return new Quaternion(
    layer.data.absoluteRotationQuaternion?.x,
    layer.data.absoluteRotationQuaternion?.y,
    layer.data.absoluteRotationQuaternion?.z,
    layer.data.absoluteRotationQuaternion?.w,
  );
};

export const rotateAndSwitchVectors = (
  point: [number, number, number],
): [number, number, number] => {
  const q = Quaternion.FromEulerAngles(Math.PI / 2, 0, 0);
  const vector = new Vector3(...point);
  vector.rotateByQuaternionToRef(q, vector);
  vector.multiplyInPlace(new Vector3(-1, 1, 1));
  return [vector.x, vector.y, vector.z];
};

export const getLinePoints = (points: Coordinates3[]) => {
  return [...points, points[0]].map((point) => rotateAndSwitchVectors([point.x, point.y, point.z]));
};

export const getCameraTargetDataByMesh = (mesh: Mesh, radius?: number) => {
  const { min, max } = mesh.getHierarchyBoundingVectors();
  const center = Vector3.Center(min, max);
  return { center, radius: radius || Vector3.Distance(min, max) * 1.5 };
};
