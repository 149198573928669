import { AuthService } from '@App/app/auth/auth.service';
import { Model } from '@App/app/entities/models/model.model';
import { PageRangeInfo } from '@App/app/entities/shared/page-info.model';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NbTabComponent } from '@nebular/theme';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, merge } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { removeAllModelsLocal } from '../../models/store/models/actions/models.actions';
import { BaseSiteComponent } from '../shared/base-site/base-site.component';
import { ModelsDisplayingModes } from '../shared/models/models-displaying-modes.model';
import { ModelsSitesService } from '../shared/site-services/models-sites.service';
import { ModelsGridComponent } from './models-grid/models-grid.component';
import { ModelsMapComponent } from './models-map/models-map.component';
import { ModelsTableComponent } from './models-table/models-table.component';
import { ModelsContentService } from './services/models-content-service/models-content.service';

@Component({
  selector: 'app-models-site',
  templateUrl: './models-site.component.html',
  styleUrls: ['../shared/base-site/base-site.component.scss', './models-site.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ModelsContentService],
})
export class ModelsSiteComponent extends BaseSiteComponent<Model, ModelsSitesService>
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(ModelsTableComponent) private _modelsTable: ModelsTableComponent;
  @ViewChild(ModelsGridComponent) private _modelsGrid: ModelsGridComponent;
  @ViewChild(ModelsMapComponent) private _modelsMap: ModelsMapComponent;
  Modes = ModelsDisplayingModes;
  mode: ModelsDisplayingModes;
  pageRangeInfo$: Observable<PageRangeInfo | null>;

  constructor(
    authService: AuthService,
    renderer: Renderer2,
    permissionsService: NgxPermissionsService,
    store: Store,
    sitesService: ModelsSitesService,
    private _modelsContentService: ModelsContentService,
  ) {
    super(authService, renderer, permissionsService, store, sitesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sitesService.resetCurrentModel();
    this.sitesService.displayingMode$.pipe(untilDestroyed(this)).subscribe((mode) => {
      this.mode = mode;
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(removeAllModelsLocal());
  }

  ngAfterViewInit(): void {
    const childByMode = {
      [ModelsDisplayingModes.GRID]: this._modelsGrid,
      [ModelsDisplayingModes.MAP]: this._modelsMap,
      [ModelsDisplayingModes.TABLE]: this._modelsTable,
    };

    this.pageRangeInfo$ = merge(
      this._modelsGrid.gridPageInfo$,
      this.sitesService.page$,
      this.sitesService.displayingMode$,
      this._modelsContentService.filteredModels$,
    ).pipe(
      delay(10),
      map(() => childByMode[this.mode].getPageRangeInfo()),
    );
  }

  onChangeTab({ tabId }: NbTabComponent) {
    this.sitesService.setDisplayingMode(tabId as ModelsDisplayingModes);
  }
}
